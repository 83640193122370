<template>
  <div class="py-5 lg:px-10 px-3">
    <div class="flex items-center gap-2 pb-6">
      <div
        class="flex h-10 w-10 min-h-10 min-w-10 items-center justify-center rounded-lg bg-opacity-20 bg-[#724dc1]"
      >
        <div class="flex h-8 w-8 items-center justify-center rounded-md bg-[#724dc1]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-chart-pie"
          >
            <path
              d="M21 12c.552 0 1.005-.449.95-.998a10 10 0 0 0-8.953-8.951c-.55-.055-.998.398-.998.95v8a1 1 0 0 0 1 1z"
            />
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
          </svg>
        </div>
      </div>
      <p class="text-2xl font-bold uppercase">Dashboard</p>
    </div>
    <div
      class="grid grid-cols-2 lg:grid-cols-4 gap-4 items-center justify-between md:py-5 py-3 px-3 md:px-10 md:border md:border-platform-outlines rounded-xl lg:divide-x divide-neutral-gray-5"
    >
      <div class="w-full">
        <div class="lg:mx-auto">
          <div class="text-primary-2 uppercase text-xs font-medium">Total Invested</div>
          <div class="font-bold md:text-2xl text-xl text-dark">
            ${{ commaSeparateThousands(totalInvested.toFixed(2)) }}
          </div>
        </div>
        <hr class="lg:hidden" />
      </div>
      <div class="w-full lg:flex">
        <div class="mx-auto">
          <div class="text-primary-2 uppercase text-xs font-medium">Total Pending</div>
          <div class="font-bold md:text-2xl text-xl text-dark">
            ${{ commaSeparateThousands(pendingInvested.toFixed(2)) }}
          </div>
        </div>
        <hr class="lg:hidden" />
      </div>
      <div class="w-full lg:flex">
        <div class="mx-auto">
          <div class="text-primary-2 uppercase text-xs font-medium">Total Earnings</div>
          <div class="font-bold md:text-2xl text-xl text-dark">
            ${{ commaSeparateThousands(totalDistributions.toFixed(2)) }}
          </div>
        </div>
        <hr class="lg:hidden" />
      </div>
      <div class="w-full lg:flex">
        <div class="mx-auto">
          <div class="text-primary-2 uppercase text-xs font-medium">Total Invested YOY</div>
          <div class="font-bold md:text-2xl text-xl text-dark">
            {{ commaSeparateThousands(totalYOY.toFixed(2)) }}%
          </div>
        </div>
        <hr class="lg:hidden" />
      </div>
    </div>
    <DashboardCharts />
    <div class="mb-2 uppercase font-bold text-sm text-dark">Offerings</div>
    <Table
      @setSortHeader="(header, props) => setSortHeader(header)"
      :headers="tableHeaders"
      :tableData="UIStore.transformRecords(tableData)"
      :fieldsWithCustomStyles="['interest_rate']"
      class="max-w-full"
    >
      <template v-slot:interest_rate="entry">
        <div>{{ entry.row.interest_rate.value + "%" }}</div>
      </template>
    </Table>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useUIStore, useCrudStore, useInvestmentStore } from "@/stores";
import { Button, Table } from "@/components";
import DashboardCharts from "./DashboardCharts.vue";
import { commaSeparateThousands } from "@/utilities";

const UIStore = useUIStore();
const crudStore = useCrudStore();
const investmentStore = useInvestmentStore();
const tableData = ref([]);
const tableHeaders = ref([
  { field_name: "created_date", label: "Publication Date", field_type: "date" },
  { field_name: "type", label: "Reg Type" },
  { field_name: "can_compound", label: "Compounding" },
  { field_name: "name", label: "Series" },
  { field_name: "bond_term", label: "Term" },
  {
    field_name: "interest_rate",
    label: "Interest Rate",
    field_type: "number",
    number_type: "percentage",
  },
  {
    field_name: "total_raised",
    label: "Total Invested",
    field_type: "number",
    number_type: "currency",
  },
  {
    field_name: "total_pending",
    label: "Total Pending",
    field_type: "number",
    number_type: "currency",
  },
  { field_name: "offer_limit", label: "Offer Limit" },
  { field_name: "issuer", label: "Issuer" },
]);

const totalInvested = ref(0);
const pendingInvested = ref(0);
const totalDistributions = ref(0);
const totalYOY = ref(0);

onMounted(async () => {
  await getOfferings();
  totalInvested.value = await investmentStore.getDashboardMetricsTotalInvested();
  totalInvested.value = totalInvested.value[0].totalInvested;
  pendingInvested.value = await investmentStore.getDashboardMetricsPendingInvestments();
  pendingInvested.value = pendingInvested.value[0].pendingInvested;
  totalDistributions.value = await investmentStore.getDashboardMetricsTotalDistributions();
  totalDistributions.value = totalDistributions.value[0].totalInterestPaid;
  totalYOY.value = await investmentStore.getDashboardMetricsTotalYOY();
});

async function setSortHeader(header) {
  UIStore.setSortHeader(header);
  await getOfferings();
}

async function getOfferings() {
  let pipeline = [{ $match: { visible: true } }, { $sort: { type: 1 } }];
  let sortObj = {};
  if (UIStore.sortHeader) {
    sortObj[UIStore.sortHeader.field_name] = UIStore.sortAscending ? 1 : -1;
    pipeline.splice(1, 1, {
      $sort: sortObj,
    });
  }
  tableData.value = await crudStore.aggregate("Offerings", pipeline);
}
</script>
