<template>
  <AddBeneficiaryModal
    :open="beneficial_owner_modal_open"
    @close-modal="beneficial_owner_modal_open = false"
  />
  <div class="border-t border-platform-outlines pt-6 mb-4">
    <div class="flex justify-between">
      <div>
        <div class="font-bold mb-4">Beneficial Owners or Beneficiaries</div>
        <div class="mb-6 text-lighter">
          Individuals that own 20% or more of the entity. At least one is required.
        </div>
      </div>
      <Button
        :disabled="titleTransferStore.entity_template.has_existing_investments"
        @click="openEditModal(null)"
        icon
        label
        size="large"
      >
        <template #icon> <IconPlus /> </template>
        <template #label> Add Beneficiary</template>
      </Button>
    </div>

    <div class="flex h-6 items-center mb-4">
      <input
        type="checkbox"
        v-model="titleTransferStore.entity_template.owner_is_beneficial_owner"
        id="select-all"
        class="accent-secondary-6 h-5 w-5"
        :disabled="titleTransferStore.entity_template.has_existing_investments"
      />

      <div class="flex items-center font-semibold text-light ml-4">
        I am a beneficial owner or beneficiary
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div
        v-for="ben_owner of titleTransferStore.entity_template.beneficial_owners"
        class="flex rounded justify-between h-10.5 items-center gap-4 p-1"
        :class="titleTransferStore.add_entity_account ? 'hover:bg-neutral-gray-3' : ''"
      >
        <div class="flex items-center">
          <Icon name="User" class="mr-4 h-5 w-5 stroke-primary-6" :stroke-width="2" />{{
            ben_owner.first_name
          }}
          {{ ben_owner.last_name }}
        </div>
        <div class="flex gap-1">
          <Button @click="openEditModal(ben_owner)" variant="default-gray-outlined" icon>
            <template #icon> <IconEdit class="h-4.5 w-4.5" /> </template>
          </Button>
          <Button
            v-if="!titleTransferStore.entity_template.has_existing_investments"
            @click="openDeleteModal(ben_owner)"
            variant="default-gray-outlined"
            icon
          >
            <template #icon>
              <Icon
                name="Trash"
                class="h-4.5 w-4.5 stroke-functional-error-default"
                :stroke-width="2"
              />
            </template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { IconPlus, IconEdit } from "@/components/icons";
import { Icon } from "@/components";
import { Button } from "@/components";
import AddBeneficiaryModal from "./AddBeneficiaryModal.vue";
import _ from "lodash";

const beneficial_owner_modal_open = ref(false);

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();
function openEditModal(ben_owner) {
  if (ben_owner) {
    titleTransferStore.ben_owner_modal_page = 2;
    titleTransferStore.current_beneficial_owner_for_modal = _.cloneDeep(ben_owner);
  } else {
    titleTransferStore.ben_owner_modal_page = 1;
    titleTransferStore.ben_owner_template = schemaStore.createBenOwnerTemplate();
  }
  beneficial_owner_modal_open.value = true;
}
function openDeleteModal(ben_owner) {
  let index = titleTransferStore.entity_template.beneficial_owners.findIndex(
    (owner) => owner._id === ben_owner._id
  );
  if (index > -1) {
    //then remove it from the array
    titleTransferStore.entity_template.beneficial_owners.splice(index, 1);
  }
}
</script>
