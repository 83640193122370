<template>
  <div :class="[bannerStyles]">
    <Icon
      :name="customIconName ? customIconName : bannerIcon"
      :stroke-width="2"
      :class="iconStyles"
    />
    <slot name="content"></slot>
    <Icon
      v-if="!hideClose"
      name="X"
      class="absolute right-3 top-3 cursor-pointer text-neutral-gray-8"
      @click="$emit('close')"
      :stroke-width="2"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";

import { Icon } from "@/components";

const props = defineProps({
  type: {
    type: String,
    default: "info",
  },
  hideClose: {
    type: Boolean,
    default: false,
  },
  customIconName: {
    type: String,
    default: "",
  },
  iconTop: {
    type: Boolean,
    default: false,
  },
  hideIconOnMobile: {
    type: Boolean,
    default: false,
  },
});

const bannerStyles = computed(() => {
  let baseClass = `w-full rounded-md border relative flex p-3 gap-4 h-auto ${props.iconTop ? "items-start" : "items-center"}`;

  switch (props.type) {
    case "info":
      return `${baseClass} border-cTeal border-opacity-30 bg-cTeal bg-opacity-10`;
    case "warning":
      return `${baseClass} border-secondary-6 bg-secondary-1`;
    case "error":
      return `${baseClass} border-functional-error-default bg-functional-error-default bg-opacity-10`;
    case "error":
      return `${baseClass} border-functional-success-default bg-functional-success-default bg-opacity-10`;
    default:
      return `${baseClass} border-cTeal bg-cTeal bg-opacity-10`;
  }
});

const bannerIcon = computed(() => {
  switch (props.type) {
    case "info":
      return `MessageSquare`;
    case "warning":
      return `MessageSquareWarning`;
    case "error":
      return `TriangleAlert`;
    default:
      return `MessageSquare`;
  }
});

const iconStyles = computed(() => {
  switch (props.type) {
    case "info":
      return `text-cTeal text-opacity-40 min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "warning":
      return `text-secondary-6 min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "error":
      return `text-functional-error-default min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    case "success":
      return `text-functional-success-default min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
    default:
      return `text-cTeal text-opacity-40 min-h-5 min-w-5 ${props.hideIconOnMobile ? "hidden md:block" : ""}`;
  }
});
</script>
