<template>
  <Doughnut
    v-if="!UIStore.isChartLoading"
    :class="'h-96 w-full'"
    id="doughnut-chart"
    :options="chartOptionsDynamic"
    :data="chartDataDynamic"
  />
  <div v-else class="flex min-h-50 items-center justify-center p-10">
    <LoadingSpinner :show="UIStore.isChartLoading" hideOverlay position="relative" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed, shallowRef } from "vue";
import { Button, LoadingSpinner } from "@/components";
import { useUIStore } from "@/stores";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

const UIStore = useUIStore();

const chartOptionsDynamic = computed(() => {
  return props.chartOptions;
});

const chartDataDynamic = computed(() => {
  return JSON.parse(JSON.stringify(props.chartData));
});

const props = defineProps({
  parent: String,
  chartData: Object,
  chartOptions: Object,
});
</script>
