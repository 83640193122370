<template>
  <AddEditOfferingModal
    @close-modal="addEditOfferingModalOpen = false"
    @refetch-offerings="handleRefetchOfferings"
    :open="addEditOfferingModalOpen"
    :offeringForEdit="selectedOfferingForEdit"
    :isEdit="isOfferingEdit"
    :isClone="isOfferingClone"
    hideModalTabs
  />
  <div v-if="!page_is_loading" class="px-0 sm:px-10 max-w-[100vw] py-5">
    <AllRecordsTable
      :customData="{ header_name: 'Offerings', icon_name: 'Handshake', bg_color: 'bg-cMagenta' }"
      actions_always_sticky
      keep_actions_on_sort
    >
      <template v-slot:table-ctas="entry">
        <div class="flex items-center justify-center gap-2">
          <Button @click="editOffering(entry)" size="small" variant="default-gray-outlined" icon>
            <template #icon>
              <Icon name="Pencil" class="h-4 w-4" />
            </template>
          </Button>
          <Button @click="cloneOffering(entry)" size="small" variant="default-gray-outlined" icon>
            <template #icon>
              <Icon name="BookCopy" class="h-4 w-4" />
            </template>
          </Button>
        </div>
      </template>
      <template v-slot:additional-ctas>
        <div class="flex flex-row items-center ml-2">
          <Button
            variant="default-gray-outlined"
            isIconOnlyOnMobile
            icon
            label
            @click="
              (selectedOfferingForEdit = null),
                (isOfferingEdit = false),
                (isOfferingClone = false),
                (addEditOfferingModalOpen = true)
            "
          >
            <template #icon><Icon class="w-4 h-4" name="Plus" /></template>
            <template #label>Add Offering</template>
          </Button>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <!-- LOADING -->
  <div v-else-if="page_is_loading">
    <LoadingSpinner :show="page_is_loading" hideOverlay />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useCrudStore, useRecordDetailStore, useUIStore, useSchemaStore } from "@/stores";
import { Button, LoadingSpinner, AllRecordsTable, Icon } from "@/components";
import AddEditOfferingModal from "./AddEditOfferingModal.vue";

const schemaStore = useSchemaStore();
const crudStore = useCrudStore();
const recordDetailStore = useRecordDetailStore();
const UIStore = useUIStore();

const page_is_loading = ref(false);
const addEditOfferingModalOpen = ref(false);
const selectedOfferingForEdit = ref(null);
const isOfferingClone = ref(false);
const isOfferingEdit = ref(false);

onMounted(async () => await onMountedFunction());

watch(
  () => recordDetailStore.allRecordsPageNumber,
  async () => {
    await recordDetailStore.fetchTableRecords(true);
  }
);

watch(
  () => recordDetailStore.updateTic,
  async () => {
    await onMountedFunction();
  }
);

async function onMountedFunction() {
  try {
    UIStore.sortHeader = { field_name: "created_date" };
    UIStore.sortAscending = false;
    page_is_loading.value = true;
    recordDetailStore.currentPageCollection = "Offerings";
    recordDetailStore.filterPipeline = null;
    recordDetailStore.defaultFilterPipeline = null;

    schemaStore.all_offering_schema = await crudStore.find(
      "Schema",
      {
        collection_name: "Offerings",
        "record_detail_config.default.record_detail_group": "Details",
      },
      null,
      { "record_detail_config.default.record_detail_group_order": 1 }
    );
    await recordDetailStore.getPossibleColumns("Offerings");
    await recordDetailStore.fetchTableRecords(true);
  } catch (err) {
    console.error(err);
  } finally {
    page_is_loading.value = false;
  }
}

function editOffering(row) {
  selectedOfferingForEdit.value = {};
  isOfferingEdit.value = true;
  Object.entries(row?.row?.entry)?.map(
    ([key, value]) => (selectedOfferingForEdit.value[key] = value?.value)
  );
  addEditOfferingModalOpen.value = true;
}

function cloneOffering(row) {
  isOfferingClone.value = true;
  isOfferingEdit.value = false;
  selectedOfferingForEdit.value = {};
  Object.entries(row?.row?.entry)?.map(
    ([key, value]) => (selectedOfferingForEdit.value[key] = value?.value)
  );
  addEditOfferingModalOpen.value = true;
}

async function handleRefetchOfferings() {
  await recordDetailStore.fetchTableRecords(true);
}
</script>
