import { v4 as uuidv4 } from "uuid";
import * as Realm from "realm-web";

import { get, isEqual } from "lodash";

import { ALL_50_STATES, MONTHS } from "@/constants";

const {
  BSON: { ObjectId },
} = Realm;

export function applySchema(record, schema) {
  const parsedRecord = { ...record };
  delete parsedRecord._id;
  delete parsedRecord.created_date;

  for (let field of schema) {
    const fieldName = field.field_name;
    const fieldType = field.field_type;
    const numberType = field.number_type;

    if (fieldName in record) {
      // property already exists on record, format value
      let value_to_use = record[fieldName];

      if (fieldType === "date") {
        if (value_to_use) {
          value_to_use = new Date(value_to_use);
        } else {
          value_to_use = null;
        }
      } else if (fieldType == "number") {
        if (numberType == "percentage" && typeof value_to_use === "number") {
          if (value_to_use > 1) {
            value_to_use = value_to_use / 100;
          }
          // set 4 digit precision
          let num4_as_string = value_to_use.toFixed(4);
          value_to_use = parseFloat(num4_as_string);
        } else if (numberType == "decimal" && typeof value_to_use === "number") {
          let num2_as_string = value_to_use.toFixed(2);
          value_to_use = parseFloat(num2_as_string);
        } else if (numberType == "currency") {
          if (typeof value_to_use === "string" && value_to_use.includes(",")) {
            value_to_use = Number(value_to_use.replace(/,/g, ""));
          }
        }
      } else if (field.is_object_id && value_to_use) {
        value_to_use = ObjectId(value_to_use);
      } else if (fieldType === "array" && value_to_use) {
        //TODO: Loop through array and set schema for each item?
      } else if (fieldType === "object" && value_to_use) {
        //TODO: Set schema for fields that are part of a nested object
      } else if (fieldType === "password") {
        value_to_use = btoa(value_to_use);
      }

      if (fieldName === "state" && parsedRecord[fieldName]) {
        value_to_use = value_to_use.value;
      }

      // hard-coded for id_info & suitability_questionnaire temporarily
      if (
        fieldName === "id_info" &&
        parsedRecord[fieldName] !== null &&
        Object.keys(parsedRecord[fieldName]).length > 0
      ) {
        value_to_use = {
          id_number: parsedRecord[fieldName].id_number,
          id_issued_by: parsedRecord[fieldName].id_issued_by,
          id_issued_date: new Date(parsedRecord[fieldName].id_issued_date),
          id_expiration_date: new Date(parsedRecord[fieldName].id_expiration_date),
        };
      }
      if (
        fieldName === "suitability_info" &&
        parsedRecord[fieldName] !== null &&
        Object.keys(parsedRecord[fieldName]).length > 0
      ) {
        const suitabilityInfo = parsedRecord[fieldName];

        const currentIncome =
          typeof suitabilityInfo.current_income === "string"
            ? Number(suitabilityInfo.current_income.replace(/,/g, ""))
            : suitabilityInfo.current_income;

        const currentNetWorth =
          typeof suitabilityInfo.current_net_worth === "string"
            ? Number(suitabilityInfo.current_net_worth.replace(/,/g, ""))
            : suitabilityInfo.current_net_worth;

        const currentLiquidNetWorth =
          typeof suitabilityInfo.current_liquid_net_worth === "string"
            ? Number(suitabilityInfo.current_liquid_net_worth.replace(/,/g, ""))
            : suitabilityInfo.current_liquid_net_worth;

        value_to_use = {
          ...suitabilityInfo,
          current_income: currentIncome,
          current_net_worth: currentNetWorth,
          current_liquid_net_worth: currentLiquidNetWorth,
        };
      }

      parsedRecord[fieldName] = value_to_use;
    } else {
      // property does not exist on record, set default here based on type
      if (fieldType === "date" || fieldType === "object" || field.is_object_id) {
        parsedRecord[fieldName] = null;
      } else if (fieldType === "array" || fieldType === "array_of_ids") {
        parsedRecord[fieldName] = [];
      } else if (fieldType == "number") {
        parsedRecord[fieldName] = 0;
      } else if (fieldType == "boolean") {
        parsedRecord[fieldName] = false;
      } else {
        // this is the default for text, long text and dropdowns
        parsedRecord[fieldName] = "";
      }
    }
  }

  return parsedRecord;
}

export function parseDocumentsFieldsToProperType(documents) {
  function isValidObjectId(value) {
    if (value instanceof ObjectId) {
      return true;
    }
    return typeof value === "string" && value.length === 24 && /^[0-9a-fA-F]{24}$/.test(value);
  }

  function isValidDate(value) {
    // Check if the value is a string and not purely numeric
    return (
      (typeof value === "string" && isNaN(Number(value)) && !isNaN(Date.parse(value))) ||
      value instanceof Date // Check if value is an actual Date object
    );
  }
  function parseObject(obj) {
    const parsedObject = {};

    for (const [key, value] of Object.entries(obj)) {
      if (
        isValidObjectId(value) &&
        key !== "user_id" &&
        key !== "modified_by" &&
        key !== "created_by_id" &&
        key !== "updated_by_id"
      ) {
        parsedObject[key] = ObjectId(value);
      } else if (isValidDate(value)) {
        parsedObject[key] = new Date(value).toISOString().slice(0, 10); // Convert to ISO string for date inputs
      } else if (
        value &&
        (key === "ssn" || key === "ein" || key === "account_number" || key === "routing_number")
      ) {
        parsedObject[key] = atob(value); // Decode base64 string
      } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
        parsedObject[key] = parseObject(value);
      } else if (Array.isArray(value)) {
        parsedObject[key] = value.map((item) => {
          if (isValidObjectId(item)) {
            return ObjectId(item);
          } else if (isValidDate(item)) {
            return new Date(item).toISOString().slice(0, 10);
          } else if (typeof item === "object" && item !== null) {
            return parseObject(item);
          }
          return item;
        });
      } else {
        if (key === "state" && value) {
          const state = ALL_50_STATES.find((state) => state.value === value);
          parsedObject[key] = state;
        } else if ((key === "id_info" || key === "suitability_info") && !value) {
          parsedObject[key] = {};
        } else {
          parsedObject[key] = value;
        }
      }
    }

    return parsedObject;
  }

  return documents.map((document) => parseObject(document));
}

export async function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (file) => {
      resolve(file.target.result.split(",")[1]);
    };

    fileReader.onerror = (error) => reject(error);

    fileReader.readAsDataURL(file);
  });
}

export function debounce(func, wait) {
  let timeout;

  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function formatListHeaders(header) {
  let spacedHeader = header.replace(/_/g, " ");
  return autoCapitalize(spacedHeader);
}

export function autoCapitalize(input) {
  let inputWords = input.split(" ");
  let capitalizedInput = "";
  for (let word of inputWords) {
    if (word !== word.toUpperCase()) {
      // keep all uppercase words
      capitalizedInput += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    } else {
      capitalizedInput += word + " ";
    }
  }
  return capitalizedInput.trim();
}

export function objectCompare(obj1, obj2) {
  for (var p in obj1) {
    if (obj1.hasOwnProperty(p)) {
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
  }
  for (var p in obj2) {
    if (obj2.hasOwnProperty(p)) {
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
  }
  return true;
}

export function removeDuplicates(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export function formatDateStringToDayMonthYear(date) {
  if (isNaN(date)) return "-";
  // Convert UTC date to local date string
  const localDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  const day = localDate.getDate().toString().padStart(2, "0"); // Ensure it's 2 digits
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[localDate.getMonth()];
  const year = localDate.getFullYear();

  return `${day} ${month} ${year}`;
}

export function commaSeparateThousands(num) {
  if (!num) return num;
  // Split the number on the decimal point
  let parts = num.toString().split(".");

  // Add commas to the part before the decimal
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Rejoin the whole number and the decimal part (if it exists)
  return parts.join(".");
}

export function convertToKebabCase(str) {
  return (
    str
      // Insert a dash before each uppercase letter, except the first one
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      // Convert the whole string to lowercase
      .toLowerCase()
  );
}

export function generateAUniqueID() {
  return uuidv4();
}

// TODO: This might be useful for our non-atlas searches if we work out the scoring more
export function scoredSearch(data, searchTerm, options = {}) {
  const { key = "label", preferStart = true } = options;

  const searchTerms = searchTerm?.toLowerCase().split(/\s+/);

  const scoredResults = data.map((item) => {
    const itemValue = item[key]?.toLowerCase();
    let score = 0;

    // Score based on each individual word in the search term
    for (const term of searchTerms) {
      if (itemValue.includes(term)) {
        score += 1;

        // Give higher score if the item starts with the search term
        if (preferStart && itemValue.startsWith(term)) {
          score += 10;
        }
      }
    }

    return {
      ...item,
      score,
    };
  });

  // Sort by score, then by original order for results with the same score
  return scoredResults
    .filter((r) => r.score > 0)
    .sort((a, b) => b.score - a.score || a[key].localeCompare(b[key]))
    .map((r) => {
      delete r.score;
      return r;
    });
}

export function parseRGBA(rgbaString) {
  const regex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/;
  const match = rgbaString.match(regex);

  if (match) {
    return {
      r: parseInt(match[1], 10),
      g: parseInt(match[2], 10),
      b: parseInt(match[3], 10),
      a: parseFloat(match[4]),
    };
  }
  return null;
}

export function utilitySort(data, field, order) {
  if (!Array.isArray(data) || typeof field !== "string" || ![1, -1].includes(order)) {
    throw new Error("Invalid arguments passed to utilitySort.");
  }

  const compare = (a, b) => {
    let aValue = a[field];
    let bValue = b[field];

    // If both values are null or undefined, they are equal
    if (aValue == null && bValue == null) return 0;

    // Null or undefined values should be sorted first in asc sorts
    if (aValue == null) return order === 1 ? -1 : 1;
    if (bValue == null) return order === 1 ? 1 : -1;

    // Check if values are Date objects or strings that can be parsed into dates
    if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    // Now we compare dates, numbers, or strings accordingly
    if (aValue instanceof Date && bValue instanceof Date) {
      return (aValue - bValue) * order;
    } else if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * order;
    } else if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * order;
    }

    // Fallback to default comparison
    if (aValue < bValue) return -1 * order;
    if (aValue > bValue) return 1 * order;
    return 0;
  };

  return [...data].sort(compare);
}

export function isUnixTimestamp(value) {
  // Check if the value is a number and is an integer
  if (typeof value !== "number" || !Number.isInteger(value)) {
    return false;
  }

  // Check the length of the timestamp (milliseconds: 13 digits, seconds: 10 digits)
  const length = value.toString().length;
  if (length !== 10 && length !== 12 && length !== 13) {
    return false;
  }

  const date = new Date(value);
  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return false;
  }

  // Check if the date falls within a reasonable range
  const start = new Date("1940-01-01").getTime();
  const end = new Date("2050-01-01").getTime();
  return value >= start && value <= end;
}

// uses regex to determine matching phone number
// supports numbers with +1 country code prefix (note: doesn't support other country codes, probably should fix
// returns null if string if not valid phone number
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function convertJsonToCsvFileAndDownload(data, fileName) {
  const convertJsonToCsv = (data) => {
    const array = typeof data != "object" ? JSON.parse(data) : data;

    let maxKeysObject = array.reduce((prev, current) => {
      return Object.keys(current).length > Object.keys(prev).length ? current : prev;
    }, {});

    let headers = Object.keys(maxKeysObject).join(",");
    let str = headers + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let header of Object.keys(maxKeysObject)) {
        if (line !== "") line += ",";
        line += array[i][header] ? array[i][header] : "";
      }
      str += line + "\r\n";
    }

    return str;
  };

  const csvData = convertJsonToCsv(data);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function checkIfDate(property) {
  let regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  if (regex.test(property)) {
    const [month, day, year] = property.split("/");
    return new Date(`${year}-${month}-${day}`);
  } else {
    // return raw value.
    return property;
  }
}

export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    var result =
      checkIfDate(a[property]) < checkIfDate(b[property])
        ? -1
        : checkIfDate(a[property]) > checkIfDate(b[property])
          ? 1
          : 0;
    return result * sortOrder;
  };
}

export function parseAsEJSON(object) {
  for (let field in object) {
    if (object[field]?.$numberInt) {
      object[field] = Number(object[field].$numberInt);
    } else if (object[field]?.$numberDouble) {
      object[field] = Number(object[field].$numberDouble);
    } else if (object[field]?.$date) {
      object[field] = new Date(Number(object[field].$date.$numberLong));
    } else if (object[field]?.$oid) {
      object[field] = ObjectId(object[field].$oid);
    }
  }
  return object;
}

export function stripHTML(htmlString) {
  if (htmlString) {
    return this.sanitizeWithRegex(htmlString, /<[^>]*>/g, "replace");
  }
  return htmlString; // returns the original input if it's null or undefined
}

export function sanitizeWithRegex(input, regex, operator = "replace") {
  let previous;
  do {
    previous = input;
    if (operator === "replace") {
      input = input.replace(regex, "");
    } else if (operator === "match") {
      input = input.match(regex);
    }
  } while (input !== previous);
  return input;
}

export function transformRecordsForTable(records) {
  if (records) {
    return records.map((record) => {
      const transformedRecord = {};

      Object.keys(record).forEach((key) => {
        transformedRecord[key] = {
          value: record[key],
          clickable: false,
        };
      });

      transformedRecord.actions = { cta: true, hide_sort: true };

      return transformedRecord;
    });
  }
}

// function that turns an integer in months into a string in years and months
export function monthsToYears(months) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  if (years === 0) {
    return `${remainingMonths} months`;
  } else if (remainingMonths === 0) {
    return `${years} year${years > 1 ? "s" : ""}`;
  } else {
    return `${years} years and ${remainingMonths} months`;
  }
}

export function getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField) {
  // Helper to create a local date at the start of a given date
  function startOfDayLocal(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  // Helper to create a local date at the end of a given date
  function endOfDayLocal(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  }

  // Helper to create a UTC date at the start of a given local date
  function startOfDayUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  // Helper to create a UTC date at the end of a given local date
  function endOfDayUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
  }

  // Helper to subtract days from a given local date
  function subtractDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  // Helper to add days to a given local date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function getLastQuarter() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based, so we add 1

    let startMonth, endMonth, year;

    // Determine the last quarter based on the current month
    if (currentMonth >= 1 && currentMonth <= 3) {
      // Q1 (January - March)
      startMonth = 10; // October of previous year
      endMonth = 12; // December of previous year
      year = currentDate.getFullYear() - 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      // Q2 (April - June)
      startMonth = 1; // January
      endMonth = 3; // March
      year = currentDate.getFullYear();
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      // Q3 (July - September)
      startMonth = 4; // April
      endMonth = 6; // June
      year = currentDate.getFullYear();
    } else {
      // Q4 (October - December)
      startMonth = 7; // July
      endMonth = 9; // September
      year = currentDate.getFullYear();
    }

    // Create date objects for the start and end of the last quarter
    const startDate = new Date(year, startMonth - 1, 1); // Months are 0-based
    const endDate = new Date(year, endMonth, 0); // 0 gives the last day of the previous month

    return { $gte: startDate, $lte: endDate };
  }

  // Choose the appropriate start and end of day functions based on the isLocalTimeField flag
  const startOfDay = isLocalTimeField ? startOfDayLocal : startOfDayUTC;
  const endOfDay = isLocalTimeField ? endOfDayLocal : endOfDayUTC;

  const now = new Date();
  const dayOfWeek = now.getDay();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const startOfThisWeek = startOfDay(subtractDays(now, dayOfWeek));
  const endOfThisWeek = endOfDay(addDays(startOfThisWeek, 6)); // Note: Fixed to 6 days to correctly represent "This Week"

  // Define date ranges
  const ranges = {
    Today: { $gte: startOfDay(now), $lte: endOfDay(now) },
    Yesterday: {
      $gte: startOfDay(subtractDays(now, 1)),
      $lte: endOfDay(subtractDays(now, 1)),
    },
    Tomorrow: { $gte: startOfDay(addDays(now, 1)), $lte: endOfDay(addDays(now, 1)) },
    "This Week": { $gte: startOfThisWeek, $lte: endOfThisWeek },
    "This Month": {
      $gte: startOfDay(new Date(currentYear, currentMonth, 1)),
      $lte: endOfDay(new Date(currentYear, currentMonth + 1, 0)),
    },
    "This Year": {
      $gte: startOfDay(new Date(currentYear, 0, 1)),
      $lte: endOfDay(new Date(currentYear, 11, 31)),
    },
    "Last Week": {
      $gte: startOfDay(subtractDays(startOfThisWeek, 7)),
      $lte: endOfDay(subtractDays(endOfThisWeek, 7)),
    },
    "Last Month": {
      $gte: startOfDay(new Date(currentYear, currentMonth - 1, 1)),
      $lte: endOfDay(new Date(currentYear, currentMonth, 0)),
    },
    "Next Week": {
      $gte: startOfDay(addDays(endOfThisWeek, 1)),
      $lte: endOfDay(addDays(endOfThisWeek, 7)),
    },
    "Next Month": {
      $gte: startOfDay(new Date(currentYear, currentMonth + 1, 1)),
      $lte: endOfDay(new Date(currentYear, currentMonth + 2, 0)),
    },
    "Last 7 Days": { $gte: startOfDay(subtractDays(now, 7)), $lte: endOfDay(now) },
    "Last 14 Days": { $gte: startOfDay(subtractDays(now, 14)), $lte: endOfDay(now) },
    "Last 30 Days": { $gte: startOfDay(subtractDays(now, 30)), $lte: endOfDay(now) },
    "Last 60 Days": { $gte: startOfDay(subtractDays(now, 60)), $lte: endOfDay(now) },
    "Last 90 Days": { $gte: startOfDay(subtractDays(now, 90)), $lte: endOfDay(now) },
    "Last 6 Months": {
      $gte: startOfDay(subtractDays(now, 180)),
      $lte: endOfDay(now),
    },
    "Last 12 Months": {
      $gte: startOfDay(subtractDays(now, 365)),
      $lte: endOfDay(now),
    },
    "Last Year": {
      $gte: startOfDay(new Date(currentYear - 1, 0, 1)),
      $lte: endOfDay(new Date(currentYear - 1, 11, 31)),
    },
    "Next 7 Days": { $gte: startOfDay(now), $lte: endOfDay(addDays(now, 7)) },
    "Next 14 Days": { $gte: startOfDay(now), $lte: endOfDay(addDays(now, 14)) },
    "Next 30 Days": { $gte: startOfDay(now), $lte: endOfDay(addDays(now, 30)) },
    "Next 60 Days": { $gte: startOfDay(now), $lte: endOfDay(addDays(now, 60)) },
    "Next 90 Days": { $gte: startOfDay(now), $lte: endOfDay(addDays(now, 90)) },
    "Next 6 Months": {
      $gte: startOfDay(now),
      $lte: endOfDay(addDays(now, 180)),
    },
    "Next 12 Months": {
      $gte: startOfDay(now),
      $lte: endOfDay(addDays(now, 365)),
    },
    "Next Year": {
      $gte: startOfDay(new Date(currentYear + 1, 0, 1)),
      $lte: endOfDay(new Date(currentYear + 1, 11, 31)),
    },
    "Last Quarter": getLastQuarter(),
  };

  return ranges[relativeValue] || {};
}

export function flattenSchema(schema, config = "default") {
  // Flatten the schema
  const flattened = schema.map((item) => {
    // Destructure the item to separate record_detail_config and the rest of the fields
    const { record_detail_config, ...rest } = item;

    // Extract the required config or default if not provided
    const selectedConfig = record_detail_config[config] || record_detail_config["default"];

    // Merge the selected config fields into the rest of the item fields
    return {
      ...rest,
      ...selectedConfig,
    };
  });

  // Sort the flattened array based on record_detail_group_order
  flattened.sort((a, b) => (a.record_detail_group_order || 0) - (b.record_detail_group_order || 0));

  // Return the sorted array
  return flattened;
}

export function validateField(fieldName, value) {
  if (!value) return false; // Check for truthiness

  const validationRules = {
    phone: (value) => /^\(\d{3}\) \d{3}-\d{4}$/.test(value),
    ssn: (value) => /^\d{3}-\d{2}-\d{4}$/.test(value),
    email: (value) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
    ein: (value) => /^\d{2}-\d{7}$/.test(value),
    amount: (value) => /^\d{1,3}(,\d{3})*$/.test(value),
    // Add other validation rules as needed
  };

  const validationRule = validationRules[fieldName];
  if (validationRule) {
    return validationRule(value);
  }

  return true;
}

export function commaSeparateThousands_2(num) {
  const isNegative = num < 0;

  const formattedNumber = Math.abs(num)
    .toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return isNegative ? `-${formattedNumber}` : formattedNumber;
}

export function getObjectDifferences(obj1, obj2) {
  const differences = {};

  function compareObjects(o1, o2, path = "") {
    Object.keys(o1).forEach((key) => {
      const fullPath = path ? `${path}.${key}` : key;

      if (typeof o1[key] === "object" && o1[key] !== null && !Array.isArray(o1[key])) {
        compareObjects(o1[key], o2[key], fullPath);
      } else if (!isEqual(o1[key], o2[key])) {
        differences[fullPath] = { original: o1[key], current: o2[key] };
      }
    });
  }

  compareObjects(obj1, obj2);

  return differences;
}

export function isValidObjectId(value) {
  if (value instanceof ObjectId) {
    return true;
  }
  return typeof value === "string" && value.length === 24 && /^[0-9a-fA-F]{24}$/.test(value);
}

export function sanitizeJSONAndDownloadCSV(headers, data, fileName) {
  function sanitizeData(data) {
    return data.map((item) => {
      const sanitizedItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          let value = item[key];

          // Convert to string if not null/undefined
          if (value !== null && value !== undefined) {
            value = value.toString();

            // Remove new lines and carriage returns
            value = value.replace(/\r?\n|\r/g, " ");

            // Escape double quotes
            value = value.replace(/"/g, '""');

            // Remove non-ASCII characters
            value = value.normalize("NFKD").replace(/[^\x00-\x7F]/g, "");

            // Wrap value in double quotes if it contains commas
            if (value.includes(",")) {
              value = `"${value}"`;
            }
          } else {
            value = ""; // Replace null/undefined with an empty string
          }

          sanitizedItem[key] = value;
        }
      }
      return sanitizedItem;
    });
  }

  function convertJsonToCsv(headers, data) {
    // Convert headers object to CSV string
    const headerString = Object.values(headers).join(",") + "\r\n";

    // Convert data to CSV string
    const dataString = data
      .map((row) => {
        return Object.keys(headers)
          .map((headerKey) => row[headerKey] || "")
          .join(",");
      })
      .join("\r\n");

    return headerString + dataString;
  }
  // Sanitize the data
  const sanitizedData = sanitizeData(data);

  // Convert JSON to CSV
  const csvContent = convertJsonToCsv(headers, sanitizedData);

  // Create a blob from the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a link element and trigger a download
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function fillMissingMonths(data) {
  const result = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  for (let i = 0; i < data.length - 1; i++) {
    let current = data[i];
    let next = data[i + 1];

    // Add the first object to the result
    if (i === 0) {
      //result.push(current);
    }

    let currentYear = current.year;
    let currentMonth = parseInt(current.month, 10);

    // Generate missing months between current and next
    while (currentYear < next.year || (currentYear === next.year && currentMonth < next.month)) {
      result.push({
        runningTotal: current.runningTotal,
        year: currentYear,
        month: currentMonth < 10 ? "0" + currentMonth : currentMonth.toString(), // Format month with leading zero if necessary
      });

      currentMonth++;
      if (currentMonth > 12) {
        currentMonth = 1;
        currentYear++;
      }
    }
  }

  // Add the last object to the result
  result.push(data[data.length - 1]);

  // Extend to the current month
  let last = result[result.length - 1];

  let lastYear = last.year;
  let lastMonth = parseInt(last.month, 10);

  while (lastYear < currentYear || (lastYear === currentYear && lastMonth < currentMonth)) {
    lastMonth++;
    if (lastMonth > 12) {
      lastMonth = 1;
      lastYear++;
    }
    // result.push({
    //   runningTotal: last.runningTotal,
    //   year: lastYear,
    //   month: lastMonth < 10 ? "0" + lastMonth : lastMonth.toString(), // Format month with leading zero if necessary
    // });
  }

  return result;
}

export function convertToLabels(arr) {
  return arr.map((obj) => {
    const monthIndex = parseInt(obj.month, 10) - 1; // Convert month to zero-based index
    const monthName = MONTHS[monthIndex];
    return `${monthName} ${obj.year}`;
  });
}

export function createDocumentWithFieldOrder(currentDoc, schema, order) {
  const getDefaultFieldValue = (fieldType) => {
    switch (fieldType) {
      case "string":
      case "dropdown":
        return "";
      case "number":
        return 0;
      case "boolean":
        return false;
      case "array_of_ids":
      case "array":
        return [];
      case "date":
      case "object":
        return null;
      default:
        return "";
    }
  };
  let doc = {};
  for (let fieldName of order) {
    let field = schema.find((s) => s.field_name === fieldName);
    if (field) {
      doc[field.field_name] =
        currentDoc[field.field_name] ||
        field.record_detail_config.default.default_value ||
        getDefaultFieldValue(field.field_type);
    }
  }
  // Add remaining fields
  for (let field of schema) {
    if (!(field.field_name in doc)) {
      doc[field.field_name] =
        currentDoc[field.field_name] ||
        field.record_detail_config.default.default_value ||
        getDefaultFieldValue(field.field_type);
    }
  }
  return doc;
}
