<template>
  <div
    class="no-scrollbar flex min-h-12 items-center w-screen max-w-[100vw] overflow-x-auto border-b border-platform-outlines bg-neutral-gray-1 lg:max-w-[calc(100vw-3.688rem)]"
    @drop="dropOnEmptySpace"
    @dragover.prevent
    @dragleave="dragLeave()"
    @scroll="hideAllTabMenus(), handleScroll()"
    ref="scrollContainer"
  >
    <Tab v-for="(tab, index) in UIStore.tabs" :key="tab.id" :tab="tab" :index="index" />
    <div
      v-if="showScrollLeft"
      class="fixed left-0 top-0 z-50 mt-14 flex h-12 items-center lg:ml-value-1"
    >
      <button
        type="button"
        @click="scrollLeft"
        class="flex h-9.5 w-12 items-center justify-center bg-white"
      >
        <IconChevronLeft class="h-5 w-5" />
      </button>
      <div class="gradient-right h-9.5 w-8"></div>
    </div>
    <div v-if="showScrollRight" class="fixed right-0 top-0 z-50 mt-14 flex h-12 items-center">
      <div class="gradient-left h-9.5 w-8"></div>
      <button
        type="button"
        @click="scrollRight"
        class="flex h-9.5 w-12 items-center justify-center bg-white"
      >
        <IconChevronRight class="h-5 w-5" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { useUIStore, useAuthStore, useCrudStore } from "@/stores";
import { IconChevronLeft, IconChevronRight } from "@/components/icons";
import { useGradientScroll } from "@/composables/useGradientScroll";
import Tab from "./Tab.vue";

const { scrollContainer, showScrollRight, showScrollLeft, scrollRight, scrollLeft, handleScroll } =
  useGradientScroll({ isLongScroll: true });

const router = useRouter();

const UIStore = useUIStore();
const authStore = useAuthStore();

onMounted(() => {
  window.addEventListener("resize", hideAllTabMenus);
});

onUnmounted(() => {
  window.removeEventListener("resize", hideAllTabMenus);
});

watch(
  () => router.currentRoute.value,
  (route) => {
    const updatedTabs = UIStore.tabs.map((tab) => {
      if (tab.path === route.fullPath) {
        return { ...tab, is_active: true };
      } else {
        return { ...tab, is_active: false };
      }
    });
    UIStore.tabs = updatedTabs;
  },
  { immediate: true }
);

function hideAllTabMenus() {
  UIStore.tabs = UIStore.tabs.map((tab) => ({
    ...tab,
    is_show_options: false,
  }));
}

const dragLeave = () => {
  UIStore.overEmptySpace_tab = true;
};

const dropOnEmptySpace = async (event) => {
  if (UIStore.overEmptySpace_tab) {
    event.preventDefault();

    // Get data from event.dataTransfer
    const data = JSON.parse(event.dataTransfer.getData("text"));
    const draggedTab = UIStore.tabs[data.currentDraggingIndex];

    // Remove the tab from its original position
    UIStore.tabs.splice(data.currentDraggingIndex, 1);

    if (draggedTab.is_pinned) {
      // Find the last index of the pinned tabs
      const lastPinnedIndex = UIStore.tabs.reduce((lastIndex, tab, index) => {
        return tab.is_pinned ? index : lastIndex;
      }, -1);

      // Insert the tab after the last pinned tab
      UIStore.tabs.splice(lastPinnedIndex + 1, 0, draggedTab);
    } else {
      // If the tab is not pinned, insert it at the end of the array
      UIStore.tabs.push(draggedTab);
    }

    // Update pin order if necessary
    if (draggedTab.is_pinned) {
      await updatePinOrder();
    }
  }
};

const updatePinOrder = async () => {
  const updatedTabs = UIStore.tabs.map((tab, index) => {
    const updatedTab = { ...tab };
    if (tab.is_pinned) {
      updatedTab.pin_order = index;
    }

    delete updatedTab.is_active;
    delete updatedTab.is_show_options;
    return updatedTab;
  });

  let update = {
    $set: {
      tabs: updatedTabs,
    },
  };

  await crudStore.updateOne(
    "UserTabs",
    {
      user_id: authStore.currentUserDocument.user_id,
    },
    update
  );
};
</script>
