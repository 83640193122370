<template>
  <button
    @click="handleClick"
    :class="[baseClass, sizeClass, variantClass, iconClass, disabledClass]"
    :disabled="disabled"
    type="button"
  >
    <slot name="icon" />
    <div
      :class="[
        isIconOnlyOnMobile ? 'hidden md:inline-flex' : '',
        isIconOnlyOnTablet ? 'hidden lg:inline-flex' : '',
      ]"
    >
      <slot name="label" />
    </div>
  </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  label: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "medium",
  },
  variant: {
    type: String,
    default: "default-filled",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isIconOnlyOnMobile: {
    type: Boolean,
    default: false,
  },
  isIconOnlyOnTablet: {
    type: Boolean,
    default: false,
  },
  propagateClick: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

const handleClick = (event) => {
  if (!props.propagateClick) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }
  emit("click", event);
};

const baseClass = "flex items-center justify-center transition whitespace-nowrap";

const sizeClass = computed(() => {
  const baseSizeClass =
    props.icon && !props.label
      ? "h-9 min-w-9 rounded"
      : props.icon && props.label && props.isIconOnlyOnMobile
        ? "h-9 min-w-9 rounded gap-1 text-xs md:w-auto md:px-2 gap-1 text-xs"
        : props.icon && props.label
          ? "h-9 px-2 rounded gap-1 text-xs"
          : "h-9 px-4 rounded gap-1 text-xs";

  const sizeClasses = {
    small:
      props.icon && !props.label
        ? "h-7 min-w-7 rounded"
        : props.icon && props.label && props.isIconOnlyOnMobile
          ? "h-7 min-w-7 rounded text-xs md:w-auto md:px-3 text-xs gap-2"
          : props.icon && props.label
            ? "h-7 px-3 rounded text-xs gap-2"
            : "h-7 px-3 rounded text-xs",
    medium: baseSizeClass,
    large:
      props.icon && !props.label
        ? "h-10.5 min-w-10.5 rounded-md"
        : props.icon && props.label && props.isIconOnlyOnMobile
          ? "h-10.5 min-w-10.5 md:w-auto md:px-3 text-sm rounded-md gap-2 "
          : props.icon && props.label
            ? "h-10.5 px-3 text-base rounded-md gap-2 "
            : "h-10.5 px-4 text-base rounded-md",
    smallToMedium:
      props.icon && !props.label
        ? "h-7 min-w-7 md:min-w-5 md:h-9 rounded"
        : props.icon && props.label && props.isIconOnlyOnMobile
          ? "h-7 min-w-7 md:h-9 rounded md:w-auto md:px-2 gap-1 text-xs"
          : props.icon && props.label
            ? "h-7 px-3 md:h-9 md:px-2 rounded md:gap-1 gap-2 text-xs"
            : "h-7 px-3 md:h-9 md:px-4 rounded text-xs",
    mediumToLarge:
      props.icon && !props.label
        ? "h-9 min-w-9 md:h-10.5 md:min-w-10.5 rounded md:rounded-md"
        : props.icon && props.label && props.isIconOnlyOnMobile
          ? "h-9 min-w-9 md:h-10.5 md:w-auto md:px-3 md:py-value-9 rounded md:rounded-md gap-1 text-xs md:gap-2 md:text-sm"
          : props.icon && props.label && props.isIconOnlyOnTablet
            ? "h-9 min-w-9 md:h-10.5 md:min-w-10.5 lg:px-3 lg:py-value-9 rounded md:rounded-md gap-1 text-xs md:gap-2 md:text-sm"
            : props.icon && props.label
              ? "h-9 px-2 md:px-3 md:py-[.6875rem] md:h-10.5 rounded md:rounded-md gap-1 md:gap-2 text-xs md:text-sm"
              : "h-9 px-4 rounded md:rounded-md gap-1 md:gap-2 text-xs md:text-sm",
  };
  return sizeClasses[props.size] || baseSizeClass;
});

const iconClass = computed(() => {
  const iconClasses = {
    subtle: "fill-primary-6  disabled:fill-neutral-gray-5",
    "default-filled": "fill-white disabled:fill-neutral-gray-5",
    "error-filled": "fill-white disabled:fill-neutral-gray-5",
    "default-color-outlined": "fill-primary-6 disabled:fill-neutral-gray-5",
    "default-gray-outlined": "fill-primary-6 disabled:fill-neutral-gray-1",
    "warning-filled": "fill-primary-6 disabled:fill-neutral-gray-5",
    "warning-color-outlined": "fill-secondary-6 disabled:fill-neutral-gray-5",
    "warning-color-outlined-text": "fill-secondary-6 disabled:fill-neutral-gray-5",
    "warning-gray-outlined": "fill-secondary-6 disabled:fill-neutral-gray-5",
    "error-color-outlined": "fill-functional-error-default",
    "error-color-outlined-text": "fill-functional-error-default disabled:fill-neutral-gray-5",
    "error-gray-outlined": "fill-functional-error-default disabled:fill-neutral-gray-5",
    "custom-accordion-button": "fill-primary-2 ",
  };
  return iconClasses[props.variant] || "fill-white";
});

const variantClass = computed(() => {
  const baseVariantClass =
    "border border-primary-6 bg-primary-6 hover:bg-primary-7 active:bg-primary-8 text-white ";
  const variantClasses = {
    subtle:
      "border border-neutral-gray-4 bg-neutral-gray-4 hover:bg-neutral-gray-5 active:bg-neutral-gray-6 text-primary box-border",
    "white-bordered":
      "border border-neutral-gray-4 bg-white hover:bg-neutral-gray-4 active:bg-neutral-gray-5 text-primary box-border",
    "default-filled": baseVariantClass,
    "default-color-outlined":
      "border border-primary-6 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-primary box-border",
    "default-gray-outlined":
      "border border-neutral-gray-5 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-primary box-border",
    "warning-filled":
      "border border-functional-warning-default bg-functional-warning-default hover:bg-functional-warning-hover active:bg-functional-warning-click text-primary box-border",
    "warning-color-outlined":
      "border border-secondary-6 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-primary box-border",
    "warning-color-outlined-big-border":
      "border-2 border-secondary-6 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-primary box-border",
    "warning-color-outlined-text":
      "border border-secondary-6 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-secondary-6 box-border",
    "warning-gray-outlined":
      "border border-neutral-gray-5 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-secondary-6 box-border",
    "error-filled":
      "border border-functional-error-default bg-functional-error-default hover:bg-functional-error-hover active:bg-functional-error-click text-white box-border",
    "error-color-outlined":
      "border border-functional-error-default bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-primary box-border",
    "error-color-outlined-text":
      "border border-functional-error-default bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-functional-error-default box-border",
    "error-gray-outlined":
      "border border-neutral-gray-5 bg-neutral-gray-1 hover:bg-button-primary-hover active:bg-button-primary-press text-functional-error-default box-border",
    "custom-accordion-button": "  bg-none  ",
  };
  return variantClasses[props.variant] || baseVariantClass;
});

const disabledClass = computed(() => {
  // Default disabled class
  const defaultDisabled =
    "disabled:bg-button-primary-hover disabled:cursor-not-allowed disabled:text-white disabled:border-neutral-gray-4";

  // Disabled class for error variants
  const errorDisabled = "disabled:cursor-not-allowed disabled:opacity-20";

  // Check if the variant contains the word "error"
  if (props.variant.includes("error")) {
    return errorDisabled;
  }
  return defaultDisabled;
});
</script>
