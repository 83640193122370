export default [
  //{ id: 0, name: "Select a State" },
  { id: 1, abbr: "AL", name: "Alabama" },
  { id: 2, abbr: "AK", name: "Alaska" },
  { id: 3, abbr: "AZ", name: "Arizona" },
  { id: 4, abbr: "AR", name: "Arkansas" },
  { id: 5, abbr: "CA", name: "California" },
  { id: 6, abbr: "CO", name: "Colorado" },
  { id: 7, abbr: "CT", name: "Connecticut" },
  { id: 8, abbr: "DE", name: "Delaware" },
  { id: 9, abbr: "FL", name: "Florida" },
  { id: 10, abbr: "GA", name: "Georgia" },
  { id: 11, abbr: "HI", name: "Hawaii" },
  { id: 12, abbr: "ID", name: "Idaho" },
  { id: 13, abbr: "IL", name: "Illinois" },
  { id: 14, abbr: "IN", name: "Indiana" },
  { id: 15, abbr: "IA", name: "Iowa" },
  { id: 16, abbr: "KS", name: "Kansas" },
  { id: 17, abbr: "KY", name: "Kentucky" },
  { id: 18, abbr: "LA", name: "Lousiana" },
  { id: 19, abbr: "ME", name: "Maine" },
  { id: 20, abbr: "MD", name: "Maryland" },
  { id: 21, abbr: "MA", name: "Massachusetts" },
  { id: 22, abbr: "MI", name: "Michigan" },
  { id: 23, abbr: "MN", name: "Minnesota" },
  { id: 24, abbr: "MS", name: "Mississippi" },
  { id: 25, abbr: "MO", name: "Missouri" },
  { id: 26, abbr: "MT", name: "Montana" },
  { id: 27, abbr: "NE", name: "Nebraska" },
  { id: 28, abbr: "NV", name: "Nevada" },
  { id: 29, abbr: "NH", name: "New Hampshire" },
  { id: 30, abbr: "NJ", name: "New Jersey" },
  { id: 31, abbr: "NM", name: "New Mexico" },
  { id: 32, abbr: "NY", name: "New York" },
  { id: 33, abbr: "NC", name: "North Carolina" },
  { id: 34, abbr: "ND", name: "North Dakota" },
  { id: 35, abbr: "OH", name: "Ohio" },
  { id: 36, abbr: "OK", name: "Oklahoma" },
  { id: 37, abbr: "OR", name: "Oregon" },
  { id: 38, abbr: "PA", name: "Pennsylvania" },
  { id: 39, abbr: "RI", name: "Rhode Island" },
  { id: 40, abbr: "SC", name: "South Carolina" },
  { id: 41, abbr: "SD", name: "South Dakota" },
  { id: 42, abbr: "TN", name: "Tennessee" },
  { id: 43, abbr: "TX", name: "Texas" },
  { id: 44, abbr: "UT", name: "Utah" },
  { id: 45, abbr: "VT", name: "Vermont" },
  { id: 46, abbr: "VA", name: "Virginia" },
  { id: 47, abbr: "WA", name: "Washington" },
  { id: 48, abbr: "WV", name: "West Virginia" },
  { id: 49, abbr: "WI", name: "Wisconsin" },
  { id: 50, abbr: "WY", name: "Wyoming" },
];
