import * as Realm from "realm-web";
import { defineStore } from "pinia";
import {
  useCollectionStore,
  useUIStore,
  useAuthStore,
  useCrudStore,
  useInvestmentStore,
  useInvestorStore,
  useCreateInvestmentStore,
  useDataModelManagementStore,
  useTitleTransferStore,
  useSchemaStore,
} from "@/stores";

import {
  commaSeparateThousands,
  convertJsonToCsvFileAndDownload,
  objectCompare,
  transformRecordsForTable,
  autoCapitalize,
} from "@/utilities";
import _ from "lodash";
// import { list } from "postcss";
// import { ssrContextKey } from "vue";

const {
  BSON: { ObjectId },
} = Realm;

export const useRecordDetailStore = defineStore("recordDetailStore", {
  state: () => ({
    currentPageCollection: "",
    currentPageConfig: {},
    currentPageDocument: {},
    investment_has_paid_distributions: false,
    currentPageDetailSections: [],
    currentPageLayout: {},
    invalidContactPage: false,
    directToPage: "",
    allRecordsTableHeaders: [],
    allRecords: [],
    allRecordsPageNumber: 1,
    pageSize: 15,
    filterPipeline: null,
    pageLoaderMessage: "Loading...",
    tableRecordCount: 0,
    fetchedAccordionData: null,
    fetched_and_set_settings: null,
    updateTic: 0,

    // record type settings
    defaultFilterPipeline: null,

    // filtering
    activeFilterCetegory: "",
    possibleColumns: [],

    //title transfer fields for banner on investment page
    fetched_transfer_children: [],
    fetched_transfer_parents: [],
    transfer_is_disabled: false,

    //investment specific
    fetched_distributions_for_investment_earnings_tab: [],
    investment_earnings_tab_headers: [],

    //document landing page
    fileIsLoading: false,
    preview_csv_headers: [],
    preview_csv_data: [],
    file_preview_url: "",
    current_preview_file: {},
    file_details_schema: [],
  }),

  actions: {
    async setCurrentPageConfig() {
      const crudStore = useCrudStore();
      const investmentStore = useInvestmentStore();
      const schemaStore = useSchemaStore();
      const UIStore = useUIStore();

      const [currentPageConfig, currentPageLayout] = await Promise.all([
        crudStore.findOne("Settings", { collection: this.currentPageCollection }),
        crudStore.findOne("PageLayouts", { collection_name: this.currentPageCollection }),
      ]);

      this.currentPageConfig = currentPageConfig;
      this.currentPageLayout = currentPageLayout;

      console.log("currentPageConfig", this.currentPageConfig);

      if (this.currentPageConfig.primary_key.includes("+")) {
        this.currentPageConfig.primary_key = this.currentPageConfig.primary_key
          .split("+")
          .map((key) => key.trim());
      } else {
        this.currentPageConfig.primary_key = [this.currentPageConfig.primary_key];
      }

      if (schemaStore.all_contact_schema.length === 0) {
        await schemaStore.setSchema();
      }
    },
    async setCurrentPageDocument(record_id) {
      const crudStore = useCrudStore();
      const investorStore = useInvestorStore();
      const titleTransferStore = useTitleTransferStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      const createInvestmentStore = useCreateInvestmentStore();
      this.invalidContactPage = false;

      let fetched_record = await crudStore.findOne(this.currentPageCollection, {
        _id: record_id,
      });

      if (this.currentCollection == "Contacts" && fetched_record.type !== "Regular") {
        this.invalidContactPage = true;

        let fetched_owner_record = await crudStore.findOne(this.currentPageCollection, {
          user_id: fetched_record.user_id,
          type: "Regular",
        });

        this.directToPage = `/contacts?recordId=${fetched_owner_record._id}`;
        return;
      }

      let fetched_schema = schemaStore.getSchemaByCollection(this.currentPageCollection);

      fetched_record = UIStore.formatFetchedRecordFromCF(fetched_record, fetched_schema);

      this.currentPageDocument = _.cloneDeep(fetched_record);

      if (this.currentPageCollection === "Contacts") {
        createInvestmentStore.clearState();
        createInvestmentStore.current_owner_contact = _.cloneDeep(fetched_record);

        createInvestmentStore.current_owner_contact_full_name = `${fetched_record.first_name} ${fetched_record.last_name}`;
        await createInvestmentStore.prepInvestmentFlow();
      }
      if (this.currentPageCollection === "Investments") {
        //clear ttitle transfer state

        titleTransferStore.clearState();
        console.log("1");

        await Promise.all([
          this.setTransferBanners(fetched_record),
          this.setDistributionsForSingleInvestment(fetched_record._id, fetched_record),
        ]);

        if (Object.keys(titleTransferStore.current_owner_contact).length === 0) {
          titleTransferStore.current_investment = fetched_record;
        }
      }
    },
    async fetchTableRecords(use_actions_header = false, isMarketing = false, customHeaders = []) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let collection = this.currentPageCollection;

      let tableHeaders = schemaStore.getTableHeadersForCollection(collection);

      if (customHeaders.length > 0) {
        tableHeaders = customHeaders;
      }

      if (collection === "Investments" && !isMarketing) {
        const hasCheckboxHeader = tableHeaders?.some((header) => header.field_name === "checkbox");

        if (!hasCheckboxHeader) {
          tableHeaders.unshift({ label: "Select All", field_name: "checkbox" });
        }
      }

      if (use_actions_header) {
        tableHeaders.push({
          label: "Actions",
          field_name: "actions",
          actions: { cta: true, hide_sort: true },
        });
      }

      this.allRecordsTableHeaders = tableHeaders;

      // ?
      const projection = {};
      this.allRecordsTableHeaders.forEach((header) => {
        projection[header.field_name] = 1;
        if (header.associated_field_name) {
          projection[header.associated_field_name] = 1;
        }
      });

      try {
        const skipValue = (this.allRecordsPageNumber - 1) * this.pageSize;
        const skipStage = { $skip: skipValue };
        const limitStage = { $limit: this.pageSize };
        const matchStage = { $match: { ...this.filterPipeline, ...this.defaultFilterPipeline } };

        let pipeline = [skipStage, limitStage];

        pipeline.unshift({
          $sort: { _id: -1 }, // newest records should be first and also to ensure unique ids while paginating for setting status/signature dates
        });
        if (UIStore.sortHeader) {
          pipeline[0].$sort = {
            [UIStore.sortHeader.field_name]: UIStore.sortAscending ? 1 : -1,
            _id: -1,
          };
        }

        if (this.filterPipeline !== null || this.defaultFilterPipeline !== null) {
          pipeline.unshift(matchStage);
        }

        console.log("pipeline", pipeline);

        const fetchedRecords = await crudStore.aggregate(collection, pipeline);
        console.log("fetched records", fetchedRecords);

        if (fetchedRecords.length) {
          var count = await crudStore.countRecords(collection, pipeline);
          this.tableRecordCount = count;
        } else {
          this.tableRecordCount = 0;
        }

        //console.log("transforming...");
        const transformedRecords = this.transformRecords(fetchedRecords);
        //console.log("transformed records", transformedRecords);

        this.allRecords = transformedRecords;

        return transformedRecords;
      } catch (error) {}
    },
    transformRecords(records) {
      const UIStore = useUIStore();
      return UIStore.transformRecords(records);
    },
    async setNuggets(collection) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      // let nuggetPipeline;
      let config = "default";
      if (this.currentPageConfig.has_multiple_configs) {
        const configDeterminingValue =
          this.currentPageDocument[this.currentPageConfig.config_determining_field];

        const matchFieldMap = this.currentPageConfig.field_map.find(
          (data) => data.value === configDeterminingValue
        );

        if (matchFieldMap) {
          config = matchFieldMap.config;
        }
      }

      let fetchedInfoNuggetData = schemaStore.getSchemaNuggetsForCollection(collection, config);
      // console.log("fetched_nuggets_v2", fetched_nuggets_v2);
      for (const nugget of fetchedInfoNuggetData) {
        const newField = nugget.field_name;

        let currentValue = this.currentPageDocument[newField];
        if (nugget.type === "string" && nugget.is_association_field) {
          currentValue = {
            name: this.currentPageDocument[newField].assignedObject.name,
            route_to_push: `/${nugget.associated_collection?.toLowerCase()}?recordId=${
              this.currentPageDocument[newField].assignedObject._id
            }`,
          };
        }
        if (nugget.field_name == "phone") {
          currentValue = this.formatPhoneNumber(currentValue);
        }
        if ((currentValue !== null && currentValue !== "") || currentValue === 0) {
          if (currentValue === true) {
            nugget.v_model = "Yes";
          } else if (currentValue === false) {
            nugget.v_model = "No";
          } else {
            if (nugget.type === "number") {
              if (typeof currentValue === "number") {
                // Check if currentValue is a number
                if (nugget.number_type === "currency") {
                  nugget.v_model = "$" + commaSeparateThousands(currentValue.toFixed(2));
                } else if (nugget.number_type === "decimal") {
                  nugget.v_model = commaSeparateThousands(currentValue);
                } else if (nugget.number_type === "integer") {
                  nugget.v_model = commaSeparateThousands(currentValue.toFixed(0));
                } else if (nugget.number_type === "percentage") {
                  nugget.v_model = commaSeparateThousands((currentValue * 100).toFixed(2)) + "%";
                } else {
                  nugget.v_model = commaSeparateThousands(currentValue);
                }
              } else {
                nugget.v_model = currentValue; // Handle non-numeric values differently if needed
              }
            } else if (nugget.type === "date") {
              let date_string = new Date(currentValue).toLocaleDateString("en-US", {
                timeZone: "UTC",
              });
              nugget.v_model = date_string.includes("Invalid Date") ? "-" : date_string;
            } else {
              nugget.v_model = currentValue;
            }
          }
        } else {
          nugget.v_model = "-";
        }

        // if (!currentValue) {
        //   nugget.v_model = "-";
        // }
      }

      UIStore.headerNuggets = fetchedInfoNuggetData;
      return fetchedInfoNuggetData;
    },
    async setData(collection, object_id, deleted_record) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      const objectIdRegex = /^[0-9a-fA-F]{24}$/;

      if (!objectIdRegex.test(object_id)) {
        this.noRecordFound = true;
        console.log("record not found");
        return;
      }

      let config = "default";
      if (this.currentPageConfig.has_multiple_configs) {
        const configDeterminingValue =
          this.currentPageDocument[this.currentPageConfig.config_determining_field];

        const matchFieldMap = this.currentPageConfig.field_map.find(
          (data) => data.value === configDeterminingValue
        );

        if (matchFieldMap) {
          config = matchFieldMap.config;
        }
      }
      //let groupingOrder = this.currentPageLayout.record_detail_config[config].detail_sections;

      let groupingOrder =
        collection === "Investments"
          ? schemaStore.visible_investment_detail_groups
          : this.currentPageLayout.record_detail_config[config].detail_sections;

      this.finalGroupingOrder = groupingOrder;

      let fetchedAccordionData = schemaStore.setAccordionSchema(collection, groupingOrder, config);

      // Step 1: Filter out objects not in groupingOrder
      const filteredAndOrderedData = fetchedAccordionData.filter((item) =>
        groupingOrder.includes(item.name)
      );

      // Modify the map operation to include a default object when find returns undefined
      const orderedData = groupingOrder.map(
        (orderItem) =>
          filteredAndOrderedData.find((item) => item.name === orderItem) || {
            name: orderItem,
            fields: [],
          }
      );

      for (const group of orderedData) {
        for (const field of group.fields) {
          const newField = field.field_name;

          const currentValue = this.currentPageDocument[newField];

          if ((currentValue !== null && currentValue !== "") || currentValue === 0) {
            if (currentValue === true) {
              field.v_model = "Yes";
            } else if (currentValue === false) {
              field.v_model = "No";
            } else if (field.type === "number") {
              if (typeof currentValue === "number") {
                if (field.number_type === "currency") {
                  field.v_model = "$" + commaSeparateThousands(currentValue.toFixed(2));
                } else if (field.number_type === "decimal") {
                  field.v_model = commaSeparateThousands(currentValue);
                } else if (field.number_type === "percentage") {
                  field.v_model = commaSeparateThousands((currentValue * 100).toFixed(2)) + "%";
                  this.recordEdits[newField] = (currentValue * 100).toFixed(2);
                } else if (field.number_type === "integer") {
                  field.v_model = commaSeparateThousands(currentValue.toFixed(0));
                } else {
                  field.v_model = commaSeparateThousands(currentValue);
                }
              } else {
                field.v_model = currentValue; // Handle non-numeric values differently if needed
              }
            } else if (Array.isArray(currentValue) && currentValue.length === 0) {
              field.v_model = "-";
            } else if (field.type === "date") {
              if (newField === "updated_date" || newField === "created_date") {
                let date_string =
                  new Date(currentValue).toLocaleDateString("en-US", {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }) +
                  " - " +
                  new Date(currentValue).toLocaleTimeString("en-US", {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  });
                field.v_model = date_string.includes("Invalid Date") ? "-" : date_string;
              } else {
                let date_string = new Date(currentValue).toLocaleDateString("en-US", {
                  timeZone: "UTC",
                });
                field.v_model = date_string.includes("Invalid Date") ? "-" : date_string;
              }
            } else {
              field.v_model = currentValue;
            }
          } else {
            field.v_model = "-";
          }
          // if (!currentValue &&  && currentValue !== 0) {
          //   field.v_model = "-";
          // }

          if (field.type === "string" && field.is_association_field) {
            const nameFieldValue = this.currentPageDocument[newField];
            const idFieldValue = this.currentPageDocument[field.associated_field_name];

            this.recordEdits[newField] = {
              searchTerm: "",
              assignedObject: {
                _id: idFieldValue,
                name: nameFieldValue,
              },
              results: [],
              schema: field,
            };
            //From Kamary - I don't think this will cause any problems - had to set this so that the info nuggets route for association fields could be set correctly
            this.currentPageDocument[newField] = {
              searchTerm: "",
              assignedObject: {
                _id: idFieldValue,
                name: nameFieldValue,
              },
              results: [],
              schema: field,
            };

            let path = convertToKebabCase(field.associated_collection);
            if (path === "title") {
              path = "title-assignments";
            }
            field.exact_route =
              "/" + path + "?recordId=" + this.recordEdits[field.associated_field_name];
          } else if (
            newField === "associated_record_name" &&
            collection === "Tasks" &&
            this.recordEdits.associated_record_type &&
            this.recordEdits.associated_record_id
          ) {
            let routesObject;
            if (this.recordEdits.associated_record_type === "Contact") {
              routesObject = "contacts";
            } else if (this.recordEdits.associated_record_type === "Account") {
              routesObject = "accounts";
            } else if (this.recordEdits.associated_record_type === "Offer") {
              routesObject = "offers";
            } else if (this.recordEdits.associated_record_type === "Investment") {
              routesObject = "investments";
            }

            field.exact_route =
              "/" + routesObject + "?recordId=" + this.recordEdits.associated_record_id;
          } else if (field.type === "array" && field.associated_collection) {
            this.recordEdits[newField] = {
              searchTerm: "",
              selectedItems: this.recordEdits[newField],
              results: [],
              schema: field,
            };
            const path = this.convertToKebabCase(field.associated_collection);
            field.base_route = "/" + path + "?recordId=";
          }
        }
      }

      this.setNuggets(collection);

      fetchedAccordionData = fetchedAccordionData.filter((item) => item.name !== "");
      this.fetchedAccordionData = orderedData;
      const corrected_record_edit = _.cloneDeep(this.recordEdits);
      this.finalized_record_edits = corrected_record_edit;

      //console.log("end");

      return orderedData;
    },
    async fetchSubAgreements(investment_id) {
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      // console.log({
      //   investment_id: investment_id,
      //   type: "Sub Agreements",
      // });

      const subAgreements = await crudStore.find("Documents", {
        investment_id: investment_id,
        type: "Sub Agreements",
      });

      //  console.log(subAgreements);

      return subAgreements;
    },
    async fetchOffering(offering_id) {
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      const offering = await crudStore.find("Offerings", {
        _id: offering_id,
      });

      //console.log(subAgreements);

      return offering[0];
    },
    async fetchCustodian(custodian_id) {
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      const custodian = await crudStore.find("Custodians", {
        _id: custodian_id,
      });

      //console.log(subAgreements);

      return custodian[0];
    },
    async fetchBanks(funding_account_id, distrubution_account_id) {
      // console.log(
      //   `fetching banks with funding account id: ${funding_account_id} and distribution account id: ${distrubution_account_id}`
      // );
      // use crudstore to get subagreements with investment id
      const crudStore = useCrudStore();

      const funding_account = await crudStore.findOne("BankAccounts", {
        _id: funding_account_id,
      });

      const distrubution_account = await crudStore.findOne("BankAccounts", {
        _id: distrubution_account_id,
      });

      return {
        funding_account: funding_account,
        distrubution_account: distrubution_account,
      };
    },
    async getPossibleColumns(collection_name) {
      const crudStore = useCrudStore();
      let group_by = "";
      let match = {};
      group_by = "$collection_name";
      match = {
        collection_name: collection_name,
        available_for_filtering: true,
      };
      let pipeline = [
        {
          $match: match,
        },
        {
          $sort: { label: 1 },
        },
        {
          $group: {
            _id: group_by,
            fields: {
              $push: {
                $cond: [
                  {
                    $or: [{ $eq: ["$merged_field", false] }, { $ifNull: ["$merged_field", true] }],
                  },
                  {
                    field_name: "$field_name",
                    label: "$label",
                    field_type: "$field_type",
                    number_type: "$number_type",
                    collection_name: "$collection_name",
                    dropdown_options: "$record_detail_config.default.dropdown_options",
                    is_object_id: "$is_object_id",
                  },
                  "$$REMOVE",
                ],
              },
            },
          },
        },
        {
          $project: {
            _id: 0,
            collection_name: "$_id",
            fields: 1,
          },
        },
      ];
      let res = await crudStore.aggregate("Schema", pipeline);
      this.possibleColumns = res;
      return res;
    },

    async storeFilterHistoryForUser(filters, rlqrCollection = null) {
      // const authStore = useAuthStore();
      // const collectionStore = useCollectionStore();
      // const collection = filters[0]?.collection_name;
      // const filterHistory = {
      //   user_id: authStore.currentUser.id,
      //   collection_name: rlqrCollection ? rlqrCollection : collection,
      //   filters: filters,
      //   created_date: new Date(),
      //   is_favorited: false,
      //   favorite_filter_name: "",
      //   created_by_id: authStore.currentUser.id,
      //   created_by_name: authStore.currentUserDocument.name,
      //   updated_date: new Date(),
      //   updated_by_id: authStore.currentUser.id,
      //   updated_by_name: authStore.currentUserDocument.name,
      // };
      // await collectionStore.insertRecord("UserFilters", filterHistory);
    },

    parseFilters(filters, isDataAPI) {
      let matchObj = {};
      let matchTemp = {};
      let matches = [];
      filters.forEach((filter) => {
        matchObj = {};
        let blankObj = {};
        let blankArr = [];
        if (!filter.operator) {
          matchObj = {};
        } else {
          if (filter.is_self_referential) {
            if (filter.operator.name === "Equal To") {
              matchTemp = {
                $expr: {
                  $eq: ["$" + filter.field_name, "$" + filter.self_ref_field?.field_name],
                },
              };
            } else if (filter.operator.name === "NOT Equal To") {
              matchTemp = {
                $expr: {
                  $ne: ["$" + filter.field_name, "$" + filter.self_ref_field?.field_name],
                },
              };
            } else if (filter.operator.name === "Greater Than") {
              matchTemp = {
                $expr: {
                  $gt: ["$" + filter.field_name, "$" + filter.self_ref_field?.field_name],
                },
              };
            } else if (filter.operator.name === "Greater Than or Equal To") {
              matchTemp = {
                $expr: {
                  $gte: ["$" + filter.field_name, "$" + filter.self_ref_field?.field_name],
                },
              };
            } else if (filter.operator.name === "Less Than") {
              matchTemp = {
                $expr: {
                  $lt: ["$" + filter.field_name, "$" + filter.self_ref_field?.field_name],
                },
              };
            } else if (filter.operator.name === "Less Than or Equal To") {
              matchTemp = {
                $expr: {
                  $lte: ["$" + filter.field_name, "$" + filter.self_ref_field?.field_name],
                },
              };
            }
          } else {
            if (filter.operator.name === "Is Blank") {
              blankObj[filter.field_name] = { $eq: null };
              blankArr.push(blankObj);
              blankObj = {};
              blankObj[filter.field_name] = { $eq: "" };
              blankArr.push(blankObj);
              matchObj = { $or: blankArr };
            } else if (filter.operator.name === "Is NOT Blank") {
              blankObj[filter.field_name] = { $ne: null };
              blankArr.push(blankObj);
              blankObj = {};
              blankObj[filter.field_name] = { $ne: "" };
              blankArr.push(blankObj);
              blankObj = {};
              blankObj[filter.field_name] = { $ne: [] };
              blankArr.push(blankObj);
              matchObj = { $and: blankArr };
            } else if (
              typeof filter.value === "string" &&
              (filter.operator.name === "Contains" ||
                filter.operator.name === "Does NOT Contain" ||
                filter.operator.name === "Equal To" ||
                filter.operator.name === "NOT Equal To")
            ) {
              matchObj = this.buildOpObj(
                filter.operator.name,
                filter.value,
                filter.data_type,
                filter.is_object_id,
                isDataAPI,
                filter.field_name
              );
            } else {
              matchObj = this.buildOpObj(
                filter.operator.name,
                filter.value,
                filter.data_type,
                filter.is_object_id,
                isDataAPI,
                filter.field_name
              );
            }
            //matchObj['$' + filters[index -1].logical_operator] =
            matchTemp = matchObj;
          }

          // build internal set first
          filter.internal_sets.forEach((set) => {
            let internal_set_obj = {};
            let blankArr = [];
            if (set.is_self_referential) {
              if (set.operator.name === "Equal To") {
                internal_set_obj = {
                  $expr: {
                    $eq: ["$" + set.field_name, "$" + set.self_ref_field?.field_name],
                  },
                };
              } else if (set.operator.name === "NOT Equal To") {
                internal_set_obj = {
                  $expr: {
                    $ne: ["$" + set.field_name, "$" + set.self_ref_field?.field_name],
                  },
                };
              } else if (set.operator.name === "Greater Than") {
                internal_set_obj = {
                  $expr: {
                    $gt: ["$" + set.field_name, "$" + set.self_ref_field?.field_name],
                  },
                };
              } else if (set.operator.name === "Greater Than or Equal To") {
                internal_set_obj = {
                  $expr: {
                    $gte: ["$" + set.field_name, "$" + set.self_ref_field?.field_name],
                  },
                };
              } else if (set.operator.name === "Less Than") {
                internal_set_obj = {
                  $expr: {
                    $lt: ["$" + set.field_name, "$" + set.self_ref_field?.field_name],
                  },
                };
              } else if (set.operator.name === "Less Than or Equal To") {
                internal_set_obj = {
                  $expr: {
                    $lte: ["$" + set.field_name, "$" + set.self_ref_field?.field_name],
                  },
                };
              }
            } else {
              if (set.operator.name === "Is Blank") {
                blankObj[set.field_name] = { $eq: null };
                blankArr.push(blankObj);
                blankObj = {};
                blankObj[set.field_name] = { $eq: "" };
                blankArr.push(blankObj);
                internal_set_obj = { $and: blankArr };
              } else if (set.operator.name === "Is NOT Blank") {
                blankObj[set.field_name] = { $ne: null };
                blankArr.push(blankObj);
                blankObj = {};
                blankObj[set.field_name] = { $ne: "" };
                blankArr.push(blankObj);
                blankObj = {};
                blankObj[filter.field_name] = { $ne: [] };
                blankArr.push(blankObj);
                internal_set_obj = { $and: blankArr };
              } else if (
                typeof set.value === "string" &&
                (set.operator.name === "Contains" ||
                  set.operator.name === "Does NOT Contain" ||
                  set.operator.name === "Equal To" ||
                  set.operator.name === "NOT Equal To")
              ) {
                internal_set_obj = this.buildOpObj(
                  set.operator.name,
                  set.value,
                  set.data_type,
                  set.is_object_id,
                  isDataAPI,
                  set.field_name
                );
              } else {
                internal_set_obj = this.buildOpObj(
                  set.operator.name,
                  set.value,
                  set.data_type,
                  set.is_object_id,
                  isDataAPI,
                  set.field_name
                );
              }
            }
            let nested_obj = {};
            nested_obj["$" + set.logical_operator] = [matchTemp, internal_set_obj];
            matchTemp = nested_obj;
          });
        }
        matches.push(matchTemp); // make sure to put end result onto selectedReport and upsert it. this way we can read it in without having to parse it if it's existing
      });
      let setGroup = {};
      if (filters.length > 1) {
        setGroup["$" + filters[0].logical_operator] = [matches[0], matches[1]];
        filters.forEach((filter, index) => {
          let some_var = {};
          if (index >= 2) {
            some_var["$" + filters[index - 1].logical_operator] = [matches[index], setGroup];
            setGroup = some_var;
          }
        });
      } else if (filters.length === 1) {
        setGroup = matches[0];
      }
      return setGroup;
    },
    buildOpObj(operator, value, field_type, is_object_id, isDataAPI, field_name) {
      // parse string into date

      const isLocalTimeField = field_name === "created_date" || field_name === "updated_date";
      if (field_type === "date" && !value?.is_relative_date) {
        value = new Date(value);
      }

      const objectIdRegex = /^[0-9a-fA-F]{24}$/;

      const valid_obj_id = objectIdRegex.test(value);

      if (operator === "Equal To") {
        let containsObj = {};
        let containsArr = [];

        if (typeof value === "string") {
          if (
            (!is_object_id || !valid_obj_id) &&
            (field_type === "string" || field_type === "long_text" || field_type === "dropdown")
          ) {
            // if field is a string
            containsObj[field_name] = value;
            containsArr.push(containsObj);
            containsObj = {};
            containsObj[field_name] = autoCapitalize(value);
            containsArr.push(containsObj);
            containsObj = {};
            containsObj[field_name] = value.toUpperCase();
            containsArr.push(containsObj);
            return { $or: containsArr };
          } else if (is_object_id && !isDataAPI) {
            // parse string into ObjectId
            containsObj[field_name] = ObjectId(value);
            return containsObj;
          } else if (is_object_id && isDataAPI) {
            // parse string into ObjectId
            containsObj[field_name] = { $oid: value };
            return containsObj;
          }
        } else if (value instanceof Date) {
          if (isLocalTimeField) {
            const startRangeWithTimezoneOffset = new Date(
              value.getTime() + value.getTimezoneOffset() * 60000
            );
            const endRangeWithTimezoneOffset = new Date(
              value.getTime() + value.getTimezoneOffset() * 60000 + 60 * 60 * 24 * 1000
            );
            containsObj[field_name] = isDataAPI
              ? { $gte: { $date: startRangeWithTimezoneOffset } }
              : { $gte: startRangeWithTimezoneOffset };
            containsArr.push(containsObj);
            containsObj = {};
            containsObj[field_name] = isDataAPI
              ? { $lt: { $date: endRangeWithTimezoneOffset } }
              : { $lt: endRangeWithTimezoneOffset };
            containsArr.push(containsObj);
          } else {
            containsObj[field_name] = isDataAPI ? { $gte: { $date: value } } : { $gte: value };
            containsArr.push(containsObj);
            containsObj = {};
            containsObj[field_name] = isDataAPI
              ? { $lt: { $date: new Date(value.getTime() + 60 * 60 * 24 * 1000) } }
              : { $lt: new Date(value.getTime() + 60 * 60 * 24 * 1000) };
            containsArr.push(containsObj);
          }

          return { $and: containsArr };
        } else if (value?.is_relative_date) {
          const relativeValue = value.name;
          let query = {};
          query = this.getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField);

          if (isDataAPI) {
            for (let [key, val] of Object.entries(query)) {
              query[key] = { $date: val };
            }
          }

          containsObj[field_name] = query;
          return containsObj;
        } else {
          containsObj[field_name] = { $eq: value?.hasOwnProperty("value") ? value.value : value };
          return containsObj;
        }
      } else if (operator === "NOT Equal To") {
        let containsObj = {};
        let containsArr = [];
        if (typeof value === "string") {
          if (
            !is_object_id &&
            (field_type === "string" || field_type === "long_text" || field_type === "dropdown")
          ) {
            // if field is a string, do a regex match
            containsObj[field_name] = { $regex: "^(?!" + this.escapeRegExp(value) + "$).*" };
            containsArr.push(containsObj);
            containsObj = {};
            containsObj[field_name] = {
              $regex: "^(?!" + this.escapeRegExp(autoCapitalize(value)) + "$).*",
            };
            containsArr.push(containsObj);
            containsObj = {};
            containsObj[field_name] = {
              $regex: "^(?!" + this.escapeRegExp(value.toUpperCase()) + "$).*",
            };
            containsArr.push(containsObj);
            return { $and: containsArr };
          } else if (is_object_id && !isDataAPI) {
            // parse string into ObjectId
            containsObj[field_name] = { $ne: ObjectId(value) };
            return containsObj;
          } else if (is_object_id && isDataAPI) {
            // parse string into ObjectId
            containsObj[field_name] = { $ne: { $oid: value } };
            return containsObj;
          }
        } else if (value?.is_relative_date) {
          const relativeValue = value.name;
          const query = this.getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField);

          let notEqualQuery = {
            $or: [
              { [field_name]: { $lt: isDataAPI ? { $date: query.$gte } : query.$gte } },
              { [field_name]: { $gt: isDataAPI ? { $date: query.$lte } : query.$lte } },
            ],
          };
          return notEqualQuery;
        } else {
          containsObj[field_name] = { $ne: value?.hasOwnProperty("value") ? value.value : value };
          return containsObj;
        }
      } else if (operator === "Greater Than") {
        let containsObj = {};
        if (value instanceof Date) {
          if (isLocalTimeField) {
            const startRangeWithTimezoneOffset = new Date(
              value.getTime() + value.getTimezoneOffset() * 60000 + 60 * 60 * 24 * 1000
            );
            containsObj[field_name] = isDataAPI
              ? { $gte: { $date: startRangeWithTimezoneOffset } }
              : { $gte: startRangeWithTimezoneOffset };
          } else {
            containsObj[field_name] = isDataAPI ? { $gt: { $date: value } } : { $gt: value };
          }
          return containsObj;
        } else if (value?.is_relative_date) {
          const relativeValue = value.name;
          const query = this.getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField);
          // everything that is greater than the end of the range is greater than the relative date
          const greaterThanQuery = { $gt: isDataAPI ? { $date: query.$lte } : query.$lte };

          containsObj[field_name] = greaterThanQuery;
          return containsObj;
        } else {
          containsObj[field_name] = {
            $gt: value?.hasOwnProperty("value") ? value.value : value,
          };
          return containsObj;
        }
      } else if (operator === "Less Than") {
        let containsObj = {};
        if (value instanceof Date) {
          if (isLocalTimeField) {
            const endRangeWithTimezoneOffset = new Date(
              value.getTime() + value.getTimezoneOffset() * 60000
            );
            containsObj[field_name] = isDataAPI
              ? { $lt: { $date: endRangeWithTimezoneOffset } }
              : { $lt: endRangeWithTimezoneOffset };
          } else {
            containsObj[field_name] = isDataAPI ? { $lt: { $date: value } } : { $lt: value };
          }
          return containsObj;
        } else if (value?.is_relative_date) {
          const relativeValue = value.name;
          const query = this.getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField);
          // everything that is less than the beginning of the range is less than the relative date
          const lessThanQuery = { $lt: isDataAPI ? { $date: query.$gte } : query.$gte };

          containsObj[field_name] = lessThanQuery;
          return containsObj;
        } else {
          containsObj[field_name] = {
            $lt: value?.hasOwnProperty("value") ? value.value : value,
          };
          return containsObj;
        }
      } else if (operator === "Greater Than or Equal To") {
        let containsObj = {};
        if (value instanceof Date) {
          if (isLocalTimeField) {
            const startRangeWithTimezoneOffset = new Date(
              value.getTime() + value.getTimezoneOffset() * 60000
            );
            containsObj[field_name] = isDataAPI
              ? { $gte: { $date: startRangeWithTimezoneOffset } }
              : { $gte: startRangeWithTimezoneOffset };
          } else {
            containsObj[field_name] = isDataAPI ? { $gte: { $date: value } } : { $gte: value };
          }
          return containsObj;
        } else if (value?.is_relative_date) {
          const relativeValue = value.name;
          const query = this.getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField);
          // everything that is greater than or equal to the beginning of the range is greater than or equal to the relative date
          containsObj[field_name] = isDataAPI
            ? { $gte: { $date: query.$gte } }
            : { $gte: query.$gte };
          return containsObj;
        } else {
          containsObj[field_name] = {
            $gte: value?.hasOwnProperty("value") ? value.value : value,
          };
          return containsObj;
        }
      } else if (operator === "Less Than or Equal To") {
        let containsObj = {};
        if (value instanceof Date) {
          if (isLocalTimeField) {
            const endRangeWithTimezoneOffset = new Date(
              value.getTime() + value.getTimezoneOffset() * 60000 + 60 * 60 * 24 * 1000
            );
            containsObj[field_name] = isDataAPI
              ? { $lt: { $date: endRangeWithTimezoneOffset } }
              : { $lt: endRangeWithTimezoneOffset };
          } else {
            containsObj[field_name] = isDataAPI ? { $lte: { $date: value } } : { $lte: value };
          }
          return containsObj;
        } else if (value?.is_relative_date) {
          const relativeValue = value.name;
          const query = this.getDateRangeQueryForRelativeValue(relativeValue, isLocalTimeField);
          // Everything that is less than or equal to the end of the range is less than or equal to the relative date
          containsObj[field_name] = isDataAPI
            ? { $lte: { $date: query.$lte } }
            : { $lte: query.$lte };
          return containsObj;
        } else {
          containsObj[field_name] = {
            $lte: value?.hasOwnProperty("value") ? value.value : value,
          };
          return containsObj;
        }
      } else if (operator === "Contains") {
        let containsObj = {};
        let containsArr = [];
        if (typeof value === "string") {
          if (
            !is_object_id &&
            (field_type === "string" || field_type === "long_text" || field_type === "dropdown")
          ) {
            // if field is a string, do a regex match
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              containsObj[field_name] = {
                $regex: ".*" + this.escapeRegExp(comma_split_value.trim()) + ".*",
              };
              containsArr.push(containsObj);
              containsObj = {};
              containsObj[field_name] = {
                $regex: ".*" + this.escapeRegExp(autoCapitalize(comma_split_value.trim())) + ".*",
              };
              containsArr.push(containsObj);
              containsObj = {};
              containsObj[field_name] = {
                $regex: ".*" + this.escapeRegExp(comma_split_value.trim().toUpperCase()) + ".*",
              };
              containsArr.push(containsObj);
            });
            return { $or: containsArr };
          } else if (is_object_id && !isDataAPI) {
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = ObjectId(comma_split_value.trim());
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else if (is_object_id && isDataAPI) {
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = { $oid: comma_split_value.trim() };
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else {
            return { $in: value.split(",") };
          }
        } else if (value?.is_relative_date) {
          containsObj.non_existant_field = { $eq: true };
          return containsObj;
        } else {
          containsObj[field_name] = { $eq: value?.hasOwnProperty("value") ? value.value : value };
          return containsObj;
        }
      } else if (operator === "Does NOT Contain") {
        let containsObj = {};
        let containsArr = [];
        if (typeof value === "string") {
          if (
            !is_object_id &&
            (field_type === "string" || field_type === "long_text" || field_type === "dropdown")
          ) {
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              containsObj[field_name] = {
                $regex: `^((?!${this.escapeRegExp(comma_split_value.trim())}).)*$`,
              };
              containsArr.push(containsObj);
              containsObj = {};
              containsObj[field_name] = {
                $regex: `^((?!${this.escapeRegExp(autoCapitalize(comma_split_value.trim()))}).)*$`,
              };
              containsArr.push(containsObj);
              containsObj = {};
              containsObj[field_name] = {
                $regex: `^((?!${this.escapeRegExp(comma_split_value.trim().toUpperCase())}).)*$`,
              };
              containsArr.push(containsObj);
            });
            return { $and: containsArr };
          } else if (is_object_id && !isDataAPI) {
            // parse string into ObjectId
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = { $ne: ObjectId(comma_split_value.trim()) };
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else if (is_object_id && isDataAPI) {
            // parse string into ObjectId
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = { $ne: { $oid: comma_split_value.trim() } };
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $and: containsArr };
          } else {
            return { $nin: value.split(",") };
          }
        } else if (value?.is_relative_date) {
          containsObj.non_existant_field = { $eq: true };
          return containsObj;
        } else {
          containsObj[field_name] = { $ne: value?.hasOwnProperty("value") ? value.value : value };
          return containsObj;
        }
      } else if (operator === "Includes") {
        let containsObj = {};
        let containsArr = [];
        if (typeof value === "string") {
          if (
            !is_object_id &&
            (field_type === "string" || field_type === "long_text" || field_type === "dropdown")
          ) {
            // if field is a string, do a regex match
            let comma_split = value.split(",");
            containsObj[field_name] = {
              $in: comma_split.map((val) => {
                return val.trim();
              }),
            };
            return containsObj;
          } else if (is_object_id && !isDataAPI) {
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = ObjectId(comma_split_value.trim());
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else if (is_object_id && isDataAPI) {
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = { $oid: comma_split_value.trim() };
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else {
            return { $in: value.split(",") };
          }
        } else if (value?.is_relative_date) {
          containsObj.non_existant_field = { $eq: true };
          return containsObj;
        } else {
          containsObj[field_name] = { $eq: value?.hasOwnProperty("value") ? value.value : value };
          return containsObj;
        }
      } else if (operator === "Does NOT Include") {
        let containsObj = {};
        let containsArr = [];
        if (typeof value === "string") {
          if (
            !is_object_id &&
            (field_type === "string" || field_type === "long_text" || field_type === "dropdown")
          ) {
            // if field is a string, do a regex match
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              containsObj[field_name] = { $ne: comma_split_value.trim() };
              containsArr.push(containsObj);
              containsObj = {};
              containsObj[field_name] = {
                $ne: autoCapitalize(comma_split_value.trim()),
              };
              containsArr.push(containsObj);
              containsObj = {};
              containsObj[field_name] = {
                $ne: comma_split_value.trim().toUpperCase(),
              };
              containsArr.push(containsObj);
            });
            return { $and: containsArr };
          } else if (is_object_id && !isDataAPI) {
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = ObjectId(comma_split_value.trim());
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else if (is_object_id && isDataAPI) {
            // parse string into ObjectId
            let comma_split = value.split(",");
            comma_split.forEach((comma_split_value) => {
              if (objectIdRegex.test(comma_split_value.trim())) {
                containsObj[field_name] = { $oid: comma_split_value.trim() };
                containsArr.push(containsObj);
                containsObj = {};
              }
            });
            return { $or: containsArr };
          } else {
            return { $in: value.split(",") };
          }
        } else if (value?.is_relative_date) {
          containsObj.non_existant_field = { $eq: true };
          return containsObj;
        } else {
          containsObj[field_name] = { $eq: value?.hasOwnProperty("value") ? value.value : value };
          return containsObj;
        }
      }
    },
    async downloadTableRecordsCSV(file_name) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let collection = this.currentPageCollection;

      try {
        //const skipValue = (this.allRecordsPageNumber - 1) * this.pageSize;
        //const skipStage = { $skip: skipValue };
        //const limitStage = { $limit: this.pageSize };
        const matchStage = { $match: { ...this.filterPipeline, ...this.defaultFilterPipeline } };
        let pipeline = [];

        if (UIStore.sortHeader) {
          pipeline.unshift({
            $sort: { [UIStore.sortHeader.field_name]: UIStore.sortAscending ? 1 : -1 },
          });
        }

        if (this.filterPipeline !== null || this.defaultFilterPipeline !== null) {
          pipeline.unshift(matchStage);
        }

        const fetchedRecords = await crudStore.aggregate(collection, pipeline);

        convertJsonToCsvFileAndDownload(fetchedRecords, file_name);

        return;
      } catch (error) {
        console.error(error);
      }
    },
    async setTransferBanners(fetched_record) {
      console.log("setTransferBanners");
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      let projection = {};

      projection.inception_to_date_interest_paid = 1;
      projection.year_to_date_interest_paid = 1;

      for (const field of schemaStore.transfer_banner_headers) {
        projection[field.field_name] = 1;
      }

      this.fetched_transfer_children = [];
      this.fetched_transfer_parents = [];
      console.log(122);
      if (fetched_record.transfer_parent_ids && fetched_record.transfer_parent_ids.length > 0) {
        let aggregation_pipeline = [
          {
            $match: {
              _id: {
                $in: fetched_record.transfer_parent_ids ? fetched_record.transfer_parent_ids : [],
              },
            },
          },
          {
            $project: projection,
          },
        ];

        this.fetched_transfer_parents = await crudStore.aggregate(
          "Investments",
          aggregation_pipeline
        );
        this.fetched_transfer_parents = transformRecordsForTable(this.fetched_transfer_parents);

        console.log("this.fetched_transfer_parents", this.fetched_transfer_parents);
      }

      console.log(this.fetched_transfer_parents);

      let aggregation_pipeline = [
        {
          $match: {
            $or: [
              {
                _id: {
                  $in: fetched_record.transfer_children_ids
                    ? fetched_record.transfer_children_ids
                    : [],
                },
              },
              { transfer_parent_ids: fetched_record._id },
            ],
          },
        },
        {
          $project: projection,
        },
      ];
      console.log(123);
      this.fetched_transfer_children = await crudStore.aggregate(
        "Investments",
        aggregation_pipeline
      );

      console.log("this.fetched_transfer_children", this.fetched_transfer_children);
      this.transfer_is_disabled = false;
      //if any of the children have a status that is not settled i want to set this. transfer_is_disabled = true
      this.fetched_transfer_children.forEach((child) => {
        if (child.status !== "Settled") {
          this.transfer_is_disabled = true;
        }
      });

      this.fetched_transfer_children = transformRecordsForTable(this.fetched_transfer_children);
    },
    async setDistributionsForSingleInvestment(investment_id, investment) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let projection_fields = ["distribution_date", "amount"];
      if (investment.is_discounted_bond) {
        projection_fields.push("OID");
      }
      if (investment.partial_redemptions?.length) {
        projection_fields.push("redemption_principal", "redemption_accrual", "redemption_oid");
      }
      projection_fields.push("is_completed"); // ensures this is always last column
      this.investment_earnings_tab_headers = schemaStore.getEarningsTabHeaders(projection_fields);
      this.investment_earnings_tab_headers.unshift({ label: "#", field_name: "index" });

      console.log("this.investment_earnings_tab_headers", this.investment_earnings_tab_headers);
      let projection = {};

      for (const field of projection_fields) {
        projection[field] = 1;
      }

      let pipeline = [
        {
          $match: {
            investment_id: investment_id,
          },
        },
        {
          $sort: {
            distribution_date: 1,
          },
        },
        {
          $project: projection,
        },
      ];

      let fetched_distributions = await crudStore.aggregate("Distributions", pipeline);

      //if any of the distributions have is_completed marked to true, i want ti set a flag this.has_paid_distributions = true
      this.has_paid_distributions = false;
      fetched_distributions.forEach((distribution) => {
        if (distribution.is_completed) {
          this.investment_has_paid_distributions = true;
        }
      });
      console.log("this.has_paid_distributions", this.has_paid_distributions);

      fetched_distributions.forEach((distribution, index) => {
        distribution.index = index + 1;
      });
      fetched_distributions = transformRecordsForTable(fetched_distributions);
      console.log(fetched_distributions);
      this.fetched_distributions_for_investment_earnings_tab = fetched_distributions;
    },
    async downloadDistributionsForSingleInvestment(file_name) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let projection_fields = ["distribution_date", "amount"];
      if (this.currentPageDocument.is_discounted_bond) {
        projection_fields.push("OID");
      }
      if (this.currentPageDocument.partial_redemptions?.length) {
        projection_fields.push("redemption_principal", "redemption_accrual", "redemption_oid");
      }
      projection_fields.push("is_completed"); // ensures this is always last column
      //this.investment_earnings_tab_headers = schemaStore.getEarningsTabHeaders(projection_fields);
      //this.investment_earnings_tab_headers.unshift({ label: "#", field_name: "index" });

      //console.log("this.investment_earnings_tab_headers", this.investment_earnings_tab_headers);
      let projection = {
        _id: 0, // do not pull _id
      };

      for (const field of projection_fields) {
        projection[field] = 1;
      }

      // add OID to the projection if the investment is discounted
      // if (this.currentPageDocument.is_discounted_bond) {
      //   projection.OID = 1;
      // }

      let pipeline = [
        {
          $match: {
            investment_id: this.currentPageDocument._id,
          },
        },
        {
          $sort: {
            distribution_date: 1,
          },
        },
        {
          $project: projection,
        },
      ];

      let fetched_distributions = await crudStore.aggregate("Distributions", pipeline);

      //if any of the distributions have is_completed marked to true, i want to set a flag this.has_paid_distributions = true
      this.has_paid_distributions = false;
      fetched_distributions.forEach((distribution) => {
        if (distribution.is_completed) {
          this.investment_has_paid_distributions = true;
        }
      });
      console.log("this.has_paid_distributions", this.has_paid_distributions);

      fetched_distributions.forEach((distribution, index) => {
        distribution.index = index + 1;
      });

      const fetched_ordered_distributions = fetched_distributions.map((distribution) => {
        return {
          index: distribution.index, // Ensure 'index' is first
          ...distribution, // Spread the rest of the keys in their original order
        };
      });

      // Pass the ordered array to the conversion function
      convertJsonToCsvFileAndDownload(fetched_ordered_distributions, file_name);

      // fetched_distributions = transformRecordsForTable(fetched_distributions);
      // this.fetched_distributions_for_investment_earnings_tab = fetched_distributions;
    },
    async setSortHeader(header, keepActions = false, hideCheckboxes = false) {
      const UIStore = useUIStore();

      if (JSON.stringify(header) === JSON.stringify(UIStore.sortHeader)) {
        UIStore.sortAscending = !UIStore.sortAscending;
      }
      UIStore.sortHeader = header;

      await this.fetchTableRecords(keepActions, hideCheckboxes, this.customTableHeaders || []); // 1 for ascending, -1 for descending
    },
    createCSVTable(json_data) {
      // Check if json_data is not empty and has at least one item
      if (json_data.length > 0) {
        // Use Object.keys() to get an array of property names from the first object in json_data
        const propertyNames = Object.keys(json_data[0]);

        let header_array = [];
        for (const name of propertyNames) {
          let header_object = {
            label: name,
            field_name: name,
            field_type: "string",
          };
          header_array.push(header_object);
        }

        this.preview_csv_headers = header_array;
        let transformedRecords = transformRecordsForTable(json_data);
        this.preview_csv_data = transformedRecords;
      }
    },
  },
});
