<template>
  <div class="md:h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <div class="w-full md:max-w-[450px] p-6 md:p-0">
        <div
          :class="[
            authStore.is_verified
              ? 'bg-functional-success-default bg-opacity-30'
              : 'bg-functional-error-default bg-opacity-30',
            'flex items-center justify-center rounded-full h-8 w-8 mb-4',
          ]"
        >
          <Icon
            :name="authStore.is_verified ? 'CircleCheckBig' : 'CircleX'"
            class="h-6 w-6"
            :class="
              authStore.is_verified
                ? 'text-functional-success-default'
                : 'text-functional-error-default'
            "
            strokeWidth="2"
          />
        </div>
        <h3 class="font-bold text-2xl mb-6">
          Verification {{ authStore.is_verified ? "Success" : "Failed" }}
        </h3>
        <p class="text-sm text-lighter mb-6">
          {{
            authStore.is_verified
              ? `Thank you for verifying. Now you can sign in and buy shares.`
              : "The link or code has expired or was invalid please request a new one and try again."
          }}
        </p>
        <div class="flex flex-col items-center w-full gap-4">
          <Button @click="handleNextStep" label size="large" class="w-full">
            <template #label>{{ authStore.is_verified ? "Sign In" : "Back to Sign in" }}</template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import { Button, Icon } from "@/components";
import { IconChevronLeft } from "@/components/icons";

const router = useRouter();
const authStore = useAuthStore();

onMounted(() => {
  if (!authStore.signInFields.email) {
    router.push("/sign-in");
  }
});

async function handleNextStep() {
  authStore.isAuthenticatedNavigation = true;
  router.push("/dashboard");
}
</script>
