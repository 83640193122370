<template>
  <AddEditAdminUserModal
    :open="isAddNewUserModalOpen"
    :adminUserForEdit="selectedAdminUserForUpdate"
    @close-modal="isAddNewUserModalOpen = false"
  />
  <div class="px-10 py-6 flex flex-col h-full">
    <div class="flex justify-between">
      <div class="flex items-center gap-2">
        <div
          class="flex h-10 w-10 min-h-10 min-w-10 items-center justify-center rounded-lg bg-opacity-20 bg-cPurple"
        >
          <div class="flex h-8 w-8 items-center justify-center rounded-md bg-cPurple">
            <Icon name="Key" :stroke-width="2" class="h-6 w-6 text-neutral-gray-1" />
          </div>
        </div>
        <p class="text-2xl font-bold uppercase">Admin Permissions</p>
      </div>

      <Button
        @click="(isAddNewUserModalOpen = true), (selectedAdminUserForUpdate = null)"
        label
        icon
        class="!text-sm"
      >
        <template #icon>
          <Icon name="Plus" class="h-4 w-4" :stroke-width="2" />
        </template>
        <template #label>Add New User</template>
      </Button>
    </div>
    <div v-if="paginatedAdminUsers.length" class="flex flex-col justify-between grow mt-6">
      <div>
        <Table
          @setSortHeader="(header, props) => setSortHeader(header, props)"
          :headers="adminUserTableHeaders"
          :tableData="UIStore.transformRecords(paginatedAdminUsers)"
          :fieldsWithCustomStyles="['user_id', 'permissions', 'navigation']"
          actions_always_sticky
          class="max-w-full"
          use_table_loader
        >
          <template v-slot:table-ctas="entry">
            <div class="flex items-center ml-1.5">
              <Button @click="handleEditAdminUser(entry.entry)" icon size="small" variant="subtle">
                <template #icon>
                  <Icon name="Pencil" class="h-4 w-4" :strokeWidth="2" />
                </template>
              </Button>
            </div>
          </template>
          <template v-slot:user_id="entry">
            <div class="flex items-center ml-2 gap-4 truncate text-sm">
              <p class="truncate">{{ entry.row.user_id.value }}</p>
              <Button
                @click="handleCopy(entry.row.user_id.value)"
                icon
                variant="subtle"
                size="small"
              >
                <template #icon>
                  <Icon
                    :name="copiedUserId === entry.row.user_id.value ? 'CheckCircle' : 'Copy'"
                    :stroke-width="2.5"
                    class="cursor-pointer"
                    :class="
                      copiedUserId === entry.row.user_id.value
                        ? 'text-green-500 h-4 w-4'
                        : 'h-4 w-4'
                    "
                  />
                </template>
              </Button>
            </div>
          </template>
          <template v-slot:permissions="entry">
            <div class="flex items-center ml-2 gap-4 truncate text-sm">
              <p class="truncate">{{ entry.row.permissions.value }}</p>
            </div>
          </template>
        </Table>
      </div>
      <Pagination
        v-if="paginatedAdminUsers.length > 0"
        show-count
        :total-category-results="authStore.allAdminUsers.length"
        :page-size="pageSize"
        :page-num="currentPage"
        @change-page="currentPage = $event"
        class="mt-8"
      />
    </div>
    <!-- <div
      v-else-if="!authStore?.allAdminUsers?.length && !UIStore.isLoading"
      class="flex flex-col gap-2 items-center justify-center mt-8"
    >
      <div class="p-2 rounded-full bg-[#6e9fd850] flex items-center justify-center h-16 w-16">
        <div class="p-2 rounded-full bg-[#6e9fd850] flex items-center justify-center h-12 w-12">
          <Icon name="Banknote" :stroke-width="2" class="text-primary-6 h-8 w-8" />
        </div>
      </div>
      <p class="font-bold text-center text-xl">No Admin Us</p>
      <Button @click="isAddToBatchModalOpen = true" label icon>
        <template #icon>
          <Icon name="Plus" class="h-4 w-4" :stroke-width="2" />
        </template>
        <template #label>Add to Batch</template>
      </Button>
    </div> -->
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import { ObjectId } from "bson";

import { Icon, Button, Pagination, Table, ConfirmDeleteModal, LoadingSpinner } from "@/components";
import { useACHBatchStore, useUIStore, useAuthStore } from "@/stores";
import AddEditAdminUserModal from "./AddEditAdminUserModal.vue";

const achBatchStore = useACHBatchStore();
const UIStore = useUIStore();
const authStore = useAuthStore();

const isAddNewUserModalOpen = ref(false);
const isShowConfirmDeleteModal = ref(false);
const selectedAdminUserForUpdate = ref(null);
const currentPage = ref(1);
const pageSize = ref(25);

const adminUserTableHeaders = ref([
  { field_name: "user_id", label: "User ID" },
  { field_name: "first_name", label: "First Name" },
  { field_name: "last_name", label: "Last Name" },
  { field_name: "permissions", label: "Permissions" },
  { field_name: "actions", label: "Actions" },
]);

const copiedUserId = ref("");

onMounted(async () => {
  UIStore.isLoading = true;
  await authStore.fetchAdminUsers();
  UIStore.isLoading = false;
});

const paginatedAdminUsers = computed(() => {
  if (!authStore.allAdminUsers || !authStore.allAdminUsers.length) return [];

  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  return authStore.allAdminUsers.slice(start, end);
});

function handleEditAdminUser(adminUser) {
  const formattedAdminUser = {};
  for (let key in adminUser) {
    formattedAdminUser[key] = adminUser[key].value;
  }

  selectedAdminUserForUpdate.value = formattedAdminUser;
  isAddNewUserModalOpen.value = true;
}

let timeout = null;
async function handleCopy(text) {
  copiedUserId.value = "";
  // Copy text to clipboard
  try {
    await navigator.clipboard.writeText(text);
    copiedUserId.value = text;

    // Clear any existing timeout
    if (timeout) {
      clearTimeout(timeout);
    }

    // Set a timeout to reset the copied status
    timeout = setTimeout(() => {
      copiedUserId.value = "";
    }, 2000);
  } catch (error) {
    console.error("Failed to copy text: ", error);
  }
}

async function setSortHeader(header, props) {
  UIStore.setSortHeader(header, props);
  await authStore.fetchAdminUsers();
}
</script>
