<template>
  <div class="relative max-w-[100vw] h-screen bg-white flex flex-col">
    <div
      v-if="!isMaintenanceMode"
      class="fixed top-0 z-50 w-full"
      :class="{ 'lg:hidden': route.path === '/sign-in' || route.path === '/sign-up' }"
    >
      <TopNavigation />
    </div>
    <TabList
      v-if="UIStore.tabs.length && authStore.isAuthenticatedNavigation"
      class="fixed top-14 z-20 lg:left-value-1"
    />
    <SideNavigation v-if="!isMaintenanceMode && authStore.isAuthenticatedNavigation" />
    <main
      :class="{
        'lg:pl-value-1': !isMaintenanceMode && authStore.isAuthenticatedNavigation,
        'pt-26': !isMaintenanceMode && UIStore.tabs.length && authStore.isAuthenticatedNavigation,
        'pt-14': !isMaintenanceMode && !UIStore.tabs.length,
        'lg:pt-0': route.path === '/sign-in',
      }"
      class="grow w-full"
    >
      <div
        class="fixed right-0 z-50 flex w-full flex-col gap-2 px-2 md:w-auto lg:mr-4 lg:px-0"
        :class="authStore.isAuthenticatedNavigation ? 'top-16' : 'top-4'"
      >
        <Notification
          v-for="alert in UIStore.alertList"
          :key="alert.id"
          :alert="alert"
          @close-alert="UIStore.removeNotification(alert.id)"
        />
      </div>
      <router-view />
    </main>
  </div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";

import { useUIStore, useAuthStore } from "@/stores";
import { TopNavigation, SideNavigation, Notification, TabList } from "@/components";

const route = useRoute();
const router = useRouter();
const UIStore = useUIStore();
const authStore = useAuthStore();
const isMaintenanceMode = import.meta.env.VITE_MAINTANENCE_MODE == "true";

onMounted(async () => {
  if (window.localStorage.getItem("currentUser")) {
    authStore.currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
    authStore.isAuthenticatedNavigation = true;
    await authStore.setTabsForLoggedInUser();
  } else {
    authStore.currentUser = null;
  }
  UIStore.setupAlertWatcher();
});

watch(
  () => authStore.invalidSession,
  async function () {
    if (authStore.invalidSession) {
      window.localStorage.clear();
      authStore.isAuthenticatedNavigation = false;
      authStore.currentUser = null;
      router.push("/sign-in");
    }
  }
);
watch(
  () => route.fullPath,
  (newValue, oldValue) => {
    if (oldValue !== newValue) {
      UIStore.current_full_path = route.fullPath;
      console.log("app route change", UIStore.current_full_path);
    }
  }
);
</script>
