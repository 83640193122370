<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 box-border md:py-5 py-3">
    <div class="bg-white border border-platform-outlines rounded-xl pt-5 pb-[2.438rem] px-5">
      <div class="flex justify-between items-center mb-3">
        <div class="text-xl text-primary-6 font-medium">Investments By Status</div>
        <BaseInput
          v-model="statusFrequencyRange"
          type="dropdown"
          :options="['Last Month', 'Last Quarter', 'This Year', 'All Time']"
          size="small"
          required
          showFullText
          class="max-w-40"
        />
      </div>
      <div>
        <DoughnutChart
          :chartData="
            investmentsByStatus_config ? investmentsByStatus_config.data : default_config_data
          "
          :chartOptions="
            investmentsByStatus_config ? investmentsByStatus_config.options : default_config_options
          "
        />
      </div>
    </div>
    <div class="bg-white border border-platform-outlines rounded-xl pt-5 pb-[2.438rem] px-5">
      <div class="flex justify-between items-center mb-3">
        <div class="text-xl text-primary-6 font-medium">Conversion Rate</div>
        <BaseInput
          v-model="conversionRateRange"
          type="dropdown"
          :options="['Last Month', 'Last Quarter', 'This Year', 'All Time']"
          size="small"
          required
          showFullText
          class="max-w-40"
        />
      </div>
      <div>
        <DoughnutChart
          :chartData="
            convertedInvestments_config ? convertedInvestments_config.data : default_config_data
          "
          :chartOptions="
            convertedInvestments_config
              ? convertedInvestments_config.options
              : default_config_options
          "
        />
      </div>
    </div>
  </div>

  <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 box-border md:py-5 py-3 h-full">
    <div class="bg-white border border-platform-outlines rounded-xl pt-5 pb-[2.438rem] px-5">
      <div class="flex justify-between items-center mb-3">
        <div class="text-xl text-primary-6 font-medium">Investment Amount Frequency</div>
        <BaseInput
          v-model="amountFrequencyRange"
          type="dropdown"
          :options="['Last Month', 'Last Quarter', 'This Year', 'All Time']"
          size="small"
          required
          showFullText
          class="max-w-40"
        />
      </div>
      <div>
        <BarChart
          :chartData="
            investmentsByAmount_config ? investmentsByAmount_config.data : default_config_data
          "
          :chartOptions="
            investmentsByAmount_config ? investmentsByAmount_config.options : default_config_options
          "
        />
      </div>
    </div>
    <div class="bg-white border border-platform-outlines rounded-xl pt-5 pb-[2.438rem] px-5">
      <div class="flex justify-between items-center mb-3">
        <div class="text-xl text-primary-6 font-medium">Investment Amount by Week</div>
        <BaseInput
          v-model="amountByWeekRange"
          type="dropdown"
          :options="['Last Month', 'Last Quarter', 'This Year', 'All Time']"
          size="small"
          required
          showFullText
          class="max-w-40"
        />
      </div>
      <div>
        <BarChart
          :chartData="
            investmentsByWeek_config ? investmentsByWeek_config.data : default_config_data
          "
          :chartOptions="
            investmentsByWeek_config ? investmentsByWeek_config.options : default_config_options
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BarChart from "./BarChart.vue";
import DoughnutChart from "./DoughnutChart.vue";
import { buildDataObject_AvgInvestment } from "../../chart-datasets/avg-investment-size-data.js";
import { buildDataObject_InvestmentsByWeek } from "../../chart-datasets/investments-by-week-data.js";
import { buildDataObject_InvestmentConversions } from "../../chart-datasets/investment-conversions-data.js";
import { buildDataObject_InvestmentStatuses } from "../../chart-datasets/investments-by-status-data.js";
import { onMounted, watch, ref } from "vue";
import { useCollectionStore } from "../../stores/useCollectionStore";
import { useInvestmentStore } from "../../stores/useInvestmentStore";
import { BaseInput } from "@/components";

const investmentStore = useInvestmentStore();
const collectionStore = useCollectionStore();

const default_config_data = ref({
  labels: [],
  datasets: [],
});
const default_config_options = ref({
  responsive: true,
  maintainAspectRatio: false,
});

const investmentsByStatus_config = ref(null);
const convertedInvestments_config = ref(null);
const investmentsByWeek_config = ref(null);
const investmentsByAmount_config = ref(null);

const amountByWeekRange = ref("Last Month");
const conversionRateRange = ref("Last Month");
const amountFrequencyRange = ref("Last Month");
const statusFrequencyRange = ref("Last Month");

onMounted(async () => {
  let investmentStatuses = await investmentStore.getInvestmentsByStatus("Last Month");
  investmentsByStatus_config.value = buildDataObject_InvestmentStatuses(investmentStatuses);
  console.log(investmentsByStatus_config.value);

  let investmentFrequency = await investmentStore.getAvgInvestmentAmount("Last Month");
  investmentsByAmount_config.value = buildDataObject_AvgInvestment(investmentFrequency);
  console.log(investmentsByAmount_config.value);

  let investmentsByWeek = await investmentStore.getInvestmentsByWeek("Last Month");
  investmentsByWeek_config.value = buildDataObject_InvestmentsByWeek(investmentsByWeek);

  let convertedInvestments = await investmentStore.getConvertedInvestments("All Time");
  console.log(convertedInvestments);
  convertedInvestments_config.value = buildDataObject_InvestmentConversions(convertedInvestments);
});

watch(
  () => amountByWeekRange.value,
  async () => {
    let investmentsByWeek = await investmentStore.getInvestmentsByWeek(amountByWeekRange.value);
    investmentsByWeek_config.value = buildDataObject_InvestmentsByWeek(investmentsByWeek);
  }
);

watch(
  () => amountFrequencyRange.value,
  async () => {
    let investmentFrequency = await investmentStore.getAvgInvestmentAmount(
      amountFrequencyRange.value,
      true
    );
    investmentsByAmount_config.value = buildDataObject_AvgInvestment(investmentFrequency);
  }
);

watch(
  () => statusFrequencyRange.value,
  async () => {
    let investmentStatuses = await investmentStore.getInvestmentsByStatus(
      statusFrequencyRange.value,
      true
    );
    investmentsByStatus_config.value = buildDataObject_InvestmentStatuses(investmentStatuses);
  }
);

watch(
  () => conversionRateRange.value,
  async () => {
    let investmentConversions = await investmentStore.getConvertedInvestments(
      conversionRateRange.value
    );
    convertedInvestments_config.value =
      buildDataObject_InvestmentConversions(investmentConversions);
  }
);
</script>
