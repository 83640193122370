export function buildDataObject_InvestmentConversions(data) {
  data.sort((a, b) => a._id - b._id);

  let labels = data.map((entry) => {
    return entry._id;
  });
  const formatLabels = (context) => {
    return context.parsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatTitles = (tooltipItem) => {
    return tooltipItem[0].label;
  };
  const investmentData = {
    type: "doughnut",
    data: {
      labels: data.map((entry) => entry.status),
      datasets: [
        {
          data: data.map((entry) => entry.count),
          backgroundColor: ["#46934680", "#6e9fd880"],
          borderColor: ["#469346", "#3f87dc"],
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        bar: {
          borderWidth: 1,
        },
      },
      cutout: "70%",
      plugins: {
        tooltip: {
          displayColors: false,
          bodyFontSize: 16,
          bodyFontColor: "Gold",
          callbacks: {
            title: formatTitles,
            label: formatLabels,
          },
        },
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: "right",
        },
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
            drawBorder: true,
            lineWidth: 2,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          display: false,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
  return investmentData;
}
