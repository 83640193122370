import { defineStore } from "pinia";
import { useAuthStore } from "./useAuthStore";

const CRUD_URL = import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT;

export const useCrudStore = defineStore("crudStore", {
  state: () => ({}),
  actions: {
    async find(collection, filter = {}, projection = null, sort = null, skip = null, limit = null) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!currentUser) throw new Error("User is not authenticated");

        const request_body = {
          collection,
          filter,
          ...(projection && { projection }),
          ...(sort && { sort }),
          ...(skip !== null && { skip }),
          ...(limit !== null && { limit }),
        };

        const response = await fetch(`${CRUD_URL}/find`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify(request_body),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error finding records from the ${collection} collection
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - find",
        });
        return err;
      }
    },
    async findOne(collection, filter = {}) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      // console.log("findOne", collection, filter);
      try {
        if (!collection) throw new Error("Collection is required");
        if (!currentUser) throw new Error("User is not authenticated");
        const response = await fetch(`${CRUD_URL}/find-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error finding one record from the ${collection} collection 
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - findOne",
        });
        return err;
      }
    },
    async insertOne(collection, document) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!document) throw new Error("No document object found for insert one operation");

        const response = await fetch(`${CRUD_URL}/insert-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, document }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error inserting one record to the ${collection} collection`,
          error_source: "crudStore - insertOne",
        });
        return err;
      }
    },
    async insertMany(collection, documents) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!documents) throw new Error("No documents found for insert many operation");

        const response = await fetch(`${CRUD_URL}/insert-many`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, documents }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error inserting many records to the ${collection} collection`,
          error_source: "crudStore - insertMany",
        });
        return err;
      }
    },
    async updateOne(collection, filter = {}, update = null, is_upsert = false) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!update) throw new Error("No update object found for update one operation");

        const response = await fetch(`${CRUD_URL}/update-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter, update, is_upsert }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error updating one record in the ${collection} collection 
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - updateOne",
        });
        return err;
      }
    },
    async updateMany(collection, filter = {}, update = null, is_upsert = false) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!update) throw new Error("No update object found for update one operation");

        const response = await fetch(`${CRUD_URL}/update-many`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter, update, is_upsert }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error updating many records in the ${collection} collection 
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - updateMany",
        });
        return err;
      }
    },
    async deleteOne(collection, filter = {}) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");

        const response = await fetch(`${CRUD_URL}/delete-one`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error deleting one record from the ${collection} collection 
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - deleteOne",
        });
        return err;
      }
    },
    async deleteMany(collection, filter = {}) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");

        const response = await fetch(`${CRUD_URL}/delete-many`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, filter }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error deleting many records from the ${collection} collection 
          - Query: ${JSON.stringify(filter)}`,
          error_source: "crudStore - deleteMany",
        });
        return err;
      }
    },
    async aggregate(collection, pipeline) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!pipeline) throw new Error("Pipeline is required");

        const response = await fetch(`${CRUD_URL}/aggregate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, pipeline }),
        });

        let response_json = await response.json();
        if (response_json.status === 401) {
          authStore.invalidSession = true;
        } else {
          return response_json;
        }
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error with aggregation operation on the ${collection} collection 
          - Query: ${JSON.stringify(pipeline)}`,
          error_source: "crudStore - aggregate",
        });
        return err;
      }
    },
    async countRecords(collection, pipeline) {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        if (!collection) throw new Error("Collection is required");
        if (!pipeline) throw new Error("Pipeline is required");

        // add count stage to pipeline
        pipeline.push({ $count: "recordCount" });
        // remove limit stage from pipeline
        pipeline = pipeline.filter(
          (stage) => !stage.hasOwnProperty("$limit") && !stage.hasOwnProperty("$skip")
        );

        const response = await fetch(`${CRUD_URL}/aggregate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: currentUser.id,
            session_id: currentUser.session_id,
          },
          body: JSON.stringify({ collection, pipeline }),
        });
        //return response.json(); this was what was originally here - changed by Kamary
        let final_response = await response.json();
        return final_response[0].recordCount || 0;
      } catch (err) {
        console.error(err);
        await authStore.sendCustomLog({
          error: err,
          error_type: "Error",
          error_summary: `Error with aggregation operation on the ${collection} collection 
          - Query: ${JSON.stringify(pipeline)}`,
          error_source: "crudStore - aggregate",
        });
        return err;
      }
    },
  },
});
