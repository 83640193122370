<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" @close-modal="$emit('close-modal')" size="small-narrow">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="flex flex-row items-center">
          <div
            class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-info-click bg-opacity-20 p-1"
          >
            <IconSetting class="h-5 w-5 fill-functional-info-click" />
          </div>

          <div class="font-bold text-custom-navy-2">Manage Properties</div>
        </div>
      </template>
      <template v-slot:modal-tabs>
        <div class="no-scrollbar ml-4 flex overflow-x-scroll">
          <!-- Render tabs -->
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            @click="activeTab = tab"
            :class="
              activeTab === tab
                ? ' border-secondary-6 bg-secondary-6 bg-opacity-20 font-bold'
                : 'border-transparent'
            "
            class="flex flex-grow-0 cursor-pointer flex-row items-center justify-center whitespace-nowrap border-b-3 p-3"
          >
            <div
              class="flex h-5 flex-row items-center gap-2 text-left text-base text-primary-6"
              :class="activeTab === tab ? 'text-primary-6' : 'text-dark'"
            >
              <div>
                <IconSearch
                  v-if="tab === 'Search Results'"
                  class="h-5.5 w-5.5"
                  :class="activeTab === tab ? 'fill-primary-4' : 'fill-neutral-gray-6'"
                />
                <IconTableRows
                  v-if="tab === 'Edit Groups'"
                  class="h-5.5 w-5.5"
                  :class="activeTab === tab ? 'fill-primary-4' : 'fill-neutral-gray-6'"
                />
                <IconX
                  v-if="tab === 'Related Lists'"
                  class="h-5.5 w-5.5"
                  :class="activeTab === tab ? 'fill-primary-4' : 'fill-neutral-gray-6'"
                />
              </div>

              {{ tab }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-body>
        <!-- Display content based on the active tab -->

        <div
          v-if="activeTab === 'Edit Groups'"
          class="no-scrollbar overflow-y-auto bg-neutral-gray-2 p-4"
        >
          <Button
            variant="default-gray-outlined"
            icon
            label
            class="w-full"
            @click="addGroup()"
            :disabled="inEditMode"
          >
            <template #icon><IconPlus class="fill-primary-6" /></template>
            <template #label
              ><p class="text-center text-sm text-primary-6">Add Group</p></template
            ></Button
          >
          <div class="mt-3 space-y-1">
            <div
              v-for="(group, index) in calculated_groups"
              :key="index"
              class="flex min-h-9 items-center justify-between py-1"
            >
              <div class="flex flex-row items-center">
                <div class="flex flex-row space-x-1">
                  <Button
                    v-if="currentEditGroupIndex !== index"
                    @click="moveGroup(group, 'RDG', 'up')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="group === calculated_groups[0] || inEditMode"
                  >
                    <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
                  </Button>
                  <Button
                    v-if="currentEditGroupIndex !== index"
                    @click="moveGroup(group, 'RDG', 'down')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="
                      group === calculated_groups[calculated_groups.length - 1] || inEditMode
                    "
                  >
                    <template #icon><IconExpandMore class="h-4 w-4" /></template>
                  </Button>
                  <Button
                    v-if="currentEditGroupIndex === index"
                    @click="saveEdit(index, group)"
                    :disabled="currentGroup === ''"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                  >
                    <template #icon
                      ><IconCheckmark class="h-4 w-4 fill-functional-success-default" />
                    </template>
                  </Button>
                  <Button
                    v-if="currentEditGroupIndex === index"
                    @click="discardEdit(group)"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                  >
                    <template #icon
                      ><IconX class="h-4 w-4 fill-functional-error-default"
                    /></template>
                  </Button>
                </div>
                <div v-if="currentEditGroupIndex === index" class="w-value-21 md:w-180 lg:w-96">
                  <BaseInput
                    v-if="currentEditGroupIndex === index"
                    v-model="currentGroup"
                    type="text"
                    size="large"
                    class="ml-1.5 rounded-lg border border-primary-6"
                  />
                </div>

                <p v-else class="ml-3 text-sm text-dark">{{ group }}</p>
              </div>

              <div class="flex flex-row space-x-1">
                <Button
                  v-if="!inEditMode"
                  @click="deleteGroup(group)"
                  icon
                  variant="default-gray-outlined"
                  size="small"
                >
                  <template #icon>
                    <Icon
                      name="Trash"
                      class="h-4.5 w-4.5 stroke-functional-error-default"
                      :stroke-width="2"
                  /></template>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="activeTab === 'Related Lists'"
          class="no-scrollbar overflow-y-auto bg-neutral-gray-2 p-4"
        >
          <div class="space-y-1">
            <div
              v-for="(group, index) in dataModelManagementStore.relatedListArray"
              :key="index"
              class="flex min-h-9 items-center justify-between py-1"
            >
              <div class="flex flex-row items-center">
                <div class="flex flex-row space-x-1">
                  <Button
                    @click="moveGroup(group, 'RL', 'up')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="group === dataModelManagementStore.relatedListArray[0]"
                  >
                    <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
                  </Button>
                  <Button
                    @click="moveGroup(group, 'RL', 'down')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="
                      group ===
                      dataModelManagementStore.relatedListArray[
                        dataModelManagementStore.relatedListArray - 1
                      ]
                    "
                  >
                    <template #icon><IconExpandMore class="h-4 w-4" /></template>
                  </Button>
                </div>

                <p class="ml-3 text-sm text-dark">{{ group.label }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="activeTab === 'Search Results'"
          class="no-scrollbar h-80 overflow-y-auto bg-neutral-gray-2 p-4"
        >
          <div class="space-y-1">
            <div
              v-for="(group, index) in dataModelManagementStore.searchResultsArray"
              :key="index"
              class="flex min-h-9 items-center justify-between py-1"
            >
              <div class="flex flex-row items-center">
                <div class="flex flex-row space-x-1">
                  <Button
                    @click="moveGroup(group, 'SR', 'up')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="group === dataModelManagementStore.searchResultsArray[0]"
                  >
                    <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
                  </Button>
                  <Button
                    @click="moveGroup(group, 'SR', 'down')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="
                      group ===
                      dataModelManagementStore.searchResultsArray[
                        dataModelManagementStore.searchResultsArray - 1
                      ]
                    "
                  >
                    <template #icon><IconExpandMore class="h-4 w-4" /></template>
                  </Button>
                </div>

                <p class="ml-3 text-sm text-dark">{{ group.label }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="flex flex-row justify-end gap-1">
          <Button label variant="default-gray-outlined" size="large" @click="$emit('close-modal')">
            <template #label><p>Cancel</p></template></Button
          >
          <Button
            size="large"
            :disabled="inEditMode"
            label
            @click="updateGroups(), $emit('close-modal')"
          >
            <template #label>Confirm</template></Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

import { BaseInput, Button, Modal } from "@/components";
import {
  IconExpandLess,
  IconExpandMore,
  IconTableRows,
  IconPlus,
  IconDelete,
  IconEdit,
  IconX,
  IconCheckmark,
  IconSetting,
  IconSearch,
} from "@/components/icons";
import { Icon } from "@/components";

import { useDataModelManagementStore } from "@/stores/useDataModelManagementStore";

const dataModelManagementStore = useDataModelManagementStore();

const currentEditGroupIndex = ref(null);
const originalGroup = ref("");
const inEditMode = ref(false);
const currentGroup = ref("");
const calculated_groups = ref([]);
const addingnew = ref(true);
const activeTab = ref("Edit Groups");
const tabs = ref(["Edit Groups", "Related Lists", "Search Results"]);

watch(
  () => dataModelManagementStore.finalGroupingOrder,
  () => {
    const filteredGroupingOrder = dataModelManagementStore.finalGroupingOrder.filter(
      (item) => item !== ""
    );

    calculated_groups.value = filteredGroupingOrder;
  }
);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  modalData: {
    type: Array,
    required: true,
  },
});

watch(
  () => props.open,
  async () => {
    if (!props.open) {
      await dataModelManagementStore.setGroupData();
      activeTab.value = "Edit Groups";
    }
  }
);

function moveGroup(group, group_str, direction) {
  let array;
  switch (group_str) {
    case "RDG":
      array = calculated_groups.value;
      break;
    case "RL":
      array = dataModelManagementStore.relatedListArray;
      break;
    case "SR":
      array = dataModelManagementStore.searchResultsArray;
      break;
    default:
      return;
  }

  const index = array.indexOf(group);
  if (direction === "up" && index > 0) {
    // Ensure it's not the first item
    const itemToMove = array.splice(index, 1)[0];
    array.splice(index - 1, 0, itemToMove);
  } else if (direction === "down" && index < array.length - 1) {
    // Ensure it's not the last item
    const itemToMove = array.splice(index, 1)[0];
    array.splice(index + 1, 0, itemToMove);
  }
}

function deleteGroup(group) {
  const index = calculated_groups.value.indexOf(group);
  if (index > -1) {
    // Ensure the item exists
    calculated_groups.value.splice(index, 1);
  }
}

function discardEdit(index) {
  if (addingnew.value === true) {
    calculated_groups.value.shift();
  }
  addingnew.value = false;
  calculated_groups.value[index] = originalGroup.value;

  currentEditGroupIndex.value = -1;

  inEditMode.value = false;
  currentGroup.value = "";
}
function saveEdit(index) {
  addingnew.value = false;
  calculated_groups.value[index] = currentGroup.value;
  inEditMode.value = false;
  currentGroup.value = "";
  currentEditGroupIndex.value = -1;
}
function addGroup() {
  addingnew.value = true;
  calculated_groups.value.unshift("");
  inEditMode.value = true;
  currentEditGroupIndex.value = 0;
}
async function updateGroups() {
  const search_result_final = JSON.parse(
    JSON.stringify(dataModelManagementStore.searchResultsArray)
  );
  const rl_final = JSON.parse(JSON.stringify(dataModelManagementStore.relatedListArray));
  await dataModelManagementStore.setGroups(calculated_groups.value, search_result_final, rl_final);
}
</script>
