export function buildDataObject_AvgInvestment(data) {
  data.sort((a, b) => a._id - b._id);

  let labels = data.map((entry) => {
    return entry._id;
  });

  // For tooltips
  const formatLabels = (context) => {
    let label = context.dataset.label || "";
    if (context.parsed.y !== null) {
      label =
        context.parsed.y > 1 ? context.parsed.y + " investments" : context.parsed.y + " investment";
    }
    return label;
  };

  const formatTitles = (tooltipItem) => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
      tooltipItem[0].label
    );
  };

  const investmentData = {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          data: data.map((entry) => {
            return entry.frequency;
          }),
          backgroundColor: "#6e9fd880",
          borderColor: "#3f87dc",
          borderWidth: 1,
        },
      ],
    },
    options: {
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      plugins: {
        tooltip: {
          displayColors: false,
          bodyFontSize: 16,
          bodyFontColor: "Gold",
          callbacks: {
            title: formatTitles,
            label: formatLabels,
          },
        },
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: true,
            lineWidth: 2,
          },
          ticks: {
            callback: function (value) {
              return "$" + labels[value].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format commas in number
            },
          },
        },
        y: {
          grid: {
            display: true,
            callback: function (value) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format commas in number
            },
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format commas in number
            },
          },
        },
      },
      datalabels: {
        color: "black",
        font: {
          weight: "bold",
        },
      },
    },
  };
  return investmentData;
}
