<template>
  <Modal
    :hideModalTabs="true"
    @close-modal="$emit('close-modal')"
    :open="open"
    :size="is_account_on_other_investments ? 'large' : 'small'"
    class="custom-size-transition"
  >
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-error-default bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-error-default"
            :is="iconMapping['IconDelete']"
          />
        </div>

        <div class="font-bold text-custom-navy-2">Delete Bank Account</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div v-if="loading" class="h-full w-full flex items-center justify-center">
        <LoadingSpinner :show="true" :hide-overlay="true" position="absolute" />
      </div>
      <div
        v-else
        class="w-full bg-white flex flex-col gap-4 items-center justify-start px-4"
        :class="is_account_on_other_investments ? 'h-fit' : 'h-full'"
      >
        <div v-if="is_account_on_other_investments" class="p-4 flex flex-col items-center gap-4">
          <p class="text-functional-error-default text-center w-3/4">
            This bank account currently active in some investments. Please choose a replacement
            account to be used once this account is deleted.
          </p>
          <div class="self-start">
            <span class="text-lg font-semibold">Affected Investments</span>
            <div v-for="investment in affected_investments" class="flex flex-col gap-2">
              <span v-if="recordDetailStore.currentPageDocument._id == investment._id"
                >This Investment</span
              >
              <a
                v-else
                class="text-functional-warning-hover hover:underline cursor-pointer"
                :href="`investments?recordId=${investment._id}`"
                target="_blank"
                >{{ investment._id }}</a
              >
            </div>
          </div>
          <div class="flex flex-col gap-2 w-full">
            <span class="text-lg font-semibold">Select Replacement Account</span>
            <div class="grid grid-cols-2 gap-2">
              <div v-for="(account, index) in bank_accounts">
                <!-- Check  -->
                <div v-if="account._id == 'check'" class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
                  <div
                    class="border rounded-xl bg-white p-4 flex flex-row items-center h-full grow cursor-pointer"
                    :class="
                      selectedReplacementAccountId === account._id
                        ? 'border-secondary-6'
                        : 'border-platform-outlines'
                    "
                    @click="selectedReplacementAccountId = account._id"
                  >
                    <RadioOption
                      :id="'' + index"
                      :name="'Account' + index"
                      value="check"
                      v-model="selectedReplacementAccountId"
                      :label="account.financial_institution_name"
                      description=""
                      :account_num="account.account_number"
                      class="cursor-pointer z--10 pointer-events-none"
                    />
                    <div>
                      <Icon name="Banknote" class="w-12 text-primary-6 lg:invisible xl:visible" />
                    </div>
                  </div>
                </div>
                <!-- Wire -->
                <div
                  v-else-if="account._id == 'wire'"
                  class="bg-neutral-gray-2 p-4 rounded-2xl h-36"
                >
                  <div
                    class="border rounded-xl bg-white p-4 flex flex-row items-center h-full cursor-pointer"
                    :class="
                      selectedReplacementAccountId === account._id
                        ? 'border-secondary-6'
                        : 'border-platform-outlines'
                    "
                    @click="selectedReplacementAccountId = account._id"
                  >
                    <RadioOption
                      :id="'' + index"
                      :name="'Account' + index"
                      value="wire"
                      v-model="selectedReplacementAccountId"
                      :label="account.financial_institution_name"
                      description=""
                      account_num=""
                      class="cursor-pointer z--10 pointer-events-none"
                    />
                    <div>
                      <Icon name="DollarSign" class="w-12 text-primary-6 lg:invisible xl:visible" />
                    </div>
                  </div>
                </div>
                <!-- Bank Accounts -->
                <div v-else class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
                  <div
                    class="border rounded-xl bg-white p-4 flex flex-row items-center h-full cursor-pointer"
                    :class="[
                      selectedReplacementAccountId === account._id
                        ? 'border-secondary-6'
                        : 'border-platform-outlines',
                    ]"
                    @click="selectedReplacementAccountId = account._id"
                  >
                    <RadioOption
                      :id="'' + index"
                      :name="'Account' + index"
                      :value="account._id"
                      v-model="selectedReplacementAccountId"
                      :label="account.financial_institution_name"
                      :description="account.account_type"
                      :account_num="account.account_number"
                      class="cursor-pointer z--10"
                    />
                    <div v-if="account.logo">
                      <img
                        class="w-12 lg:invisible xl:visible"
                        :src="'data:image/png;base64, ' + account.logo + ''"
                        alt="Red dot"
                      />
                    </div>
                    <div v-else>
                      <IconLandmark class="w-12 lg:invisible xl:visible" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="p-4 w-3/4 text-center flex items-center justify-center h-full">
          <p>Are you sure you want to delete this bank account?</p>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="handleDeleteBankAccount">
          <template #label>
            {{ is_account_on_other_investments ? "Delete and Replace" : "Delete" }}
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import { Modal, Button, BaseInput, Banner, RadioOption, LoadingSpinner } from "@/components";
import {
  useCrudStore,
  useCollectionStore,
  useUIStore,
  useInvestorStore,
  useRecordDetailStore,
} from "@/stores";
import { iconMapping } from "@/constants/iconMapping";
import InvestmentApprovals from "@/pages/InvestmentApprovals/InvestmentApprovals.vue";
import InvestmentsEarningTab from "@/pages/Investments/InvestmentsEarningTab.vue";

const crudStore = useCrudStore();
const collectionStore = useCollectionStore();
const UIStore = useUIStore();
const investorStore = useInvestorStore();
const recordDetailStore = useRecordDetailStore();

const is_account_on_other_investments = ref(false);
const affected_investments = ref([]);
const is_current_account_affected = ref(false);
const loading = ref(false);

const showMore = ref(false);
const selectedReplacementAccountId = ref(null);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  account: {
    type: Object,
    required: false,
  },
  account_type: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(["close-modal", "account-updated"]);

// created array of account options. Check, Wire, and Investor Bank Accounts
const bank_accounts = computed(() => {
  const check_option = {
    _id: "check",
    financial_institution_name: "Check",
    account_type: "check",
    account_number: "",
    logo: "",
  };

  const wire_option = {
    _id: "wire",
    financial_institution_name: "Wire",
    account_type: "wire",
    account_number: "",
    logo: "",
  };

  var bank_array = [];
  bank_array.push(check_option);

  if (props.account_type === "Funding") {
    bank_array.push(wire_option);
  }

  bank_array.push(...investorStore.bank_accounts.filter((o) => o._id != props.account._id));

  return bank_array;
});

onMounted(async () => {});

const isSaveDisabled = computed(() => {
  return is_account_on_other_investments.value && !selectedReplacementAccountId.value;
  return false;
});

async function handleDeleteBankAccount() {
  //console.log("handleUpdateBankAccount");
  //console.log(bankAccountEdits.value);
  loading.value = true;

  if (is_account_on_other_investments.value) {
    let replace_result = await handleReplaceBankAccounts();
    if (!replace_result) {
      // there was an error in account replacement; do not delete account
      loading.value = false;
      return;
    }
  }

  let filter = {
    _id: props.account._id,
  };

  var res = await crudStore.deleteOne("BankAccounts", filter);
  //var res = {};
  console.log(res);
  if (res.deletedCount == 1) {
    var alert = {
      type: "success",
      icon: "BadgeCheck",
      message: "Bank account deleted successfully",
    };
  } else {
    var alert = {
      type: "error",
      icon: "IconX",
      message: "Failed to delete bank account",
    };
  }

  UIStore.animateNotificationAlert(alert);

  if (is_current_account_affected.value) emit("account-replaced");
  emit("account-updated");
  emit("close-modal");
  loading.value = false;
}

async function handleReplaceBankAccounts() {
  //console.log("handleReplaceBankAccounts");
  //console.log(selectedReplacementAccountId.value);
  //console.log(affected_investments.value);

  var all_promises = [];
  // for each investment affected, we will push an update to it to replace the account
  for (let investment of affected_investments.value) {
    console.log(investment);

    var account_fields_to_update = [];
    // determine where account to be deleted is: distribution or funding (or both)
    if (investment.distribution_account == props.account._id) {
      account_fields_to_update.push("distribution_account");
    }

    if (investment.funding_account == props.account._id) {
      account_fields_to_update.push("funding_account");
    }

    // filter for investments that have this account
    let filter = {
      _id: investment._id,
    };

    var update = { $set: {} };

    if (selectedReplacementAccountId.value == "check") {
      for (let field of account_fields_to_update) {
        // set distro/pay method to Check and account to null

        if (field == "distribution_account") {
          update.$set.distrubution_method = "Check";
        }

        if (field == "funding_account") {
          update.$set.payment_method = "Check";
        }
        update.$set[field] = null;
      }
    } else if (selectedReplacementAccountId.value == "wire") {
      for (let field of account_fields_to_update) {
        // set distro/pay method to Check and account to null

        if (field == "distribution_account") {
          update.$set.distrubution_method = "Check";
        }

        if (field == "funding_account") {
          update.$set.payment_method = "Check";
        }
        update.$set[field] = null;
      }
    } else {
      // likely ACH account
      for (let field of account_fields_to_update) {
        // set distro/pay method to Check and account to null
        update.$set[field] = selectedReplacementAccountId.value;
      }
    }

    console.log(filter, update);
    all_promises.push(crudStore.updateOne("Investments", filter, update));
  }

  var res;
  try {
    const results = await Promise.all(all_promises);
    res = true;
  } catch (error) {
    console.error(error);
    res = false;
  }

  if (res) {
    var alert = {
      type: "success",
      icon: "BadgeCheck",
      message: "Bank accounts replaced successfully",
    };
  } else {
    var alert = {
      type: "error",
      icon: "IconX",
      message: "Failed to replace bank accounts",
    };
  }

  UIStore.animateNotificationAlert(alert);

  return res;
}

// vue watcher to check for account on other investments
watch(props, async (value) => {
  if (value.open) {
    loading.value = true;
    selectedReplacementAccountId.value = null;
    is_current_account_affected.value = false;
    affected_investments.value = [];
    is_account_on_other_investments.value = false;
    // check if this account is on other investments
    let filter = {
      // where props.account._id is either distrubution_account or funding_account
      $or: [{ distribution_account: props.account._id }, { funding_account: props.account._id }],
    };
    var res = await crudStore.find("Investments", filter);
    //console.log(res);

    if (res.length > 0) {
      is_account_on_other_investments.value = true;
      affected_investments.value = res;
      if (props.account_type === "Funding") {
        is_current_account_affected.value = res.some((o) => o.funding_account == props.account._id);
      } else if (props.account_type === "Distribution") {
        is_current_account_affected.value = res.some(
          (o) => o.distribution_account == props.account._id
        );
      }
    } else {
      is_account_on_other_investments.value = false;
    }
    loading.value = false;
  }
});
</script>
