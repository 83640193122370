<template>
  <div
    class="absolute self-start top-0 sm:top-3 z-[100] mx-auto flex flex-col w-full max-w-160 items-start sm:items-center transition-opacity duration-300 sm:relative sm:transition-none pointer-events-none"
  >
    <div
      class="absolute top-0 z-[100] flex w-full max-w-160 items-center self-center transition-opacity duration-300 sm:relative sm:transition-none pointer-events-auto"
      :class="
        searchStore.isSearchFocused
          ? 'visible opacity-100'
          : 'invisible opacity-0 sm:visible sm:max-w-160 sm:opacity-100'
      "
    >
      <div
        class="border-default-grey-4 mx-auto flex h-value-1 w-full max-w-103 flex-grow items-center gap-3 px-4 transition-all duration-300 sm:relative sm:h-8.5 sm:min-h-8.5 sm:w-auto sm:rounded-[5px] lg:max-w-160"
        :class="[
          searchStore.isSearchFocused
            ? 'top-0 absolute flex !max-w-full !rounded-b-none bg-white sm:flex lg:max-w-160'
            : 'md:flex',
          searchStore.searchTerm.length && !searchStore.isSearchFocused
            ? 'bg-white sm:rounded-b-[5px]'
            : 'bg-primary-5',
        ]"
      >
        <div
          class="z-50 flex min-h-9 min-w-9 cursor-pointer items-center justify-center rounded bg-neutral-gray-4 sm:hidden"
          v-if="searchStore.isSearchFocused"
          @click="searchStore.isSearchFocused = false"
        >
          <IconChevronLeft class="z-50 md:hidden"></IconChevronLeft>
        </div>

        <IconSearch class="z-50 h-5 min-h-5 w-5 min-w-5 fill-primary-1" />
        <input
          type="text"
          placeholder="Search"
          ref="searchInput"
          v-model="searchStore.searchTerm"
          class="z-50 h-full w-full caret-neutral-gray-5 outline-none transition-all duration-300 placeholder:text-sm focus:outline-none"
          autocomplete="off"
          :class="isActiveSearch ? 'bg-white text-dark' : 'bg-primary-5'"
          @keyup.enter="handleEnter"
          @keyup.esc="searchStore.searchTerm = ''"
          @focus="handleFocus"
          @input="debouncedSearch()"
        />
        <div
          v-if="searchStore.isSearchFocused && searchStore.searchTerm"
          class="z-50 m-auto cursor-pointer self-end p-2 transition-opacity"
          @click="
            searchStore.searchTerm = '';
            searchStore.isSearchFocused = true;
            searchStore.searchResults = [];
          "
        >
          <IconX />
        </div>
      </div>
    </div>
    <div
      class="border-neutral-gray-5 border-t-2 bg-white m-0 flex w-full h-full overflow-y-auto pb-value-1 sm:pb-0 sm:h-auto flex-grow pointer-events-auto items-center gap-3 transition-all duration-300 fixed top-value-1 sm:top-0 left-0 sm:relative sm:min-h-8.5 sm:rounded-b-[5px]"
      :class="
        searchStore.isSearchFocused ? 'visible opacity-100' : 'invisible opacity-0 sm:max-w-160'
      "
    >
      <!-- LOADING -->
      <div
        v-if="loading"
        class="w-full h-auto p-4 flex items-center align-middle justify-center min-h-44"
      >
        <LoadingSpinner position="relative" :show="true" :hide-overlay="true" message="loading" />
      </div>
      <!-- RESULTS -->
      <div
        v-else-if="searchStore.searchResults.nbHits"
        class="flex flex-col w-full h-auto p-4 self-start"
      >
        <div
          v-for="source of [
            ...new Set(searchStore.searchResults.hits.map((result) => result.source)),
          ]"
          class="py-2 border-b-2 border-neutral-gray-5 last:border-none"
        >
          <span class="capitalize text-sm font-medium text-lighter">{{ source }}</span>
          <div class="w-full h-auto grid grid-cols-1 sm:grid-cols-2 items-start justify-start">
            <div
              v-for="result of searchStore.searchResults.hits.filter(
                (result) => result.source == source
              )"
              class="flex flex-row gap-2 p-2 hover:bg-neutral-gray-5 cursor-pointer w-full rounded-lg"
              @click="routeToResult(result)"
            >
              <div class="flex items-center justify-center rounded-lg bg-opacity-20">
                <div
                  class="flex h-8 w-8 items-center justify-center rounded-lg"
                  :class="searchStore.source_dictionary[result.source]?.bg"
                >
                  <component
                    :is="searchStore.source_dictionary[result.source]?.icon"
                    class="h-5.5 w-5.5 fill-primary-1-light"
                  />
                </div>
              </div>
              <div class="max-w-[calc(100%-5rem)]">
                <p
                  class="mb-0.5 truncate text-sm font-medium text-neutral-gray-13"
                  v-highlight="searchStore.searchTerm"
                >
                  {{ getDisplayText(result) }}
                </p>
                <p class="text-medium text-xs text-neutral-gray-7">
                  {{ getSubDisplayText(result) }}
                </p>
              </div>
              <!-- <span
            >{{ result.source.charAt(0).toUpperCase() + result.source.slice(1).replace(/s$/, "") }}:
          </span>
          <span>{{ getDisplayText(result) }}</span>
          <span class="text-neutral-gray-7">{{ getSubDisplayText(result) }}</span> -->
            </div>
          </div>
        </div>
      </div>
      <!-- NO RESULTS -->
      <div
        v-else-if="searchStore.searchTerm"
        class="w-full h-auto p-4 flex flex-col gap-2 items-center align-middle justify-center min-h-44 text-center"
      >
        <IconSearchOff class="h-6 w-6 fill-neutral-gray-6" />
        No results found for "{{ searchStore.searchTerm }}"
      </div>
      <!-- EMPTY -->
      <div
        v-else
        class="w-full h-auto p-4 flex items-center align-middle justify-center min-h-44 text-center"
      >
        Search for Name, Email, Contact ID, or Investment ID
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, markRaw, onMounted } from "vue";
import { useRouter } from "vue-router";
import _ from "lodash";

import { LoadingSpinner } from "@/components";
import { IconSearch, IconChevronLeft, IconX, IconSearchOff } from "@/components/icons";
import { useSearchStore, useUIStore, useAuthStore, useCollectionStore } from "@/stores";
import { debounce } from "@/utilities";

const router = useRouter();

const searchStore = useSearchStore();
const UIStore = useUIStore();
const authStore = useAuthStore();
const collectionStore = useCollectionStore();

const activeSearchResultsTab = ref({ title: "All", icon: markRaw(IconX), source: "all" });

const isActiveSearch = computed(() => searchStore.isSearchFocused || searchStore.searchTerm.length);
const searchInput = ref(null);
const loading = ref(0);

onMounted(async () => {
  // getUserRecents();
});

function setActiveSearch() {
  searchStore.isSearchFocused = true;
  //nextTick(() => searchInput.value.focus());
}

async function mainSearch() {
  //console.log("searching...");
  loading.value += 1;
  if (searchStore.searchTerm.length > 0) {
    try {
      let results = await searchStore.getTopResults(searchStore.searchTerm, 6);
      if (results) {
        searchStore.searchResults = results;
      } else {
        searchStore.searchResults = [];
      }
    } catch (err) {
      console.error(err);
      searchStore.searchResults = [];
    }
  } else {
    searchStore.searchResults = [];
  }
  loading.value -= 1;
}

let debouncedSearch = debounce(mainSearch, 400);

// store to user object
// call to get user stuffs once again.
async function routeToResult(result) {
  // get result path from source dictionary and route to it with _id apppended
  const resultPath = searchStore.source_dictionary[result.source].path;
  const route = `${resultPath}?recordId=${result._id}`;

  //console.log("routing to: ", route);
  searchStore.isSearchFocused = false;
  searchStore.searchTerm = "";
  router.push(route);

  // let user = authStore.currentUserDocument;
  // await searchStore.storeRecentTerm(searchInput.value.value);
  // await searchStore.storeRecentObject(result);
  // getUserRecents();
  // // META: updating the store will happen in getUserResults!
  // // searchStore.recentlyTypedSearches = [
  // //   searchStore.searchTerm,
  // //   ...searchStore.recentlyTypedSearches,
  // // ].slice(0, 4);
  // // searchStore.recentlySearchedObjects = [result, ...searchStore.recentlySearchedObjects].slice(
  // //   0,
  // //   6
  // // );
  // searchStore.searchTerm = "";
  // searchStore.isSearchFocused = false;
  // // nav to /source?reportid={reportid}
  // if (result.source == "title") {
  //   // exception where the source != route name...
  //   router.push(`/title-assignments?recordId=${result._id}`);
  // } else {
  //   router.push(`/${result.source}?recordId=${result._id}`);
  // }
  // //router.push(`/search-results/${result.api_10}`);
  // searchInput.value.blur();
}

async function getUserRecents() {
  // update current user Document
  authStore.currentUserDocument = await collectionStore.findOneRecord("Users", {
    user_id: authStore.currentUser.id,
  });

  // set store values for recents
  searchStore.recentlySearchedObjects = authStore.currentUserDocument?.search_recent_objects;
  searchStore.recentlyTypedSearches = authStore.currentUserDocument?.search_recent_terms;

  if (searchStore.recentlySearchedObjects == null) searchStore.recentlySearchedObjects = [];
  if (searchStore.recentlyTypedSearches == null) searchStore.recentlyTypedSearches = [];
}

async function handleEnter() {
  // // failsafe for when state changes and user immedietely inputs enter
  // if (searchStore.searchTerm == undefined) searchStore.searchTerm = searchInput.value.value;
  // await searchStore.storeRecentTerm(searchInput.value.value);
  // await getUserRecents();
  // initSearch(searchStore.searchTerm);
}

function handleFocus() {
  searchStore.isSearchFocused = true;
  mainSearch();
  // getUserRecents();
}

function getDisplayText(result) {
  // comma seperate the display field and add each to the display text
  const displayFields = searchStore.source_dictionary[result.source].display_field
    .split(",")
    .map((field) => field.trim());
  let displayText = "";
  displayFields.forEach((field) => {
    displayText += result[field] + " ";
  });
  return displayText;

  return result[searchStore.source_dictionary[result.source].display_field];
}

function getSubDisplayText(result) {
  return result[searchStore.source_dictionary[result.source].sub_display_field];
}

watch(
  () => searchStore.isSearchFocused,
  () => {
    if (searchStore.isSearchFocused) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
  { deep: true }
);

defineExpose({ setActiveSearch });
</script>
