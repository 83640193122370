<template>
  <SideDrawer
    @close-drawer="$emit('close-drawer')"
    :open="open"
    customWidth="lg:w-3/4"
    id="quick-ref-rel-list"
    :hideHeader="true"
    :showFooter="true"
  >
    <template v-slot:tabs> <div class="font-semibold">Process Title Transfer</div> </template>

    <template v-slot:main-content><TitleTransferFlow /> </template>
    <template v-slot:footer><TransferFlowBackAndNext /></template>
  </SideDrawer>
</template>
<script setup>
import { SideDrawer } from "@/components";
import { useTitleTransferStore } from "@/stores";
import { TitleTransferFlow } from "@/pages";
import TransferFlowBackAndNext from "./TransferFlowBackAndNext.vue";

const titleTransferStore = useTitleTransferStore();
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
</script>
