<template>
  <div
    class="relative flex h-12 max-w-[280px] cursor-pointer items-center pr-2"
    :class="index === 0 ? 'pl-2' : ''"
    @click.prevent="handleSetActiveTab(tab.id)"
    @mouseover="handleHoverTab(true, tab.id)"
    @mouseleave="handleHoverTab(false, tab.id)"
    @contextmenu.prevent="handleRightClickMenu(tab.id)"
    :data-id="tab.id"
    draggable="true"
    @dragstart="dragStart"
    @drop.prevent="drop"
    @dragover.prevent="dragOver(tab.id)"
    :id="`navigation-tab-${tab.id}`"
  >
    <div
      class="h-8 text-sm flex items-center justify-center rounded px-2 gap-2"
      :class="tab.is_active ? 'bg-[#d7dfea]' : 'hover:bg-button-primary-hover'"
      :id="`navigation-tab-text-${tab.id}`"
    >
      <IconPinActive
        v-if="tab.is_pinned"
        class="h-5 w-5"
        :class="tab.is_pinned ? 'visible fill-primary-6' : 'invisible'"
      />
      <p class="whitespace-nowrap">
        {{ tab.name }}
      </p>
      <Icon
        @click.prevent.stop="handleRightClickMenu(tab.id)"
        name="EllipsisVertical"
        :stroke-width="2"
        class="w-5 h-5 text-primary-6"
      />
    </div>
    <div class="h-8 border-r border-platform-outlines pl-2"></div>
    <!-- <Teleport to="body">
      <Tooltip
        v-if="isTabHovered"
        class="fixed z-50 hidden md:block"
        :text="tooltipText"
        :style="{
          top: tooltipPosition.top + 'px',
          left: tooltipPosition.left + 'px',
          maxWidth: tooltipMaxWidthStyle,
        }"
      />
    </Teleport> -->
    <Teleport to="body">
      <div
        id="options-menu"
        v-if="tab.is_show_options"
        class="fixed z-30 ml-2 mt-1 min-h-[9.125rem] w-[11.65rem] cursor-pointer rounded-md bg-white py-2 text-sm shadow-lg md:mt-2 lg:ml-[.188rem] lg:mt-value-25"
        :style="{
          top: contextMenuPosition.top + 'px',
          left: contextMenuPosition.left + 'px',
        }"
      >
        <ul>
          <li
            v-if="!tab.is_pinned"
            @click.prevent.stop="togglePinTab(tab.id, true)"
            class="bg-white p-2 px-4 hover:bg-platform-hover-secondary hover:font-bold"
          >
            Pin Tab
          </li>
          <li
            v-else
            @click.prevent.stop="togglePinTab(tab.id, false)"
            class="bg-white p-2 px-4 hover:bg-platform-hover-secondary hover:font-bold"
          >
            Unpin Tab
          </li>
          <li
            @click.prevent.stop="handleRemoveTab(tab.id)"
            class="bg-white p-2 px-4 hover:bg-platform-hover-secondary hover:font-bold"
          >
            Close Tab
          </li>
          <li
            @click.prevent.stop="closeOtherTabs(tab.id)"
            class="bg-white p-2 px-4 hover:bg-platform-hover-secondary hover:font-bold"
          >
            Close Other Tabs
          </li>
          <li
            @click.prevent.stop="closeTabsToTheRight"
            class="bg-white p-2 px-4 hover:bg-platform-hover-secondary hover:font-bold"
          >
            Close Tabs to the Right
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, Teleport } from "vue";
import { useRouter } from "vue-router";

import { Icon } from "@/components";
import { IconPinActive, IconPinInactive, IconX, IconMoreVert } from "@/components/icons";
import { useUIStore, useAuthStore, useCrudStore } from "@/stores";

const props = defineProps({ tab: Object, index: Number });

const router = useRouter();
const UIStore = useUIStore();
const authStore = useAuthStore();
const crudStore = useCrudStore();

const isTabHovered = ref(false);
const currentDraggingIndex = ref(null);
const draggedTab = ref(null);
const openMenuId = ref(null);

const contextMenuPosition = ref({ top: 100, left: 0 });
const tooltipPosition = ref({ top: 100, left: 64 });
const tooltipText = ref("");
const tooltipMaxWidthStyle = ref("250px");

onMounted(() => {
  window.addEventListener("click", handleMenuClicks);
});

onUnmounted(() => {
  window.removeEventListener("click", handleMenuClicks);
});

function handleRightClickMenu(tabId) {
  const tabElement = document.getElementById(`navigation-tab-${tabId}`);
  const tabRect = tabElement.getBoundingClientRect();

  const menuWidth = 186.4; // Width of the context menu
  const viewportWidth = window.innerWidth;

  let menuLeft = tabRect.left; // Default to align with left edge of tab
  let menuTop = tabRect.bottom; // Default to align with bottom edge of tab

  const lgBreakpoint = 1024; // Tailwinds default lg breakpoint
  const isLgScreen = viewportWidth >= lgBreakpoint;

  // Check if the menu goes off the right edge of the viewport
  if (menuLeft + menuWidth > viewportWidth) {
    menuLeft = viewportWidth - menuWidth - (isLgScreen ? 8 : 12);
  }

  // Adjust left menus for when we have a side nav
  const sideNavWidth = 59;
  if (isLgScreen && menuLeft < sideNavWidth) {
    menuLeft = sideNavWidth + 3; // Adjust for the width of the sidenav + a buffer
  } else {
    menuLeft = menuLeft + 3;
  }

  contextMenuPosition.value = { top: menuTop, left: menuLeft };
  hideAllTabMenus();
  updateTabProperty(tabId, "is_show_options", true);
  openMenuId.value = tabId;
}

function handleHoverTab(isHovered, tabId) {
  isTabHovered.value = isHovered;

  const tabElement = document.getElementById(`navigation-tab-${tabId}`);
  const tabRect = tabElement.getBoundingClientRect();
  const textElement = tabElement.querySelector(`#navigation-tab-text-${tabId}`);

  if (isHovered) {
    const textToShow = textElement.textContent;
    const tooltipMaxWidth = 250;
    const tooltipWidth = Math.min(textElement.scrollWidth, tooltipMaxWidth);
    const viewportWidth = window.innerWidth;

    // Calculate the middle of the tab and subtract half the tooltip width
    let tooltipLeft = tabRect.left + tabRect.width / 2 - tooltipWidth / 2;
    let tooltipTop = tabRect.bottom + 4; // Default to align directly under the tab with some spacing

    // Check if the tooltip goes off the right edge of the viewport
    if (tooltipLeft + tooltipWidth > viewportWidth) {
      tooltipLeft = viewportWidth - tooltipWidth - 8;
    }

    // Adjust left tooltips for when we have a side nav
    const sideNavWidth = 59;
    if (viewportWidth >= 1024 && tooltipLeft < sideNavWidth) {
      tooltipLeft = sideNavWidth + 3; // Adjust for the width of the sidenav + a buffer
    } else if (tooltipLeft < 0) {
      tooltipLeft = 3; // Adjust for the left edge of the viewport
    }

    tooltipPosition.value = { top: tooltipTop, left: tooltipLeft };
    tooltipText.value = textToShow;
    tooltipMaxWidthStyle.value = `${tooltipMaxWidth}px`;
  } else {
    tooltipText.value = "";
  }
}

function updateTabProperty(id, property, value) {
  const index = UIStore.tabs.findIndex((tab) => tab.id === id);
  if (index !== -1) {
    UIStore.tabs[index][property] = value;
  }
}

function hideAllTabMenus() {
  UIStore.tabs = UIStore.tabs.map((tab) => ({
    ...tab,
    is_show_options: false,
  }));
}

const handleMenuClicks = (e) => {
  if (!e.target.closest("#options-menu")) {
    hideAllTabMenus();
  }
};

const handleSetActiveTab = (id) => {
  UIStore.tabs = UIStore.tabs.map((tab) => ({
    ...tab,
    is_active: tab.id === id,
  }));
  const activeTab = UIStore.tabs.find((tab) => tab.id === id);

  if (activeTab) {
    updateTabProperty(id, "is_active", true);
    router.push(activeTab.path);
  }
};

const handleRemoveTab = async (id) => {
  if (UIStore.tabs.find((tab) => tab.id === id).is_active) {
    let activeTabIndex = UIStore.tabs.indexOf(UIStore.tabs.find((tab) => tab.is_active));
    if (activeTabIndex > 0) {
      //if there are tabs behind it and it is not last
      if (activeTabIndex < UIStore.tabs.length - 1) {
        activeTabIndex = activeTabIndex + 1;
      } else {
        activeTabIndex = activeTabIndex - 1;
      }

      let nextTabId = UIStore.tabs[activeTabIndex].id;
      handleSetActiveTab(nextTabId);
    } else if (activeTabIndex < UIStore.tabs.length - 1) {
      //if there are tabs in front of it
      activeTabIndex = activeTabIndex + 1;

      let nextTabId = UIStore.tabs[activeTabIndex].id;
      handleSetActiveTab(nextTabId);
    } else {
      router.push("/dashboard");
    }
  }
  UIStore.tabs = UIStore.tabs.filter((tab) => tab.id !== id);
  let update = {
    $pull: {
      tabs: { id: id },
    },
  };
  await crudStore.updateOne(
    "UserTabs",
    {
      user_id: authStore.currentUser.id,
    },
    update
  );
  hideAllTabMenus();
};

const togglePinTab = async (id, is_pinned) => {
  //update local state first
  updateTabProperty(id, "is_pinned", is_pinned);

  if (is_pinned) {
    // Assign the new pin_order to be one greater than the highest current pin_order
    const maxPinOrder = UIStore.tabs.reduce(
      (max, tab) => (tab.is_pinned ? Math.max(max, tab.pin_order || 0) : max),
      0
    );
    updateTabProperty(id, "pin_order", maxPinOrder + 1);
  } else {
    // If unpinning, remove the pin_order
    updateTabProperty(id, "pin_order", null);
  }
  hideAllTabMenus();

  UIStore.tabs.sort((a, b) => {
    if (a.is_pinned && !b.is_pinned) {
      return -1;
    }
    if (!a.is_pinned && b.is_pinned) {
      return 1;
    }

    // If both are pinned, then sort by pin_order
    if (a.is_pinned && b.is_pinned) {
      return a.pin_order - b.pin_order;
    }

    // If neither are pinned, their order doesn't change
    return 0;
  });

  await updatePinOrder();
};

const closeOtherTabs = async (id) => {
  handleSetActiveTab(id);
  UIStore.tabs = UIStore.tabs.filter((tab) => tab.id === id);
  let update = {
    $set: {
      tabs: UIStore.tabs,
    },
  };
  await crudStore.updateOne(
    "UserTabs",
    {
      user_id: authStore.currentUser.id,
    },
    update
  );
  hideAllTabMenus();
  await updatePinOrder();
};

const closeTabsToTheRight = async () => {
  const openMenuTabIndex = UIStore.tabs.findIndex((tab) => tab.id === openMenuId.value);
  UIStore.tabs = UIStore.tabs.slice(0, openMenuTabIndex + 1);
  let update = {
    $set: {
      tabs: UIStore.tabs,
    },
  };
  await crudStore.updateOne(
    "Users",
    {
      user_id: authStore.currentUser.id,
    },
    update
  );
  hideAllTabMenus();
  await updatePinOrder();
};

const updatePinOrder = async () => {
  const updatedTabs = UIStore.tabs.map((tab, index) => {
    const updatedTab = { ...tab };
    if (tab.is_pinned) {
      updatedTab.pin_order = index;
    }

    delete updatedTab.is_active;
    delete updatedTab.is_show_options;
    return updatedTab;
  });

  let update = {
    $set: {
      tabs: updatedTabs,
    },
  };

  await crudStore.updateOne(
    "UserTabs",
    {
      user_id: authStore.currentUser.id,
    },
    update
  );
};

const dragStart = (event) => {
  const id = event.currentTarget.getAttribute("data-id");
  currentDraggingIndex.value = UIStore.tabs.findIndex((tab) => tab.id === id);
  draggedTab.value = UIStore.tabs[currentDraggingIndex.value];

  // Store the data that will be needed during the drop event
  event.dataTransfer.setData(
    "text",
    JSON.stringify({
      currentDraggingIndex: currentDraggingIndex.value,
      draggedTab: draggedTab.value,
    })
  );
};

let draggedIndex = ref(null);

const dragOver = (tabId) => {
  UIStore.overEmptySpace_tab = false;
  draggedIndex.value = UIStore.tabs.findIndex((tab) => tab.id === tabId);
};

const drop = async (event) => {
  event.preventDefault();
  const currentDroppingIndex = draggedIndex.value;
  const data = JSON.parse(event.dataTransfer.getData("text"));
  const draggedTab = data.draggedTab;

  // Find the last index of the pinned tabs
  let lastPinnedIndex = -1;
  for (let i = 0; i < UIStore.tabs.length; i++) {
    if (UIStore.tabs[i].is_pinned) {
      lastPinnedIndex = i;
    }
  }

  // Remove the tab from its original position
  const [removedTab] = UIStore.tabs.splice(data.currentDraggingIndex, 1);

  // Determine the target index for the dragged tab
  let targetIndex;
  if (draggedTab.is_pinned) {
    targetIndex = currentDroppingIndex > lastPinnedIndex ? lastPinnedIndex : currentDroppingIndex;
  } else {
    // Ensure unpinned tabs do not move into the pinned section
    // If the current dropping index is after the last pinned tab, or there are no pinned tabs,
    // allow the non-pinned tab to be placed at the current dropping index
    targetIndex =
      currentDroppingIndex > lastPinnedIndex || lastPinnedIndex === -1
        ? currentDroppingIndex
        : lastPinnedIndex + 1;
  }

  // Insert the tab at the determined target index
  UIStore.tabs.splice(targetIndex, 0, removedTab);

  // Update pin order if necessary
  if (draggedTab.is_pinned) {
    await updatePinOrder();
  }
};
</script>
