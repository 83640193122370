<template>
  <EditListModal
    :open="dataModelManagementStore.editListModalOpen"
    @close-modal="dataModelManagementStore.editListModalOpen = false"
  />
  <!-- <ManageRLQRModal :open="RLQRModalOpen" @close-modal="RLQRModalOpen = false" /> -->
  <div v-if="UIStore.isLoading"><LoadingSpinner :show="UIStore.isLoading" /></div>
  <div v-else class="h-full md:px-4">
    <!-- Header -->
    <div
      class="my-5 border-b border-t border-platform-outlines bg-neutral-gray-1 px-4 py-2.5 md:rounded-lg md:border"
    >
      <Header>
        <template #header-icon>
          <div
            class="flex h-10 w-10 items-center justify-center rounded-md border-4 bg-custom-hot-pink"
          >
            <IconTableRows class="h-6 w-6 fill-primary-1-light" />
          </div>
        </template>
        <template #header-title>
          <h1 class="text-primary text-sm font-bold md:text-lg">List Manager</h1>
        </template>
        <template #header-desc>
          <p class="text-xs text-light md:text-sm">Manage Lists</p>
        </template>
        <template #header-ctas>
          <div class="flex gap-1">
            <Button
              @click="routeToListManager()"
              isIconOnlyOnMobile
              icon
              label
              variant="default-gray-outlined"
              size="medium"
            >
              <template #icon><IconSetting class="h-4 w-4 fill-neutral-gray-13" /></template>
              <template #label>Manage Models</template>
            </Button>
            <Button
              @click="openModal()"
              isIconOnlyOnMobile
              icon
              label
              variant="default-gray-outlined"
              size="medium"
            >
              <template #icon><IconPlus class="h-4 w-4 fill-neutral-gray-13" /></template>
              <template #label>New List</template>
            </Button>
          </div>
        </template>
      </Header>
    </div>

    <div
      class="min-h-[75vh] border-b border-t border-platform-outlines bg-neutral-gray-1 px-5 py-5 md:rounded-md md:border"
    >
      <div class="mb-4 flex justify-between">
        <div class="flex flex-col">
          <p class="mb-0.5 text-xs font-[600] text-lighter">Lists</p>
          <p class="text-sm font-semibold">Select the List to Manage</p>
        </div>
      </div>
      <!-- Search Bar -->
      <div class="relative w-full">
        <div class="flex items-center rounded-md border border-neutral-gray-5">
          <input
            type="text"
            placeholder="Search for Lists"
            class="text-primary h-11 w-full rounded-md bg-neutral-gray-3 px-4 py-3 text-sm placeholder:text-sm focus:border-none focus:outline-none focus:ring-0 disabled:bg-neutral-gray-4"
            v-model="searchQuery"
          />
          <IconSearch
            class="absolute right-2 top-1/2 mr-4 h-5 w-5 -translate-y-1/2 fill-primary-1"
          />
        </div>
      </div>

      <div class="mt-4 grid w-full gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-5">
        <ListCard
          v-if="filteredListCards.length"
          v-for="card in filteredListCards"
          :key="card._id"
          :data="card"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { Header, Button, LoadingSpinner } from "@/components";
import ListCard from "./ListCard.vue";
import EditListModal from "./EditListModal.vue";
import { useDataModelManagementStore, useUIStore } from "@/stores";

import { IconPlus, IconSetting, IconTableRows } from "@/components/icons";

const dataModelManagementStore = useDataModelManagementStore();
const UIStore = useUIStore();
const listCards = ref([]);
const searchQuery = ref("");
const router = useRouter();

onMounted(async () => {
  UIStore.isLoading = true;
  listCards.value = await dataModelManagementStore.setLists();
  setTimeout(() => {
    UIStore.isLoading = false;
  }, 500);
});
watch(
  () => dataModelManagementStore.resetTic,
  async () => {
    listCards.value = await dataModelManagementStore.setLists();
  }
);
watch(
  () => dataModelManagementStore.editListModalOpen,
  async () => {
    if (!dataModelManagementStore.editListModalOpen) {
      listCards.value = await dataModelManagementStore.setLists();
    }
  }
);

function openModal() {
  dataModelManagementStore.editListModalOpen = true;
  const template = JSON.parse(JSON.stringify(dataModelManagementStore.blank_list_setting));
  dataModelManagementStore.list_being_edited = template;
}
function routeToListManager() {
  router.push(`/data-model-management`);
}
const filteredListCards = computed(() => {
  if (!searchQuery.value) {
    return listCards.value;
  }
  return listCards.value.filter((card) =>
    card.card_name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});
</script>
