<template>
  <SideDrawer
    @close-drawer="$emit('close-drawer')"
    :open="open"
    customWidth="lg:w-2/3"
    hideHeader
    showFooter
    stayOpen
  >
    <template v-slot:tabs>
      <div class="font-semibold mt-1 flex justify-between w-full">
        <p>
          Step {{ investmentStore.currentConversionStep + 1 }} -
          {{ investmentStore.conversionSteps[investmentStore.currentConversionStep] }}
        </p>
        <p>{{ investmentStore.selectedConversionType?.name }}</p>
      </div>
    </template>
    <template v-slot:main-content>
      <div v-if="isFinalizingConversion" class="!z-50">
        <LoadingSpinner :show="isFinalizingConversion" />
      </div>
      <ConvertInvestmentsFlow />
    </template>
    <template v-slot:footer>
      <div class="flex justify-end gap-2">
        <Button
          v-if="investmentStore.currentConversionStep > 0"
          @click="handleBack"
          label
          icon
          class="px-4 min-w-16"
          variant="default-gray-outlined"
        >
          <template #label>Back</template>
        </Button>
        <Button @click="handleNext" :disabled="isNextDisabled" label icon class="px-4 min-w-16">
          <template v-if="isFinalizingConversion" #label>
            <IconPulseSpinner class="h-6 w-6 stroke-white" />
          </template>
          <template v-else #label>{{
            investmentStore.currentConversionStep === investmentStore.conversionSteps.length - 1
              ? "Finish"
              : "Next"
          }}</template>
        </Button>
      </div>
    </template>
  </SideDrawer>
</template>

<script setup>
import { ref, watch, computed } from "vue";

import { useInvestmentStore } from "@/stores";
import { SideDrawer, Button, LoadingSpinner } from "@/components";
import { IconPulseSpinner } from "@/components/icons";
import ConvertInvestmentsFlow from "./ConvertInvestmentsFlow.vue";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["close-drawer"]);

const investmentStore = useInvestmentStore();
const isFinalizingConversion = ref(false);

watch(
  () => props.open,
  () => {
    if (!props.open) {
      investmentStore.currentConversionStep = 0;
    }
  }
);

watch(
  () => investmentStore.selectedInvestmentsForConversion,
  () => {
    investmentStore.selectedConversionType = null;
  },
  { deep: true }
);

const isNextDisabled = computed(() => {
  // Select Conversion Type
  if (investmentStore.currentConversionStep === 0) {
    return (
      !investmentStore.selectedConversionType ||
      !investmentStore.selectedInvestmentsForConversion?.length ||
      investmentStore.selectedInvestmentsForConversion.some(
        (investment) => investment.status !== "Settled"
      ) ||
      investmentStore.selectedInvestmentsForConversion.reduce((acc, curr) => {
        if (!acc.includes(curr.type)) {
          acc.push(curr.type);
        }
        return acc;
      }, [])?.length > 1
    );
  } else if (investmentStore.currentConversionStep === 1) {
    // Select Conversion Method
    const isAddNewCapital = investmentStore.selectedConversionMethod?.id === 2;
    if (isAddNewCapital) {
      return (
        !investmentStore.conversionDetails?.amount_to_add ||
        investmentStore.conversionDetails.amount_to_add === "0" ||
        investmentStore.conversionDetails.amount_to_add?.length < 4 ||
        !investmentStore.conversionPaymentMethod ||
        !investmentStore.conversionDetails?.selectedOffering
      );
    }

    const isConvertOnly = investmentStore.selectedConversionMethod?.id === 1;
    if (isConvertOnly) {
      return !investmentStore.conversionDetails?.selectedOffering;
    }
    return true;
  } else if (
    // Split Investments
    investmentStore.currentConversionStep === 2 &&
    investmentStore.conversionSteps.length === 4
  ) {
    return (
      investmentStore.totalAvailableAmountForConvertedInvestments !== 0 &&
      !investmentStore.oneToManyInvestmentList.some((investment) => !investment.amount)
    );
  } else if (
    // Complete Conversion w/ no split
    investmentStore.currentConversionStep === 2 &&
    investmentStore.conversionSteps.length === 3
  ) {
    return (
      !investmentStore.conversionDetails?.selectedOffering ||
      !investmentStore.conversionSubsToUpload.length
    );
  } else if (investmentStore.currentConversionStep === 3) {
    // Complete Conversion w/ split
    return (
      !investmentStore.conversionDetails?.selectedOffering ||
      !investmentStore.conversionSubsToUpload.length ||
      investmentStore.conversionSubsToUpload.length !==
        investmentStore.oneToManyInvestmentList.length
    );
  }

  return true;
});

async function handleBack() {
  investmentStore.currentConversionStep--;
}

async function handleNext() {
  if (investmentStore.currentConversionStep === investmentStore.conversionSteps.length - 1) {
    isFinalizingConversion.value = true;
    await investmentStore.finalizeConversion();
    isFinalizingConversion.value = false;
    emit("close-drawer");
  } else {
    investmentStore.currentConversionStep++;
  }
}
</script>
