<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" :hideModalTabs="true" @close-modal="$emit('close-modal')" size="large">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="font-bold text-custom-navy-2">
          {{ createInvestmentStore.bank_account_type }} Account Information
        </div>
      </template>

      <template v-slot:modal-body>
        <div class="p-4 md:py-8 md:px-10">
          <div class="text-light mb-6">
            {{
              createInvestmentStore.bank_account_type == "Funding"
                ? "Phoenix distributes interest monthly via ACH on the 10th of each month. Please fill in the information below for you’d like interest deposited."
                : ""
            }}
          </div>

          <div>
            <div class="flex flex-col">
              <div v-for="(group, index) in schemaStore.modal_banking_schema" :key="index">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div v-for="field of group.fields">
                    <BaseInput
                      :required="field.is_required"
                      :disabled="field.read_only"
                      :label="field.label"
                      v-if="!field.is_association_field && !field.belongs_to_nested_object"
                      v-model="template_to_use[field.field_name]"
                      :formatRule="field.field_name"
                      :errorMessage="field.error_message"
                      :type="field.field_type"
                      :options="field.dropdown_options"
                      size="large"
                      customClass="bg-neutral-gray-1"
                    ></BaseInput>
                    <BaseInput
                      :required="field.is_required"
                      :disabled="field.read_only"
                      :formatRule="field.field_name"
                      :errorMessage="field.error_message"
                      :label="field.label"
                      v-if="!field.is_association_field && field.belongs_to_nested_object"
                      v-model="template_to_use[field.nested_object_name][field.field_name]"
                      :options="field.dropdown_options"
                      :type="field.field_type"
                      size="large"
                      customClass="bg-neutral-gray-1"
                    ></BaseInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <div class="flex justify-between">
          <div>
            <Button size="large" label variant="default-gray-outlined">
              <template #label><p>Back</p></template></Button
            >
          </div>
          <div class="flex flex-row justify-end gap-1">
            <Button
              size="large"
              label
              variant="default-gray-outlined"
              @click="$emit('close-modal')"
            >
              <template #label><p>Cancel</p></template></Button
            >
            <Button size="large" @click="handleSave" :disabled="nextIsDisabled" label>
              <template #label>Save</template></Button
            >
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { Button, Modal, BaseInput } from "@/components";

import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { computed } from "vue";
import _ from "lodash";

const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

const emit = defineEmits(["close_modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

function handleSave() {
  template_to_use.value.is_new_bank_account = true;
  // convert template_to_use.value.account_number_for_display to '**** last `4` digits'
  if (template_to_use.value.account_number) {
    template_to_use.value.account_number_for_display = `**** ${template_to_use.value.account_number.slice(
      -4
    )}`;
  }
  if (createInvestmentStore.bank_account_type == "Funding") {
    //find an item in createInvestmentStore.owners_funding_bank_accounts where is_new_bank_account is true and replace it with the new bank account
    let index = createInvestmentStore.owners_funding_bank_accounts.findIndex(
      (item) => item.is_new_bank_account
    );
    if (index > -1) {
      //replace the item at that index
      createInvestmentStore.owners_funding_bank_accounts.splice(
        index,
        1,
        _.cloneDeep(template_to_use.value)
      );
    } else {
      createInvestmentStore.owners_funding_bank_accounts.unshift(
        _.cloneDeep(template_to_use.value)
      );
    }
    createInvestmentStore.selected_funding_method = _.cloneDeep(template_to_use.value);
  }
  if (createInvestmentStore.bank_account_type == "Distribution") {
    let index = createInvestmentStore.owners_distribution_bank_accounts.findIndex(
      (item) => item.is_new_bank_account
    );
    if (index > -1) {
      createInvestmentStore.owners_distribution_bank_accounts.splice(
        index,
        1,
        _.cloneDeep(template_to_use.value)
      );
    } else {
      createInvestmentStore.owners_distribution_bank_accounts.unshift(
        _.cloneDeep(template_to_use.value)
      );
    }
    createInvestmentStore.selected_distribution_method = _.cloneDeep(template_to_use.value);
  }

  emit("close-modal");
}

const nextIsDisabled = computed(() => {
  for (let group of schemaStore.modal_banking_schema) {
    for (let field of group.fields) {
      if (field.is_required && !template_to_use.value[field.field_name]) {
        return true;
      }
    }
  }
  return false;
});
const template_to_use = computed(() => {
  return createInvestmentStore.bank_account_type == "Funding"
    ? createInvestmentStore.funding_account_template
    : createInvestmentStore.distribution_account_template;
});
</script>
