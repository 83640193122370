<template>
  <Modal :open="open" @close-modal="$emit('close-modal')" size="36square" hideModalTabs>
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">Add Investment to ACH Batch</p>
    </template>

    <template v-slot:modal-body>
      <div class="py-8 px-10">
        <div class="flex gap-5 items-end">
          <div class="w-full relative">
            <BaseInput
              label="Investment ID"
              required
              placeholder="Investment ID"
              v-model="investmentId"
              size="large"
              @input="validateId"
            />
            <Icon
              @click="(investmentId = ''), (addError = '')"
              name="X"
              :stoke-with="2"
              class="h-4 w-4 text-lighter absolute top-10.5 right-3 cursor-pointer"
            />
          </div>

          <Button @click="handleAddId" :disabled="!isIdValid" icon variant="subtle" size="large">
            <template #icon>
              <Icon name="Plus" :stroke-width="2" />
            </template>
          </Button>
        </div>
        <p v-if="addError" class="text-functional-error-default text-sm mt-1">
          {{ addError }}
        </p>
        <div class="border-b border-platform-outlines w-full my-4"></div>
        <div class="flex flex-col gap-4">
          <h3 class="font-medium">Investments added</h3>
          <div v-for="investment in investmentIdsToAdd" class="flex items-center gap-4">
            <BaseInput v-model="investment.id" size="large" disabled />
            <Button
              @click="handleRemoveInvestmentId(investment.id)"
              icon
              variant="error-color-outlined"
              size="large"
            >
              <template #icon>
                <Icon
                  name="Trash"
                  :stroke-width="2.5"
                  class="h-4 w-4 text-functional-error-default"
                />
              </template>
            </Button>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button size="large" label @click="handleAddToBatch" :disabled="!investmentIdsToAdd.length">
          <template #label> Add To Batch </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, watch } from "vue";

import { useACHBatchStore } from "@/stores";
import { Modal, Button, BaseInput, Icon } from "@/components";
import { isValidObjectId } from "@/utilities";

const achBatchStore = useACHBatchStore();

const emit = defineEmits(["close-modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const investmentIdsToAdd = ref([]);
const investmentId = ref("");
const addError = ref("");

const isIdValid = computed(() => {
  return isValidObjectId(investmentId.value);
});

function validateId() {
  if (!investmentId.value) {
    addError.value = ""; // No error when input is empty
  } else if (!isIdValid.value) {
    addError.value = "Invalid Investment ID";
  } else {
    addError.value = ""; // Clear error if valid
  }
}

async function handleAddToBatch() {
  const idsToAdd = investmentIdsToAdd.value.map((investment) => investment.id);
  await achBatchStore.addInvestmentsToBatch(idsToAdd);
  investmentIdsToAdd.value = [];
  emit("close-modal");
}

function handleAddId() {
  if (!isIdValid.value) {
    addError.value = "Invalid Investment ID";
    return;
  }
  const idsAlreadyAdded = investmentIdsToAdd.value.map((investment) => investment.id);
  const idsAlreadyInBatch = achBatchStore.achBatchData.map(
    (investment) => investment.investment_id
  );

  if (idsAlreadyAdded.includes(investmentId.value)) {
    addError.value = "Investment ID already added";
  } else if (idsAlreadyInBatch.includes(investmentId.value)) {
    addError.value = "Investment ID already exists in the current ACH batch";
  } else {
    investmentIdsToAdd.value.push({ id: investmentId.value });
    investmentId.value = "";
    addError.value = "";
  }
}

function handleRemoveInvestmentId(investmentIdToRemove) {
  investmentIdsToAdd.value = investmentIdsToAdd.value.filter(
    (investment) => investment.id !== investmentIdToRemove
  );
}
</script>
