<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Transfer Investment
    </div>
  </div>
  <div class="mt-4 border-b border-platform-outlines pb-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <InvestorDetailCard />
      <CustodianDetailCard
        v-if="
          titleTransferStore.current_investment.type.toLowerCase().includes('ira') ||
          titleTransferStore.current_investment.type.toLowerCase().includes('hsa')
        "
      />
      <JointDetailCard v-if="titleTransferStore.current_investment.type == 'Joint'" />
      <EntityDetailCard v-if="titleTransferStore.current_investment.type == 'Entity'" />
    </div>
  </div>
  <div class="pb-8 pt-4 border-b border-platform-outlines">
    <div class="font-bold">Reason For Transfer</div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <BaseInput
        :required="true"
        v-model="titleTransferStore.reason_for_transfer"
        type="dropdown"
        label="Reason For Transfer"
        :options="schemaStore.title_transfer_reasons"
        size="large"
        customClass="bg-neutral-gray-1"
      ></BaseInput>
      <BaseInput
        :required="titleTransferStore.reason_for_transfer === 'Other'"
        v-model="titleTransferStore.other_reason_for_transfer"
        type="text"
        label="Please specify other reason"
        size="large"
        customClass="bg-neutral-gray-1"
        :disabled="titleTransferStore.reason_for_transfer !== 'Other'"
      ></BaseInput>
    </div>
  </div>
  <div class="pb-8 pt-4 border-b border-platform-outlines">
    <div class="font-bold">Signature Dates</div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <BaseInput
          v-model="titleTransferStore.transferor_signature_date"
          type="date"
          label="Transferor Signature Date"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <div class="mt-4 text-sm text-lighter pb-4">
          This signature will be used as the signature date on the investment
        </div>
      </div>

      <BaseInput
        v-if="
          titleTransferStore.current_investment.type.toLowerCase().includes('ira') ||
          titleTransferStore.current_investment.type.toLowerCase().includes('hsa')
        "
        v-model="titleTransferStore.transferor_custodian_signature_date"
        type="date"
        label="Transferor Custodian Signature Date"
        size="large"
        customClass="bg-neutral-gray-1"
      ></BaseInput>
      <BaseInput
        v-if="titleTransferStore.current_investment.type == 'Joint'"
        v-model="titleTransferStore.transferor_joint_signature_date"
        type="date"
        label="Transferor Joint Signature Date"
        size="large"
        customClass="bg-neutral-gray-1"
      ></BaseInput>
      <BaseInput
        v-if="titleTransferStore.current_investment.type == 'Entity'"
        v-model="titleTransferStore.transferor_entity_signature_date"
        type="date"
        label="Transferor Entity Signature Date"
        size="large"
        customClass="bg-neutral-gray-1"
      ></BaseInput>
    </div>
  </div>
  <div class="pb-8 pt-4">
    <div class="font-bold">Transfer To Type...</div>
    <div class="grid md:grid-cols-2 gap-6">
      <div class="mt-4 flex flex-col md:flex-row gap-2 items-center">
        <div class="text-primary-4 font-semibold">
          {{ titleTransferStore.current_investment.type }}
        </div>
        <div>
          <IconArrowForward class="fill-gold w-4 rotate-90 md:rotate-0 mb-0 md:mb-0" />
        </div>

        <BaseInput
          dropdownAbove
          v-model="titleTransferStore.transfer_to_investment_type"
          type="select"
          placeholder="Select Investment Type"
          customClass="bg-neutral-gray-1"
          class="max-w-1/2"
          size="large"
          :options="investment_type_options"
        ></BaseInput>
      </div>
    </div>

    <div
      v-if="
        titleTransferStore.transfer_to_investment_type == 'Entity' &&
        titleTransferStore.current_investment.type == 'Entity'
      "
      class="mt-4 text-sm text-lighter pb-4"
    >
      During this transfer, you can create a new Entity or transfer to an existing Entity for this
      investor.
    </div>
    <div
      v-if="
        titleTransferStore.transfer_to_investment_type == 'Joint' &&
        titleTransferStore.current_investment.type == 'Joint'
      "
      class="mt-4 text-sm text-lighter pb-4"
    >
      During this transfer, you can create a new Joint Contact or transfer to an existing Joint
      Contact for this investor.
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from "vue";
import { BaseInput } from "@/components";
import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { IconArrowForward } from "@/components/icons";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();

import InvestorDetailCard from "./InvestorDetailCard.vue";
import CustodianDetailCard from "./CustodianDetailCard.vue";
import JointDetailCard from "./JointDetailCard.vue";
import EntityDetailCard from "./EntityDetailCard.vue";
import _ from "lodash";

const investment_type_options = computed(() => {
  let options_array = _.cloneDeep(schemaStore.admin_investment_types);

  if (titleTransferStore.current_investment.type == "Individual") {
    options_array = options_array.filter((option) => option != "Individual");
  } else if (
    titleTransferStore.current_investment.type.toLowerCase().includes("ira") ||
    titleTransferStore.current_investment.type.toLowerCase().includes("hsa")
  ) {
    options_array = options_array.filter(
      (option) =>
        (option.toLowerCase().includes("ira") || option.toLowerCase().includes("hsa")) &&
        option != titleTransferStore.current_investment.type
    );
  }
  return options_array;
});

watch(
  () => titleTransferStore.transfer_to_investment_type,
  () => {
    //set next steps
    let options;
    if (titleTransferStore.transfer_to_investment_type == "Entity") {
      if (titleTransferStore.existing_entity_accounts.length > 0) {
        options = [
          "Type Selection",
          "First Transfer Step",
          "Select Entity Account",
          "Entity Info",
          "Complete Title Transfer",
        ];
      } else {
        options = [
          "Type Selection",
          "First Transfer Step",
          "Entity Info",
          "Complete Title Transfer",
        ];
        titleTransferStore.add_entity_account = true;
        titleTransferStore.selected_entity_account = {};
        titleTransferStore.entity_template = schemaStore.createEntityTemplate();
        titleTransferStore.entity_template.user_id =
          titleTransferStore.current_owner_contact.user_id;
        titleTransferStore.entity_template.contact_id =
          titleTransferStore.current_owner_contact.contact_id;
      }

      // options = [
      //   "Type Selection",
      //   "First Transfer Step",
      //   "Select Entity Account",
      //   "Entity Info",
      //   "Complete Title Transfer",
      // ];
    } else if (titleTransferStore.transfer_to_investment_type == "Joint") {
      if (titleTransferStore.existing_joint_contacts.length > 0) {
        options = [
          "Type Selection",
          "First Transfer Step",
          "Select Joint Contact",
          "Joint Info",
          "Complete Title Transfer",
        ];
      } else {
        options = [
          "Type Selection",
          "First Transfer Step",
          "Joint Info",
          "Complete Title Transfer",
        ];
        titleTransferStore.add_joint_contact = true;
        titleTransferStore.selected_joint_contact = {};
        titleTransferStore.joint_contact_template = schemaStore.createJointTemplate();
        titleTransferStore.joint_contact_template.user_id =
          titleTransferStore.current_owner_contact.user_id;
        titleTransferStore.joint_contact_template.account_id =
          titleTransferStore.current_owner_contact.account_id;
      }
    } else if (
      titleTransferStore.transfer_to_investment_type.toLowerCase().includes("ira") ||
      titleTransferStore.transfer_to_investment_type.toLowerCase().includes("hsa")
    ) {
      options = ["Type Selection", "First Transfer Step", "Complete Title Transfer"];
    } else {
      options = ["Type Selection", "First Transfer Step", "Complete Title Transfer"];
    }
    titleTransferStore.investmentSteps = options;
  }
);

watch(
  () => [
    titleTransferStore.reason_for_transfer,
    titleTransferStore.other_reason_for_transfer,
    titleTransferStore.transferor_signature_date,
    titleTransferStore.transferor_custodian_signature_date,
    titleTransferStore.transferor_joint_signature_date,
    // titleTransferStore.transferor_entity_signature_date,
    titleTransferStore.transfer_to_investment_type,
  ],
  () => {
    if (
      !titleTransferStore.reason_for_transfer ||
      (titleTransferStore.reason_for_transfer === "Other" &&
        !titleTransferStore.other_reason_for_transfer) ||
      !titleTransferStore.transferor_signature_date ||
      ((titleTransferStore.current_investment.type.toLowerCase().includes("ira") ||
        titleTransferStore.current_investment.type.toLowerCase().includes("hsa")) &&
        !titleTransferStore.transferor_custodian_signature_date) ||
      (titleTransferStore.current_investment.type == "Joint" &&
        !titleTransferStore.transferor_joint_signature_date) ||
      // (titleTransferStore.current_investment.type == "Entity" &&
      //   !titleTransferStore.transferor_entity_signature_date) ||
      !titleTransferStore.transfer_to_investment_type
    ) {
      titleTransferStore.first_og_title_transfer_step_is_invalid = true;
    } else {
      titleTransferStore.first_og_title_transfer_step_is_invalid = false;
    }
  }
);
</script>
