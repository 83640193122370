import { defineStore } from "pinia";

import {
  useCrudStore,
  useAuthStore,
  useRecordDetailStore,
  useUIStore,
  useSchemaStore,
  useSearchStore,
} from "@/stores";

import _ from "lodash";
import useFileUtility from "@/composables/useFileUtility";

export const useCreateInvestmentStore = defineStore("createInvestmentStore", {
  state: () => ({
    // Investment Details
    investment_type: "",
    ben_owner_modal_page: 1,
    selected_offering: {},
    selected_offering_name: "",
    investments_being_created: false,
    investment_amount: "0",
    payment_frequency: "",
    all_related_contacts: [],
    payment_frequency_options: [
      {
        name: "Compounded Monthly",
        description: "Interest and principle are paid at maturity",
        disabled: false,
      },
      {
        name: "Interest Paid Monthly",
        description: "Interest paid monthly and principle paid at maturity",
        disabled: false,
      },
    ],
    investment_is_discounted: false,
    notify_user_of_created_investment: false,
    add_to_ach_batch: false,
    investment_discount_amount: "0",
    banking_info_is_invalid: true,
    investment_funding_method: "",
    investmentSteps: [],
    currentStep: 0,
    offerings: [],
    offering_name_array: [],
    investment_drawer_open: false,
    investment_signature_date: null,
    uploaded_subagreeement: null,
    investment_being_created: false,
    show_investment_completion_screen: false,
    selected_funding_method: {},
    selected_distribution_method: {},
    selected_rollover_investment: {},
    current_owner_contact: {},
    ben_owner_template: {},
    current_owner_contact_full_name: "",
    owner_email_is_invalid: false,
    joint_email_is_invalid: false,
    owner_ssn_is_invalid: false,
    entity_ein_is_invalid: false,
    owner_has_investments: false,
    add_joint_contact: false,
    add_entity_account: false,
    joint_contact_template: {},
    entity_template: {},
    existing_joint_contacts: [],
    existing_entity_accounts: [],
    owners_investments: [],
    selected_joint_contact: {},
    selected_entity_account: {},
    all_beneficial_owners_for_user: [],
    joint_ssn_is_invalid: false,
    current_beneficial_owner_for_modal: {},
    bank_account_modal_open: false,
    generic_funding_accounts: [
      {
        _id: 4,
        unique_id: "funding_rollover",
        financial_institution_name: "Rollover",
        account_type: "Use money from existing expiring investment",
        is_type_rollover: true,
        account_number_for_display: "",
      },
      {
        _id: 3,
        unique_id: "funding_check",
        financial_institution_name: "Check",
        account_type: "Make payment by check",
        is_check_method: true,
        account_number_for_display: "",
      },
      {
        _id: 2,
        unique_id: "funding_wire",
        financial_institution_name: "Wire Transfer",
        account_type: "Make payment by wire transfer",
        is_wire_transfer: true,
        account_number_for_display: "",
      },
      {
        _id: 5,
        unique_id: "funding_conversion",
        financial_institution_name: "Conversion",
        account_type: "Process Legacy Conversion",
        is_conversion_funding: true,
        account_number_for_display: "",
      },
    ],
    owners_funding_bank_accounts: [],
    generic_distribution_accounts: [
      {
        _id: 1,
        unique_id: "distribution_check",
        financial_institution_name: "Check",
        account_type: "Make payment by check",
        is_check_method: true,
        account_number_for_display: "",
      },
    ],
    owners_distribution_bank_accounts: [],
    bank_account_type: "",
    funding_account_template: {},
    distribution_account_template: {},
    invalid_ssn_array: [],
    invalid_email_array: [],
    invalid_ein_array: [],
    suitability_income_info: [],
    admin_investment_types: [],
    rollover_table_fields: [],
    rollover_table_investments: [],
    rollover_table_headers: [],
    custodian_name: "",
    custodian_account_number: "",
    is_valid_suitability_info: true,
    accreditation_documents: [],
    selected_owner_investor: {
      assignedObject: {
        _id: null,
        name: "",
      },
      results: [],
      schema: "field",
      searchTerm: "",
    },
    selected_owner_investor_full_document: {},
  }),
  actions: {
    async setInvestmentSteps() {
      const schemaStore = useSchemaStore();
      if (!this.investment_type || !this.selected_offering) {
        this.investmentSteps = [];
        return;
      }

      const steps = {
        individual: {
          s1: ["Personal Information", "Banking Info", "Complete Your Investment"],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Accreditation Details",
            "Banking Info",
            "Complete Your Investment",
          ],
        },
        joint: {
          s1: [
            "Personal Information",
            "Select Joint Contact",
            "Joint Info",
            "Banking Info",
            "Complete Your Investment",
          ],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Select Joint Contact",
            "Joint Info",
            "Accreditation Details",
            "Banking Info",
            "Complete Your Investment",
          ],
        },
        entity: {
          s1: [
            "Personal Information",
            "Select Entity Account",
            "Entity Info",
            "Banking Info",
            "Complete Your Investment",
          ],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Select Entity Account",
            "Entity Info",
            "Accreditation Details",
            "Banking Info",
            "Complete Your Investment",
          ],
        },
        ira: {
          s1: ["Personal Information", "Banking Info", "Complete Your Investment"],
          reg_d: [
            "Personal Information",
            "Suitability Questionnaire",
            "Accreditation Details",
            "Banking Info",
            "Complete Your Investment",
          ],
        },
      };

      let offeringType;

      switch (this.selected_offering.name.toLowerCase()) {
        case "s1":
          offeringType = "s1";
          break;
        case "reg a":
          offeringType = "s1";
          break;
        default:
          offeringType = "reg_d";
          break;
      }

      let investment_type = this.investment_type.toLowerCase();
      if (
        investment_type.includes("IRA") ||
        investment_type.includes("HSA") ||
        investment_type.includes("ira") ||
        investment_type.includes("hsa")
      ) {
        investment_type = "ira";
      }
      this.investmentSteps = steps[investment_type][offeringType];

      if (investment_type === "joint") {
        this.joint_contact_template = schemaStore.createJointTemplate();
        this.joint_contact_template.user_id = this.current_owner_contact.user_id;
        this.joint_contact_template.account_id = this.current_owner_contact.account_id;

        if (this.existing_joint_contacts.length === 0) {
          const stepIndex = this.investmentSteps.findIndex(
            (step) => step === "Select Joint Contact"
          );
          if (stepIndex !== -1) {
            this.investmentSteps.splice(stepIndex, 1);
          }
        }
      }
      if (investment_type === "entity") {
        this.entity_template = schemaStore.createEntityTemplate();
        this.entity_template.user_id = this.current_owner_contact.user_id;
        this.entity_template.contact_id = this.current_owner_contact._id;

        if (this.existing_entity_accounts.length === 0) {
          const stepIndex = this.investmentSteps.findIndex(
            (step) => step === "Select Entity Account"
          );
          if (stepIndex !== -1) {
            this.investmentSteps.splice(stepIndex, 1);
          }
        }
      }
    },
    async completeInvestment() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let new_investment_record = this.createInvestmentTemplate();
      let inserted_entity_id = null;

      if (this.investment_type === "Joint") {
        new_investment_record.joint_contact_id = await this.insertOrUpdateJointContact(
          this.add_joint_contact
        );
      }

      if (this.investment_type === "Entity") {
        new_investment_record.entity_account_id = await this.insertOrUpdateEntityAccount(
          this.add_entity_account
        );
      }
      if (this.investment_type.includes("IRA") || this.investment_type.includes("HSA")) {
        let matching_custodian = schemaStore.all_custodians.find(
          (custodian) => custodian.name === this.custodian_name
        );

        new_investment_record.custodian_id = matching_custodian._id;
        new_investment_record.custodian_account_number = this.custodian_account_number;
      }

      let funding_information = await this.processFundingBankAccount();
      let distribution_information = await this.processDistributionBankAccount();

      new_investment_record = {
        ...new_investment_record,
        ...funding_information,
        ...distribution_information,
      };
      new_investment_record = schemaStore.sortObjectProperties(new_investment_record);
      let inserted_investment = await crudStore.insertOne("Investments", new_investment_record);
      let inserted_investment_id = inserted_investment.insertedId;
      if (inserted_investment_id && !this.notify_user_of_created_investment) {
        await this.updateInvestmentEmailHistory(inserted_investment_id);
      }
      await this.insertSubagreement(inserted_investment_id);

      await this.updateOwnerContact(inserted_entity_id);

      return inserted_investment_id;
    },
    async updateInvestmentEmailHistory(investment_id) {
      const crudStore = useCrudStore();

      const query = { investment_id: investment_id };
      const update = {
        $set: {
          investment_completed: new Date(),
        },
      };

      await crudStore.updateOne("InvestmentEmailHistory", query, update, true);
      return true;
    },
    async insertOrUpdateEntityAccount(is_insert) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      let entity_account = this.entity_template;
      let beneficial_owners = _.cloneDeep(entity_account.beneficial_owners);
      let boolean_owner_is_beneficial_owner = entity_account.owner_is_beneficial_owner;

      // If it's an insert, we need to insert the entity first to get its ID
      if (is_insert) {
        delete entity_account._id;
        delete entity_account.has_existing_investments;
        entity_account = schemaStore.update_password_fields("Accounts", entity_account);
        entity_account = schemaStore.sortObjectProperties(entity_account);

        let result = await crudStore.insertOne("Accounts", entity_account);
        entity_account._id = result.insertedId;
        console.log("boolean_owner_is_beneficial_owner", boolean_owner_is_beneficial_owner);
        if (boolean_owner_is_beneficial_owner) {
          let update_res;
          console.log(this.current_owner_contact.entity_ids);
          let entity_id_array = this.current_owner_contact.entity_ids;
          entity_id_array.push(entity_account._id);

          try {
            update_res = await crudStore.updateOne(
              "Contacts",
              { _id: this.current_owner_contact._id },
              { $set: { entity_ids: entity_id_array } }
            );
          } catch (error) {
            update_res = error;
          }
          console.log("update_res", update_res);
        }
      }

      // Pass entity_account._id to processUpdatesOrInsertsForBeneficialOwners for `entity_ids` association
      await this.processUpdatesOrInsertsForBeneficialOwners(beneficial_owners, entity_account._id);

      // Check if the owner is also a beneficial owner, and add entity_account._id to their entity_ids

      if (!is_insert) {
        let original_entity_account = this.existing_entity_accounts.find(
          (entity) => entity._id === this.selected_entity_account._id
        );
        let fetched_schema = schemaStore.all_account_schema;
        entity_account = UIStore.formatFetchedRecordFromCF(entity_account, fetched_schema);
        let update_entity = schemaStore.compareObjects(original_entity_account, entity_account);
        if (update_entity) {
          entity_account = schemaStore.addCreatedUpdatedFields(entity_account, false, true);
          entity_account = schemaStore.update_password_fields("Accounts", entity_account);
          let query = { _id: entity_account._id };
          await crudStore.updateOne("Accounts", query, { $set: entity_account });
        }
      }

      return entity_account._id;
    },
    async insertOrUpdateJointContact(is_insert) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let contact = this.joint_contact_template;
      let result;

      if (is_insert) {
        delete contact._id;
        contact = schemaStore.update_password_fields("Contacts", contact);
        contact = schemaStore.sortObjectProperties(contact);

        result = await crudStore.insertOne("Contacts", contact);

        return result.insertedId;
      } else {
        let original_contact = this.existing_joint_contacts.find(
          (c) => c._id === this.selected_joint_contact._id
        );

        contact = UIStore.formatFetchedRecordFromCF(contact, schemaStore.all_contact_schema);
        original_contact = UIStore.formatFetchedRecordFromCF(
          original_contact,
          schemaStore.all_contact_schema
        );

        if (schemaStore.compareObjects(original_contact, contact)) {
          contact = schemaStore.addCreatedUpdatedFields(contact, false, true);
          contact = schemaStore.update_password_fields("Contacts", contact);
          contact._id = this.selected_joint_contact._id;

          let query = { _id: this.joint_contact_template._id };
          result = await crudStore.updateOne("Contacts", query, { $set: contact });
        }

        return this.selected_joint_contact._id;
      }
    },
    async processUpdatesOrInsertsForBeneficialOwners(beneficial_owners, entity_account_id) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      let ownerIds = [];

      console.log("Starting processUpdatesOrInsertsForBeneficialOwners:");
      console.log(`Entity Account ID: ${entity_account_id}`);
      console.log(`Total Beneficial Owners to Process: ${beneficial_owners.length}`);

      for (const [index, ben_owner_contact] of beneficial_owners.entries()) {
        console.log(`\nProcessing Beneficial Owner #${index + 1}`);
        let ben_owner = ben_owner_contact;

        if (ben_owner._id && ben_owner._id.length > 10) {
          console.log(`Existing Beneficial Owner detected with ID: ${ben_owner._id}`);

          // // Find the original version of the beneficial owner for comparison
          let original_ben_owner = this.all_related_contacts.find(
            (owner) => owner._id === ben_owner._id
          );

          ben_owner = UIStore.formatFetchedRecordFromCF(ben_owner, schemaStore.all_contact_schema);
          original_ben_owner = UIStore.formatFetchedRecordFromCF(
            original_ben_owner,
            schemaStore.all_contact_schema
          );

          console.log(`Formatted Beneficial Owner Record:`, ben_owner);

          // Ensure entity_account_id is included in the entity_ids array
          if (!ben_owner.entity_ids.includes(entity_account_id)) {
            ben_owner.entity_ids.push(entity_account_id);
            console.log(
              `Added Entity Account ID to entity_ids array for Beneficial Owner ID: ${ben_owner._id}`
            );
          } else {
            console.log(
              `Entity Account ID already present in entity_ids for Beneficial Owner ID: ${ben_owner._id}`
            );
          }

          let update_ben_owner = schemaStore.compareObjects(original_ben_owner, ben_owner);
          console.log(
            `Changes detected for Beneficial Owner ID ${ben_owner._id}:`,
            update_ben_owner
          );

          ben_owner = schemaStore.addCreatedUpdatedFields(ben_owner, false, true);
          ben_owner = schemaStore.update_password_fields("Contacts", ben_owner);

          if (update_ben_owner) {
            console.log(`Updating Beneficial Owner ID: ${ben_owner._id} in Contacts collection`);
            await crudStore.updateOne("Contacts", { _id: ben_owner._id }, { $set: ben_owner });
          } else {
            console.log(
              `No changes detected for Beneficial Owner ID: ${ben_owner._id}. Skipping update.`
            );
          }

          ownerIds.push(ben_owner._id);
          console.log(`Beneficial Owner ID ${ben_owner._id} added to ownerIds array`);
        } else {
          console.log(`New Beneficial Owner detected. Preparing to insert.`);

          delete ben_owner._id;
          ben_owner.user_id = this.current_owner_contact.user_id;
          ben_owner.account_id = this.current_owner_contact.account_id;
          ben_owner.entity_ids = [entity_account_id];
          ben_owner = schemaStore.update_password_fields("Contacts", ben_owner);
          ben_owner = schemaStore.sortObjectProperties(ben_owner);

          console.log(`Inserting new Beneficial Owner into Contacts collection:`, ben_owner);
          const result = await crudStore.insertOne("Contacts", ben_owner);
          const inserted_id = result.insertedId;

          ownerIds.push(inserted_id);
          console.log(
            `New Beneficial Owner inserted with ID: ${inserted_id}. Added to ownerIds array.`
          );
        }
      }

      console.log("\nCompleted processing all beneficial owners.");
      console.log(`Final ownerIds array: ${ownerIds}`);
      return ownerIds;
    },
    async insertSubagreement(investment_id) {
      const { uploadFile_R2 } = useFileUtility();

      let newFileDocument = await uploadFile_R2({
        file: this.uploaded_subagreeement,
        type: "Sub Agreements",
        existingKey: null,
        details: {
          user_id: this.current_owner_contact.user_id,
          investment_id: { $oid: investment_id },
          contact_id: { $oid: this.current_owner_contact._id },
          account_id: { $oid: this.current_owner_contact.account_id },
        },
      });
    },
    async updateOwnerContact() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      const recordDetailStore = useRecordDetailStore();
      let updated_owner_contact = this.current_owner_contact;

      updated_owner_contact = UIStore.formatFetchedRecordFromCF(
        updated_owner_contact,
        schemaStore.all_contact_schema
      );

      updated_owner_contact = schemaStore.addCreatedUpdatedFields(
        updated_owner_contact,
        false,
        true
      );
      updated_owner_contact = schemaStore.update_password_fields("Contacts", updated_owner_contact);

      let update_owner = schemaStore.compareObjects(
        recordDetailStore.currentPageDocument,
        updated_owner_contact
      );
      if (update_owner) {
        let synced_fields = [
          "email",
          "country",
          "street1",
          "street2",
          "state",
          "zip_code",
          "city",
          "phone",
        ];

        //ANY FIELD THAT IS IN THE SYNCED FIELDS ARRAY WILL BE SYNCED TO THE ACCOUNT FROM THE CONTACT

        let update_set = {};

        for (let field of synced_fields) {
          update_set[field] = updated_owner_contact[field];
        }

        update_set.name = `${updated_owner_contact.first_name} ${updated_owner_contact.last_name}`;

        let query = { user_id: updated_owner_contact.user_id, type: "Regular" };
        let update_account_result = await crudStore.updateOne("Accounts", query, {
          $set: update_set,
        });

        let result = await crudStore.updateOne("Contacts", query, { $set: updated_owner_contact });
      }
    },
    async insertBankAccount(type) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let bank_account = {};

      if (type == "Funding") {
        bank_account = this.funding_account_template;
      } else if (type == "Distribution") {
        bank_account = this.distribution_account_template;
      }

      bank_account = schemaStore.update_password_fields("BankAccounts", bank_account);

      delete bank_account._id;
      bank_account = schemaStore.sortObjectProperties(bank_account);

      let result = await crudStore.insertOne("BankAccounts", bank_account);

      return result.insertedId;
    },
    async processFundingBankAccount() {
      let selected_funding_method = this.selected_funding_method;

      let updated_investment_details = {};

      if (selected_funding_method.is_type_rollover) {
        updated_investment_details = {
          payment_method: "Existing Investment",
          is_rollover: true,
          parent_investment: this.selected_rollover_investment._id,
          funding_account: null,
        };
      } else if (selected_funding_method.is_new_bank_account) {
        let inserted_id = await this.insertBankAccount("Funding");

        updated_investment_details = {
          payment_method: "ACH",
          funding_account: inserted_id,
          add_to_ach_batch: this.add_to_ach_batch,
        };
      } else if (selected_funding_method.is_wire_transfer) {
        updated_investment_details = {
          payment_method: "Wire",
          funding_account: null,
        };
      } else if (selected_funding_method.is_check_method) {
        updated_investment_details = {
          payment_method: "Check",
          funding_account: null,
        };
      } else if (selected_funding_method.is_conversion_funding) {
        updated_investment_details = {
          payment_method: "Conversion",
          funding_account: null,
        };
      } else {
        updated_investment_details = {
          payment_method: "ACH",
          funding_account: selected_funding_method._id,
          add_to_ach_batch: this.add_to_ach_batch,
        };
      }

      return updated_investment_details;
    },

    async processDistributionBankAccount() {
      let selected_distribution_method = this.selected_distribution_method;

      let updated_investment_details = {};

      if (selected_distribution_method.is_new_bank_account) {
        let inserted_id = await this.insertBankAccount("Distribution");

        updated_investment_details = {
          distribution_method: "ACH",
          distribution_account: inserted_id,
        };
      } else if (selected_distribution_method.is_check_method) {
        updated_investment_details = {
          distribution_method: "Check",
          distribution_account: null,
        };
      } else {
        updated_investment_details = {
          distribution_method: "ACH",
          distribution_account: selected_distribution_method._id,
        };
      }

      return updated_investment_details;
    },

    //INITIAL FETCHING FUNCTIONS CALLED BY PARENT FUNCTION CALLED PREP INVESTMENT FLOW
    async prepInvestmentFlow() {
      const schemaStore = useSchemaStore();
      const UIStore = useUIStore();
      this.invalid_ein_array = [];
      this.invalid_ssn_array = [];
      this.invalid_email_array = [];

      this.selected_owner_investor = {
        assignedObject: {
          _id: this.current_owner_contact._id,
          name: this.current_owner_contact.email,
        },
        results: [],
        schema: "field",
        searchTerm: "",
      };

      // Fetch offerings first as it seems necessary for the subsequent functions
      this.fetchOfferings();
      const crudStore = useCrudStore();
      this.all_related_contacts = await crudStore.find("Contacts", {
        user_id: this.current_owner_contact.user_id,
        type: { $ne: "Regular" },
      });

      //format all related contacts

      this.all_related_contacts = this.all_related_contacts.map((contact) => {
        const formattedContact = UIStore.formatFetchedRecordFromCF(
          contact,
          schemaStore.all_contact_schema
        );

        return formattedContact;
      });

      console.log("all_related_contacts", this.all_related_contacts);
      this.all_beneficial_owners_for_user = this.all_related_contacts.filter(
        (contact) => contact.type != "Regular"
      );

      for (const contact of this.all_related_contacts) {
        if (contact.email) {
          this.invalid_email_array.push(contact.email);
        }
        if (contact.ssn) {
          this.invalid_ssn_array.push(contact.ssn);
        }
      }
      // Use Promise.all to run these functions concurrently
      await Promise.all([
        this.setSchemaForInvestmentFlow(),
        this.fetchOwnersInvestments(),
        this.fetchExistingJointContacts(),
        this.fetchExistingEntityAccounts(),
        this.fetchExistingAccreditationDocs(),
        this.fetchExistingBankInfo(),
      ]);

      // Filter invalid arrays after fetching is complete
      this.invalid_ssn_array = this.invalid_ssn_array.filter((ssn) => ssn);
      this.invalid_email_array = this.invalid_email_array.filter((email) => email);
      this.invalid_ein_array = this.invalid_ein_array.filter((ein) => ein);
    },
    fetchOfferings() {
      this.offering_name_array = [];
      const schemaStore = useSchemaStore();

      let cloned_offerings = _.cloneDeep(schemaStore.all_offerings);
      this.offerings = cloned_offerings.filter((offering) => offering.is_visible_to_admins);

      for (const offering of this.offerings) {
        this.offering_name_array.push(`${offering.name} - ${offering.issuer} - ${offering.type}`);
      }
    },
    async setSchemaForInvestmentFlow() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      const listsToGrab = ["rollover_table_fields"];

      const configurationLists = await crudStore.find("Settings", {
        reference: { $in: listsToGrab },
      });

      this.rollover_table_fields = configurationLists.find(
        (config) => config.reference === "rollover_table_fields"
      ).options;

      this.funding_account_template = schemaStore.createBankTemplate("Funding");
      this.funding_account_template.user_id = this.current_owner_contact.user_id;
      this.funding_account_template.contact_id = this.current_owner_contact._id;
      this.funding_account_template.account_id = this.current_owner_contact.account_id;
      this.distribution_account_template = schemaStore.createBankTemplate("Distribution");
      this.distribution_account_template.user_id = this.current_owner_contact.user_id;
      this.distribution_account_template.contact_id = this.current_owner_contact._id;
      this.distribution_account_template.account_id = this.current_owner_contact.account_id;
    },
    async fetchExistingAccreditationDocs() {
      const crudStore = useCrudStore();
      let aggregation = [
        { $match: { user_id: this.current_owner_contact.user_id, type: "Accreditation" } },
        { $sort: { created_date: -1 } },
      ];
      this.accreditation_documents = await crudStore.aggregate("Documents", aggregation);
    },
    async fetchOwnersInvestments() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      this.owners_investments = await crudStore.find("Investments", {
        user_id: this.current_owner_contact.user_id,
      });

      if (this.owners_investments.length > 0) {
        this.owner_has_investments = true;
      }

      this.rollover_table_investments = _.cloneDeep(this.owners_investments).filter(
        (investment) => investment.able_to_rollover
      );
      this.rollover_table_investments = this.rollover_table_investments.map((investment) => {
        const formattedInvestment = UIStore.formatFetchedRecordFromCF(
          investment,
          schemaStore.all_investment_schema
        );
        return formattedInvestment;
      });
      this.rollover_table_investments = UIStore.transformRecords(this.rollover_table_investments);

      this.rollover_table_headers = schemaStore.all_investment_schema
        .filter((field) => this.rollover_table_fields.includes(field.field_name))
        .map((header) => ({
          label: header.label,
          field_name: header.field_name,
          field_type: header.field_type,
          number_type: header.number_type,
        }));
      this.rollover_table_headers.unshift({ label: "Select", field_name: "checkbox" });
    },
    async fetchExistingJointContacts() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      this.existing_joint_contacts = await crudStore.find("Contacts", {
        type: "Joint",
        user_id: this.current_owner_contact.user_id,
      });

      this.existing_joint_contacts = this.existing_joint_contacts.map((contact) => {
        const formattedContact = UIStore.formatFetchedRecordFromCF(
          contact,
          schemaStore.all_contact_schema
        );

        this.invalid_ssn_array.push(formattedContact.ssn);
        this.invalid_email_array.push(formattedContact.email);

        return formattedContact;
      });

      if (this.existing_joint_contacts.length == 0) {
        this.add_joint_contact = true;
      } else {
        this.existing_joint_contacts = this.existing_joint_contacts.map((contact) => {
          const hasExistingInvestments = this.owners_investments.some(
            (investment) => investment.joint_contact_id === contact._id
          );
          return {
            ...contact,
            has_existing_investments: hasExistingInvestments,
          };
        });
      }
    },
    async fetchExistingEntityAccounts() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      // Fetch existing entity accounts
      this.existing_entity_accounts = await crudStore.find("Accounts", {
        type: "Entity",
        user_id: this.current_owner_contact.user_id,
      });

      if (this.existing_entity_accounts.length === 0) {
        this.add_entity_account = true;
      } else {
        this.existing_entity_accounts = this.existing_entity_accounts.map((account) =>
          UIStore.formatFetchedRecordFromCF(account, schemaStore.all_account_schema)
        );
        for (const account of this.existing_entity_accounts) {
          if (account.ein) {
            this.invalid_ein_array.push(account.ein);
          }
        }
        // Fetch contacts with an entity_ids array that includes this entity's ID
        const allEntityIds = this.existing_entity_accounts.map((entity) => entity._id);
        const contactsWithEntities = await crudStore.find("Contacts", {
          entity_ids: { $in: allEntityIds },
        });

        const formattedContacts = contactsWithEntities.map((contact) =>
          UIStore.formatFetchedRecordFromCF(contact, schemaStore.all_contact_schema)
        );

        // Attach beneficial owners to each entity account based on the entity_id references in contacts
        this.existing_entity_accounts = this.existing_entity_accounts.map((entity) => {
          //if one of the formatted contacts has type regular i want to set owner_is_beneficial_owner to true
          let owner_is_beneficial_owner = formattedContacts.some(
            (contact) => contact.entity_ids.includes(entity._id) && contact.type === "Regular"
          );

          const associatedOwners = formattedContacts.filter(
            (contact) => contact.entity_ids.includes(entity._id) && contact.type !== "Regular"
          );

          return {
            ...entity,
            beneficial_owners: associatedOwners,
            owner_is_beneficial_owner: owner_is_beneficial_owner,
            has_existing_investments: associatedOwners.some((owner) =>
              this.owners_investments.some(
                (investment) => investment.entity_account_id === entity._id
              )
            ),
          };
        });
        console.log("existing_entity_accounts", this.existing_entity_accounts);
      }
    },
    async fetchExistingBankInfo() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let fetched_bank_accounts = await crudStore.find("BankAccounts", {
        user_id: this.current_owner_contact.user_id,
      });

      fetched_bank_accounts = fetched_bank_accounts.map((bank_account) => {
        let formattedBankAccount = UIStore.formatFetchedRecordFromCF(
          bank_account,
          schemaStore.all_banking_schema
        );

        if (formattedBankAccount.account_number) {
          formattedBankAccount.account_number_for_display = `**** ${formattedBankAccount.account_number.slice(
            -4
          )}`;
        }
        return formattedBankAccount;
      });

      let potential_funding_bank_accounts = _.cloneDeep(fetched_bank_accounts);
      let potential_distribution_bank_accounts = _.cloneDeep(fetched_bank_accounts);
      let generic_funding_accounts = _.cloneDeep(this.generic_funding_accounts);
      let generic_distribution_accounts = _.cloneDeep(this.generic_distribution_accounts);

      this.owners_funding_bank_accounts = [
        ...generic_funding_accounts,
        ...potential_funding_bank_accounts,
      ];
      this.owners_distribution_bank_accounts = [
        ...generic_distribution_accounts,
        ...potential_distribution_bank_accounts,
      ];

      //now for this array this.owners_funding_bank_accounts i need to go through them and make their unique_id a random 10 digit number - this is for a bug fix regarding radio options
      this.owners_funding_bank_accounts = this.owners_funding_bank_accounts.map((bank_account) => {
        bank_account.unique_id = Math.floor(1000000000 + Math.random() * 9000000000);
        return bank_account;
      });
      this.owners_distribution_bank_accounts = this.owners_distribution_bank_accounts.map(
        (bank_account) => {
          bank_account.unique_id = Math.floor(1000000000 + Math.random() * 9000000000);
          return bank_account;
        }
      );
    },

    //UTILITY FUNCTIONS TO CREATE TEMPLATES

    createInvestmentTemplate() {
      const schemaStore = useSchemaStore();
      let investment_schema = schemaStore.all_investment_schema;

      let flattened_investment_schema = schemaStore.flattenSchema(investment_schema, "default");
      let investment_template = schemaStore.generateDefaultObject(flattened_investment_schema);
      let investment_amount =
        Math.floor(parseFloat(this.investment_amount.replace(/,/g, "")) / 1000) * 1000;
      let discount_amount = parseFloat(this.investment_discount_amount.replace(/,/g, ""));

      delete investment_template._id;
      investment_template = schemaStore.addCreatedUpdatedFields(investment_template, true, true);
      investment_template.amount = investment_amount;
      investment_template.bonds = investment_amount / 1000;
      investment_template.is_discounted_bond = this.investment_is_discounted;
      investment_template.current_nav = this.investment_is_discounted
        ? discount_amount
        : investment_amount;
      investment_template.discounted_bond_amount = discount_amount;
      investment_template.status = "Created";
      investment_template.is_paper_sub = true;
      investment_template.offering_id = this.selected_offering._id;

      investment_template.contact_id = this.current_owner_contact._id;
      investment_template.user_id = this.current_owner_contact.user_id;
      investment_template.account_id = this.current_owner_contact.account_id;
      investment_template.is_compounded =
        this.payment_frequency === "Compounded Monthly" ? true : false;

      investment_template.signature_date = new Date(this.investment_signature_date);
      investment_template.type = this.investment_type;
      //new fields from kristian
      investment_template.owner_contact_full_name = `${this.current_owner_contact.first_name} ${this.current_owner_contact.last_name}`;
      investment_template.offering_name = this.selected_offering.name;
      investment_template.offering_type = this.selected_offering.type;

      return investment_template;
    },
    clearState() {
      // Investment Details
      this.investment_type = "";
      this.ben_owner_modal_page = 1;
      this.selected_offering = {};
      this.selected_offering_name = "";
      this.investments_being_created = false;
      this.investment_amount = "0";
      this.payment_frequency = "";
      this.payment_frequency_options = [
        {
          name: "Compounded Monthly",
          description: "Interest and principle are paid at maturity",
          disabled: false,
        },
        {
          name: "Interest Paid Monthly",
          description: "Interest paid monthly and principle paid at maturity",
          disabled: false,
        },
      ];
      this.investment_is_discounted = false;
      this.notify_user_of_created_investment = false;
      this.add_to_ach_batch = false;
      this.investment_discount_amount = "0";
      this.banking_info_is_invalid = true;
      this.investment_funding_method = "";
      this.investmentSteps = [];
      this.currentStep = 0;
      this.offerings = [];
      this.offering_name_array = [];
      this.investment_drawer_open = false;
      this.investment_signature_date = null;
      this.uploaded_subagreeement = null;
      this.investment_being_created = false;
      this.show_investment_completion_screen = false;
      this.selected_funding_method = {};
      this.selected_distribution_method = {};
      this.selected_rollover_investment = {};
      this.current_owner_contact = {};
      this.ben_owner_template = {};
      this.current_owner_contact_full_name = "";
      this.owner_email_is_invalid = false;
      this.joint_email_is_invalid = false;
      this.owner_ssn_is_invalid = false;
      this.entity_ein_is_invalid = false;
      this.owner_has_investments = false;
      this.add_joint_contact = false;
      this.add_entity_account = false;
      this.joint_contact_template = {};
      this.entity_template = {};
      this.existing_joint_contacts = [];
      this.existing_entity_accounts = [];
      this.owners_investments = [];
      this.selected_joint_contact = {};
      this.selected_entity_account = {};
      this.all_beneficial_owners_for_user = [];
      this.joint_ssn_is_invalid = false;
      this.current_beneficial_owner_for_modal = {};
      this.bank_account_modal_open = false;
      this.generic_funding_accounts = [
        {
          _id: 4,
          unique_id: "funding_rollover",
          financial_institution_name: "Rollover",
          account_type: "Use money from existing expiring investment",
          is_type_rollover: true,
          account_number_for_display: "",
        },
        {
          _id: 3,
          unique_id: "funding_check",
          financial_institution_name: "Check",
          account_type: "Make payment by check",
          is_check_method: true,
          account_number_for_display: "",
        },
        {
          _id: 2,
          unique_id: "funding_wire",
          financial_institution_name: "Wire Transfer",
          account_type: "Make payment by wire transfer",
          is_wire_transfer: true,
          account_number_for_display: "",
        },
        {
          _id: 5,
          unique_id: "funding_conversion",
          financial_institution_name: "Conversion",
          account_type: "Process Legacy Conversion",
          is_conversion_funding: true,
          account_number_for_display: "",
        },
      ];
      this.owners_funding_bank_accounts = [];
      this.generic_distribution_accounts = [
        {
          _id: 1,
          unique_id: "distribution_check",
          financial_institution_name: "Check",
          account_type: "Make payment by check",
          is_check_method: true,
          account_number_for_display: "",
        },
      ];
      this.owners_distribution_bank_accounts = [];
      this.bank_account_type = "";
      this.funding_account_template = {};
      this.distribution_account_template = {};
      this.invalid_ssn_array = [];
      this.invalid_email_array = [];
      this.invalid_ein_array = [];
      this.suitability_income_info = [];
      this.admin_investment_types = [];
      this.rollover_table_fields = [];
      this.rollover_table_investments = [];
      this.rollover_table_headers = [];
      this.custodian_name = "";
      this.custodian_account_number = "";
      this.is_valid_suitability_info = true;
      this.accreditation_documents = [];
      this.all_related_contacts = [];
    },
  },
});
