<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" @close-modal="$emit('close-modal')" size="small">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="flex flex-row items-center">
          <div
            class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
          >
            <IconWarning class="fill-functional-warning-click" />
          </div>

          <div class="font-bold text-custom-navy-2">Are you sure?</div>
        </div>
      </template>

      <template v-slot:modal-body>
        <div class="flex flex-col items-center justify-center py-8">
          <div
            class="mb-4 flex items-center justify-center rounded-[6px] bg-functional-error-hover bg-opacity-20"
          >
            <IconDeleteData class="h-value-14 w-value-14 fill-functional-error-hover" />
          </div>
          <div class="text-4.5 h-7 flex-grow-0 text-center leading-[1.56] text-primary-6">
            <p class="text-base">You are about to delete the</p>
            <p class="font-bold">
              {{ dataModelManagementStore.propertyToEdit.label.value }} ({{
                dataModelManagementStore.propertyToEdit.field_name.value
              }})
            </p>
            <p class="text-base">Property</p>
          </div>
          <div
            class="text-4.5 h-7 flex-grow-0 text-left font-semibold leading-[1.56] text-primary-6"
          >
            {{ recordName }}
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <div class="flex flex-row justify-end gap-1">
          <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
            <template #label><p>Cancel</p></template></Button
          >
          <Button size="large" label @click="deleteProperty(), $emit('close-modal')">
            <template #label>Delete</template></Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { Button, Modal } from "@/components";
import { IconDeleteData, IconWarning } from "@/components/icons";
import { useCrudStore, useDataModelManagementStore } from "@/stores";

const dataModelManagementStore = useDataModelManagementStore();
const crudStore = useCrudStore();

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});
async function deleteProperty() {
  await crudStore.deleteOne("Schema", {
    _id: dataModelManagementStore.propertyToEdit._id.value,
  });

  await dataModelManagementStore.setGroupData();
  await dataModelManagementStore.fetchModelNuggets();
}
</script>
