<template>
  <div
    v-if="
      !titleTransferStore.investment_being_created &&
      !titleTransferStore.show_investment_completion_screen
    "
    class="flex flex-col gap-8 mb-4"
  >
    <div>
      <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
        Step {{ titleTransferStore.currentStep + 1 }} : Review and Complete Investment
      </div>
    </div>
    <div class="text-sm text-light">
      User: {{ titleTransferStore.current_owner_contact_full_name }} - Offering Type:
      {{ titleTransferStore.current_offering.name }} ({{
        titleTransferStore.current_offering.interest_rate
      }}%-{{ titleTransferStore.current_offering.bond_term / 12 }} Years-{{
        titleTransferStore.payment_frequency
      }}) - Investment Type: {{ titleTransferStore.transfer_to_investment_type }} - Amount: $
      {{ commaSeparateThousands(titleTransferStore.current_investment.amount) }}
    </div>
    <div class="pb-8 pt-4 border-b border-platform-outlines">
      <div class="font-bold">Signature Dates</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          v-model="titleTransferStore.transferee_signature_date"
          type="date"
          label="Transferee Signature Date"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          v-if="
            titleTransferStore.transfer_to_investment_type.toLowerCase().includes('ira') ||
            titleTransferStore.transfer_to_investment_type.toLowerCase().includes('hsa')
          "
          v-model="titleTransferStore.transferee_custodian_signature_date"
          type="date"
          label="Transferee Custodian Signature Date"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          v-if="titleTransferStore.transfer_to_investment_type == 'Joint'"
          v-model="titleTransferStore.transferee_joint_signature_date"
          type="date"
          label="Transferee Joint Signature Date"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          v-if="titleTransferStore.transfer_to_investment_type == 'Entity'"
          v-model="titleTransferStore.transferee_entity_signature_date"
          type="date"
          label="Transferee Entity Signature Date"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>
    <div
      v-if="
        titleTransferStore.transfer_to_investment_type.toLowerCase().includes('ira') ||
        titleTransferStore.transfer_to_investment_type.toLowerCase().includes('hsa')
      "
      class="pb-8 border-b border-platform-outlines"
    >
      <div class="font-bold">Custodian Details</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          :required="true"
          label="Custodian Name"
          v-model="titleTransferStore.custodian_name"
          type="select"
          :options="schemaStore.custodian_name_options"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          :required="true"
          label="Custodian Account Number"
          v-model="titleTransferStore.custodian_account_number"
          type="number"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>
    <EmailPreferences />
    <div>
      <div class="font-semibold text-lg">Upload Signed Sub Agreement</div>
      <div class="my-2">Please verify before submitting that the:</div>
      <ul class="list-disc pl-5 text-gray-500">
        <li>
          Name on the sub agreement matches the name in the personal information form you filled out
        </li>
        <li>Sensitive information filled out (banking, SSN, EIN) matches what is on the sub</li>
        <li>Joint signature exists on the sub agreeement in the case of a joint investment</li>
      </ul>
    </div>
    <div>
      <div v-if="titleTransferStore.uploaded_title_transfer_subagreeement">
        <div class="mb-4 flex items-center border p-4 rounded-md shadow-sm">
          <div class="flex-grow">
            <div class="text-sm">
              {{ titleTransferStore.uploaded_title_transfer_subagreeement.name }}
            </div>
          </div>

          <div class="flex gap-1">
            <Button @click="removeFile()" icon variant="default-gray-outlined">
              <template #icon>
                <Icon
                  name="Trash"
                  class="h-4.5 w-4.5 stroke-functional-error-default"
                  :stroke-width="2"
              /></template>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <input @change="handleUpload" ref="fileInputRef" type="file" hidden />
        <div
          class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0 bg-light bg-opacity-20"
          :class="dragging ? 'border-blue-500 bg-blue-100' : 'bg-light bg-opacity-20'"
          @drop="drop"
          @dragover.prevent="dragging = true"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <IconX class="h-6 w-6 fill-cTeal" />
          <p>Drag the file here or click upload</p>

          <div class="mt-4">
            <Button @click="chooseFiles" label>
              <template #label>{{
                titleTransferStore.uploaded_title_transfer_subagreeement
                  ? "Replace File"
                  : "Upload File"
              }}</template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="titleTransferStore.investment_being_created">Investment being created ...</div>
  <div v-if="titleTransferStore.show_investment_completion_screen">Investment complete</div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { commaSeparateThousands } from "@/utilities";
import { Button, BaseInput } from "@/components";
import EmailPreferences from "./EmailPreferences.vue";
import { Icon } from "@/components";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();
const fileInputRef = ref(null);

const dragging = ref(false);
const uploadState = ref("idle"); // 'idle', 'uploading', 'completed'
const uploadProgress = ref(0);
let uploadTimeout = null;

const removeFile = () => {
  titleTransferStore.uploaded_title_transfer_subagreeement = null;
};

const simulateUpload = () => {
  uploadState.value = "uploading";
  uploadProgress.value = 0;
  uploadTimeout = setInterval(() => {
    uploadProgress.value += 50; // Increase by 50% every second
    if (uploadProgress.value >= 100) {
      clearInterval(uploadTimeout);
      uploadState.value = "completed";
    }
  }, 1000);
};

const chooseFiles = () => {
  fileInputRef.value.click();
};

const handleUpload = async (e) => {
  titleTransferStore.uploaded_title_transfer_subagreeement = e.target.files[0];
  simulateUpload();
};

const drop = (e) => {
  e.preventDefault();
  dragging.value = false;
  titleTransferStore.uploaded_title_transfer_subagreeement = e.dataTransfer.files[0];
  simulateUpload();
};

watch(
  () => [
    titleTransferStore.transferee_signature_date,
    titleTransferStore.transferee_custodian_signature_date,
    titleTransferStore.transferee_joint_signature_date,
    // titleTransferStore.transferee_entity_signature_date,
    titleTransferStore.custodian_name,
    titleTransferStore.custodian_account_number,
    titleTransferStore.uploaded_title_transfer_subagreeement,
  ],
  () => {
    const {
      transferee_signature_date,
      transferee_custodian_signature_date,
      transferee_joint_signature_date,
      // transferee_entity_signature_date,
      custodian_name,
      custodian_account_number,
      transfer_to_investment_type,
    } = titleTransferStore;

    const isIRAorHSA =
      transfer_to_investment_type.toLowerCase().includes("ira") ||
      transfer_to_investment_type.toLowerCase().includes("hsa");

    const isJoint = transfer_to_investment_type === "Joint";
    const isEntity = transfer_to_investment_type === "Entity";

    const custodian_info_is_invalid = isIRAorHSA && (!custodian_name || !custodian_account_number);

    const isInvalid =
      !transferee_signature_date ||
      (isIRAorHSA && !transferee_custodian_signature_date) ||
      (isJoint && !transferee_joint_signature_date) ||
      //(isEntity && !transferee_entity_signature_date) ||
      custodian_info_is_invalid ||
      !titleTransferStore.uploaded_title_transfer_subagreeement;

    titleTransferStore.last_og_title_transfer_step_is_invalid = isInvalid;
  }
);
</script>
