import { defineStore } from "pinia";
import * as Realm from "realm-web";
import { useUIStore, useCrudStore } from "@/stores";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    //user data
    currentUser: null,
    signInFields: {
      email: "",
      password: "",
    },
    is_verified: true,
    verification_code: ["", "", "", "", "", ""],
    isAuthenticatedNavigation: false,
    invalidSession: false,
    allAdminUsers: [],
    adminUserData: null,

    //turnstile
    turnstileToken: "",
    isTurnstileValid: false,

    //auth logs
    authLogs: [],
    authLogsFilter: null,
    authLogsSort: null,
    authLogsPage: 1,
  }),
  getters: {
    isDalmoreAdmin: (state) => {
      if (!state.adminUserData) return false;
      return (
        state.adminUserData.permissions.includes("dalmore-admin") &&
        !state.adminUserData.permissions.includes("master-admin")
      );
    },
    hasInvestmentProcessingPermissions: (state) => {
      if (!state.adminUserData) return false;
      return (
        state.adminUserData.permissions.includes("finance-admin") ||
        state.adminUserData.permissions.includes("ir-admin") ||
        state.adminUserData.permissions.includes("master-admin")
      );
    },
  },
  actions: {
    async signOut() {
      try {
        // make call to remove user session from KV storage
        await fetch(`${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/admin-sign-out`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: this.currentUser.id,
            session_id: this.currentUser.session_id,
          },
        });
        this.currentUser = null;
        this.isAuthenticatedNavigation = false;
        this.is_verified = false;
        localStorage.removeItem("currentUser");
      } catch (err) {
        console.error(err);
      }
    },
    async sendVerificationCode() {
      try {
        const formattedEmail = this.signInFields.email.toLowerCase().trim();

        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/admin-mfa?type=request`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              email: formattedEmail,
            }),
          }
        );
        return await response.json();
      } catch (err) {
        console.error(err);
      }
    },
    async verifyMFACodeAndSignin(code) {
      try {
        const formattedEmail = this.signInFields.email.toLowerCase().trim();
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/admin-mfa?type=verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              email: formattedEmail,
              password: this.signInFields.password,
              code,
            }),
          }
        );
        const result = await response.json();
        if (result.status !== 500) {
          this.currentUser = result;
          localStorage.setItem("currentUser", JSON.stringify(result));
        } else {
          this.currentUser = null;
          localStorage.removeItem("currentUser");
        }
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async sendPasswordResetEmail(email) {
      try {
        await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/admin-send-password-reset-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              email: email.toLowerCase(),
            }),
          }
        );
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async changePassword(token, tokenId, password) {
      try {
        await fetch(`${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/admin-reset-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            origin_token: this.turnstileToken,
          },
          body: JSON.stringify({
            token,
            tokenId,
            password,
          }),
        });
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async validateUser(email, password) {
      try {
        // TODO: Need to setup to work with actual Turnstile
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/validate-credentials`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              origin_token: this.turnstileToken,
            },
            body: JSON.stringify({
              email: email.toLowerCase().trim(),
              password,
            }),
          }
        );

        return await response.json();
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async sendCustomLog({ error, error_type, error_summary, error_source }) {
      // console.log(this.currentUser);
      // await this.currentUser.functions.Create_Custom_Log(
      //   this.currentUser.id,
      //   error_type,
      //   error_summary,
      //   this.serializeError(error),
      //   error_source
      // );
    },
    serializeError(error) {
      if (error instanceof Error) {
        return {
          message: error.message || "No error message provided.",
          stack: error.stack || "No stack trace provided.",
          name: error.name || "No error name provided.",
        };
      } else if (typeof error === "object" && error !== null) {
        return error;
      } else if (typeof error === "string") {
        try {
          const parsed = JSON.parse(error);
          return typeof parsed === "object" ? parsed : { message: error };
        } catch (e) {
          return { message: error };
        }
      } else {
        return { message: "Unrecognized error format", data: String(error) };
      }
    },
    async setTabsForLoggedInUser() {
      const UIStore = useUIStore();
      const crudStore = useCrudStore();

      if (!this.currentUser) return;

      const { tabs = [] } = await crudStore.findOne("UserTabs", { user_id: this.currentUser.id });
      if (tabs.length == 0) return;

      let temp = [];
      for (let tab of tabs) {
        let tabToAdd = {
          ...tab,
          is_active: false,
          is_show_options: false,
        };
        temp.push(tabToAdd);
      }

      UIStore.tabs = temp;
      UIStore.tabs.sort((a, b) => {
        if (a.is_pinned && !b.is_pinned) {
          return -1;
        }
        if (!a.is_pinned && b.is_pinned) {
          return 1;
        }

        // If both are pinned, then sort by pin_order
        if (a.is_pinned && b.is_pinned) {
          return a.pin_order - b.pin_order;
        }

        // If neither are pinned, their order doesn't change
        return 0;
      });
    },
    async fetchAdminUsers() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      try {
        const pipeline = [];
        if (UIStore.sortHeader) {
          pipeline.push({
            $sort: { [UIStore.sortHeader.field_name]: UIStore.sortAscending ? 1 : -1 },
          });
        }
        this.allAdminUsers = await crudStore.aggregate("AdminUserData", pipeline);
      } catch (err) {
        console.error(err);
      }
    },
    async registerNewAdminUser(email) {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/register-new-admin-user`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              session_id: this.currentUser.session_id,
              user_id: this.currentUser.id,
            },
            body: JSON.stringify({
              email,
            }),
          }
        );
        return await response.json();
      } catch (err) {
        console.error(err);
      }
    },
    async fetchAuthLogs(isFetchAll = false, hideLoader = false) {
      const UIStore = useUIStore();
      const AUTH_LOGS_LIMIT = 20;
      try {
        UIStore.isLoading = isFetchAll || hideLoader ? false : true;

        const requestBody = {
          skip: (this.authLogsPage - 1) * AUTH_LOGS_LIMIT,
          limit: AUTH_LOGS_LIMIT,
          orderBy: { created_date: "DESC" },
        };

        if (this.authLogsFilter) {
          requestBody.filter = this.authLogsFilter;
        }

        if (this.authLogsSort) {
          requestBody.orderBy = this.authLogsSort;
        }

        if (isFetchAll) {
          requestBody.limit = this.authLogsTotalCount;
          requestBody.skip = null;
        }

        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/fetch-auth-logs`,
          {
            method: "POST",
            headers: {
              user_id: this.currentUser.id,
              session_id: this.currentUser.session_id,
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();
        const authLogResults = result?.results?.results;
        const authLogTotalCount = result?.totalCount?.results?.total;

        console.log("auth logs results", authLogResults);
        console.log("auth logs total count", authLogTotalCount);
        if (result && authLogResults.length && !isFetchAll) {
          this.authLogs = authLogResults;
          this.authLogsTotalCount = authLogTotalCount;
        }
        if (isFetchAll) {
          return authLogResults;
        }
      } catch (err) {
        console.error(err);
        UIStore.animateNotificationAlert({
          message: "Failed to fetch auth logs, try refreshing the page.",
          type: "error",
          icon: "X",
        });
      } finally {
        UIStore.isLoading = false;
      }
    },
  },
});
