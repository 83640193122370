import {
  IconArrowUpward,
  IconBell,
  IconCampaign,
  IconCheckmark,
  IconChevronLeft,
  IconChevronRight,
  IconContract,
  IconDelete,
  IconDeleteData,
  IconEdit,
  IconExpand,
  IconExpandLess,
  IconExpandMore,
  IconHamburgerMenu,
  IconPhxCapLogo,
  IconPlus,
  IconPulseSpinner,
  IconQuestionMark,
  IconSearch,
  IconSearchOff,
  IconSetting,
  IconTableChart,
  IconTableRows,
  IconVisibility,
  IconVisibilityOff,
  IconWarning,
  IconX,
  IconContact,
  IconInvestment,
  IconApproval,
  IconIdentity,
  IconLandmark,
  IconKey,
} from "@/components/icons";

export const iconMapping = {
  IconArrowUpward: IconArrowUpward,
  IconBell: IconBell,
  IconCampaign: IconCampaign,
  IconCheckmark: IconCheckmark,
  IconChevronLeft: IconChevronLeft,
  IconChevronRight: IconChevronRight,
  IconContract: IconContract,
  IconDelete: IconDelete,
  IconDeleteData: IconDeleteData,
  IconEdit: IconEdit,
  IconExpand: IconExpand,
  IconExpandLess: IconExpandLess,
  IconExpandMore: IconExpandMore,
  IconHamburgerMenu: IconHamburgerMenu,
  IconPhxCapLogo: IconPhxCapLogo,
  IconPlus: IconPlus,
  IconPulseSpinner: IconPulseSpinner,
  IconQuestionMark: IconQuestionMark,
  IconSearch: IconSearch,
  IconSearchOff: IconSearchOff,
  IconSetting: IconSetting,
  IconTableChart: IconTableChart,
  IconTableRows: IconTableRows,
  IconVisibility: IconVisibility,
  IconVisibilityOff: IconVisibilityOff,
  IconWarning: IconWarning,
  IconX: IconX,
  IconContact: IconContact,
  IconInvestment: IconInvestment,
  IconApproval: IconApproval,
  IconIdentity: IconIdentity,
  IconLandmark: IconLandmark,
  IconKey: IconKey,
};
