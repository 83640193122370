import { defineStore } from "pinia";

import { useCrudStore, useSearchStore, useAuthStore, useUIStore, useSchemaStore } from "@/stores";

import _ from "lodash";
import useFileUtility from "@/composables/useFileUtility";

export const useTitleTransferStore = defineStore("titleTransferStore", {
  state: () => ({
    investment_type: "",
    selected_offering: {},
    selected_offering_name: "",
    all_related_contacts: [],
    current_offering: {},
    investment_amount: "0",
    first_og_title_transfer_step_is_invalid: true,
    last_og_title_transfer_step_is_invalid: true,
    payment_frequency: "",
    send_settled_email: false,
    notify_user_of_created_investment: false,
    selected_transfer_option: {},
    payment_frequency_options: [
      {
        name: "Compounded Monthly",
        description: "Interest and principle are paid at maturity",
        disabled: false,
      },
      {
        name: "Interest Paid Monthly",
        description: "Interest paid monthly and principle paid at maturity",
        disabled: false,
      },
    ],
    title_transfer_flow_options: [
      {
        name: "Transfer to New Owner",
        description: "Transfers the investment to a new investor within the system.",
        id: "transfer_to_new_owner",
      },
      {
        name: "Change Investment Type",
        description:
          "Modifies the type of investment. This mirrors the title transfer process from the original system.",
        id: "legacy_title_transfer",
      },
      {
        name: "Split Investment",
        description: "Divides the existing investment into multiple, separate investments.",
        id: "split_investment",
      },
    ],

    investment_is_discounted: false,
    owner_entered_email: "",
    transferor_signature_date: null,
    transferee_signature_date: null,
    owner_entered_ssn: "",
    transferee_entered_email: "",
    transferee_entered_ssn: "",
    investment_discount_amount: "0",
    banking_info_is_invalid: true,
    investment_funding_method: "",
    investmentSteps: ["Type Selection"],
    currentStep: 0,
    offerings: [],
    offering_name_array: [],
    transfer_drawer_open: false,
    investment_signature_date: null,
    uploaded_subagreeement: null,
    uploaded_split_subagreeement: null,
    uploaded_owner_transfer_form: null,
    investment_being_created: false,
    show_investment_completion_screen: false,
    selected_funding_method: {},
    selected_distribution_method: {},
    selected_rollover_investment: {},
    selected_transferee_investor: {
      assignedObject: {
        _id: null,
        name: "",
      },
      results: [],
      schema: "field",
      searchTerm: "",
    },
    current_owner_contact: {},
    transfer_to_investment_type: "",
    owner_transferor_is_invalid: true,
    transferee_is_invalid: true,
    current_owner_contact_full_name: "",
    owner_email_is_invalid: false,
    joint_email_is_invalid: false,
    transferor_custodian_signature_date: null,
    transferor_joint_signature_date: null,
    transferor_entity_signature_date: null,
    transferee_custodian_signature_date: null,
    transferee_joint_signature_date: null,
    transferee_entity_signature_date: null,
    owner_ssn_is_invalid: false,
    entity_ein_is_invalid: false,
    owner_has_investments: false,
    add_joint_contact: false,
    add_entity_account: false,
    joint_contact_template: {},
    entity_template: {},
    investment_split_is_invalid: true,
    existing_joint_contacts: [],
    uploaded_title_transfer_subagreeement: null,
    existing_entity_accounts: [],
    owners_investments: [],
    selected_joint_contact: {},
    selected_entity_account: {},
    all_beneficial_owners_for_user: [],
    joint_ssn_is_invalid: false,
    current_beneficial_owner_for_modal: {},
    reason_for_transfer: "",
    other_reason_for_transfer: "",
    bank_account_modal_open: false,
    split_investments: [
      {
        bond_amount: "0",
      },
      {
        bond_amount: "0",
      },
    ],
    current_investment: {},
    bank_account_type: "",
    funding_account_template: {},
    distribution_account_template: {},
    invalid_ssn_array: [],
    invalid_email_array: [],
    invalid_ein_array: [],
    suitability_income_info: [],
    admin_investment_types: [],
    rollover_table_fields: [],
    rollover_table_investments: [],
    rollover_table_headers: [],
    selected_transferee_full_document: {},
    custodian_name: "",
    custodian_account_number: "",
    is_valid_suitability_info: true,
    accreditation_documents: [],
  }),
  actions: {
    async completeSplitInvestment() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let investments_to_insert = [];

      console.log(
        "current_investment discount amount",
        this.current_investment.discounted_bond_amount
      );

      const { next_10th, prev_10th } = this.calculateNextAndPrev10th();
      //   console.log("next_10th", next_10th, "prev_10th", prev_10th);
      for (const investment of this.split_investments) {
        let new_investment_record = _.cloneDeep(this.current_investment);

        if (new_investment_record.is_discounted_bond) {
          new_investment_record.discounted_bond_amount = this.calculateSplitDiscountedBondAmount(
            new_investment_record.amount,
            investment.bond_amount.replace(/,/g, "") * 1000,
            new_investment_record.discounted_bond_amount
          );
          new_investment_record.is_discounted_bond = true;
        } else {
          new_investment_record.is_discounted_bond = false;
          new_investment_record.discounted_bond_amount = 0;
        }

        //set distribution window dates
        //   new_investment_record.is_discounted_bond = false;
        //  new_investment_record.discounted_bond_amount = 0;
        new_investment_record.is_child_of_split = true;
        new_investment_record.is_title_transfer = true;
        new_investment_record.bonds = investment.bond_amount.replace(/,/g, "") * 1;
        new_investment_record.status = "Created";
        new_investment_record.amount = investment.bond_amount.replace(/,/g, "") * 1000;
        new_investment_record.current_nav = investment.bond_amount.replace(/,/g, "") * 1000;
        new_investment_record.transfer_parent_ids = [this.current_investment._id];
        new_investment_record.split_date = new Date();
        new_investment_record.close_date = new Date();
        new_investment_record.send_settled_email = this.send_settled_email;
        new_investment_record.distribution_window_start_date = next_10th;
        new_investment_record.partial_redemptions = [];
        new_investment_record = schemaStore.addCreatedUpdatedFields(
          new_investment_record,
          true,
          true
        );
        new_investment_record = schemaStore.update_password_fields(
          "Investments",
          new_investment_record
        );
        delete new_investment_record._id;
        investments_to_insert.push(new_investment_record);
      }

      // console.log("investments_to_insert", investments_to_insert);

      //need to loop through the investments to sum up the discounted bond amounts and console.log the final total
      let total_discounted_bond_amount = 0;
      for (const investment of investments_to_insert) {
        total_discounted_bond_amount += investment.discounted_bond_amount;
      }

      console.log("total_discounted_bond_amount", total_discounted_bond_amount);

      let result = await crudStore.insertMany("Investments", investments_to_insert);

      let insert_id_array = result.insertedIds;

      let filter = { _id: { $in: insert_id_array } };
      // //status needs to be updated after the insert due to the handler logic - this has to remain separate
      let status_update = { $set: { status: "Settled" } };

      // console.log(filter, status_update);

      let response = await crudStore.updateMany("Investments", filter, status_update, false);

      for (const new_id of insert_id_array) {
        await this.insertSubagreement(new_id, this.uploaded_split_subagreeement);
      }

      // //update the parent investment to have the children ids
      let query = { _id: this.current_investment._id };
      let update = {
        $set: {
          transfer_children_ids: insert_id_array,
          status: "Split",
          distribution_window_end_date: prev_10th,
          split_date: new Date(),
          //potentially other fields here too
        },
      };
      await crudStore.updateOne("Investments", query, update);
      return this.current_owner_contact._id;
    },
    async completeOwnerTransferInvestment() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      let investment = _.cloneDeep(this.current_investment);

      investment.status = "Created";
      investment.type = "Individual";
      investment.is_title_transfer = true;
      investment.transfer_parent_ids = [this.current_investment._id];
      investment.transfer_children_ids = [];
      investment.contact_id = this.selected_transferee_full_document._id;
      investment.account_id = this.selected_transferee_full_document.account_id;
      investment.user_id = this.selected_transferee_full_document.user_id;
      investment.all_contacts_identity_verified = false;
      investment.custodian_account_number = "";
      investment.custodian_id = null;
      investment.signature_date = this.transferor_signature_date;
      investment.joint_contact_id = null;
      investment.entity_account_id = null;
      investment.close_date = null;
      investment.funding_account = null;
      investment.distribution_account = null;
      investment.payment_method = "";
      investment.send_settled_email = this.send_settled_email;
      investment.distribution_method = "";
      investment.transferred_from_first_payment_date = this.current_investment.first_payment_date;
      investment.transferee_entity_signature_date = null;
      investment.transferor_custodian_signature_date = null;
      investment.transferee_custodian_signature_date = null;
      investment.transferor_joint_signature_date = null;
      investment.transferee_joint_signature_date = null;
      investment.transferor_entity_signature_date = null;
      investment.notes = "";
      investment.approval_status = "";
      investment.cancelled_date = null;
      investment.transferor_signature_date = this.transferor_signature_date;
      investment.transferee_signature_date = this.transferee_signature_date;
      investment.is_child_of_split = false;
      investment.split_date = null;
      investment.partial_redemptions = [];

      delete investment._id;
      investment = schemaStore.addCreatedUpdatedFields(investment, true, true);
      investment = schemaStore.update_password_fields("Investments", investment);
      investment = schemaStore.sortObjectProperties(investment);

      let inserted_investment = await crudStore.insertOne("Investments", investment);
      let inserted_investment_id = inserted_investment.insertedId;
      if (inserted_investment_id && !this.notify_user_of_created_investment) {
        await this.updateInvestmentEmailHistory(inserted_investment_id);
      }
      await this.insertSubagreement(inserted_investment_id, this.uploaded_owner_transfer_form);
      return inserted_investment_id;
    },
    async completeTransferInvestment() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      let cloned_investment_record = _.cloneDeep(this.current_investment);

      let fields_to_set = {
        all_contacts_identity_verified: false,
        close_date: null,
        cancelled_date: null,
        type: this.transfer_to_investment_type,
        notes: "",
        status: "Created",
        signature_date: this.transferor_signature_date,
        transferee_custodian_signature_date: null,
        transferee_signature_date: null,
        transferor_custodian_signature_date: null,
        transferor_entity_signature_date: null,
        transferor_joint_signature_date: null,
        transferor_signature_date: null,
        transferee_joint_signature_date: null,
        transferee_entity_signature_date: null,
        custodian_account_number: "",
        is_child_of_split: false,
        split_date: null,
        custodian_id: "",
        joint_contact_id: null,
        entity_account_id: null,
        is_title_transfer: true,
        transfer_children_ids: [],
        transfer_parent_ids: [this.current_investment._id],
        first_payment_date: null, //should initially be set to null and will be updated when we settle
        close_date: null, //should initially be set to null and will be updated when we settle
        send_settled_email: this.send_settled_email,
        approval_status: "",
        partial_redemptions: [],
      };

      let new_investment_record = {
        ...cloned_investment_record,
        ...fields_to_set,
      };

      if (this.transfer_to_investment_type === "Joint") {
        new_investment_record.joint_contact_id = await this.insertOrUpdateJointContact(
          this.add_joint_contact
        );
        new_investment_record.transferee_joint_signature_date =
          this.transferee_joint_signature_date;
      }
      if (this.current_investment.type === "Joint") {
        new_investment_record.transferor_joint_signature_date =
          this.transferor_joint_signature_date;
      }
      if (this.transfer_to_investment_type === "Entity") {
        new_investment_record.entity_account_id = await this.insertOrUpdateEntityAccount(
          this.add_entity_account
        );
        new_investment_record.transferee_entity_signature_date =
          this.transferee_entity_signature_date || null;
      }
      if (this.current_investment.type === "Entity") {
        new_investment_record.transferor_entity_signature_date =
          this.transferor_entity_signature_date || null;
      }
      if (
        this.transfer_to_investment_type.toLowerCase().includes("ira") ||
        this.transfer_to_investment_type.toLowerCase().includes("hsa")
      ) {
        let matching_custodian = schemaStore.all_custodians.find(
          (custodian) => custodian.name === this.custodian_name
        );
        new_investment_record.custodian_id = matching_custodian._id;
        new_investment_record.custodian_account_number = this.custodian_account_number;
      }
      if (
        this.current_investment.type.toLowerCase().includes("ira") ||
        this.current_investment.type.toLowerCase().includes("hsa")
      ) {
        new_investment_record.transferor_custodian_signature_date =
          this.transferor_custodian_signature_date;
      }

      delete new_investment_record._id;
      new_investment_record.transferred_from_first_payment_date =
        this.current_investment.first_payment_date;
      new_investment_record.transferee_signature_date = this.transferee_signature_date;
      new_investment_record.transferor_signature_date = this.transferor_signature_date;
      new_investment_record = schemaStore.addCreatedUpdatedFields(
        new_investment_record,
        true,
        true
      );
      new_investment_record = schemaStore.update_password_fields(
        "Investments",
        new_investment_record
      );
      new_investment_record = schemaStore.sortObjectProperties(new_investment_record);

      let inserted_investment = await crudStore.insertOne("Investments", new_investment_record);
      let inserted_investment_id = inserted_investment.insertedId;
      if (inserted_investment_id && !this.notify_user_of_created_investment) {
        await this.updateInvestmentEmailHistory(inserted_investment_id);
      }
      await this.insertSubagreement(
        inserted_investment_id,
        this.uploaded_title_transfer_subagreeement
      );
      return inserted_investment_id;
    },
    async updateInvestmentEmailHistory(investment_id) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();

      const query = { investment_id: investment_id };
      const update = {
        $set: {
          investment_completed: new Date(),
        },
      };

      await crudStore.updateOne("InvestmentEmailHistory", query, update, true);
    },
    async insertOrUpdateEntityAccount(is_insert) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      let entity_account = this.entity_template;
      let beneficial_owners = _.cloneDeep(entity_account.beneficial_owners);
      let boolean_owner_is_beneficial_owner = entity_account.owner_is_beneficial_owner;

      // If it's an insert, we need to insert the entity first to get its ID
      if (is_insert) {
        delete entity_account._id;
        delete entity_account.has_existing_investments;
        entity_account = schemaStore.update_password_fields("Accounts", entity_account);
        entity_account = schemaStore.sortObjectProperties(entity_account);

        let result = await crudStore.insertOne("Accounts", entity_account);
        entity_account._id = result.insertedId;
        console.log("boolean_owner_is_beneficial_owner", boolean_owner_is_beneficial_owner);
        if (boolean_owner_is_beneficial_owner) {
          let update_res;
          console.log(this.current_owner_contact.entity_ids);
          let entity_id_array = this.current_owner_contact.entity_ids;
          entity_id_array.push(entity_account._id);

          try {
            update_res = await crudStore.updateOne(
              "Contacts",
              { _id: this.current_owner_contact._id },
              { $set: { entity_ids: entity_id_array } }
            );
          } catch (error) {
            update_res = error;
          }
          console.log("update_res", update_res);
        }
      }

      // Pass entity_account._id to processUpdatesOrInsertsForBeneficialOwners for `entity_ids` association
      await this.processUpdatesOrInsertsForBeneficialOwners(beneficial_owners, entity_account._id);

      // Check if the owner is also a beneficial owner, and add entity_account._id to their entity_ids

      if (!is_insert) {
        let original_entity_account = this.existing_entity_accounts.find(
          (entity) => entity._id === this.selected_entity_account._id
        );
        let fetched_schema = schemaStore.all_account_schema;
        entity_account = UIStore.formatFetchedRecordFromCF(entity_account, fetched_schema);
        let update_entity = schemaStore.compareObjects(original_entity_account, entity_account);
        if (update_entity) {
          entity_account = schemaStore.addCreatedUpdatedFields(entity_account, false, true);
          entity_account = schemaStore.update_password_fields("Accounts", entity_account);
          let query = { _id: entity_account._id };
          await crudStore.updateOne("Accounts", query, { $set: entity_account });
        }
      }

      return entity_account._id;
    },
    async insertOrUpdateJointContact(is_insert) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let contact = this.joint_contact_template;
      let result;

      if (is_insert) {
        delete contact._id;
        contact = schemaStore.update_password_fields("Contacts", contact);
        contact = schemaStore.sortObjectProperties(contact);
        result = await crudStore.insertOne("Contacts", contact);

        return result.insertedId;
      } else {
        let original_contact = this.existing_joint_contacts.find(
          (c) => c._id === this.selected_joint_contact._id
        );

        contact = UIStore.formatFetchedRecordFromCF(contact, schemaStore.all_contact_schema);
        original_contact = UIStore.formatFetchedRecordFromCF(
          original_contact,
          schemaStore.all_contact_schema
        );

        if (schemaStore.compareObjects(original_contact, contact)) {
          contact = schemaStore.addCreatedUpdatedFields(contact, false, true);
          contact = schemaStore.update_password_fields("Contacts", contact);
          contact._id = this.selected_joint_contact._id;

          let query = { _id: this.joint_contact_template._id };

          result = await crudStore.updateOne("Contacts", query, { $set: contact });
        }

        return this.selected_joint_contact._id;
      }
    },
    async processUpdatesOrInsertsForBeneficialOwners(beneficial_owners, entity_account_id) {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      let ownerIds = [];

      console.log("Starting processUpdatesOrInsertsForBeneficialOwners:");
      console.log(`Entity Account ID: ${entity_account_id}`);
      console.log(`Total Beneficial Owners to Process: ${beneficial_owners.length}`);

      for (const [index, ben_owner_contact] of beneficial_owners.entries()) {
        console.log(`\nProcessing Beneficial Owner #${index + 1}`);
        let ben_owner = ben_owner_contact;

        if (ben_owner._id && ben_owner._id.length > 10) {
          console.log(`Existing Beneficial Owner detected with ID: ${ben_owner._id}`);

          // // Find the original version of the beneficial owner for comparison
          let original_ben_owner = this.all_related_contacts.find(
            (owner) => owner._id === ben_owner._id
          );

          ben_owner = UIStore.formatFetchedRecordFromCF(ben_owner, schemaStore.all_contact_schema);
          original_ben_owner = UIStore.formatFetchedRecordFromCF(
            original_ben_owner,
            schemaStore.all_contact_schema
          );

          console.log(`Formatted Beneficial Owner Record:`, ben_owner);

          // Ensure entity_account_id is included in the entity_ids array
          if (!ben_owner.entity_ids.includes(entity_account_id)) {
            ben_owner.entity_ids.push(entity_account_id);
            console.log(
              `Added Entity Account ID to entity_ids array for Beneficial Owner ID: ${ben_owner._id}`
            );
          } else {
            console.log(
              `Entity Account ID already present in entity_ids for Beneficial Owner ID: ${ben_owner._id}`
            );
          }

          let update_ben_owner = schemaStore.compareObjects(original_ben_owner, ben_owner);
          console.log(
            `Changes detected for Beneficial Owner ID ${ben_owner._id}:`,
            update_ben_owner
          );

          ben_owner = schemaStore.addCreatedUpdatedFields(ben_owner, false, true);
          ben_owner = schemaStore.update_password_fields("Contacts", ben_owner);

          if (update_ben_owner) {
            console.log(`Updating Beneficial Owner ID: ${ben_owner._id} in Contacts collection`);
            await crudStore.updateOne("Contacts", { _id: ben_owner._id }, { $set: ben_owner });
          } else {
            console.log(
              `No changes detected for Beneficial Owner ID: ${ben_owner._id}. Skipping update.`
            );
          }

          ownerIds.push(ben_owner._id);
          console.log(`Beneficial Owner ID ${ben_owner._id} added to ownerIds array`);
        } else {
          console.log(`New Beneficial Owner detected. Preparing to insert.`);

          delete ben_owner._id;
          ben_owner.user_id = this.current_owner_contact.user_id;
          ben_owner.account_id = this.current_owner_contact.account_id;
          ben_owner.entity_ids = [entity_account_id];
          ben_owner = schemaStore.update_password_fields("Contacts", ben_owner);
          ben_owner = schemaStore.sortObjectProperties(ben_owner);

          console.log(`Inserting new Beneficial Owner into Contacts collection:`, ben_owner);
          const result = await crudStore.insertOne("Contacts", ben_owner);
          const inserted_id = result.insertedId;

          ownerIds.push(inserted_id);
          console.log(
            `New Beneficial Owner inserted with ID: ${inserted_id}. Added to ownerIds array.`
          );
        }
      }

      console.log("\nCompleted processing all beneficial owners.");
      console.log(`Final ownerIds array: ${ownerIds}`);
      return ownerIds;
    },
    async insertSubagreement(investment_id, file) {
      const { uploadFile_R2 } = useFileUtility();

      let newFileDocument = await uploadFile_R2({
        file: file,
        type: "Sub Agreements",
        existingKey: null,
        details: {
          user_id: this.current_owner_contact.user_id,
          investment_id: { $oid: investment_id },
          contact_id: { $oid: this.current_owner_contact._id },
          account_id: { $oid: this.current_owner_contact.account_id },
        },
      });
    },

    async getResultsForTransfereeUserAssignmentField(searchTerm) {
      const crudStore = useCrudStore();
      const searchStore = useSearchStore();
      const collection = "Contacts";

      let resultsToSet = [];
      let query = { type: "Regular", _id: { $ne: this.current_owner_contact._id } };

      if (!searchTerm) {
        resultsToSet = await crudStore.find(
          collection,
          query,
          { _id: 1, first_name: 1, last_name: 1, email: 1 },
          null,
          null,
          50
        );
      } else {
        resultsToSet = await searchStore.getSingleCollectionResults(
          searchTerm,
          "contacts",
          null,
          50,
          query
        );
      }

      this.selected_transferee_investor.results = resultsToSet;
    },
    async setResultForTransfereeUserAssignmentField(result) {
      const UIStore = useUIStore();
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      this.selected_transferee_investor.searchTerm = "";
      this.selected_transferee_investor.assignedObject = {
        _id: result._id,
        name: `${result.first_name} ${result.last_name}`,
      };

      let fetched_record = await crudStore.findOne("Contacts", {
        _id: result._id,
      });
      //format fetched record

      this.selected_transferee_full_document = fetched_record = UIStore.formatFetchedRecordFromCF(
        fetched_record,
        schemaStore.all_contact_schema
      );
    },

    async prepTransferInvestmentFlow() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      this.all_related_contacts = await crudStore.find("Contacts", {
        user_id: this.current_owner_contact.user_id,
        type: { $ne: "Regular" },
      });

      this.all_related_contacts = this.all_related_contacts.map((contact) => {
        const formattedContact = UIStore.formatFetchedRecordFromCF(
          contact,
          schemaStore.all_contact_schema
        );

        return formattedContact;
      });

      console.log("all_related_contacts", this.all_related_contacts);
      this.all_beneficial_owners_for_user = this.all_related_contacts.filter(
        (contact) => contact.type != "Regular"
      );
      for (const contact of this.all_related_contacts) {
        if (contact.email) {
          this.invalid_email_array.push(contact.email);
        }
        if (contact.ssn) {
          this.invalid_ssn_array.push(contact.ssn);
        }
      }
      await Promise.all([
        this.setSchemaForInvestmentFlow(),
        this.fetchOwnersInvestments(),
        this.fetchExistingJointContacts(),
        this.fetchExistingEntityAccounts(),
      ]);

      this.invalid_ssn_array = this.invalid_ssn_array.filter((ssn) => ssn);
      this.invalid_email_array = this.invalid_email_array.filter((email) => email);
      this.invalid_ein_array = this.invalid_ein_array.filter((ein) => ein);
    },
    async setCurrentOwnerContactFromInvestment() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      let fetched_record = await crudStore.findOne("Contacts", {
        _id: this.current_investment.contact_id,
      });
      fetched_record = UIStore.formatFetchedRecordFromCF(
        fetched_record,
        schemaStore.all_contact_schema
      );
      this.current_owner_contact = _.cloneDeep(fetched_record);
      this.fetchOfferings();

      this.current_offering = this.offerings.find(
        (offering) => offering._id === this.current_investment.offering_id
      );

      await this.prepTransferInvestmentFlow();
      if (this.current_investment.type === "Entity") {
        this.entity_for_investment = this.existing_entity_accounts.find(
          (entity) => entity._id === this.current_investment.entity_account_id
        );
        //then also set a flag on the entity account in the all entity accounts array to hodden = true
        this.existing_entity_accounts = this.existing_entity_accounts.map((entity) => {
          if (entity._id === this.current_investment.entity_account_id) {
            entity.hidden = true;
          }
          return entity;
        });
      }
      if (this.current_investment.type === "Joint") {
        this.joint_contact_for_investment = this.existing_joint_contacts.find(
          (contact) => contact._id === this.current_investment.joint_contact_id
        );

        //then also set a flag on the joint contact in the all joint contacts array to hodden = true
        this.existing_joint_contacts = this.existing_joint_contacts.map((contact) => {
          if (contact._id === this.current_investment.joint_contact_id) {
            contact.hidden = true;
          }
          return contact;
        });
      }
    },
    fetchOfferings() {
      const schemaStore = useSchemaStore();

      // Fetch offerings visible to admins
      // this.offerings = await crudStore.find("Offerings", { is_visible_to_admins: true });

      let cloned_offerings = _.cloneDeep(schemaStore.all_offerings);
      this.offerings = cloned_offerings.filter((offering) => offering.is_visible_to_admins);

      for (const offering of this.offerings) {
        this.offering_name_array.push(`${offering.name} - ${offering.issuer} - ${offering.type}`);
      }
    },
    async setSchemaForInvestmentFlow() {
      // Initialize the CRUD store
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();

      const listsToGrab = ["rollover_table_fields"];

      // Fetch the configuration lists based on the references
      const configurationLists = await crudStore.find("Settings", {
        reference: { $in: listsToGrab },
      });

      this.rollover_table_fields = configurationLists.find(
        (config) => config.reference === "rollover_table_fields"
      ).options;

      this.funding_account_template = schemaStore.createBankTemplate("Funding");
      this.funding_account_template.user_id = this.current_owner_contact.user_id;
      this.funding_account_template.contact_id = this.current_owner_contact._id;
      this.funding_account_template.account_id = this.current_owner_contact.account_id;
      this.distribution_account_template = schemaStore.createBankTemplate("Distribution");
      this.distribution_account_template.user_id = this.current_owner_contact.user_id;
      this.distribution_account_template.contact_id = this.current_owner_contact._id;
      this.distribution_account_template.account_id = this.current_owner_contact.account_id;
    },
    async fetchExistingAccreditationDocs() {
      const crudStore = useCrudStore();
      let aggregation = [
        { $match: { user_id: this.current_owner_contact.user_id, type: "Accreditation" } },
        { $sort: { created_date: -1 } },
      ];
      this.accreditation_documents = await crudStore.aggregate("Documents", aggregation);
    },
    async fetchOwnersInvestments() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();
      this.owners_investments = await crudStore.find("Investments", {
        user_id: this.current_owner_contact.user_id,
      });

      if (this.owners_investments.length > 0) {
        this.owner_has_investments = true;
      }

      this.rollover_table_investments = _.cloneDeep(this.owners_investments).filter(
        (investment) => investment.able_to_rollover
      );
      this.rollover_table_investments = this.rollover_table_investments.map((investment) => {
        const formattedInvestment = UIStore.formatFetchedRecordFromCF(
          investment,
          schemaStore.all_investment_schema
        );
        return formattedInvestment;
      });
      this.rollover_table_investments = UIStore.transformRecords(this.rollover_table_investments);

      this.rollover_table_headers = schemaStore.all_investment_schema
        .filter((field) => this.rollover_table_fields.includes(field.field_name))
        .map((header) => ({
          label: header.label,
          field_name: header.field_name,
          field_type: header.field_type,
          number_type: header.number_type,
        }));
      this.rollover_table_headers.unshift({ label: "Select", field_name: "checkbox" });
    },
    async fetchExistingJointContacts() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore(); // Assuming you have access to UIStore
      const schemaStore = useSchemaStore();

      this.existing_joint_contacts = await crudStore.find("Contacts", {
        type: "Joint",
        user_id: this.current_owner_contact.user_id,
      });

      // Format each record using formatFetchedRecordFromCF and push SSN to invalid_ssn_array
      this.existing_joint_contacts = this.existing_joint_contacts.map((contact) => {
        const formattedContact = UIStore.formatFetchedRecordFromCF(
          contact,
          schemaStore.all_contact_schema
        );

        // Assuming the SSN is stored in a field called `ssn` in the contact object
        this.invalid_ssn_array.push(formattedContact.ssn);
        this.invalid_email_array.push(formattedContact.email);

        return formattedContact;
      });

      if (this.existing_joint_contacts.length == 0) {
        this.add_joint_contact = true;
      } else {
        // Flag the joint contacts that have investments
        this.existing_joint_contacts = this.existing_joint_contacts.map((contact) => {
          const hasExistingInvestments = this.owners_investments.some(
            (investment) => investment.joint_contact_id === contact._id
          );
          return {
            ...contact,
            has_existing_investments: hasExistingInvestments,
          };
        });
      }
    },
    async fetchExistingEntityAccounts() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      // Fetch existing entity accounts
      this.existing_entity_accounts = await crudStore.find("Accounts", {
        type: "Entity",
        user_id: this.current_owner_contact.user_id,
      });

      if (this.existing_entity_accounts.length === 0) {
        this.add_entity_account = true;
      } else {
        this.existing_entity_accounts = this.existing_entity_accounts.map((account) =>
          UIStore.formatFetchedRecordFromCF(account, schemaStore.all_account_schema)
        );

        for (const account of this.existing_entity_accounts) {
          if (account.ein) {
            this.invalid_ein_array.push(account.ein);
          }
        }

        // Fetch contacts with an entity_ids array that includes this entity's ID
        const allEntityIds = this.existing_entity_accounts.map((entity) => entity._id);
        const contactsWithEntities = await crudStore.find("Contacts", {
          entity_ids: { $in: allEntityIds },
        });

        const formattedContacts = contactsWithEntities.map((contact) =>
          UIStore.formatFetchedRecordFromCF(contact, schemaStore.all_contact_schema)
        );

        // Attach beneficial owners to each entity account based on the entity_id references in contacts
        this.existing_entity_accounts = this.existing_entity_accounts.map((entity) => {
          let owner_is_beneficial_owner = formattedContacts.some(
            (contact) => contact.entity_ids.includes(entity._id) && contact.type === "Regular"
          );
          const associatedOwners = formattedContacts.filter(
            (contact) => contact.entity_ids.includes(entity._id) && contact.type !== "Regular"
          );

          return {
            ...entity,
            beneficial_owners: associatedOwners,
            owner_is_beneficial_owner: owner_is_beneficial_owner,
            has_existing_investments: associatedOwners.some((owner) =>
              this.owners_investments.some(
                (investment) => investment.entity_account_id === entity._id
              )
            ),
          };
        });
        console.log("existing_entity_accounts", this.existing_entity_accounts);
      }
    },
    calculateNextAndPrev10th() {
      let currentDate = new Date();
      // Zero out hours, minutes, seconds, and milliseconds for the current date
      currentDate.setUTCHours(0, 0, 0, 0); // Set time to midnight in UTC

      let next_10th, prev_10th;

      // Get the year, month, and day from the current date
      let year = currentDate.getUTCFullYear(); // Use UTC methods
      let month = currentDate.getUTCMonth(); // Use UTC methods
      let day = currentDate.getUTCDate(); // Use UTC methods

      // Calculate the next 10th
      if (day < 10) {
        next_10th = new Date(Date.UTC(year, month, 10)); // Create date in UTC
      } else {
        next_10th = new Date(Date.UTC(year, month + 1, 10)); // Create date in UTC
      }

      // Calculate the previous 10th
      if (day >= 10) {
        prev_10th = new Date(Date.UTC(year, month, 10)); // Create date in UTC
      } else {
        prev_10th = new Date(Date.UTC(year, month - 1, 10)); // Create date in UTC
      }

      return { next_10th, prev_10th };
    },
    calculateSplitDiscountedBondAmount(parent_amount, child_amount, discount_amount) {
      let calculated_discount = 0;
      // console.log("parent_amount", parent_amount);
      // console.log("child_amount", child_amount);
      // console.log("discount_amount", discount_amount);

      //calculate what recent the child amount is of the parent amount
      let child_percent = child_amount / parent_amount;

      //  console.log("child_percent_of_parent", child_percent);

      //calculate the discount amount
      calculated_discount = child_percent * discount_amount;

      // console.log a useful sentence to show that the child amount is whatever percentage of the parent amount and therefor the discount amount is whatever percentage of the discount amount
      console.log(
        `The child amount (${child_amount}) is ${child_percent} of the parent amount (${parent_amount}) and therefor the discount amount is ${calculated_discount} which is ${child_percent} of the total discount amount (${discount_amount})`
      );

      return calculated_discount;
    },

    clearState() {
      this.investment_type = "";
      this.selected_offering = {};
      this.selected_offering_name = "";
      this.current_offering = {};
      this.investment_amount = "0";
      this.first_og_title_transfer_step_is_invalid = true;
      this.last_og_title_transfer_step_is_invalid = true;
      this.payment_frequency = "";
      this.send_settled_email = false;
      this.notify_user_of_created_investment = false;
      this.selected_transfer_option = {};
      this.payment_frequency_options = [
        {
          name: "Compounded Monthly",
          description: "Interest and principle are paid at maturity",
          disabled: false,
        },
        {
          name: "Interest Paid Monthly",
          description: "Interest paid monthly and principle paid at maturity",
          disabled: false,
        },
      ];
      this.title_transfer_flow_options = [
        {
          name: "Transfer to New Owner",
          description: "Transfers the investment to a new investor within the system.",
          id: "transfer_to_new_owner",
        },
        {
          name: "Change Investment Type",
          description:
            "Modifies the type of investment. This mirrors the title transfer process from the original system.",
          id: "legacy_title_transfer",
        },
        {
          name: "Split Investment",
          description: "Divides the existing investment into multiple, separate investments.",
          id: "split_investment",
        },
      ];
      this.investment_is_discounted = false;
      this.owner_entered_email = "";
      this.transferor_signature_date = null;
      this.transferee_signature_date = null;
      this.owner_entered_ssn = "";
      this.transferee_entered_email = "";
      this.transferee_entered_ssn = "";
      this.investment_discount_amount = "0";
      this.banking_info_is_invalid = true;
      this.investment_funding_method = "";
      this.investmentSteps = ["Type Selection"];
      this.currentStep = 0;
      this.offerings = [];
      this.offering_name_array = [];
      this.transfer_drawer_open = false;
      this.investment_signature_date = null;
      this.uploaded_subagreeement = null;
      this.uploaded_owner_transfer_form = null;
      this.investment_being_created = false;
      this.show_investment_completion_screen = false;
      this.selected_funding_method = {};
      this.selected_distribution_method = {};
      this.selected_rollover_investment = {};
      this.selected_transferee_investor = {
        assignedObject: {
          _id: null,
          name: "",
        },
        results: [],
        schema: "field",
        searchTerm: "",
      };
      this.current_owner_contact = {};
      this.transfer_to_investment_type = "";
      this.owner_transferor_is_invalid = true;
      this.transferee_is_invalid = true;
      this.current_owner_contact_full_name = "";
      this.owner_email_is_invalid = false;
      this.joint_email_is_invalid = false;
      this.transferor_custodian_signature_date = null;
      this.transferor_joint_signature_date = null;
      this.transferor_entity_signature_date = null;
      this.transferee_custodian_signature_date = null;
      this.transferee_joint_signature_date = null;
      this.transferee_entity_signature_date = null;
      this.owner_ssn_is_invalid = false;
      this.entity_ein_is_invalid = false;
      this.owner_has_investments = false;
      this.add_joint_contact = false;
      this.add_entity_account = false;
      this.joint_contact_template = {};
      this.entity_template = {};
      this.investment_split_is_invalid = true;
      this.existing_joint_contacts = [];
      this.uploaded_title_transfer_subagreeement = null;
      this.existing_entity_accounts = [];
      this.owners_investments = [];
      this.selected_joint_contact = {};
      this.selected_entity_account = {};
      this.all_beneficial_owners_for_user = [];
      this.joint_ssn_is_invalid = false;
      this.current_beneficial_owner_for_modal = {};
      this.reason_for_transfer = "";
      this.other_reason_for_transfer = "";
      this.bank_account_modal_open = false;
      this.split_investments = [
        {
          bond_amount: "0",
        },
        {
          bond_amount: "0",
        },
      ];
      this.current_investment = {};
      this.bank_account_type = "";
      this.funding_account_template = {};
      this.distribution_account_template = {};
      this.invalid_ssn_array = [];
      this.invalid_email_array = [];
      this.invalid_ein_array = [];
      this.suitability_income_info = [];
      this.admin_investment_types = [];
      this.rollover_table_fields = [];
      this.rollover_table_investments = [];
      this.rollover_table_headers = [];
      this.selected_transferee_full_document = {};
      this.custodian_name = "";
      this.custodian_account_number = "";
      this.is_valid_suitability_info = true;
      this.accreditation_documents = [];
      this.uploaded_split_subagreeement = null;
      this.all_related_contacts = [];
      let res = this.calculateNextAndPrev10th();
      console.log("calculate next 10th", res.next_10th);
      console.log("calculate next 10th", typeof res.next_10th);
      console.log("calculate prev 10th", res.prev_10th);
      console.log("calculate prev 10th", typeof res.prev_10th);
    },
  },
});
