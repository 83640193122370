<template>
  <div>
    <div class="pb-2 text-primary-6 font-semibold">Entity Details</div>
    <div class="flex flex-col gap-2 text-light text-sm">
      <div>Name: {{ titleTransferStore.entity_for_investment.street1 }}</div>

      <div class="flex w-full items-center">
        <div class="flex h-full items-center">
          EIN: {{ ein_is_visible ? titleTransferStore.entity_for_investment.ein : "***********" }}
          <div class="ml-2 mb-1 flex items-center">
            <IconVisibility
              class="fill-light"
              id="base-input"
              v-if="!ein_is_visible"
              @click="ein_is_visible = true"
            />
            <IconVisibilityOff
              class="fill-light"
              id="base-input"
              v-else
              @click="ein_is_visible = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { IconVisibility, IconVisibilityOff } from "@/components/icons";
import { useTitleTransferStore } from "@/stores";
const titleTransferStore = useTitleTransferStore();
const ein_is_visible = ref(false);
</script>
