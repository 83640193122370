<template>
  <div class="md:h-full w-full p-6">
    <div
      class="flex w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-10"
      >
        <Icon name="ChevronLeft" class="h-5 w-5 text-primary-6 mb-1" :stroke-width="2" />
        Back
      </button>
      <div
        class="w-full md:max-w-[23.813rem] md:p-0 mt-4 lg:mt-0 h-full flex flex-col md:justify-center"
      >
        <div>
          <div>
            <h3 class="font-bold text-2xl mb-6">Verify It's You</h3>
            <p class="text-lighter text-sm">
              A 6 digit verification code has been sent to an email
              <span v-if="authStore.signInFields.email">
                {{
                  `${authStore.signInFields.email[0]}${"*".repeat(authStore.signInFields.email.split("@")[0].length - 1)}${authStore.signInFields.email.split("@")[0][authStore.signInFields.email.split("@")[0].length - 1]}@${authStore.signInFields.email.split("@")[1]}`
                }}
              </span>
            </p>

            <div class="flex flex-col my-4">
              <label for="verification-code" class="text-sm text-lighter font-medium mb-1">
                Verification Code
              </label>
              <div class="flex gap-2" id="verification-code">
                <input
                  v-for="(digit, index) in authStore.verification_code"
                  :key="index"
                  type="text"
                  inputmode="numeric"
                  maxlength="1"
                  placeholder="0"
                  class="w-[2.65rem] h-11 text-center text-xl border border-neutral-gray-4 rounded focus:outline-none focus:ring-2 focus:ring-primary-6 placeholder:text-neutral-gray-5"
                  v-model="authStore.verification_code[index]"
                  @input="onInput(index)"
                  @keydown.backspace="onBackspace(index)"
                  @paste="onPaste($event)"
                  ref="inputs"
                />
              </div>
            </div>
            <p v-if="timeUntilNextCodeRequest > 0" class="mb-8">
              Request a new code in ({{ timeUntilNextCodeRequest }}s)
            </p>
            <Banner type="error" v-if="sendCodeError" class="my-8" @close="sendCodeError = ''">
              <template #content>
                <p class="max-w-[80%]">{{ sendCodeError }}</p>
              </template>
            </Banner>
            <Button
              v-show="timeUntilNextCodeRequest === 0"
              @click="handleRequestVerificationCode"
              label
              size="large"
              class="w-full mb-2 mt-10"
              variant="default-color-outlined"
            >
              <template v-if="isRequestCodeLoading" #label>
                <IconPulseSpinner class="h-6 w-6 stroke-white" />
              </template>
              <template v-else #label>Request Another Code</template>
            </Button>
            <Button
              @click="handleVerifyCode"
              :disabled="!isFormValid"
              label
              size="large"
              class="w-full"
            >
              <template v-if="isVerifyCodeLoading" #label>
                <IconPulseSpinner class="h-6 w-6 stroke-white" />
              </template>
              <template v-else #label>Verify Code</template>
            </Button>
          </div>
        </div>
        <!-- Turnstyle widget -->
        <div class="my-4" data-theme="light" id="turnstile"></div>
        <div class="text-lighter text-base absolute bottom-6 md:bottom-12">
          <p>
            Need help?
            <a
              :href="MFAGuide"
              class="font-semibold"
              download="MFA_Guide"
              rel="noreferrer noopener"
            >
              Multi Factor Authentication Guide
            </a>
          </p>
          <p class="mt-1">
            Lost your MFA Method?
            <a
              href="https://phxcapitalgroup.com/contact-us-about-it-support/"
              rel="noreferrer noopener"
              target="_blank"
              class="font-semibold"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore, useUIStore } from "@/stores";
import { IconChevronLeft, IconPulseSpinner } from "@/components/icons";
import { Button, Icon, Banner } from "@/components";
import MFAGuide from "@/assets/documents/MFA_guide.pdf";

const router = useRouter();
const authStore = useAuthStore();
const UIStore = useUIStore();
const timeUntilNextCodeRequest = ref(30);

const inputs = ref([]);
const sendCodeError = ref("");
const isVerifyCodeLoading = ref(false);
const isRequestCodeLoading = ref(false);

let interval = null;
onMounted(async () => {
  // Ensure the refs are correctly set for the inputs array
  if (!authStore.signInFields.email) {
    router.push("/sign-in");
  }

  inputs.value = inputs.value.slice(0, 6);

  authStore.verification_code = ["", "", "", "", "", ""];
  timeUntilNextCodeRequest.value = 30;
  interval = setInterval(() => {
    if (timeUntilNextCodeRequest.value > 0) {
      timeUntilNextCodeRequest.value--;
    } else {
      clearInterval(interval);
    }
  }, 1000);

  turnstile.ready(function () {
    turnstile.render("#turnstile", {
      sitekey:
        import.meta.env.VITE_ENV === "Production"
          ? "0x4AAAAAAAbuzA10_ip9GLl-"
          : "1x00000000000000000000AA",
      callback: function (token) {
        authStore.turnstileToken = token;
        authStore.isTurnstileValid = true;
      },
    });
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = false;
});

const isFormValid = computed(() => {
  return authStore.verification_code.join("").length === 6;
});

function onInput(index) {
  if (authStore.verification_code[index].length === 1 && index < 5) {
    inputs.value[index + 1].focus();
  }
}

function onBackspace(index) {
  if (index > 0 && authStore.verification_code[index] === "") {
    inputs.value[index - 1].focus();
  }
}

function onPaste(event) {
  event.preventDefault();
  const paste = (event.clipboardData || window.clipboardData).getData("text");
  if (paste.length === 6 && /^\d+$/.test(paste)) {
    // Ensure it's a 6-digit numeric string
    for (let i = 0; i < 6; i++) {
      authStore.verification_code[i] = paste[i];
    }
    inputs.value[5].focus(); // Focus the last input after pasting
  }
}

async function handleVerifyCode() {
  try {
    isVerifyCodeLoading.value = true;
    if (!authStore.isTurnstileValid) {
      sendCodeError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    const code = authStore.verification_code.join("");
    const verifyResult = await authStore.verifyMFACodeAndSignin(code);
    if (verifyResult.session_id) {
      authStore.is_verified = true;
      authStore.isAuthenticatedNavigation = true;
      await authStore.setTabsForLoggedInUser();
      router.push("/dashboard");
    } else if (
      verifyResult.error &&
      verifyResult.error === "Unexpected Error: Max check attempts reached"
    ) {
      sendCodeError.value =
        "You have made too many incorrect attempts. Please wait a few minutes before trying again.";
    } else {
      sendCodeError.value = "Please check the code you entered is correct, or request a new one.";
    }
  } catch (err) {
    sendCodeError.value = "Failed to verify code. Please try again.";
  } finally {
    isVerifyCodeLoading.value = false;
  }
}

function handleBack() {
  router.push("/sign-in");
}

async function handleRequestVerificationCode(channel) {
  try {
    isRequestCodeLoading.value = true;
    if (!authStore.isTurnstileValid) {
      sendCodeError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    // TODO: Actually send the verification code
    const sendCodeResult = await authStore.sendVerificationCode();
    if (sendCodeResult.status !== "pending") {
      sendCodeError.value = "Failed to send verification code. Please try again.";
      return;
    }
    authStore.mfa_channel = channel;
  } catch (err) {
    sendCodeError.value = "Failed to send verification code. Please try again.";
  } finally {
    isRequestCodeLoading.value = false;
  }
}
</script>
