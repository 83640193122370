<template>
  <div>
    <p class="py-2 text-xl font-bold">Select Type of Conversion</p>

    <div class="mt-4 flex flex-col gap-6">
      <label
        v-for="(option, index) in conversionOptions"
        :key="option.id"
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="
          investmentStore.selectedConversionType?.id === option.id
            ? 'border-gold'
            : 'border-platform-outlines'
        "
        @click="!isOptionDisabled(option) && (investmentStore.selectedConversionType = option)"
      >
        <RadioOption
          :id="index"
          name="conversionOption"
          :value="option"
          v-model="investmentStore.selectedConversionType"
          :label="`${option.name}`"
          :description="option.description"
          :disabled="isOptionDisabled(option)"
        />
      </label>
    </div>
    <div class="text-lg font-bold mt-6">Selected Investments:</div>
    <p
      v-if="investmentStore.selectedInvestmentsForConversion.some((i) => i.status !== 'Settled')"
      class="text-functional-error-default text-sm"
    >
      Only settled investments can be converted, please remove any non-settled investments.
    </p>
    <p
      v-if="
        investmentStore.selectedInvestmentsForConversion.reduce((acc, curr) => {
          if (!acc.includes(curr.type)) {
            acc.push(curr.type);
          }
          return acc;
        }, [])?.length > 1
      "
      class="text-functional-error-default text-sm"
    >
      Only 1 type of investment can be selected for a many to 1 conversion. Please title transfer
      first if you need to convert multiple types of investments.
    </p>
    <Table
      :headers="investmentTableHeaders"
      :tableData="UIStore.transformRecords(investmentStore.selectedInvestmentsForConversion)"
      :fieldsWithCustomStyles="['status']"
      actions_always_sticky
      class="max-w-full"
    >
      <template v-slot:table-ctas="entry">
        <div class="flex items-center space-x-2">
          <Button
            @click="handleRemoveInvestment(entry.entry._id.value)"
            icon
            size="small"
            variant="subtle"
          >
            <template #icon>
              <Icon name="Trash" class="h-4 w-4 text-functional-error-default" :strokeWidth="2.5" />
            </template>
          </Button>
        </div>
      </template>
      <template v-slot:status="entry">
        <div class="ml-4 truncate">
          <div
            class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
            :class="
              entry.row.status.value == 'Created'
                ? 'bg-custom-light-blue text-custom-light-blue'
                : entry.row.status.value == 'Settled'
                  ? 'bg-custom-lime-green text-custom-lime-green'
                  : entry.row.status.value === 'Canceled'
                    ? 'bg-functional-error-default text-functional-error-hover'
                    : 'bg-functional-warning-default text-functional-warning-hover'
            "
          >
            {{ entry.row.status.value }}
          </div>
        </div>
      </template>
    </Table>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { useInvestmentStore, useUIStore } from "@/stores";
import { RadioOption, Table, Button, Icon } from "@/components";

const investmentStore = useInvestmentStore();
const UIStore = useUIStore();

const conversionOptions = ref([
  {
    id: 1,
    name: "1 to 1 Conversion",
    description: "Convert 1 investment to 1 new investment",
  },
  {
    id: 2,
    name: "1 to Many Conversion",
    description: "Convert 1 investment to multiple new investments",
  },
  {
    id: 3,
    name: "Many to 1 Conversion",
    description: "Convert multiple investments to 1 new investment",
  },
]);

const investmentTableHeaders = ref([
  { field_name: "signature_date", label: "Signature Date", field_type: "date" },
  { field_name: "type", label: "Type" },
  { field_name: "status", label: "Status" },
  { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
  {
    field_name: "interest_rate",
    label: "Rate",
    field_type: "number",
    number_type: "percentage",
  },
  { field_name: "offering_type", label: "Offering" },
  { field_name: "offering_name", label: "Offering Name" },
  { field_name: "bond_term", label: "Term", field_type: "number" },
  { field_name: "maturity_date", label: "Maturity Date", field_type: "date" },

  { field_name: "actions", label: "Actions" },
]);

onMounted(() => {
  investmentStore.selectedInvestmentsForConversion = [];
  for (const investmentId of investmentStore.investment_ids_to_process) {
    const investment = investmentStore.transactionsData.find(
      (investment) => investment._id?.toString() === investmentId
    );
    investmentStore.selectedInvestmentsForConversion.push(investment);
  }
});

function isOptionDisabled(option) {
  const numSelectedInvestments = investmentStore.investment_ids_to_process.length;
  if (numSelectedInvestments === 0) {
    return true;
  }

  if ((option.id === 1 || option.id === 2) && numSelectedInvestments !== 1) {
    return true;
  }

  if (option.id === 3 && numSelectedInvestments < 2) {
    return true;
  }
}

function handleRemoveInvestment(investmentId) {
  const index = investmentStore.selectedInvestmentsForConversion.findIndex(
    (investment) => investment._id === investmentId
  );
  investmentStore.selectedInvestmentsForConversion.splice(index, 1);
  investmentStore.investment_ids_to_process = investmentStore.selectedInvestmentsForConversion.map(
    (investment) => investment._id
  );
}
</script>
