<template>
  <div v-if="recordDetailStore.fetched_distributions_for_investment_earnings_tab.length > 0">
    <div class="w-full border-platform-outlines border-b flex py-4 font-medium mb-4 items-center">
      <div class="grow flex gap-x-12">
        <div>
          <div class="text-xs text-lighter uppercase font-medium tracking-wideish">
            Total Earned
          </div>
          <div class="text-xl text-dark font-bold">
            ${{
              commaSeparateThousands(
                recordDetailStore.currentPageDocument.inception_to_date_interest_paid
              )
            }}
          </div>
        </div>
        <div v-if="recordDetailStore.fetched_transfer_parents.length > 0">
          <div class="text-xs text-lighter uppercase font-medium tracking-wideish">Parent ITD</div>
          <div class="text-xl text-dark font-bold">
            ${{
              commaSeparateThousands(
                recordDetailStore.fetched_transfer_parents[0].inception_to_date_interest_paid.value
                  ? recordDetailStore.fetched_transfer_parents[0].inception_to_date_interest_paid
                      .value
                  : 0
              )
            }}
          </div>
        </div>
        <div v-if="recordDetailStore.fetched_transfer_parents.length > 0">
          <div class="text-xs text-lighter uppercase font-medium tracking-wideish">Parent YTD</div>
          <div class="text-xl text-dark font-bold">
            ${{
              commaSeparateThousands(
                recordDetailStore.fetched_transfer_parents[0].year_to_date_interest_paid.value
                  ? recordDetailStore.fetched_transfer_parents[0].year_to_date_interest_paid.value
                  : 0
              )
            }}
          </div>
        </div>
      </div>
      <div class="">
        <Button
          variant="default-gray-outlined"
          size="large"
          label
          icon
          :is-icon-only-on-mobile="true"
          @click="
            recordDetailStore.downloadDistributionsForSingleInvestment(
              `inv_${recordDetailStore.currentPageDocument._id}_distributions_${new Date().toLocaleDateString()}`
            )
          "
        >
          <template #icon>
            <Icon name="Download" class="w-4 h-4" />
          </template>
          <template #label> Download </template>
        </Button>
      </div>
    </div>

    <Table
      :table-data="recordDetailStore.fetched_distributions_for_investment_earnings_tab"
      :headers="recordDetailStore.investment_earnings_tab_headers"
      :fieldsWithCustomStyles="['is_completed', 'OID', 'index']"
    >
      <template v-slot:is_completed="entry">
        <div
          class="rounded-xl py-1 px-4 min-w-16 w-fit flex items-center justify-center"
          :class="
            entry.row.is_completed.value
              ? 'bg-opacity-15 bg-royal-blue text-royal-blue'
              : 'bg-opacity-20 bg-secondary-6 text-secondary-8'
          "
        >
          {{ entry.row.is_completed.value ? "Processed" : "Pending" }}
        </div>
      </template>
      <template v-slot:OID="entry">
        <div v-if="entry.row.OID.value" class="rounded-xl py-1 pl-4 w-fit flex justify-center">
          ${{ entry.row.OID.value.toFixed(2) }}
        </div>
      </template>
      <template v-slot:index="entry">
        <div class="rounded-xl py-1 pl-4 w-fit flex justify-center">
          {{ entry.row.index.value }}
        </div>
      </template>
    </Table>
  </div>
  <div v-else>
    <EmptyState
      description="There are currently no distributions for this investment."
      icon_name="HandCoins"
    />
  </div>
</template>
<script setup>
import { Button, Table } from "@/components";
import { commaSeparateThousands, monthsToYears } from "@/utilities";
import { useRecordDetailStore } from "@/stores";

import { Icon } from "@/components";
import EmptyState from "@/components/ui/EmptyState.vue";
import CompleteTitleTransferInvestment from "../TitleTransfers/CompleteTitleTransferInvestment.vue";
const recordDetailStore = useRecordDetailStore();
</script>
