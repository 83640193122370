<template>
  <Transition :name="`fade-in-${animationSlideDirection}`">
    <div
      v-if="show"
      id="filter-modal"
      class="fixed z-30 ml-3 mt-3 flex w-value-33 flex-col justify-between overflow-hidden rounded-md border border-primary-6 bg-neutral-gray-1 shadow-lg"
      :class="customClasses ? customClasses : ''"
      ref="filterModal"
    >
      <ScrollList :tabs="tabs" component="header" :activeTab="activeTab">
        <template v-slot:filter-modal-header>
          <slot name="filter-modal-header" />
        </template>
      </ScrollList>
      <div class="w-full flex-grow rounded-b-md bg-neutral-gray-2">
        <slot name="filter-modal-body" />
      </div>
      <div class="absolute bottom-0 z-20 w-full rounded-b-md bg-neutral-gray-1">
        <slot name="filter-modal-footer" />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, onUnmounted } from "vue";

import { ScrollList } from "@/components";
import { useUIStore } from "@/stores/useUIStore";

const filterModal = ref(null);
const UIStore = useUIStore();

onUnmounted(() => {
  UIStore.isEditFilterModalCollapsed = false;
});

const props = defineProps({
  customClasses: {
    type: String,
    default: "",
  },
  tabs: Array,
  activeTab: String,
  show: Boolean,
  animationSlideDirection: {
    type: String,
    default: "left",
  },
});
</script>

<style scoped>
/* Right Slide */
.fade-in-right-enter-active,
.fade-in-right-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
  transition-timing-function: ease;
}
.fade-in-right-enter-from,
.fade-in-right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.fade-in-right-enter-to,
.fade-in-right-leave-from {
  opacity: 1;
  transform: translateX(0);
}

/* Left Slide */
.fade-in-left-enter-active,
.fade-in-left-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
  transition-timing-function: ease;
}
.fade-in-left-enter-from,
.fade-in-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-in-left-enter-to,
.fade-in-left-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
