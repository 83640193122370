<template>
  <Modal size="26x32" :hideModalTabs="true" @close-modal="$emit('close-modal')">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2">
          Update {{ investmentStore.investment_ids_to_process.length }} Investment<span
            v-if="
              investmentStore.investment_ids_to_process.length > 1 ||
              investmentStore.investment_ids_to_process.length === 0
            "
            >s</span
          >
        </div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white flex flex-col gap-4 p-8">
        <label for="sendEmail" class="flex w-min items-center">
          <input type="checkbox" v-model="sendEmail" id="sendEmail" class="accent-secondary-6" />
          <span class="ml-2 pt-1 text-sm text-primary whitespace-nowrap">Send Email?</span>
        </label>
        <BaseInput label="Signature Date" v-model="signatureDate" type="date" size="large" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button
          size="large"
          :disabled="isSaveDisabled"
          label
          @click="handleUpdateInvestmentSignatures"
        >
          <template #label>
            Update {{ investmentStore.investment_ids_to_process.length }} Investment<span
              v-if="investmentStore.investment_ids_to_process.length > 1"
              >s</span
            >
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed } from "vue";

import { Modal, Button, BaseInput } from "@/components";
import { useRecordDetailStore, useInvestmentStore, useUIStore } from "@/stores";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const investmentStore = useInvestmentStore();
const UIStore = useUIStore();

const emit = defineEmits(["close-modal"]);

const signatureDate = ref(null);
const sendEmail = ref(false);

const isSaveDisabled = computed(() => {
  return !signatureDate.value || !investmentStore.investment_ids_to_process.length;
});

async function handleUpdateInvestmentSignatures() {
  emit("close-modal");
  // to get the frontend to immediately update the status of the investments
  recordDetailStore.allRecords = recordDetailStore.allRecords.map((record) => {
    if (investmentStore.investment_ids_to_process.includes(record._id.value.toString())) {
      record.signature_date.value = signatureDate.value;
    }
    return record;
  });

  await investmentStore.updateInvestmentSignatureDateInBulk(signatureDate.value, sendEmail.value);
  UIStore.animateNotificationAlert({
    icon: "BadgeCheck",
    message: `Successfully updated ${investmentStore.investment_ids_to_process.length} Investments!`,
  });
  investmentStore.investment_ids_to_process = [];
  signatureDate.value = null;
  sendEmail.value = false;
}
</script>
