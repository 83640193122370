<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 11.334 13.334 8m0 0L10 4.667M13.334 8h-8a2.667 2.667 0 0 0-2.667 2.667V12"
      stroke="#06253F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
