<template>
  <Modal :open="props.open" @close-modal="$emit('close-modal')" size="26x38" :hideModalTabs="true">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2">Edit Custodian Information</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white flex flex-col gap-4 p-4">
        <BaseInput
          :required="false"
          label="Investment Type"
          v-model="custodianEdits.type"
          :options="investmentTypes"
          type="select"
          size="large"
          showFullText
        ></BaseInput>
        <BaseInput
          :required="false"
          label="Custodian"
          v-model="custodianEdits.custodian_name"
          :options="custodians.map((o) => o.name)"
          type="select"
          size="large"
          showFullText
        ></BaseInput>
        <BaseInput
          :required="false"
          label="Custodian Account Number"
          v-model="custodianEdits.custodian_account_number"
          type="string"
          size="large"
          showFullText
        ></BaseInput>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template></Button
        >
        <Button size="large" :disabled="isSaveDisabled" label @click="updateCustodian">
          <template #label>Update</template></Button
        >
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconInvestment } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRecordDetailStore, useCrudStore } from "@/stores";
import { Modal, Button, BaseInput } from "@/components";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();

const investment_type_schema_query = {
  collection_name: "Investments",
  field_name: "type",
};

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

// define emits
const emit = defineEmits(["close-modal"]);

// declare props open
// const props = defineProps(["open"]);

onMounted(async () => {
  custodians.value = await crudStore.find("Custodians", {});
  let investmentTypeSchema = await crudStore.findOne("Schema", investment_type_schema_query);
  investmentTypes.value = investmentTypeSchema.record_detail_config.default.dropdown_options;
});

async function updateCustodian() {
  console.log("updateCustodian");
  let filter = {
    _id: recordDetailStore.currentPageDocument._id,
  };
  console.log(custodianEdits.value.custodian_name);
  console.log(custodians.value);
  let custodian = custodians.value.find((o) => o.name == custodianEdits.value.custodian_name);
  console.log(custodian);
  let update = {
    $set: {
      type: custodianEdits.value.type,
      custodian_id: custodian._id,
      custodian_account_number: custodianEdits.value.custodian_account_number,
    },
  };

  await crudStore.updateOne("Investments", filter, update);
  recordDetailStore.updateTic += 1;
  emit("close-modal");
}

const custodians = ref([]);
const investmentTypes = ref([]);
const custodianEdits = ref({});

const isSaveDisabled = computed(() => {
  return false;
});

// vue watcher to set custodianEdits when open prop changes
watch(props, (value) => {
  if (value.open) {
    let custodian = custodians.value.find(
      (o) => o._id == recordDetailStore.currentPageDocument.custodian_id
    );
    //console.log(custodian);
    custodianEdits.value = {
      type: recordDetailStore.currentPageDocument.type,
      custodian_name: custodian.name,
      custodian_account_number: recordDetailStore.currentPageDocument.custodian_account_number,
    };
  }
});
</script>
