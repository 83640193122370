<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Select Type of Transfer
    </div>
  </div>
  <div class="mb-4">
    <div class="mt-4 flex flex-col gap-6">
      <label
        v-for="(option, index) in titleTransferStore.title_transfer_flow_options"
        :key="option.id"
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="
          titleTransferStore.selected_transfer_option?.id === option.id
            ? 'border-gold'
            : 'border-platform-outlines'
        "
        @click="setNextStep(option)"
      >
        <RadioOption
          :id="index"
          name="activeTransferOption"
          :value="option"
          v-model="titleTransferStore.selected_transfer_option"
          :label="`${option.name}`"
          :description="option.description"
        />
      </label>
    </div>
  </div>
</template>

<script setup>
import { useTitleTransferStore } from "@/stores";
import { RadioOption } from "@/components";
import _ from "lodash";

const titleTransferStore = useTitleTransferStore();

function setNextStep(option) {
  if (option.id === "transfer_to_new_owner") {
    titleTransferStore.investmentSteps = [
      "Type Selection",
      "Transferor Owner",
      "Transferee Owner",
      "Complete Owner Transfer",
    ];
  } else if (option.id === "legacy_title_transfer") {
    titleTransferStore.investmentSteps = ["Type Selection", "First Transfer Step"];
  } else if (option.id === "split_investment") {
    titleTransferStore.investmentSteps = [
      "Type Selection",
      "Split Investment",
      "Complete Split Investment",
    ];
  }
}
</script>
