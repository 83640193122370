<template>
  <!-- <CreateInvestmentDrawer
    :open="createInvestmentStore.investment_drawer_open"
    @close-drawer="createInvestmentStore.investment_drawer_open = false"
  /> -->
  <TitleTransferDrawer
    :open="titleTransferStore.transfer_drawer_open"
    @close-drawer="titleTransferStore.transfer_drawer_open = false"
  />
  <EditBankDrawer
    :open="editBankDrawerOpen"
    @close-drawer="editBankDrawerOpen = false"
    @account-updated="updateBankAccounts"
    @account-replaced="(editBankDrawerOpen = false), recordDetailStore.updateTic++"
    :account_type="editBankType"
  />
  <EditCustodianInfoModal :open="custodianModalOpen" @close-modal="custodianModalOpen = false" />
  <EditInvestmentModal :open="investmentModalOpen" @close-modal="investmentModalOpen = false" />
  <SetInvestmentStatusModal
    :open="statusModalOpen"
    @close-modal="statusModalOpen = false"
    @fetch-investments="handleRefetchInvestments"
  />
  <SetInvestmentSignatureModal
    :open="signatureModalOpen"
    @close-modal="signatureModalOpen = false"
  />
  <PartialRedemptionModal :open="partialModalOpen" @close-modal="partialModalOpen = false" />
  <ConvertInvestmentsDrawer
    @close-drawer="investmentStore.isConversionDrawerOpen = false"
    :open="investmentStore.isConversionDrawerOpen"
  />
  <DocPreviewModal
    :open="docPreviewModalOpen"
    @close-modal="docPreviewModalOpen = false"
    :file-name="file_name"
    :fileContent="file_data"
    :fileType="file_type"
  />
  <!-- LANDING -->
  <div
    v-if="!route.query.recordId && !page_is_loading && !titleTransferStore.investment_being_created"
    class="px-0 sm:px-10 max-w-[100vw] py-5"
  >
    <AllRecordsTable
      :fields-with-custom-styles="['status', 'checkbox', 'actions']"
      :actions_always_sticky="true"
      @selectAll="handleSelectAll"
      :isAllChecked="isAllChecked"
      keep_actions_on_sort
    >
      <template v-slot:actions="data">
        <div class="px-4 h-[3.25rem] border-l bg-white flex items-center justify-center">
          <Button
            @click="
              handleOpenTab(
                `/investments?recordId=${data.row.row._id.value}`,
                data.row.row._id.value
              )
            "
            icon
            label
            isIconOnlyOnMobile
            size="small"
            variant="subtle"
          >
            <template #icon>
              <Icon name="SquareArrowOutUpRight" class="h-4 w-4 stroke-primary-6" />
            </template>
            <template #label> New Tab </template>
          </Button>
        </div>
      </template>
      <template v-slot:checkbox="entry">
        <div class="flex max-w-min items-center space-x-value-8 px-4">
          <input
            @click="select(entry)"
            :checked="
              investmentStore.investment_ids_to_process.includes(
                entry.row.row._id?.value?.toString()
              )
            "
            type="checkbox"
            class="accent-secondary-6"
          />
          <button
            @click="selectOnly(entry)"
            class="text-primary rounded bg-neutral-gray-4 px-4.5 py-0.5 text-xs"
          >
            Only
          </button>
        </div>
      </template>
      <template v-slot:header-ctas></template>
      <template v-slot:additional-ctas>
        <div class="flex flex-row items-center gap-3 ml-3">
          <div class="hidden lg:flex gap-2">
            <Button
              v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
              variant="default-gray-outlined"
              :is-icon-only-on-mobile="true"
              :icon="true"
              :label="true"
              @click="signatureModalOpen = true"
            >
              <template #icon> <IconSignature /> </template>
              <template #label> Signature </template>
            </Button>
            <Button
              variant="default-gray-outlined"
              :is-icon-only-on-mobile="true"
              :icon="true"
              :label="true"
              @click="downloadInvestments"
            >
              <template #icon> <Icon class="w-4 h-4" name="Download" /> </template>
              <template #label> Download </template>
            </Button>
          </div>
          <Button
            v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
            variant="default"
            :is-icon-only-on-mobile="true"
            :icon="true"
            :label="true"
            @click="statusModalOpen = true"
          >
            <template #icon>
              <Icon name="RefreshCcw" class="h-4 w-4 text-white" />
            </template>
            <template #label> Update Status </template>
          </Button>

          <div class="flex-row items-center gap-3 flex lg:hidden">
            <Button
              variant="default-gray-outlined"
              :is-icon-only-on-mobile="true"
              :icon="true"
              @click="dropdownOpen = !dropdownOpen"
            >
              <template #icon> <Icon class="h-4 w-4" name="ChevronDown" /> </template>
            </Button>
          </div>
          <div
            v-if="dropdownOpen"
            ref="dropdownRef"
            class="w-fit-content absolute right-0 top-10 sm:top-10 z-50 mt-1 flex h-auto flex-col rounded-md bg-neutral-gray-1 py-1 text-sm shadow-full lg:hidden"
            @click="dropdownOpen = false"
          >
            <div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="statusModalOpen = true"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <IconRefresh class="" />
                </div>
                <p class="mr-3">Update Status</p>
              </div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="signatureModalOpen = true"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <IconSignature class="" />
                </div>
                <p class="mr-3">Signature</p>
              </div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="downloadInvestments"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <Icon name="Download" class="" />
                </div>
                <p class="mr-3">Download</p>
              </div>
            </div>
          </div>
        </div></template
      >
      <template v-slot:status="data">
        <div class="ml-4 truncate">
          <div
            class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
            :class="
              data.row.row.status?.value == 'Created'
                ? 'bg-custom-light-blue text-custom-light-blue'
                : data.row.row.status?.value == 'Settled'
                  ? 'bg-custom-lime-green text-custom-lime-green'
                  : data.row.row.status?.value === 'Canceled'
                    ? 'bg-functional-error-default text-functional-error-hover'
                    : data.row.row.status?.value == 'Title Transferred'
                      ? 'bg-custom-teal text-custom-teal'
                      : data.row.row.status?.value == 'Funded'
                        ? 'bg-functional-warning-default text-functional-warning-hover'
                        : 'bg-neutral-gray-6 text-neutral-gray-6'
            "
          >
            {{ data.row.row.status?.value }}
          </div>
        </div>
      </template>
      <template v-slot:contact_id="data">
        <div class="ml-4 truncate text-gold flex items-center gap-2">
          <div
            class="p-1 cursor-pointer bg-opacity-20 rounded-md"
            @click="copyToClipboard(data.row.row, 'contact_id')"
            :class="
              data.row.row.contact_id?.isCopied
                ? 'bg-functional-success-default'
                : 'bg-neutral-gray-6'
            "
          >
            <IconCopyAll class="w-4 h-4" v-if="data.row.row.contact_id?.isCopied != true" />
            <IconCircleCheck class="w-4 h-4 fill-functional-success-default" v-else />
          </div>
          <router-link
            :to="`/contacts?recordId=${data.row.row.contact_id.value}`"
            class="text-gold truncate"
          >
            {{ data.row.row.contact_id.value }}
          </router-link>
        </div>
      </template>
      <template v-slot:_id="data">
        <div class="ml-4 truncate text-gold flex items-center gap-2">
          <div
            class="p-1 cursor-pointer bg-opacity-20 rounded-md"
            @click="copyToClipboard(data.row.row, '_id')"
            :class="
              data.row.row._id?.isCopied ? 'bg-functional-success-default' : 'bg-neutral-gray-6'
            "
          >
            <IconCopyAll class="w-4 h-4" v-if="data.row.row._id?.isCopied != true" />
            <IconCircleCheck class="w-4 h-4 fill-functional-success-default" v-else />
          </div>
          <router-link
            :to="`/investments?recordId=${data.row.row._id.value}`"
            class="text-gold truncate"
          >
            {{ data.row.row._id.value }}
          </router-link>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <!-- RECORD DETAIL -->
  <div
    v-else-if="
      route.query.recordId && !page_is_loading && !titleTransferStore.investment_being_created
    "
    class="mb-8"
  >
    <div class="flex flex-col px-3 sm:px-8 py-2 gap-2">
      <!-- Icon Above -->
      <div
        class="flex items-center justify-center self-start justify-self-auto pt-2 pr-2 cursor-pointer"
        @click="router.push(router.options?.history?.state?.back || '/investments')"
      >
        <Icon name="ChevronLeft" class="h-4 w-4 m-1.5" />
        <span class="text-lighter">
          Back to <span class="capitalize">{{ backTitle }}</span>
        </span>
      </div>
      <Header
        class="border-b border-platform-outlines pb-4"
        :header-nuggets="UIStore.headerNuggets"
      >
        <template #header-icon>
          <div
            class="p-1 rounded-lg bg-opacity-20"
            :class="recordDetailStore.currentPageConfig.icon_color_bg"
          >
            <component
              :is="iconMapping[recordDetailStore.currentPageConfig?.icon]"
              class="w-6 h-6"
              :class="recordDetailStore.currentPageConfig.icon_color_fill"
            />
          </div>
        </template>
        <template #header-title>
          <div
            class="flex flex-col sm:flex-row flex-wrap items-start sm:items-center uppercase font-bold text-2xl gap-1 sm:gap-3"
          >
            <span>INVESTMENT DETAILS</span>
            <div
              v-if="recordDetailStore.currentPageDocument.is_rollover"
              class="bg-opacity-20 bg-functional-warning-default text-functional-warning-hover rounded-2xl py-0.5 px-2 text-sm capitalize font-normal"
            >
              Rollover Investment
            </div>
            <div
              v-if="recordDetailStore.currentPageDocument.able_to_rollover"
              class="bg-opacity-20 bg-custom-lime-green text-custom-lime-green rounded-2xl py-0.5 px-2 text-sm capitalize font-normal"
            >
              Rollover Available
            </div>
            <div
              v-if="
                recordDetailStore.fetched_transfer_parents.length > 0 ||
                recordDetailStore.fetched_transfer_children.length > 0
              "
              class="bg-opacity-20 bg-custom-navy text-custom-navy rounded-2xl py-0.5 px-2 text-sm capitalize font-normal"
            >
              Title Transfer
            </div>
            <div
              v-if="recordDetailStore.currentPageDocument.partial_redemptions?.length"
              class="bg-opacity-20 bg-custom-navy text-custom-navy rounded-2xl py-0.5 px-2 text-sm capitalize font-normal"
            >
              Partial Redemption
            </div>
          </div>
        </template>

        <template #header-ctas>
          <div class="flex-row items-center gap-3 hidden lg:flex">
            <Button
              v-if="
                recordDetailStore.currentPageDocument.status == 'Settled' &&
                !recordDetailStore.transfer_is_disabled &&
                !authStore.isDalmoreAdmin &&
                authStore.hasInvestmentProcessingPermissions
              "
              variant="default-gray-outlined"
              isIconOnlyOnMobile
              icon
              label
              @click="openTTDrawer"
            >
              <template #icon> <IconForward /> </template>
              <template #label> Transfer Title </template>
            </Button>
            <Button
              v-if="
                recordDetailStore.currentPageDocument.status == 'Settled' &&
                !authStore.isDalmoreAdmin &&
                authStore.hasInvestmentProcessingPermissions
              "
              variant="default-gray-outlined"
              :is-icon-only-on-mobile="true"
              :icon="true"
              :label="true"
              @click="partialModalOpen = true"
            >
              <template #icon> <IconPercent /> </template>
              <template #label> Partially Redeem </template>
            </Button>
            <Button
              v-if="
                recordDetailStore.currentPageDocument.status == 'Settled' &&
                !authStore.isDalmoreAdmin &&
                authStore.hasInvestmentProcessingPermissions
              "
              variant="default-gray-outlined"
              icon
              label
              isIconOnlyOnMobile
              @click="handleConvertInvestment"
            >
              <template #label>Convert Investment</template>
              <template #icon>
                <Icon name="MessageSquareReply" :stroke-width="2" class="h-4 w-4" />
              </template>
            </Button>
            <Button
              variant="default-gray-outlined"
              :is-icon-only-on-mobile="true"
              :icon="true"
              :label="true"
              @click="investmentModalOpen = true"
              v-if="
                ['Settled', 'Created', 'Pending'].includes(
                  recordDetailStore.currentPageDocument.status
                ) &&
                !recordDetailStore.investment_has_paid_distributions &&
                !recordDetailStore.currentPageDocument.is_title_transfer &&
                !authStore.isDalmoreAdmin &&
                authStore.hasInvestmentProcessingPermissions
              "
            >
              <template #icon> <IconEdit /> </template>
              <template #label> Edit </template>
            </Button>
            <Button @click="handleOpenInNewTab" label icon variant="default-gray-outlined">
              <template #icon>
                <Icon name="SquareArrowOutUpRight" :stroke-width="2" class="w-4 h-4" />
              </template>
              <template #label>New Tab</template>
            </Button>
          </div>
          <div class="flex-row items-center gap-3 flex lg:hidden">
            <Button
              variant="default-gray-outlined"
              :is-icon-only-on-mobile="true"
              :icon="true"
              @click="dropdownOpen = !dropdownOpen"
            >
              <template #icon> <Icon class="h-4 w-4" name="ChevronDown" /> </template>
            </Button>
          </div>
          <div
            v-if="dropdownOpen"
            ref="dropdownRef"
            class="w-fit-content absolute right-0 top-26 sm:top-10 z-50 mt-1 flex h-auto flex-col rounded-md bg-neutral-gray-1 py-1 text-sm shadow-full lg:hidden"
            @click="dropdownOpen = false"
          >
            <div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="openTTDrawer"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <IconForward class="" />
                </div>
                <p class="mr-3">Transfer Title</p>
              </div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="(partialModalOpen = true), (dropdownOpen = false)"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <IconPercent class="" />
                </div>
                <p class="mr-3">Partially Redeem</p>
              </div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="handleConvertInvestment(), (dropdownOpen = false)"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <Icon name="MessageSquareReply" class="" />
                </div>
                <p class="mr-3">Convert Investment</p>
              </div>
              <div
                class="flex cursor-pointer flex-row items-center py-value-6 text-primary-6 hover:bg-button-primary-hover"
                @click="handleOpenInNewTab"
              >
                <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                  <Icon name="SquareArrowOutUpRight" class="" />
                </div>
                <p class="mr-3">New Tab</p>
              </div>
            </div>
          </div>
        </template>
      </Header>

      <!-- TABS -->
      <!-- TABS -->
      <div class="flex mt-2 overflow-scroll no-scrollbar">
        <div
          v-for="tab in availableTabs"
          @click="activeTab = tab.name"
          :key="tab"
          class="border-b-3 p-3 text-xs cursor-pointer uppercase font-semibold flex gap-2 tracking-wide items-center justify-center text-nowrap"
          :class="[
            activeTab == tab.name
              ? 'text-primary-6 border-functional-warning-default bg-secondary-6 bg-opacity-20'
              : 'text-primary-2 border-platform-outlines hover:bg-gray-100',
          ]"
        >
          <Icon
            v-if="tab.is_lucide"
            :name="tab.icon"
            class="h-5.5 w-5.5"
            :class="[activeTab == tab.name ? 'stroke-primary-6' : 'stroke-primary-2']"
          />
          <component
            v-else
            :is="iconMapping[tab.icon]"
            class="h-5.5 w-5.5"
            :class="[activeTab == tab.name ? 'fill-primary-6' : 'fill-primary-2']"
          />
          <span>{{ tab.name }}</span>
        </div>
      </div>

      <!----- TAB CONTENT ----->
      <!-- Investment Info -->
      <div v-if="activeTab === 'Investment Info'" class="flex flex-col gap-2">
        <div class="w-full border-platform-outlines flex gap-12 py-2 font-medium flex-wrap">
          <div>
            <div class="text-xs text-lighter uppercase font-medium tracking-wideish">Amount</div>
            <div class="text-xl text-dark font-bold">
              ${{ commaSeparateThousands(recordDetailStore.currentPageDocument.amount) }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Bonds</div>
            <div class="text-xl text-dark font-bold">
              {{ recordDetailStore.currentPageDocument.bonds }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Interest Rate</div>
            <div class="text-xl text-dark font-bold">
              {{ offering.interest_rate ? offering.interest_rate + "%" : "-" }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Status</div>
            <div
              class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center text-sm font-normal"
              :class="
                recordDetailStore.currentPageDocument.status == 'Created'
                  ? 'bg-custom-light-blue text-custom-light-blue'
                  : recordDetailStore.currentPageDocument.status == 'Settled'
                    ? 'bg-custom-lime-green text-custom-lime-green'
                    : recordDetailStore.currentPageDocument.status == 'Canceled'
                      ? 'bg-functional-error-default text-functional-error-hover'
                      : recordDetailStore.currentPageDocument.status == 'Title Transferred'
                        ? 'bg-custom-teal text-custom-teal'
                        : recordDetailStore.currentPageDocument.status == 'Funded'
                          ? 'bg-functional-warning-default text-functional-warning-hover'
                          : 'bg-neutral-gray-6 text-neutral-gray-6'
              "
            >
              {{ recordDetailStore.currentPageDocument.status }}
            </div>
          </div>
          <div>
            <div class="text-xs text-lighter uppercase">Maturity Date</div>
            <div class="text-xl text-dark font-semibold">
              {{
                recordDetailStore.currentPageDocument?.maturity_date
                  ? new Date(
                      recordDetailStore.currentPageDocument?.maturity_date
                    )?.toLocaleDateString("en-us", { timeZone: "UTC" })
                  : "-"
              }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
          <div class="flex flex-col grow gap-4">
            <div
              v-if="recordDetailStore.fetchedAccordionData"
              v-for="section of recordDetailStore.fetchedAccordionData.filter(
                (section) =>
                  section.name !== 'Custodian' &&
                  section.name !== 'Offering' &&
                  section.name !== 'Sub Agreement' &&
                  section.name !== 'Partial Redemptions'
              )"
              class="rounded-lg border-platform-outlines border px-4 py-4"
            >
              <div class="text-dark font-bold text-lg flex items-center w-full">
                <div class="grow">{{ section.name }}</div>
                <div class="grow flex justify-end">
                  <IconChevronUp
                    class="h-5.5 w-5.5 cursor-pointer"
                    @click="section.collapsed = !section.collapsed"
                    :class="section.collapsed ? 'rotate-180' : 'rotate-0'"
                  />
                </div>
              </div>
              <!-- TODO: better solution for accordion collapsing transition -->
              <div
                class="grid grid-cols-1 md:grid-cols-2 gap-4 transition-all duration-300 overflow-hidden"
                :class="section.collapsed ? 'max-h-0' : 'max-h-[200vh]'"
              >
                <div
                  v-for="field of section.fields"
                  class="flex flex-col gap-0.5 border-platform-outlines border-b py-1"
                >
                  <span
                    class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                    >{{ field.name }}</span
                  >
                  <span v-if="field.v_model" class="truncate text-md">{{ field.v_model }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col grow gap-4">
            <!-- Sub -->
            <div class="rounded-lg border-platform-outlines border px-4 py-4">
              <div class="text-dark font-bold text-lg mb-2 flex items-center">
                <div class="grow">Sub Agreement</div>
                <IconChevronUp
                  class="h-5.5 w-5.5 cursor-pointer"
                  @click="subAgreementCollapsed = !subAgreementCollapsed"
                  :class="subAgreementCollapsed ? 'rotate-180' : 'rotate-0'"
                />
              </div>
              <div
                class="flex md:grid sm:grid-cols-2 gap-2 transition-all duration-300 overflow-hidden"
                :class="subAgreementCollapsed ? 'max-h-0' : 'max-h-[200vh]'"
              >
                <div v-if="subAgreements.length == 0">
                  <div
                    class="sm:col-start-1 bg-neutral-gray-6 bg-opacity-20 rounded-md items-center flex px-2 gap-2 h-8 w-full"
                  >
                    <IconAttachment class="w-6 h-6" />
                    <span class="text-base">No Subscription Agreement</span>
                  </div>
                </div>
                <div
                  v-else
                  v-for="sub of subAgreements"
                  class="sm:col-start-1 bg-custom-light-blue bg-opacity-20 rounded-md items-center flex p-2 gap-2 h-8 w-full"
                >
                  <IconAttachment class="w-6 h-6" />
                  <div class="underline text-sm w-full overflow-hidden truncate">
                    <span class="cursor-pointer" @click="previewSubAgreement(sub._id)">{{
                      sub.file_name
                    }}</span>
                  </div>
                  <IconFileDownload
                    class="justify-self-end cursor-pointer w-6 h-6"
                    @click="downloadSubAgreement(sub._id)"
                  />
                </div>
                <div
                  class="col-start-1 sm:col-start-2 sm:row-start-1 sm:justify-self-end row-span-2"
                >
                  <Button
                    v-if="!authStore.isDalmoreAdmin"
                    variant="default-gray-outlined"
                    :is-icon-only-on-mobile="false"
                    icon
                    label
                    class="w-full"
                    size="medium"
                  >
                    <template #icon>
                      <Icon
                        class="h-5 w-5"
                        :name="subAgreements.length == 0 ? 'Plus' : 'RefreshCcw'"
                      />
                    </template>
                    <template #label>
                      {{ subAgreements.length == 0 ? "Add" : "Replace" }} Sub Agreement
                    </template>
                  </Button>
                </div>
              </div>
            </div>
            <!-- Offering -->
            <div class="rounded-lg border-platform-outlines border px-4 py-4">
              <div class="text-dark font-bold text-lg flex items-center w-full">
                <div class="grow">Offering</div>
                <div class="grow flex justify-end">
                  <IconChevronUp
                    class="h-5.5 w-5.5 cursor-pointer"
                    @click="offeringCollapsed = !offeringCollapsed"
                    :class="offeringCollapsed ? 'rotate-180' : 'rotate-0'"
                  />
                </div>
              </div>
              <div
                class="grid grid-cols-1 md:grid-cols-2 gap-4 transition-all duration-300 overflow-hidden"
                :class="offeringCollapsed ? 'max-h-0' : 'max-h-[200vh]'"
              >
                <div class="flex flex-col gap-0.5 border-platform-outlines border-b py-1">
                  <span
                    class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                  >
                    OFFERING
                  </span>
                  <span v-if="offering.type == 'Reg A'" class="truncate">{{ offering.type }}</span>
                  <span v-else-if="offering.type && offering.name" class="truncate">{{
                    offering.type +
                    ` ${offering.name.includes("Series") ? "" : "Series "}` +
                    offering.name
                  }}</span>
                  <span v-else>-</span>
                </div>
                <div class="flex flex-col gap-0.5 border-platform-outlines border-b py-1">
                  <span
                    class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                  >
                    TERM
                  </span>
                  <span v-if="offering.bond_term" class="truncate">{{
                    monthsToYears(offering.bond_term)
                  }}</span>
                  <span v-else>-</span>
                </div>
                <div class="flex flex-col gap-0.5 border-platform-outlines border-b py-1">
                  <span
                    class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                  >
                    INTEREST RATE
                  </span>
                  <span v-if="offering.interest_rate" class="truncate"
                    >{{ offering.interest_rate }}%</span
                  >
                  <span v-else>-</span>
                </div>
                <div
                  v-if="recordDetailStore.fetchedAccordionData"
                  v-for="field of recordDetailStore.fetchedAccordionData.find(
                    (section) => section.name == 'Offering'
                  )?.fields"
                  class="flex flex-col gap-0.5 border-platform-outlines border-b py-1"
                >
                  <span
                    class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                    >{{ field.name }}</span
                  >
                  <span v-if="field.v_model" class="truncate">{{ field.v_model }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>

            <!-- how to tell if IRA type contains ira? -->
            <div v-if="recordDetailStore.currentPageDocument?.type?.includes('Traditional IRA')">
              <div class="flex flex-col rounded-lg border-platform-outlines border px-4 py-4">
                <div class="text-dark font-bold text-lg mb-2 flex items-center">
                  <div class="grow">Custodian</div>
                  <IconChevronUp
                    class="h-5.5 w-5.5 cursor-pointer"
                    @click="custodianCollapsed = !custodianCollapsed"
                    :class="custodianCollapsed ? 'rotate-180' : 'rotate-0'"
                  />
                </div>
                <div
                  class="grid grid-cols-2 sm:grid-cols-3 gap-2 transition-all duration-300 overflow-hidden"
                  :class="custodianCollapsed ? 'max-h-0' : 'max-h-[200vh]'"
                >
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-2 col-span-2">
                    <div
                      v-if="true"
                      class="flex flex-col gap-0.5 border-platform-outlines border-b py-1"
                    >
                      <span
                        class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                        >CUSTODIAN NAME</span
                      >
                      <span v-if="true" class="truncate">{{ custodian?.name || "-" }}</span>
                      <span v-else>-</span>
                    </div>
                    <div
                      v-if="
                        recordDetailStore.fetchedAccordionData &&
                        recordDetailStore.fetchedAccordionData.filter(
                          (section) => section?.name == 'Custodian'
                        )[0]?.fields
                      "
                      v-for="field of recordDetailStore.fetchedAccordionData.filter(
                        (section) => section.name == 'Custodian'
                      )[0].fields"
                      class="flex flex-col gap-0.5 border-platform-outlines border-b py-1"
                    >
                      <span
                        class="text-sm text-lighter truncate uppercase font-medium tracking-wideish"
                        >{{ field.name }}</span
                      >
                      <span v-if="field.v_model" class="truncate">{{ field.v_model }}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <div class="flex justify-end">
                    <Button
                      label
                      variant="default-gray-outlined"
                      size="large"
                      @click="custodianModalOpen = true"
                    >
                      ><template #label>Edit Custodian</template></Button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="bg-gray-200"
            v-for="(value, key) in recordDetailStore.currentPageDocument"
            :key="key"
          >
            <strong>{{ key }}:</strong> {{ value }}
          </div> -->
        </div>
      </div>
      <div v-else-if="activeTab == 'Earnings'"><InvestmentsEarningTab /></div>
      <div v-else-if="activeTab == 'Banking Info'">
        <InvestmentsBankingTab :banks="banks" />
      </div>
      <div v-else-if="activeTab == 'Rollovers'">Rollovers</div>
      <div v-else-if="activeTab == 'Transfer Details'"><TransferDetails /></div>
      <div v-else-if="activeTab == 'Partial Redemptions'">
        <Table
          @setSortHeader="(header, props) => setSortHeader(header, props, activeTab)"
          :headers="[
            { label: 'Date', field_name: 'redemption_date', field_type: 'date' },
            {
              label: 'Amount',
              field_name: 'redemption_amount',
              field_type: 'number',
              number_type: 'currency',
            },
            {
              label: 'Previous Amount',
              field_name: 'previous_amount',
              field_type: 'number',
              number_type: 'currency',
            },
            {
              label: 'Previous Discounted Amount',
              field_name: 'previous_discounted_amount',
              field_type: 'number',
              number_type: 'currency',
            },
          ]"
          :tableData="
            UIStore.transformRecords(recordDetailStore.currentPageDocument.partial_redemptions)
          "
          :fieldsWithCustomStyles="['table-ctas']"
          :isResizable="true"
          actions_always_sticky
        >
          <template v-slot:table-ctas="entry">
            <div class="flex items-center space-x-2">
              <Button @click="previewSubAgreement(entry)" size="small" variant="subtle" icon label>
                <template #label> Delete </template>
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-4 w-4 text-functional-error-default"
                    :strokeWidth="2.5"
                  />
                </template>
              </Button>
            </div>
          </template>
        </Table>
      </div>
    </div>
  </div>
  <!-- LOADING -->
  <div v-else-if="page_is_loading || titleTransferStore.investment_being_created">
    <LoadingSpinner
      :show="page_is_loading || titleTransferStore.investment_being_created"
      :message="recordDetailStore.pageLoaderMessage"
      :hide-overlay="true"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, watch, computed, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  useCrudStore,
  useRecordDetailStore,
  useUIStore,
  useDataModelManagementStore,
  useCreateInvestmentStore,
  useTitleTransferStore,
  useInvestmentStore,
  useSchemaStore,
  useInvestorStore,
  useAuthStore,
} from "@/stores";
import {
  Button,
  Modal,
  BaseInput,
  Table,
  LoadingSpinner,
  AllRecordsTable,
  Header,
  Icon,
  EditBankDrawer,
  EmptyState,
  DocPreviewModal,
} from "@/components";
import {
  IconEdit,
  IconChevronUp,
  IconForward,
  IconRefresh,
  IconX,
  IconFileDownload,
  IconAttachment,
  IconSignature,
  IconFilter,
  IconLandmark,
  IconPercent,
  IconCopyAll,
  IconCircleCheck,
} from "@/components/icons";
import { CreateInvestmentDrawer, TitleTransferDrawer } from "@/pages";
import { iconMapping } from "@/constants/iconMapping";
import { commaSeparateThousands, monthsToYears } from "@/utilities";
import useFileUtility from "@/composables/useFileUtility";
import EditCustodianInfoModal from "./EditCustodianInfoModal.vue";
import SetInvestmentStatusModal from "./SetInvestmentStatusModal.vue";
import SetInvestmentSignatureModal from "./SetInvestmentSignatureModal.vue";
import PartialRedemptionModal from "./PartialRedemptionModal.vue";
import ConvertInvestmentsDrawer from "../Contacts/InvestmentConversions/ConvertInvestmentsDrawer.vue";
import InvestmentsEarningTab from "./InvestmentsEarningTab.vue";
import EditInvestmentModal from "./EditInvestmentModal.vue";

//import EditBankDrawer from "./EditBankDrawer.vue";
import TransferDetails from "./TransferDetails.vue";
import { Copy } from "lucide-vue-next";
import InvestmentsBankingTab from "./InvestmentsBankingTab.vue";

const {
  downloadFile_R2,
  getFile_R2,
  fetchFileForPreview_R2,
  uploadFile_R2,
  deleteFile_R2,
  parseCSV,
} = useFileUtility();

const schemaStore = useSchemaStore();
const crudStore = useCrudStore();
const investmentStore = useInvestmentStore();
const recordDetailStore = useRecordDetailStore();
const titleTransferStore = useTitleTransferStore();
const createInvestmentStore = useCreateInvestmentStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();
const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const dropdownRef = ref(null);
const dropdownOpen = ref(false);
const page_is_loading = ref(false);
const investment_modal_open = ref(false);
const availableTabs = computed(() => {
  // default tabs
  const tabs = [
    { name: "Investment Info", icon: "IconInvestment", is_lucide: false },
    { name: "Earnings", icon: "HandCoins", is_lucide: true },
  ];
  if (!authStore.isDalmoreAdmin) {
    tabs.push({ name: "Banking Info", icon: "Landmark", is_lucide: true });
  }
  // declare conditinal tabs
  const rollover_tab = { name: "Rollover", icon: "RedoDot", is_lucide: true };
  const title_transfer_tab = { name: "Transfer Details", icon: "Handshake", is_lucide: true };
  const partial_redemption_tab = { name: "Partial Redemptions", icon: "Percent", is_lucide: true };
  // assign rollover if available rollover
  if (
    (recordDetailStore.currentPageDocument?.able_to_rollover ||
      recordDetailStore.currentPageDocument?.is_rollover) &&
    !authStore.isDalmoreAdmin
  ) {
    tabs.push(rollover_tab);
  }
  if (
    (recordDetailStore.fetched_transfer_children.length > 0 ||
      recordDetailStore.fetched_transfer_parents.length > 0) &&
    !authStore.isDalmoreAdmin
  ) {
    tabs.push(title_transfer_tab);
  }
  if (
    recordDetailStore.currentPageDocument.partial_redemptions?.length &&
    !authStore.isDalmoreAdmin
  ) {
    tabs.push(partial_redemption_tab);
  }
  return tabs;
});
const activeTab = ref("Investment Info");
const subAgreementCollapsed = ref(false);
const custodianCollapsed = ref(false);
const offeringCollapsed = ref(false);
const copy_value = ref("");

const addEditCustodianModalOpen = ref(false);
const custodianModalOpen = ref(false);
const investmentModalOpen = ref(false);
const partialModalOpen = ref(false);

const statusModalOpen = ref(false);
const signatureModalOpen = ref(false);

const editBankDrawerOpen = ref(false);
const editBankType = ref(null);

const subAgreements = ref([]);
const offering = ref([]);
const custodian = ref({});
const banks = ref({});

const docPreviewModalOpen = ref(false);
const file_data = ref(null);
const file_type = ref(null);
const file_name = ref(null);

const backTitle = computed(() => {
  let prev_name = "Investments";

  // if we have history, use that to get the previous path name
  let prev_path = router.options.history?.state?.back;
  if (prev_path) {
    let prev_path_min = prev_path.split("?")[0]; // remove query params
    prev_name = prev_path_min.replace(/^\//, "").replace(/-/g, " "); // remove leading slash and replace dashes with spaces
  }

  // return previous path name
  return prev_name;
});

onMounted(async () => {
  window.addEventListener("click", (event) => handleClickOutside(event));
  await onMountedFunction();
});

onBeforeUnmount(() => {
  window.removeEventListener("click", handleClickOutside);
});

watch(
  () => route.query.recordId,
  async () => {
    //console.log(route, route.query.recordId);
    if (route.fullPath.includes("/investments")) {
      //console.log("running onMounted");
      await onMountedFunction();
    }
  }
);

watch(
  () => recordDetailStore.allRecordsPageNumber,
  async (newValue) => {
    await recordDetailStore.fetchTableRecords(true, !authStore.hasInvestmentProcessingPermissions);
  }
);

watch(
  () => recordDetailStore.updateTic,
  async (newValue) => {
    await onMountedFunction();
  }
);

async function onMountedFunction() {
  UIStore.sortHeader = { field_name: "signature_date" };
  UIStore.sortAscending = false;
  page_is_loading.value = true;
  recordDetailStore.currentPageCollection = "Investments";
  recordDetailStore.filterPipeline = null;
  recordDetailStore.defaultFilterPipeline = null;
  activeTab.value = "Investment Info";

  let currentTimeAll = new Date();
  let currentTime = new Date();
  let delta = 0;

  await recordDetailStore.setCurrentPageConfig();

  if (route.query.recordId) {
    currentTime = new Date();
    //await recordDetailStore.setCurrentPageDocument(route.query.recordId);

    await Promise.all([
      recordDetailStore.setCurrentPageConfig(),
      recordDetailStore.setCurrentPageDocument(route.query.recordId),
    ]);
    delta = new Date() - currentTime;
    console.log("curentPageDocument", delta);

    // await recordDetailStore.setData("Investments", route.query.recordId);

    // subAgreements.value = await recordDetailStore.fetchSubAgreements(route.query.recordId);

    // offering.value = await recordDetailStore.fetchOffering(
    //   recordDetailStore.currentPageDocument.offering_id
    // );

    // custodian.value = await recordDetailStore.fetchCustodian(
    //   recordDetailStore.currentPageDocument.custodian_id
    // );

    // banks.value = await recordDetailStore.fetchBanks(
    //   recordDetailStore.currentPageDocument.funding_account,
    //   recordDetailStore.currentPageDocument.distribution_account
    // );

    currentTime = new Date();
    var all_promises = [
      recordDetailStore.setData("Investments", route.query.recordId),
      recordDetailStore.fetchSubAgreements(route.query.recordId),
      recordDetailStore.fetchOffering(recordDetailStore.currentPageDocument.offering_id),
      recordDetailStore.fetchCustodian(recordDetailStore.currentPageDocument.custodian_id),
      recordDetailStore.fetchBanks(
        recordDetailStore.currentPageDocument.funding_account,
        recordDetailStore.currentPageDocument.distribution_account
      ),
    ];

    const [_, subAgreementsResult, offeringResult, custodianResult, banksResult] =
      await Promise.all(all_promises);

    delta = new Date() - currentTime;
    console.log("finish Promise.all", delta);
    currentTime = new Date();

    subAgreements.value = subAgreementsResult;
    offering.value = offeringResult;
    custodian.value = custodianResult;
    banks.value = banksResult;
  } else {
    await recordDetailStore.setCurrentPageConfig();
    await recordDetailStore.getPossibleColumns("Investments");
    await recordDetailStore.fetchTableRecords(true, !authStore.hasInvestmentProcessingPermissions);
    createInvestmentStore.fetchOfferings();
  }
  console.log("delta all", new Date() - currentTimeAll);

  page_is_loading.value = false;

  console.log("Investment Details Page Mounted");
  recordDetailStore.pageLoaderMessage = "Loading...";
}

const isAllChecked = computed(() => {
  if (recordDetailStore.allRecords.length === 0) {
    return false;
  }

  return recordDetailStore.allRecords.every((record) =>
    investmentStore.investment_ids_to_process.includes(record._id.value?.toString())
  );
});

function handleOpenInNewTab() {
  const fullPath = route.fullPath;
  const investmentId = recordDetailStore.currentPageDocument._id;
  UIStore.createNewTab({
    name: `INV - ${investmentId}`,
    fullPath,
  });
}

function handleSelectAll() {
  let selectAllInput = document.getElementById("select-all");

  if (!selectAllInput.checked) {
    // Remove the IDs of the records on the current page from the investment_ids_to_process array
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) =>
        !recordDetailStore.allRecords.some(
          (record) => record._id.value.toString() === id.toString()
        )
    );
  } else {
    // Add the IDs of the records on the current page to the investment_ids_to_process array
    const currentIds = recordDetailStore.allRecords.map((record) => record._id.value?.toString());
    const uniqueIds = new Set([...investmentStore.investment_ids_to_process, ...currentIds]);
    investmentStore.investment_ids_to_process = Array.from(uniqueIds);
  }
}

function selectOnly(entry) {
  const recordId = entry.row.row._id.value?.toString();

  // Clear the array and add only the selected ID
  investmentStore.investment_ids_to_process = [recordId];
}

function select(entry) {
  const recordId = entry.row.row._id.value?.toString();

  if (investmentStore.investment_ids_to_process.includes(recordId)) {
    // If the ID is already in the array, remove it
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) => id !== recordId
    );
  } else {
    // If the ID is not in the array, add it
    investmentStore.investment_ids_to_process.push(recordId);
  }
}

async function handleRefetchInvestments() {
  setTimeout(async () => {
    await recordDetailStore.fetchTableRecords(true, !authStore.hasInvestmentProcessingPermissions);
  }, 3000);
}

async function openTTDrawer() {
  console.log("openTTDrawer");
  titleTransferStore.transfer_drawer_open = true;
  console.log(titleTransferStore.transfer_drawer_open);

  await titleTransferStore.setCurrentOwnerContactFromInvestment();
  console.log(titleTransferStore.transfer_drawer_open);
}

async function handleOpenBankDrawer(type) {
  UIStore.isDrawerLoading = true;
  editBankType.value = type;
  editBankDrawerOpen.value = true;
  if (type == "Funding") {
    investorStore.active_bank_account = banks.value.funding_account;
    investorStore.active_finance_method = recordDetailStore.currentPageDocument.payment_method;
  } else if (type == "Distribution") {
    investorStore.active_bank_account = banks.value.distrubution_account;
    investorStore.active_finance_method = recordDetailStore.currentPageDocument.distribution_method;
  }

  await investorStore.fetchBankAccounts(recordDetailStore.currentPageDocument.account_id);
  UIStore.isDrawerLoading = false;
}

async function updateBankAccounts() {
  UIStore.isDrawerLoading = true;
  await investorStore.fetchBankAccounts(recordDetailStore.currentPageDocument.account_id);
  UIStore.isDrawerLoading = false;
}

function copyToClipboard(entry, field_name) {
  //console.log("Copied to clipboard: ", nugget.v_model);
  navigator.clipboard.writeText(entry[field_name].value);
  entry[field_name].isCopied = true;
  setTimeout(() => {
    entry[field_name].isCopied = false;
  }, 2000);
}

async function previewSubAgreement(id) {
  let subAgreement = await crudStore.findOne("Documents", {
    _id: id,
  });
  if (!subAgreement) {
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to find Subscription Agreement!",
      subText: "This could be from a legacy investment.",
      is_read: false,
      does_persist: false,
      action: null,
    });
    return;
  }

  file_type.value = subAgreement?.file_type;
  file_data.value = await fetchFileForPreview_R2(subAgreement);
  file_name.value = subAgreement?.file_name;
  docPreviewModalOpen.value = true;
}

async function downloadSubAgreement(id) {
  let subAgreement = await crudStore.findOne("Documents", {
    _id: id,
  });
  if (!subAgreement) {
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to find Subscription Agreement!",
      subText: "This could be from a legacy investment.",
      is_read: false,
      does_persist: false,
      action: null,
    });
    return;
  }
  await downloadFile_R2(subAgreement);
}

async function downloadInvestments() {
  recordDetailStore.downloadTableRecordsCSV(`Investments_${new Date().toISOString()}.csv`);
}

function handleConvertInvestment() {
  investmentStore.investment_ids_to_process = [recordDetailStore.currentPageDocument._id];
  investmentStore.isConversionDrawerOpen = true;
}

const handleClickOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

function handleOpenTab(path, investment_id) {
  const fullPath = path;

  UIStore.createNewTab({
    name: investment_id,
    fullPath,
    active: false,
    add_to_front: true,
  });
}
</script>
