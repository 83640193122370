<template>
  <div v-for="(filter, index) in dataFilters" :key="filter">
    <div class="w-full bg-neutral-gray-4 p-3" v-if="!filter.is_edit_disabled">
      <div class="relative mb-2 ml-0.5 flex items-center">
        <div
          v-if="!isViewOnly && showDropdownMenuForFilter === index"
          class="absolute right-0 top-24 z-20 flex w-44 flex-col rounded-md bg-neutral-gray-1 py-1 shadow"
          id="dropdown-menu"
        >
          <div>
            <button
              @click="addOperatorSet(filter, 'and', index)"
              class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
            >
              <div class="mx-2 flex items-center justify-center">
                <IconAndLogic class="fill-neutral-gray-6" />
              </div>
              <p class="text-sm">Add Nested AND</p>
            </button>
            <button
              @click="addOperatorSet(filter, 'or', index)"
              class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
            >
              <div class="mx-2 flex items-center justify-center">
                <IconOrLogic class="fill-neutral-gray-6" />
              </div>
              <p class="text-sm">Add Nested OR</p>
            </button>
            <button
              @click="
                (filter.is_self_referential = !filter.is_self_referential),
                  (filter.is_relative_date = false)
              "
              v-if="!filter.operator.no_self_ref"
              class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
            >
              <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                <IconPivotTable v-if="!filter.is_self_referential" class="fill-neutral-gray-6" />
                <IconABC v-else class="fill-neutral-gray-6" />
              </div>
              <p id="filter-button" v-if="!filter.is_self_referential" class="text-sm">
                Self Referential
              </p>
              <p id="filter-button" v-else class="text-sm">Standard Value</p>
            </button>
            <button
              v-if="filter.data_type === 'date'"
              @click="
                (filter.is_relative_date = !filter.is_relative_date),
                  (filter.is_self_referential = false)
              "
              class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
            >
              <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                <IconABC v-if="!filter.is_relative_date" class="fill-neutral-gray-6" />
                <Icon123 v-else class="fill-neutral-gray-6" />
              </div>
              <p v-if="!filter.is_relative_date" class="alt-filter-toggle text-sm">Alt Value</p>
              <p v-else class="text-sm">Standard Value</p>
            </button>
          </div>
        </div>
        <div class="flex w-full justify-between">
          <label
            v-if="filter.label"
            for=""
            class="mr-2.5 block truncate text-wrap text-xs font-semibold text-lighter"
          >
            {{ filter.label }}
          </label>
          <div v-if="filter.is_relative_date" class="text-xs font-semibold text-primary-1">
            (Alt)
          </div>
          <div v-else-if="filter.is_self_referential" class="text-xs font-semibold text-primary-1">
            (Ref)
          </div>
        </div>
      </div>
      <div class="flex items-start space-x-2.5">
        <div class="relative w-full">
          <Transition
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <Tooltip
              v-if="filter.value && filter.isShowTooltip"
              class="absolute bottom-10 left-5 z-50 overflow-hidden"
              :text="getTooltipText(filter)"
            />
          </Transition>

          <BaseInput
            v-if="filter.data_type !== 'boolean'"
            v-model="filter.operator"
            :options="
              filter.is_self_referential
                ? UIStore.operators.filter((op) => !op.no_self_ref)
                : UIStore.operators
            "
            :disabled="isViewOnly || filter.is_edit_disabled"
            size="medium"
            type="select"
            class="mb-1"
          />
          <template
            v-if="
              (filter.data_type === 'string' ||
                filter.data_type === 'long_text' ||
                filter.data_type === 'array') &&
              filter.operator.name !== 'Is Blank' &&
              filter.operator.name !== 'Is NOT Blank' &&
              !filter.is_self_referential
            "
          >
            <div @mouseenter="handleShowTooltip(filter)" @mouseleave="handleHideTooltip(filter)">
              <input
                type="text"
                v-model="filter.value"
                :disabled="filter.is_edit_disabled || isViewOnly"
                placeholder="Text"
                :class="inputStyles"
              />
            </div>
          </template>
          <template
            v-if="
              filter.data_type === 'dropdown' &&
              filter.operator.name !== 'Is Blank' &&
              filter.operator.name !== 'Is NOT Blank' &&
              !filter.is_self_referential
            "
          >
            <BaseInput
              v-if="
                filter.operator.name !== 'Contains' &&
                filter.operator.name !== 'Does NOT Contain' &&
                filter.operator.name !== 'Includes' &&
                filter.operator.name !== 'Does NOT Include'
              "
              v-model="filter.value"
              :options="filter.dropdown_options"
              :disabled="isViewOnly"
              size="medium"
              placeholder="Equal to value"
              type="select"
            />

            <div
              v-else
              @mouseenter="handleShowTooltip(filter)"
              @mouseleave="handleHideTooltip(filter)"
            >
              <BaseInput
                v-model="filter.value"
                :options="filter.dropdown_options"
                :disabled="isViewOnly"
                size="medium"
                placeholder="Contains value"
                type="multi-select"
              />
            </div>
          </template>
          <template
            v-if="
              filter.data_type === 'number' &&
              filter.operator.name !== 'Is Blank' &&
              filter.operator.name !== 'Is NOT Blank' &&
              !filter.is_self_referential
            "
          >
            <input
              type="number"
              v-model="filter.value"
              :disabled="isViewOnly"
              placeholder="Number"
              :class="inputStyles"
              :step="step"
              :max="max"
              :min="min"
            />
          </template>
          <template
            v-if="
              filter.data_type === 'boolean' &&
              filter.operator.name !== 'Is Blank' &&
              filter.operator.name !== 'Is NOT Blank'
            "
          >
            <BaseInput
              v-model="filter.value"
              :options="[
                { name: 'Yes', value: true },
                { name: 'No', value: false },
              ]"
              :disabled="isViewOnly"
              size="medium"
              type="select"
              class="mb-1"
            />
          </template>
          <template
            v-else-if="
              filter.data_type === 'date' &&
              filter.operator.name !== 'Is Blank' &&
              filter.operator.name !== 'Is NOT Blank' &&
              !filter.is_self_referential
            "
          >
            <input
              v-if="!filter.is_relative_date"
              type="date"
              v-model="filter.value"
              :disabled="isViewOnly"
              :class="inputStyles"
            />
            <div
              v-else
              @mouseenter="handleShowTooltip(filter)"
              @mouseleave="handleHideTooltip(filter)"
            >
              <BaseInput
                v-model="filter.value"
                :options="relativeDateOptions"
                :disabled="isViewOnly"
                size="medium"
                type="select"
                placeholder="Select a Relative Date"
              />
            </div>
          </template>
          <template v-if="filter.is_self_referential">
            <!-- Search Bar -->
            <BaseInput
              :required="true"
              v-model:currentTermValue="searchStore.searchTerm_selfRef[index + '' + null]"
              @update:currentTermValue="searchForSelfRefFields(index, null)"
              v-model:assignedObject="selectedObj_selfRef[index]"
              type="assignment"
              size="medium"
              :disabled="isViewOnly"
              customClass="bg-neutral-gray-1"
              :placeholder="`Search and Add Filters`"
              :results="filteredPossibleColumns_selfRef[index + '' + null]"
              @focus="true"
            >
              <template #search-dropdown>
                <div
                  v-for="columnGroup in filteredPossibleColumns_selfRef[index + '' + null]"
                  :key="columnGroup"
                  class="w-full"
                >
                  <div
                    v-if="columnGroup.fields.length"
                    class="sticky top-0 w-full bg-neutral-gray-3 p-2 text-xs font-bold text-primary-6"
                  >
                    {{ columnGroup.collection_name.toUpperCase() }}
                  </div>
                  <div
                    v-for="column in columnGroup.fields"
                    :key="column"
                    @mousedown="addSelfReferential(column, filter, index)"
                    class="cursor-pointer px-3 py-2 hover:bg-platform-hover-secondary"
                  >
                    <span class="text-primary text-sm">
                      {{ column.label }}
                    </span>
                  </div>
                </div>
              </template>
            </BaseInput>
          </template>
        </div>
        <div class="space-y-2">
          <Button
            v-if="!isViewOnly"
            @click="deleteSet(filter)"
            icon
            variant="default-gray-outlined"
            size="small"
            class="flex self-start"
          >
            <template #icon><IconDelete class="h-4 w-4 fill-functional-error-default" /></template>
          </Button>

          <Button
            v-if="!isViewOnly"
            @click="toggleShowDropdownMenuForFilter(index)"
            icon
            customClass="bg-neutral-gray-2"
            variant="subtle"
            size="small"
            class="flex self-start"
          >
            <template #icon><IconMoreVert class="h-4 w-4 fill-[#6a7c8c]" /></template>
          </Button>
        </div>
      </div>
      <!-- Internal Set -->
      <div
        v-for="(set, internal_index) of filter.internal_sets"
        :key="set"
        class="flex items-center"
      >
        <div
          :class="
            set.label === 'New Filter'
              ? internal_index === filter.internal_sets.length - 1
                ? 'dashed-internal-and-last-new'
                : 'dashed-internal-and-new'
              : internal_index === filter.internal_sets.length - 1
                ? 'dashed-internal-and-last'
                : 'dashed-internal-and'
          "
          class="my-value-6 mt-5 flex items-center space-x-1.5"
        >
          <div
            v-if="set.logical_operator === 'and'"
            class="z-10 rounded-md border bg-neutral-gray-5 px-1 py-0.5 text-xs font-normal text-primary-2"
          >
            AND
          </div>
          <div
            v-else
            class="z-10 rounded-md border bg-neutral-gray-5 px-2 py-0.5 text-xs font-normal text-primary-2"
          >
            OR
          </div>
        </div>
        <div class="mt-3 flex items-center" v-if="set.label === 'New Filter'">
          <BaseInput
            v-if="parent !== 'maps-layers'"
            v-model="searchStore.searchTerms_sets[index + '' + internal_index]"
            @input="searchForSetFilters(index, internal_index)"
            :results="filteredPossibleColumns_forIndex[index + '' + internal_index]"
            :disabled="isViewOnly"
            placeholder="Add Filters"
            type="search"
            size="small"
            customClass="bg-neutral-gray-1"
            ref="searchInput"
            class="px-3"
          >
            <template #search-dropdown>
              <div
                v-for="columnGroup in filteredPossibleColumns_forIndex[index + '' + internal_index]"
                :key="columnGroup"
                class="w-full"
              >
                <div
                  v-if="columnGroup.fields.length"
                  class="sticky top-0 w-full bg-neutral-gray-3 p-2 text-xs font-bold text-primary-6"
                >
                  {{ columnGroup.collection_name.toUpperCase() }}
                </div>
                <div
                  v-for="column in columnGroup.fields"
                  :key="column"
                  @mousedown="addInternalSet(set, column, internal_index)"
                  class="cursor-pointer px-3 py-2 hover:bg-platform-hover-secondary"
                >
                  <span class="text-primary text-sm">
                    {{ column.label }}
                  </span>
                </div>
              </div>
            </template>
          </BaseInput>
          <BaseInput
            v-else
            v-model="searchStore.searchTerms_sets[index + '' + internal_index]"
            @input="searchForSetFilters(index, internal_index)"
            :results="filteredPossibleColumns_forIndex[index + '' + internal_index]"
            :disabled="isViewOnly"
            placeholder="Add Filters"
            type="search"
            size="small"
            customClass="bg-neutral-gray-1"
            ref="searchInput"
            class="px-3"
          >
            <template #search-dropdown>
              <div
                v-for="field in filteredPossibleColumns_forIndex[index + '' + internal_index]"
                :key="field"
                class="w-full"
              >
                <div
                  @mousedown="addInternalSet(set, field)"
                  class="cursor-pointer px-3 py-2 hover:bg-platform-hover-secondary"
                >
                  <span class="text-primary text-xs">
                    {{ field.label }}
                  </span>
                </div>
              </div>
            </template>
          </BaseInput>
          <Button
            v-if="!isViewOnly"
            @click="deleteInternalSet(filter, set)"
            icon
            variant="default-gray-outlined"
            size="small"
            class="flex self-start"
          >
            <template #icon><IconDelete class="h-4 w-4 fill-functional-error-default" /></template>
          </Button>
        </div>
        <div
          v-if="set.label !== 'New Filter'"
          class="relative mt-3 max-w-54 w-full bg-neutral-gray-4 pl-3"
        >
          <div class="mb-2 ml-0.5 flex w-full items-center justify-between">
            <label
              v-if="set.label"
              class="mr-2.5 block truncate text-wrap text-xs font-semibold text-lighter"
            >
              {{ set.label }}
            </label>
            <div v-if="set.is_relative_date" class="text-xs font-semibold text-primary-1">
              (Alt)
            </div>
            <div v-else-if="set.is_self_referential" class="text-xs font-semibold text-primary-1">
              (Ref)
            </div>
          </div>
          <div class="relative flex items-start space-x-2.5">
            <div class="w-full">
              <BaseInput
                v-model="set.operator"
                :options="
                  set.is_self_referential
                    ? UIStore.operators.filter((op) => !op.no_self_ref)
                    : UIStore.operators
                "
                :disabled="isViewOnly"
                size="medium"
                type="select"
                class="mb-1"
              />
              <template
                v-if="
                  (set.data_type === 'string' ||
                    set.data_type === 'long_text' ||
                    set.data_type === 'array') &&
                  set.operator.name !== 'Is Blank' &&
                  set.operator.name !== 'Is NOT Blank' &&
                  !set.is_self_referential
                "
              >
                <input
                  type="text"
                  v-model="set.value"
                  :disabled="isViewOnly"
                  placeholder="Text"
                  :class="inputStyles"
                />
              </template>
              <template
                v-if="
                  set.data_type === 'dropdown' &&
                  set.operator.name !== 'Is Blank' &&
                  set.operator.name !== 'Is NOT Blank' &&
                  !set.is_self_referential
                "
              >
                <BaseInput
                  v-if="
                    set.operator.name !== 'Contains' &&
                    set.operator.name !== 'Does NOT Contain' &&
                    set.operator.name !== 'Includes' &&
                    set.operator.name !== 'Does NOT Include'
                  "
                  v-model="set.value"
                  :options="set.dropdown_options"
                  :disabled="isViewOnly"
                  size="medium"
                  placeholder="Equal to value"
                  type="select"
                  class="max-w-full"
                />
                <div
                  v-else
                  class="relative w-full"
                  @mouseenter="handleShowTooltip(set)"
                  @mouseleave="handleHideTooltip(set)"
                >
                  <Transition
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <Tooltip
                      v-if="set.value && set.isShowTooltip"
                      class="absolute bottom-10 left-5 z-50 overflow-hidden"
                      :text="getTooltipText(set)"
                    />
                  </Transition>
                  <BaseInput
                    v-model="set.value"
                    :options="set.dropdown_options"
                    :disabled="isViewOnly"
                    size="medium"
                    placeholder="Contains value"
                    type="multi-select"
                    class="max-w-full"
                  />
                </div>
              </template>
              <template
                v-if="
                  set.data_type === 'number' &&
                  set.operator.name !== 'Is Blank' &&
                  set.operator.name !== 'Is NOT Blank' &&
                  !set.is_self_referential
                "
              >
                <input
                  type="number"
                  v-model="set.value"
                  :disabled="isViewOnly"
                  placeholder="Number"
                  :class="inputStyles"
                />
              </template>
              <template
                v-if="
                  set.data_type === 'boolean' &&
                  set.operator.name !== 'Is Blank' &&
                  set.operator.name !== 'Is NOT Blank'
                "
              >
                <BaseInput
                  v-model="set.value"
                  :options="[
                    { name: 'Yes', value: true },
                    { name: 'No', value: false },
                  ]"
                  :disabled="isViewOnly"
                  size="medium"
                  type="select"
                  class="mb-1"
                />
              </template>
              <template
                v-else-if="
                  set.data_type === 'date' &&
                  set.operator.name !== 'Is Blank' &&
                  set.operator.name !== 'Is NOT Blank' &&
                  !set.is_self_referential
                "
              >
                <input
                  v-if="!set.is_relative_date"
                  type="date"
                  v-model="set.value"
                  :disabled="isViewOnly"
                  :class="inputStyles"
                />
                <div
                  v-else
                  class="relative w-full"
                  @mouseenter="handleShowTooltip(set)"
                  @mouseleave="handleHideTooltip(set)"
                >
                  <Transition
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <Tooltip
                      v-if="set.value && set.isShowTooltip"
                      class="absolute bottom-10 left-2 z-50 overflow-hidden"
                      :text="getTooltipText(set)"
                    />
                  </Transition>

                  <BaseInput
                    v-model="set.value"
                    :options="relativeDateOptions"
                    :disabled="isViewOnly"
                    size="medium"
                    type="select"
                    placeholder="Select a Relative Date"
                  />
                </div>
              </template>
              <template v-if="set.is_self_referential">
                <!-- Search Bar -->
                <BaseInput
                  :required="true"
                  v-model:currentTermValue="
                    searchStore.searchTerm_selfRef[index + '' + internal_index]
                  "
                  @update:currentTermValue="searchForSelfRefFields(index, internal_index)"
                  v-model:assignedObject="selectedObj_selfRef[index + '' + internal_index]"
                  type="assignment"
                  size="medium"
                  :disabled="isViewOnly"
                  customClass="bg-neutral-gray-1"
                  :placeholder="`Search for Filters`"
                  :results="filteredPossibleColumns_selfRef[index + '' + internal_index]"
                  @focus="true"
                >
                  <template #search-dropdown>
                    <div
                      v-for="columnGroup in filteredPossibleColumns_selfRef[
                        index + '' + internal_index
                      ]"
                      :key="columnGroup"
                      class="w-full"
                    >
                      <div
                        v-if="columnGroup.fields.length"
                        class="sticky top-0 w-full bg-neutral-gray-3 p-2 text-xs font-bold text-primary-6"
                      >
                        {{ columnGroup.collection_name.toUpperCase() }}
                      </div>
                      <div
                        v-for="column in columnGroup.fields"
                        :key="column"
                        @mousedown="addSelfReferential(column, set, index + '' + internal_index)"
                        class="cursor-pointer px-3 py-2 hover:bg-platform-hover-secondary"
                      >
                        <span class="text-primary text-sm">
                          {{ column.label }}
                        </span>
                      </div>
                    </div>
                  </template>
                </BaseInput>
              </template>
            </div>
            <div class="space-y-2">
              <Button
                v-if="!isViewOnly"
                @click="deleteInternalSet(filter, set)"
                icon
                variant="default-gray-outlined"
                size="small"
                class="flex self-start"
              >
                <template #icon
                  ><IconDelete class="h-4 w-4 fill-functional-error-default"
                /></template>
              </Button>
              <Button
                v-if="!isViewOnly"
                @click="toggleShowDropdownMenuForFilter(index + '' + internal_index)"
                icon
                customClass="bg-neutral-gray-2"
                variant="subtle"
                size="small"
                class="flex self-start"
              >
                <template #icon><IconMoreVert class="h-4 w-4 fill-[#6a7c8c]" /></template>
              </Button>
            </div>
            <!-- Dropdown menu for internal set -->
            <div
              v-if="!isViewOnly && showDropdownMenuForFilter === index + '' + internal_index"
              class="absolute right-0 top-20 z-20 flex w-44 flex-col rounded-md bg-neutral-gray-1 py-1 shadow"
              id="dropdown-menu"
            >
              <div>
                <button
                  @click="addOperatorSet(filter, 'and', index)"
                  class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
                >
                  <div class="mx-2 flex items-center justify-center">
                    <IconAndLogic class="fill-neutral-gray-6" />
                  </div>
                  <p class="text-sm">Add Nested AND</p>
                </button>
                <button
                  @click="addOperatorSet(filter, 'or', index)"
                  class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
                >
                  <div class="mx-2 flex items-center justify-center">
                    <IconOrLogic class="fill-neutral-gray-6" />
                  </div>
                  <p class="text-sm">Add Nested OR</p>
                </button>
                <button
                  @click="
                    (set.is_self_referential = !set.is_self_referential),
                      (set.is_relative_date = false)
                  "
                  v-if="!set.operator.no_self_ref"
                  class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
                >
                  <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                    <IconPivotTable v-if="!set.is_self_referential" class="fill-neutral-gray-6" />
                    <IconABC v-else class="fill-neutral-gray-6" />
                  </div>
                  <p id="filter-button" v-if="!set.is_self_referential" class="text-sm">
                    Self Referential
                  </p>
                  <p id="filter-button" v-else class="text-sm">Standard Value</p>
                </button>
                <button
                  v-if="set.data_type === 'date'"
                  @click="
                    (set.is_relative_date = !set.is_relative_date),
                      (set.is_self_referential = false)
                  "
                  class="flex w-full cursor-pointer flex-row items-center py-2 text-primary-6 hover:bg-button-primary-hover"
                >
                  <div class="mx-3 flex h-4.5 w-4.5 items-center justify-center">
                    <IconABC v-if="!set.is_relative_date" class="fill-neutral-gray-6" />
                    <Icon123 v-else class="fill-neutral-gray-6" />
                  </div>
                  <p v-if="!set.is_relative_date" class="alt-filter-toggle text-sm">Alt Value</p>
                  <p v-else class="text-sm">Standard Value</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="dataFilters.length > 1 && index < dataFilters.length - 1 && !filter.is_edit_disabled"
      class="my-value-6 flex items-center space-x-1.5 pl-3"
      :class="filter.logical_operator === 'and' ? 'dashed-and' : 'dashed-or'"
    >
      <button
        @click="filter.logical_operator = 'and'"
        :disabled="isViewOnly"
        class="z-10 rounded-md border px-1 py-0.5 text-xs font-normal text-primary-2"
        :class="
          filter.logical_operator === 'and'
            ? 'border-primary-2 bg-platform-outlines'
            : 'border-neutral-gray-5 bg-none hover:border-neutral-gray-5 hover:bg-platform-canvas'
        "
      >
        AND
      </button>
      <button
        @click="filter.logical_operator = 'or'"
        :disabled="isViewOnly"
        class="z-10 rounded-md border px-1 py-0.5 text-xs font-normal text-primary-2"
        :class="
          filter.logical_operator === 'or'
            ? 'border-primary-2 bg-platform-outlines'
            : 'border-neutral-gray-5 bg-none hover:border-neutral-gray-5 hover:bg-platform-canvas'
        "
      >
        OR
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted, onUnmounted } from "vue";
import { Switch } from "@headlessui/vue";
import { Button, BaseInput } from "@/components";
import {
  IconDelete,
  IconAndLogic,
  IconOrLogic,
  IconMoreVert,
  IconPivotTable,
  IconABC,
  Icon123,
} from "@/components/icons";

import { useUIStore } from "@/stores/useUIStore";
import { useSearchStore } from "@/stores/useSearchStore";
//import { useReportStore } from "@/stores/useReportStore";
import { getDateRangeQueryForRelativeValue } from "@/utilities";
import _ from "lodash";

const props = defineProps({
  dataFilters: {
    type: Array,
    default: [],
  },
  searchOptions: {
    type: Array,
    default: [],
  },
  parent: {
    type: String,
    default: "",
  },
  isViewOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "update:modelValue",
  "watchCurrentTerm",
  "blur",
  "focus",
  "updateDataFilters",
]);

const showDropdownMenuForFilter = ref(null);

const UIStore = useUIStore();
const searchStore = useSearchStore();
//const reportStore = useReportStore();

const filteredPossibleColumns_forIndex = ref({});
const filteredPossibleColumns_selfRef = ref({});
const selectedObj_selfRef = ref({});
const relativeDateOptions = [
  { name: "Today", value: "Today", is_relative_date: true },
  { name: "Yesterday", value: "Yesterday", is_relative_date: true },
  { name: "Tomorrow", value: "Tomorrow", is_relative_date: true },
  { name: "This Week", value: "This Week", is_relative_date: true },
  { name: "This Month", value: "This Month", is_relative_date: true },
  { name: "This Year", value: "This Year", is_relative_date: true },
  { name: "Last Week", value: "Last Week", is_relative_date: true },
  { name: "Last Month", value: "Last Month", is_relative_date: true },
  { name: "Last Year", value: "Last Year", is_relative_date: true },
  { name: "Last 7 Days", value: "Last 7 Days", is_relative_date: true },
  { name: "Last 14 Days", value: "Last 14 Days", is_relative_date: true },
  { name: "Last 30 Days", value: "Last 30 Days", is_relative_date: true },
  { name: "Last 60 Days", value: "Last 60 Days", is_relative_date: true },
  { name: "Last 90 Days", value: "Last 90 Days", is_relative_date: true },
  { name: "Last 6 Months", value: "Last 6 Months", is_relative_date: true },
  { name: "Last 12 Months", value: "Last 12 Months", is_relative_date: true },
  { name: "Next Week", value: "Next Week", is_relative_date: true },
  { name: "Next Month", value: "Next Month", is_relative_date: true },
  { name: "Next Year", value: "Next Year", is_relative_date: true },
  { name: "Next 7 Days", value: "Next 7 Days", is_relative_date: true },
  { name: "Next 14 Days", value: "Next 14 Days", is_relative_date: true },
  { name: "Next 30 Days", value: "Next 30 Days", is_relative_date: true },
  { name: "Next 60 Days", value: "Next 60 Days", is_relative_date: true },
  { name: "Next 90 Days", value: "Next 90 Days", is_relative_date: true },
  { name: "Next 6 Months", value: "Next 6 Months", is_relative_date: true },
  { name: "Next 12 Months", value: "Next 12 Months", is_relative_date: true },
];

onMounted(() => {
  document.addEventListener("click", handleOutsideClick);
  // populate any self ref fields to existing value
  props.dataFilters.forEach((dataFilter, index) => {
    if (dataFilter.is_self_referential) {
      selectedObj_selfRef.value[index] = { _id: index, name: dataFilter.self_ref_field.label };
    }
    dataFilter.internal_sets.forEach((internal_set, internal_index) => {
      if (internal_set.is_self_referential) {
        selectedObj_selfRef.value[index + "" + internal_index] = {
          _id: index,
          name: internal_set.self_ref_field.label,
        };
      }
    });
  });
});

onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick);
  // reset search terms
  searchStore.searchTerm_selfRef = {};
  searchStore.searchTerms_sets = {};
});

const inputStyles = computed(() => {
  const baseClass = `text-primary w-full rounded-md border border-neutral-gray-5 bg-neutral-gray-1 px-3 py-3
focus:border-primary-6 focus:outline-none focus:ring-0 disabled:bg-neutral-gray-4`;

  switch (props.size) {
    case "medium":
      return props.type === "longText"
        ? `${baseClass} text-xs placeholder:text-xs`
        : `${baseClass} h-8 text-xs placeholder:text-xs`;
    case "large":
      return props.type === "longText"
        ? `${baseClass} text-sm placeholder:text-sm`
        : `${baseClass} h-11 text-sm placeholder:text-sm`;
    default:
      return props.type === "longText"
        ? `${baseClass} text-xs placeholder:text-xs`
        : `${baseClass} h-8 text-xs placeholder:text-xs`;
  }
});

function searchForSetFilters(set_index, internal_index) {
  let filteredPossibleColumns = JSON.parse(JSON.stringify(props.searchOptions)); // We need a deep copy here
  props.searchOptions.forEach((columnGroup, col_index) => {
    filteredPossibleColumns[col_index].fields = columnGroup.fields.filter((column) =>
      column.label
        .toLowerCase()
        .replace(/_/g, " ")
        .includes(searchStore.searchTerms_sets[set_index + "" + internal_index].toLowerCase())
    );
  });

  filteredPossibleColumns_forIndex.value[set_index + "" + internal_index] = filteredPossibleColumns;
}

function addOperatorSet(filter, logical_operator, set_index) {
  let filterSet = {
    operator: UIStore.operators.find((operator) => operator.name === "Equal To"),
    value: null,
    logical_operator: logical_operator,
    label: "New Filter",
    data_type: "null",
    field_name: "null",
    collection_name: "null",
    dropdown_options: [],
    internal_sets: [],
  };

  let internal_index = filter.internal_sets.length;
  filter.internal_sets.push(filterSet);
  searchStore.searchTerms_sets[set_index + "" + internal_index] = "";
}

function addInternalSet(set, column) {
  set.label = column.label;
  set.data_type = column.field_type;
  set.field_name = column.field_name;
  set.is_object_id = column.is_object_id ? column.is_object_id : false;
  set.collection_name = column.collection_name;
  set.dropdown_options = column.dropdown_options ? column.dropdown_options : [];
  set.value = column.field_type === "boolean" ? { name: "Yes", value: true } : null;
}

function deleteInternalSet(filter, internal_set) {
  const index = filter.internal_sets.indexOf(internal_set);
  if (index > -1) {
    filter.internal_sets.splice(index, 1);
  }
}

const deleteSet = (filter) => {
  const index = props.dataFilters.indexOf(filter);
  if (index > -1) {
    props.dataFilters.splice(index, 1);
  }
};

function searchForSelfRefFields(set_index, internal_index) {
  let filteredPossibleColumns = _.cloneDeep(props.searchOptions); // We need a deep copy here
  props.searchOptions.forEach((columnGroup, col_index) => {
    filteredPossibleColumns[col_index].fields = columnGroup.fields.filter((column) =>
      column.label
        .toLowerCase()
        .replace(/_/g, " ")
        .includes(searchStore.searchTerm_selfRef[set_index + "" + internal_index].toLowerCase())
    );
  });

  filteredPossibleColumns_selfRef.value[set_index + "" + internal_index] = filteredPossibleColumns;
}

function addSelfReferential(column, filter, index) {
  selectedObj_selfRef.value[index] = { _id: index, name: column.label };
  filter.self_ref_field = {
    field_name: column.field_name,
    label: column.label,
    type: column.field_type,
  };
}

function handleShowTooltip(filter) {
  if (!filter || filter.operator.name === "Is Blank" || filter.operator.name === "Is NOT Blank")
    return;

  // Check if the filter is a relative date or a dropdown with 'Contains' or 'Does NOT Contain'
  if (
    filter.is_relative_date ||
    ((filter.data_type === "string" ||
      filter.data_type === "long_text" ||
      filter.data_type === "array") &&
      (filter.operator.name === "Contains" || filter.operator.name === "Does NOT Contain")) ||
    (filter.data_type === "dropdown" &&
      (filter.operator.name === "Contains" || filter.operator.name === "Does NOT Contain"))
  ) {
    filter.isShowTooltip = true;
  }
}

function handleHideTooltip(filter) {
  filter.isShowTooltip = false;
}

function handleOutsideClick() {
  props.dataFilters.forEach((filter) => {
    filter.isShowTooltip = false;
    if (filter.internal_sets.length) {
      filter.internal_sets.forEach((set) => {
        set.isShowTooltip = false;
      });
    }
  });
  showDropdownMenuForFilter.value = null;
}

function toggleShowDropdownMenuForFilter(index) {
  if (showDropdownMenuForFilter.value === index) {
    showDropdownMenuForFilter.value = null;
  } else {
    showDropdownMenuForFilter.value = index;
  }
}

function getTooltipText(filter) {
  const isRelativeDate = filter?.is_relative_date;
  if (!isRelativeDate) return filter.value;

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const relativeDates = getDateRangeQueryForRelativeValue(filter.value?.value);
  let ranges = [];
  for (let item in relativeDates) {
    ranges.push(
      relativeDates[item].toLocaleString("en-us", {
        timeZone: "UTC",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    );
  }

  return `${ranges[0]} \n To ${ranges[1]}`;
}
</script>

<style scoped>
.dashed-and:after {
  content: "";
  position: absolute;
  height: 40px;
  left: 11%;
  border-left: 1px dashed #000000;
  transform: translate(-50%);
}

.dashed-internal-and:after {
  content: "";
  position: absolute;
  height: 100px;
  left: 11%;
  border-left: 1px dashed #000000;
  transform: translate(-50%);
}

.dashed-internal-and-new:after {
  content: "";
  position: absolute;
  height: 55px;
  left: 11%;
  border-left: 1px dashed #000000;
  transform: translate(-50%);
}

.dashed-internal-and-last:after {
  content: "";
  position: absolute;
  height: 62px;
  left: 11%;
  border-left: 1px dashed #000000;
  transform: translate(-50%, -35%);
}

.dashed-internal-and-last-new:after {
  content: "";
  position: absolute;
  height: 38px;
  left: 11%;
  border-left: 0.5px dashed #000000;
  transform: translate(-50%, -30%);
}

.dashed-or:after {
  content: "";
  position: absolute;
  height: 40px;
  left: 25%;
  border-left: 1px dashed #000000;
  transform: translate(-50%);
}

/* Tooltip container */

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 4px 6px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-size: 10px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
