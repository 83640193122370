import { defineStore } from "pinia";
import { useAuthStore, useCrudStore } from "@/stores";
import { IconContact, IconInvestment } from "@/components/icons";

export const useSearchStore = defineStore("searchStore", {
  state: () => ({
    isSearchFocused: false,
    searchTerm: "",
    searchResults: [],
    source_dictionary: {
      investments: {
        path: "/investments",
        algolia_index: "Investments",
        display_field: "_id",
        sub_display_field: "type",
        icon: IconInvestment,
        bg: "bg-custom-lime-green",
      },
      contacts: {
        path: "/contacts",
        algolia_index: "Contacts",
        display_field: "first_name, last_name",
        sub_display_field: "email",
        icon: IconContact,
        bg: "bg-custom-gray-blue",
      },

      // filtering
      searchTermColumns: "",
    },
  }),
  actions: {
    async getSearchResults(
      searchTerm,
      source,
      pageNumber = null,
      limit,
      query = null,
      sort = null
    ) {
      let skip = pageNumber ? (pageNumber - 1) * limit : 0;

      const authStore = useAuthStore();
      var algIndex = this.source_dictionary[source].algolia_index;

      // prepend QA_ for QA environment
      if (import.meta.env.VITE_REALM_APP_ID == "investor_admin_qa-vrfbe") {
        algIndex = "QA_" + algIndex;
      }

      // console.log("searchTerm", searchTerm);
      // console.log("algIndex", algIndex);

      // prepare algolia request
      var requestBody = {
        query: searchTerm,
        index: algIndex,
        skip: skip,
        limit: limit,
      };

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("session_id", authStore.currentUser.session_id);
      myHeaders.append("user_id", authStore.currentUser.id);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      // call request
      const response = await fetch(
        `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/search/`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`Bad response from /search: ${response.statusText}`);
      }

      var response_data = await response.json();

      if (!response_data) {
        return { hits: [], nbHits: 0 };
      } else {
        // console.log("good search response!", response_data);
        return response_data;
      }
    },

    async getTopResults(searchTerm, limit) {
      // holds all results we collect
      var all_results = {
        hits: [],
        nbHits: 0,
      };

      // all possible sources
      var all_sources = Object.keys(this.source_dictionary);

      // make all promises
      let promises = all_sources.map(async (source) => {
        var res = await this.getSearchResults(searchTerm, source, 0, limit, null, null);

        let hits = res.hits;
        for (var hit of hits) {
          hit.source = source;
          all_results.hits.push(hit);
        }

        all_results.nbHits += res.nbHits;
      });

      // Wait for all promises to resolve
      await Promise.all(promises);

      // console.log("all", all_results);

      return all_results;
    },
    async getSingleCollectionResults(
      searchTerm,
      source,
      pageNumber = null,
      limit,
      query = null,
      sort
    ) {
      if (source === "topresults") {
        // console.log("Source is 'topresults'. Returning null.");
        return null;
      }

      const crudStore = useCrudStore();
      const skip = pageNumber ? (pageNumber - 1) * limit : 0;

      // console.log(`Starting search for source: '${source}' with term: '${searchTerm}'`);
      // console.log(`Pagination - Page: ${pageNumber || 1}, Skip: ${skip}, Limit: ${limit}`);

      let baseSearch;
      let coll;
      switch (source) {
        case "contacts":
          baseSearch = getContactsSearch(searchTerm);
          coll = "Contacts";
          break;
        default:
          console.warn(`Unhandled source: '${source}'`);
          return null;
      }

      const baseProject = await getCollectionProjection(source);

      let pipeline = [baseSearch, baseProject];
      if (query) {
        const matchStage = { $match: query };
        pipeline.splice(1, 0, matchStage);
        // console.log("Applied additional query filter:", query);
      }
      if (sort) {
        pipeline.push({ $sort: sort });
        //  console.log("Applied sorting:", sort);
      }
      pipeline.push({ $skip: skip }, { $limit: limit });

      //  console.log("Executing aggregation pipeline:", JSON.stringify(pipeline));

      const results = await crudStore.aggregate(coll, pipeline);

      // console.log(`Retrieved ${results.length} results from '${coll}' collection.`);

      return results;
    },
  },
});

async function getCollectionProjection(source) {
  if (source === "users") {
    //  console.log("Generating projection for 'users' source.");
    const proj = {
      meta: "$$SEARCH_META",
      score: { $meta: "searchScore" },
      user_id: 1,
      name: 1,
      email: 1,
    };

    const finalproj = {
      $project: proj,
    };

    // console.log("Projection for 'users' created:", finalproj);
    return finalproj;
  }

  //  console.log(`Generating projection for '${source}' source.`);
  const results = await getCollectionSchemaFields(source);

  const proj = {
    meta: "$$SEARCH_META",
    score: { $meta: "searchScore" },
    api_10: 1,
    name: 1,
    section_name: 1, // temp fix for landholding collections
    type: 1, // needed for section subtext
    owner_type: 1, // needed for account subtext
  };

  results.forEach((result) => {
    proj[result.field_name] = 1;
  });

  const finalproj = {
    $project: proj,
  };

  // console.log(`Projection for '${source}' created with fields:`, Object.keys(proj));
  return finalproj;
}
function getContactsSearch(searchTerm) {
  // console.log(`Creating search pipeline for term: '${searchTerm}' in 'Contacts'.`);

  //check if search term matches the regex for objectid in mongodb
  const isValidObjectId = (id) => {
    return /^[0-9a-fA-F]{24}$/.test(id);
  };
  let id_search_term = searchTerm;
  if (isValidObjectId(searchTerm)) {
    id_search_term = { $oid: searchTerm };
  }
  const searchPipeline = {
    $search: {
      index: "ContactSearchIndex_V2",
      compound: {
        should: [
          {
            text: {
              query: searchTerm,
              path: "first_name",
              score: {
                boost: { value: 5 },
              },
            },
          },
          {
            autocomplete: {
              query: searchTerm,
              path: "first_name",
              score: {
                boost: { value: 5 },
              },
            },
          },
          {
            text: {
              query: searchTerm,
              path: "last_name",
              score: {
                boost: { value: 5 },
              },
            },
          },
          {
            autocomplete: {
              query: searchTerm,
              path: "last_name",
              score: {
                boost: { value: 5 },
              },
            },
          },
          {
            text: {
              query: searchTerm,
              path: "email",
              score: {
                boost: { value: 20 },
              },
            },
          },
          {
            autocomplete: {
              query: searchTerm,
              path: "email",
              score: {
                boost: { value: 5 },
              },
            },
          },
        ],
        minimumShouldMatch: 1,
      },
    },
  };

  // console.log("Search pipeline created:", searchPipeline);
  return searchPipeline;
}

async function getCollectionSchemaFields(source) {
  //console.log(`Retrieving schema fields for source: '${source}'.`);

  const crudStore = useCrudStore();

  let collection;
  switch (source) {
    case "contacts":
      collection = "Contacts";
      break;
    default:
      console.error("Source not found:", source);
      return [];
  }

  const pipeline = [
    {
      $match: {
        collection_name: collection,
        show_in_search_results: true,
      },
    },
    {
      $sort: {
        search_result_order: 1,
      },
    },
  ];

  //console.log("Executing pipeline for schema fields retrieval:", pipeline);
  const schemaFields = await crudStore.aggregate("Schema", pipeline);
  // console.log(`Retrieved ${schemaFields.length} schema fields for '${collection}'.`);

  return schemaFields;
}
