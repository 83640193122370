<template>
  <Modal :open="open" @close-modal="$emit('close-modal')" size="large" hideModalTabs>
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-custom-navy bg-opacity-20 p-1"
        >
          <Icon name="FileText" class="h-6 w-6 text-custom-navy" :stroke-width="2" />
        </div>
        <p class="font-medium mt-1 text-lg">Upload Documents</p>
      </div>
    </template>

    <template v-slot:modal-body>
      <div class="p-4 md:px-10 flex flex-col h-full">
        <div class="flex items-center">
          <BaseInput
            type="select"
            v-model="fileCategory"
            required
            size="large"
            placeholder="Select Document Type"
            label="Document Type"
            showFullText
            :options="categoryOptions"
            class="!w-64 mb-4"
          />
          <div class="px-4 flex items-center gap-2 text-lighter mt-3">
            <label for="compliance"> Visible to Compliance? </label>
            <input
              id="compliance"
              type="checkbox"
              v-model="visibileToCompliance"
              class="accent-secondary-6 h-5 w-5 mb-1 cursor-pointer"
            />
          </div>
        </div>

        <div v-if="generalFiles.length" class="my-4">
          <ul class="flex flex-col gap-2">
            <li
              class="flex w-full items-center justify-between rounded-md border border-dashed border-primary-3 bg-primary-6 bg-opacity-10 px-5 py-1.5"
              v-for="(file, index) in generalFiles"
              :key="index"
            >
              <div class="flex items-center gap-2 mr-2 truncate">
                <Icon name="FileUp" class="h-5 w-5 text-cTeal" :stroke-width="2" />
                <p class="truncate">
                  {{ file.name }}
                </p>
              </div>
              <Button icon size="small" variant="default-gray-outlined">
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-3.5 w-3.5 text-functional-error-default"
                    @click="generalFiles.splice(index, 1)"
                    :stroke-width="3"
                  />
                </template>
              </Button>
            </li>
          </ul>
        </div>
        <FileDropper @files-selected="handleFiles" />
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button
          size="large"
          :disabled="!generalFiles.length || !fileCategory"
          label
          @click="handleUploadDocs"
        >
          <template #label>Upload</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from "vue";

import { Button, Icon, Modal, BaseInput, FileDropper } from "@/components";

const emit = defineEmits(["close-modal", "upload-docs"]);

const fileCategory = ref("");
const visibileToCompliance = ref(false);
const generalFiles = ref([]);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  categoryOptions: {
    type: Array,
    required: true,
  },
});

function handleFiles(files) {
  generalFiles.value = [...generalFiles.value, ...files];
}

function handleUploadDocs() {
  emit("upload-docs", generalFiles.value, fileCategory.value, visibileToCompliance.value);
  emit("close-modal");
  setTimeout(() => {
    generalFiles.value = [];
    fileCategory.value = "";
  }, 100);
}
</script>
