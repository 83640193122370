<template>
  <div
    v-if="
      !titleTransferStore.investment_being_created &&
      !titleTransferStore.show_investment_completion_screen
    "
  >
    <div class="flex justify-between items-center">
      <Button
        @click="titleTransferStore.currentStep--"
        :disabled="titleTransferStore.currentStep == 0"
      >
        <template #label>Back</template>
      </Button>
      <Button @click="next" :disabled="nextIsDisabled">
        <template #label>{{ button_text }}</template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

import { useTitleTransferStore, useSchemaStore, useRecordDetailStore } from "@/stores";
import { Button } from "@/components";
import { STATES } from "@/constants";
import { useRouter } from "vue-router";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();
const recordDetailStore = useRecordDetailStore();
const router = useRouter();

const button_text = ref("Next");

async function next() {
  const current_step_name = titleTransferStore.investmentSteps[titleTransferStore.currentStep];

  if (current_step_name == "Complete Split Investment") {
    recordDetailStore.pageLoaderMessage = "Splitting...";
    titleTransferStore.transfer_drawer_open = false;
    titleTransferStore.investment_being_created = true;
    let contact_id = await titleTransferStore.completeSplitInvestment();
    router.push(`/contacts?recordId=${contact_id}`);
    titleTransferStore.investment_being_created = false;
  } else if (current_step_name == "Complete Owner Transfer") {
    recordDetailStore.pageLoaderMessage = "Transferring...";
    titleTransferStore.transfer_drawer_open = false;
    titleTransferStore.investment_being_created = true;
    let investment_id = await titleTransferStore.completeOwnerTransferInvestment();
    router.push(`/investments?recordId=${investment_id}`);
    titleTransferStore.investment_being_created = false;
  } else if (current_step_name == "Complete Title Transfer") {
    recordDetailStore.pageLoaderMessage = "Transferring...";
    titleTransferStore.transfer_drawer_open = false;
    titleTransferStore.investment_being_created = true;
    let investment_id = await titleTransferStore.completeTransferInvestment();
    router.push(`/investments?recordId=${investment_id}`);
    titleTransferStore.investment_being_created = false;
  } else {
    titleTransferStore.currentStep++;
  }
}

watch(
  () => titleTransferStore.currentStep,
  () => {
    const current_step_name = titleTransferStore.investmentSteps[titleTransferStore.currentStep];
    if (
      current_step_name == "Complete Title Transfer" ||
      current_step_name == "Complete Owner Transfer" ||
      current_step_name == "Complete Split Investment"
    ) {
      button_text.value = "Complete";
    } else {
      button_text.value = "Next";
    }
  }
);

const validateField = (fieldValue, regex) => {
  if (!fieldValue) {
    return false;
  }
  if (regex && !regex.test(fieldValue)) {
    return false;
  }
  return true;
};

const isFieldValid = (currentContact, field, regex) => {
  if (field.belongs_to_nested_object) {
    const nestedValue = currentContact[field.nested_object_name]?.[field.field_name];
    if (!validateField(nestedValue, regex)) {
      return false;
    }
  } else {
    const fieldValue = currentContact[field.field_name];
    if (!validateField(fieldValue, regex)) {
      return false;
    }
  }
  return true;
};

const validateContactInfo = (currentContact, schema) => {
  for (const group of schema) {
    for (const field of group.fields) {
      if (field.is_required) {
        let regex;
        switch (field.field_name) {
          case "phone":
            regex = /^\(\d{3}\) \d{3}-\d{4}$/;
            break;
          case "email":
            regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            break;
          case "ssn":
            regex = /^\d{3}-\d{2}-\d{4}$/;
            break;
          case "ein":
            regex = /^\d{2}-\d{7}$/;
            break;
          // case "state":
          //   if (!STATES.includes(currentContact[field.field_name])) {
          //     return true;
          //   }
          //   continue;
          default:
            regex = null;
        }
        if (!isFieldValid(currentContact, field, regex)) {
          return true;
        }
      }
    }
  }
  return false;
};

const nextIsDisabled = computed(() => {
  const current_step_name = titleTransferStore.investmentSteps[titleTransferStore.currentStep];

  switch (current_step_name) {
    case "Split Investment": {
      return titleTransferStore.investment_split_is_invalid;
    }
    case "Complete Split Investment": {
      return titleTransferStore.uploaded_split_subagreeement ? false : true;
    }
    case "Transferor Owner": {
      return titleTransferStore.owner_transferor_is_invalid;
    }
    case "Transferee Owner": {
      return titleTransferStore.transferee_is_invalid;
    }
    case "First Transfer Step": {
      return titleTransferStore.first_og_title_transfer_step_is_invalid;
    }
    case "Complete Title Transfer": {
      return titleTransferStore.last_og_title_transfer_step_is_invalid;
    }
    case "Complete Owner Transfer": {
      return (
        !titleTransferStore.reason_for_transfer ||
        (titleTransferStore.reason_for_transfer === "Other" &&
          !titleTransferStore.other_reason_for_transfer) ||
        !titleTransferStore.uploaded_owner_transfer_form
      );
    }

    case "Select Joint Contact": {
      if (titleTransferStore.add_joint_contact) {
        return false;
      } else {
        return Object.keys(titleTransferStore.selected_joint_contact).length === 0;
      }
    }
    case "Select Entity Account": {
      if (titleTransferStore.add_entity_account) {
        return false;
      } else {
        return Object.keys(titleTransferStore.selected_entity_account).length === 0;
      }
    }
    case "Joint Info": {
      const isRegD = titleTransferStore.current_offering.type === "Reg D";
      const jointInfoSchema = isRegD
        ? schemaStore.accredited_joint_contact_personal_info_schema
        : schemaStore.non_accredited_joint_contact_personal_info_schema;
      return (
        validateContactInfo(titleTransferStore.joint_contact_template, jointInfoSchema) ||
        titleTransferStore.joint_ssn_is_invalid ||
        titleTransferStore.joint_email_is_invalid
      );
    }

    case "Entity Info": {
      let validEntityInfo = validateContactInfo(
        titleTransferStore.entity_template,
        schemaStore.entity_information
      );

      let is_valid_ben_owner_info = !(
        titleTransferStore.entity_template.owner_is_beneficial_owner ||
        titleTransferStore.entity_template.beneficial_owners?.length > 0
      );

      let is_disabled =
        validEntityInfo || is_valid_ben_owner_info || titleTransferStore.entity_ein_is_invalid;

      return is_disabled;
    }

    default:
      return false;
  }
});
</script>
