<template>
  <div class="mt-6">
    <Table
      v-if="filteredRolloverInvestments.length > 0"
      :tableData="filteredRolloverInvestments"
      :headers="createInvestmentStore.rollover_table_headers"
      :rounded_bottom="false"
      class="w-full overflow-auto border"
      :fieldsWithCustomStyles="['checkbox']"
      :selectAllIsDisabled="true"
    >
      <template v-slot:checkbox="entry">
        <div class="flex max-w-min items-center space-x-value-8 px-4">
          <input
            @click="select(entry)"
            :checked="createInvestmentStore.selected_rollover_investment._id == entry.row._id.value"
            type="checkbox"
            class="accent-secondary-6"
          />
        </div>
      </template>
    </Table>
    <div v-else>No investments available for rollover</div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Table } from "@/components";
import { useCreateInvestmentStore } from "@/stores";

const createInvestmentStore = useCreateInvestmentStore();

const filteredRolloverInvestments = computed(() => {
  return createInvestmentStore.rollover_table_investments.filter((investment) => {
    const rolloverAvailable = investment.rollover_available.value;
    const investmentAmount = parseFloat(createInvestmentStore.investment_amount.replace(/,/g, ""));
    return rolloverAvailable > investmentAmount;
  });
});
function select(entry) {
  createInvestmentStore.selected_rollover_investment._id = entry.row._id.value;
}
</script>
