<template>
  <Modal
    :open="open"
    @close-modal="$emit('close-modal')"
    customHeight="h-value-15 md:h-value-23 lg:h-160"
    customWidth="lg:w-240"
    hideModalTabs
  >
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-custom-navy bg-opacity-20 p-1"
        >
          <Icon name="File" class="h-6 w-6 text-custom-navy" :stroke-width="2" />
        </div>
        <div class="font-bold text-custom-navy-2">
          Document - {{ file.file_name?.value ? file.file_name?.value : file.file_name }}
        </div>
      </div>
    </template>

    <template v-slot:modal-body>
      <div>
        <div
          v-if="
            file.file_type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          "
        >
          <div
            class="m-4 rounded border border-platform-outlines bg-white p-4"
            v-html="docContent"
          ></div>
        </div>

        <object
          v-else
          :data="file.file_url"
          :type="file.file_type"
          width="100%"
          height="825"
          class="rounded"
        ></object>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Close</p></template>
        </Button>
        <Button size="large" label @click="handleDownload(), $emit('close-modal')">
          <template #label><p>Download</p></template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, watch } from "vue";
import mammoth from "mammoth";

import { Button, Icon, Modal } from "@/components";

import useFileUtility from "@/composables/useFileUtility";

const { downloadFile_R2 } = useFileUtility();
const emit = defineEmits(["close-modal"]);

const docContent = ref("");

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  file: {
    type: [Object, null],
    required: true,
  },
});

async function handleDownload() {
  await downloadFile_R2(props.file);
}

watch(
  () => props.file,
  async (newVal) => {
    if (
      props.file.file_type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
      newVal
    ) {
      const response = await fetch(newVal);
      const arrayBuffer = await response.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      docContent.value = result.value;
    }
  }
);
</script>
