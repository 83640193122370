<template>
  <div class="px-6 py-4 h-full">
    <div>
      <component
        :is="
          getConversionStepComponent(
            investmentStore.conversionSteps[investmentStore.currentConversionStep]
          )
        "
        :key="investmentStore.currentConversionStep"
      />
    </div>
  </div>
</template>

<script setup>
import { watch } from "vue";

import { useInvestmentStore } from "@/stores";
import SelectConversionType from "./SelectConversionType.vue";
import SelectConversionMethod from "./SelectConversionMethod.vue";
import CompleteConversion from "./CompleteConversion.vue";
import NewInvestmentDetails from "./NewInvestmentDetails.vue";
import CollectAccreditedInfoForm from "./CollectAccreditedInfoForm.vue";

const investmentStore = useInvestmentStore();

const stepComponents = {
  "Select Conversion Type": SelectConversionType,
  "Select Conversion Method": SelectConversionMethod,
  "Complete Conversion": CompleteConversion,
};

watch(
  () => [
    investmentStore.conversionDetails.selectedOffering,
    investmentStore.selectedConversionMethod,
  ],
  () => {
    updateConversionSteps();
  }
);

const getConversionStepComponent = (stepName) => {
  return stepComponents[stepName] || null;
};

function updateConversionSteps() {
  // Default steps
  let newSteps = ["Select Conversion Type", "Select Conversion Method", "Complete Conversion"];

  // Add "Collect Accredited Information" if necessary
  if (
    !investmentStore.hasAccreditedInvestments &&
    investmentStore.conversionDetails.selectedOffering?.offering?.type === "Reg D"
  ) {
    newSteps.splice(2, 0, "Collect Accredited Infomation");
    stepComponents["Collect Accredited Infomation"] = CollectAccreditedInfoForm;
  } else {
    delete stepComponents["Collect Accredited Infomation"];
  }

  // Add "Split Investments" if necessary
  if (investmentStore.selectedConversionMethod?.id === 2) {
    newSteps.splice(newSteps.length - 1, 0, "Split Investments");
    stepComponents["Split Investments"] = NewInvestmentDetails;
  } else {
    delete stepComponents["Split Investments"];
  }

  // Update the store with the new steps
  investmentStore.conversionSteps = newSteps;
}
</script>
