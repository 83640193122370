<template>
  <DocPreviewModal
    :open="previewModalOpen"
    @close-modal="previewModalOpen = false"
    :fileName="file_name"
    :fileContent="file_data"
    :fileType="file_type"
  />

  <div class="flex flex-col gap-8 mb-4">
    <div>
      <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
        Step {{ createInvestmentStore.currentStep + 1 }} : Accreditation Details
      </div>
    </div>
    <div class="pb-8 border-b border-platform-outlines">
      <div class="font-bold">Accreditation Details</div>

      <div class="flex h-6 items-center mt-6">
        <input
          type="checkbox"
          v-model="createInvestmentStore.current_owner_contact.are_you_an_accredited_investor"
          id="select-all"
          class="accent-secondary-6 h-5 w-5"
        />

        <div class="flex items-center font-semibold text-light ml-4">
          Yes, I have an annual income greater than $200k, joint income greater than $300k, or net
          worth greater than $1M (excluding primary residence)
        </div>
      </div>
    </div>

    <div class="pb-8 border-b border-platform-outlines">
      <div class="font-semibold text-lg">
        Upload Accreditation Documents (PDF, JPG, and PNG only)
      </div>
      <div class="my-2">
        Regulation D offerings require us to validate accreditation documentation which could be:
      </div>
      <ul class="list-disc pl-5 text-gray-500">
        <li>
          A letter from a CPA, Attorney, or Financial professional with an active Series 7, Series
          65, or Series 82
        </li>
        <li>
          Proof of assets worth more than $1M by reviewing specific types of documentation dated
          within the prior three months, such as bank statements, brokerage statements, certificates
          of deposit, tax assessments, and a credit report from at least one of the nationwide
          consumers reporting agencies and obtaining a written representation from the investor
        </li>
        <li>
          Individual - proof of income for 2 years of at least 200k by reviewing copies of any
          Internal Revenue Service form that reports income, such as Form W-2, Form 1099, Schedule
          K-1 of Form 1065, and a filed Form 1040
        </li>
        <li>
          Joint - Proof of income for 2 years of at least 300k by reviewing copies of any Internal
          Revenue Service form that reports income, such as Form W-2, Form 1099, Schedule K-1 of
          Form 1065, and a filed Form 1040
        </li>
      </ul>
    </div>

    <div>
      <div v-if="!files_are_uploading">
        <input @change="handleUpload" ref="fileInputRef" type="file" multiple hidden />

        <div
          class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0 bg-light bg-opacity-20"
          :class="dragging ? 'border-blue-500 bg-blue-100' : 'bg-light bg-opacity-20'"
          @drop="drop"
          @dragover.prevent="dragging = true"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <p>Drag the files here or click upload</p>

          <div class="mt-4">
            <Button @click="chooseFiles" label>
              <template #label>Upload Files</template>
            </Button>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0 bg-white"
        >
          <LoadingSpinner :show="files_are_uploading" hideOverlay position="relative" />
        </div>
      </div>

      <div class="mt-4 flex flex-col gap-2">
        <div
          v-for="(file, index) in createInvestmentStore.accreditation_documents"
          :key="index"
          class="flex items-center border p-4 rounded-md shadow-sm"
        >
          <div class="flex-grow">
            <div class="text-sm">{{ file.file_name }}</div>
            <div class="text-sm">{{ file.r2_key }}</div>
          </div>
          <div v-if="confirm_file_to_remove == file._id" class="flex gap-1">
            <div class="text-sm max-w-60 text-functional-error-default">
              Are you sure you want to delete this file? This will remove the file from this
              contacts Accreditation Documents.
            </div>

            <div class="flex gap-1">
              <Button
                @click="permanentlyDeleteFile(index, file)"
                icon
                variant="default-gray-outlined"
              >
                <template #icon><IconCheckmark class="fill-progress-bar-green" /></template>
              </Button>
              <Button @click="cancelDelete()" icon variant="default-gray-outlined">
                <template #icon><IconX class="fill-functional-error-default" /></template>
              </Button>
            </div>
          </div>
          <div v-else class="flex gap-1">
            <Button @click="previewFile(file)" icon variant="default-gray-outlined">
              <template #icon><IconVisibility /></template>
            </Button>
            <Button
              id="base-input"
              @click="downloadFile(file)"
              variant="default-gray-outlined"
              icon
            >
              <template #icon><IconFileDownload /></template>
            </Button>
            <Button
              @click="removeFileConfirmation(file)"
              icon
              label
              variant="default-gray-outlined"
            >
              <template #icon>
                <Icon
                  name="Trash"
                  class="h-4.5 w-4.5 stroke-functional-error-default"
                  :stroke-width="2"
              /></template>
              <template #label>Delete</template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useCreateInvestmentStore } from "@/stores";
import { Button, DocPreviewModal, LoadingSpinner } from "@/components";
import {
  IconX,
  IconDelete,
  IconVisibility,
  IconCheckmark,
  IconFileDownload,
} from "@/components/icons";
import { Icon } from "@/components";
import useFileUtility from "@/composables/useFileUtility";
import mammoth from "mammoth";
const { downloadFile_R2, fetchFileForPreview_R2, uploadFile_R2, deleteFile_R2 } = useFileUtility();

const createInvestmentStore = useCreateInvestmentStore();

const fileInputRef = ref(null);
const dragging = ref(false);

let confirm_file_to_remove = ref("");
const previewModalOpen = ref(false);
const file_data = ref("");
const file_name = ref("");
const file_type = ref("");
const files_are_uploading = ref(false);

async function previewFile(file) {
  file_name.value = file.file_name;
  file_type.value = file.file_type;
  if (file.file_type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    let fetched_file = await fetchFileForPreview_R2(file);
    const response = await fetch(fetched_file);
    const arrayBuffer = await response.arrayBuffer();
    const result = await mammoth.convertToHtml({ arrayBuffer });
    file_data.value = result.value;
  } else {
    file_data.value = await fetchFileForPreview_R2(file);
  }
  previewModalOpen.value = true;
}
async function downloadFile(file) {
  await downloadFile_R2(file);
}
function removeFileConfirmation(file) {
  confirm_file_to_remove.value = file._id;
}
async function permanentlyDeleteFile(index, file) {
  confirm_file_to_remove.value = "";
  createInvestmentStore.accreditation_documents.splice(index, 1);
  await deleteFile_R2(file);
}
function cancelDelete() {
  confirm_file_to_remove.value = "";
}

const chooseFiles = () => {
  fileInputRef.value.value = null;
  fileInputRef.value.click();
};

const handleUpload = async (e) => {
  files_are_uploading.value = true;
  const files = Array.from(e.target.files);
  if (files.length > 0) {
    for (const file of files) {
      await handleUploadFinal(file);
    }
    await createInvestmentStore.fetchExistingAccreditationDocs();
  }
  files_are_uploading.value = false;
};

async function handleUploadFinal(file) {
  let newFileDocument = await uploadFile_R2({
    file: file,
    type: "Accreditation",
    existingKey: null,
    details: {
      file_category: "Accreditation Support",
      visible_to_compliance: false,
      user_id: createInvestmentStore.current_owner_contact.user_id,
      contact_id: { $oid: createInvestmentStore.current_owner_contact._id },
      account_id: { $oid: createInvestmentStore.current_owner_contact.account_id },
    },
  });
}

const drop = async (e) => {
  files_are_uploading.value = true;
  e.preventDefault();
  dragging.value = false;
  const files = Array.from(e.dataTransfer.files);
  for (const file of files) {
    await handleUploadFinal(file);
  }
  await createInvestmentStore.fetchExistingAccreditationDocs();
  files_are_uploading.value = false;
};
</script>
