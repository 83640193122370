<template>
  <div>
    <div v-if="investmentStore.accreditedInfoTabs?.length">
      <CustomTabs
        :tabList="investmentStore.accreditedInfoTabs"
        @set-active-tab="activeTab = $event"
        :activeTab="activeTab"
      />
    </div>
    <div class="mt-4">
      <div v-if="activeTab === 'Personal'" class="flex flex-col gap-4 pb-6">
        <Form
          :fields="contactAccreditedSpecificSchema"
          :record="investorStore.contact"
          header="Accredited Personal Information"
          type="personal"
          :isFormValid="isFormValid"
          isSmall
        />
        <div class="border-b border-platform-outlines my-4"></div>
        <p class="font-bold">Suitability Questionnaire</p>
        <div
          v-for="(object, index) of QUESTIONNAIRE_QUESTIONS_ANSWERS"
          :key="object"
          class="flex flex-col gap-2 relative"
        >
          <div class="font-bold text-sm md:text-base">
            {{ index + 1 + ". " + object.question }}
          </div>
          <div
            v-for="(answer, answerIndex) of object.answers"
            :key="answer"
            class="w-full text-left text-gray-700"
          >
            <div class="w-full flex items-center text-sm mb-1 gap-2 lg:gap-2">
              <input
                :id="answer + answerIndex"
                type="radio"
                :name="object.question"
                v-model="investorStore.contact.suitability_info[object.field_name]"
                :value="answer"
                :disabled="!isEditMode"
                class="hidden"
              />
              <div
                class="min-w-5 min-h-5 rounded-full border-2 flex items-center justify-center cursor-pointer"
                :class="{
                  'border-gray-300':
                    !isEditMode ||
                    investorStore.contact.suitability_info[object.field_name] !== answer,
                  'border-neutral-gray-4': !isEditMode,
                  'border-secondary-6 bg-secondary-6':
                    isEditMode &&
                    investorStore.contact.suitability_info[object.field_name] === answer,
                }"
                @click="
                  isEditMode && (investorStore.contact.suitability_info[object.field_name] = answer)
                "
              >
                <div
                  class="min-w-1.5 min-h-1.5 rounded-full"
                  :class="{
                    'bg-primary-6':
                      isEditMode &&
                      investorStore.contact.suitability_info[object.field_name] === answer,
                    'bg-neutral-gray-5':
                      !isEditMode &&
                      investorStore.contact.suitability_info[object.field_name] === answer,
                  }"
                ></div>
              </div>
              <label
                :for="answer + answerIndex"
                @click="
                  isEditMode && (investorStore.contact.suitability_info[object.field_name] = answer)
                "
                class="text-sm lg:text-base text-lighter cursor-pointer"
                :class="{ 'text-gray-300': !isEditMode }"
              >
                {{ answer }}
              </label>
            </div>
            <div
              v-if="
                index === 2 &&
                question3Error &&
                ((answer === 'Conservative' &&
                  investorStore.contact.suitability_info?.risk_willingness === 'Conservative') ||
                  (answer === 'Moderate' &&
                    investorStore.contact.suitability_info?.risk_willingness === 'Moderate'))
              "
              class="flex space-x-2 items-center mt-2"
            >
              <Icon
                name="Flag"
                class="h-6 w-6 text-functional-error-default animate-bounce"
                :stroke-width="2"
              />
              <p class="text-functional-error-default text-sm font-medium">
                Your current selection is not appropriate for this investment. Regardless of the
                specific investment type, be it equity or fixed income, the SEC considers all
                Regulation D offerings to be speculative. You must select “Significant” or
                “Speculative” to continue.
              </p>
            </div>
            <div
              v-else-if="
                otherQuestionsError &&
                ((index === 10 &&
                  answer === 'No' &&
                  investorStore.contact.suitability_info.significant_liquid_net_worth === 'No') ||
                  (index === 11 &&
                    answer === 'No' &&
                    investorStore.contact.suitability_info.risky_investment_understanding ===
                      'No') ||
                  (index === 13 &&
                    answer === 'No' &&
                    investorStore.contact.suitability_info.self_responsibility === 'No'))
              "
              class="flex space-x-2 items-center mt-2"
            >
              <Icon
                name="Flag"
                class="h-6 w-6 text-functional-error-default animate-bounce"
                :stroke-width="2"
              />
              <p class="text-red-500 font-medium text-sm">
                Your current selection is not appropriate for this investment. You must select “Yes”
                to continue.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Form
        v-if="activeTab === 'Joint'"
        :fields="contactAccreditedSpecificSchema"
        :record="investorStore.active_joint_contact"
        header="Accredited Joint Information"
        type="personal"
        :isFormValid="isFormValid"
        isSmall
      />
      <div v-if="activeTab === 'Entity'">
        <div v-for="beneficialOwner in beneficialOwnersForActiveEntity">
          <p class="font-bold">
            Accredited Beneficial Owner Information -
            {{ `${beneficialOwner.first_name} ${beneficialOwner.last_name}` }}
          </p>
          <Form
            :fields="
              contactAccreditedSpecificSchema.filter(
                (f) => f.field_name !== 'employer' && f.field_name !== 'occupation'
              )
            "
            :record="beneficialOwner"
            type="beneficial owner"
            :isFormValid="isFormValid"
            isSmall
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// If type individual - we'll need ID info, suitability questionnaire, accreditation info, Esignature info
// if type joint - we'll need personal + joint id info + suitability + accreditation + esignature
// if entity we'll need personal id_info + suitability info + accreditation info + esignature
// if IRA we'll need personal id_info + suitability info + accreditation info + esignature

import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep } from "lodash";

import { useInvestmentStore, useInvestorStore, useSchemaStore } from "@/stores";
import { QUESTIONNAIRE_QUESTIONS_ANSWERS, INCOME_FIELDS } from "@/constants";
import { Form, LoadingSpinner, CustomTabs, Icon } from "@/components";
import { getObjectDifferences, validateField } from "@/utilities";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const schemaStore = useSchemaStore();

const activeTab = ref("Personal");
const beneficialOwnersForActiveEntity = ref([]);

onMounted(async () => {
  const type = investmentStore.selectedInvestmentsForConversion[0].type;
  const IRA_types = [
    "Traditional IRA",
    "Roth IRA",
    "SEP IRA",
    "Simple IRA",
    "Solo 401(k)",
    "Self-Directed Roth IRA",
    "KEOGH IRA",
    "Qualified RecordKeeping IRA",
    "HSA",
  ];
  const selectedInvestmentForConversion = investmentStore.selectedInvestmentsForConversion[0];
  switch (type) {
    case "Individual":
    case IRA_types.includes(type):
      investmentStore.accreditedInfoTabs = ["Personal"];
      break;
    case "Joint":
      investmentStore.accreditedInfoTabs = ["Personal", "Joint"];
      if (selectedInvestmentForConversion?.joint_contact_id) {
        investorStore.active_joint_contact = investorStore.allContacts.find(
          (c) =>
            c._id?.toString() === selectedInvestmentForConversion.joint_contact_id?._id?.toString()
        );
      }
      break;
    case "Entity":
      investmentStore.accreditedInfoTabs = ["Personal", "Entity"];
      if (selectedInvestmentForConversion?.entity_account_id) {
        investorStore.active_entity_account = investorStore.entity_accounts.find(
          (e) => e._id?.toString() === selectedInvestmentForConversion.entity_account_id?.toString()
        );
      }
      beneficialOwnersForActiveEntity.value = investorStore.allContacts.filter((c) => {
        const stringEntityIds = c.entity_ids?.map((id) => id.toString()) || [];
        return (
          stringEntityIds?.includes(investorStore.active_entity_account?._id?.toString()) &&
          !c.is_owner
        );
      });
      break;
    default:
      investmentStore.accreditedInfoTabs = ["Personal"];
  }
  window.scrollTo(0, 0);
});

const contactAccreditedSpecificSchema = computed(() => {
  return schemaStore.accredited_owner_contact_personal_info_schema.flatMap((sGroup) => {
    return sGroup.fields.filter((f) => f.accredited_only);
  });
});

const isFormValid = computed(() => {
  return true;
  // if (
  //   !contactAccreditedSpecificSchema.value ||
  //   contactAccreditedSpecificSchema.value.length === 0
  // ) {
  //   return false;
  // }

  // const fieldsToValidate = contactAccreditedSpecificSchema.value.flatMap((schema) =>
  //   schema.fields.filter((f) => f.is_required)
  // );

  // if (investorStore.hasSpecialError) {
  //   return false;
  // }

  // const isValid = fieldsToValidate.every((field) => {
  //   const value =
  //     field.belongs_to_nested_object && field.nested_object_name
  //       ? investorStore.contact[field.nested_object_name]?.[field.field_name]
  //       : investorStore.contact[field.field_name];

  //   return validateField(field.field_name, value);
  // });

  // return isValid;
});
</script>
