<template>
  <div class="pb-8 border-b border-platform-outlines max-w-full">
    <div class="font-bold">Email Preferences</div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <div class="block text-sm font-semibold text-lighter">
          Would you like to notify this investor that an investment has been created?
        </div>
        <BaseInput
          v-model="titleTransferStore.notify_user_of_created_investment"
          type="boolean"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
      <div>
        <div class="block text-sm font-semibold text-lighter">
          Would you like to notify this investor when this investment is settled?
        </div>
        <BaseInput
          v-model="titleTransferStore.send_settled_email"
          type="boolean"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useTitleTransferStore } from "@/stores";
import { BaseInput } from "@/components";
const titleTransferStore = useTitleTransferStore();
</script>
