import { defineStore } from "pinia";
import * as Realm from "realm-web";
import { watch } from "vue";

import { generateAUniqueID } from "@/utilities";
import { useAuthStore, useCrudStore } from "@/stores";
// import { STATES } from "@/constants";
import states from "@/constants/states";

const {
  BSON: { ObjectId },
} = Realm;

export const useUIStore = defineStore("UIStore", {
  state: () => ({
    //tabs
    tabs: [],
    //alerts
    alertList: [], // the animated alerts/notifications
    //general
    isLoading: false,
    isDrawerLoading: false,
    //table
    sortAscending: null,
    sortHeader: null,
    current_full_path: "",
    //config
    all_color_options: [],
    all_icon_options: [],
    all_collection_options: [],
    color_map: {},
    headerNuggets: [],
    // filter
    isEditFilterModalOpen: false,
    isEditFilterModalCollapsed: false,
    operators: [
      { name: "Equal To", id: 1 },
      { name: "NOT Equal To", id: 2 },
      { name: "Greater Than", id: 3 },
      { name: "Greater Than or Equal To", id: 4 },
      { name: "Less Than", id: 5 },
      { name: "Less Than or Equal To", id: 6 },
      { name: "Is Blank", id: 7, no_self_ref: true },
      { name: "Is NOT Blank", id: 8, no_self_ref: true },
      { name: "Contains", id: 9, no_self_ref: true },
      { name: "Does NOT Contain", id: 10, no_self_ref: true },
      { name: "Includes", id: 11, no_self_ref: true },
      { name: "Does NOT Include", id: 11, no_self_ref: true },
    ],
  }),
  getters: {},
  actions: {
    async createNewTab({ name, fullPath, active = true, add_to_front = false }) {
      const authStore = useAuthStore();
      const crudStore = useCrudStore();

      //check if we already have a tab with this path
      const existingTab = this.tabs.find((t) => t.path === fullPath);
      console.log("existingTab", existingTab);
      if (existingTab) {
        if (existingTab.path == this.current_full_path) {
          existingTab.is_active = true;
        }
        return;
      }

      const tabDocumentToAdd = {
        id: generateAUniqueID(),
        user_id: authStore.currentUser.id,
        is_pinned: false,
        pin_order: null,
        path: fullPath,
        name: name,
        record_id: ObjectId(fullPath.slice(-24)),
      };

      const tabToAddToLocalState = {
        ...tabDocumentToAdd,
        is_active: active,
        is_show_options: false,
      };

      if (add_to_front) {
        // Find the first unpinned tab index
        const firstUnpinnedIndex = this.tabs.findIndex((t) => !t.is_pinned);

        if (firstUnpinnedIndex > 0) {
          // I want to but the new tab inbetween the last pinned tab and the first unpinned tab
          this.tabs.splice(firstUnpinnedIndex, 0, tabToAddToLocalState);
        } else {
          // Log that no unpinned tab was found, so adding to the very front
          this.tabs.unshift(tabToAddToLocalState);
        }
      } else {
        // Log the action of adding the tab to the end
        this.tabs.push(tabToAddToLocalState);
      }

      const updatedTimestamp = {
        updated_date: new Date(),
        updated_by_id: authStore.currentUser.id,
        updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
      };
      const createdTimestamp = {
        created_date: new Date(),
        created_by_id: authStore.currentUser.id,
        created_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
      };

      await crudStore.updateOne(
        "UserTabs",
        { user_id: authStore.currentUser.id },
        {
          $push: { tabs: tabDocumentToAdd },
          $set: { ...updatedTimestamp },
          $setOnInsert: { ...createdTimestamp },
        },
        true
      );
    },
    setupAlertWatcher() {
      watch(
        () => this.alertList,
        () => {
          const nonPersistentAlerts = this.alertList.filter((alert) => !alert.does_persist);

          // If there are non-persistent alerts, schedule their removal
          if (nonPersistentAlerts.length > 0) {
            this.scheduleAlertRemoval(nonPersistentAlerts);
          }
        },
        { deep: true }
      );
    },
    scheduleAlertRemoval(alerts) {
      // Cancel any existing removal timer
      clearTimeout(this.removalTimer);

      this.removalTimer = setTimeout(() => {
        // Start removing alerts, last to first
        for (let i = alerts.length - 1; i >= 0; i--) {
          setTimeout(
            () => {
              this.removeNotification(alerts[i].id);
            },
            (alerts.length - 1 - i) * 500
          ); // 500ms-second stagger
        }
      }, 3000); // 3-second delay before starting removal
    },
    removeNotification(id) {
      const alertIndex = this.alertList.findIndex((alert) => alert.id === id);
      if (alertIndex !== -1) {
        this.alertList[alertIndex].show = false;
      }

      setTimeout(() => {
        this.alertList = this.alertList.filter((alert) => alert.id !== id);
      }, 200);
    },
    async animateNotificationAlert(doc) {
      const {
        type = "success",
        icon = "BadgeCheck",
        message = "Default Message For Notification",
        subText = "",
        is_read = false,
        does_persist = false,
        action = null,
      } = doc;

      const alert_notification = {
        type,
        icon,
        message,
        subText,
        is_read,
        does_persist,
        action,
        id: Date.now(),
        show: false,
      };
      this.alertList.push(alert_notification);

      // This is to get the animation to work as expected
      setTimeout(() => {
        const index = this.alertList.findIndex((alert) => alert.id === alert_notification.id);
        if (index !== -1) {
          this.alertList[index].show = true;
        }
      }, 0);
    },
    transformRecords(records) {
      if (records) {
        return records.map((record) => {
          const transformedRecord = {};

          Object.keys(record).forEach((key) => {
            transformedRecord[key] = {
              value: record[key],
              clickable: false,
            };
          });

          transformedRecord.actions = { cta: true, hide_sort: true };

          return transformedRecord;
        });
      }
    },
    formatFetchedRecordFromCF(record, schema) {
      schema.forEach((fieldSchema) => {
        let fieldName = fieldSchema.field_name;
        let fieldType = fieldSchema.field_type;
        let belongsToNestedObject = fieldSchema.belongs_to_nested_object || false;
        let nestedObjectName = fieldSchema.nested_object_name || "";

        // Determine the target value and location
        let targetValue;
        if (belongsToNestedObject && nestedObjectName) {
          if (!record[nestedObjectName]) {
            record[nestedObjectName] = {};
          }
          targetValue = record[nestedObjectName][fieldName];
        } else {
          targetValue = record[fieldName];
        }

        // Transform based on field type
        switch (fieldType) {
          // case "string":
          // case "dropdown":
          //   if (
          //     typeof targetValue !== "string" &&
          //     targetValue !== undefined &&
          //     targetValue !== null
          //   ) {
          //     console.warn(`Expected string for field ${fieldName}, got ${typeof targetValue}`);
          //   }
          //   break;

          case "date":
            if (targetValue) {
              let dateValue = new Date(targetValue);
              if (isNaN(dateValue)) {
                targetValue = null;
              } else {
                targetValue = dateValue;
              }
            }
            break;
          case "number":
            if (typeof targetValue === "string") {
              // Remove commas from the string
              let cleanedValue = targetValue.replace(/,/g, "");

              if (!isNaN(cleanedValue)) {
                targetValue = Number(cleanedValue);
              } else {
                console.warn(
                  `Expected number format for field ${fieldName}, but got a non-numeric string.`
                );
              }
            } else if (typeof targetValue !== "number") {
              console.warn(`Expected number for field ${fieldName}, got ${typeof targetValue}`);
            }
            break;

          case "boolean":
            if (
              typeof targetValue !== "boolean" &&
              targetValue !== undefined &&
              targetValue !== null
            ) {
              console.warn(`Expected boolean for field ${fieldName}, got ${typeof targetValue}`);
            }
            break;

          case "password":
            if (typeof targetValue === "string") {
              try {
                targetValue = atob(targetValue); // Decode base64
              } catch (e) {
                console.warn(`Failed to decode password for field ${fieldName}`);
              }
            } else {
              console.warn(
                `Expected string for password field ${fieldName}, got ${typeof targetValue}`
              );
            }
            break;

          case "object":
            if (
              typeof targetValue !== "object" ||
              targetValue === null ||
              Array.isArray(targetValue)
            ) {
              console.warn(`Expected object for field ${fieldName}, got ${typeof targetValue}`);
            }
            break;

          case "array":
            if (!Array.isArray(targetValue)) {
              console.warn(`Expected array for field ${fieldName}, got ${typeof targetValue}`);
            }
            break;

          default:
            console.warn(`Unrecognized field type: ${fieldType}`);
        }

        // Assign transformed value back to the correct location
        if (belongsToNestedObject && nestedObjectName) {
          record[nestedObjectName][fieldName] = targetValue;
        } else {
          record[fieldName] = targetValue;
        }

        if (fieldName == "state") {
          //  console.log("record[fieldName]", record[fieldName], targetValue);
          //   console.log(states);
          let full_state_obj = states.find((state) => state.abbr == targetValue);
          //  console.log("full_state_obj", full_state_obj);
          record.state = full_state_obj?.name || targetValue || "";
          //  console.log("final record state", record.state);
        }
        // if (fieldName === "updated_by_id") {
        //   console.log("Processing updated_by_id:");
        //   console.log("Record before transformation:", record);
        //   console.log("Field schema:", fieldSchema);
        //   console.log("Target value:", targetValue);
        // }
      });

      return record;
    },
    transformRecords(records) {
      const authStore = useAuthStore();

      if (records) {
        //console.log("Records before transformation:", records);

        var records_transformed = records.map((record) => {
          const transformedRecord = {};

          Object.keys(record).forEach((key) => {
            transformedRecord[key] = {
              value: record[key],
              clickable: false,
            };
          });

          transformedRecord.actions = { cta: true, hide_sort: true };

          return transformedRecord;
        });

        //console.log("Transformed records:", records_transformed);
        return records_transformed;
      } else {
        console.error("transformRecords: No records to transform");
      }
    },
    async setSortHeader(header) {
      this.isLoading = true;
      // if active header is clicked again, toggle icon and ascending value
      if (header.field_name === this.sortHeader?.field_name) {
        this.sortAscending = !this.sortAscending;
      }
      this.sortHeader = header;
      this.isLoading = false;
    },

    // TODO: Implement when we decide what to do with persistent alerts/notifications
    // async createNotificationAlert({
    //   type,
    //   message,
    //   subText = "Description of the alert",
    //   does_persist = false,
    //   action = null,
    //   is_read = false,
    //   sendTo = null,
    // }) {
    //   const authStore = useAuthStore();

    //   const alert_notification_doc = {
    //     type,
    //     message,
    //     subText,
    //     is_read,
    //     does_persist,
    //     action,
    //     created_date: new Date(),
    //     created_by_id: sendTo ? sendTo.id : authStore.currentUserDocument.user_id,
    //     created_by_name: sendTo ? sendTo.name : authStore.currentUserDocument.name,
    //   };
    //   await collectionStore.insertRecord("Notifications_Alerts", alert_notification_doc);
    // },
    // async listenFor_Notifications_Alerts() {
    //   const authStore = useAuthStore();

    //   try {
    //     for await (const change of collectionStore.notificationsAlertsCollection.watch({
    //       filter: {
    //         "fullDocument.created_by_id": authStore.currentUserDocument.user_id,
    //       },
    //     })) {
    //       switch (change.operationType) {
    //         case "insert": {
    //           const { fullDocument } = change;
    //           this.animateNotificationAlert(fullDocument);
    //           if (fullDocument.type === "Notification") {
    //             this.userNotifications.push(fullDocument);
    //             if (
    //               fullDocument.update_record_detail &&
    //               fullDocument.action &&
    //               fullDocument.action.payload === this.currentRoute
    //             ) {
    //               recordDetailStore.modifyTic++;
    //             }
    //           } else if (fullDocument.type === "Alert") {
    //             this.userAlerts.push(fullDocument);
    //             if (
    //               fullDocument.update_record_detail &&
    //               fullDocument.action &&
    //               fullDocument.action.payload === this.currentRoute
    //             ) {
    //               recordDetailStore.modifyTic++;
    //             }
    //           }
    //           break;
    //         }
    //         case "update": {
    //           const { fullDocument } = change;
    //           if (fullDocument.subText) {
    //             // if subtext changed, change it in state
    //             if (fullDocument.type === "Notification") {
    //               let notificationToChange = this.userNotifications.find(
    //                 (notification) => notification._id.toString() === fullDocument._id.toString()
    //               );
    //               this.userNotifications[
    //                 this.userNotifications.indexOf(notificationToChange)
    //               ].subText = fullDocument.subText;
    //             } else if (fullDocument.type === "Alert") {
    //               //this.userAlerts.push(fullDocument);
    //             }
    //           }
    //           break;
    //         }
    //       }
    //     }
    //   } catch (err) {
    //     setTimeout(() => {
    //       this.listenFor_Notifications_Alerts();
    //     }, 1000);
    //   }
    // },
  },
});
