<template>
  <div
    v-if="investorStore.contact && investorStore.contact.suitability_info"
    id="element-to-convert"
    class="w-full flex flex-col justify-between h-full"
  >
    <div class="flex justify-between w-ful items-center mb-6">
      <h1 class="font-bold text-xl self-start">Investor Questionnaire</h1>
      <div class="flex items-center gap-2">
        <Button @click="exportToPDF" isIconOnlyOnMobile icon label variant="default-gray-outlined">
          <template #label>Download Questionnaire</template>
          <template #icon>
            <Icon name="Download" class="h-4 w-4" />
          </template>
        </Button>
        <Button
          v-if="!isEditMode && !authStore.isDalmoreAdmin"
          @click="toggleEditMode"
          label
          variant="default-gray-outlined"
        >
          <template #label>Edit Questionnaire</template>
        </Button>
        <div v-else-if="isEditMode" class="flex items-center gap-2">
          <Button @click="toggleEditMode" label variant="default-gray-outlined">
            <template #label>Cancel</template>
          </Button>
          <Button @click="handleSaveQuestionnaire" :disabled="!isQuestionnaireCompleted" label icon>
            <template #icon>
              <Icon name="Save" :stroke-width="2" class="text-white h-4 w-4" />
            </template>
            <template #label>Save</template>
          </Button>
        </div>
      </div>
    </div>
    <div>
      <Form
        :fields="INCOME_FIELDS"
        :record="investorStore.contact"
        header="Income Information"
        :isDisabled="!isEditMode"
      />
    </div>
    <div class="border-b border-neutral-gray-4 w-full my-4 md:my-8"></div>
    <div
      v-for="(object, index) of QUESTIONNAIRE_QUESTIONS_ANSWERS"
      :key="object"
      class="flex flex-col gap-2 relative"
      id="edit-form"
    >
      <div
        :class="index == 3 || index === 13 ? 'break-after-auto' : ''"
        class="font-bold text-sm md:text-base"
      >
        {{ index + 1 + ". " + object.question }}
      </div>
      <div
        v-for="(answer, answerIndex) of object.answers"
        :key="answer"
        class="w-full text-left text-gray-700"
      >
        <div class="w-full flex items-center text-sm mb-1 gap-2 lg:gap-2">
          <input
            :id="answer + answerIndex"
            type="radio"
            :name="object.question"
            v-model="investorStore.contact.suitability_info[object.field_name]"
            :value="answer"
            :disabled="!isEditMode"
            class="hidden"
          />
          <div
            class="min-w-5 min-h-5 rounded-full border-2 flex items-center justify-center cursor-pointer"
            :class="{
              'border-gray-300':
                !isEditMode || investorStore.contact.suitability_info[object.field_name] !== answer,
              'border-neutral-gray-4': !isEditMode,
              'border-secondary-6 bg-secondary-6':
                isEditMode && investorStore.contact.suitability_info[object.field_name] === answer,
            }"
            @click="
              isEditMode && (investorStore.contact.suitability_info[object.field_name] = answer)
            "
          >
            <div
              class="min-w-1.5 min-h-1.5 rounded-full"
              :class="{
                'bg-primary-6':
                  isEditMode &&
                  investorStore.contact.suitability_info[object.field_name] === answer,
                'bg-neutral-gray-5':
                  !isEditMode &&
                  investorStore.contact.suitability_info[object.field_name] === answer,
              }"
            ></div>
          </div>
          <label
            :for="answer + answerIndex"
            @click="
              isEditMode && (investorStore.contact.suitability_info[object.field_name] = answer)
            "
            class="text-sm lg:text-base text-lighter cursor-pointer"
            :class="{ 'text-gray-300': !isEditMode }"
          >
            {{ answer }}
          </label>
        </div>
        <div
          v-if="
            index === 2 &&
            question3Error &&
            ((answer === 'Conservative' &&
              investorStore.contact.suitability_info?.risk_willingness === 'Conservative') ||
              (answer === 'Moderate' &&
                investorStore.contact.suitability_info?.risk_willingness === 'Moderate'))
          "
          class="flex space-x-2 items-center mt-2"
        >
          <Icon
            name="Flag"
            class="h-6 w-6 text-functional-error-default animate-bounce"
            :stroke-width="2"
          />
          <p class="text-functional-error-default text-sm font-medium">
            Your current selection is not appropriate for this investment. Regardless of the
            specific investment type, be it equity or fixed income, the SEC considers all Regulation
            D offerings to be speculative. You must select “Significant” or “Speculative” to
            continue.
          </p>
        </div>
        <div
          v-else-if="
            otherQuestionsError &&
            ((index === 10 &&
              answer === 'No' &&
              investorStore.contact.suitability_info.significant_liquid_net_worth === 'No') ||
              (index === 11 &&
                answer === 'No' &&
                investorStore.contact.suitability_info.risky_investment_understanding === 'No') ||
              (index === 13 &&
                answer === 'No' &&
                investorStore.contact.suitability_info.self_responsibility === 'No'))
          "
          class="flex space-x-2 items-center mt-2"
        >
          <Icon
            name="Flag"
            class="h-6 w-6 text-functional-error-default animate-bounce"
            :stroke-width="2"
          />
          <p class="text-red-500 font-medium text-sm">
            Your current selection is not appropriate for this investment. You must select “Yes” to
            continue.
          </p>
        </div>
      </div>
      <div
        v-if="index !== QUESTIONNAIRE_QUESTIONS_ANSWERS.length - 1"
        class="border-b border-neutral-gray-4 w-full my-4"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep, isEqual } from "lodash";
import { useInvestorStore, useAuthStore } from "@/stores";

import { Button, Icon, Form } from "@/components";
import { QUESTIONNAIRE_QUESTIONS_ANSWERS, INCOME_FIELDS } from "@/constants";
import { commaSeparateThousands_2 } from "@/utilities";
import html2pdf from "html2pdf.js";

const investorStore = useInvestorStore();
const authStore = useAuthStore();

const question3Error = ref(false);
const otherQuestionsError = ref(false);
const isQuestionnaireCompleted = ref(false);
const originalSuitabilityInfo = ref({});
const isEditMode = ref(false);

onMounted(() => {
  window.scrollTo(0, 0);
});

watch(
  () => investorStore.active_profile_tab,
  () => {
    if (
      investorStore.contact &&
      investorStore.contact.suitability_info &&
      investorStore.active_profile_tab === "Questionnaire"
    ) {
      for (let field of INCOME_FIELDS) {
        if (field.number_type === "currency") {
          investorStore.contact.suitability_info[field.field_name]
            ? (investorStore.contact.suitability_info[field.field_name] = formatAmount(
                investorStore.contact.suitability_info[field.field_name]
              ))
            : (investorStore.contact.suitability_info[field.field_name] = "0");
        }
      }
      validateFields();
      originalSuitabilityInfo.value = cloneDeep(investorStore.contact.suitability_info);
      isEditMode.value = false;
      investorStore.supressErrors = true;
    }
  }
);

// Check if the fields are complete every time one changes
watch(
  () => investorStore.contact?.suitability_info,
  () => {
    validateSpecificQuestions();
    validateFields();
  },
  { deep: true }
);

const hasSuitabilityInfoChanged = computed(() => {
  if (investorStore.contact && investorStore.contact.suitability_info) {
    return !isEqual(investorStore.contact.suitability_info, originalSuitabilityInfo.value);
  }
  return false;
});

function toggleEditMode() {
  isEditMode.value = !isEditMode.value;
  if (!isEditMode.value) {
    investorStore.supressErrors = true;
    investorStore.contact.suitability_info = cloneDeep(originalSuitabilityInfo.value);
  } else {
    investorStore.supressErrors = false;
  }
}

function validateSpecificQuestions() {
  if (investorStore.contact && investorStore.contact.suitability_info) {
    if (
      investorStore.contact.suitability_info?.risk_willingness === "Moderate" ||
      investorStore.contact.suitability_info?.risk_willingness === "Conservative"
    ) {
      question3Error.value = true;
    } else {
      question3Error.value = false;
    }
    if (
      investorStore.contact.suitability_info?.significant_liquid_net_worth === "No" ||
      investorStore.contact.suitability_info?.risky_investment_understanding === "No" ||
      investorStore.contact.suitability_info?.self_responsibility === "No"
    ) {
      otherQuestionsError.value = true;
    } else {
      otherQuestionsError.value = false;
    }
  }
}

function validateFields() {
  if (investorStore.contact && investorStore.contact.suitability_info) {
    const currentIncome = investorStore.contact.suitability_info.current_income;

    let isIncomeValid = false;
    if (typeof currentIncome === "string") {
      isIncomeValid = parseFloat(currentIncome.replace(/,/g, "")) >= 10000;
    } else if (typeof currentIncome === "number") {
      isIncomeValid = currentIncome >= 10000;
    }

    if (
      Object.values(investorStore.contact.suitability_info).every((x) => x != null && x !== "") &&
      !question3Error.value &&
      !otherQuestionsError.value &&
      isIncomeValid
    ) {
      isQuestionnaireCompleted.value = true;
    } else {
      isQuestionnaireCompleted.value = false;
    }
  }
}

function modifyClasses() {
  var element = document.getElementById("edit-form");
  element.classList.remove("max-h-[650px]"); // Remove an existing class
  var buttonDiv = document.getElementById("button-div");
  if (buttonDiv) {
    buttonDiv.style.display = "none"; // Set the button div to hidden
  }
}
function replaceClasses() {
  var element = document.getElementById("edit-form");
  element.classList.add("max-h-[650px]");
  var buttonDiv = document.getElementById("button-div");
  if (buttonDiv) {
    buttonDiv.style.display = "flex"; // Set the button div to visible
    buttonDiv.style.flexJustify = "between";
    buttonDiv.style.marginTop = "4px";
  }
}

async function exportToPDF() {
  modifyClasses();
  const componentHtml = document.getElementById("element-to-convert");
  const selectedValues = {};
  const selectedInputs = componentHtml.querySelectorAll("input:checked");
  selectedInputs.forEach((input) => {
    selectedValues[input.name] = input.value;
  });
  //console.log(investorStore.investorFields?.first_name);
  const file_name =
    "investor-questionnaire-" +
    investorStore.contact?.first_name +
    "-" +
    investorStore.contact?.last_name +
    ".pdf";
  await html2pdf()
    .set({
      filename: file_name,
      format: "letter",
      orientation: "portrait",
      margin: 4,
      pagebreak: { before: ".page-break" },
      html2canvas: {
        logging: true,
      },
      jsPDF: {
        format: "letter",

        hotfixes: [], // Specify any required hotfixes
      },
    })
    .from(componentHtml)
    .save();

  replaceClasses();
  Object.entries(selectedValues).forEach(([name, value]) => {
    const input = componentHtml.querySelector(`input[name="${name}"][value="${value}"]`);
    if (input) {
      input.checked = true;
    }
  });
}

function formatAmount(val) {
  let newValue = commaSeparateThousands_2(val);
  return newValue;
}

async function handleSaveQuestionnaire() {
  isEditMode.value = false;
  if (hasSuitabilityInfoChanged.value) {
    await investorStore.updateContactSuitabilityInfo();
  }
  originalSuitabilityInfo.value = cloneDeep(investorStore.contact.suitability_info);
}
</script>
