import { defineStore } from "pinia";
import * as Realm from "realm-web";

import {
  useCrudStore,
  useAuthStore,
  useUIStore,
  useInvestorStore,
  useCollectionStore,
  useRecordDetailStore,
  useSchemaStore,
} from "@/stores";
import {
  flattenSchema,
  fillMissingMonths,
  convertToLabels,
  getDateRangeQueryForRelativeValue,
  createDocumentWithFieldOrder,
} from "@/utilities";
import { CHART_OPTIONS, INVESTMENT_DOCUMENT_FIELD_ORDER } from "@/constants";

import useFileUtility from "@/composables/useFileUtility";

export const useInvestmentStore = defineStore("investmentStore", {
  state: () => ({
    // investment processing
    investment_ids_to_process: [],

    // investment_approvals
    investments_for_approvals: [],
    investment_to_process: null,

    // general investment data
    transactionsData: [],
    earningsData: [],
    bankAccounts: [],

    // Dashboard Charts
    runningTotalInvestments: [],
    runningTotalDistributions: [],
    investmentsByOffering: [],
    chartData: { labels: [""], datasets: [] },
    chartOptions: CHART_OPTIONS,

    // Schema Details
    all_contact_schema: [],
    all_account_schema: [],
    non_accredited_owner_contact_schema: [],
    accredited_owner_contact_schema: [],
    non_accredited_joint_contact_schema: [],
    accredited_joint_contact_schema: [],
    entity_info_schema: [],

    // Investment Details
    investmentDetails: {},
    fetchedAccordionData: [],
    currentPageConfig: {},

    // Investment Conversions
    currentConversionStep: 0,
    conversionSteps: ["Select Conversion Type", "Select Conversion Method", "Complete Conversion"],
    selectedConversionType: null,
    selectedConversionMethod: null,
    conversionPaymentMethod: null,
    conversionFundingAccount: null,
    conversionDetails: {
      amount_to_add: "",
      selectedOffering: null,
      is_compounded: false,
    },
    selectedInvestmentsForConversion: [],
    oneToManyInvestmentList: [
      { amount: 0, index: 0 },
      { amount: 0, index: 1 },
    ],
    conversionSubsToUpload: [],
    accreditedInfoTabs: [],

    // Dashboard Table
    dashboard_filters: null,
    isGeneratingOneOffStatement: false,
  }),
  getters: {
    hasSignedInvestments: (state) => {
      if (!state.transactionsData) return false;
      return state.transactionsData.some((i) => i.signature_date);
    },
    hasSettledInvestments: (state) => {
      if (!state.transactionsData) return false;
      return state.transactionsData.some((i) => i.status === "Settled");
    },
    isAccreditedCurrentInvestment: (state) => {
      if (!state.selectedOffering) return false;
      return state.selectedOffering.type === "Reg D";
    },
    hasAccreditedInvestments: (state) => {
      if (!state.transactionsData || state.transactionsData.length === 0) return false;
      return state.transactionsData.some((i) => i.offering.type === "Reg D");
    },
    hasSignedEntityInvestment: (state) => {
      if (!state.transactionsData || state.transactionsData.length === 0) return false;
      return state.transactionsData.some((i) => i.type === "Entity");
    },
    hasSignedJointInvestment: (state) => {
      if (!state.transactionsData || state.transactionsData.length === 0) return false;
      return state.transactionsData.some((i) => i.type === "Joint");
    },
    totalAvailableAmountForConvertedInvestments: (state) => {
      const totalSelected = state.selectedInvestmentsForConversion.reduce((acc, investment) => {
        return acc + investment.amount;
      }, 0);
      const allNewAmounts = state.oneToManyInvestmentList.reduce((acc, investment) => {
        const cleanAmount = investment.amount ? investment.amount.replace(/,/g, "") : "0";
        const amount = isNaN(Number(cleanAmount)) ? 0 : Number(cleanAmount);

        return acc + amount;
      }, 0);

      const newCapital = state.conversionDetails.amount_to_add
        ? Number(state.conversionDetails.amount_to_add.replace(/,/g, ""))
        : 0;

      return totalSelected + newCapital - allNewAmounts;
    },
    conversionInvestments: (state) => {
      if (!state.selectedInvestmentsForConversion?.length) return [];
      const selectedInvestment = state.selectedInvestmentsForConversion[0];
      const fieldsToBringOver = {
        account_id: selectedInvestment.account_id,
        contact_id: selectedInvestment.contact_id,
        user_id: selectedInvestment.user_id,
        type: selectedInvestment.type,
        entity_account_id: selectedInvestment.entity_account_id,
        joint_contact_id: selectedInvestment.joint_contact_id,
        custodian_id: selectedInvestment.custodian_id,
        custodian_account_number: selectedInvestment.custodian_account_number,
        distribution_method: selectedInvestment.distribution_method,
        distribution_account: selectedInvestment.distribution_account,
        amount: selectedInvestment.amount,
        bonds: selectedInvestment.bonds,
        current_nav: selectedInvestment.current_nav,
      };
      if (state.selectedConversionType.id === 1) {
        if (state.selectedConversionMethod.id === 1) {
          return [
            {
              ...fieldsToBringOver,
              is_compounded: state.conversionDetails.is_compounded,
              offering: state.conversionDetails.selectedOffering.offering,
              offering_name: state.conversionDetails.selectedOffering.offering.name,
              offering_id: state.conversionDetails.selectedOffering.offering._id,
              offering_type: state.conversionDetails.selectedOffering.offering.type,
              funding_account: state.conversionFundingAccount,
              payment_method: state.conversionPaymentMethod,
            },
          ];
        }

        if (state.selectedConversionMethod.id === 2) {
          const newAmount =
            Number(selectedInvestment.amount) +
            Number(state.conversionDetails.amount_to_add.replace(/,/g, ""));

          return [
            {
              ...fieldsToBringOver,
              amount: newAmount,
              bonds: newAmount / 1000,
              current_nav: newAmount,
              is_compounded: state.conversionDetails.is_compounded,
              offering: state.conversionDetails.selectedOffering.offering,
              offering_name: state.conversionDetails.selectedOffering.offering.name,
              offering_id: state.conversionDetails.selectedOffering.offering._id,
              offering_type: state.conversionDetails.selectedOffering.offering.type,
              funding_account: state.conversionFundingAccount,
              payment_method: state.conversionPaymentMethod,
            },
          ];
        }

        return [];
      } else if (state.selectedConversionType.id === 2) {
        const filteredOneToManyList = state.oneToManyInvestmentList.filter(
          (investment) => investment.amount !== "0"
        );
        return filteredOneToManyList.map((investment) => {
          return {
            ...fieldsToBringOver,
            amount: Number(investment.amount.replace(/,/g, "")),
            bonds: Number(investment.amount.replace(/,/g, "")) / 1000,
            current_nav: Number(investment.amount.replace(/,/g, "")),
            is_compounded: state.conversionDetails.is_compounded,
            offering: state.conversionDetails.selectedOffering.offering,
            offering_name: state.conversionDetails.selectedOffering.offering.name,
            offering_id: state.conversionDetails.selectedOffering.offering._id,
            offering_type: state.conversionDetails.selectedOffering.offering.type,
            funding_account: state.conversionFundingAccount,
            payment_method: state.conversionPaymentMethod,
          };
        });
      } else if (state.selectedConversionType.id === 3) {
        // many to one
        const summedAmount = state.selectedInvestmentsForConversion.reduce((acc, investment) => {
          return acc + investment.amount;
        }, 0);
        const newAmount =
          Number(summedAmount) + Number(state.conversionDetails.amount_to_add.replace(/,/g, ""));
        return [
          {
            ...fieldsToBringOver,
            amount: newAmount,
            bonds: newAmount / 1000,
            current_nav: newAmount,
            is_compounded: state.conversionDetails.is_compounded,
            offering: state.conversionDetails.selectedOffering.offering,
            offering_name: state.conversionDetails.selectedOffering.offering.name,
            offering_id: state.conversionDetails.selectedOffering.offering._id,
            offering_type: state.conversionDetails.selectedOffering.offering.type,
            funding_account: state.conversionFundingAccount,
            payment_method: state.conversionPaymentMethod,
          },
        ];
      }
    },
  },
  actions: {
    async setSchemaForInvestmentFlow() {
      // Initialize the CRUD store
      const crudStore = useCrudStore();

      // Define the list of configuration references to fetch
      const listsToGrab = [
        "non_accredited_personal_info",
        "accredited_personal_info",
        "non_accredited_joint_info",
        "accredited_joint_info",
        "entity_information",
        "admin_disabled_joint_contact_fields",
        "admin_disabled_owner_contact_fields",
        "admin_disabled_beneficial_owner_contact_fields",
        "admin_disabled_entity_account_fields",
      ];

      // Fetch the configuration lists based on the references
      const configurationLists = await crudStore.find("Settings", {
        reference: { $in: listsToGrab },
      });

      this.admin_disabled_beneficial_owner_contact_fields = configurationLists.find(
        (config) => config.reference === "admin_disabled_beneficial_owner_contact_fields"
      ).options;
      this.admin_disabled_joint_contact_fields = configurationLists.find(
        (config) => config.reference === "admin_disabled_joint_contact_fields"
      ).options;
      this.admin_disabled_owner_contact_fields = configurationLists.find(
        (config) => config.reference === "admin_disabled_owner_contact_fields"
      ).options;
      this.admin_disabled_entity_account_fields = configurationLists.find(
        (config) => config.reference === "admin_disabled_entity_account_fields"
      ).options;

      // Fetch schemas and custodian names concurrently
      const schemaProjection = {
        field_name: 1,
        field_type: 1,
        record_detail_config: 1,
        accredited_only: 1,
        number_type: 1,
        label: 1,
        read_only: 1,
        belongs_to_nested_object: 1,
        nested_object_name: 1,
        _id: 0,
      };
      const [contactSchema, accountSchema] = await Promise.all([
        crudStore.find("Schema", { collection_name: "Contacts" }, schemaProjection),
        crudStore.find("Schema", { collection_name: "Accounts" }, schemaProjection),
        crudStore.find("Schema", { collection_name: "Investments" }, schemaProjection),
      ]);

      // Assign fetched schemas and custodian names to the store properties
      this.all_contact_schema = contactSchema;
      this.all_account_schema = accountSchema;

      // Helper function to map configuration to schema fields
      const mapConfigToSchema = (config, flattenedSchema, useAccreditedFields) =>
        config.options.map((groupName) => ({
          group_name: groupName,
          fields: flattenedSchema.filter(
            (field) =>
              field.record_detail_group === groupName &&
              (useAccreditedFields || !field.accredited_only)
          ),
        }));

      // Flatten the schemas based on different configurations
      const flattenedOwnerContactSchema = flattenSchema(contactSchema, "default");
      const flattenedJointContactSchema = flattenSchema(contactSchema, "joint");
      const flattenedEntitySchema = flattenSchema(accountSchema, "entity");

      // Map configurations to schemas
      this.accredited_owner_contact_schema = mapConfigToSchema(
        configurationLists.find((config) => config.reference === "accredited_personal_info"),
        flattenedOwnerContactSchema,
        true // Use accredited fields
      );

      this.non_accredited_owner_contact_schema = mapConfigToSchema(
        configurationLists.find((config) => config.reference === "non_accredited_personal_info"),
        flattenedOwnerContactSchema,
        false // Do not use accredited fields
      );

      this.accredited_joint_contact_schema = mapConfigToSchema(
        configurationLists.find((config) => config.reference === "accredited_joint_info"),
        flattenedJointContactSchema,
        true // Use accredited fields
      );

      this.non_accredited_joint_contact_schema = mapConfigToSchema(
        configurationLists.find((config) => config.reference === "non_accredited_joint_info"),
        flattenedJointContactSchema,
        false // Do not use accredited fields
      );

      this.entity_info_schema = mapConfigToSchema(
        configurationLists.find((config) => config.reference === "entity_information"),
        flattenedEntitySchema,
        true // Use accredited fields
      );
    },
    async getAccountsFromPlaid(accountType, accessToken) {
      const authStore = useAuthStore();
      const UIStore = useUIStore();
      const collectionStore = useCollectionStore();
      const investorStore = useInvestorStore();
      const crudStore = useCrudStore();
      try {
        if (accountType === "Funding") {
          UIStore.fundingLoading = true;
        } else if (accountType === "Distribution") {
          UIStore.distributionLoading = true;
        }
        let method = "POST";
        const headers = {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
          session_id: authStore.currentUser.session_id,
          user_id: authStore.currentUser.id,
        };
        let body = {
          endpoint: "auth",
          data: {
            user_id: authStore.currentUser.id,
            accountType: accountType,
            plaid_access_token: accessToken,
          },
        };
        let options = {
          method: method,
          headers,
        };
        if (method === "PUT" || method === "POST") {
          options.body = JSON.stringify(body);
        }
        let response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/call-plaid-endpoint`,
          options
        );
        let plaidAccounts = await response.json();

        //const plaidAccounts = await authStore.currentUser.functions.CallPlaidEndpoint('auth', {user_id: authStore.currentUser.id, accountType: accountType, plaid_access_token: accessToken});
        let accountsToAdd = [];
        for (let [index, plaidAccount] of plaidAccounts.entries()) {
          let accountToAdd = {};
          body = {
            endpoint: "get_institution_by_id",
            data: { institution_id: plaidAccount.institution_id },
          };
          options = {
            method: method,
            headers,
          };
          if (method === "PUT" || method === "POST") {
            options.body = JSON.stringify(body);
          }
          response = await fetch(
            `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/call-plaid-endpoint`,
            options
          );
          let institution = await response.json();
          //let institution = await authStore.currentUser.functions.CallPlaidEndpoint('get_institution_by_id', {institution_id: plaidAccount.institution_id});
          accountToAdd.user_id = authStore.currentUser.id;
          accountToAdd.account_nickname = "";
          accountToAdd.account_number = collectionStore.encryptField(plaidAccount.account_number);
          accountToAdd.account_type = UIStore.autoCapitalize(plaidAccount.subtype);
          accountToAdd.mask = plaidAccount.mask ? plaidAccount.mask : null;
          accountToAdd.financial_institution_name = institution.name;
          accountToAdd.institution_id = plaidAccount.institution_id;
          accountToAdd.routing_number = collectionStore.encryptField(plaidAccount.routing_number);
          accountToAdd.plaid_account_id = plaidAccount.internal_account_id;
          accountToAdd.item_id = plaidAccount.item_id;
          accountToAdd.plaid_access_token = plaidAccount.plaid_access_token;
          accountToAdd.available_balance = plaidAccount.available_balance;
          accountToAdd.current_balance = plaidAccount.current_balance;
          accountToAdd.account_id = investorStore.account._id;
          accountToAdd.contact_id = investorStore.contact._id;
          accountToAdd.is_visible = true;
          accountToAdd.logo = institution.logo;
          accountToAdd.created_date = new Date();

          // insert accounts into collection here now, to have access to their _ids
          let insertedBankAccount = await crudStore.insertOne("BankAccounts", accountToAdd);
          if (insertedBankAccount.status !== 500) {
            accountToAdd._id = insertedBankAccount.insertedId;
            accountsToAdd.push(accountToAdd);
          }
        }

        // set active account to the first one from Plaid
        this.setActiveBankAccount(accountsToAdd[0], accountType);
        this.bankAccounts = [...accountsToAdd, ...this.bankAccounts];
        UIStore.fundingLoading = false;
        UIStore.distributionLoading = false;
      } catch (e) {
        // already make custom log in server side function
        console.log(e);
        UIStore.setAlert({
          title: "There was an error!",
          message: "There was an error retrieving your bank accounts. Please try again.",
          type: "error",
        });
      }
    },
    setActiveBankAccount(bankingOption, accountType) {
      if (accountType === "Funding") {
        this.currentInvestment.payment_method = "ACH";
        this.currentInvestment.funding_account = bankingOption._id;
      } else {
        this.currentInvestment.distribution_method = "ACH";
        this.currentInvestment.distribution_account = bankingOption._id;
        this.currentInvestment.voided_check = false;
      }
    },
    async cancelRolloverInvestment(id) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investorStore = useInvestorStore();
      try {
        const now = new Date();
        now.setUTCHours(0, 0, 0, 0);

        const query = { _id: id };
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };
        const update = {
          $set: {
            status: "Canceled",
            cancelled_date: now,
            ...updatedTimestamp,
          },
        };
        await crudStore.updateOne("Investments", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async updateInvestmentStatusInBulk(
      ids_to_process,
      status,
      isSendEmail = false,
      date,
      specialDate
    ) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();

      try {
        // 1. Update Investment Email History based on the sendEmail boolean so we know whether to send an email or not in the trigger handler
        const email_history_filter = { investment_id: { $in: ids_to_process } };

        let updateField = "";
        if (status === "Settled") {
          updateField = "investment_closed";
        } else if (status === "Canceled") {
          updateField = "investment_cancelled";
        }

        if (updateField) {
          const updateValue = isSendEmail ? null : new Date();
          const update = { $set: { [updateField]: updateValue } };
          const result = await crudStore.updateMany(
            "InvestmentEmailHistory",
            email_history_filter,
            update
          );
        }

        const requestBody = {
          id_array: ids_to_process,
          status,
          date,
          specialDate,
        };

        console.log("set status request body", requestBody);

        // 2. Update Investment Status
        await fetch(`${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/bulk-status-update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            user_id: authStore.currentUser.id,
            session_id: authStore.currentUser.session_id,
          },
          body: JSON.stringify(requestBody),
        });
      } catch (err) {
        console.error(err);
        UIStore.animateNotificationAlert({
          icon: "X",
          type: "error",
          message: "Failed to update investments!",
        });
        throw err;
      }
    },
    async updateInvestmentSignatureDateInBulk(date, isSendEmail = false) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();

      try {
        // 1. Update Investment Email History based on the isSendEmail boolean so we know whether to send an email or not in the trigger handler
        const filter = { _id: { $in: this.investment_ids_to_process } };

        if (isSendEmail) {
          const update = { $set: { investment_completed: null } };
          await crudStore.updateMany("InvestmentEmailHistory", filter, update);
        }

        // 2. Update Investment Signature Date
        const signature_date = new Date(date);
        const timeStampFields = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };
        await crudStore.updateMany("Investments", filter, {
          $set: { signature_date: signature_date, ...timeStampFields },
        });
      } catch (err) {
        console.error(err);
        UIStore.animateNotificationAlert({
          icon: "X",
          message: "Failed to update investments!",
        });
      }
    },
    async finalizeConversion() {
      const UIStore = useUIStore();
      try {
      } catch (err) {
        console.error(err);
        UIStore.animateNotificationAlert({
          icon: "X",
          message: "Failed to finalize conversion! Please try again.",
        });
      }
    },
    // DASHBOARD AND CHARTS
    async getInvestorTransactions() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const recordDetailStore = useRecordDetailStore();

      let match_obj = {
        user_id: recordDetailStore.currentPageDocument.user_id,
        $or: [
          { signature_date: { $nin: [null, ""] } },
          {
            signature_date: { $in: [null, ""] },
            status: "Pending",
          },
        ],
      };

      if (this.dashboard_filters) {
        match_obj = { ...match_obj, ...this.dashboard_filters };
      }

      console.log("filters: ", this.dashboard_filters, match_obj);

      let pipeline = [
        {
          $match: match_obj,
        },
        {
          // Add a field to prioritize "Pending" status
          $addFields: {
            pending_priority: {
              $cond: {
                if: { $eq: ["$status", "Pending"] },
                then: 0,
                else: 1,
              },
            },
          },
        },
        {
          $sort: {
            pending_priority: 1,
            signature_date: -1,
            _id: -1,
          },
        },
        {
          $lookup: {
            from: "Offerings",
            localField: "offering_id",
            foreignField: "_id",
            as: "offering",
          },
        },
        {
          $unwind: {
            path: "$offering",
          },
        },
        {
          $addFields: {
            bond_term: { $divide: ["$offering.bond_term", 12] },
            interest_rate: { $divide: ["$offering.interest_rate", 100] },
          },
        },
      ];
      let sortObj = {};
      if (UIStore.sortHeader) {
        sortObj[UIStore.sortHeader.field_name] = UIStore.sortAscending ? 1 : -1;
        pipeline.splice(3, 0, {
          $sort: sortObj,
        });
      }

      this.transactionsData = await crudStore.aggregate("Investments", pipeline);
      return this.transactionsData;
    },
    async getInvestorEarnings() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const recordDetailStore = useRecordDetailStore();

      let pipeline = [
        {
          $match: {
            user_id: recordDetailStore.currentPageDocument.user_id,
            is_completed: true,
          },
        },
        {
          $group: {
            _id: "$distribution_date",
            amount: { $sum: "$amount" },
          },
        },
        {
          $addFields: {
            distribution_date: "$_id",
          },
        },
        { $sort: { _id: 1 } },
      ];
      let sortObj = {};
      if (UIStore.sortHeader) {
        sortObj[UIStore.sortHeader.field_name] = UIStore.sortAscending ? 1 : -1;
        pipeline[3] = {
          $sort: sortObj,
        };
      }

      this.earningsData = await crudStore.aggregate("Distributions", pipeline);
      return this.earningsData;
    },
    async getPortfolioDynamicsData() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const recordDetailStore = useRecordDetailStore();

      this.chartOptions.scales.x.stacked = true;
      this.chartOptions.scales.y.stacked = true;
      this.chartOptions.scales.x.ticks.display = true;

      this.runningTotalInvestments = [];
      this.runningTotalDistributions = [];
      UIStore.isChartLoading = true;
      let investmentsByMonth = await crudStore.aggregate("Investments", [
        {
          $match: {
            signature_date: {
              $ne: null,
            },
            user_id: recordDetailStore.currentPageDocument.user_id,
            status: {
              $ne: "Canceled",
            },
          },
        },
        {
          $group: {
            _id: {
              year: {
                $year: "$signature_date",
              },
              month: {
                $month: "$signature_date",
              },
            },
            totalAmount: {
              $sum: "$amount",
            },
            signatureDate: {
              $first: "$signature_date",
            },
          },
        },
        {
          $sort: {
            "_id.year": 1,
            "_id.month": 1,
          },
        },
        {
          $project: {
            _id: 0,
            year: "$_id.year",
            month: "$_id.month",
            totalAmount: 1,
            runningTotal: 1,
            signatureDate: 1,
          },
        },
      ]);
      let runningTotal = 0;
      investmentsByMonth.forEach((investment, index) => {
        runningTotal = investment.totalAmount + runningTotal;
        this.runningTotalInvestments.push({
          ...investment,
          ...{
            runningTotal: runningTotal,
          },
        });
      });

      let distributionsByMonth = await crudStore.aggregate("Distributions", [
        {
          $match: {
            is_completed: true,
            user_id: recordDetailStore.currentPageDocument.user_id,
          },
        },
        {
          $group: {
            _id: {
              year: {
                $year: "$distribution_date",
              },
              month: {
                $month: "$distribution_date",
              },
              is_compounded: "$is_compounded",
            },
            totalAmount: {
              $sum: "$amount",
            },
            signatureDate: {
              $first: "$distribution_date",
            },
          },
        },
        {
          $sort: {
            "_id.year": 1,
            "_id.month": 1,
          },
        },
        {
          $project: {
            _id: 0,
            year: "$_id.year",
            month: "$_id.month",
            is_compounded: "$_id.is_compounded",
            totalAmount: 1,
            distributionDate: 1,
          },
        },
      ]);

      let compoundedAmount = 0;
      let runningNonCompoundedAmount = 0;
      distributionsByMonth.forEach((distribution, index) => {
        compoundedAmount = distribution.is_compounded
          ? distribution.totalAmount + compoundedAmount
          : compoundedAmount;
        runningNonCompoundedAmount = distribution.is_compounded
          ? runningNonCompoundedAmount
          : distribution.totalAmount + runningNonCompoundedAmount;
        this.runningTotalDistributions.push({
          ...distribution,
          ...{
            compoundedAmount: compoundedAmount,
            runningNonCompoundedAmount: runningNonCompoundedAmount,
          },
        });
      });

      const currentDate = new Date();
      if (currentDate.getDate() < 10) {
        // if we haven't paid distribution for this month, add same amount as last month
        if (this.runningTotalDistributions.length) {
          this.runningTotalDistributions.push({
            ...this.runningTotalDistributions[this.runningTotalDistributions.length - 1],
            month: currentDate.getMonth() + 1,
          });
        }
      }
      this.chartData.datasets = [
        {
          label: "Invested",
          data: [],
          backgroundColor: ["rgba(110, 159, 216, 0.5)"],
          borderColor: ["#3f87dc"],
          borderWidth: 1,
        },
        {
          label: "Simple Interest",
          data: [],
          backgroundColor: ["#426fa2"],
          borderColor: ["#3f87dc"],
          borderWidth: 1,
        },
        {
          label: "Compounding Interest",
          data: [],
          backgroundColor: ["#fbf6e1"],
          borderColor: ["#9e7400"],
          borderWidth: 1,
        },
      ];
      this.chartOptions.scales.y.title.display = true;
      let investmentsWithAllMonths = fillMissingMonths(this.runningTotalInvestments);
      this.chartData.labels = convertToLabels(investmentsWithAllMonths);
      this.chartData.datasets[0].data = investmentsWithAllMonths.map((obj) => obj.runningTotal);
      // go through entire array, if new array does not have a value with that month/year, add it. if it does, if the current element is compounding, add compounding field
      let combined_distributions = [];
      this.runningTotalDistributions.forEach((distribution) => {
        let existing_combined_distribution = combined_distributions.findIndex(
          (obj) => obj.year === distribution.year && obj.month === distribution.month
        );
        if (existing_combined_distribution !== -1) {
          if (distribution.is_compounded) {
            combined_distributions[existing_combined_distribution].compoundedAmount =
              distribution.compoundedAmount;
          } else {
            combined_distributions[existing_combined_distribution].runningNonCompoundedAmount =
              distribution.runningNonCompoundedAmount;
          }
        } else {
          combined_distributions.push(distribution);
        }
      });
      this.runningTotalDistributions = combined_distributions;
      this.chartData.datasets[1].data = this.runningTotalDistributions.map(
        (obj) => obj.runningNonCompoundedAmount
      );
      this.chartData.datasets[2].data = this.runningTotalDistributions.map(
        (obj) => obj.compoundedAmount
      );
      UIStore.isChartLoading = false;
    },
    getTotalEarningsData() {
      this.chartOptions.scales.x.ticks.display = true;
      this.chartData.labels = convertToLabels(this.runningTotalDistributions);
      this.chartData.datasets = [
        {
          label: "Total Earnings",
          data: this.runningTotalDistributions.map(
            (obj) => obj.compoundedAmount + obj.runningNonCompoundedAmount
          ),
          backgroundColor: ["rgba(110, 159, 216, 0.5)"],
          borderColor: ["#3f87dc"],
          borderWidth: 1,
        },
      ];
      this.chartOptions.scales.y.title.display = true;
    },
    getPortfolioStructureData() {
      let investmentsByOffering = Object.values(
        this.transactionsData.reduce((acc, obj) => {
          // Extract the group key (_id from offering)
          const key = obj.offering._id;

          // Initialize the group if it doesn't exist
          if (!acc[key]) {
            // Make a shallow copy of the offering object to keep all its fields
            acc[key] = { ...obj.offering, amount: 0 };
          }

          // Sum the amount field within the group
          acc[key].amount += obj.amount;

          return acc;
        }, {})
      );
      this.chartOptions.scales.y.title.display = false;
      this.chartOptions.scales.x.ticks.display = false;
      this.chartData.labels = investmentsByOffering.map(
        (offering) =>
          offering.interest_rate +
          "% for " +
          offering.bond_term / 12 +
          (offering.bond_term / 12 > 1 ? " years" : " year")
      );
      this.chartData.datasets = [
        {
          label: "Total Earnings",
          data: investmentsByOffering.map((investment) => investment.amount),
          backgroundColor: ["rgba(110, 159, 216, 0.5)", "#426fa2", "#fbf6e1"],
          borderColor: ["#3f87dc", "#3f87dc", "#9e7400"],
          borderWidth: 1,
        },
      ];
    },
    async getAvgInvestmentAmount(dateRange) {
      const crudStore = useCrudStore();
      let dateRangeFilter = getDateRangeQueryForRelativeValue(dateRange, true);
      let match = { $or: [{ status: { $eq: "Funded" } }, { status: { $eq: "Settled" } }] };
      if (dateRange !== "All Time") {
        match = { $and: [match, { close_date: dateRangeFilter }] };
      }
      return await crudStore.aggregate("Investments", [
        {
          $match: match,
        },
        {
          $group: {
            _id: "$amount",
            frequency: { $sum: 1 },
          },
        },
      ]);
    },
    async getInvestmentsByStatus(dateRange) {
      const crudStore = useCrudStore();
      let dateRangeFilter = getDateRangeQueryForRelativeValue(dateRange, true);
      let and_array = [{ signature_date: { $nin: ["", null] } }];
      if (dateRange !== "All Time") {
        and_array.push({ close_date: dateRangeFilter });
      }
      return await crudStore.aggregate("Investments", [
        {
          $match: {
            $and: and_array,
          },
        },
        {
          $group: {
            _id: "$status",
            frequency: { $sum: 1 },
          },
        },
      ]);
    },
    async getInvestmentsByWeek(dateRange) {
      const crudStore = useCrudStore();
      let dateRangeFilter = getDateRangeQueryForRelativeValue(dateRange, true);
      let and_array = [
        { close_date: { $nin: ["", null] } },
        { $or: [{ status: { $eq: "Funded" } }, { status: { $eq: "Settled" } }] },
      ];
      if (dateRange !== "All Time") {
        and_array.push({ close_date: dateRangeFilter });
      }
      return await crudStore.aggregate("Investments", [
        {
          $match: {
            $and: and_array,
          },
        },
        {
          $group: {
            _id: { year: { $year: "$close_date" }, week: { $week: "$close_date" } },
            totalInvestmentAmount: { $sum: "$amount" },
          },
        },
        {
          $sort: {
            "_id.year": 1,
            "_id.week": 1,
          },
        },
      ]);
    },
    async getConvertedInvestments(dateRange) {
      const crudStore = useCrudStore();
      let dateRangeFilter = getDateRangeQueryForRelativeValue(dateRange, true);
      let and_array = [{ _id: { $exists: true } }];
      if (dateRange !== "All Time") {
        and_array.push({ created_date: dateRangeFilter });
      }
      return await crudStore.aggregate("Investments", [
        { $match: { $and: and_array } },
        {
          $group: {
            _id: {
              hasSignatureDate: {
                $cond: {
                  if: { $gt: ["$signature_date", null] },
                  then: "Completed",
                  else: "Abandoned",
                },
              },
            },
            count: { $sum: 1 },
          },
        },
        {
          $project: {
            _id: 0,
            status: "$_id.hasSignatureDate",
            count: 1,
          },
        },
      ]);
    },
    // Metrics
    async getDashboardMetricsTotalInvested() {
      const crudStore = useCrudStore();
      let pipeline = [
        {
          $group: {
            _id: null,
            totalInvested: { $sum: "$total_raised" },
          },
        },
      ];
      return await crudStore.aggregate("Offerings", pipeline);
    },
    async getDashboardMetricsPendingInvestments() {
      const crudStore = useCrudStore();
      let pipeline = [
        {
          $group: {
            _id: null,
            pendingInvested: { $sum: "$total_pending" },
          },
        },
      ];
      return await crudStore.aggregate("Offerings", pipeline);
    },
    async getDashboardMetricsTotalDistributions() {
      const crudStore = useCrudStore();
      let pipeline = [
        {
          $group: {
            _id: null,
            totalInterestPaid: { $sum: "$total_interest_paid" },
          },
        },
      ];
      return await crudStore.aggregate("Offerings", pipeline);
    },
    async getDashboardMetricsTotalYOY() {
      const crudStore = useCrudStore();
      let today = new Date();

      let pipeline = [
        // Step 1: Filter documents to include only entries within the same date range for this year and last year
        {
          $match: {
            signature_date: {
              $gte: new Date(today.getFullYear() - 1, 0, 1), // Start of the date range for current year
              $lt: new Date(today.getFullYear(), 0, 1), // Today's date (current year up to now)
            },
          },
        },
        // Step 2: Group investments by year and calculate total investment for each year
        {
          $group: {
            _id: null,
            totalAmount: { $sum: "$amount" },
          },
        },
      ];
      let last_year_total = await crudStore.aggregate("Investments", pipeline);
      pipeline = [
        // Step 1: Filter documents to include only entries within the same date range for this year and last year
        {
          $match: {
            signature_date: {
              $gte: new Date(today.getFullYear() - 2, 0, 1), // Start of the date range for current year
              $lt: new Date(today.getFullYear() - 1, 0, 1), // Today's date (current year up to now)
            },
          },
        },
        // Step 2: Group investments by year and calculate total investment for each year
        {
          $group: {
            _id: null,
            totalAmount: { $sum: "$amount" },
          },
        },
      ];
      let two_years_ago_total = await crudStore.aggregate("Investments", pipeline);
      return (
        ((last_year_total[0].totalAmount - two_years_ago_total[0].totalAmount) /
          two_years_ago_total[0].totalAmount) *
        100
      );
    },
    async finalizeConversion() {
      const UIStore = useUIStore();
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const schemaStore = useSchemaStore();
      const recordDetailStore = useRecordDetailStore();

      try {
        const investmentsToInsert = [];
        const parentIds = this.selectedInvestmentsForConversion.map((investment) => investment._id);

        // Insert All Converted Investments
        this.conversionInvestments.forEach((investment, index) => {
          delete investment.offering;
          delete investment.bond_term;
          delete investment.interest_rate;

          const investmentDoc = createDocumentWithFieldOrder(
            investment,
            schemaStore.all_investment_schema,
            INVESTMENT_DOCUMENT_FIELD_ORDER
          );
          delete investmentDoc._id;

          const today = new Date();
          today.setUTCHours(0, 0, 0, 0);
          investmentDoc.signature_date = today;
          investmentDoc.conversion_parent_ids = parentIds;
          investmentDoc.updated_date = new Date();
          investmentDoc.updated_by_id = authStore.currentUser.id;
          investmentDoc.updated_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
          investmentDoc.created_date = new Date();
          investmentDoc.created_by_id = authStore.currentUser.id;
          investmentDoc.created_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
          if (
            index === 0 &&
            this.conversionDetails.amount_to_add &&
            Number(this.conversionDetails.amount_to_add.replace(/,/g, "")) > 0
          ) {
            investmentDoc.conversion_new_capital = Number(
              this.conversionDetails.amount_to_add.replace(/,/g, "")
            );
          }

          investmentsToInsert.push(investmentDoc);
        });

        const { insertedIds } = await crudStore.insertMany("Investments", investmentsToInsert);

        // Upload All Subs Docs to R2
        const { uploadFile_R2 } = useFileUtility();
        this.conversionSubsToUpload.forEach(async (sub, index) => {
          const investmentId = insertedIds[index];
          await uploadFile_R2({
            file: sub,
            type: "Sub Agreements",
            details: {
              investment_id: { $oid: investmentId },
              account_id: { $oid: recordDetailStore.currentPageDocument.account_id },
              contact_id: { $oid: recordDetailStore.currentPageDocument._id },
              user_id: recordDetailStore.currentPageDocument.user_id,
            },
          });
        });

        // add new investments to the transactions data
        await this.getInvestorTransactions();

        // reset conversion state
        this.resetConversionState();
      } catch (err) {
        console.error(err);
        UIStore.animateNotificationAlert({
          icon: "X",
          message: "Failed to finalize conversion! Please try again.",
        });
      }
    },
    resetConversionState() {
      this.selectedInvestmentsForConversion = [];
      this.conversionDetails = {
        selectedOffering: null,
        amount_to_add: "",
        is_compounded: false,
      };
      this.conversionSubsToUpload = [];
      this.conversionFundingAccount = null;
      this.conversionPaymentMethod = null;
      this.investment_ids_to_process = [];
    },

    //function to update investment amount or discount amount
    async updateInvestmentAmounts(details) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      //remove the commas and make this an actual number
      let investment_amount = Number(details.investment_amount.replace(/,/g, ""));
      let discount_amount = Number(details.discount_amount.replace(/,/g, ""));
      let roundedValue = Math.round(Number(investment_amount) / 1000) * 1000;

      const query = { _id: details._id };

      let update = {
        amount: roundedValue,
        discounted_bond_amount: discount_amount,
        bonds: roundedValue / 1000,
      };

      update = schemaStore.addCreatedUpdatedFields(update, false, true);
      await crudStore.updateOne("Investments", query, { $set: update });
    },
    async generateMonthlyStatement(statementDate, isSendEmail) {
      const investorStore = useInvestorStore();
      const authStore = useAuthStore();
      const crudStore = useCrudStore();
      const UIStore = useUIStore();

      const monthlyStatementDate = new Date(statementDate);

      const message_query = {
        mongodb_record_type: "Account-Statement",
        mongodb_record_id: investorStore?.contact?.account_id,
      };
      const message_update = {
        $set: {
          message: "Process Next Distribution",
          is_ready_for_statement: false,
          is_statement_complete: false,
          modified_by: authStore.currentUser?.id,
          statement_date: monthlyStatementDate,
          is_on_demand: true,
        },
        $setOnInsert: {
          mongodb_record_type: "Account-Statement",
          mongodb_record_id: investorStore?.contact?.account_id,
          created_date: new Date(),
        },
      };

      const contact_email_history_query = {
        contact_id: investorStore.contact?._id,
      };
      let contact_email_history_update;

      if (isSendEmail) {
        contact_email_history_update = {
          $set: {
            monthly_statement: null,
          },
        };
      } else {
        contact_email_history_update = {
          $set: {
            monthly_statement: new Date("2050-01-01"), // set to something in the future to avoid sending email
          },
        };
      }

      try {
        await crudStore.updateOne(
          "ContactEmailHistory",
          contact_email_history_query,
          contact_email_history_update
        );
        await crudStore.updateOne("Messages", message_query, message_update, true);
        this.isGeneratingOneOffStatement = true;
        UIStore.animateNotificationAlert({
          message: `Successfully started statement generation for ${monthlyStatementDate.toLocaleDateString(
            "en-US",
            {
              timeZone: "UTC",
            }
          )}`,
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
});
