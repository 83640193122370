<template>
  <BulkUpdateInvestmentApprovalModal
    :open="bulkUpdateModalOpen"
    @close-modal="bulkUpdateModalOpen = false"
    @refetch-investments="handleRefetchInvestments"
  />
  <QualifiersModal :open="qualifiersModalOpen" @close-modal="qualifiersModalOpen = false" />
  <UpdateInvestmentNotesModal :open="notesModalOpen" @close-modal="notesModalOpen = false" />
  <DocPreviewModal
    :open="docPreviewModalOpen"
    @close-modal="docPreviewModalOpen = false"
    :file-name="file_name"
    :fileContent="file_data"
    :fileType="file_type"
  />
  <div v-if="!page_is_loading" class="p-0 sm:px-10 max-w-[100vw] py-5">
    <AllRecordsTable
      :fields-with-custom-styles="[
        'status',
        'checkbox',
        'approval_status',
        'actions',
        'subagreement_ctas',
      ]"
      @selectAll="handleSelectAll"
      :use-filters="false"
      :is-all-checked="isAllChecked"
    >
      <template v-slot:subagreement_ctas="entry">
        <div class="flex max-w-min items-center space-x-2 px-4 bg-white">
          <Button
            variant="default-gray-outlined"
            icon
            @click="previewSubAgreement(entry.row.row._id.value)"
          >
            <template #icon>
              <Icon name="Eye" class="h-5 w-5" />
            </template>
          </Button>
          <Button
            variant="default-gray-outlined"
            is-icon-only-on-mobile
            icon
            label
            @click="downloadSubAgreement(entry.row.row._id.value)"
          >
            <template #icon>
              <Icon name="Download" class="min-h-4 min-w-4 w-4 h-4" />
            </template>
            <template #label> Download </template>
          </Button>
        </div>
      </template>
      <template v-slot:actions="entry">
        <div class="flex max-w-min items-center space-x-2 px-4 bg-white">
          <Button variant="default-gray-outlined" icon @click="openQualifiers(entry.row.row)">
            <template #icon><Icon name="FileCheck2" class="text-primary-6 w-4" /></template>
          </Button>
          <Button variant="default-gray-outlined" icon @click="openNotesModal(entry.row.row)"
            >http://localhost:5174/investments?recordId=672bf725ca7e5b945ca1f619
            <template #icon>
              <Icon name="NotebookPen" class="h-4 w-4" />
            </template>
          </Button>
        </div>
      </template>
      <template v-slot:checkbox="entry">
        <div class="flex max-w-min items-center space-x-value-8 px-4">
          <input
            @click="select(entry)"
            :checked="
              investmentStore.investment_ids_to_process?.includes(
                entry.row.row._id.value?.toString()
              )
            "
            type="checkbox"
            class="accent-secondary-6"
          />
          <button
            @click="selectOnly(entry)"
            class="text-primary rounded bg-neutral-gray-4 px-4.5 py-0.5 text-xs"
          >
            Only
          </button>
        </div>
      </template>
      <template v-slot:title><span class="text-wrap">Investment Approvals</span></template>
      <template v-slot:additional-ctas>
        <div class="flex flex-row items-center gap-3 mx-3">
          <Button
            variant="default-gray-outlined"
            is-icon-only-on-mobile
            icon
            label
            @click="openBulkUpdateModal"
            :disabled="!investmentStore.investment_ids_to_process.length"
          >
            <template #icon>
              <Icon
                name="RefreshCcw"
                class="h-4 w-4"
                :class="
                  !investmentStore.investment_ids_to_process.length
                    ? 'text-white'
                    : 'text-primary-8'
                "
              />
            </template>
            <template #label> Update </template>
          </Button>
          <Button
            variant="default-gray-outlined"
            is-icon-only-on-mobile
            icon
            label
            @click="
              recordDetailStore.downloadTableRecordsCSV(
                `Investment_Approvals_${new Date().toISOString()}`
              )
            "
          >
            <template #icon> <Icon name="Download" class="min-h-4 min-w-4 w-4 h-4" /> </template>
            <template #label> Download CSV </template>
          </Button>
        </div>
      </template>
      <template v-slot:status="data">
        <div class="ml-4 truncate">
          <div
            class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
            :class="
              data.row.row.status.value == 'Created'
                ? 'bg-custom-light-blue text-custom-light-blue'
                : data.row.row.status.value == 'Settled'
                  ? 'bg-custom-lime-green text-custom-lime-green'
                  : 'bg-functional-warning-default text-functional-warning-hover'
            "
          >
            {{ data.row.row.status.value }}
          </div>
        </div>
      </template>
      <template v-slot:approval_status="data">
        <div class="ml-4 truncate">
          <p v-if="!data.row.row.approval_status?.value">New</p>
          <p v-else-if="data.row.row.approval_status?.value === 'approved'">Approved</p>
          <p v-else-if="data.row.row.approval_status?.value === 'pending'">Pending</p>
          <p v-else-if="data.row.row.approval_status?.value === 'rejected'">Rejected</p>
          <p v-else-if="data.row.row.approval_status?.value === 'Needs Attention'">
            Needs Attention
          </p>
        </div>
      </template>
      <template v-slot:contact_id="data">
        <div class="ml-4 truncate text-gold">
          <a :href="`/contacts?recordId=${data.row.row.contact_id.value}`">
            {{ data.row.row.contact_id.value }}
          </a>
        </div>
      </template>
      <template v-slot:_id="data">
        <div class="ml-4 truncate text-gold">
          <a :href="`/investments?recordId=${data.row.row._id.value}`">
            {{ data.row.row._id.value }}
          </a>
        </div>
      </template>
      <template v-slot:filter-inputs>
        <!-- Desktop/Tablet -->
        <div class="hidden sm:flex sm:flex-row sm:flex-nowrap">
          <div class="flex flex-row md:flex-row flex-wrap items-end gap-4 mt-4 w-full pb-4 grow">
            <div class="relative w-full max-w-64">
              <BaseInput
                v-model="filters._id"
                placeholder="Filter by Investment ID"
                label="Investment ID"
                size="large"
                type="text"
              />
            </div>
            <div class="relative w-full max-w-64">
              <BaseInput
                v-model="filters.contact_id"
                placeholder="Filter by Contact ID"
                label="Contact ID"
                size="large"
                type="text"
              />
            </div>
            <div class="relative w-full max-w-40">
              <BaseInput
                v-model="filters.approval_status"
                :options="['All', ...(approval_status_options || [])]"
                label="Approval Status"
                size="large"
                type="select"
              />
            </div>
            <div class="relative w-full max-w-40">
              <BaseInput
                v-model="filters.offering_type"
                :options="offering_type_options"
                placeholder="All"
                label="Offering Type"
                size="large"
                type="select"
              />
            </div>
            <div class="relative w-full max-w-40">
              <BaseInput
                v-model="filters.payment_method"
                :options="payment_method_options"
                placeholder="All"
                label="Payment Method"
                size="large"
                type="select"
              />
            </div>
            <div class="relative w-full max-w-20">
              <BaseInput
                v-model="filters.has_notes"
                placeholder=""
                label="Has Notes?"
                size="large"
                type="boolean"
              />
            </div>
          </div>
          <div class="flex flex-row items-end gap-4 mt-4 pb-4 justify-self-end justify-end">
            <Button
              variant="default-gray-outlined"
              :label="true"
              size="large"
              @click="clearFilters"
            >
              <template #label> Clear </template>
            </Button>

            <Button :label="true" size="large" @click="applyFilters">
              <template #label> Apply </template>
            </Button>
          </div>
        </div>
        <!-- Mobile -->
        <div class="flex flex-col sm:hidden border-platform-outlines border rounded-xl mt-4">
          <div class="w-full flex justify-between p-4 items-center">
            <span class="font-bold">Filter</span>
            <div
              class="border-platform-outlines border rounded-lg p-1.5 cursor-pointer"
              @click="filterDropOpen = !filterDropOpen"
            >
              <Icon :name="filterDropOpen ? 'ChevronUp' : 'ChevronDown'" class="w-4 h-4" />
            </div>
          </div>
          <div
            class="transition-all duration-300 overflow-hidden"
            :class="filterDropOpen ? 'max-h-screen' : 'max-h-0'"
          >
            <div
              class="flex flex-col md:flex-row flex-wrap items-end gap-4 w-full py-6 px-4 grow bg-neutral-gray-3"
            >
              <div class="relative w-full">
                <BaseInput
                  v-model="filters._id"
                  placeholder="Filter by Investment ID"
                  label="Investment ID"
                  size="large"
                  type="text"
                />
              </div>
              <div class="relative w-full">
                <BaseInput
                  v-model="filters.contact_id"
                  placeholder="Filter by Contact ID"
                  label="Contact ID"
                  size="large"
                  type="text"
                />
              </div>
              <div class="relative w-full">
                <BaseInput
                  v-model="filters.approval_status"
                  :options="approval_status_options"
                  placeholder="All"
                  label="Approval Status"
                  size="large"
                  type="select"
                />
              </div>
              <div class="relative w-full">
                <BaseInput
                  v-model="filters.offering_type"
                  :options="offering_type_options"
                  placeholder="All"
                  label="Offering Type"
                  size="large"
                  type="select"
                />
              </div>
              <div class="relative w-full">
                <BaseInput
                  v-model="filters.payment_method"
                  :options="payment_method_options"
                  placeholder="All"
                  label="Payment Method"
                  size="large"
                  type="select"
                />
              </div>
              <div class="relative w-full">
                <BaseInput
                  v-model="filters.has_notes"
                  placeholder=""
                  label="Has Notes?"
                  size="large"
                  type="boolean"
                />
              </div>
            </div>
            <div class="flex flex-row items-center gap-4 p-4 justify-center">
              <Button
                class="w-full"
                variant="default-gray-outlined"
                label
                size="large"
                @click="clearFilters"
              >
                <template #label> Clear </template>
              </Button>

              <Button class="w-full" label size="large" @click="applyFilters">
                <template #label> Apply </template>
              </Button>
            </div>
          </div>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <div v-else>
    <LoadingSpinner :show="true" message="Loading..." :hide-overlay="true" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";

import {
  useCrudStore,
  useRecordDetailStore,
  useUIStore,
  useInvestmentStore,
  useInvestorStore,
  useSchemaStore,
} from "@/stores";
import {
  Button,
  BaseInput,
  LoadingSpinner,
  AllRecordsTable,
  Icon,
  DocPreviewModal,
} from "@/components";

import BulkUpdateInvestmentApprovalModal from "./BulkUpdateInvestmentApprovalModal.vue";
import QualifiersModal from "@/pages/IdentityChecks/QualifiersModal.vue";
import UpdateInvestmentNotesModal from "./UpdateInvestmentNotesModal.vue";
import useFileUtility from "@/composables/useFileUtility";

const { downloadFile_R2, fetchFileForPreview_R2 } = useFileUtility();

const crudStore = useCrudStore();
const recordDetailStore = useRecordDetailStore();
const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();
const schemaStore = useSchemaStore();

const page_is_loading = ref(false);
const bulkUpdateModalOpen = ref(false);
const qualifiersModalOpen = ref(false);
const notesModalOpen = ref(false);
const docPreviewModalOpen = ref(false);
const filterDropOpen = ref(false);

const file_data = ref(null);
const file_type = ref(null);
const file_name = ref(null);

const filters = ref({
  contact_id: null,
  _id: null,
  approval_status: "New",
  offering_type: null,
  payment_method: null,
  has_notes: null,
});

const approval_status_options = computed(() => {
  let schema = schemaStore.all_investment_schema.find((o) => o.field_name == "approval_status");
  return schema?.record_detail_config?.default?.dropdown_options;
});
const offering_type_options = computed(() => {
  let schema = schemaStore.all_investment_schema.find((o) => o.field_name == "offering_type");
  return schema?.record_detail_config?.default?.dropdown_options;
});
const payment_method_options = computed(() => {
  let schema = schemaStore.all_investment_schema.find((o) => o.field_name == "payment_method");
  return schema?.record_detail_config?.default?.dropdown_options;
});

const default_approval_filter = {
  signature_date: { $nin: ["", null] },
  status: { $in: ["Created", "Funded", "Settled"] },
  all_contacts_identity_verified: true,
};

const investmentApprovalTableHeaders = ref([
  { field_name: "approval_status", label: "Approval Status" },
  {
    field_name: "_id",
    label: "Investment ID",
    has_copy: true,
    associated_collection: "Investments",
  },
  {
    field_name: "contact_id",
    label: "Contact ID",
    has_copy: true,
    associated_collection: "Contacts",
  },
  { field_name: "owner_contact_full_name", label: "Name" },
  { field_name: "signature_date", label: "Signature Date", field_type: "date" },
  { field_name: "created_date", label: "Created Date", field_type: "date" },
  { field_name: "type", label: "Type" },
  { field_name: "status", label: "Status" },
  { field_name: "offering_type", label: "Offering" },
  { field_name: "amount", label: "Amount", number_type: "currency" },
  { field_name: "payment_method", label: "Payment" },
  {
    field_name: "actions",
    label: "Actions",
    actions: { cta: true, hide_sort: true },
  },
  {
    field_name: "subagreement_ctas",
    label: "Sub Agreement",
    actions: { cta: true, hide_sort: true },
  },
]);

onMounted(onMountedFunction);

onUnmounted(() => {
  recordDetailStore.customTableHeaders = [];
  recordDetailStore.defaultFilterPipeline = null;
  investmentStore.investment_ids_to_process = [];
});

watch(
  () => recordDetailStore.allRecordsPageNumber,
  async () => {
    await recordDetailStore.fetchTableRecords(false, false, investmentApprovalTableHeaders.value);
  }
);

async function onMountedFunction() {
  page_is_loading.value = true;
  recordDetailStore.customTableHeaders = investmentApprovalTableHeaders.value;
  recordDetailStore.currentPageCollection = "Investments";

  await recordDetailStore.setCurrentPageConfig();
  setCustomConfig();

  recordDetailStore.filterPipeline = {};
  recordDetailStore.defaultFilterPipeline = default_approval_filter;

  await applyFilters(); // apply filters on first load to only get "New" investments
  page_is_loading.value = false;
}

const isAllChecked = computed(() => {
  if (recordDetailStore.allRecords.length === 0) {
    return false;
  }

  return recordDetailStore.allRecords.every((record) =>
    investmentStore.investment_ids_to_process.includes(record._id?.value?.toString())
  );
});

function handleSelectAll() {
  let selectAllInput = document.getElementById("select-all");

  if (!selectAllInput.checked) {
    // Remove the IDs of the records on the current page from the investment_ids_to_process array
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) =>
        !recordDetailStore.allRecords.some(
          (record) => record._id.value.toString() === id.toString()
        )
    );
    investmentStore.investments_for_approvals = investmentStore.investments_for_approvals.filter(
      (investment) =>
        !recordDetailStore.allRecords.some(
          (record) => record._id.value.toString() === investment._id.value.toString()
        )
    );
  } else {
    // Add the IDs of the records on the current page to the investment_ids_to_process array
    const currentIds = recordDetailStore.allRecords.map((record) => record._id?.value?.toString());
    const uniqueIds = new Set([...investmentStore.investment_ids_to_process, ...currentIds]);
    investmentStore.investment_ids_to_process = Array.from(uniqueIds);

    // Add the records on the current page to the investments_for_approvals array
    investmentStore.investments_for_approvals = recordDetailStore.allRecords;
  }
}

function selectOnly(entry) {
  const recordId = entry.row.row._id.value?.toString();

  // Clear the array and add only the selected ID
  investmentStore.investment_ids_to_process = [recordId];
  investmentStore.investments_for_approvals = [entry.row.row];
}

function select(entry) {
  const recordId = entry.row.row._id.value?.toString();

  if (investmentStore.investment_ids_to_process.includes(recordId)) {
    // If the ID is already in the array, remove it
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) => id !== recordId
    );
    investmentStore.investments_for_approvals = investmentStore.investments_for_approvals.filter(
      (investment) => investment._id.value !== recordId
    );
  } else {
    // If the ID is not in the array, add it
    investmentStore.investment_ids_to_process.push(recordId);
    investmentStore.investments_for_approvals.push(entry.row.row);
  }
}

function setCustomConfig() {
  recordDetailStore.currentPageConfig.icon = "IconApproval";
  recordDetailStore.currentPageConfig.icon_color_bg = "bg-custom-light-blue";
}

function openBulkUpdateModal() {
  bulkUpdateModalOpen.value = true;
}

async function openQualifiers(investment) {
  let id = investment.contact_id.value;
  investorStore.investor_to_process = await crudStore.findOne("Contacts", { _id: id });
  qualifiersModalOpen.value = true;
}

async function openNotesModal(investment) {
  let id = investment._id.value;
  investmentStore.investment_to_process = await crudStore.findOne("Investments", { _id: id });
  notesModalOpen.value = true;
}

async function previewSubAgreement(id) {
  let subAgreement = await crudStore.findOne("Documents", {
    type: "Sub Agreements",
    investment_id: id,
  });
  if (!subAgreement) {
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to find Subscription Agreement!",
      subText: "This could be from a legacy investment.",
      is_read: false,
      does_persist: false,
      action: null,
    });
    return;
  }

  file_type.value = subAgreement?.file_type;
  file_data.value = await fetchFileForPreview_R2(subAgreement);
  file_name.value = subAgreement?.file_name;
  docPreviewModalOpen.value = true;
}

async function downloadSubAgreement(id) {
  let subAgreement = await crudStore.findOne("Documents", {
    type: "Sub Agreements",
    investment_id: id,
  });
  if (!subAgreement) {
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to find Subscription Agreement!",
      subText: "This could be from a legacy investment.",
      is_read: false,
      does_persist: false,
      action: null,
    });
    return;
  }
  await downloadFile_R2(subAgreement);
}

async function clearFilters() {
  filters.value = {
    contact_id: null,
    _id: null,
    approval_status: "New",
    offering_type: null,
    payment_method: null,
    has_notes: false,
  };
  recordDetailStore.filterPipeline = null;
  await applyFilters();
}

async function applyFilters() {
  recordDetailStore.filterPipeline = null;
  let filter = {};

  for (let key in filters.value) {
    if (!filters.value[key]) {
      continue;
    }
    filter[key] = filters.value[key];
    delete filter["has_notes"];

    if (key == "approval_status") {
      if (filters.value[key] == "All") {
        delete filter[key];
      }
      if (filters.value[key] == "New") {
        filter[key] = "";
      }
      if (filters.value[key] == "Needs Attention") {
        filter[key] = "Needs Attention";
      }
      if (filters.value[key] == "Approved") {
        filter[key] = "approved";
      }
      if (filters.value[key] == "Pending") {
        filter[key] = "pending";
      }
      if (filters.value[key] == "Rejected") {
        filter[key] = "rejected";
      }
    }

    if (key == "has_notes") {
      if (filters.value[key] == true) {
        filter["notes"] = { $nin: [null, ""] };
      }
    }

    // break up contact_id string into array of contact_ids
    if (key == "contact_id" || key == "_id") {
      let in_array = [];
      for (let id of filters.value[key].split(",")) {
        in_array.push({ $oid: id.trim() });
      }

      filter[key] = { $in: in_array };
    }
  }

  // if filter has any keys set, then set the filterPipeline to the filter object
  if (Object.keys(filter).length > 0) {
    recordDetailStore.filterPipeline = filter;
  }

  await recordDetailStore.fetchTableRecords(false, false, investmentApprovalTableHeaders.value);
}

async function handleRefetchInvestments() {
  await applyFilters();
  investmentStore.investment_ids_to_process = [];
  investmentStore.investments_for_approvals = [];
}
</script>
