<template>
  <div
    id="pagination"
    class="flex w-full items-center justify-between"
    :class="{ 'px-4 md:px-0': !showCount }"
  >
    <h3 v-if="showCount" class="text-sm text-lighter">
      <span class="hidden md:inline">Showing </span
      >{{
        1 +
        (curPage - 1) * pageSize +
        " - " +
        Math.min(curPage * pageSize, props.totalCategoryResults) +
        " of " +
        props.totalCategoryResults
      }}
    </h3>
    <div class="flex gap-1">
      <div
        @click="prevPage(), $emit('changePage', curPage)"
        class="flex h-8 w-8 items-center justify-center rounded border border-neutral-gray-5 bg-neutral-gray-1 hover:bg-neutral-gray-4 text-xs font-medium md:h-9 md:w-9 transition-all duration-200"
        :class="curPage == 1 ? 'cursor-default opacity-30' : 'cursor-pointer opacity-100'"
      >
        <IconChevronLeft class="mx-2 min-h-4 min-w-4" />
      </div>
      <div
        v-for="(page, index) in pageButtonsFull"
        @click="changePage(page), $emit('changePage', curPage)"
        class="hidden h-8 cursor-pointer select-none items-center justify-center rounded border px-3 text-xs font-medium md:flex md:h-9 transition-all duration-200"
        :class="[
          page == '...' ? 'min-w-9' : 'w-fit-content min-w-7 md:min-w-9',
          curPage == page
            ? 'bg-active-pagination-color border-primary-1 text-dark'
            : 'bg-neutral-gray-1 hover:bg-neutral-gray-4',
        ]"
      >
        {{ commaSeparateThousands(page) }}
      </div>

      <div
        v-for="(page, index) in pageButtonsMobile"
        @click="changePage(page), $emit('changePage', curPage)"
        class="flex h-8 cursor-pointer select-none items-center justify-center rounded border px-3 text-xs font-medium md:hidden md:h-9 transition-all duration-200"
        :class="[
          page == '...' ? 'min-w-9' : 'w-fit-content  min-w-7 md:min-w-9',
          curPage == page
            ? 'bg-active-pagination-color border-primary-1 text-dark'
            : 'bg-neutral-gray-1 hover:bg-neutral-gray-4',
        ]"
      >
        {{ page }}
      </div>

      <div
        @click="nextPage(), $emit('changePage', curPage)"
        class="flex h-8 w-8 items-center justify-center rounded border border-neutral-gray-5 bg-neutral-gray-1 hover:bg-neutral-gray-4 text-xs font-medium md:h-9 md:w-9 transition-all duration-200"
        :class="
          curPage == getPageCount() ? 'cursor-default opacity-30' : 'cursor-pointer opacity-100'
        "
      >
        <IconChevronRight class="mx-2 min-h-4 min-w-4" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { IconChevronLeft, IconChevronRight } from "@/components/icons";
import { ref, computed, watch } from "vue";
import { useUIStore } from "../../stores/useUIStore";
import { commaSeparateThousands } from "@/utilities";
const UIStore = useUIStore();

const props = defineProps({
  showCount: Boolean,
  totalCategoryResults: Number,
  pageSize: Number,
  pageNum: Number,
});
const curPage = ref(props.pageNum);
const pageButtonsFull = computed(() => {
  let pageCount = getPageCount();
  let arr = [];

  // 7 or less pages
  if (pageCount <= 7) {
    for (var i = 1; i <= pageCount; i++) {
      arr.push(i);
    }
    return arr;
  }

  // first 5 pages
  if (curPage.value <= 5) {
    for (var i = 1; i <= 5; i++) {
      arr.push(i);
    }
    arr.push("...");
    arr.push(pageCount);
    return arr;
  }

  // last 5 pages
  if (curPage.value >= pageCount - 4) {
    arr.push(1);
    arr.push("...");
    for (var i = pageCount - 4; i <= pageCount; i++) {
      arr.push(i);
    }
    return arr;
  }

  // middle pages
  arr.push(1);
  arr.push("...");
  arr.push(curPage.value - 1);
  arr.push(curPage.value);
  arr.push(curPage.value + 1);
  arr.push("...");
  arr.push(pageCount);
  return arr;
});
const pageButtonsMobile = computed(() => {
  let pageCount = getPageCount();
  let arr = [];

  // 5 or less pages
  if (pageCount <= 5) {
    for (var i = 1; i <= pageCount; i++) {
      arr.push(i);
    }
    return arr;
  }

  // first 3 pages
  if (curPage.value <= 3) {
    for (var i = 1; i <= 3; i++) {
      arr.push(i);
    }
    arr.push("...");
    arr.push(pageCount);
    return arr;
  }

  // last 3 pages
  if (curPage.value >= pageCount - 2) {
    arr.push(1);
    arr.push("...");
    for (var i = pageCount - 2; i <= pageCount; i++) {
      arr.push(i);
    }
    return arr;
  }

  // middle pages
  arr.push(1);
  arr.push("...");
  arr.push(curPage.value);
  arr.push("...");
  arr.push(pageCount);
  return arr;
});

changePage(1);

function prevPage() {
  changePage(curPage.value - 1);
}

function nextPage() {
  changePage(curPage.value + 1);
}

function changePage(page) {
  if (page == "...") return;
  if (page < 1) return;
  if (page > getPageCount()) return;
  curPage.value = page;
}

defineExpose({ curPage });

watch(
  () => props.pageNum,
  () => {
    (() => {
      changePage(props.pageNum);
      // if (UIStore.isSideDrawerOpen) {
      //   changePage(props.pageNum);
      // }
    })();
  }
);

function getPageCount() {
  if (props.pageSize == undefined || props.totalCategoryResults == undefined) return null;
  return Math.ceil(props.totalCategoryResults / props.pageSize);
}
</script>
