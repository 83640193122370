import { createRouter, createWebHistory } from "vue-router";
import {
  Signin,
  Dashboard,
  MaintenanceScreen,
  ForgotPassword,
  ChangePassword,
  MFA,
  VerificationResult,
  DataModelManagement,
  ManageModel,
  ListManager,
  R2Testing,
  Contacts,
  Investments,
  InvestmentApprovals,
  IdentityChecks,
  ACHBatches,
  AdminPermissions,
  Marketing,
  Custodians,
  OfferingManagement,
  DocumentManagement,
  AuthLogs,
  History,
} from "@/pages";
import { useAuthStore, useCrudStore } from "@/stores";

let routes = [];

if (import.meta.env.VITE_MAINTANENCE_MODE === "true") {
  routes = [
    {
      path: "/:pathMatch(.*)*",
      redirect: "/maintenance",
      meta: { requires_auth: false },
    },
    {
      path: "/maintenance",
      name: "MaintenanceScreen",
      component: MaintenanceScreen,
      meta: { requires_auth: false },
    },
  ];
} else {
  routes = [
    {
      path: "/:catchAll(.*)",
      redirect: "/dashboard",
    },
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: { requires_auth: true },
    },
    {
      path: "/investment-ach-distributions",
      name: "ach-batches",
      component: ACHBatches,
      meta: { requires_auth: true },
    },
    {
      path: "/manage-model",
      name: "manage-model",
      component: ManageModel,
      meta: { requires_auth: true, is_admin: true },
    },
    {
      path: "/list-manager",
      name: "list-manager",
      component: ListManager,
      meta: { requires_auth: true, is_admin: true },
    },
    {
      path: "/data-model-management",
      name: "data-model-management",
      component: DataModelManagement,
      meta: { requires_auth: true, is_admin: true },
    },
    {
      path: "/permissions",
      name: "permissions",
      component: AdminPermissions,
      meta: { requires_auth: true, is_admin: true },
    },
    {
      path: "/marketing",
      name: "marketing",
      component: Marketing,
      meta: { requires_auth: true },
    },
    {
      path: "/custodians",
      name: "custodians",
      component: Custodians,
      meta: { requires_auth: true },
    },
    {
      path: "/offering-management",
      name: "offering-management",
      component: OfferingManagement,
      meta: { requires_auth: true },
    },
    {
      path: "/auth-logs",
      name: "auth-logs",
      component: AuthLogs,
      meta: { requires_auth: true },
    },
    {
      path: "/history",
      name: "history",
      component: History,
      meta: { requires_auth: true },
    },
    {
      path: "/document-management",
      name: "document-management",
      component: DocumentManagement,
      meta: { requires_auth: true },
    },
    {
      path: "/r2-testing",
      name: "r2-testing",
      component: R2Testing,
      meta: { requires_auth: true, is_admin: true },
    },
    {
      path: "/contacts",
      name: "contacts",
      component: Contacts,
      meta: { requires_auth: true, is_dalmore: true },
    },
    {
      path: "/investments",
      name: "investments",
      component: Investments,
      meta: { requires_auth: true, is_dalmore: true },
    },
    {
      path: "/investment-approvals",
      name: "investment-approvals",
      component: InvestmentApprovals,
      meta: { requires_auth: true, is_dalmore: true },
    },
    {
      path: "/identity-checks",
      name: "identity-checks",
      component: IdentityChecks,
      meta: { requires_auth: true },
    },
    {
      path: "/sign-in",
      name: "signin",
      component: Signin,
      meta: { requires_auth: false },
    },
    {
      path: "/auth-verification",
      name: "auth-verification",
      component: MFA,
      meta: { requires_auth: false },
    },
    {
      path: "/verification-result",
      name: "verification-result",
      component: VerificationResult,
      meta: { requires_auth: true },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
      meta: { requires_auth: false },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: ChangePassword,
      meta: { requires_auth: false },
    },
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Variable to store previous route
let previousRoute = null;

// Global beforeEach guard for previous route storing
router.beforeEach((to, from, next) => {
  previousRoute = from; // Capture the previous route
  next();
});

if (import.meta.env.VITE_MAINTENANCE !== "true") {
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const crudStore = useCrudStore();

    if (authStore.currentUser) {
      if (!authStore.adminUserData) {
        authStore.adminUserData = await crudStore.findOne("AdminUserData", {
          user_id: authStore.currentUser.id,
        });
      }

      let permissions = authStore?.adminUserData?.permissions;
      if (to.meta.requires_auth) {
        // master-admins can access any route
        if (permissions.includes("master-admin")) {
          next();
          return;
        }

        // only master admins can access AdminPermissions route
        if (to.meta.is_admin && !permissions.includes("master-admin")) {
          next("/investments");
          return;
        }

        // only master-admin, finance-admin, and dalmore-admin can access InvestmentApprovals route
        if (
          to.name === "investment-approvals" &&
          !(permissions.includes("finance-admin") || permissions.includes("dalmore-admin"))
        ) {
          next("/investments");
          return;
        }

        if (to.name === "marketing" && !permissions.includes("marketing-admin")) {
          next("/investments");
          return;
        }

        // users with dalmore-admin can only access dalmore meta routes
        if (permissions.includes("dalmore-admin")) {
          if (to.meta.is_dalmore) {
            next();
            return;
          } else {
            next("/investments");
            return;
          }
        }

        // users with marketing-admin permission can only access dashboard and reports route
        if (permissions.includes("marketing-admin")) {
          if (to.name === "dashboard" || to.name === "marketing") {
            next();
            return;
          } else {
            next("/marketing");
            return;
          }
        }

        // default case
        next();
        return;
      }

      // handle authenticated users trying to manually go to guest routes
      if (to.matched.some((record) => record.meta.requires_auth === false)) {
        next("/investments");
        return;
      }
    } else {
      // handle guest routes
      if (to.matched.some((record) => record.meta.requires_auth === false)) {
        next();
      } else {
        // default case
        next("/sign-in");
      }
    }
  });
}

export default router;
export { previousRoute };
