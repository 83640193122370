<template>
  <aside
    id="sidebar"
    class="fixed left-0 top-0 z-40 mt-14 h-full overflow-x-hidden bg-primary-5 text-white transition-all duration-200 ease-in-out lg:pb-0"
    :class="{
      'w-0 lg:w-sidenav-closed': !UIStore.isSideNavigationOpen,
      'w-full md:w-sidenav-open': UIStore.isSideNavigationOpen,
    }"
  >
    <nav class="no-scrollbar flex h-[calc(100vh-3.5rem)] flex-col gap-1.5 overflow-y-auto pb-32">
      <NavigationItem
        v-for="item in navItems"
        @click="!item.nestedItems ? (UIStore.isSideNavigationOpen = false) : null"
        :title="item.title"
        :icon="item.icon"
        :iconName="item.iconName"
        :nav_route="item.route"
        :nestedItems="item.nestedItems || null"
      />
    </nav>
  </aside>
</template>

<script setup>
import { onMounted, onUnmounted, ref, markRaw } from "vue";

import { NavigationItem } from "@/components";
import { IconPhxCapLogo, IconX, IconInvestment, IconIdentity, IconKey } from "@/components/icons";
import { useUIStore, useAuthStore, useCrudStore } from "@/stores";

const UIStore = useUIStore();
const authStore = useAuthStore();
const crudStore = useCrudStore();

const nonAdminRouteList = [
  { title: "Dashboard", iconName: "Bell", route: "/dashboard" },
  { title: "Contacts", iconName: "UsersRound", route: "/contacts" },
  { title: "Investments", icon: markRaw(IconInvestment), route: "/investments" },
  { title: "Approvals", iconName: "BadgeCheck", route: "/investment-approvals" },
  { title: "Identity Checks", icon: markRaw(IconKey), route: "/identity-checks" },
  { title: "ACH Batches", iconName: "CircleDollarSign", route: "/investment-ach-distributions" },
  { title: "Marketing", iconName: "Megaphone", route: "/marketing" },
  { title: "Custodians", iconName: "Landmark", route: "/custodians" },
  { title: "History", iconName: "History", route: "/history" },
];

const navItems = ref([]);

onMounted(async () => {
  document.addEventListener("click", handleClickOutside);

  authStore.adminUserData = await crudStore.findOne("AdminUserData", {
    user_id: authStore.currentUser.id,
  });

  const userPermissions = authStore.adminUserData?.permissions || [];
  const userNavigationItems = authStore.adminUserData?.navigation || [];

  const routeNamesForPermission = userNavigationItems.map((item) => item.name);
  for (let item of nonAdminRouteList) {
    if (routeNamesForPermission.includes(item.title)) {
      navItems.value.push(item);
    }
  }

  // If user is an admin, set all items to be visible
  if (userPermissions.includes("master-admin")) {
    navItems.value.forEach((item) => (item.is_visible = true));
    navItems.value.push({
      title: "Admin",
      icon: markRaw(IconPhxCapLogo),
      route: null,
      nestedItems: [
        { title: "Admin Permissions", route: "/permissions" },
        { title: "Data Model Management", route: "/data-model-management" },
        { title: "Offering Management", route: "/offering-management" },
        { title: "Auth Logs", route: "/auth-logs" },
        { title: "Document Management", route: "/document-management" },
      ],
    });
  }
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function handleClickOutside(event) {
  if (!event.target.closest("#sidebar")) {
    UIStore.isSideNavigationOpen = false;
  }
}
</script>
