<template>
  <div
    v-if="
      !titleTransferStore.investment_being_created &&
      !titleTransferStore.show_investment_completion_screen
    "
    class="flex flex-col gap-8 mb-4"
  >
    <div>
      <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
        Review and Complete Investment Split
      </div>
    </div>
    <div class="pb-4 border-b border-platform-outlines">
      <div class="font-bold">Split Children Being Created</div>
      <div class="mt-4">
        <div class="text-sm text-light">
          <div class="flex flex-col gap-2">
            <div v-for="(split, index) of titleTransferStore.split_investments">
              <div class="flex-col md:flex-row flex gap-5 items-end mb-5">
                <BaseInput
                  v-model="split.bond_amount"
                  type="string"
                  label="Total Bonds"
                  size="large"
                  disabled
                  formatRule="amount"
                  :hideCurrencyUI="true"
                />
                <BaseInput
                  :placeholder="getPlaceHolder(split)"
                  type="string"
                  label="Amount"
                  disabled
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-8 border-b border-platform-outlines">
      <div class="font-bold">Split Date</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          v-model="date"
          type="date"
          size="large"
          :disabled="true"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>

    <div>
      <div class="font-semibold text-lg">Upload Signed Sub Agreement</div>
      <div class="my-2">Please verify before submitting that the:</div>
      <ul class="list-disc pl-5 text-gray-500">
        <li>
          Name on the sub agreement matches the name in the personal information form you filled out
        </li>
        <li>Sensitive information filled out (banking, SSN, EIN) matches what is on the sub</li>
        <li>Joint signature exists on the sub agreeement in the case of a joint investment</li>
      </ul>
    </div>
    <div>
      <div v-if="titleTransferStore.uploaded_split_subagreeement">
        <div class="mb-4 flex items-center border p-4 rounded-md shadow-sm">
          <div class="flex-grow">
            <div class="text-sm">
              {{ titleTransferStore.uploaded_split_subagreeement.name }}
            </div>
          </div>

          <div class="flex gap-1">
            <Button @click="removeFile()" icon variant="default-gray-outlined">
              <template #icon>
                <Icon
                  name="Trash"
                  class="h-4.5 w-4.5 stroke-functional-error-default"
                  :stroke-width="2"
              /></template>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <input @change="handleUpload" ref="fileInputRef" type="file" hidden />
        <div
          class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0 bg-light bg-opacity-20"
          :class="dragging ? 'border-blue-500 bg-blue-100' : 'bg-light bg-opacity-20'"
          @drop="drop"
          @dragover.prevent="dragging = true"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <IconX class="h-6 w-6 fill-cTeal" />
          <p>Drag the file here or click upload</p>

          <div class="mt-4">
            <Button @click="chooseFiles" label>
              <template #label>{{
                titleTransferStore.uploaded_split_subagreeement ? "Replace File" : "Upload File"
              }}</template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="titleTransferStore.investment_being_created">Investment being created ...</div>
  <div v-if="titleTransferStore.show_investment_completion_screen">Investment complete</div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { commaSeparateThousands } from "@/utilities";
import { Button, BaseInput } from "@/components";
import { Icon } from "@/components";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();
const fileInputRef = ref(null);

const dragging = ref(false);
const uploadState = ref("idle"); // 'idle', 'uploading', 'completed'
const uploadProgress = ref(0);
const date = ref(new Date().toISOString().split("T")[0]);
let uploadTimeout = null;

const removeFile = () => {
  titleTransferStore.uploaded_split_subagreeement = null;
};

const simulateUpload = () => {
  uploadState.value = "uploading";
  uploadProgress.value = 0;
  uploadTimeout = setInterval(() => {
    uploadProgress.value += 50; // Increase by 50% every second
    if (uploadProgress.value >= 100) {
      clearInterval(uploadTimeout);
      uploadState.value = "completed";
    }
  }, 1000);
};

const chooseFiles = () => {
  fileInputRef.value.click();
};
function getPlaceHolder(split) {
  let num_val = split.bond_amount.replace(/,/g, "");
  return `$ ${commaSeparateThousands(num_val * 1000)}`;
}

const handleUpload = async (e) => {
  titleTransferStore.uploaded_split_subagreeement = e.target.files[0];
  simulateUpload();
};

const drop = (e) => {
  e.preventDefault();
  dragging.value = false;
  titleTransferStore.uploaded_split_subagreeement = e.dataTransfer.files[0];
  simulateUpload();
};
</script>
