<template>
  <Modal :open="props.open" @close-modal="$emit('close-modal')" size="36x46" :hideModalTabs="false">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2">One-Off KYC</div>
      </div>
    </template>
    <template v-slot:modal-tabs>
      <div class="no-scrollbar ml-4 flex overflow-x-scroll border-b border-neutral-gray-4">
        <!-- Render tabs -->
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          @click="tab === 'Arrow' ? '' : (activeTab = tab)"
          :class="[
            activeTab === tab
              ? 'border-b-3 border-secondary-6  bg-opacity-20 font-bold'
              : 'border-b-3 border-transparent',
            tab === 'Arrow' ? 'pl-2 pr-1' : 'p-3',
          ]"
          class="flex flex-grow-0 cursor-pointer flex-row items-center justify-center whitespace-nowrap"
        >
          <div class="flex flex-row items-center text-left text-base text-primary-6">
            <div v-if="tab !== 'Arrow'">{{ tab }}</div>
            <div v-else><IconChevronRight class="h-value-12 w-value-12" /></div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div
        class="h-full w-full bg-neutral-gray-2 gap-x-4 p-4 grid grid-cols-2"
        v-if="activeTab == 'Data'"
      >
        <BaseInput
          :required="true"
          label="First Name"
          v-model="one_off_contact.first_name"
          type="string"
          size="large"
          showFullText
          format-rule="first_name"
        />
        <BaseInput
          :required="true"
          label="Last Name"
          v-model="one_off_contact.last_name"
          type="string"
          size="large"
          showFullText
          format-rule="last_name"
        />
        <BaseInput
          :required="true"
          label="City"
          v-model="one_off_contact.city"
          type="string"
          size="large"
          showFullText
        />
        <BaseInput
          :required="true"
          label="State"
          v-model="one_off_contact.state"
          placeholder="Select State"
          type="select"
          :options="states"
          size="large"
          showFullText
        />
        <BaseInput
          :required="true"
          label="Address"
          v-model="one_off_contact.address"
          type="string"
          size="large"
          showFullText
        />
        <BaseInput
          :required="true"
          label="Zip Code"
          v-model="one_off_contact.zip_code"
          type="string"
          size="large"
          showFullText
        />
        <BaseInput
          :required="true"
          label="SSN"
          v-model="one_off_contact.ssn"
          type="string"
          size="large"
          showFullText
          format-rule="ssn"
        />
        <BaseInput
          :required="true"
          label="Date of Birth"
          v-model="one_off_contact.dob"
          type="date"
          size="large"
          showFullText
        />
      </div>
      <div
        v-else-if="activeTab == 'Results'"
        class="flex items-center justify-center h-full w-full"
      >
        <div v-if="isFetchingKYC">
          <LoadingSpinner
            :show="true"
            :hideOverlay="true"
            message="Fetching results..."
            position="absolute"
          />
        </div>
        <div
          v-else-if="kycData.result"
          class="flex flex-col items-center justify-center h-full w-full gap-8"
        >
          <span
            :key="(summary = kycData.summaryResult)"
            class="text-semibold text-3xl"
            :class="summary ? 'text-functional-error-default' : 'text-functional-success-default'"
            >{{ summary }}</span
          >
          <span class="text-lg">Result: {{ kycData.result }}</span>
        </div>
        <div v-else>Run KYC/AML to see results.</div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template></Button
        >
        <Button size="large" :disabled="isSaveDisabled" label @click="handleOneOffKYC">
          <template #label>Run KYC/AML</template></Button
        >
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconInvestment } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRecordDetailStore, useCrudStore, useAuthStore } from "@/stores";
import { Modal, Button, BaseInput, LoadingSpinner } from "@/components";
import { iconMapping } from "@/constants/iconMapping";
import states from "@/constants/states";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();
const isFetchingKYC = ref(false);

const tabs = ref(["Data", "Results"]);
const activeTab = ref("Data");

const one_off_contact = ref({
  first_name: "",
  last_name: "",
  city: "",
  state: "",
  address: "",
  zip_code: "",
  ssn: "",
  dob: "",
});

const kycData = ref({});

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

// define emits
const emit = defineEmits(["close-modal"]);

// declare props open
// const props = defineProps(["open"]);

onMounted(async () => {});

async function runKYC() {
  emit("close-modal");
}

const custodians = ref([]);
const investmentTypes = ref([]);

const isSaveDisabled = computed(() => {
  // ensure that all fields we fill in for one_off_contact are not empty strings
  return Object.values(one_off_contact.value).some((field) => field === "");
});

async function handleOneOffKYC() {
  isFetchingKYC.value = true;
  activeTab.value = "Results";
  try {
    let request_body = {
      personal_info: {
        first_name: one_off_contact.value.first_name,
        last_name: one_off_contact.value.last_name,
        city: one_off_contact.value.city,
        state: one_off_contact.value.state.abbr,
        address: one_off_contact.value.address,
        zip: one_off_contact.value.zip_code,
        ssn: one_off_contact.value.ssn,
        dob: new Date(one_off_contact.value.dob).toLocaleDateString("en-US"),
      },
    };
    // let contact_object = {
    //   ...one_off_contact.value,
    //   state: one_off_contact.value.state.abbr,
    // };
    // set date format fo mm/dd/yyyy from object
    //contact_object.dob = new Date(contact_object.dob).toLocaleDateString("en-US");
    console.log(request_body);
    const response = await fetch(
      `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/run-expectid-iq`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          user_id: authStore.currentUser.id,
          session_id: authStore.currentUser.session_id,
        },
        body: JSON.stringify(request_body),
      }
    );
    var result = await response.json();
    if (result) {
      result = result.response;
      console.log(JSON.stringify(result, null, 2));

      if (result?.response?.error) {
        kycData.value = {
          result: "Input Error",
          summaryResult: result?.response?.error,
          qualifiers: [{ message: "No Qualifiers" }],
        };
      } else {
        kycData.value = {
          result: result?.response?.results?.message || result?.error,
          summaryResult: result?.response?.["summary-result"]?.message || "",
          qualifiers: result?.response?.qualifiers?.qualifier
            ? result?.response?.qualifiers?.qualifier?.length > 0
              ? result?.response?.qualifiers?.qualifier
              : [result?.response?.qualifiers?.qualifier]
            : [{ message: "No Qualifiers" }],
        };
        if (result?.response?.restriction) {
          kycData.value.qualifiers.push({ message: result?.response?.restriction?.message });
        }
      }
    }
  } catch (e) {
    console.error(e);
    // await authStore.currentUser.functions.Create_Custom_Log(
    //   authStore.currentUser.id,
    //   "Error",
    //   `Error running one-off KYC check`,
    //   e.message,
    //   "handleOneOffKYC - IdentityChecksSearch.vue"
    // );
    // UIStore.setAlert({
    //   title: "There was an error!",
    //   message: "There was an error running the KYC/AML check, please try again.",
    //   type: "error",
    // });
  } finally {
    isFetchingKYC.value = false;
  }
}
</script>
