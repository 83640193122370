<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ titleTransferStore.currentStep + 1 }} : Entity Information
    </div>
  </div>
  <div class="flex flex-col">
    <div
      v-for="(group, index) in schemaStore.entity_information"
      :key="index"
      :class="{
        'py-8 border-b border-platform-outlines':
          index !== schemaStore.entity_information.length - 1,
        'py-8': index === schemaStore.entity_information.length - 1,
      }"
    >
      <div class="font-semibold text-lg mb-4">
        {{ group.group_name }}
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="field of group.fields">
          <BaseInput
            :required="field.is_required"
            :disabled="
              field.read_only ||
              (titleTransferStore.entity_template.has_existing_investments &&
                schemaStore.admin_disabled_entity_account_fields.includes(field.field_name))
            "
            :label="field.label"
            v-if="!field.is_association_field && !field.belongs_to_nested_object"
            v-model="titleTransferStore.entity_template[field.field_name]"
            :formatRule="field.field_name"
            :errorMessage="field.error_message"
            :type="field.field_type"
            :options="field.dropdown_options"
            size="large"
            customClass="bg-neutral-gray-1"
          ></BaseInput>
          <BaseInput
            :required="field.is_required"
            :disabled="field.read_only || !titleTransferStore.add_entity_account"
            :formatRule="field.field_name"
            :errorMessage="field.error_message"
            :label="field.label"
            v-if="!field.is_association_field && field.belongs_to_nested_object"
            v-model="titleTransferStore.entity_template[field.nested_object_name][field.field_name]"
            :options="field.dropdown_options"
            :type="field.field_type"
            size="large"
            customClass="bg-neutral-gray-1"
          ></BaseInput>
          <div
            v-if="field.field_name === 'ein' && ein_is_invalid"
            class="text-functional-error-default text-sm my-1"
          >
            Invalid EIN. EIN can not match the EIN of any othe Entity Accounts related to this owner
          </div>
        </div>
      </div>
    </div>
    <AddBeneficialOwners />
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";

import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { BaseInput } from "@/components";

import AddBeneficialOwners from "./AddBeneficialOwners.vue";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();

onMounted(async () => {
  window.scrollTo(0, 0);
});

const ein_is_invalid = computed(() => {
  const entityAccountTemplate = titleTransferStore.entity_template;
  const existingAccounts = titleTransferStore.existing_entity_accounts;
  const invalidEINs = titleTransferStore.invalid_ein_array;

  // Find the account in existing enity account with the same _id
  const matchingAccount = existingAccounts.find(
    (account) => account._id === entityAccountTemplate._id
  );
  let val;
  if (matchingAccount) {
    val =
      entityAccountTemplate.ein !== matchingAccount.ein &&
      invalidEINs.includes(entityAccountTemplate.ein);
  } else {
    val = invalidEINs.includes(entityAccountTemplate.ein);
  }
  titleTransferStore.entity_ein_is_invalid = val;
  return val;
});
</script>
