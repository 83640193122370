<template>
  <Modal :open="props.open" @close-modal="$emit('close-modal')" size="36x46" :hideModalTabs="true">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <!-- <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div> -->

        <div class="font-bold text-custom-navy-2">Affected Investments</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white flex items-center justify-center" v-if="props.loading">
        <LoadingSpinner
          :show="true"
          message="Loading..."
          :hide-overlay="true"
          position="absolute"
        />
      </div>
      <div class="h-full w-full bg-white flex items-center flex-col px-8 gap-4" v-else>
        <div class="flex flex-col items-center text-primary-6 border-b py-4 w-full">
          <span class="font-bold text-lg">{{
            investorStore.investor_to_process.first_name +
            " " +
            investorStore.investor_to_process.last_name
          }}</span>
          <a
            :href="`/contacts?recordId=${investorStore.investor_to_process._id}`"
            class="underline"
            @click=""
            >{{ investorStore.investor_to_process.email }}</a
          >
        </div>
        <div
          class="w-full h-full flex items-center justify-center"
          v-if="investorStore.investments_for_investor.length == 0"
        >
          No Investments found for this Investor.
        </div>
        <div v-else class="w-full">
          <Table
            class="w-full"
            :table-data="tableRecords"
            :headers="tableHeaders"
            :hide-sort="true"
          ></Table>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Close</p></template></Button
        >
        <!-- <Button size="large" :disabled="isSaveDisabled" label @click="updateCustodian">
          <template #label></template
        ></Button> -->
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconInvestment } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRecordDetailStore, useCrudStore, useInvestorStore, useUIStore } from "@/stores";
import { Modal, Button, BaseInput, Table, LoadingSpinner } from "@/components";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

// define emits
const emit = defineEmits(["close-modal"]);

// declare props open
// const props = defineProps(["open"]);

onMounted(async () => {});

async function runKYC() {
  emit("close-modal");
}

const custodians = ref([]);
const investmentTypes = ref([]);

var tableRecords = computed(() => {
  return UIStore.transformRecords(investorStore.investments_for_investor);
});

// TODO this should come from schema honestly.
const tableHeaders = ref([
  { field_name: "signature_date", label: "Signature Date", field_type: "date" },
  { field_name: "_id", label: "ID" },
  { field_name: "type", label: "Type" },
  { field_name: "payment_method", label: "Payment Method" },
  { field_name: "status", label: "Status" },
  { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
]);

const isSaveDisabled = computed(() => {
  return false;
});

// vue watcher to set custodianEdits when open prop changes
// watch(props, (value) => {
//   if (value.open) {
//   }
// });
</script>
