<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Split Investment
    </div>
  </div>
  <div class="mt-4 border-b border-platform-outlines pb-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
      <InvestorDetailCard />
      <CustodianDetailCard
        v-if="
          titleTransferStore.current_investment.type.toLowerCase().includes('ira') ||
          titleTransferStore.current_investment.type.toLowerCase().includes('hsa')
        "
      />
      <JointDetailCard v-if="titleTransferStore.current_investment.type == 'Joint'" />
      <EntityDetailCard v-if="titleTransferStore.current_investment.type == 'Entity'" />
    </div>
  </div>

  <div class="my-5">
    <div class="text-primary-6 text-xl font-bold">Create Child-Investments</div>
    <div class="text-light text-sm">
      Offering Type: {{ titleTransferStore.current_offering.type }} ({{
        titleTransferStore.current_offering.interest_rate
      }}% - {{ titleTransferStore.current_offering.bond_term / 12 }} years)
    </div>
  </div>
  <div
    class="border bg-opacity-20 rounded-lg p-3 flex items-center"
    :class="
      bonds_used == titleTransferStore.current_investment.bonds
        ? 'bg-functional-info-default border-functional-info-default'
        : 'bg-functional-error-default border-functional-error-default'
    "
  >
    <Icon
      name="MessageSquareWarning"
      class="mr-value-12 h-5.5 w-5.5"
      :class="
        bonds_used == titleTransferStore.current_investment.bonds
          ? ' stroke-functional-info-default'
          : 'stroke-functional-error-default '
      "
      :stroke-width="2"
    />
    <div class="text-dark font-semibold">
      {{ commaSeparateThousands(bonds_used) }} /
      {{ commaSeparateThousands(titleTransferStore.current_investment.bonds) }} bonds are used
    </div>
  </div>

  <div class="my-2">
    <div class="flex flex-col gap-2">
      <div
        v-for="(split, index) of titleTransferStore.split_investments"
        :class="index == titleTransferStore.split_investments.length - 1 ? '' : 'border-b'"
      >
        <div class="my-4 text-primary-6 font-semibold">{{ `Investment ${index + 1}` }}</div>
        <div class="flex-col md:flex-row flex gap-5 items-end mb-5">
          <BaseInput
            v-model="split.bond_amount"
            type="string"
            label="Total Bonds"
            size="large"
            required
            formatRule="amount"
            :hideCurrencyUI="true"
          />
          <BaseInput
            :placeholder="getPlaceHolder(split)"
            type="string"
            label="Amount"
            disabled
            size="large"
            required
          />
          <div class="flex gap-5">
            <Button
              :disabled="index != titleTransferStore.split_investments.length - 1"
              @click="addInput()"
              size="large"
              icon
              variant="default-gray-outlined"
            >
              <template #label><IconPlus /></template>
            </Button>
            <Button
              :disabled="titleTransferStore.split_investments.length == 1"
              @click="deleteInput(index)"
              size="large"
              variant="default-gray-outlined"
              icon
            >
              <template #label>
                <Icon
                  name="Trash"
                  class="h-4.5 w-4.5 stroke-functional-error-default"
                  :stroke-width="2"
              /></template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, watch } from "vue";
import { BaseInput, Button } from "@/components";
import { IconPlus, IconDelete } from "@/components/icons";
import { Icon } from "@/components";
import { useTitleTransferStore } from "@/stores";
import { commaSeparateThousands } from "@/utilities";

const titleTransferStore = useTitleTransferStore();

import InvestorDetailCard from "./InvestorDetailCard.vue";
import CustodianDetailCard from "./CustodianDetailCard.vue";
import JointDetailCard from "./JointDetailCard.vue";
import EntityDetailCard from "./EntityDetailCard.vue";

const bonds_used = computed(() => {
  return titleTransferStore.split_investments.reduce((acc, split) => {
    return acc + parseInt(split.bond_amount.replace(/,/g, ""));
  }, 0);
});
const bond_amount_is_zero = computed(() => {
  return titleTransferStore.split_investments.some((split) => {
    return parseInt(split.bond_amount.replace(/,/g, "")) === 0;
  });
});
function getPlaceHolder(split) {
  let num_val = split.bond_amount.replace(/,/g, "");
  return `$ ${commaSeparateThousands(num_val * 1000)}`;
}

function addInput() {
  titleTransferStore.split_investments.push({ bond_amount: "0" });
}

function deleteInput(index) {
  titleTransferStore.split_investments.splice(index, 1);
}

watch(
  () => titleTransferStore.split_investments,
  (newVal) => {
    titleTransferStore.investment_split_is_invalid =
      bond_amount_is_zero.value ||
      titleTransferStore.split_investments.some((split) => {
        return parseInt(split.bond_amount.replace(/,/g, "")) === 0;
      }) ||
      bonds_used.value > titleTransferStore.current_investment.bonds ||
      bonds_used.value !== titleTransferStore.current_investment.bonds ||
      titleTransferStore.split_investments.length == 1;
  },
  { deep: true }
);
</script>
