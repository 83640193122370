import { defineStore } from "pinia";
import * as Realm from "realm-web";
import { useUIStore, useCrudStore } from "@/stores";

export const useHistoryStore = defineStore("historyStore", {
  state: () => ({
    history: [],
    historyFilter: null,
    historySort: null,
    historyPage: 1,
    totalHistoryCount: 0,
  }),
  actions: {
    async fetchHistory() {
      const crudStore = useCrudStore();
      try {
        const pipeline = [];
        if (this.historyFilter) {
          pipeline.push({ $match: this.historyFilter });
        }
        if (this.historySort) {
          pipeline.push({ $sort: this.historySort });
        } else {
          pipeline.push({ $sort: { modified_date: -1 } });
        }
        pipeline.push({ $skip: (this.historyPage - 1) * 20 });
        pipeline.push({ $limit: 20 });

        const history = await crudStore.aggregate("History", pipeline);
        const countPipeline = [{ $count: "count" }];
        if (this.historyFilter) {
          countPipeline.unshift({ $match: this.historyFilter });
        }
        const allHistory = await crudStore.aggregate("History", countPipeline);
        this.totalHistoryCount = allHistory[0]?.count;
        this.history = history;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchAllHistoryForCSV() {
      const crudStore = useCrudStore();
      try {
        const pipeline = [];
        if (this.historyFilter) {
          pipeline.push({ $match: this.historyFilter });
        }
        if (this.historySort) {
          pipeline.push({ $sort: this.historySort });
        } else {
          pipeline.push({ $sort: { modified_date: -1 } });
        }

        const allHistory = await crudStore.aggregate("History", pipeline);
        return allHistory;
      } catch (err) {
        console.error(err);
      }
    },
  },
});
