<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.042 14.078A7 7 0 0 0 3 21a1 1 0 0 1-2 0 9 9 0 0 1 14.954-6.75 1 1 0 1 1-1.324 1.5 7 7 0 0 0-3.588-1.672z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM4 8a6 6 0 1 1 12 0A6 6 0 0 1 4 8zM22.707 16.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L18 19.586l3.293-3.293a1 1 0 0 1 1.414 0z"
    />
  </svg>
</template>
