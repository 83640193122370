<template>
  <div
    v-if="
      !createInvestmentStore.investment_being_created &&
      !createInvestmentStore.show_investment_completion_screen
    "
  >
    <div class="flex justify-between items-center">
      <Button
        @click="createInvestmentStore.currentStep--"
        :disabled="createInvestmentStore.currentStep == 0"
      >
        <template #label>Back</template>
      </Button>
      <Button @click="next" :disabled="nextIsDisabled">
        <template #label>{{ button_text }}</template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useCreateInvestmentStore, useSchemaStore, useRecordDetailStore } from "@/stores";
import { Button } from "@/components";
import { STATES } from "@/constants";

const createInvestmentStore = useCreateInvestmentStore();
const recordDetailStore = useRecordDetailStore();
const schemaStore = useSchemaStore();
const router = useRouter();

const button_text = ref("Next");

async function next() {
  if (createInvestmentStore.currentStep === 0) {
    // Correct the investment amount inline
    let amount = parseFloat(createInvestmentStore.investment_amount.replace(/,/g, ""));
    if (!isNaN(amount) && amount >= 100) {
      amount = Math.floor(amount / 1000) * 1000;
      createInvestmentStore.investment_amount = amount.toLocaleString();
    } else {
      createInvestmentStore.investment_amount = "0";
    }
    createInvestmentStore.setInvestmentSteps();

    createInvestmentStore.currentStep++;
  } else if (
    createInvestmentStore.currentStep > 0 &&
    createInvestmentStore.currentStep < createInvestmentStore.investmentSteps.length
  ) {
    createInvestmentStore.currentStep++;
  } else {
    createInvestmentStore.investment_drawer_open = false;
    recordDetailStore.pageLoaderMessage = "Creating...";
    createInvestmentStore.investment_being_created = true;
    let investment_id = await createInvestmentStore.completeInvestment();
    router.push(`/investments?recordId=${investment_id}`);
    createInvestmentStore.investment_being_created = false;
  }
}

watch(
  () => createInvestmentStore.currentStep,
  () => {
    let current_step_name =
      createInvestmentStore.investmentSteps[createInvestmentStore.currentStep - 1] || "Step 0";
    if (current_step_name == "Complete Your Investment") {
      button_text.value = "Complete";
    } else {
      button_text.value = "Next";
    }
  }
);

const formatToNumber = (amount) => parseFloat(amount.replace(/,/g, ""));

const validateField = (fieldValue, regex) => {
  if (!fieldValue) {
    return false;
  }
  if (regex && !regex.test(fieldValue)) {
    return false;
  }
  return true;
};

const isFieldValid = (currentContact, field, regex) => {
  if (field.belongs_to_nested_object) {
    const nestedValue = currentContact[field.nested_object_name]?.[field.field_name];
    if (!validateField(nestedValue, regex)) {
      return false;
    }
  } else {
    const fieldValue = currentContact[field.field_name];
    if (!validateField(fieldValue, regex)) {
      return false;
    }
  }
  return true;
};

const validateContactInfo = (currentContact, schema) => {
  for (const group of schema) {
    for (const field of group.fields) {
      if (field.is_required) {
        let regex;
        switch (field.field_name) {
          case "phone":
            regex = /^\(\d{3}\) \d{3}-\d{4}$/;
            break;
          case "email":
            regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            break;
          case "ssn":
            regex = /^\d{3}-\d{2}-\d{4}$/;
            break;
          case "ein":
            regex = /^\d{2}-\d{7}$/;
            break;
          // case "state":
          //   if (!STATES.includes(currentContact[field.field_name])) {
          //     return true;
          //   }
          //   continue;
          default:
            regex = null;
        }
        if (!isFieldValid(currentContact, field, regex)) {
          return true;
        }
      }
    }
  }
  return false;
};

const nextIsDisabled = computed(() => {
  const current_step_name =
    createInvestmentStore.investmentSteps[createInvestmentStore.currentStep - 1] || "Step 0";

  switch (current_step_name) {
    case "Step 0": {
      const investmentAmount = formatToNumber(createInvestmentStore.investment_amount);
      if (
        !investmentAmount ||
        Object.keys(createInvestmentStore.selected_offering).length === 0 ||
        !createInvestmentStore.investment_type ||
        !createInvestmentStore.payment_frequency ||
        (createInvestmentStore.selected_offering.type === "Reg D" && investmentAmount < 1000) ||
        (createInvestmentStore.selected_offering.type !== "Reg D" && investmentAmount < 1000) ||
        (createInvestmentStore.investment_is_discounted &&
          createInvestmentStore.investment_discount_amount < 1)
      ) {
        return true;
      }
      return false;
    }
    case "Personal Information": {
      const isRegD = createInvestmentStore.selected_offering.type === "Reg D";
      const personalInfoSchema = isRegD
        ? schemaStore.accredited_owner_contact_personal_info_schema
        : schemaStore.non_accredited_owner_contact_personal_info_schema;
      let val =
        validateContactInfo(createInvestmentStore.current_owner_contact, personalInfoSchema) ||
        createInvestmentStore.owner_ssn_is_invalid;
      return val;
    }
    case "Select Joint Contact": {
      if (createInvestmentStore.add_joint_contact) {
        return false;
      } else {
        return Object.keys(createInvestmentStore.selected_joint_contact).length === 0;
      }
    }
    case "Select Entity Account": {
      if (createInvestmentStore.add_entity_account) {
        return false;
      } else {
        return Object.keys(createInvestmentStore.selected_entity_account).length === 0;
      }
    }
    case "Joint Info": {
      //  if (createInvestmentStore.add_joint_contact) {
      const isRegD = createInvestmentStore.selected_offering.type === "Reg D";
      const jointInfoSchema = isRegD
        ? schemaStore.accredited_joint_contact_personal_info_schema
        : schemaStore.non_accredited_joint_contact_personal_info_schema;
      return (
        validateContactInfo(createInvestmentStore.joint_contact_template, jointInfoSchema) ||
        createInvestmentStore.joint_ssn_is_invalid ||
        createInvestmentStore.joint_email_is_invalid
      );
      // } else {
      //   return Object.keys(createInvestmentStore.selected_joint_contact).length === 0;
      // }
    }
    case "Banking Info": {
      return createInvestmentStore.banking_info_is_invalid;
    }

    case "Entity Info": {
      let validEntityInfo = validateContactInfo(
        createInvestmentStore.entity_template,
        schemaStore.entity_information
      );

      let is_valid_ben_owner_info = !(
        createInvestmentStore.entity_template.owner_is_beneficial_owner ||
        createInvestmentStore.entity_template.beneficial_owners?.length > 0
      );

      let is_disabled =
        validEntityInfo || is_valid_ben_owner_info || createInvestmentStore.entity_ein_is_invalid;

      return is_disabled;
    }
    case "Suitability Questionnaire": {
      return !createInvestmentStore.is_valid_suitability_info;
    }
    case "Accreditation Details": {
      //createInvestmentStore.current_owner_contact.are_you_an_accredited_investor has to be true and createInvestmentStore.accreditation_documents.length had to be greater that 0
      return !createInvestmentStore.current_owner_contact.are_you_an_accredited_investor;
    }
    case "Complete Your Investment": {
      return (
        !createInvestmentStore.investment_signature_date ||
        ((createInvestmentStore.investment_type.includes("IRA") ||
          createInvestmentStore.investment_type.includes("HSA")) &&
          (!createInvestmentStore.custodian_name ||
            !createInvestmentStore.custodian_account_number)) ||
        !createInvestmentStore.uploaded_subagreeement
      );
    }
    default:
      return false;
  }
});
</script>
