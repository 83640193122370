<template>
  <div class="flex min-h-full items-center justify-between rounded-md bg-neutral-gray-3 p-4">
    <div class="flex items-center gap-4">
      <div
        v-if="!data.is_merge_collection"
        class="flex h-8 w-8 items-center justify-center rounded-md bg-opacity-20 p-1"
        :class="[icon_bg]"
      >
        <component :is="iconMapping[data.icon]" class="h-6 w-6" :class="[icon_fill]" />
      </div>
      <div v-else class="flex">
        <div class="flex h-8 w-8 items-center justify-center rounded-md bg-opacity-20 p-1">
          <component :is="iconMapping[data.icon]" class="h-6 w-6" :class="[icon_fill]" />
        </div>
        <div
          class="relative -ml-3 mt-3 flex h-8 w-8 items-center justify-center rounded-md bg-opacity-20 p-1"
          :class="[data.icon_color_bg2]"
        >
          <component
            :is="iconMapping[data.icon2]"
            class="h-6 w-6"
            :class="[data.icon_color_fill2]"
          />
        </div>
      </div>
      <div>
        <h3 class="mb-1 text-base font-bold">{{ data.name }}</h3>
        <p class="text-xs text-lighter">{{ data.description }}</p>
      </div>
    </div>
    <div class="flex flex-row">
      <Button @click="openModal()" icon variant="default-gray-outlined" size="small" class="mx-1">
        <template #icon><IconEdit /></template>
      </Button>
      <Button @click="routeToNewPage(data)" icon variant="default-gray-outlined" size="small">
        <template #icon><IconChevronRight /></template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Button } from "@/components";

import { iconMapping } from "@/constants/iconMapping";

import { useDataModelManagementStore, useUIStore } from "@/stores";
import { IconEdit, IconChevronRight } from "@/components/icons";

const router = useRouter();
const dataModelManagementStore = useDataModelManagementStore();
const UIStore = useUIStore();
const modalOpen = ref(false);

const props = defineProps({
  data: Object,
  icon_bg: String,
  icon_color: String,
  icon_fill: String,
  // icon_bg2: String,
  // icon_color2: String,
  // icon_fill2: String,
});

function routeToNewPage(card) {
  dataModelManagementStore.currentModelCard = card;
  dataModelManagementStore.currentModel = card.name;
  dataModelManagementStore.model_being_edited = dataModelManagementStore.fetched_settings.find(
    (setting) => setting.collection === props.data.collection
  );
  const adjustedRoute = card.name.toLowerCase().split(" ").join("");
  router.push(`/manage-model?model=${card.collection.toLowerCase()}/${adjustedRoute}`);
}

function openModal() {
  modalOpen.value = true;

  dataModelManagementStore.datamodalopen = true;
  dataModelManagementStore.model_being_edited = dataModelManagementStore.fetched_settings.find(
    (setting) => setting.collection === props.data.collection
  );
}
</script>
