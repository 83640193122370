<template>
  <OneOffKYCModal :open="oneOffKYCModalOpen" @close-modal="oneOffKYCModalOpen = false" />
  <QualifiersModal
    :open="qualifiersModalOpen"
    @close-modal="qualifiersModalOpen = false"
    size="large"
  />
  <InvestmentListModal
    :open="investmentListModalOpen"
    :loading="investmentListModalLoading"
    @close-modal="investmentListModalOpen = false"
  />
  <!-- LANDING -->
  <div v-if="!page_is_loading" class="p-0 sm:px-10 max-w-[100vw] py-5">
    <AllRecordsTable
      :use-filters="false"
      :fields-with-custom-styles="['status', 'checkbox', 'qualifiers', 'investments', 'actions']"
      @selectAll="handleSelectAll"
      :header-seperator="false"
      keep_actions_on_sort
      actions_always_sticky
    >
      <template v-slot:actions="entry">
        <div class="flex items-center justify-center px-4 gap-2 border-l h-[3.25rem] bg-white">
          <Button
            variant="default-gray-outlined"
            icon
            :is-icon-only-on-mobile="true"
            @click="openQualifiers(entry.row.row)"
          >
            <template #icon><Icon name="FileCheck2" class="text-primary-6 w-4" /></template>
            <!-- <template #label>Qualifiers</template> -->
          </Button>
          <Button
            variant="default-gray-outlined"
            icon
            :is-icon-only-on-mobile="true"
            @click="openInvestmentList(entry.row.row)"
          >
            <template #icon><IconInvestment class="fill-primary-6 w-4" /></template>
            <!-- <template #label>Investments</template> -->
          </Button>
        </div>
      </template>
      <template v-slot:checkbox="entry">
        <div class="flex max-w-min items-center space-x-value-8 px-4">
          <input
            @click="select(entry)"
            :checked="
              investmentStore.investment_ids_to_process?.includes(
                entry.row.row._id.value?.toString()
              )
            "
            type="checkbox"
            class="accent-secondary-6"
          />
          <button
            @click="selectOnly(entry)"
            class="text-primary rounded bg-neutral-gray-4 px-4.5 py-0.5 text-xs"
          >
            Only
          </button>
        </div>
      </template>
      <template v-slot:title>Identity Checks</template>
      <template v-slot:header-ctas></template>
      <template v-slot:additional-ctas>
        <div class="flex flex-row items-center gap-3 justify-self-end justify-end">
          <Button
            variant="default-gray-outlined"
            :is-icon-only-on-mobile="true"
            :icon="true"
            :label="true"
            @click="oneOffKYCModalOpen = true"
          >
            <template #icon> <Icon name="RefreshCcw" class="h-4 w-4 text-primary" /> </template>
            <template #label> One-Off KYC </template>
          </Button>
          <!-- <Button variant="default">
            <template #label> Create New Investment </template>
          </Button> -->
        </div></template
      >
      <template v-slot:status="data">
        <div class="ml-4 truncate">
          <div
            class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
            :class="
              data.row.row.status.value == 'Created'
                ? 'bg-custom-light-blue text-custom-light-blue'
                : data.row.row.status.value == 'Settled'
                  ? 'bg-custom-lime-green text-custom-lime-green'
                  : 'bg-functional-warning-default text-functional-warning-hover'
            "
          >
            {{ data.row.row.status.value }}
          </div>
        </div>
      </template>
      <template v-slot:filter-inputs>
        <!-- Desktop Filters -->
        <div class="sm:grid grid-cols-2 hidden">
          <div class="flex flex-col md:flex-row items-end gap-4 mt-4 w-full pb-4">
            <div class="relative w-full max-w-80">
              <BaseInput
                v-model="filters.email"
                placeholder="Email"
                label="Email"
                size="large"
                type="text"
              />
              <!-- <Icon
                @click="filters.email = ''"
                name="X"
                class="absolute right-2 top-10.5 h-4 w-4 text-lighter cursor-pointer"
                :stroke-width="2"
              /> -->
            </div>
            <div class="relative w-full max-w-80">
              <BaseInput
                v-model="filters._id"
                placeholder="Contact ID"
                label="Contact ID"
                size="large"
                type="text"
              />
              <!-- <Icon
                @click="filters._id = ''"
                name="X"
                class="absolute right-2 top-10.5 h-4 w-4 text-lighter cursor-pointer"
                :stroke-width="2"
              /> -->
            </div>
          </div>
          <div
            class="flex flex-col md:flex-row items-end gap-4 mt-4 w-full xl:w-1/2 pb-4 justify-self-end justify-end"
          >
            <Button
              variant="default-gray-outlined"
              :label="true"
              size="large"
              @click="clearFilters()"
              :disabled="are_filters_empty"
            >
              <template #label> Clear </template>
            </Button>

            <Button :label="true" size="large" @click="applyFilters()">
              <template #label> Apply </template>
            </Button>
          </div>
        </div>
        <!-- Mobile Filters -->
        <div class="flex flex-col sm:hidden border-platform-outlines border rounded-xl mt-4">
          <div class="w-full flex justify-between p-4 items-center">
            <span class="font-bold">Filter</span>
            <div
              class="border-platform-outlines border rounded-lg p-1.5 cursor-pointer"
              @click="filterDropOpen = !filterDropOpen"
            >
              <Icon :name="filterDropOpen ? 'ChevronUp' : 'ChevronDown'" class="w-4 h-4" />
            </div>
          </div>
          <div
            class="transition-all duration-300 overflow-hidden"
            :class="filterDropOpen ? 'max-h-screen' : 'max-h-0'"
          >
            <div
              class="flex flex-col md:flex-row flex-wrap items-end gap-4 w-full py-6 px-4 grow bg-neutral-gray-3"
            >
              <div class="relative w-full">
                <BaseInput
                  v-model="filters.email"
                  placeholder="Email"
                  label="Email"
                  size="large"
                  type="text"
                />
                <!-- <Icon
                @click="filters.email = ''"
                name="X"
                class="absolute right-2 top-10.5 h-4 w-4 text-lighter cursor-pointer"
                :stroke-width="2"
              /> -->
              </div>
              <div class="relative w-full">
                <BaseInput
                  v-model="filters._id"
                  placeholder="Contact ID"
                  label="Contact ID"
                  size="large"
                  type="text"
                />
                <!-- <Icon
                @click="filters._id = ''"
                name="X"
                class="absolute right-2 top-10.5 h-4 w-4 text-lighter cursor-pointer"
                :stroke-width="2"
              /> -->
              </div>
            </div>
            <div class="flex flex-row items-center gap-4 p-4 justify-center">
              <Button
                class="w-full"
                variant="default-gray-outlined"
                :label="true"
                size="large"
                @click="clearFilters()"
              >
                <template #label> Clear </template>
              </Button>

              <Button class="w-full" :label="true" size="large" @click="applyFilters()">
                <template #label> Apply </template>
              </Button>
            </div>
          </div>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <!-- LOADING -->
  <div v-else-if="page_is_loading">
    <LoadingSpinner :show="true" message="Loading..." :hide-overlay="true" />
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import {
  useCrudStore,
  useRecordDetailStore,
  useUIStore,
  useInvestmentStore,
  useInvestorStore,
} from "@/stores";
import { Button, LoadingSpinner, AllRecordsTable, Icon, BaseInput } from "@/components";
import { IconRefresh, IconInvestment } from "@/components/icons";
import { iconMapping } from "@/constants/iconMapping";
import OneOffKYCModal from "./OneOffKYCModal.vue";
import QualifiersModal from "./QualifiersModal.vue";
import InvestmentListModal from "./InvestmentListModal.vue";

const crudStore = useCrudStore();
const recordDetailStore = useRecordDetailStore();
const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();
const route = useRoute();

const page_is_loading = ref(false);

const oneOffKYCModalOpen = ref(false);
const qualifiersModalOpen = ref(false);
const investmentListModalOpen = ref(false);
const investmentListModalLoading = ref(false);
const filterDropOpen = ref(false);

var default_idcheck_filter = {
  is_identity_verified: false,
};

const filters = ref({
  email: "",
  _id: "",
});

const are_filters_empty = computed(() => {
  return filters.value.email == "" && filters.value._id == "";
});

onMounted(async () => {
  await onMountedFunction();
});

onUnmounted(() => {
  recordDetailStore.defaultFilterPipeline = null;
});

watch(
  () => recordDetailStore.allRecordsPageNumber,
  (newValue) => {
    (async () => {
      recordDetailStore.defaultFilterPipeline = default_idcheck_filter;
      await recordDetailStore.fetchTableRecords(true);
    })();
  }
);

// watch(
//   () => filters.email,
//   async (newEmail) => {
//     if (newEmail) {
//       //currentPage.value = 1;
//       recordDetailStore.filterPipeline = {
//         email: filters.email ? filters.email : null,
//       };
//       await recordDetailStore.fetchTableRecords(true);
//     } else {
//       recordDetailStore.filterPipeline = default_idcheck_filter;
//       await recordDetailStore.fetchTableRecords(true);
//     }
//   }
// );

// watch(
//   () => filters._id,
//   async (newID) => {
//     if (newID) {
//       //currentPage.value = 1;
//       recordDetailStore.filterPipeline = {
//         _id: filters._id,
//       };
//       console.log(recordDetailStore.defaultFilterPipeline);
//       await recordDetailStore.fetchTableRecords(true);
//     } else {
//       recordDetailStore.filterPipeline = default_idcheck_filter;
//       await recordDetailStore.fetchTableRecords(true);
//     }
//   }
// );

watch(
  () => recordDetailStore.updateTic,
  (newValue) => {
    (async () => {
      await onMountedFunction();
    })();
  }
);

async function onMountedFunction() {
  page_is_loading.value = true;
  recordDetailStore.currentPageCollection = "Contacts";

  //console.log(dataModelManagementStore.fetched_settings);

  await recordDetailStore.setCurrentPageConfig();
  setCustomConfig();

  await recordDetailStore.getPossibleColumns("Contacts");

  recordDetailStore.filterPipeline = {};
  recordDetailStore.defaultFilterPipeline = default_idcheck_filter;
  await recordDetailStore.fetchTableRecords(true);
  // recordDetailStore.allRecordsTableHeaders.unshift({
  //   label: "Investments",
  //   field_name: "investments",
  // });
  // recordDetailStore.allRecordsTableHeaders.unshift({
  //   label: "Qualifiers",
  //   field_name: "qualifiers",
  // });

  page_is_loading.value = false;
}

function selectOnly(entry) {
  const recordId = entry.row.row._id.value?.toString();

  // Clear the array and add only the selected ID
  investmentStore.investment_ids_to_process = [recordId];
}

function select(entry) {
  const recordId = entry.row.row._id.value?.toString();

  if (investmentStore.investment_ids_to_process.includes(recordId)) {
    // If the ID is already in the array, remove it
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) => id !== recordId
    );
  } else {
    // If the ID is not in the array, add it
    investmentStore.investment_ids_to_process.push(recordId);
  }
}

function setCustomConfig() {
  recordDetailStore.currentPageConfig.icon = "IconKey";
  recordDetailStore.currentPageConfig.icon_color_bg = "bg-custom-teal";
}

async function openQualifiers(contact) {
  let id = contact._id.value;
  investorStore.investor_to_process = await crudStore.findOne("Contacts", { _id: id });
  qualifiersModalOpen.value = true;
}

async function openInvestmentList(contact) {
  investmentListModalOpen.value = true;
  investmentListModalLoading.value = true;
  let id = contact._id.value;
  investorStore.investor_to_process = await crudStore.findOne("Contacts", { _id: id });
  investorStore.investments_for_investor = await crudStore.find("Investments", {
    $or: [{ _id: id }, { joint_contact_id: id }],
  });
  //console.log(investorStore.investments_for_investor);
  investmentListModalLoading.value = false;
}

async function clearFilters() {
  filters.value = {
    email: "",
    _id: "",
  };
  recordDetailStore.filterPipeline = {};
  await recordDetailStore.fetchTableRecords(true);
}

async function applyFilters() {
  recordDetailStore.filterPipeline = null;

  // set filter based on values in filters object
  let filter = {};

  for (let key in filters.value) {
    // cancel if value is null or empty string
    if (filters.value[key] === null || filters.value[key] === "") {
      continue;
    }

    // break up contact_id string into array of contact_ids
    if (key == "_id") {
      let in_array = [];
      for (let id of filters.value[key].split(",")) {
        in_array.push({ $oid: id.trim() });
      }

      filter["_id"] = { $in: in_array };
      continue;
    }
    // all other keys get set to filter object
    filter[key] = filters.value[key];
  }

  console.log(filter);

  // if filter has any keys set, then set the filterPipeline to the filter object
  if (Object.keys(filter).length > 0) {
    recordDetailStore.filterPipeline = filter;
  }

  // fetch records
  recordDetailStore.defaultFilterPipeline = default_idcheck_filter;
  await recordDetailStore.fetchTableRecords(true);
}
</script>
