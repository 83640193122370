<template>
  <DocumentPreviewDrawer :open="previewDrawerOpen" @close-drawer="previewDrawerOpen = false" />
  <div v-if="!page_is_loading" class="px-0 sm:px-10 max-w-[100vw] py-5">
    <AllRecordsTable
      :customData="{ header_name: 'Documents', icon_name: 'File', bg_color: 'bg-royal-blue' }"
      actions_always_sticky
      keep_actions_on_sort
    >
      <template v-slot:table-ctas="entry">
        <div class="flex items-center justify-center gap-2">
          <Button @click="previewFile(entry)" size="small" variant="default-gray-outlined" icon>
            <template #icon>
              <Icon name="Eye" class="h-4 w-4" />
            </template>
          </Button>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <!-- LOADING -->
  <div v-else-if="page_is_loading">
    <LoadingSpinner :show="page_is_loading" hideOverlay />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useCrudStore, useRecordDetailStore, useUIStore, useSchemaStore } from "@/stores";
import { Button, LoadingSpinner, AllRecordsTable, Icon } from "@/components";
import useFileUtility from "@/composables/useFileUtility";
const { downloadFile_R2, getFile_R2, fetchFileForPreview_R2, parseCSV } = useFileUtility();
import DocumentPreviewDrawer from "./DocumentPreviewDrawer.vue";

const schemaStore = useSchemaStore();
const crudStore = useCrudStore();
const recordDetailStore = useRecordDetailStore();
const UIStore = useUIStore();

const page_is_loading = ref(false);
const previewDrawerOpen = ref(false);

onMounted(async () => await onMountedFunction());

watch(
  () => recordDetailStore.allRecordsPageNumber,
  async () => {
    await recordDetailStore.fetchTableRecords(true);
  }
);

watch(
  () => recordDetailStore.updateTic,
  async () => {
    await onMountedFunction();
  }
);

async function onMountedFunction() {
  try {
    page_is_loading.value = true;
    recordDetailStore.currentPageCollection = "Documents";
    recordDetailStore.filterPipeline = null;
    recordDetailStore.defaultFilterPipeline = null;
    await recordDetailStore.setCurrentPageConfig();
    await recordDetailStore.getPossibleColumns("Documents");
    await recordDetailStore.fetchTableRecords(true);
    recordDetailStore.file_details_schema =
      schemaStore.getRelatedListSchemaForCollection("Documents");
  } catch (err) {
    console.error(err);
  } finally {
    page_is_loading.value = false;
  }
}

async function previewFile(entry) {
  let table_entry_object = entry.row.entry;

  let document_object = {};

  Object.keys(table_entry_object).forEach((key) => {
    document_object[key] = table_entry_object[key].value;
  });

  delete document_object.actions;

  console.log(document_object);
  recordDetailStore.current_preview_file = document_object;
  previewDrawerOpen.value = true;
  await viewRecord(document_object);
}

async function viewRecord(document) {
  recordDetailStore.fileIsLoading = true;

  if (document.file_type == "text/csv") {
    let csv_file = await getFile_R2(document);
    let returned_json = await parseCSV(csv_file);
    recordDetailStore.createCSVTable(returned_json);
  } else {
    recordDetailStore.file_preview_url = await fetchFileForPreview_R2(document);
  }
  recordDetailStore.fileIsLoading = false;
}
</script>
