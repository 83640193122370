<template>
  <Modal :open="open" @close-modal="$emit('close-modal')" size="small" hideModalTabs>
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">Generate Monthly Statement</p>
    </template>

    <template v-slot:modal-body>
      <div class="py-4 px-10 flex flex-col gap-4">
        <label for="sendEmail" class="flex w-min items-center">
          <input
            type="checkbox"
            v-model="investorStore.sendStatementEmail"
            id="sendEmail"
            class="accent-secondary-6"
          />
          <span class="ml-2 pt-1 text-sm text-primary whitespace-nowrap">Send Email?</span>
        </label>
        <div>
          <BaseInput
            label="Statement Date"
            required
            type="date"
            v-model="statementDate"
            size="large"
          />
          <p v-if="!isDateOnTenth" class="text-sm text-functional-error-default mt-1">
            Statement date must be on the 10th of the month and not in the future.
          </p>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button
          size="large"
          label
          @click="handleSubmitGenerateStatement"
          :disabled="!statementDate || !isDateOnTenth"
        >
          <template #label>Submit</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed } from "vue";

import { useCrudStore, useInvestorStore } from "@/stores";
import { Modal, Button, BaseInput } from "@/components";

const investorStore = useInvestorStore();
const crudStore = useCrudStore();

const emit = defineEmits(["close-modal", "generate-statement"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  currentStatementDate: {
    type: [String, null],
    default: null,
  },
});

const statementDate = ref("");
const sendEmail = ref(false);

const isDateOnTenth = computed(() => {
  if (!statementDate.value) return true;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return statementDate.value.split("-")[2] === "10" && new Date(statementDate.value) <= today;
});

async function handleSubmitGenerateStatement() {
  emit("generate-statement", statementDate.value, sendEmail.value);
  emit("close-modal");
  statementDate.value = "";
  sendEmail.value = false;
}
</script>
