<template>
  <div name="header" class="">
    <div
      class="relative flex h-auto justify-between space-x-5 bg-neutral-gray-1 md:rounded-t-lg"
      :class="{
        'pb-4': headerNuggets,
      }"
    >
      <div class="flex flex-row w-full items-center align-middle gap-2 sm:gap-4 truncate">
        <slot name="header-icon"></slot>
        <div>
          <slot name="header-title"></slot>
          <div class="">
            <slot name="header-desc"></slot>
          </div>
        </div>
      </div>
      <div class="mt-0 flex items-end sm:items-center md:mt-0">
        <slot name="header-ctas"></slot>

        <slot name="additional-ctas"></slot>
      </div>
    </div>
    <div>
      <slot name="filter-inputs"></slot>
    </div>
    <div v-if="headerNuggets" class="">
      <slot name="header-nuggets">
        <div
          v-if="headerNuggets?.length"
          class="flex sm:grid sm:grid-cols-2 md:flex md:flex-row gap-6 flex-wrap items-start justify-normal"
        >
          <div
            v-for="(nugget, index) in headerNuggets"
            :key="nugget"
            class="relative flex sm:w-auto w-auto flex-row justify-start gap-2 items-center"
          >
            <div class="flex flex-col">
              <div
                @mouseenter="checkIfTruncated(index + 'label')"
                @mouseleave="isTruncated[index + 'label'] = false"
                :ref="setNuggetNameRef(index + 'label')"
                class="truncate text-xs font-medium leading-4 text-light uppercase"
              >
                {{ nugget.label }}
              </div>

              <div
                v-if="isTruncated[index + 'label']"
                class="absolute -top-5 z-50 w-full whitespace-nowrap"
              >
                <!-- <Tooltip :text="nugget.label" /> -->
              </div>
              <div
                class="truncate text-sm leading-5 text-dark"
                @mouseenter="checkIfTruncated(index)"
                @mouseleave="isTruncated[index] = false"
                :ref="setNuggetNameRef(index)"
              >
                <router-link
                  v-if="nugget.v_model?.route_to_push && !isUnlinked"
                  :to="`${nugget.v_model?.route_to_push}`"
                  class="cursor-pointer text-gold"
                >
                  {{ nugget.v_model?.name || "-" }}
                </router-link>
                <router-link
                  v-else-if="nugget.field_name === 'contact_id'"
                  :to="`/contacts?recordId=${nugget.v_model}`"
                  class="cursor-pointer text-gold"
                >
                  {{ nugget.v_model }}
                </router-link>
                <!-- <router-link
                  v-else-if="nugget.field_name === '_id'"
                  :to="`/investments?recordId=${nugget.v_model}`"
                  class="cursor-pointer text-gold"
                >
                  {{ nugget.v_model }}
                </router-link> -->
                <span v-else>
                  {{
                    nugget.v_model?.hasOwnProperty("name") ? nugget.v_model?.name : nugget.v_model
                  }}</span
                >
                <div
                  v-if="isTruncated[index]"
                  class="absolute -top-2 z-50 w-full whitespace-nowrap"
                >
                  <!-- <Tooltip
                  :text="
                    nugget.v_model?.hasOwnProperty('name') ? nugget.v_model?.name : nugget.v_model
                  "
                /> -->
                </div>
              </div>
            </div>
            <div>
              <!-- TODO add proper record config to determine the type of CTA here -->
              <div
                v-if="['_id', 'contact_id'].includes(nugget.field_name)"
                class="bg-opacity-20 p-1.5 rounded-lg cursor-pointer transition-all"
                :class="nugget.isCopied ? 'bg-functional-success-default' : 'bg-neutral-gray-6'"
                @click="copyToClipboard(nugget)"
              >
                <Icon name="Copy" class="w-4 h-4" v-if="nugget.isCopied != true" />
                <!-- <IconCopyAll class="w-4 h-4" v-if="nugget.isCopied != true" /> -->
                <IconCircleCheck class="w-4 h-4 fill-functional-success-default" v-else />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="grid grid-cols-1 gap-3 rounded-lg bg-neutral-gray-1 pt-2 md:grid-cols-3 lg:grid-cols-6"
        >
          <div
            v-for="nugget in headerNuggets"
            :key="nugget"
            class="flex h-20 w-full items-center rounded-lg bg-platform-canvas px-5"
          >
            <div class="align-middle">
              <div class="animate-pulse text-sm">Loading...</div>
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { IconCopyAll, IconLink, IconCircleCheck } from "@/components/icons";
import { Icon } from "@/components";

// import { Tooltip } from "@/components";
const router = useRouter();

const props = defineProps({
  headerNuggets: Array,
  isUnlinked: {
    type: Boolean,
    default: false,
  },
});

const nuggetNameRefs = reactive({});
const isTruncated = reactive({});

function setNuggetNameRef(id) {
  return (el) => {
    if (el) {
      nuggetNameRefs[id] = el;
    }
  };
}

function checkIfTruncated(id) {
  const el = nuggetNameRefs[id];
  if (el) {
    // scroll with is full width + scroll, client width is viewable area
    isTruncated[id] = el.scrollWidth > el.clientWidth;
  }
}

function routeToContact(contact) {
  if (contact) {
    router.push(`/contacts?recordId=${contact}`);
  }
}

function copyToClipboard(nugget) {
  console.log("Copied to clipboard: ", nugget.v_model);
  navigator.clipboard.writeText(nugget.v_model);
  nugget.isCopied = true;
  setTimeout(() => {
    nugget.isCopied = false;
  }, 2000);
}
</script>
