<template>
  <div v-if="show" :class="positionClass">
    <div
      class="relative flex items-center justify-center"
      :class="(position === 'fixed' ? 'mb-14 lg:mr-value-1' : '', !hideOverlay ? 'z-30' : 'z-0')"
    >
      <div class="absolute top-[40px] flex flex-col items-center justify-center text-[#A2AEB8]">
        <div class="flex items-center justify-center gap-4">
          <div>...</div>
          <IconPhxCapLogo class="h-12 w-12 animate-scale-pulse fill-[#A2AEB8]" />
          <div>...</div>
        </div>
        <p class="text-center" :class="{ 'text-xs': smallText }">{{ message }}</p>
      </div>
      <div class="relative flex h-36 w-36 items-center justify-center">
        <div
          class="h-36 w-36 animate-rotating-border rounded-full border-[4px] border-[#A2AEB8] opacity-20"
        ></div>
        <div
          class="absolute right-0 top-0 h-36 w-36 animate-custom-spin rounded-full border-[4px] border-transparent border-t-[#A2AEB8] opacity-30"
        ></div>
      </div>
    </div>
    <div
      v-if="!hideOverlay"
      id="side-drawer-bg"
      as="template"
      class="fixed inset-0 z-10 h-full w-full bg-neutral-gray-13 bg-opacity-80"
    />
  </div>
</template>

<script setup>
import { watch, computed } from "vue";

import { IconPhxCapLogo } from "@/components/icons";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  hideOverlay: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: "fixed",
  },
  message: {
    type: String,
    default: "Loading",
  },
  smallText: {
    type: Boolean,
    default: false,
  },
});

const positionClass = computed(() => {
  return {
    "fixed inset-0 z-20 flex h-full w-full items-center justify-center lg:ml-value-1 mt-14":
      props.position === "fixed",
    "relative z-50 flex items-center justify-center": props.position === "relative",
  };
});

watch(
  () => props.show,
  () => {
    if (props.show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
  { deep: true }
);
</script>
