<template>
  <Modal size="36square" :open="open" :hideModalTabs="true" @close-modal="$emit('close-modal')">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2">
          Update {{ investmentStore.investment_ids_to_process.length }} Investment<span
            v-if="
              investmentStore.investment_ids_to_process.length > 1 ||
              investmentStore.investment_ids_to_process.length === 0
            "
            >s</span
          >
        </div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white flex flex-col gap-4 p-8">
        <label
          v-if="investmentStatus === 'Settled' || investmentStatus === 'Canceled'"
          for="sendEmail"
          class="flex w-min items-center"
        >
          <input type="checkbox" v-model="sendEmail" id="sendEmail" class="accent-secondary-6" />
          <span class="ml-2 pt-1 text-sm text-primary whitespace-nowrap">Send Email?</span>
        </label>
        <Banner v-if="statusError" type="error" hideClose>
          <template #content>
            <div class="flex items-start justify-start">
              <p class="font-normal">{{ statusError }}</p>
            </div>
          </template>
        </Banner>

        <BaseInput
          label="Status"
          placeholder="Select Status"
          v-model="investmentStatus"
          :options="investmentStatuses"
          type="select"
          size="large"
          showFullText
        />
        <div class="flex gap-4">
          <BaseInput
            :label="`${investmentStatus === 'Settled' ? 'Close' : investmentStatus} Date`"
            v-model="statusDate"
            type="date"
            size="large"
          />
          <BaseInput
            v-if="isSpecialStatus"
            :label="specialDateLabel"
            v-model="specialStatusDate"
            type="date"
            size="large"
            formatRule="first_payment_date"
            errorMessage="First payment date must be on the 10th of the month."
            showErrorOnInput
          />
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="handleUpdateInvestmentStatus">
          <template #label>
            Update {{ investmentStore.investment_ids_to_process.length }} Investment<span
              v-if="investmentStore.investment_ids_to_process.length > 1"
              >s</span
            >
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { ObjectId } from "bson";

import { Modal, Button, BaseInput, Banner } from "@/components";
import {
  useRecordDetailStore,
  useInvestmentStore,
  useSchemaStore,
  useUIStore,
  useAuthStore,
  useCrudStore,
} from "@/stores";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const investmentStore = useInvestmentStore();
const schemaStore = useSchemaStore();
const UIStore = useUIStore();
const authStore = useAuthStore();
const crudStore = useCrudStore();

const props = defineProps({
  open: Boolean,
});

const emit = defineEmits(["close-modal", "fetch-investments"]);

const investmentStatus = ref("");
const statusDate = ref("");
const specialStatusDate = ref("");
const sendEmail = ref(false);
const statusError = ref("");
const selectedInvestments = ref([]);

watch(
  () => [investmentStore.investment_ids_to_process, props.open],
  async () => {
    if (!props.open) {
      statusError.value = "";
      investmentStatus.value = "";
      statusDate.value = "";
      specialStatusDate.value = "";
    }
    selectedInvestments.value = await crudStore.find("Investments", {
      _id: { $in: investmentStore.investment_ids_to_process?.map((id) => ObjectId(id)) },
    });
    if (selectedInvestments.value.length === 0) {
      statusError.value = "";
    }
    if (selectedInvestments.value.some((investment) => investment.status === "Bonds Matured")) {
      statusError.value = "";
      statusError.value = "You cannot update the status of a matured investment.";
    } else if (
      selectedInvestments.value.some((investment) => investment.status !== "Bonds Matured")
    ) {
      statusError.value = "";
    }
  },
  { deep: true }
);

const isSpecialStatus = computed(() => {
  return ["Settled", "Early Redemption"].includes(investmentStatus.value);
});

const investmentStatuses = computed(() => {
  if (schemaStore.all_investment_schema.length === 0) return [];
  return schemaStore.all_investment_schema
    .find((s) => s.field_name === "status")
    ?.record_detail_config?.default?.dropdown_options?.filter(
      (status) =>
        status !== "Created" &&
        status !== "Pending" &&
        status !== "Bonds Matured" &&
        status !== "Title Transferred" &&
        status !== "Split"
    );
});

const specialDateLabel = computed(() => {
  switch (investmentStatus.value) {
    case "Settled":
      return "First Payment Date";
    case "Early Redemption":
      return "Last Payment Date";
    default:
      return "";
  }
});

const isSaveDisabled = computed(() => {
  // TODO: Add logic in for Dalmore approval status for settling
  const isInvalidFirstPaymentDate =
    investmentStatus.value === "Settled" &&
    specialStatusDate.value &&
    specialStatusDate.value.split("-")[2] !== "10";

  const isInvalidLastPaymentDate =
    investmentStatus.value === "Early Redemption" &&
    specialStatusDate.value &&
    specialStatusDate.value.split("-")[2] !== "10";

  return (
    !investmentStore.investment_ids_to_process.length ||
    !investmentStatus.value ||
    !statusDate.value ||
    (!specialStatusDate.value && isSpecialStatus.value) ||
    isInvalidFirstPaymentDate ||
    isInvalidLastPaymentDate ||
    statusError.value !== ""
  );
});

watch(
  () => investmentStatus.value,
  async () => {
    statusError.value = "";
    // IR admins should not be able to cancel settled investments
    if (
      investmentStatus.value === "Canceled" &&
      authStore.adminUserData.permissions.includes("ir-admin")
    ) {
      if (
        selectedInvestments.value.some((investment) => investment.status === "Settled") &&
        selectedInvestments.value.length > 0
      ) {
        statusError.value =
          "You cannot cancel a settled investment, please reach out to finance for assistance.";
      } else {
        statusError.value = "";
      }
    }

    if (
      investmentStatus.value === "Settled" &&
      selectedInvestments.value.length > 0 &&
      selectedInvestments.value.some((investment) => investment.approval_status !== "approved")
    ) {
      statusError.value = "You cannot settle investments until they have been approved by Dalmore.";
    } else if (
      investmentStatus.value === "Settled" &&
      selectedInvestments.value.length > 0 &&
      selectedInvestments.value.some((investment) => investment.approval_status === "approved")
    ) {
      statusError.value = "";
    }
  }
);

async function handleUpdateInvestmentStatus() {
  emit("close-modal");
  // to get the frontend to immediately update the status of the investments
  recordDetailStore.allRecords = recordDetailStore.allRecords.map((record) => {
    if (investmentStore.investment_ids_to_process.includes(record._id.value.toString())) {
      record.status.value = investmentStatus.value;
    }
    return record;
  });

  const idsToProcess = investmentStore.investment_ids_to_process;
  investmentStore.investment_ids_to_process = [];

  await investmentStore.updateInvestmentStatusInBulk(
    idsToProcess,
    investmentStatus.value,
    sendEmail.value,
    statusDate.value,
    specialStatusDate.value
  );

  UIStore.animateNotificationAlert({
    icon: "BadgeCheck",
    message: `Successfully updated ${idsToProcess.length} Investment${idsToProcess.length !== 1 ? "s" : ""}!`,
  });

  // setTimeout(() => {
  //   UIStore.animateNotificationAlert({
  //     icon: "BadgeCheck",
  //     message: `Successfully updated ${idsToProcess.length} Investment${idsToProcess.length !== 1 ? "s" : ""}!`,
  //   });
  // }, 300);

  emit("fetch-investments");

  investmentStatus.value = "";
  statusDate.value = "";
  specialStatusDate.value = "";
  sendEmail.value = false;
}
</script>
