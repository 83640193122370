<template>
  <div class="md:h-full w-full p-6">
    <div
      class="flex flex-col w-full md:border border-platform-outlines rounded-md h-full items-center md:justify-center mt-12 md:mt-0"
    >
      <button
        type="button"
        @click="handleBack"
        class="flex items-center justify-center gap-1.5 absolute top-24 left-10"
      >
        <IconChevronLeft class="h-5 w-5 text-primary-6 mb-1" />
        <a href="/sign-in">Back</a>
      </button>
      <div v-if="emailSuccess" class="flex flex-col items-center">
        <h3 class="text-3xl mb-2">Instructions Sent!</h3>
        <p class="text-gray-400 text-baseleading-7">
          If your email was found in our system, you will receive an email for reset instructions.
        </p>
        <div class="mt-3">
          <a href="/sign-in" class="hover:underline">
            Back to <span class="text-copper_main">Sign In</span>
          </a>
        </div>
      </div>
      <div v-else class="w-full md:max-w-[450px] p-6 md:p-0">
        <h3 class="font-bold text-2xl mb-2">Reset Password</h3>
        <h3 class="text-lighter mb-6">
          Please enter your email and we will send instructions on how to change your password
        </h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <BaseInput
            v-model="forgotPasswordEmail"
            label="Email"
            placeholder="Type Here"
            size="large"
            @keydown.enter="handleSubmitForgotPasswordEmail"
          />
        </div>
        <div class="flex flex-col items-center w-full gap-4">
          <Button
            @click="handleSubmitForgotPasswordEmail"
            @keydown.enter="handleSubmitForgotPasswordEmail"
            :disabled="!forgotPasswordEmail"
            label
            size="large"
            class="w-full"
          >
            <template v-if="isLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template #label>Submit</template>
          </Button>
        </div>
        <p v-if="submitError" class="text-functional-error-default mt-2">
          {{ submitError }}
        </p>
        <!-- Turnstyle widget -->
        <div class="mt-4" data-theme="light" id="turnstile"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";

import { useAuthStore } from "@/stores";
import { BaseInput, Button } from "@/components";
import { IconChevronLeft, IconPulseSpinner } from "@/components/icons";

const authStore = useAuthStore();

const forgotPasswordEmail = ref("");
const submitError = ref("");
const emailSuccess = ref(false);
const isLoading = ref(false);

onMounted(() => {
  turnstile.ready(function () {
    turnstile.render("#turnstile", {
      sitekey:
        import.meta.env.VITE_ENV === "Production"
          ? "0x4AAAAAAAbuzA10_ip9GLl-"
          : "1x00000000000000000000AA",
      callback: function (token) {
        authStore.turnstileToken = token;
        authStore.isTurnstileValid = true;
      },
    });
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = false;
});

async function handleSubmitForgotPasswordEmail() {
  try {
    if (!authStore.isTurnstileValid) {
      submitError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    if (!forgotPasswordEmail.value) return;
    isLoading.value = true;
    submitError.value = false;
    const emailSentResponse = await authStore.sendPasswordResetEmail(forgotPasswordEmail.value);
    if (!emailSentResponse) {
      submitError.value = true;
    } else {
      emailSuccess.value = true;
    }
  } catch (err) {
    submitError.value =
      "Error sending password reset email. Please ensure your email address is correct and try again.";
  } finally {
    isLoading.value = false;
  }
}

let timeout = null;
watch(
  () => submitError.value,
  (newValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (newValue) {
      timeout = setTimeout(() => {
        submitError.value = false;
      }, 3000);
    }
  }
);
</script>
