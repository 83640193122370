import { defineStore } from "pinia";

import { useAuthStore } from "@/stores";

export const useCollectionStore = defineStore("collectionStore", {
  state: () => ({
    mongodb: null,
    dbName: null,
    //collections
  }),
  actions: {
    async setMongodbInstance() {
      const authStore = useAuthStore();
      this.dbName = import.meta.env.VITE_REALM_DB_NAME;
      this.mongodb = authStore.currentUser.mongoClient("mongodb-atlas");
      // if we need to initilize collections for watching or decide to use SDK CRUD operations
      // this.accountCollection = this.mongodb.db(dbName).collection("Accounts");
      // this.contactCollection = this.mongodb.db(dbName).collection("Contacts");
      // this.investmentCollection = this.mongodb.db(dbName).collection("Investments");
      // this.distributionAccountCollection = this.mongodb.db(dbName).collection("DistributionAccounts");
      // this.fundingAccountCollection = this.mongodb.db(dbName).collection("FundingAccounts");
      // this.offeringCollection = this.mongodb.db(dbName).collection("Offerings");
      // this.statementCollection = this.mongodb.db(dbName).collection("MonthlyStatements");
      // this.distributionCollection = this.mongodb.db(dbName).collection("Distributions");
      // this.subscriptionAgreementCollection = this.mongodb.db(dbName).collection("SubscriptionAgreements");
      // this.contactEmailHistoryCollection = this.mongodb.db(dbName).collection("ContactEmailHistory");
      // this.achBatchesCollection = this.mongodb.db(dbName).collection("ACHBatches");
      // this.adminUserDataCollection = this.mongodb.db(dbName).collection("AdminUserData");
      // this.distributionDataCollection = this.mongodb.db(dbName).collection("DistributionData");
      // this.messagesCollection = this.mongodb.db(dbName).collection("Messages");
      // this.custodianCollection = this.mongodb.db(dbName).collection("Custodians");
      // this.accreditationCollection = this.mongodb.db(dbName).collection("Accreditation");
      // this.investmentEmailHistoryCollection = this.mongodb.db(dbName).collection("InvestmentEmailHistory");
      // this.accessTokenCollection = this.mongodb.db(dbName).collection("AccessTokens");
      // this.generalDocumentsCollection = this.mongodb.db(dbName).collection("GeneralDocuments");
      // this.adminRoutesCollection = this.mongodb.db(dbName).collection("AdminRoutes");
      // this.adminPermissionsCollection = this.mongodb.db(dbName).collection("AdminPermissions");
    },
    decryptField(field) {
      const authStore = useAuthStore();
      // if field is null or empty string, don't do anything
      if (!field) {
        return "";
      }
      let decodedBase64 = atob(field);
      return decodedBase64;
    },
    encryptField(field) {
      const authStore = useAuthStore();
      // if field is null or empty string, don't do anything
      if (!field) {
        return "";
      }
      let encodedBase64 = btoa(field);
      return encodedBase64;
    },
  },
});
