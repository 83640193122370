<template>
  <div
    v-if="
      !createInvestmentStore.investment_being_created &&
      !createInvestmentStore.show_investment_completion_screen
    "
    class="flex flex-col gap-8 mb-4"
  >
    <div>
      <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
        Step {{ createInvestmentStore.currentStep + 1 }} : Review and Complete Investment
      </div>
    </div>
    <div class="text-sm text-light">
      User: {{ createInvestmentStore.current_owner_contact_full_name }} - Offering Type:
      {{ createInvestmentStore.selected_offering.name }} ({{
        createInvestmentStore.selected_offering.interest_rate
      }}%-{{ createInvestmentStore.selected_offering.bond_term / 12 }} Years-{{
        createInvestmentStore.payment_frequency
      }}) - Investment Type: {{ createInvestmentStoreinvestment_type }} - Amount:
      {{ createInvestmentStore.investment_amount }}
    </div>
    <div class="pb-8 border-b border-platform-outlines">
      <div class="font-bold">Signature Date</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          :required="true"
          v-model="createInvestmentStore.investment_signature_date"
          type="date"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>
    <div
      v-if="
        createInvestmentStore.investment_type.includes('IRA') ||
        createInvestmentStore.investment_type.includes('HSA') ||
        createInvestmentStore.investment_type.includes('ira') ||
        createInvestmentStore.investment_type.includes('hsa')
      "
      class="pb-8 border-b border-platform-outlines"
    >
      <div class="font-bold">Custodian Details</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          :required="true"
          label="Custodian Name"
          v-model="createInvestmentStore.custodian_name"
          type="select"
          :options="schemaStore.custodian_name_options"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          :required="true"
          label="Custodian Account Number"
          v-model="createInvestmentStore.custodian_account_number"
          type="number"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>
    <div class="pb-8 border-b border-platform-outlines">
      <div class="font-bold">Email Preferences</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div class="block text-sm font-semibold text-lighter">
            Would you like to notify this investor that an investment has been created?
          </div>
          <BaseInput
            :required="true"
            v-model="createInvestmentStore.notify_user_of_created_investment"
            type="boolean"
            size="large"
            customClass="bg-neutral-gray-1"
          ></BaseInput>
        </div>
      </div>
    </div>
    <div>
      <div class="font-semibold text-lg">Upload Signed Sub Agreement</div>
      <div class="my-2">Please verify before submitting that the:</div>
      <ul class="list-disc pl-5 text-gray-500">
        <li>
          Name on the sub agreement matches the name in the personal information form you filled out
        </li>
        <li>Sensitive information filled out (banking, SSN, EIN) matches what is on the sub</li>
        <li>Joint signature exists on the sub agreeement in the case of a joint investment</li>
      </ul>
    </div>
    <div>
      <div v-if="createInvestmentStore.uploaded_subagreeement">
        <div class="mb-4 flex items-center border p-4 rounded-md shadow-sm">
          <div class="flex-grow">
            <div class="text-sm">{{ createInvestmentStore.uploaded_subagreeement.name }}</div>
          </div>

          <div class="flex gap-1">
            <Button @click="removeFile()" icon variant="default-gray-outlined">
              <template #icon>
                <Icon
                  name="Trash"
                  class="h-4.5 w-4.5 stroke-functional-error-default"
                  :stroke-width="2"
              /></template>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <input @change="handleUpload" ref="fileInputRef" type="file" hidden />
        <div
          class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0 bg-light bg-opacity-20"
          :class="dragging ? 'border-blue-500 bg-blue-100' : 'bg-light bg-opacity-20'"
          @drop="drop"
          @dragover.prevent="dragging = true"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <IconX class="h-6 w-6 fill-cTeal" />
          <p>Drag the file here or click upload</p>

          <div class="mt-4">
            <Button @click="chooseFiles" label>
              <template #label>{{
                createInvestmentStore.uploaded_subagreeement ? "Replace File" : "Upload File"
              }}</template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="createInvestmentStore.investment_being_created">Investment being created ...</div>
  <div v-if="createInvestmentStore.show_investment_completion_screen">Investment complete</div>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@/components";
import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { Button, BaseInput } from "@/components";

const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

const fileInputRef = ref(null);

const dragging = ref(false);
const uploadState = ref("idle"); // 'idle', 'uploading', 'completed'
const uploadProgress = ref(0);
let uploadTimeout = null;

const removeFile = () => {
  createInvestmentStore.uploaded_subagreeement = null;
};

const simulateUpload = () => {
  uploadState.value = "uploading";
  uploadProgress.value = 0;
  uploadTimeout = setInterval(() => {
    uploadProgress.value += 50; // Increase by 50% every second
    if (uploadProgress.value >= 100) {
      clearInterval(uploadTimeout);
      uploadState.value = "completed";
    }
  }, 1000);
};

const chooseFiles = () => {
  fileInputRef.value.click();
};

const handleUpload = async (e) => {
  createInvestmentStore.uploaded_subagreeement = e.target.files[0];
  simulateUpload();
};

const drop = (e) => {
  e.preventDefault();
  dragging.value = false;
  createInvestmentStore.uploaded_subagreeement = e.dataTransfer.files[0];
  simulateUpload();
};
</script>
