<template>
  <div class="flex justify-between w-full">
    <div
      class="flex items-center text-sm"
      id="base-input"
      :class="{ 'opacity-50 cursor-not-allowed': disabled }"
    >
      <label
        id="base-input"
        :for="id"
        class="text-sm lg:text-base cursor-[inherit]"
        :class="{ 'cursor-not-allowed': disabled }"
      >
        <div class="flex gap-2">
          <p id="base-input" class="text-primary" :class="boldLabel ? 'font-semibold text-lg' : ''">
            {{ label }}
          </p>
          <span
            v-if="edit_cta"
            @click="emit('edit-account')"
            class="text-lighter hover:underline cursor-pointer z-100"
          >
            Edit
          </span>
          <span
            v-if="delete_cta"
            @click="emit('delete-account')"
            class="text-functional-error-default hover:underline cursor-pointer z-100"
          >
            Delete
          </span>
        </div>
        <p id="base-input" class="text-lighter">{{ description }}</p>
        <p id="base-input" v-if="account_num" class="text-lighter">
          {{ collectionStore.decryptField(account_num).replace(/\d(?=\d{4})/g, "*") }}
        </p>
      </label>
    </div>
    <div class="bg-red-100 flex items-center justify-center ml-4">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useCollectionStore } from "@/stores";

const collectionStore = useCollectionStore();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  boldLabel: {
    type: Boolean,
    default: true,
  },
  value: {
    type: [String, Object, Boolean, null],
    required: true,
  },
  modelValue: {
    type: [String, Object, Boolean, null],
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  account_num: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  edit_cta: {
    type: Boolean,
    default: false,
  },
  delete_cta: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "edit-account", "delete-account"]);

const updateValue = () => {
  if (!props.disabled) {
    emit("update:modelValue", props.value);
  }
};

const handleClick = () => {
  if (!props.disabled) {
    updateValue();
  }
};

const isSelected = computed(() => {
  if (typeof props.modelValue === "object" && typeof props.value === "object") {
    return JSON.stringify(props.modelValue) === JSON.stringify(props.value);
  }
  return props.modelValue === props.value;
});
</script>
