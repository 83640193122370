<template>
  <div class="flex min-h-full items-center justify-between rounded-md bg-neutral-gray-3 p-4">
    <div class="flex items-center gap-4">
      <div>
        <h3 class="mb-1 text-base font-bold">{{ data.card_name }}</h3>
        <p class="text-xs text-lighter">{{ data.description }}</p>
      </div>
    </div>
    <div class="flex flex-row">
      <Button @click="openModal()" icon variant="default-gray-outlined" size="small" class="mx-1">
        <template #icon><IconEdit /></template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { Button } from "@/components";

import { useDataModelManagementStore } from "../../stores/useDataModelManagementStore";
import { IconEdit } from "@/components/icons";
import _ from "lodash";

const dataModelManagementStore = useDataModelManagementStore();

const props = defineProps({
  data: Object,
});

function openModal() {
  dataModelManagementStore.editListModalOpen = true;
  dataModelManagementStore.list_being_edited = _.cloneDeep(
    dataModelManagementStore.all_available_lists.find((setting) => setting._id === props.data._id)
  );
}
</script>
