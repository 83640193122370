<template>
  <HistoryEditsDrawer
    @close-drawer="isEditsDrawerOpen = false"
    :open="isEditsDrawerOpen"
    :edits="historyEditsForModal"
  />
  <div v-if="UIStore.isLoading">
    <LoadingSpinner :show="UIStore.isLoading" message="Loading..." hideOverlay />
  </div>
  <div v-else class="px-4 md:px-6 lg:px-10 py-6 flex flex-col h-full">
    <div v-if="isCSVGenerating">
      <LoadingSpinner :show="isCSVGenerating" message="Loading..." />
    </div>
    <div class="flex flex-col md:flex-row gap-2 justify-between">
      <div class="flex items-center gap-2">
        <div
          class="flex h-10 w-10 min-h-10 min-w-10 items-center justify-center rounded-lg bg-opacity-20 bg-secondary-6"
        >
          <div class="flex h-8 w-8 items-center justify-center rounded-md bg-secondary-6">
            <Icon name="History" class="h-6 w-6 text-neutral-gray-1" />
          </div>
        </div>
        <p class="text-2xl font-bold uppercase whitespace-nowrap">History</p>
      </div>
    </div>
    <div
      class="hidden sm:flex flex-col lg:flex-row justify-between items-end mt-6 w-full gap-4 lg:gap-10"
    >
      <div class="flex flex-col md:flex-row items-end gap-4 w-full xl:w-1/2">
        <BaseInput
          v-model="filters.collection"
          placeholder="Collection"
          label="Collection"
          size="large"
          type="dropdown"
          :options="['Investment', 'Contact', 'Account', 'BankAccount']"
        />
        <BaseInput
          v-model="filters.record_id"
          placeholder="Record ID"
          label="Record ID"
          size="large"
          type="text"
        />
        <BaseInput
          v-model="filters.updated_by"
          placeholder="Updated By"
          label="Updated By"
          size="large"
          type="text"
        />
      </div>

      <div class="flex gap-2">
        <Button
          label
          @click="handleDownloadHistoryCSV"
          variant="default-gray-outlined"
          class="w-full md:w-auto"
          size="large"
        >
          <template #label>Download .CSV</template>
        </Button>
        <Button
          label
          @click="clearFilters"
          variant="default-gray-outlined"
          class="w-full md:w-auto"
          size="large"
        >
          <template #label>Clear</template>
        </Button>
        <Button @click="applyFilters" label class="w-full md:w-auto" size="large">
          <template #label>Apply</template>
        </Button>
      </div>
    </div>
    <!-- Mobile -->
    <div class="flex flex-col sm:hidden border-platform-outlines border rounded-xl mt-4">
      <div class="w-full flex justify-between p-4 items-center">
        <span class="font-bold">Filter</span>
        <div
          class="border-platform-outlines border rounded-lg p-1.5 cursor-pointer"
          @click="filterDropOpen = !filterDropOpen"
        >
          <Icon :name="filterDropOpen ? 'ChevronUp' : 'ChevronDown'" class="w-4 h-4" />
        </div>
      </div>
      <div
        class="transition-all duration-300 overflow-hidden"
        :class="filterDropOpen ? 'max-h-screen' : 'max-h-0'"
      >
        <div
          class="flex flex-col md:flex-row flex-wrap items-end gap-4 w-full py-6 px-4 grow bg-neutral-gray-3"
        >
          <BaseInput
            v-model="filters.collection"
            placeholder="Collection"
            label="Collection"
            size="large"
            type="dropdown"
            :options="['Investment', 'Contact', 'Account', 'BankAccount']"
          />
          <BaseInput
            v-model="filters.record_id"
            placeholder="Record ID"
            label="Record ID"
            size="large"
            type="text"
          />
          <BaseInput
            v-model="filters.updated_by"
            placeholder="Updated By"
            label="Updated By"
            size="large"
            type="text"
          />
        </div>
        <div class="flex flex-row items-center gap-4 p-4 justify-center">
          <Button
            label
            @click="handleDownloadAuthLogCSV"
            variant="default-gray-outlined"
            class="w-full md:w-auto"
            size="large"
          >
            <template #label>Download .CSV</template>
          </Button>
          <Button
            class="w-full"
            variant="default-gray-outlined"
            :label="true"
            size="large"
            @click="clearFilters"
          >
            <template #label> Clear </template>
          </Button>

          <Button class="w-full" :label="true" size="large" @click="applyFilters">
            <template #label> Apply </template>
          </Button>
        </div>
      </div>
    </div>
    <div class="border-b border-platform-outlines w-full my-6"></div>
    <div v-if="historyStore.history?.length" class="flex flex-col justify-between grow">
      <div>
        <Table
          @setSortHeader="(header, props) => setSortHeader(header, props)"
          :headers="historyTableHeaders"
          :tableData="UIStore.transformRecords(historyStore.history)"
          class="max-w-full"
          use_table_loader
          isResizable
          :fields-with-custom-styles="['mongodb_record_id', 'modified_date', 'edits']"
          actions_always_sticky
        >
          <template v-slot:mongodb_record_id="data">
            <div class="ml-4 text-gold flex items-center gap-2">
              <RouterLink
                v-if="
                  data.row.object_name?.value === 'Investment' ||
                  data.row.object_name?.value === 'Contact'
                "
                :to="`/${data.row.object_name?.value?.toLowerCase()}?recordId=${data.row.mongodb_record_id.value}`"
              >
                {{ data.row.mongodb_record_id.value }}
              </RouterLink>
              <p v-else class="text-dark">
                {{ data.row.mongodb_record_id.value }}
              </p>
              <div
                class="flex gap-2 items-center"
                @click="handleCopy(data.row.mongodb_record_id.value, data.row.modified_date.value)"
              >
                <Icon
                  :name="
                    copyValue ===
                    `${data.row.mongodb_record_id.value}${new Date(data.row.modified_date.value)?.getTime()}`
                      ? 'CheckCircle'
                      : 'Copy'
                  "
                  :stroke-width="2"
                  class="cursor-pointer"
                  :class="
                    copyValue ===
                    `${data.row.mongodb_record_id.value}${new Date(data.row.modified_date.value)?.getTime()}`
                      ? 'text-green-500 h-4 w-4'
                      : 'text-primary-2 h-4 w-4'
                  "
                />
              </div>
            </div>
          </template>
          <template v-slot:modified_date="data">
            <div class="ml-4">
              <p>
                {{
                  new Date(data?.row?.modified_date?.value)?.toLocaleDateString("en-US", {
                    timeZone: "UTC",
                  })
                }}
                -
                {{
                  new Date(data?.row?.modified_date?.value)?.toLocaleTimeString("en-US", {
                    timeZone: "UTC",
                  })
                }}
                (UTC)
              </p>
            </div>
          </template>
          <template v-slot:edits="data">
            <div class="ml-4">
              <p>
                {{ data?.row?.edits?.value?.length }}
              </p>
            </div>
          </template>
          <template v-slot:table-ctas="entry">
            <div class="flex items-center space-x-2">
              <Button
                @click="handleOpenEditsDrawer(entry)"
                size="small"
                variant="default-gray-outlined"
                icon
                label
              >
                <template #label> <p class="mt-0.5">View Edits</p> </template>
                <template #icon>
                  <Icon name="Eye" class="h-4 w-4" />
                </template>
              </Button>
            </div>
          </template>
        </Table>
      </div>
      <Pagination
        v-if="historyStore.history?.length > 0"
        show-count
        :total-category-results="historyStore.totalHistoryCount"
        :page-size="20"
        :page-num="historyStore.historyPage"
        @change-page="historyStore.historyPage = $event"
        class="mt-8"
      />
    </div>
    <div
      v-else-if="!historyStore.history?.length && !UIStore.isLoading"
      class="flex flex-col gap-2 items-center justify-center mt-8"
    >
      <EmptyState description="No History to Display." icon_name="History" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { ObjectId } from "bson";

import {
  Icon,
  Button,
  BaseInput,
  Pagination,
  Table,
  LoadingSpinner,
  EmptyState,
} from "@/components";
import { useUIStore, useHistoryStore } from "@/stores";
import { sanitizeJSONAndDownloadCSV } from "@/utilities";
import HistoryEditsDrawer from "./HistoryEditsDrawer.vue";

const UIStore = useUIStore();
const historyStore = useHistoryStore();

const filters = reactive({
  collection: "",
  record_id: "",
  updated_by: "",
});

const historyTableHeaders = ref([
  { field_name: "object_name", label: "Collection" },
  { field_name: "mongodb_record_id", label: "Record ID" },
  { field_name: "modified_date", label: "Updated Date" },
  { field_name: "modified_by_name", label: "Update By" },
  { field_name: "actions", label: "Actions" },
]);

const filterDropOpen = ref(false);
const isCSVGenerating = ref(false);
const copyValue = ref("");
const isEditsDrawerOpen = ref(false);
const historyEditsForModal = ref([]);

onMounted(onMountedFunction);

async function onMountedFunction() {
  UIStore.isLoading = true;
  await historyStore.fetchHistory();
  UIStore.isLoading = false;
}

watch(
  () => historyStore.historyPage,
  async () => {
    await historyStore.fetchHistory();
  }
);

async function applyFilters() {
  if (!filters.collection && !filters.record_id && !filters.updated_by) {
    return;
  }

  if (filters.collection) {
    historyStore.historyFilter = { object_name: filters.collection };
  }

  if (filters.record_id) {
    historyStore.historyFilter = {
      ...historyStore.historyFilter,
      mongodb_record_id: ObjectId(filters.record_id),
    };
  }

  if (filters.updated_by) {
    historyStore.historyFilter = {
      ...historyStore.historyFilter,
      modified_by_name: filters.updated_by,
    };
  }

  await historyStore.fetchHistory();
}

async function clearFilters() {
  filters.collection = "";
  filters.record_id = "";
  filters.updated_by = "";
  filters.action = "";
  historyStore.historyPage = 1;
  historyStore.historySort = null;
  historyStore.historyFilter = null;
  await historyStore.fetchHistory();
}

async function handleDownloadHistoryCSV() {
  isCSVGenerating.value = true;
  const headers = {
    object_name: "Collection",
    mongodb_record_id: "Record ID",
    modified_date: "Updated Date",
    modified_by_name: "Update By",
  };
  const allHistory = await historyStore.fetchAllHistoryForCSV();
  const fileName = `HISTORY_${new Date().toLocaleDateString().replace(/\//g, "_")}`;
  sanitizeJSONAndDownloadCSV(headers, allHistory, fileName);
  isCSVGenerating.value = false;
}

async function setSortHeader(header) {
  UIStore.setSortHeader(header);
  const fieldName = header.field_name;

  historyStore.historySort = {
    [fieldName]: UIStore.sortAscending ? 1 : -1,
  };

  await historyStore.fetchHistory();
}

function handleOpenEditsDrawer(entry) {
  historyEditsForModal.value = entry.entry.edits.value || [];
  isEditsDrawerOpen.value = true;
}

async function handleCopy(text, date) {
  try {
    copyValue.value = "";
    await navigator.clipboard.writeText(text);
    const unix = new Date(date).getTime();

    copyValue.value = `${text}${unix}`;

    setTimeout(() => {
      copyValue.value = "";
    }, 3000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}
</script>
