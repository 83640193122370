<template>
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#tivagvb9ea)">
      <mask
        id="9ysczm3btb"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path fill="#D9D9D9" d="M0 0h16v16H0z" />
      </mask>
      <g mask="url(#9ysczm3btb)">
        <path
          d="M11.998 10.399a.774.774 0 0 1-.57-.23.774.774 0 0 1-.23-.57V6.4c0-.226.077-.416.23-.57a.774.774 0 0 1 .57-.23h2.4c.226 0 .416.077.57.23.153.154.23.344.23.57v.8h-1.2v-.4h-1.6v2.4h1.6v-.4h1.2v.8a.774.774 0 0 1-.23.569.774.774 0 0 1-.57.23h-2.4zm-5.997 0V5.6H9.2c.226 0 .416.076.57.23.153.153.23.343.23.57V7.2a.774.774 0 0 1-.23.57.774.774 0 0 1-.57.23c.226 0 .416.076.57.23.153.153.23.343.23.57v.8a.774.774 0 0 1-.23.569.774.774 0 0 1-.57.23H6zm1.2-3H8.8V6.8H7.2v.6zm0 1.8H8.8v-.6H7.2v.6zM.802 10.4V6.4c0-.226.076-.416.23-.57a.774.774 0 0 1 .57-.23h2.399c.226 0 .416.077.57.23.153.154.23.344.23.57V10.4h-1.2v-1.2h-1.6v1.2h-1.2zm1.2-2.4h1.599V6.8h-1.6V8z"
          fill="#BFBFBF"
        />
      </g>
    </g>
    <defs>
      <clipPath id="tivagvb9ea">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
