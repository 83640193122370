<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Transferor Details
    </div>

    <div class="mt-4 border-b text-sm text-lighter border-platform-outlines pb-4">
      The created investment will initially be of type 'Individual' and can be adjusted by a
      secondary title transfer after it is settled.
    </div>

    <div class="mt-4 border-b border-platform-outlines pb-4">
      <div class="font-bold mb-2">User Information</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <InvestorDetailCard />
      </div>
    </div>

    <div class="py-8 border-b border-platform-outlines">
      <div class="font-bold">Validate User Information</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <BaseInput
            v-model="titleTransferStore.owner_entered_email"
            type="string"
            label="User Email"
            size="large"
            required
          />
          <div v-if="invalid_user_email" class="mt-2 text-functional-error-default">
            Emails do not match
          </div>
          <div
            class="mt-2 text-green-400"
            v-if="
              titleTransferStore.owner_entered_email ===
              titleTransferStore.current_owner_contact.email
            "
          >
            Emails Match!
          </div>
        </div>
        <div>
          <BaseInput
            v-model="titleTransferStore.owner_entered_ssn"
            type="number"
            label="Last 4 Digits of User SSN"
            size="large"
            required
          />
          <div v-if="invalid_user_ssn" class="mt-2 text-functional-error-default">
            The last 4 digits of the SSN do not match
          </div>
          <div
            v-if="!invalid_user_ssn && titleTransferStore.owner_entered_ssn.length >= 4"
            class="mt-2 text-green-400"
          >
            SSNs Match!
          </div>
        </div>
      </div>
    </div>
    <div class="py-8">
      <div class="font-bold">Transferor Signature Date</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          v-model="titleTransferStore.transferor_signature_date"
          type="date"
          size="large"
          label="Transferor Signature Date"
          required
        />
      </div>
      <div class="mt-4 text-sm text-lighter pb-4">
        This signature will be used as the signature date on the investment
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { BaseInput } from "@/components";
import { useTitleTransferStore } from "@/stores";
import InvestorDetailCard from "./InvestorDetailCard.vue";

const titleTransferStore = useTitleTransferStore();

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const invalid_user_email = computed(() => {
  const { owner_entered_email, current_owner_contact } = titleTransferStore;
  return (
    emailRegex.test(owner_entered_email) && owner_entered_email !== current_owner_contact.email
  );
});

const invalid_user_ssn = computed(() => {
  const { owner_entered_ssn, current_owner_contact } = titleTransferStore;
  return (
    owner_entered_ssn.length >= 4 && owner_entered_ssn !== current_owner_contact?.ssn?.slice(-4)
  );
});

watch(
  () => [
    titleTransferStore.owner_entered_email,
    titleTransferStore.owner_entered_ssn,
    titleTransferStore.transferor_signature_date,
  ],
  () => {
    titleTransferStore.owner_transferor_is_invalid =
      titleTransferStore.owner_entered_ssn !==
        titleTransferStore.current_owner_contact?.ssn?.slice(-4) ||
      titleTransferStore.owner_entered_email !== titleTransferStore.current_owner_contact.email ||
      !titleTransferStore.transferor_signature_date;
  }
);
</script>
