<template>
  <CreateInvestorDrawer
    :open="investorStore.new_investor_drawer_open"
    @close-drawer="investorStore.new_investor_drawer_open = false"
  />
  <CreateInvestmentDrawer
    :open="createInvestmentStore.investment_drawer_open"
    @close-drawer="createInvestmentStore.investment_drawer_open = false"
  />
  <GetSigningLinksModal
    :open="showSigningLinksModal"
    @close-modal="showSigningLinksModal = false"
  />
  <ChangeEmailModal :open="showChangeEmailModal" @close-modal="showChangeEmailModal = false" />
  <!-- LANDING -->
  <div
    v-if="
      !route.query.recordId && !page_is_loading && !createInvestmentStore.investment_being_created
    "
    class="px-0 sm:px-4 lg:px-10 py-5 max-w-[100vw]"
  >
    <AllRecordsTable
      :fields-with-custom-styles="['email', 'actions']"
      keep_actions_on_sort
      actions_always_sticky
      :customData="{ header_name: 'Investors', bg_color: 'bg-custom-orange' }"
    >
      <template v-slot:additional-ctas>
        <div v-if="!authStore.isDalmoreAdmin" class="flex flex-row items-center gap-3 mx-3">
          <Button @click="openNewInvetorModal" icon label>
            <template #icon> <Icon name="Plus" class="h-5 w-5" /> </template>
            <template #label>Create Investor</template>
          </Button>
        </div>
      </template>

      <template v-slot:actions="data">
        <div class="px-4 h-[3.25rem] bg-white flex items-center justify-center border-l">
          <Button
            @click="
              handleOpenTab(
                `/contacts?recordId=${data.row.row._id.value}`,
                data.row.row.first_name.value,
                data.row.row.last_name.value
              )
            "
            icon
            label
            isIconOnlyOnMobile
            size="small"
            variant="default-gray-outlined"
          >
            <template #icon>
              <Icon name="SquareArrowOutUpRight" class="h-4 w-4 stroke-primary-6" />
            </template>
            <template #label> New Tab </template>
          </Button>
        </div>
      </template>
      <template v-slot:email="data">
        <div class="ml-4 truncate text-gold">
          <RouterLink :to="`/contacts?recordId=${data.row.row._id.value}`">
            {{ data.row.row.email.value }}
          </RouterLink>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <div
    v-else-if="
      route.query.recordId &&
      !page_is_loading &&
      !createInvestmentStore.investment_being_created &&
      !recordDetailStore.invalidContactPage
    "
    class="py-5"
  >
    <!-- Absolute Create Investment CTA for mobile -->
    <div
      class="md:hidden fixed bottom-0 w-full bg-white p-4 h-[4.625rem] z-50 flex items-center justify-center"
    >
      <Button
        v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
        @click="openInvestmentModal"
        size="large"
        label
        class="w-full"
      >
        <template #label>Create New Investment</template>
      </Button>
    </div>
    <div class="px-4 md:px-6 lg:px-10">
      <!-- Header + CTA Section -->
      <div class="flex justify-between gap-2">
        <div class="flex items-center gap-2 truncate">
          <div
            class="flex h-10 w-10 min-h-10 min-w-10 items-center justify-center rounded-lg bg-opacity-20 bg-[#469345]"
          >
            <div class="flex h-8 w-8 items-center justify-center rounded-md bg-[#469345]">
              <Icon name="UsersRound" :stroke-width="2" class="h-6 w-6 text-neutral-gray-1" />
            </div>
          </div>
          <p class="text-2xl font-bold uppercase truncate whitespace-nowrap">
            {{
              `${recordDetailStore.currentPageDocument.first_name} ${recordDetailStore.currentPageDocument.last_name}`
            }}
          </p>
        </div>
        <div class="flex items-center gap-2">
          <Button
            v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
            @click="openInvestmentModal"
            label
            class="hidden md:block"
          >
            <template #label>Create Investment</template>
          </Button>
          <Button @click="handleOpenInNewTab" label icon variant="default-gray-outlined">
            <template #icon>
              <Icon name="SquareArrowOutUpRight" :stroke-width="2" class="w-4 h-4" />
            </template>
            <template #label>New Tab</template>
          </Button>
          <Menu
            v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
            :actions="actionsList"
            :customWidth="`w-48`"
          >
            <template #button-content>
              <div
                class="h-9 w-9 rounded gap-1 text-xs flex items-center justify-center transition whitespace-nowrap border border-neutral-gray-4 bg-white hover:bg-neutral-gray-4 active:bg-neutral-gray-5 text-primary box-border cursor-pointer"
              >
                <Icon name="EllipsisVertical" class="h-4 w-4" :stroke-width="2" />
              </div>
            </template>
          </Menu>
        </div>
      </div>
      <!-- Info Nuggets -->
      <div class="mt-6 flex flex-col md:flex-row gap-4 text-primary-2 text-sm">
        <div class="flex gap-2 items-center">
          <div>
            <p class="text-xs text-light">EMAIL</p>
            <p class="text-primary-6">{{ recordDetailStore.currentPageDocument.email }}</p>
          </div>
          <div
            class="bg-opacity-20 p-1.5 rounded-lg cursor-pointer transition-all"
            :class="emailCopied ? 'bg-functional-success-default' : 'bg-neutral-gray-6'"
            @click="handleCopy(recordDetailStore.currentPageDocument.email, 'emailCopied')"
          >
            <Icon name="Copy" class="w-4 h-4 text-primary-6" v-if="emailCopied != true" />
            <IconCircleCheck class="w-4 h-4 fill-functional-success-default" v-else />
          </div>
        </div>
        <div class="flex gap-2 items-center">
          <div>
            <p class="text-xs text-light">CONTACT ID</p>
            <RouterLink
              :to="`/contacts?recordId=${recordDetailStore.currentPageDocument._id}`"
              class="text-gold"
              >{{ recordDetailStore.currentPageDocument._id }}</RouterLink
            >
          </div>
          <div
            class="bg-opacity-20 p-1.5 rounded-lg cursor-pointer transition-all"
            :class="idCopied ? 'bg-functional-success-default' : 'bg-neutral-gray-6'"
            @click="handleCopy(recordDetailStore.currentPageDocument._id, 'idCopied')"
          >
            <Icon name="Copy" class="w-4 h-4 text-primary-6" v-if="idCopied != true" />
            <IconCircleCheck class="w-4 h-4 fill-functional-success-default" v-else />
          </div>
        </div>
      </div>
      <div class="border-b border-platform-outlines w-full my-4"></div>
    </div>

    <div class="md:px-6 lg:px-10">
      <CustomTabs
        :tabList="tabsToShow"
        @set-active-tab="investorStore.active_profile_tab = $event"
        :activeTab="investorStore.active_profile_tab"
      />
    </div>
    <div
      class="mt-6 md:px-6 lg:px-10 pb-16 md:pb-0"
      :class="investorStore.active_profile_tab === 'Investments' ? '' : 'px-4'"
    >
      <InvestorDashboard v-show="investorStore.active_profile_tab === 'Investments'" />
      <PersonalInfoForm v-show="investorStore.active_profile_tab === 'Personal'" />
      <JointInfoForm
        v-show="
          investorStore.active_profile_tab === 'Joint' && investmentStore.hasSignedJointInvestment
        "
      />
      <EntityInfoForm
        v-show="
          investorStore.active_profile_tab === 'Entity' && investmentStore.hasSignedEntityInvestment
        "
      />
      <SuitabilityQuestionnaireForm
        v-show="
          investorStore.active_profile_tab === 'Questionnaire' &&
          investmentStore.hasAccreditedInvestments
        "
      />
      <BankingInfoForm v-show="investorStore.active_profile_tab === 'Banking'" />
      <GeneralDocuments v-show="investorStore.active_profile_tab === 'Documents'" />
    </div>
  </div>
  <div
    v-else-if="
      route.query.recordId &&
      !page_is_loading &&
      !createInvestmentStore.investment_being_created &&
      recordDetailStore.invalidContactPage
    "
  >
    <div class="h-96 w-full flex flex-col items-center justify-center">
      <div>Hmmm... Looks like you landed on a Joint or Beneficial Owner Contact...</div>
      <Button @click="routeToOwner" label>
        <template #label>View Owner Contact</template>
      </Button>
    </div>
  </div>
  <div v-else-if="page_is_loading || createInvestmentStore.investment_being_created">
    <LoadingSpinner
      :show="page_is_loading || createInvestmentStore.investment_being_created"
      :message="recordDetailStore.pageLoaderMessage"
      :hide-overlay="true"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  useRecordDetailStore,
  useCreateInvestmentStore,
  useUIStore,
  useInvestmentStore,
  useInvestorStore,
  useAuthStore,
} from "@/stores";
import { Button, LoadingSpinner, AllRecordsTable, Icon, CustomTabs, Menu } from "@/components";
import { IconCopyAll, IconCircleCheck } from "@/components/icons";
import { CreateInvestmentDrawer } from "@/pages";
import InvestorDashboard from "./InvestorDashboard.vue";
import PersonalInfoForm from "./PersonalInfoForm.vue";
import JointInfoForm from "./JointInfoForm.vue";
import EntityInfoForm from "./EntityInfoForm.vue";
import SuitabilityQuestionnaireForm from "./SuitabilityQuestionnaireForm.vue";
import BankingInfoForm from "./BankingInfoForm.vue";
import GeneralDocuments from "./GeneralDocuments.vue";
import CreateInvestorDrawer from "./CreateInvestorDrawer.vue";
import GetSigningLinksModal from "./GetSigningLinksModal.vue";
import ChangeEmailModal from "./ChangeEmailModal.vue";

const route = useRoute();
const router = useRouter();

const recordDetailStore = useRecordDetailStore();
const createInvestmentStore = useCreateInvestmentStore();
const UIStore = useUIStore();
const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const authStore = useAuthStore();

const page_is_loading = ref(false);
const emailCopied = ref(false);
const idCopied = ref(false);
const showSigningLinksModal = ref(false);
const showChangeEmailModal = ref(false);
const actionsList = ref([]);

onMounted(async () => {
  await onMountedFunction();
});

const tabsToShow = computed(() => {
  const tabs = ["Investments", "Personal"];

  if (investmentStore.hasSignedJointInvestment) {
    tabs.push("Joint");
  }
  if (investmentStore.hasSignedEntityInvestment) {
    tabs.push("Entity");
  }
  if (!authStore.isDalmoreAdmin) {
    tabs.push("Banking");
  }
  if (investmentStore.hasAccreditedInvestments) {
    tabs.push("Questionnaire");
  }
  tabs.push("Documents");
  return tabs;
});

watch(
  () => [route, route.query.recordId],
  async () => {
    if (route.path == "/contacts") {
      await onMountedFunction();
    }
    investmentStore.chartData.datasets = [
      {
        label: "Invested",
        data: [],
        backgroundColor: ["rgba(110, 159, 216, 0.5)"],
        borderColor: ["#3f87dc"],
        borderWidth: 1,
      },
      {
        label: "Dividends",
        data: [],
        backgroundColor: ["#426fa2"],
        borderColor: ["#3f87dc"],
        borderWidth: 1,
      },
      {
        label: "Compounding Interest",
        data: [],
        backgroundColor: ["#fbf6e1"],
        borderColor: ["#9e7400"],
        borderWidth: 1,
      },
    ];
  }
);

watch(
  () => recordDetailStore.allRecordsPageNumber,
  async (newValue) => {
    await recordDetailStore.fetchTableRecords(true);
  }
);

async function onMountedFunction() {
  investorStore.active_profile_tab = "Investments";
  UIStore.sortHeader = { field_name: "created_date" };
  UIStore.sortAscending = false;
  page_is_loading.value = true;
  recordDetailStore.currentPageCollection = "Contacts";
  recordDetailStore.filterPipeline = null;
  recordDetailStore.defaultFilterPipeline = null;
  await recordDetailStore.setCurrentPageConfig();
  if (route.query.recordId) {
    await recordDetailStore.setCurrentPageDocument(route.query.recordId);
    // get investment columns for filtering transactions
    await recordDetailStore.getPossibleColumns("Investments");
    await investorStore.setInvestorData();
    setAdminActions();
  } else {
    recordDetailStore.defaultFilterPipeline = { type: "Regular" };
    await recordDetailStore.getPossibleColumns("Contacts");
    await recordDetailStore.fetchTableRecords(true);
  }
  page_is_loading.value = false;
  recordDetailStore.pageLoaderMessage = "Loading...";
}

function handleOpenInNewTab() {
  const fullPath = route.fullPath;
  const investorName = `${recordDetailStore.currentPageDocument.first_name[0]}. ${recordDetailStore.currentPageDocument.last_name}`;
  UIStore.createNewTab({
    name: investorName,
    fullPath,
  });
}
function handleOpenTab(path, first, last) {
  const fullPath = path;
  const investorName = `${first[0]}. ${last}`;
  UIStore.createNewTab({
    name: investorName,
    fullPath,
    active: false,
    add_to_front: true,
  });
}

async function handleCopy(text, type) {
  try {
    await navigator.clipboard.writeText(text);
    emailCopied.value = false;
    idCopied.value = false;

    if (type === "emailCopied") {
      emailCopied.value = true;
    } else if (type === "idCopied") {
      idCopied.value = true;
    }

    setTimeout(() => {
      if (type === "emailCopied") {
        emailCopied.value = false;
      } else if (type === "idCopied") {
        idCopied.value = false;
      }
    }, 3000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}

function openInvestmentModal() {
  createInvestmentStore.investment_drawer_open = true;
}
function openNewInvetorModal() {
  investorStore.new_investor_drawer_open = true;
}

function routeToOwner() {
  router.push(recordDetailStore.directToPage);
}

function setAdminActions() {
  actionsList.value = [
    [
      {
        label: "Force Ark Sync",
        icon: "BookUp2",
        onClick: async () => {
          await investorStore.handleForceArkSync();
        },
      },
      {
        label: "Send Password Reset",
        icon: "FileLock2",
        onClick: async () => {
          await investorStore.handleSendPasswordReset();
        },
      },
      {
        label: investorStore?.contact?.is_user_disabled ? "Enable Account" : "Disable Account",
        icon: investorStore?.contact?.is_user_disabled ? "ShieldPlus" : "ShieldMinus",
        onClick: async () => {
          const result = await investorStore.handleToggleInvestorDisabled();
          if (result) {
            actionsList.value[0][2].label = investorStore?.contact?.is_user_disabled
              ? "Enable Account"
              : "Disable Account";
            actionsList.value[0][2].icon = investorStore?.contact?.is_user_disabled
              ? "ShieldPlus"
              : "ShieldMinus";
          }
        },
      },
      {
        label: "Change Owner Email",
        icon: "MailPlus",
        onClick: async () => {
          showChangeEmailModal.value = true;
        },
      },
    ],
  ];
  const pendingInvestment = investmentStore.transactionsData.find((i) => i.status === "Pending");
  if (pendingInvestment) {
    actionsList.value[0].push({
      label: "Get Signing Links",
      icon: "Signature",
      onClick: async () => {
        showSigningLinksModal.value = true;
      },
    });
  }
}
</script>
