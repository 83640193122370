<template>
  <div class="mt-4">
    <h2 class="font-bold" :class="isSmall ? 'text-base' : 'text-xl'">
      Beneficial Owners or Beneficiaries
    </h2>
    <p class="text-lighter mt-2">
      Includes individuals that own 20% or more of the entity. At least one is required
    </p>
    <div class="flex flex-col lg:flex-row gap-4 mt-6">
      <div class="flex flex-col gap-4 w-full lg:w-1/2">
        <label for="owner_is_beneficiary" class="text-lighter inline-flex">
          <input
            id="owner_is_beneficiary"
            type="checkbox"
            v-model="investorStore.isOwnerBeneficialOwner"
            class="text-primary-6 accent-current mr-2"
          />
          <p class="mt-1">I am a beneficial owner or beneficiary</p>
        </label>
        <div
          v-for="(beneficialOwner, index) in props.beneficial_owners"
          :key="index"
          class="flex justify-between items-center"
        >
          <div class="flex gap-4 items-center">
            <Icon name="User" class="h-5 w-5 text-primary-6" />
            <p>{{ `${beneficialOwner.first_name} ${beneficialOwner.last_name}` }}</p>
          </div>
          <div class="flex items-center gap-2">
            <Button
              @click="handleEditBeneficialOwner(beneficialOwner)"
              icon
              variant="default-gray-outlined"
            >
              <template #icon>
                <Icon name="Pencil" class="h-4 w-4 text-primary-6" :strokeWidth="2" />
              </template>
            </Button>
            <div class="relative">
              <div
                v-if="beneficialOwner.show_delete_warning"
                class="filter-warning absolute right-0 top-10 border z-50 flex h-auto w-60 animate-slideup flex-col gap-3 rounded-md bg-white p-3 text-sm shadow-lg"
              >
                <p>
                  Are you sure you want to remove this beneficiary? This action cannot be undone.
                </p>
                <div class="flex items-center gap-2">
                  <Button
                    @click="handleDeleteBeneficialOwner(index, beneficialOwner)"
                    label
                    icon
                    variant="default-color-outlined"
                    size="small"
                    class="!w-full"
                  >
                    <template #icon>
                      <Icon
                        name="Trash"
                        class="h-4 w-4 text-functional-error-default"
                        :strokeWidth="2"
                      />
                    </template>
                    <template #label>Remove</template>
                  </Button>
                  <Button
                    @click="delete beneficialOwner.show_delete_warning"
                    label
                    variant="subtle"
                    size="small"
                  >
                    <template #label>Cancel</template>
                  </Button>
                </div>
              </div>
              <Button
                @click="beneficialOwner.show_delete_warning = true"
                icon
                variant="error-gray-outlined"
              >
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-4 w-4 text-functional-error-default"
                    :strokeWidth="2"
                  />
                </template>
              </Button>
            </div>
          </div>
        </div>
        <Button
          @click="handleAddNewBeneficiary"
          icon
          label
          variant="default-gray-outlined"
          class="lg:w-40"
        >
          <template #label>Add Beneficiary</template>
          <template #icon> <Icon name="UserPlus" class="h-5 w-5 text-primary-6" /></template>
        </Button>
      </div>
      <Banner type="info" hideClose class="w-full lg:w-1/2" iconTop>
        <template #content>
          <div class="flex flex-col gap-4">
            <div>
              <h3 class="font-semibold">Documents Required</h3>
              <p class="text-sm text-lighter">Phoenix Capitalgroup Group Holdings, LLC</p>
            </div>
            <div>
              <h3 class="font-semibold">Trust Accounts:</h3>
              <ul class="list-disc pl-4">
                <li>Trust Agreement</li>
              </ul>
            </div>
            <div>
              <h3 class="font-semibold">Entity Accounts:</h3>
              <ul class="list-disc pl-4">
                <li class="">Articles of incorporation and/or state incorporation documents</li>
                <li>Operating agreement</li>
              </ul>
            </div>
          </div>
        </template>
      </Banner>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";

import { Banner, Button, Icon } from "@/components";
import { useInvestorStore, useCrudStore, useUIStore } from "@/stores";
import { NEW_BENEFICIAL_OWNER_TEMPLATE } from "@/constants";

const investorStore = useInvestorStore();
const crudStore = useCrudStore();
const UIStore = useUIStore();

const emit = defineEmits(["open-modal"]);
const props = defineProps({
  beneficial_owners: {
    type: Array,
    default: () => [],
  },
  active_entity: {
    type: Object,
    default: () => ({}),
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

watch(
  () => props.active_entity,
  (newEntity) => {
    if (!newEntity) return;
    const stringEntityIds = investorStore.contact?.entity_ids?.map((id) => id.toString());
    if (!stringEntityIds) return;
    investorStore.isOwnerBeneficialOwner = stringEntityIds.includes(newEntity?._id?.toString());
  },
  { immediate: true }
);

function handleClickOutside(e) {
  if (!e.target.closest(".filter-warning")) {
    props.beneficial_owners.forEach((contact) => {
      contact.show_delete_warning = false;
    });
  }
}

function handleAddNewBeneficiary() {
  investorStore.selectedBeneficiary = null;
  investorStore.isEditingBeneficiary = false;
  emit("open-modal");
}

function handleEditBeneficialOwner(beneficialOwner) {
  investorStore.isEditingBeneficiary = true;
  investorStore.selectedBeneficiary = beneficialOwner;
  emit("open-modal"); // for profile
}

async function handleDeleteBeneficialOwner(index, beneficialOwner) {
  delete beneficialOwner.show_delete_warning;

  if (!beneficialOwner._id || !props.active_entity?._id) {
    investorStore.tempBeneficialOwners.splice(index, 1);
    return;
  }

  const deletedBeneficialOwner = props.beneficial_owners[index];
  props.beneficial_owners.splice(index, 1);

  const beneficialOwnerEntityIds = beneficialOwner.entity_ids.map((id) => id.toString());

  // Check if there are other entities tied to the beneficial owner
  const otherEntities = investorStore.entity_accounts.filter(
    (entity) =>
      beneficialOwnerEntityIds.includes(entity._id.toString()) &&
      entity._id?.toString() !== props.active_entity?._id?.toString()
  );
  try {
    if (beneficialOwner.type !== "Joint" && beneficialOwner._id && otherEntities.length === 0) {
      await investorStore.deleteBeneficialOwner(beneficialOwner);
    }

    if (otherEntities.length > 0 || beneficialOwner.type === "Joint") {
      await investorStore.updateBeneficialOwnerEntityIds(beneficialOwner, props.active_entity);
    }
  } catch (err) {
    console.error(err);
    props.beneficial_owners.splice(index, 0, deletedBeneficialOwner);
    UIStore.animateNotificationAlert({
      message: "There was an error removing the beneficial owner!",
      icon: "ShieldAlert",
      type: "error",
    });
  }
}
</script>
