<template>
  <div class="flex flex-col gap-2 mb-4">
    <div>
      <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
        Step {{ createInvestmentStore.currentStep + 1 }}: Investor Questionnaire
      </div>
    </div>
    <div v-for="group in schemaStore.suitability_questions" :key="group.group_name">
      <div v-if="group.group_name == 'Questionnaire'">
        <div class="flex-col flex justify-center">
          <div
            class="py-6 border-b border-platform-outlines"
            v-for="(schema, index) in group.fields"
            :key="schema.field_name"
          >
            <div class="text-primary-6 font-bold text-base mb-2">
              {{ index + 1 }}. {{ schema.label }}
            </div>

            <div
              :class="schema.dropdown_options.length == 2 ? 'grid grid-cols-2' : 'grid-cols-1 grid'"
            >
              <div
                v-for="(answer, answerIndex) in schema.dropdown_options"
                :key="answerIndex"
                class="text-lighter my-2"
              >
                <label>
                  <RadioOption
                    :id="answerIndex"
                    :boldLabel="false"
                    name="name"
                    :value="answer"
                    v-model="
                      createInvestmentStore.current_owner_contact.suitability_info[
                        schema.field_name
                      ]
                    "
                    :label="answer"
                  />
                </label>
              </div>
              <!-- Validation Error Messages for radio inputs -->
              <div
                v-if="
                  schema.field_name === 'risk_willingness' &&
                  createInvestmentStore.current_owner_contact.suitability_info[schema.field_name] &&
                  !['Significant', 'Speculative'].includes(
                    createInvestmentStore.current_owner_contact.suitability_info[schema.field_name]
                  )
                "
              >
                <div class="text-red-500">{{ schema.error_message }}</div>
              </div>
              <div
                v-if="
                  schema.field_name === 'significant_liquid_net_worth' &&
                  createInvestmentStore.current_owner_contact.suitability_info[
                    schema.field_name
                  ] !== 'Yes'
                "
              >
                <div class="text-red-500">{{ schema.error_message }}</div>
              </div>
              <div
                v-if="
                  schema.field_name === 'risky_investment_understanding' &&
                  createInvestmentStore.current_owner_contact.suitability_info[
                    schema.field_name
                  ] !== 'Yes'
                "
              >
                <div class="text-red-500">{{ schema.error_message }}</div>
              </div>
              <div
                v-if="
                  schema.field_name === 'self_responsibility' &&
                  createInvestmentStore.current_owner_contact.suitability_info[
                    schema.field_name
                  ] !== 'Yes'
                "
              >
                <div class="text-red-500">{{ schema.error_message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="font-semibold my-4 text-lg">
          {{ group.group_name }}
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-for="field in group.fields" :key="field.field_name">
            <BaseInput
              :required="field.is_required"
              :disabled="field.read_only"
              :label="field.label"
              v-if="!field.is_association_field"
              v-model="
                createInvestmentStore.current_owner_contact.suitability_info[field.field_name]
              "
              :formatRule="field.field_type == 'number' ? 'amount' : ''"
              :errorMessage="field.error_message"
              type="string"
              size="large"
              :options="field.dropdown_options"
              customClass="bg-neutral-gray-1"
            ></BaseInput>
            <div v-if="field.field_name === 'current_income' && currentIncomeIsInvalid">
              <div class="text-red-500">{{ field.error_message }}</div>
            </div>

            <div v-if="field.field_name === 'current_liquid_net_worth' && currentNetWorthIsInvalid">
              <div class="text-red-500">{{ field.error_message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, watch } from "vue";
import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { RadioOption, BaseInput } from "@/components";

const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

const currentIncomeIsInvalid = computed(() => {
  const income = createInvestmentStore.current_owner_contact.suitability_info.current_income;
  if (!income) return true;

  if (typeof income === "number") {
    return income < 10000;
  } else {
    const numericIncome = parseFloat(income.replace(/,/g, ""));
    return isNaN(numericIncome) || numericIncome < 10000;
  }
});
const currentNetWorthIsInvalid = computed(() => {
  let liquid_net_worth =
    createInvestmentStore.current_owner_contact.suitability_info.current_liquid_net_worth;
  let net_worth = createInvestmentStore.current_owner_contact.suitability_info.current_net_worth;

  // Convert liquid_net_worth to number if it's a string
  if (typeof liquid_net_worth !== "number") {
    liquid_net_worth = parseFloat(liquid_net_worth.replace(/,/g, ""));
  }

  // Convert net_worth to number if it's a string
  if (typeof net_worth !== "number") {
    net_worth = parseFloat(net_worth.replace(/,/g, ""));
  }

  // Check if either value is not a number
  if (isNaN(liquid_net_worth) || isNaN(net_worth)) {
    return true; // Invalid if either value is not a number
  }

  // Return true if liquid_net_worth is greater than net_worth
  return liquid_net_worth > net_worth;
});

const validateSuitabilityInfo = () => {
  let isValid = true;
  // Iterate over suitability questions
  schemaStore.suitability_questions.forEach((group) => {
    group.fields.forEach((schema) => {
      const value = createInvestmentStore.current_owner_contact.suitability_info[schema.field_name];

      // Check specific conditions for the "Questionnaire" group
      if (group.group_name === "Questionnaire") {
        if (schema.field_name === "risk_willingness") {
          if (!["Significant", "Speculative"].includes(value)) {
            isValid = false;
          }
        }
        if (schema.field_name === "significant_liquid_net_worth") {
          if (value !== "Yes") {
            isValid = false;
          }
        }
        if (schema.field_name === "risky_investment_understanding") {
          if (value !== "Yes") {
            isValid = false;
          }
        }
        if (schema.field_name === "self_responsibility") {
          if (value !== "Yes") {
            isValid = false;
          }
        }
      }
      // Additional specific check for "current_income"
      if (schema.field_name === "current_income" && currentIncomeIsInvalid.value) {
        isValid = false;
      }
      if (schema.field_name === "current_liquid_net_worth" && currentNetWorthIsInvalid.value) {
        isValid = false;
      }
      if (createInvestmentStore.current_owner_contact.suitability_info[schema.field_name] == "") {
        isValid = false;
      }
      if (
        Number(
          createInvestmentStore.current_owner_contact.suitability_info?.current_liquid_net_worth?.replace(
            /,/g,
            ""
          )
        ) >
        Number(
          createInvestmentStore.current_owner_contact.suitability_info?.current_net_worth?.replace(
            /,/g,
            ""
          )
        )
      ) {
        isValid = false;
      }
    });
  });
  const suitabilityInfo = createInvestmentStore.current_owner_contact.suitability_info;
  for (const key in suitabilityInfo) {
    if (
      suitabilityInfo[key] === undefined ||
      suitabilityInfo[key] === null ||
      suitabilityInfo[key] === ""
    ) {
      isValid = false;
      break;
    }
  }
  createInvestmentStore.is_valid_suitability_info = isValid;
};

watch(
  () => createInvestmentStore.current_owner_contact.suitability_info,
  () => {
    validateSuitabilityInfo();
  },
  { deep: true }
);

onMounted(() => {
  validateSuitabilityInfo();
});
</script>
