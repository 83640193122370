import { defineStore } from "pinia";
import * as Realm from "realm-web";

import {
  useCrudStore,
  useAuthStore,
  useInvestmentStore,
  useUIStore,
  useRecordDetailStore,
  useSchemaStore,
} from "@/stores";
import { parseDocumentsFieldsToProperType, applySchema } from "@/utilities";
import { JOINT_TEMPLATE, ENTITY_TEMPLATE } from "@/constants";
import _ from "lodash";

const {
  BSON: { ObjectId },
} = Realm;

export const useInvestorStore = defineStore("investorStore", {
  state: () => ({
    contact: null,
    account: null,
    allContacts: [],
    active_joint_contact: null,
    joint_contacts: [],
    beneficial_owner_contacts: [],
    isOwnerBeneficialOwner: false,
    tempBeneficialOwners: [], // used for adding beneficial owners to a new entity not yet saved in the DB
    selectedBeneficiary: null,
    isEditingBeneficiary: false,
    active_entity_account: null,
    entity_accounts: [],
    contactSchema: null,
    accountSchema: null,
    accreditation_documents: [],
    general_documents: [],
    active_profile_tab: "Investments",
    investor_to_process: null,
    bank_accounts: [],
    active_bank_account: null,
    active_finance_method: null,
    new_investor_drawer_open: false,
    new_investor: {},
  }),
  getters: {
    doesActiveJointHaveSignedInvestments: (state) => {
      const investmentStore = useInvestmentStore();
      if (!state.active_joint_contact || !investmentStore.transactionsData) return false;
      return investmentStore.transactionsData
        .filter((inv) => inv.type === "Joint")
        .some((inv) => {
          return inv?.joint_contact_id?.toString() === state.active_joint_contact?._id?.toString();
        });
    },
    doesActiveEntityHaveSignedInvestments: (state) => {
      const investmentStore = useInvestmentStore();
      if (!state.active_entity_account || !investmentStore.transactionsData) return false;
      return investmentStore.transactionsData
        .filter((inv) => inv.type === "Entity")
        .some((inv) => {
          return (
            inv?.entity_account_id?.toString() === state.active_entity_account?._id?.toString()
          );
        });
    },
  },
  actions: {
    async setInvestorData() {
      try {
        const crudStore = useCrudStore();
        const recordDetailStore = useRecordDetailStore();
        const investmentStore = useInvestmentStore();
        const authStore = useAuthStore();

        const user_id = recordDetailStore.currentPageDocument.user_id;

        const documentPipeline = [
          {
            $match: {
              user_id: user_id,
              type: { $in: ["General Documents", "Accreditation"] },
            },
          },
          { $sort: { created_date: -1 } },
        ];
        if (authStore.isDalmoreAdmin) {
          documentPipeline[0].$match.visible_to_compliance = true;
        }
        const [allContacts, allAccounts, allDocuments, allInvestments] = await Promise.all([
          crudStore.find("Contacts", { user_id: user_id }),
          crudStore.find("Accounts", { user_id: user_id }),
          crudStore.aggregate("Documents", documentPipeline),
          await investmentStore.getInvestorTransactions(),
        ]);

        this.allContacts = parseDocumentsFieldsToProperType(allContacts);
        this.allAccounts = parseDocumentsFieldsToProperType(allAccounts);
        investmentStore.transactionsData = allInvestments;
        const ownerContact = this.allContacts.find((contact) => contact.type == "Regular");
        if (ownerContact) {
          this.contact = ownerContact;
        }

        const allJointContacts = this.allContacts.filter((contact) => contact.type === "Joint");
        if (allJointContacts) {
          this.joint_contacts = allJointContacts;
          this.active_joint_contact = this.joint_contacts[0] || JOINT_TEMPLATE;
        }

        if (allAccounts) {
          const regularAccount = this.allAccounts.find((account) => account.type === "Regular");
          this.account = regularAccount;
          const entityAccounts = this.allAccounts.filter((account) => account.type === "Entity");
          if (entityAccounts) {
            this.entity_accounts = entityAccounts;
            this.active_entity_account = this.entity_accounts[0] || ENTITY_TEMPLATE;
          }
        }

        if (this.active_entity_account) {
          const allBeneficialOwnerContacts = this.allContacts.filter((contact) => {
            const stringEntityIds = contact?.entity_ids?.map((id) => id.toString());
            return (
              contact.type != "Regular" &&
              stringEntityIds?.includes(this.active_entity_account?._id?.toString())
            );
          });
          if (allBeneficialOwnerContacts) {
            this.beneficial_owner_contacts = allBeneficialOwnerContacts;
          }
        }

        if (allDocuments) {
          this.general_documents = allDocuments.filter(
            (doc) => doc.type === "General Documents" || doc.type === "Accreditation"
          );
          this.accreditation_documents = allDocuments.filter((doc) => doc.type === "Accreditation");
        }
      } catch (err) {
        console.error(err);
        // TODO: send custom log
      }
    },
    async updateContactAndAccount() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const schemaStore = useSchemaStore();
      try {
        const contactSchema = schemaStore.getSchemaByCollection("Contacts");
        const filteredContactSchema = contactSchema.filter(
          (f) => f.nested_object_name === "" || !f.nested_object_name
        );
        const formattedOwnerContact = applySchema(this.contact, filteredContactSchema);
        // console.log("formattedOwnerContact", formattedOwnerContact);
        const account_query = { user_id: this.contact.user_id, type: "Regular" };
        const account_update = {
          name: `${formattedOwnerContact.first_name} ${formattedOwnerContact.last_name}`,
          email: formattedOwnerContact.email,
          phone: formattedOwnerContact.phone,
          street1: formattedOwnerContact.street1,
          street2: formattedOwnerContact.street2,
          city: formattedOwnerContact.city,
          state: formattedOwnerContact.state,
          zip_code: formattedOwnerContact.zip_code,
        };
        // console.log("formattedOwnerContact", formattedOwnerContact);
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };
        const owner_contact_query = { user_id: this.contact.user_id, type: "Regular" };
        await crudStore.updateOne("Contacts", owner_contact_query, {
          $set: { ...formattedOwnerContact, ...updatedTimestamp },
        });
        await crudStore.updateOne("Accounts", account_query, {
          $set: { ...account_update, ...updatedTimestamp },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async upsertJointContact(jointContact) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const schemaStore = useSchemaStore();

      try {
        const contactSchema = schemaStore.getSchemaByCollection("Contacts");
        const filteredContactSchema = contactSchema.filter(
          (f) => f.nested_object_name === "" || !f.nested_object_name
        );
        const formattedJointContact = applySchema(jointContact, filteredContactSchema);

        formattedJointContact.user_id = this.contact.user_id;
        formattedJointContact.account_id = this.account._id;
        formattedJointContact.contact_id = this.contact._id;

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };

        if (jointContact._id) {
          // Update existing joint contact
          const joint_contact_query = { _id: jointContact._id };
          await crudStore.updateOne("Contacts", joint_contact_query, {
            $set: { ...formattedJointContact, ...updatedTimestamp },
          });
        } else {
          // Insert new joint contact
          const newJointContact = {
            ...formattedJointContact,
            created_date: new Date(),
            created_by_id: authStore.currentUser.id,
            created_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
            ...updatedTimestamp,
          };
          delete newJointContact.is_new;
          const { insertedId } = await crudStore.insertOne("Contacts", newJointContact);
          newJointContact._id = insertedId;
          const [parsedJointContact] = parseDocumentsFieldsToProperType([newJointContact]);
          this.allContacts.push(parsedJointContact);
          this.joint_contacts.push(parsedJointContact);
          this.active_joint_contact = parsedJointContact;
          return parsedJointContact;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async upsertEntityAccount(entityAccount) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const investmentStore = useInvestmentStore();
      const schemaStore = useSchemaStore();

      try {
        const accountSchema = schemaStore.getSchemaByCollection("Accounts");
        const filteredAccountSchema = accountSchema.filter(
          (f) => f.nested_object_name === "" || !f.nested_object_name
        );
        const formattedEntityAccount = applySchema(entityAccount, filteredAccountSchema);

        formattedEntityAccount.user_id = this.contact.user_id;
        formattedEntityAccount.account_id = this.account._id;
        formattedEntityAccount.contact_id = this.contact._id;

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };

        if (entityAccount?._id) {
          // Update existing entity account
          const entity_account_query = { _id: entityAccount._id };
          await crudStore.updateOne("Accounts", entity_account_query, {
            $set: { ...formattedEntityAccount, ...updatedTimestamp },
          });

          // Update owner contacts entity_ids based on if they are also a beneficial owner
          const owner_contact_query = { _id: this.contact._id };
          let currentEntityIds = new Set(
            this.contact?.entity_ids?.map((id) => id.toString()) || []
          );

          // If the owner is a beneficial owner, add the entity_id to the set (as string)
          if (this.isOwnerBeneficialOwner) {
            currentEntityIds.add(this.active_entity_account._id.toString());
          } else {
            currentEntityIds.delete(this.active_entity_account._id.toString());
          }

          // Convert back to ObjectId before updating in MongoDB
          const updatedEntityIds = [...currentEntityIds]?.map((id) => new ObjectId(id));

          // Only perform the update if the entity_ids array has actually changed
          if (
            updatedEntityIds.length !== this.contact.entity_ids.length ||
            !updatedEntityIds.every(
              (id, index) => id.toString() === this.contact.entity_ids[index].toString()
            )
          ) {
            await crudStore.updateOne("Contacts", owner_contact_query, {
              $set: { entity_ids: updatedEntityIds },
            });
          }
        } else {
          // This is a new entity w/ possibly unsaved beneficial owners
          const newEntityAccount = {
            ...formattedEntityAccount,
            created_date: new Date(),
            created_by_id: authStore.currentUser.id,
            created_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
            ...updatedTimestamp,
          };
          delete newEntityAccount.is_new;
          delete newEntityAccount.uuid;

          // Insert new entity account
          const { insertedId } = await crudStore.insertOne("Accounts", newEntityAccount);

          // Update owner contacts entity_ids based on if they are also a beneficial owner
          const owner_contact_query = { _id: this.contact._id };
          let currentEntityIds = new Set(
            this.contact?.entity_ids?.map((id) => id.toString()) || []
          );

          if (this.isOwnerBeneficialOwner) {
            currentEntityIds.add(insertedId.toString());
            const updatedEntityIds = [...currentEntityIds]?.map((id) => new ObjectId(id));
            await crudStore.updateOne("Contacts", owner_contact_query, {
              $set: { entity_ids: updatedEntityIds },
            });
          }

          // add the new entity account to local state
          newEntityAccount._id = insertedId;
          const [parsedEntityAccount] = parseDocumentsFieldsToProperType([newEntityAccount]);
          this.entity_accounts.push(parsedEntityAccount);
          this.active_entity_account = parsedEntityAccount;

          for (const beneficialOwner of this.tempBeneficialOwners) {
            await this.upsertBeneficialOwner(beneficialOwner);
          }
          return parsedEntityAccount;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async upsertBeneficialOwner(beneficialOwner) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const schemaStore = useSchemaStore();

      try {
        const contactSchema = schemaStore.getSchemaByCollection("Contacts");
        const filteredContactSchema = contactSchema.filter(
          (f) => f.nested_object_name === "" || !f.nested_object_name
        );
        const formattedBeneficialOwner = applySchema(beneficialOwner, filteredContactSchema);

        formattedBeneficialOwner.user_id = this.contact.user_id;
        formattedBeneficialOwner.account_id = this.account._id;
        formattedBeneficialOwner.contact_id = this.contact._id;
        // Combine existing entity_ids with the current active entity account's ID and remove duplicates
        const existingEntityIds = beneficialOwner.entity_ids || [];
        formattedBeneficialOwner.entity_ids = Array.from(
          new Set([...existingEntityIds, this.active_entity_account._id])
        );
        formattedBeneficialOwner.is_beneficial_owner = true;

        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };

        if (beneficialOwner._id) {
          // Update existing joint contact
          const beneficial_owner_query = { _id: beneficialOwner._id };
          await crudStore.updateOne("Contacts", beneficial_owner_query, {
            $set: { ...formattedBeneficialOwner, ...updatedTimestamp },
          });
          // find contact in allContacts and update their entity_ids
          const contactIndex = this.allContacts.findIndex(
            (contact) => contact._id.toString() === beneficialOwner._id.toString()
          );
          if (contactIndex > -1) {
            this.allContacts[contactIndex].entity_ids = formattedBeneficialOwner.entity_ids;
          }
        } else {
          // Insert new joint contact
          const newBeneficialOwnerContact = {
            ...formattedBeneficialOwner,
            entity_ids: [ObjectId(this.active_entity_account._id)],
            created_date: new Date(),
            created_by_id: authStore.currentUser.id,
            created_by_name: `${this.contact.first_name} ${this.contact.last_name}`,
            ...updatedTimestamp,
          };
          delete newBeneficialOwnerContact.is_new;
          const { insertedId } = await crudStore.insertOne("Contacts", newBeneficialOwnerContact);
          newBeneficialOwnerContact._id = insertedId;
          const [parsedBeneficialOwner] = parseDocumentsFieldsToProperType([
            newBeneficialOwnerContact,
          ]);
          this.allContacts.push(parsedBeneficialOwner);
          this.beneficial_owner_contacts.push(parsedBeneficialOwner);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateBeneficialOwnerEntityIds(beneficialOwner, entityAccount) {
      const crudStore = useCrudStore();
      try {
        const beneficial_owner_query = { _id: ObjectId(beneficialOwner._id) };
        const newEntityIds = beneficialOwner.entity_ids.filter(
          (id) => id.toString() !== entityAccount._id?.toString()
        );
        await crudStore.updateOne("Contacts", beneficial_owner_query, {
          $set: { entity_ids: newEntityIds },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async updateContactSuitabilityInfo() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const schemaStore = useSchemaStore();
      try {
        const contactSchema = schemaStore.getSchemaByCollection("Contacts");
        const filteredContactSchema = contactSchema.filter(
          (f) => f.nested_object_name === "" || !f.nested_object_name
        );
        const formattedContact = applySchema(this.contact, filteredContactSchema);
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };
        const query = { user_id: authStore.currentUser.id, type: "Regular" };
        const update = {
          $set: { suitability_info: formattedContact.suitability_info, ...updatedTimestamp },
        };
        await crudStore.updateOne("Contacts", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async deleteBeneficialOwner(beneficialOwner) {
      const crudStore = useCrudStore();

      try {
        const contact_query = { _id: ObjectId(beneficialOwner._id) };
        crudStore.deleteOne("Contacts", contact_query);
      } catch (err) {
        console.error("Error deleting beneficial owner or updating account:", err);
      }
    },
    async fetchGeneralDocuments() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();

      try {
        const documentPipeline = [
          {
            $match: {
              user_id: this.contact.user_id,
              type: { $in: ["General Documents", "Accreditation"] },
            },
          },
          { $sort: { [UIStore.sortHeader.field_name]: UIStore.sortAscending ? 1 : -1 } },
        ];
        this.general_documents = await crudStore.aggregate("Documents", documentPipeline);
      } catch (err) {
        console.error(err);
      }
    },
    async updateGeneralDocument(document) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();

      try {
        const updatedTimestamp = {
          updated_date: new Date(),
          updated_by_id: authStore.currentUser.id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
        };
        const query = { _id: ObjectId(document._id) };
        const update = { $set: { ...document, ...updatedTimestamp } };
        await crudStore.updateOne("Documents", query, update);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchBankAccounts(account_id) {
      const crudStore = useCrudStore();
      try {
        const bankAccounts = await crudStore.find("BankAccounts", { account_id: account_id });
        this.bank_accounts = bankAccounts;
      } catch (err) {
        console.error(err);
      }
    },
    async createNewInvestor() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;

      let synced_fields = [
        "email",
        "country",
        "street1",
        "street2",
        "state",
        "zip_code",
        "city",
        "phone",
      ];
      let existing_investor = await crudStore.findOne("Contacts", {
        email: this.new_investor.email,
      });
      if (!existing_investor) {
        //GET ACCOUNT TEMPLATE READY
        let account_to_insert = _.cloneDeep(schemaStore.createOwnerAccountTemplate());

        //INSERT THE REALM USER
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/register-new-aplus-user`,
          {
            method: "POST",
            headers: {
              user_id: currentUser.id,
              session_id: currentUser.session_id,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: this.new_investor.email,
            }),
          }
        );
        if (!response.ok) {
          console.log("Error creating new investor", response);
        } else {
          //ADD REALM USER ID TO THE NEW INVESTOR CONTACTS USER_ID AND THE NEW INVESTORS ACCOUNT USER_ID
          let res = await response.json();

          this.new_investor.user_id = res.id;
          account_to_insert.user_id = res.id;

          //ANY FIELD THAT IS IN THE SYNCED FIELDS ARRAY WILL BE SYNCED TO THE ACCOUNT FROM THE CONTACT

          for (let field of synced_fields) {
            account_to_insert[field] = this.new_investor[field];
          }

          // CLEAN UP THE ACCOUNT THEN INSERT IT
          delete account_to_insert._id;
          account_to_insert.name = `${this.new_investor.first_name} ${this.new_investor.last_name}`;
          account_to_insert = schemaStore.update_password_fields("Accounts", account_to_insert);
          account_to_insert = schemaStore.sortObjectProperties(account_to_insert);

          let inserted_account_result = await crudStore.insertOne("Accounts", account_to_insert);
          let inserted_account_id = inserted_account_result.insertedId;

          //CLEAN UP THE CONTACT AND ADD THE NEWLY INSERTED ACCOUNT ID THEN INSERT IT
          delete this.new_investor._id;
          this.new_investor.account_id = inserted_account_id;
          this.new_investor = schemaStore.update_password_fields("Contacts", this.new_investor);
          this.new_investor = schemaStore.sortObjectProperties(this.new_investor);
          let inserted_contact_result = await crudStore.insertOne("Contacts", this.new_investor);
          let inserted_contact_id = inserted_contact_result.insertedId;

          // console.log({
          //   inserted_account_result: inserted_account_result,
          //   inserted_contact_result: inserted_contact_result,
          //   inserted_account_id: inserted_account_id,
          //   inserted_contact_id: inserted_contact_id,
          // });

          return inserted_contact_id;
        }
      }
    },
    async handleForceArkSync() {
      const UIStore = useUIStore();
      const authStore = useAuthStore();
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/force-ark-sync`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              user_id: authStore.currentUser.id,
              session_id: authStore.currentUser.session_id,
            },
            body: JSON.stringify({ owner_contact_id: this.contact._id }),
          }
        );
        if (!response.ok) {
          throw new Error("Error sending password reset email");
        }
        UIStore.animateNotificationAlert({
          message: `Ark sync started for ${this.contact.first_name} ${this.contact.last_name}`,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async handleSendPasswordReset() {
      const UIStore = useUIStore();
      const authStore = useAuthStore();
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/request-password-reset-for-aplus-user`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              user_id: authStore.currentUser.id,
              session_id: authStore.currentUser.session_id,
            },
            body: JSON.stringify({ email: this.contact.email }),
          }
        );
        if (!response.ok) {
          throw new Error("Error sending password reset email");
        }
        UIStore.animateNotificationAlert({ message: "Password reset email sent" });
      } catch (err) {
        console.error(err);
      }
    },
    async handleChangeOwnerContactEmail(new_email) {
      const UIStore = useUIStore();
      const authStore = useAuthStore();
      const recordDetailStore = useRecordDetailStore();
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/change-user-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              user_id: authStore.currentUser.id,
              session_id: authStore.currentUser.session_id,
            },
            body: JSON.stringify({ new_email: new_email, owner_user_id: this.contact.user_id }),
          }
        );
        if (!response.ok) {
          throw new Error("Error changing owner email");
        }
        this.contact.email = new_email;
        recordDetailStore.currentPageDocument.email = new_email;
        UIStore.animateNotificationAlert({ message: "Owner Email Successfully Changed!" });
      } catch (err) {
        console.error(err);
      }
    },
    async handleToggleInvestorDisabled() {
      const UIStore = useUIStore();
      const crudStore = useCrudStore();
      try {
        const isDisabled = this.contact.is_user_disabled;
        await crudStore.updateOne(
          "Contacts",
          { _id: this.contact._id, type: "Regular" },
          { $set: { is_user_disabled: isDisabled ? false : true } }
        );
        UIStore.animateNotificationAlert({
          message: `Successfully ${isDisabled ? "Enabled" : "Disabled"} User`,
        });
        this.contact.is_user_disabled = isDisabled ? false : true;
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
  },
});
