<template>
  <div>
    <div class="py-2 text-xl font-bold">Select Amounts for Converted Investments</div>
    <div class="mt-2 flex items-center gap-4">
      <p class="py-2 text-xl font-bold">Total Amount Available for New Investment(s):</p>
      <p
        class="text-xl font-bold"
        :class="
          investmentStore.totalAvailableAmountForConvertedInvestments >= 0
            ? 'text-functional-success-default'
            : 'text-functional-error-default'
        "
      >
        ${{ commaSeparateThousands_2(investmentStore.totalAvailableAmountForConvertedInvestments) }}
      </p>
    </div>
    <div>
      <p class="py-2 text-lg text-lighter">How would you like to split up this investment?:</p>
      <div
        v-for="(investment, index) in investmentStore.oneToManyInvestmentList"
        class="flex items-center gap-4"
      >
        <BaseInput
          v-model="investment.amount"
          placeholder="0.00"
          @blur="roundInvestmentAmount(index)"
          :label="`Investment ${index + 1} Amount`"
          size="large"
          maxLength="8"
          formatRule="amount"
          required
          class="w-full md:w-80 mb-4"
        />
        <Button
          v-if="index === 0"
          @click="investmentStore.oneToManyInvestmentList.push({ amount: 0, index: index })"
          icon
          size="large"
          variant="default-gray-outlined"
          class="mt-2.5"
        >
          <template #icon>
            <Icon name="Plus" class="h-6 w-6 text-neutral-gray-6" />
          </template>
        </Button>
        <Button
          v-else
          @click="investmentStore.oneToManyInvestmentList.splice(index, 1)"
          icon
          size="large"
          variant="default-gray-outlined"
          class="mt-2.5"
        >
          <template #icon>
            <Icon name="Trash" class="h-5 w-5 text-functional-error-default" />
          </template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";

import { useInvestmentStore } from "@/stores";
import { BaseInput, Icon, Button } from "@/components";
import { commaSeparateThousands_2 } from "@/utilities";

const investmentStore = useInvestmentStore();

function roundInvestmentAmount(index) {
  let amount = investmentStore.oneToManyInvestmentList[index].amount;
  const numberAmount = Number(amount.replace(/,/g, ""));
  const roundedAmount = Math.round(numberAmount / 1000) * 1000;
  const formattedAmount = commaSeparateThousands_2(roundedAmount.toString());
  investmentStore.oneToManyInvestmentList[index].amount = formattedAmount;
}

// Computed property to calculate the total of all entered amounts
const totalAllocatedAmount = computed(() => {
  return investmentStore.oneToManyInvestmentList.reduce((acc, investment) => {
    const cleanAmount = investment.amount ? investment.amount.replace(/,/g, "") : "0";
    const amount = isNaN(Number(cleanAmount)) ? 0 : Number(cleanAmount);
    return acc + amount;
  }, 0);
});

// Computed property to check if the total allocated amount exceeds the available amount
const isAmountExceeded = computed(() => {
  const totalNewInvestmentsAmount = investmentStore.oneToManyInvestmentList.reduce(
    (acc, investment) => {
      const cleanAmount = investment.amount ? investment.amount.replace(/,/g, "") : "0";
      const amount = isNaN(Number(cleanAmount)) ? 0 : Number(cleanAmount);
      return acc + amount;
    },
    0
  );
  console.log(
    totalNewInvestmentsAmount,
    investmentStore.totalAvailableAmountForConvertedInvestments
  );
  return totalNewInvestmentsAmount > investmentStore.totalAvailableAmountForConvertedInvestments;
});
</script>
