<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ titleTransferStore.currentStep + 1 }}: Joint Contact Details
    </div>
  </div>
  <div class="flex flex-col">
    <div
      v-for="(group, index) in jointInfo"
      :key="index"
      :class="{
        'py-8 border-b border-platform-outlines': index !== jointInfo.length - 1,
        'py-8': index === jointInfo.length - 1,
      }"
    >
      <div class="font-semibold text-lg mb-4">
        {{ group.group_name }}
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="field of group.fields">
          <BaseInput
            :required="field.is_required"
            :disabled="
              field.read_only ||
              (titleTransferStore.joint_contact_template.has_existing_investments &&
                schemaStore.admin_disabled_joint_contact_fields.includes(field.field_name))
            "
            :label="field.label"
            v-if="!field.is_association_field && !field.belongs_to_nested_object"
            v-model="titleTransferStore.joint_contact_template[field.field_name]"
            :formatRule="field.field_name"
            :errorMessage="field.error_message"
            :type="field.field_type"
            :options="field.dropdown_options"
            size="large"
            customClass="bg-neutral-gray-1"
          ></BaseInput>
          <BaseInput
            :required="field.is_required"
            :disabled="
              field.read_only ||
              (titleTransferStore.joint_contact_template.has_existing_investments &&
                schemaStore.admin_disabled_joint_contact_fields.includes(field.field_name))
            "
            :formatRule="field.field_name"
            :errorMessage="field.error_message"
            :label="field.label"
            v-if="!field.is_association_field && field.belongs_to_nested_object"
            v-model="
              titleTransferStore.joint_contact_template[field.nested_object_name][field.field_name]
            "
            :options="field.dropdown_options"
            :type="field.field_type"
            size="large"
            customClass="bg-neutral-gray-1"
          ></BaseInput>
          <div
            v-if="field.field_name === 'ssn' && ssn_is_invalid"
            class="text-functional-error-default text-sm my-1"
          >
            Invalid SSN. SSN can not match the SSN of the owner or the SSN of any other Joint
            contacts or Beneficial Owner Contacts tied to this owner
          </div>
          <div
            v-if="field.field_name === 'email' && email_is_invalid"
            class="text-functional-error-default text-sm my-1"
          >
            Invalid Email. Email can not match the email of the owner or the email of any other
            Joint contacts or Beneficial Owner Contacts tied to this owner
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { BaseInput } from "@/components";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();
const jointInfo = ref(
  titleTransferStore.current_offering.type === "Reg D"
    ? schemaStore.accredited_joint_contact_personal_info_schema
    : schemaStore.non_accredited_joint_contact_personal_info_schema
);
const ssn_is_invalid = computed(() => {
  const jointContactTemplate = titleTransferStore.joint_contact_template;
  const existingContacts = titleTransferStore.existing_joint_contacts;
  const invalidSSNs = titleTransferStore.invalid_ssn_array;

  const matchingContact = existingContacts.find(
    (contact) => contact._id === jointContactTemplate._id
  );
  let val;
  if (matchingContact) {
    val =
      jointContactTemplate.ssn !== matchingContact.ssn &&
      invalidSSNs.includes(jointContactTemplate.ssn);
  } else {
    val = invalidSSNs.includes(jointContactTemplate.ssn);
  }
  titleTransferStore.joint_ssn_is_invalid = val;
  return val;
});
const email_is_invalid = computed(() => {
  const jointContactTemplate = titleTransferStore.joint_contact_template;
  const existingContacts = titleTransferStore.existing_joint_contacts;
  const invalidEmails = titleTransferStore.invalid_email_array;

  const matchingContact = existingContacts.find(
    (contact) => contact._id === jointContactTemplate._id
  );
  let val;
  if (matchingContact) {
    val =
      jointContactTemplate.email !== matchingContact.email &&
      invalidEmails.includes(jointContactTemplate.email);
  } else {
    val = invalidEmails.includes(jointContactTemplate.email);
  }
  titleTransferStore.joint_email_is_invalid = val;
  return val;
});

onMounted(async () => {
  window.scrollTo(0, 0);
});
</script>
