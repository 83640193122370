<template>
  <div>
    <div class="flex justify-between items-center">
      <h2 v-if="header" class="font-bold" :class="isSmall ? 'text-base' : 'text-xl'">
        {{ header }}
      </h2>
      <div v-if="fromProfile && !authStore.isDalmoreAdmin">
        <Button
          v-if="isDisabled"
          @click="$emit('toggle-edit-mode')"
          label
          icon
          variant="default-gray-outlined"
        >
          <template #icon>
            <Icon name="Pencil" :stroke-width="2" class="text-primary-6 h-4 w-4" />
          </template>
          <template #label>Edit</template>
        </Button>
        <div v-else class="flex items-center gap-2">
          <Button @click="$emit('toggle-edit-mode')" label variant="default-gray-outlined">
            <template #label>Cancel</template>
          </Button>
          <Button @click="$emit('handle-save')" :disabled="!isFormValid" label icon>
            <template #icon>
              <Icon name="Save" :stroke-width="2" class="text-white h-4 w-4" />
            </template>
            <template #label>Save</template>
          </Button>
        </div>
      </div>
    </div>
    <p v-if="subHeader" class="text-lighter hidden md:block">{{ subHeader }}</p>
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4" :class="isSmall ? 'mt-3' : 'mt-6'">
      <div v-for="field in fields" :key="field.field_name">
        <div v-if="!field.belongs_to_nested_object">
          <BaseInput
            :type="getFieldType(field)"
            :label="field.label"
            :placeholder="`${field.label}`"
            v-model="record[field.field_name]"
            size="large"
            :options="field.field_name === 'state' ? ALL_50_STATES : field?.dropdown_options"
            :required="field.is_required"
            :disabled="isInputDisabled(field)"
            :maxLength="
              field.field_name === 'ssn' ? '11' : field.field_name === 'ein' ? '10' : null
            "
            :formatRule="field.field_name"
            :errorMessage="getErrorMessage(field)"
            :formType="type"
          />
        </div>
        <div v-else class="relative">
          <BaseInput
            :type="getFieldType(field)"
            :label="field.label"
            :placeholder="`${field.label}`"
            v-model="record[field.nested_object_name][field.field_name]"
            size="large"
            :options="field.field_name === 'state' ? ALL_50_STATES : field.dropdown_options"
            :required="field.is_required"
            :disabled="isInputDisabled(field)"
            :maxLength="
              field.field_name === 'ssn' ? '11' : field.field_name === 'ein' ? '10' : null
            "
            :formatRule="field.field_name"
            :errorMessage="getErrorMessage(field)"
            :formType="type"
          />
          <Icon
            v-if="fieldsThatNeedInfoModals.includes(field.field_name)"
            @click="toggleInfoModal(field.field_name)"
            name="CircleHelp"
            class="circle-help text-lighter h-4 w-4 absolute right-0 top-0 cursor-pointer"
          />
          <div
            v-if="openInfo[field.field_name]"
            class="bg-neutral-gray-4 rounded text-sm text-lighter p-3 flex absolute top-0 right-6 animate-slideup z-50 open-info shadow-lg max-w-96"
          >
            <div class="relative">
              <p class="w-[95%]">{{ modalInfo[field.field_name] }}</p>
              <Icon
                name="X"
                class="text-lighter h-3.5 w-3.5 absolute right-0 top-0 cursor-pointer"
                @click="toggleInfoModal(field.field_name)"
              />
            </div>
          </div>
        </div>

        <!-- Special Validation Fields -->
        <div
          v-if="field.field_name === 'ssn' && ssn_is_invalid"
          class="text-functional-error-default text-sm my-1"
        >
          Invalid SSN. SSN can not match the SSN of the owner or the SSN of any other Joint contacts
          or Beneficial Owner Contacts tied to this owner
        </div>
        <div
          v-if="field.field_name === 'email' && email_is_invalid"
          class="text-functional-error-default text-sm my-1"
        >
          Invalid Email. Email can not match the email of the owner or the email of any other Joint
          contacts or Beneficial Owner Contacts tied to this owner
        </div>
        <div
          v-if="field.field_name === 'id_number' && id_number_is_invalid"
          class="text-functional-error-default text-sm my-1"
        >
          Invalid ID Number. ID Number can not match the ID Number of the owner or the ID Number of
          any other Joint contacts or Beneficial Owner Contacts tied to this owner
        </div>
        <div
          v-if="field.field_name === 'ein' && ein_is_invalid"
          class="text-functional-error-default text-sm my-1"
        >
          Invalid EIN. EIN can not match the EIN of any other entities tied to this account.
        </div>
        <div
          v-if="field.field_name === 'current_income' && current_income_is_invalid"
          class="text-functional-error-default text-sm my-1"
        >
          Current Income must be at least $10,000
        </div>
        <div
          v-if="
            field.field_name === 'current_liquid_net_worth' && current_liquid_net_worth_is_invalid
          "
          class="text-functional-error-default text-sm my-1"
        >
          Liquid Net Worth cannot be more than Net Worth
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, watch, onMounted, onUnmounted } from "vue";

import { useInvestmentStore, useInvestorStore, useSchemaStore, useAuthStore } from "@/stores";
import { BaseInput, Button, Icon } from "@/components";
import { ALL_50_STATES } from "@/constants";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const schemaStore = useSchemaStore();
const authStore = useAuthStore();

const props = defineProps({
  fields: {
    type: Array,
    required: true,
    default: () => [],
  },
  record: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  header: {
    type: String,
    required: true,
    default: "",
  },
  subHeader: {
    type: String,
    default: "",
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "",
  },
  fromProfile: {
    type: Boolean,
    default: false,
  },
  isFormValid: {
    type: Boolean,
    default: false,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["toggle-edit-mode", "handle-save"]);

onMounted(() => {
  document.addEventListener("click", closeInfoModals);
});

onUnmounted(() => {
  document.removeEventListener("click", closeInfoModals);
});

const fieldsThatNeedInfoModals = [
  "current_net_worth",
  "current_liquid_net_worth",
  "current_income",
];
const modalInfo = {
  current_net_worth:
    "Measured by your assets (checking accounts, savings accounts, retirement savings, real estate, autos, other physical assets, paper asset or business assets) minus your liabilities (consumer debt, loans, mortgages, other debt).",
  current_liquid_net_worth:
    "Assets measured by available money that is not locked up in long-term investments or physical objects. These can include checking accounts, savings accounts, money market accounts, certificates of deposit, stocks, ETFs, mutual funds, and bonds. Liquid Net Worth is the combination of the above minus your liabilities (consumer debt, loans, mortgages, other debt).",
  current_income:
    "Sources of personal income include money earned from employment, dividends and distributions paid by investments, rents derived from property ownership, and profit sharing from businesses.",
};

const openInfo = reactive({
  current_net_worth: false,
  current_liquid_net_worth: false,
  current_income: false,
});

function toggleInfoModal(fieldName) {
  for (const field in openInfo) {
    if (field !== fieldName) {
      openInfo[field] = false;
    }
  }
  openInfo[fieldName] = !openInfo[fieldName];
}

function closeInfoModals(event) {
  if (!event.target.closest(".open-info") && !event.target.closest(".circle-help")) {
    for (const field in openInfo) {
      openInfo[field] = false;
    }
  }
}

const ssn_is_invalid = computed(() => {
  let ssnsToCheck = [];

  // If it's a new entity, check against tempBeneficialOwners as well
  const contactsToCheck = !investorStore.active_entity_account?._id
    ? [...investorStore.allContacts, ...investorStore.tempBeneficialOwners]
    : investorStore.allContacts;

  switch (props.type) {
    case "personal":
    case "joint":
    case "beneficial owner":
      ssnsToCheck = contactsToCheck
        .filter(
          (contact) =>
            contact.ssn &&
            (!contact._id || contact._id?.toString() !== props.record?._id?.toString())
        )
        .map((contact) => contact.ssn);
      return ssnsToCheck.includes(props.record.ssn);
    default:
      return false;
  }
});

const ein_is_invalid = computed(() => {
  if (props.type !== "entity") {
    return false;
  }

  let einsToCheck = investorStore.allAccounts
    .filter(
      (account) =>
        account._id &&
        account.type === "Entity" &&
        account.ein &&
        account._id.toString() !== props.record?._id?.toString()
    )
    .map((account) => account.ein);
  return einsToCheck.includes(props.record.ein);
});

const email_is_invalid = computed(() => {
  let emailsToCheck = [];

  // If it's a new entity, check against tempBeneficialOwners as well
  const contactsToCheck = !investorStore.active_entity_account?._id
    ? [...investorStore.allContacts, ...investorStore.tempBeneficialOwners]
    : investorStore.allContacts;

  switch (props.type) {
    case "personal":
    case "joint":
    case "beneficial owner":
      emailsToCheck = contactsToCheck
        .filter(
          (contact) =>
            contact.email &&
            (!contact._id || contact._id?.toString() !== props.record?._id?.toString())
        )
        .map((contact) => contact.email);
      return emailsToCheck.includes(props.record.email);
    default:
      return false;
  }
});

const id_number_is_invalid = computed(() => {
  let idNumbersToCheck = [];

  // If it's a new entity, check against tempBeneficialOwners as well
  const contactsToCheck = !investorStore.active_entity_account?._id
    ? [...investorStore.allContacts, ...investorStore.tempBeneficialOwners]
    : investorStore.allContacts;

  switch (props.type) {
    case "personal":
    case "joint":
    case "beneficial owner":
      idNumbersToCheck = contactsToCheck
        .filter(
          (contact) =>
            contact?.id_info?.id_number &&
            (!contact._id || contact._id?.toString() !== props.record?._id?.toString())
        )
        .map((contact) => contact.id_info.id_number);
      return idNumbersToCheck.includes(props.record?.id_info?.id_number);
    default:
      return false;
  }
});

const current_income_is_invalid = computed(() => {
  if (props.type !== "personal") {
    return false;
  }
  let currentIncome = props.record["suitability_info"]?.current_income;
  if (!currentIncome) {
    return true;
  }

  if (typeof currentIncome === "string") {
    return parseFloat(currentIncome.replace(/,/g, "")) < 10000;
  } else if (typeof currentIncome === "number") {
    return currentIncome < 10000;
  }

  return false;
});

const current_liquid_net_worth_is_invalid = computed(() => {
  if (props.type !== "personal") {
    return false;
  }
  let currentLiquidNetWorth = props.record["suitability_info"]?.current_liquid_net_worth;
  let currentNetWorth = props.record["suitability_info"]?.current_net_worth;
  if (!currentLiquidNetWorth || !currentNetWorth) {
    return true;
  }

  if (typeof currentLiquidNetWorth === "string") {
    currentLiquidNetWorth = parseFloat(currentLiquidNetWorth.replace(/,/g, ""));
  }
  if (typeof currentNetWorth === "string") {
    currentNetWorth = parseFloat(currentNetWorth.replace(/,/g, ""));
  }

  if (typeof currentLiquidNetWorth === "number" && typeof currentNetWorth === "number") {
    return currentLiquidNetWorth > currentNetWorth;
  }

  return false;
});

watch(
  () => [
    ssn_is_invalid.value,
    email_is_invalid.value,
    id_number_is_invalid.value,
    ein_is_invalid.value,
    current_income_is_invalid.value,
    current_liquid_net_worth_is_invalid.value,
  ],
  ([ssn, email, id_number, ein, current_income, current_liquid_net_worth]) => {
    if (ssn) {
      investorStore.hasSpecialError = "ssn";
    } else if (email) {
      investorStore.hasSpecialError = "email";
    } else if (id_number) {
      investorStore.hasSpecialError = "id_number";
    } else if (ein) {
      investorStore.hasSpecialError = "ein";
    } else if (current_income) {
      investorStore.hasSpecialError = "current_income";
    } else if (current_liquid_net_worth) {
      investorStore.hasSpecialError = "current_liquid_net_worth";
    } else {
      investorStore.hasSpecialError = ""; // No special error if all are valid
    }
  }
);

function isInputDisabled(field) {
  if (props.isDisabled || field.read_only || field.field_name === "country") {
    return true;
  }

  if (props.isNew) {
    return false;
  }

  switch (props.type) {
    case "personal":
      return (
        investmentStore.hasSettledInvestments &&
        schemaStore.admin_disabled_owner_contact_fields.includes(field.field_name)
      );

    case "joint":
      // Check if there are any investments with a joint_contact_id that matches props.record._id
      const jointHasSignedSettledInvestments = investmentStore.transactionsData.some(
        (investment) =>
          investment.joint_contact_id?.toString() === props.record._id?.toString() &&
          investment.status === "Settled"
      );
      return (
        jointHasSignedSettledInvestments &&
        schemaStore.admin_disabled_joint_contact_fields.includes(field.field_name)
      );

    case "entity":
      // Check if there are any investments with an entity_account_id that matches props.record._id
      const entityHasSignedSettledInvestments = investmentStore.transactionsData.some(
        (investment) =>
          investment.entity_account_id?.toString() === props.record._id?.toString() &&
          investment.status === "Settled"
      );
      return (
        entityHasSignedSettledInvestments &&
        schemaStore.admin_disabled_entity_account_fields.includes(field.field_name)
      );

    case "beneficial owner":
      // Check if any of the ids in entity_ids match an entity_account_id on a signed investment
      const beneficialOwnerHasSignedSettledInvestments = props.record?.entity_ids?.some(
        (entityId) =>
          investmentStore.transactionsData.some(
            (investment) =>
              investment.entity_account_id?.toString() === entityId.toString() &&
              investment.status === "Settled"
          )
      );
      return (
        beneficialOwnerHasSignedSettledInvestments &&
        schemaStore.admin_disabled_beneficial_owner_contact_fields.includes(field.field_name)
      );

    default:
      return false;
  }
}

function getErrorMessage(field) {
  if (field.field_name === "date_of_birth") {
    const dob = new Date(props.record.date_of_birth);
    const today = new Date();

    // Calculate age
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    const dayDifference = today.getDate() - dob.getDate();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    // If the age is less than 18, return the specific error message
    if (age < 18) {
      return "You must be at least 18 years old to invest.";
    }
  }

  // Default error message for other fields
  return `Please enter a valid ${field.label}`;
}

function getFieldType(field) {
  if (field.number_type === "currency") {
    return "text";
  }

  if (field.field_type === "dropdown" || field.field_name === "state") {
    return "select";
  }

  if (field.field_type === "string") {
    return "text";
  }

  return field.field_type;
}
</script>
