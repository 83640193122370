<template>
  <div
    v-if="investorStore.contact && schemaToUse.length > 0"
    class="w-full flex flex-col justify-between h-full"
  >
    <template v-for="(schema, index) of schemaToUse" :key="index">
      <Form
        :fields="schema.fields"
        :record="investorStore.contact"
        :header="schema.group_name"
        :subHeader="
          index === 1
            ? 'Your physical address. PO Boxes and mail drops are not valid.'
            : index === 2
              ? 'Your passport or government issued ID (driver\'s license).'
              : ''
        "
        type="personal"
        :isDisabled="!editModeState[index]"
        :isFormValid="isFormValid"
        @toggleEditMode="toggleEditMode(index)"
        @handleSave="handleSave(index)"
        fromProfile
        isSmall
      />
      <div
        v-if="index !== schemaToUse.length - 1"
        class="border-b border-neutral-gray-4 w-full my-4 md:my-8"
      ></div>
    </template>
  </div>
  <div v-else>
    <LoadingSpinner :show="true" hideOverlay />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { cloneDeep } from "lodash";

import { useInvestmentStore, useInvestorStore, useSchemaStore } from "@/stores";
import { Form, LoadingSpinner } from "@/components";
import { getObjectDifferences, validateField } from "@/utilities";

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const schemaStore = useSchemaStore();

const original_owner_contact = ref({});
const editModeState = ref({});

onMounted(async () => {
  window.scrollTo(0, 0);
});

const schemaToUse = computed(() => {
  return investmentStore.hasAccreditedInvestments
    ? schemaStore.accredited_owner_contact_personal_info_schema
    : schemaStore.non_accredited_owner_contact_personal_info_schema;
});

const isFormValid = computed(() => {
  if (!schemaToUse.value || schemaToUse.value.length === 0) {
    return false;
  }

  const fieldsToValidate = schemaToUse.value.flatMap((schema) =>
    schema.fields.filter((f) => f.is_required)
  );

  if (investorStore.hasSpecialError) {
    return false;
  }

  const isValid = fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? investorStore.contact[field.nested_object_name]?.[field.field_name]
        : investorStore.contact[field.field_name];

    return validateField(field.field_name, value);
  });

  return isValid;
});

const hasAnyValueChanged = computed(() => {
  const differences = getObjectDifferences(investorStore.contact, original_owner_contact.value);

  if (Object.keys(differences).length > 0) {
    console.log("Differences:", differences);
    return true;
  }

  return false;
});

watch(
  () => investorStore.contact,
  () => {
    original_owner_contact.value = cloneDeep(investorStore.contact);
  },
  { immediate: true }
);

watch(
  () => investorStore.active_profile_tab,
  () => {
    editModeState.value = {};
    investorStore.supressErrors = true;
    investorStore.contact = cloneDeep(original_owner_contact.value);
  }
);

function toggleEditMode(index) {
  editModeState.value[index] = !editModeState.value[index];
  if (editModeState.value[index] === false) {
    investorStore.supressErrors = true;
    investorStore.contact = cloneDeep(original_owner_contact.value);
  } else {
    investorStore.supressErrors = false;
  }
}

async function handleSave(index) {
  editModeState.value[index] = false;
  if (hasAnyValueChanged.value) {
    original_owner_contact.value = cloneDeep(investorStore.contact);
    await investorStore.updateContactAndAccount();
  }
}
</script>
