<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" @close-modal="$emit('close-modal')" size="small-narrow">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="flex flex-row items-center">
          <div
            class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-info-click bg-opacity-20 p-1"
          >
            <IconSetting class="h-5 w-5 fill-functional-info-click" />
          </div>

          <div class="font-bold text-custom-navy-2">Manage List</div>
        </div>
      </template>
      <template v-slot:modal-tabs>
        <div class="no-scrollbar ml-4 flex overflow-x-scroll">
          <!-- Render tabs -->
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            @click="activeTab = tab"
            :class="
              activeTab === tab
                ? ' border-secondary-6 bg-secondary-6 bg-opacity-20 font-bold'
                : 'border-transparent'
            "
            class="flex flex-grow-0 cursor-pointer flex-row items-center justify-center whitespace-nowrap border-b-3 p-3"
          >
            <div
              class="flex h-5 flex-row items-center gap-2 text-left text-base text-primary-6"
              :class="activeTab === tab ? 'text-primary-6' : 'text-dark'"
            >
              {{ tab }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-body>
        <!-- Display content based on the active tab -->

        <div
          v-if="activeTab === 'General Settings'"
          class="no-scrollbar flex flex-col h-80 overflow-y-auto bg-neutral-gray-2 p-4 gap-y-4"
        >
          <BaseInput
            required
            label="Card Name"
            v-model="dataModelManagementStore.list_being_edited.card_name"
            type="text"
            size="large"
          ></BaseInput>
          <BaseInput
            required
            label="Reference Name"
            v-model="dataModelManagementStore.list_being_edited.reference"
            type="text"
            size="large"
          ></BaseInput>
          <BaseInput
            required
            label="Description"
            v-model="dataModelManagementStore.list_being_edited.description"
            type="text"
            size="large"
          ></BaseInput>
        </div>
        <div
          v-if="activeTab === 'List Items'"
          class="no-scrollbar h-80 overflow-y-auto bg-neutral-gray-2 p-4"
        >
          <Button
            variant="default-gray-outlined"
            icon
            label
            class="w-full"
            @click="addItem()"
            :disabled="inEditMode"
          >
            <template #icon><IconPlus class="fill-primary-6" /></template>
            <template #label
              ><p class="text-center text-sm text-primary-6">Add Item</p></template
            ></Button
          >
          <div class="mt-3 space-y-1">
            <div
              v-for="(item, index) in calculated_items"
              :key="index"
              class="flex min-h-9 items-center justify-between py-1"
            >
              <div class="flex flex-grow items-center">
                <div class="flex flex-row space-x-1">
                  <Button
                    v-if="currentItemIndex !== index"
                    @click="moveItem(item, 'up')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="item === calculated_items[0] || inEditMode"
                  >
                    <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
                  </Button>
                  <Button
                    v-if="currentItemIndex !== index"
                    @click="moveItem(item, 'down')"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                    :disabled="item === calculated_items[calculated_items.length - 1] || inEditMode"
                  >
                    <template #icon><IconExpandMore class="h-4 w-4" /></template>
                  </Button>
                  <Button
                    v-if="currentItemIndex === index"
                    @click="saveEdit(index, item)"
                    :disabled="currentItem === ''"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                  >
                    <template #icon
                      ><IconCheckmark class="h-4 w-4 fill-functional-success-default" />
                    </template>
                  </Button>
                  <Button
                    v-if="currentItemIndex === index"
                    @click="discardEdit(item)"
                    icon
                    variant="default-gray-outlined"
                    size="small"
                  >
                    <template #icon
                      ><IconX class="h-4 w-4 fill-functional-error-default"
                    /></template>
                  </Button>
                </div>
                <div v-if="currentItemIndex === index" class="flex flex-grow">
                  <BaseInput
                    v-model="currentItem"
                    type="text"
                    size="large"
                    class="ml-1.5 rounded-lg border border-primary-6"
                  />
                </div>

                <p v-else class="ml-3 text-sm text-dark">{{ item }}</p>
              </div>

              <div class="flex flex-row space-x-1">
                <Button
                  v-if="!inEditMode"
                  @click="editItem(index, item)"
                  icon
                  variant="default-gray-outlined"
                  size="small"
                >
                  <template #icon><IconEdit class="h-4 w-4" /></template>
                </Button>
                <Button
                  v-if="!inEditMode"
                  @click="deleteItem(item)"
                  icon
                  variant="default-gray-outlined"
                  size="small"
                >
                  <template #icon>
                    <Icon
                      name="Trash"
                      class="h-4.5 w-4.5 stroke-functional-error-default"
                      :stroke-width="2"
                  /></template>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="flex flex-row justify-end gap-1">
          <Button label variant="default-gray-outlined" size="large" @click="$emit('close-modal')">
            <template #label><p>Cancel</p></template></Button
          >
          <Button
            size="large"
            :disabled="
              inEditMode ||
              !dataModelManagementStore.list_being_edited.card_name ||
              !dataModelManagementStore.list_being_edited.reference ||
              !dataModelManagementStore.list_being_edited.description ||
              !calculated_items.length
            "
            label
            @click="updateItems(), $emit('close-modal')"
          >
            <template #label>Confirm</template></Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

import { BaseInput, Button, Modal } from "@/components";
import {
  IconExpandLess,
  IconExpandMore,
  IconPlus,
  IconDelete,
  IconEdit,
  IconX,
  IconCheckmark,
  IconSetting,
  IconSearch,
} from "@/components/icons";
import { Icon } from "@/components";

import { useDataModelManagementStore } from "@/stores/useDataModelManagementStore";

const dataModelManagementStore = useDataModelManagementStore();
//const activeTab = ref(null);
const currentItemIndex = ref(null);
const originalItem = ref("");
const inEditMode = ref(false);
const currentItem = ref("");
const calculated_items = ref([]);
const addingnew = ref(true);
const activeTab = ref("General Settings");
const tabs = ref(["General Settings", "List Items"]);

watch(
  () => dataModelManagementStore.list_being_edited?.options,
  () => {
    // Create a new array without empty strings
    const filteredItemOrder = dataModelManagementStore.list_being_edited.options.filter(
      (item) => item !== ""
    );

    // Set the calculated_items.value to the new filtered array
    calculated_items.value = filteredItemOrder;
  }
);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  modalData: {
    type: Array,
    required: true,
  },
});
function moveItem(item, direction) {
  let array = calculated_items.value;

  const index = array.indexOf(item);
  if (direction === "up" && index > 0) {
    // Ensure it's not the first item
    const itemToMove = array.splice(index, 1)[0];
    array.splice(index - 1, 0, itemToMove);
  } else if (direction === "down" && index < array.length - 1) {
    // Ensure it's not the last item
    const itemToMove = array.splice(index, 1)[0];
    array.splice(index + 1, 0, itemToMove);
  }
}

watch(
  () => props.open,
  async () => {
    if (!props.open) {
      await dataModelManagementStore.setLists();
      activeTab.value = "General Settings";
    }
  }
);

function deleteItem(item) {
  const index = calculated_items.value.indexOf(item);
  if (index > -1) {
    // Ensure the item exists
    calculated_items.value.splice(index, 1);
  }
}
function editItem(index, item) {
  currentItem.value = item;
  inEditMode.value = true;
  originalItem.value = item;
  currentItemIndex.value = index;
}
function discardEdit(index) {
  if (addingnew.value === true) {
    calculated_items.value.shift();
  }
  addingnew.value = false;
  calculated_items.value[index] = originalItem.value;

  currentItemIndex.value = -1;

  inEditMode.value = false;
  currentItem.value = "";
}
function saveEdit(index) {
  addingnew.value = false;
  calculated_items.value[index] = currentItem.value;
  inEditMode.value = false;
  currentItem.value = "";
  currentItemIndex.value = -1;
}
function addItem() {
  addingnew.value = true;
  calculated_items.value.unshift("");
  inEditMode.value = true;
  currentItemIndex.value = 0;
}
async function updateItems() {
  await dataModelManagementStore.updateListItems(calculated_items.value);
}
</script>
