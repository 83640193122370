<template>
  <AddBankModal
    @close-modal="addBankModalOpen = false"
    @account-updated="emit('account-updated')"
    :open="addBankModalOpen"
    :user_id="recordDetailStore.currentPageDocument.user_id"
  />
  <EditBankModal
    @close-modal="editBankModalOpen = false"
    @account-updated="emit('account-updated')"
    :open="editBankModalOpen"
    :account_type="account_type"
    :account="account_to_edit"
  />
  <DeleteBankModal
    @close-modal="deleteBankModalOpen = false"
    @account-updated="emit('account-updated')"
    @account-replaced="emit('account-replaced')"
    :open="deleteBankModalOpen"
    :account_type="account_type"
    :account="account_to_delete"
  />
  <SideDrawer
    @close-drawer="$emit('close-drawer')"
    :open="open"
    customWidth="lg:w-1/2"
    id="quick-ref-rel-list"
    :hideHeader="true"
    :showFooter="true"
  >
    <template v-slot:tabs>
      <div class="font-semibold">Change {{ account_type ? account_type : "Bank" }} Account</div>
    </template>

    <template v-slot:main-content>
      <div
        v-if="UIStore.isDrawerLoading"
        class="h-full w-full bg-white flex items-center justify-center"
      >
        <LoadingSpinner :hide-overlay="true" position="absolute" :show="true" />
      </div>
      <div class="flex flex-col items-center px-8 py-4">
        <Button
          class="mb-2 self-end"
          label
          icon
          variant="default-gray-outlined"
          @click="addBankModalOpen = true"
        >
          <template #icon>
            <Icon name="Plus" class="w-4" />
          </template>
          <template #label> Add ACH Account </template>
        </Button>
        <div
          class="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-neutral-gray-2 w-full"
          :class="investorStore.bank_accounts.length > 6 ? 'rounded-t-2xl' : 'rounded-2xl'"
        >
          <div v-for="(account, index) in bank_accounts">
            <!-- Check  -->
            <div v-if="account._id == 'check'" class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
              <div
                class="border rounded-xl bg-white p-4 flex flex-row items-center h-full grow cursor-pointer"
                :class="
                  selectedBankAccountId === account._id
                    ? 'border-secondary-6'
                    : 'border-platform-outlines'
                "
                @click="selectedBankAccountId = account._id"
              >
                <RadioOption
                  :id="'' + index"
                  :name="'Account' + index"
                  value="check"
                  v-model="selectedBankAccountId"
                  :label="account.financial_institution_name"
                  description=""
                  :account_num="account.account_number"
                  class="cursor-pointer z--10 pointer-events-none"
                />
                <div>
                  <Icon name="Banknote" class="w-12 text-primary-6 lg:invisible xl:visible" />
                </div>
              </div>
            </div>
            <!-- Wire -->
            <div v-else-if="account._id == 'wire'" class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
              <div
                class="border rounded-xl bg-white p-4 flex flex-row items-center h-full cursor-pointer"
                :class="
                  selectedBankAccountId === account._id
                    ? 'border-secondary-6'
                    : 'border-platform-outlines'
                "
                @click="selectedBankAccountId = account._id"
              >
                <RadioOption
                  :id="'' + index"
                  :name="'Account' + index"
                  value="wire"
                  v-model="selectedBankAccountId"
                  :label="account.financial_institution_name"
                  description=""
                  account_num=""
                  class="cursor-pointer z--10 pointer-events-none"
                />
                <div>
                  <Icon name="DollarSign" class="w-12 text-primary-6 lg:invisible xl:visible" />
                </div>
              </div>
            </div>
            <!-- Bank Accounts -->
            <div v-else class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
              <div
                class="border rounded-xl bg-white p-4 flex flex-row items-center h-full cursor-pointer"
                :class="[
                  selectedBankAccountId === account._id
                    ? 'border-secondary-6'
                    : 'border-platform-outlines',
                ]"
                @click="selectedBankAccountId = account._id"
              >
                <RadioOption
                  :id="'' + index"
                  :name="'Account' + index"
                  :value="account._id"
                  v-model="selectedBankAccountId"
                  :label="account.financial_institution_name"
                  :description="account.account_type"
                  :account_num="account.account_number"
                  class="cursor-pointer z--10"
                  :edit_cta="!account.plaid_account_id"
                  @edit-account="editAccount(account)"
                  :delete_cta="true"
                  @delete-account="deleteAccount(account)"
                />
                <div v-if="account.logo">
                  <img
                    class="w-12 lg:invisible xl:visible"
                    :src="'data:image/png;base64, ' + account.logo + ''"
                    alt="Red dot"
                  />
                </div>
                <div v-else>
                  <IconLandmark class="w-12 lg:invisible xl:visible" />
                </div>
              </div>
            </div>
          </div>
          <!-- <Button
          v-if="investorStore.bank_accounts.length > 6"
          size="large"
          label
          variant="default-gray-outlined"
          @click="showMore = !showMore"
          class="lg:col-span-2"
        >
          <template #label>
            <p>{{ showMore ? "Show Less" : "Show More" }}</p>
          </template>
        </Button> -->
        </div>
        <div
          v-if="
            investorStore.bank_accounts.length + (props.account_type === 'Funding' ? 2 : 1) >
            MAX_ACCOUNTS_TO_SHOW
          "
          class="text-light hover:underline cursor-pointer lg:col-span-2 bg-neutral-gray-2 rounded-b-2xl w-full p-1 flex justify-center"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? "Show Less"
              : `Show More (${investorStore.bank_accounts.length + (props.account_type === "Funding" ? 2 : 1) - MAX_ACCOUNTS_TO_SHOW})`
          }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-drawer')">
          <template #label><p>Cancel</p></template></Button
        >
        <Button size="large" :disabled="isSaveDisabled" label @click="updateInvestmentBankAccount">
          <template #label>Update</template></Button
        >
      </div>
    </template>
  </SideDrawer>
</template>
<script setup>
import {
  SideDrawer,
  RadioOption,
  Button,
  LoadingSpinner,
  Icon,
  EditBankModal,
  DeleteBankModal,
  AddBankModal,
} from "@/components";
import { computed, watch, ref } from "vue";
import {
  useInvestorStore,
  useUIStore,
  useCrudStore,
  useAuthStore,
  useRecordDetailStore,
} from "@/stores";
import { IconLandmark } from "@/components/icons";
// import EditBankModal from "@/pages/Investments/EditBankModal.vue";
// import DeleteBankModal from "@/pages/Investments/DeleteBankModal.vue";
// import AddBankModal from "@/pages/Investments/AddBankModal.vue";

const investorStore = useInvestorStore();
const UIStore = useUIStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();
const recordDetailStore = useRecordDetailStore();

const showMore = ref(false);
const MAX_ACCOUNTS_TO_SHOW = ref(6);

const addBankModalOpen = ref(false);
const editBankModalOpen = ref(false);
const account_to_edit = ref(null);
const deleteBankModalOpen = ref(false);
const account_to_delete = ref(null);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  account_type: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["close-drawer", "account-updated", "account-replaced"]);

const selectedBankAccountId = ref("null");

// created array of account options. Check, Wire, and Investor Bank Accounts
const bank_accounts = computed(() => {
  const check_option = {
    _id: "check",
    financial_institution_name: "Check",
    account_type: "check",
    account_number: "",
    logo: "",
  };

  const wire_option = {
    _id: "wire",
    financial_institution_name: "Wire",
    account_type: "wire",
    account_number: "",
    logo: "",
  };

  var bank_array = [];
  bank_array.push(check_option);

  if (props.account_type === "Funding") {
    bank_array.push(wire_option);
  }

  if (showMore.value) {
    bank_array.push(...investorStore.bank_accounts);
  } else {
    // return first 4 bank accounts (5 if we are only showing check)
    bank_array.push(
      ...investorStore.bank_accounts.slice(0, props.account_type === "Funding" ? 4 : 5)
    );
  }

  return bank_array;
});

const isSaveDisabled = computed(() => {
  return false;
});

// TODO: set payment_method and distrubution_method
async function updateInvestmentBankAccount() {
  UIStore.isDrawerLoading = true;
  let filter = {
    _id: recordDetailStore.currentPageDocument._id,
  };
  let update = {
    $set: {
      updated_date: new Date(),
      updated_by_id: authStore.currentUser.id,
      updated_by_name:
        authStore.currentUser.customData.first_name +
        " " +
        authStore.currentUser.customData.last_name,
    },
  };

  // set funding_account or distribution_account based on account_type
  if (props.account_type === "Funding") {
    if (selectedBankAccountId.value === "check") {
      update.$set["funding_account"] = null;
      update.$set["payment_method"] = "Check";
    } else if (selectedBankAccountId.value === "wire") {
      update.$set["funding_account"] = null;
      update.$set["payment_method"] = "Wire";
    } else {
      update.$set["funding_account"] = selectedBankAccountId.value;
      update.$set["payment_method"] = "ACH";
    }
  } else if (props.account_type === "Distribution") {
    if (selectedBankAccountId.value === "check") {
      update.$set["distribution_account"] = null;
      update.$set["distribution_method"] = "Check";
    } else if (selectedBankAccountId.value === "wire") {
      update.$set["distribution_account"] = null;
      update.$set["distribution_method"] = "Wire";
    } else {
      update.$set["distribution_account"] = selectedBankAccountId.value;
      update.$set["distribution_method"] = "ACH";
    }
  }

  // // ensure update.$set has funding_account or distribution_account
  // if (!update.$set["funding_account"] && !update.$set["distribution_account"]) {
  //   UIStore.isDrawerLoading = false;
  //   emit("close-drawer");
  //   return;
  // }

  let res = await crudStore.updateOne("Investments", filter, update);

  console.log(update);
  console.log(res);

  if (res.modifiedCount === 1) {
    var alert = {
      type: "success",
      message: "Bank account updated successfully",
    };
  } else {
    var alert = {
      type: "error",
      message: "Failed to update bank account",
    };
  }

  UIStore.animateNotificationAlert(alert);

  recordDetailStore.updateTic++;

  emit("close-drawer");
  UIStore.isDrawerLoading = false;
}

function editAccount(account) {
  //console.log(account);
  account_to_edit.value = account;
  editBankModalOpen.value = true;
}

function deleteAccount(account) {
  //console.log(account);
  account_to_delete.value = account;
  deleteBankModalOpen.value = true;
}

watch(
  () => props.open,
  async () => {
    if (props.open) {
      if (investorStore.active_bank_account._id) {
        selectedBankAccountId.value = investorStore.active_bank_account._id;
      } else if (investorStore.active_finance_method === "Check") {
        selectedBankAccountId.value = "check";
      } else if (investorStore.active_finance_method === "Wire") {
        selectedBankAccountId.value = "wire";
      }
    }
  }
);
</script>
