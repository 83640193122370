<template>
  <Modal size="26x38" :hideModalTabs="true" :open="props.open" @close-modal="emit('close-modal')">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2 mt-1">
          Update Approval Status for
          {{ investmentStore.investment_ids_to_process?.length }} Investment<span
            v-if="investmentStore.investment_ids_to_process?.length > 1"
            >s</span
          >
        </div>
      </div>
    </template>
    <template v-slot:modal-body>
      <slot name="modal-body">
        <div class="h-full w-full bg-white flex flex-col gap-4 p-4 px-10">
          <div class="flex flex-col">
            <span class="font-bold">Approval Status</span>
            <span class="text-light"
              >Are you approving these investments or setting them to Pending?</span
            >
          </div>
          <div class="flex flex-row">
            <RadioOptionModal
              id="Approved"
              name="Approved"
              value="Approved"
              v-model="investmentEdits.approval_status"
              label="Approved"
              :bold-label="false"
              :disabled="
                !authStore.isDalmoreAdmin &&
                !authStore.adminUserData.permissions.includes('master-admin')
              "
            />
            <RadioOptionModal
              id="Pending"
              name="Pending"
              value="Pending"
              v-model="investmentEdits.approval_status"
              label="Pending"
              :bold-label="false"
              :disabled="
                !authStore.isDalmoreAdmin &&
                !authStore.adminUserData.permissions.includes('master-admin')
              "
            />
            <RadioOptionModal
              id="Rejected"
              name="Rejected"
              value="Rejected"
              v-model="investmentEdits.approval_status"
              label="Rejected"
              :bold-label="false"
              :disabled="
                !authStore.isDalmoreAdmin &&
                !authStore.adminUserData.permissions.includes('master-admin')
              "
            />
            <RadioOptionModal
              v-if="!authStore.isDalmoreAdmin"
              id="Needs Attention"
              name="Needs Attention"
              value="Needs Attention"
              v-model="investmentEdits.approval_status"
              label="Needs Attention"
              :bold-label="false"
            />
          </div>
          <Table
            :table-data="investmentStore.investments_for_approvals"
            :headers="tableHeaders"
            :fields-with-custom-styles="['notes', 'approval_status']"
          >
            <template #notes="record">
              <div class="px-2">
                <div
                  class="w-fit p-2 cursor-pointer"
                  @click="investmentEdits[record.row._id.value] = 1"
                >
                  <Icon name="NotebookPen" class="h-5 w-5 text-primary-8" />
                </div>
              </div>
            </template>
            <template v-slot:approval_status="data">
              <div class="ml-4 truncate">
                <p v-if="!data.row.approval_status?.value">New</p>
                <p v-else-if="data.row.approval_status?.value === 'approved'">Approved</p>
                <p v-else-if="data.row.approval_status?.value === 'pending'">Pending</p>
                <p v-else-if="data.row.approval_status?.value === 'rejected'">Rejected</p>
                <p v-else-if="data.row.approval_status?.value === 'Needs Attention'">
                  Needs Attention
                </p>
              </div>
            </template>
          </Table>
        </div>
      </slot>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template></Button
        >
        <Button size="large" :disabled="isSaveDisabled" label @click="updateInvestments">
          <template #label>Update</template></Button
        >
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconInvestment, IconX } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed } from "vue";
import {
  useRecordDetailStore,
  useCrudStore,
  useUIStore,
  useInvestmentStore,
  useAuthStore,
} from "@/stores";
import { Modal, Button, BaseInput, RadioOptionModal, Table, Icon } from "@/components";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const UIStore = useUIStore();
const investmentStore = useInvestmentStore();
const authStore = useAuthStore();

const investment_status_schema_query = {
  collection_name: "Investments",
  field_name: "status",
};

var tableRecords = computed(() => {
  //
});

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close-modal"]);

// TODO this should come from schema honestly.
const tableHeaders = ref([
  { field_name: "signature_date", label: "Signature", field_type: "date" },
  //{ field_name: "_id", label: "ID" },
  { field_name: "approval_status", label: "Approval Status" },
  { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
  { field_name: "type", label: "Type" },
  //{ field_name: "notes", label: "Notes" },
]);

onMounted(async () => {
  custodians.value = await crudStore.find("Custodians", {});
  let investmentStatusSchema = await crudStore.findOne("Schema", investment_status_schema_query);
  investmentStatuses.value = investmentStatusSchema.record_detail_config.default.dropdown_options;
});

const custodians = ref([]);
const investmentStatuses = ref([]);
const investmentEdits = ref({});

const isSaveDisabled = computed(() => {
  return false;
});

async function updateInvestments() {
  let approval_status_val =
    investmentEdits.value.approval_status != "Needs Attention"
      ? investmentEdits.value.approval_status.toLowerCase()
      : investmentEdits.value.approval_status;
  let filter = { _id: { $in: investmentStore.investment_ids_to_process } };
  const updatedTimestamp = {
    updated_date: new Date(),
    updated_by_id: authStore.currentUser.id,
    updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
  };
  let update = { $set: { approval_status: approval_status_val, ...updatedTimestamp } };
  if (approval_status_val === "approved") {
    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);
    update.$set.approval_date = now;
  }

  console.log(filter, update);

  let response = await crudStore.updateMany("Investments", filter, update, false);

  console.log(response);

  if (response.matchedCount) {
    UIStore.animateNotificationAlert({
      type: "success",
      icon: "BadgeCheck",
      message: `Successfully updated ${response.matchedCount} Investments!`,
      subText: "",
      is_read: false,
      does_persist: false,
      action: null,
    });
  } else {
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to update Investments!",
      subText: "",
      is_read: false,
      does_persist: false,
      action: null,
    });
  }

  recordDetailStore.updateTic++;

  emit("close-modal");
  emit("refetch-investments");
}
</script>
