<template>
  <div v-if="UIStore.isLoading">
    <LoadingSpinner :show="UIStore.isLoading" message="Loading..." hideOverlay />
  </div>
  <div v-else class="px-4 md:px-6 lg:px-10 py-6 flex flex-col h-full">
    <div v-if="isCSVGenerating">
      <LoadingSpinner :show="isCSVGenerating" message="Loading..." />
    </div>
    <div class="flex flex-col md:flex-row gap-2 justify-between">
      <div class="flex items-center gap-2">
        <div
          class="flex h-10 w-10 min-h-10 min-w-10 items-center justify-center rounded-lg bg-opacity-20 bg-cBlue"
        >
          <div class="flex h-8 w-8 items-center justify-center rounded-md bg-cBlue">
            <Icon name="LockKeyhole" class="h-6 w-6 text-neutral-gray-1" />
          </div>
        </div>
        <p class="text-2xl font-bold uppercase whitespace-nowrap">Auth Logs</p>
      </div>
    </div>
    <div
      class="hidden sm:flex flex-col lg:flex-row justify-between items-end mt-6 w-full gap-4 lg:gap-10"
    >
      <div class="flex flex-col md:flex-row items-end gap-4 w-full xl:w-1/2">
        <BaseInput
          v-model="filters.email"
          placeholder="Email"
          label="Email"
          size="large"
          type="text"
        />
        <BaseInput
          v-model="filters.user_id"
          placeholder="User ID"
          label="User ID"
          size="large"
          type="text"
        />
        <BaseInput
          v-model="filters.location"
          placeholder="Location"
          label="Location"
          size="large"
          type="text"
        />
        <BaseInput
          v-model="filters.action"
          placeholder="Action"
          label="Action"
          size="large"
          type="text"
        />
      </div>

      <div class="flex gap-2">
        <Button
          label
          @click="handleDownloadAuthLogCSV"
          variant="default-gray-outlined"
          class="w-full md:w-auto"
          size="large"
        >
          <template #label>Download .CSV</template>
        </Button>
        <Button
          label
          @click="clearFilters"
          variant="default-gray-outlined"
          class="w-full md:w-auto"
          size="large"
        >
          <template #label>Clear</template>
        </Button>
        <Button @click="applyFilters" label class="w-full md:w-auto" size="large">
          <template #label>Apply</template>
        </Button>
      </div>
    </div>
    <!-- Mobile -->
    <div class="flex flex-col sm:hidden border-platform-outlines border rounded-xl mt-4">
      <div class="w-full flex justify-between p-4 items-center">
        <span class="font-bold">Filter</span>
        <div
          class="border-platform-outlines border rounded-lg p-1.5 cursor-pointer"
          @click="filterDropOpen = !filterDropOpen"
        >
          <Icon :name="filterDropOpen ? 'ChevronUp' : 'ChevronDown'" class="w-4 h-4" />
        </div>
      </div>
      <div
        class="transition-all duration-300 overflow-hidden"
        :class="filterDropOpen ? 'max-h-screen' : 'max-h-0'"
      >
        <div
          class="flex flex-col md:flex-row flex-wrap items-end gap-4 w-full py-6 px-4 grow bg-neutral-gray-3"
        >
          <BaseInput
            v-model="filters.email"
            placeholder="Email"
            label="Email"
            size="large"
            type="text"
          />
          <BaseInput
            v-model="filters.user_id"
            placeholder="User ID"
            label="User ID"
            size="large"
            type="text"
          />
          <BaseInput
            v-model="filters.location"
            placeholder="Location"
            label="Location"
            size="large"
            type="text"
          />
          <BaseInput
            v-model="filters.action"
            placeholder="Action"
            label="Action"
            size="large"
            type="text"
          />
        </div>
        <div class="flex flex-row items-center gap-4 p-4 justify-center">
          <Button
            label
            @click="handleDownloadAuthLogCSV"
            variant="default-gray-outlined"
            class="w-full md:w-auto"
            size="large"
          >
            <template #label>Download .CSV</template>
          </Button>
          <Button
            class="w-full"
            variant="default-gray-outlined"
            :label="true"
            size="large"
            @click="clearFilters"
          >
            <template #label> Clear </template>
          </Button>

          <Button class="w-full" :label="true" size="large" @click="applyFilters">
            <template #label> Apply </template>
          </Button>
        </div>
      </div>
    </div>
    <div class="border-b border-platform-outlines w-full my-6"></div>
    <div v-if="authStore.authLogs?.length" class="flex flex-col justify-between grow">
      <div>
        <Table
          @setSortHeader="(header, props) => setSortHeader(header, props)"
          :headers="authLogTableHeaders"
          :tableData="UIStore.transformRecords(authStore.authLogs)"
          class="max-w-full"
          use_table_loader
          isResizable
          :fields-with-custom-styles="['created_date', 'email']"
        >
          <template v-slot:created_date="data">
            <div class="ml-4">
              <p>
                {{
                  new Date(data?.row?.created_date?.value)?.toLocaleDateString("en-US", {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })
                }}
                -
                {{
                  new Date(data?.row?.created_date?.value)?.toLocaleTimeString("en-US", {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })
                }}
                (Local)
              </p>
            </div>
          </template>
          <template v-slot:email="data">
            <div class="ml-4 text-gold cursor-pointer">
              <RouterLink
                v-if="data?.row?.contact_id?.value"
                :to="`/contacts?recordId=${data.row.contact_id.value}`"
              >
                {{ data.row.email.value }}
              </RouterLink>
              <div v-else @click="handleRouteToEmail">
                {{ data.row.email.value }}
              </div>
            </div>
          </template>
        </Table>
      </div>
      <Pagination
        v-if="authStore.authLogs?.length > 0"
        show-count
        :total-category-results="authStore.authLogsTotalCount"
        :page-size="20"
        :page-num="authStore.authLogsPage"
        @change-page="authStore.authLogsPage = $event"
        class="mt-8"
      />
    </div>
    <div
      v-else-if="!authStore.authLogs?.length && !UIStore.isLoading"
      class="flex flex-col gap-2 items-center justify-center mt-8"
    >
      <EmptyState description="No Auth Logs to Display." icon_name="LockKeyhole" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useRouter } from "vue-router";

import {
  Icon,
  Button,
  BaseInput,
  Pagination,
  Table,
  LoadingSpinner,
  EmptyState,
} from "@/components";
import { useUIStore, useAuthStore, useCrudStore } from "@/stores";
import { sanitizeJSONAndDownloadCSV } from "@/utilities";

const router = useRouter();

const UIStore = useUIStore();
const authStore = useAuthStore();
const crudStore = useCrudStore();

const filters = reactive({
  email: "",
  location: "",
  action: "",
  user_id: "",
});

const authLogTableHeaders = ref([
  { field_name: "user_id", label: "User ID" },
  { field_name: "email", label: "Email" },
  { field_name: "location", label: "Location" },
  { field_name: "action", label: "Action" },
  { field_name: "device_information", label: "Device Info" },
  { field_name: "created_date", label: "Created Date (Local)" },
  { field_name: "created_date_string", label: "Create Date (UTC)" },
  { field_name: "ip_address", label: "IP Address" },
  { field_name: "session_id", label: "Session ID" },
]);

const filterDropOpen = ref(false);
const isCSVGenerating = ref(false);

onMounted(onMountedFunction);

async function onMountedFunction() {
  await authStore.fetchAuthLogs();
}

watch(
  () => authStore.authLogsPage,
  async () => {
    await authStore.fetchAuthLogs(false, true);
  }
);

async function applyFilters() {
  let filterString = "";
  if (filters.email) {
    filterString += `email='${filters.email}'`;
  }
  if (filters.user_id) {
    filterString += filterString
      ? ` AND user_id='${filters.user_id}'`
      : `user_id='${filters.user_id}'`;
  }
  if (filters.location) {
    filterString += filterString
      ? ` AND location LIKE '%${filters.location}%'`
      : `location LIKE '%${filters.location}%'`;
  }
  if (filters.action) {
    filterString += filterString
      ? ` AND action LIKE '%${filters.action}%'`
      : `action LIKE '%${filters.action}%'`;
  }

  authStore.authLogsFilter = filterString;

  await authStore.fetchAuthLogs(false, true);
}

async function clearFilters() {
  filters.email = "";
  filters.user_id = "";
  filters.location = "";
  filters.action = "";
  authStore.authLogsPage = 1;
  authStore.authLogsSort = null;
  authStore.authLogsFilter = null;
  await authStore.fetchAuthLogs(false, true);
}

async function handleDownloadAuthLogCSV() {
  isCSVGenerating.value = true;
  const headers = {
    user_id: "User ID",
    email: "Email",
    created_date_string: "Created Date (UTC)",
    ip_address: "IP Address",
    location: "Location",
    action: "Action",
    session_id: "Session ID",
  };
  const allAuthLogs = await authStore.fetchAuthLogs(true, true);
  const fileName = `AUTH_LOGS_${new Date().toLocaleDateString().replace(/\//g, "_")}`;
  sanitizeJSONAndDownloadCSV(headers, allAuthLogs, fileName);
  isCSVGenerating.value = false;
}

async function setSortHeader(header) {
  UIStore.setSortHeader(header);
  const fieldName =
    header.field_name === "created_date_string" ? "created_date" : header.field_name;
  authStore.authLogsSort = {
    [fieldName]: UIStore.sortAscending ? "ASC" : "DESC",
  };
  await authStore.fetchAuthLogs(false, true);
}

async function handleRouteToEmail(email) {
  const ownerContact = await crudStore.findOne("Contacts", { email: email, type: "Regular" });
  if (ownerContact) {
    router.push(`/contacts?recordId=${ownerContact._id}`);
  }
}
</script>
