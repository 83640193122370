<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" @close-modal="$emit('close-modal')" size="large">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="flex flex-row items-center">
          <div
            class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-info-click bg-opacity-20 p-1"
          >
            <IconTableChart class="fill-functional-info-click" />
          </div>

          <div class="font-bold text-custom-navy-2">Data Model Configuration</div>
        </div>
      </template>
      <template v-slot:modal-tabs>
        <div class="no-scrollbar ml-4 flex overflow-x-scroll border-b border-neutral-gray-4">
          <!-- Render tabs -->
          <!-- Render tabs -->
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            @click="tab === 'Arrow' ? '' : (activeTab = tab)"
            :class="[
              activeTab === tab
                ? 'border-b-3 border-secondary-6 bg-secondary-6 bg-opacity-20 font-bold'
                : 'border-b-3 border-transparent',
              tab === 'Arrow' ? 'pl-2 pr-1' : 'p-3',
            ]"
            class="flex flex-grow-0 cursor-pointer flex-row items-center justify-center whitespace-nowrap"
          >
            <div class="flex flex-row items-center text-left text-base text-primary-6">
              <div v-if="tab !== 'Arrow'">{{ tab }}</div>
              <div v-else><IconChevronRight class="h-value-12 w-value-12" /></div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-body>
        <!-- Display content based on the active tab -->
        <!-- {{ dataModelManagementStore.model_being_edited }} -->

        <div v-if="activeTab === 'General Settings'" class="mt-4 px-4 pb-8">
          <div class="grid grid-cols-1 gap-4 pb-4 lg:grid-cols-2">
            <BaseInput
              required
              label="Collection Name"
              v-model="dataModelManagementStore.model_being_edited.collection"
              type="select"
              :options="UIStore.all_collection_options"
              size="large"
            ></BaseInput>
            <BaseInput
              label="Primary Key"
              required
              placeholder="Enter Primary Key"
              v-model="dataModelManagementStore.model_being_edited.primary_key"
              type="text"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Record Path"
              placeholder="Enter Record Path"
              v-model="dataModelManagementStore.model_being_edited.record_path"
              type="text"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Landing Path"
              placeholder="Enter Landing Path"
              v-model="dataModelManagementStore.model_being_edited.landing_path"
              type="text"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Singular Name"
              placeholder="Enter Singular Name"
              v-model="dataModelManagementStore.model_being_edited.singular"
              type="text"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Plural Name"
              placeholder="Enter Plural Name"
              v-model="dataModelManagementStore.model_being_edited.plural"
              type="text"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Icon Name"
              v-model="dataModelManagementStore.model_being_edited.icon"
              type="select"
              :options="UIStore.all_icon_options"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Icon Color"
              v-model="dataModelManagementStore.model_being_edited.icon_color"
              type="select"
              :options="UIStore.all_color_options"
              size="large"
            ></BaseInput>
            <BaseInput
              required
              label="Is Merge Collection"
              v-model="dataModelManagementStore.model_being_edited.is_merge_collection"
              type="boolean"
              size="large"
            ></BaseInput>
            <BaseInput
              v-if="dataModelManagementStore.model_being_edited.is_merge_collection"
              required
              label="Secondary Icon Name"
              v-model="dataModelManagementStore.model_being_edited.icon2"
              type="select"
              :options="UIStore.all_icon_options"
              size="large"
            ></BaseInput>
            <BaseInput
              v-if="dataModelManagementStore.model_being_edited.is_merge_collection"
              required
              label="Secondary Icon Color"
              v-model="dataModelManagementStore.model_being_edited.icon_color2"
              type="select"
              :options="UIStore.all_color_options"
              size="large"
            ></BaseInput>
          </div>
        </div>
        <div v-if="activeTab === 'Model Configuration'" class="mt-4 px-4 pb-8">
          <div class="grid grid-cols-1 gap-4 pb-4 md:grid-cols-2">
            <div class="w-full">
              <label for="" class="mb-2 block text-xs font-semibold text-lighter"
                >Multiple Configurations?
              </label>
              <Switch
                v-model="dataModelManagementStore.model_being_edited.has_multiple_configs"
                :class="[
                  dataModelManagementStore.model_being_edited.has_multiple_configs
                    ? 'bg-secondary-6'
                    : 'bg-neutral-gray-5',
                  'focus:ring-none relative my-3.5 inline-flex h-3.5 w-8 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                ]"
              >
                <span
                  aria-hidden="true"
                  :class="[
                    dataModelManagementStore.model_being_edited.has_multiple_configs
                      ? 'translate-x-4 bg-primary-6'
                      : '-translate-x-1 bg-primary-2',
                    'pointer-events-none inline-block h-4 w-4 transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
                  ]"
                />
              </Switch>
            </div>
            <BaseInput
              required
              label="Config Determining Field"
              placeholder="Enter Field Name"
              :disabled="!dataModelManagementStore.model_being_edited.has_multiple_configs"
              v-model="dataModelManagementStore.model_being_edited.config_determining_field"
              type="text"
              size="large"
            ></BaseInput>
          </div>
          <hr />
          <div class="my-4 flex flex-col gap-y-2">
            <label for="" class="mb-2 block text-sm font-bold text-neutral-gray-9"
              >Define Config Objects
            </label>

            <div
              class="flex items-center"
              v-for="(field, index) of dataModelManagementStore.model_being_edited
                .all_config_options"
            >
              <div class="w-full">
                <!-- {{ field.value }} -->
                <div class="flex items-center gap-4">
                  <BaseInput
                    required
                    :disabled="
                      dataModelManagementStore.model_being_edited.all_config_options[index] ===
                        'default' ||
                      !dataModelManagementStore.model_being_edited.has_multiple_configs
                    "
                    v-model="dataModelManagementStore.model_being_edited.all_config_options[index]"
                    type="text"
                    size="large"
                  ></BaseInput>
                  <div class="flex items-center gap-2">
                    <Button
                      icon
                      required
                      size="large"
                      :disabled="!dataModelManagementStore.model_being_edited.has_multiple_configs"
                      variant="default-gray-outlined"
                      :class="
                        dataModelManagementStore.model_being_edited.all_config_options[index] ===
                        'default'
                          ? 'px-value-34'
                          : ''
                      "
                      @click="
                        dataModelManagementStore.model_being_edited.all_config_options.push('')
                      "
                    >
                      <template #icon> <IconPlus /></template
                    ></Button>
                    <Button
                      v-if="
                        dataModelManagementStore.model_being_edited.all_config_options[index] !==
                        'default'
                      "
                      icon
                      size="large"
                      variant="default-gray-outlined"
                      @click="
                        dataModelManagementStore.model_being_edited.all_config_options.splice(
                          index,
                          1
                        )
                      "
                    >
                      <template #icon>
                        <Icon
                          name="Trash"
                          class="h-4.5 w-4.5 stroke-functional-error-default"
                          :stroke-width="2" /></template
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="my-4 flex flex-col gap-y-2">
            <label for="" class="mb-2 block text-sm font-bold text-neutral-gray-9"
              >Define Field Objects
            </label>

            <div
              class="flex items-end gap-4"
              v-for="(field, index) of dataModelManagementStore.model_being_edited.field_map"
            >
              <div class="grid w-full grid-cols-2 gap-4">
                <!-- {{ field.value }} -->
                <div>
                  <label
                    v-if="index === 0"
                    for=""
                    class="mb-2 block text-xs font-semibold text-lighter"
                    >Field Value<span class="text-red-500">*</span>
                  </label>
                  <BaseInput
                    required
                    :disabled="!dataModelManagementStore.model_being_edited.has_multiple_configs"
                    v-model="dataModelManagementStore.model_being_edited.field_map[index].value"
                    type="text"
                    size="large"
                  ></BaseInput>
                </div>
                <!-- {{ field.config }} -->
                <div>
                  <label
                    v-if="index === 0"
                    for=""
                    class="mb-2 block text-xs font-semibold text-lighter"
                    >Field Config<span class="text-red-500">*</span>
                  </label>

                  <BaseInput
                    required
                    :disabled="!dataModelManagementStore.model_being_edited.has_multiple_configs"
                    v-model="dataModelManagementStore.model_being_edited.field_map[index].config"
                    :options="dataModelManagementStore.model_being_edited.all_config_options"
                    type="select"
                    size="large"
                  ></BaseInput>
                </div>
              </div>
              <div class="flex gap-2">
                <Button
                  icon
                  :class="index === 0 ? 'px-value-34' : ''"
                  size="large"
                  :disabled="!dataModelManagementStore.model_being_edited.has_multiple_configs"
                  variant="default-gray-outlined"
                  @click="
                    dataModelManagementStore.model_being_edited.field_map.push({
                      value: '',
                      config: '',
                    })
                  "
                >
                  <template #icon><IconPlus /></template>
                </Button>
                <Button
                  icon
                  v-if="index !== 0"
                  size="large"
                  variant="default-gray-outlined"
                  @click="dataModelManagementStore.model_being_edited.field_map.splice(index, 1)"
                >
                  <template #icon>
                    <Icon
                      name="Trash"
                      class="h-4.5 w-4.5 stroke-functional-error-default"
                      :stroke-width="2" /></template
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'Card Management'" class="m-4">
          <div class="flex flex-col gap-4">
            <div
              class="flex max-w-full flex-col"
              v-for="(field, index) of dataModelManagementStore.model_being_edited.card_map"
            >
              <label for="" class="mb-3 block text-sm font-bold text-neutral-gray-9"
                >Card {{ index + 1 }}
              </label>
              <div class="grid grid-cols-2 gap-3">
                <!-- {{ field.value }} -->
                <BaseInput
                  required
                  label="Card Name"
                  v-model="dataModelManagementStore.model_being_edited.card_map[index].name"
                  type="text"
                  size="large"
                ></BaseInput>

                <!-- {{ field.config }} -->
                <BaseInput
                  required
                  label="Card Configuration"
                  v-model="
                    dataModelManagementStore.model_being_edited.card_map[index].config_object
                  "
                  disabled
                  type="text"
                  size="large"
                ></BaseInput>
              </div>
              <div class="mb-4 mt-2 flex">
                <div class="w-full">
                  <BaseInput
                    required
                    label="Description"
                    v-model="
                      dataModelManagementStore.model_being_edited.card_map[index].description
                    "
                    type="longText"
                    size="large"
                  ></BaseInput>
                </div>
              </div>
              <hr
                v-if="index !== dataModelManagementStore.model_being_edited.card_map.length - 1"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <div class="flex flex-row justify-end gap-1">
          <Button label variant="default-gray-outlined" size="large" @click="$emit('close-modal')">
            <template #label><p>Cancel</p></template></Button
          >
          <Button label size="large" @click="nextpage()" :disabled="disabled">
            <template #label> {{ custom_button_text }}</template></Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

import { BaseInput, Button, Modal } from "@/components";
import { Switch } from "@headlessui/vue";

import { useDataModelManagementStore, useUIStore } from "@/stores";
import { Icon } from "@/components";
import { IconDelete, IconPlus, IconChevronRight, IconTableChart } from "@/components/icons";

const dataModelManagementStore = useDataModelManagementStore();
const UIStore = useUIStore();

const tabs = ref(["General Settings", "Arrow", "Model Configuration", "Arrow", "Card Management"]);
const activeTab = ref("General Settings");
const custom_button_text = ref("Next");
const emit = defineEmits(["close-modal"]);
const disabled = ref(false);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});
watch(
  () => [dataModelManagementStore.model_being_edited, activeTab.value],
  () => {
    if (activeTab.value !== "Card Management") {
      custom_button_text.value = "Next";
    } else {
      custom_button_text.value = "Save";
    }

    if (activeTab.value === "General Settings") {
      if (
        !dataModelManagementStore.model_being_edited.primary_key ||
        !dataModelManagementStore.model_being_edited.landing_path ||
        !dataModelManagementStore.model_being_edited.record_path ||
        !dataModelManagementStore.model_being_edited.singular ||
        !dataModelManagementStore.model_being_edited.plural ||
        !dataModelManagementStore.model_being_edited.icon ||
        !dataModelManagementStore.model_being_edited.icon_color ||
        !dataModelManagementStore.model_being_edited.collection
      ) {
        disabled.value = true;
      } else {
        disabled.value = false;
      }
    } else if (activeTab.value === "Model Configuration") {
      if (dataModelManagementStore.model_being_edited.has_multiple_configs) {
        if (
          dataModelManagementStore.model_being_edited.config_determining_field === "" ||
          dataModelManagementStore.model_being_edited.all_config_options.length === 1 ||
          dataModelManagementStore.model_being_edited.field_map.length === 1 ||
          dataModelManagementStore.model_being_edited.all_config_options.includes("") ||
          dataModelManagementStore.model_being_edited.field_map.some((item) => item.config === "")
        ) {
          //add mode logic to make sure none of the inputs are empty
          disabled.value = true;
        } else {
          disabled.value = false;
        }
      } else {
        disabled.value = false;
      }
    } else if (activeTab.value === "Card Management") {
      dataModelManagementStore.model_being_edited.all_config_options.forEach((option) => {
        const exists = dataModelManagementStore.model_being_edited.card_map.some(
          (card) => card.config_object === option
        );
        if (!exists) {
          dataModelManagementStore.model_being_edited.card_map.push({ config_object: option });
        }
      });

      // Removing invalid cards

      dataModelManagementStore.model_being_edited.card_map.filter((card) => {
        return dataModelManagementStore.model_being_edited.all_config_options.includes(
          card.config_object
        );
      });
      if (
        dataModelManagementStore.model_being_edited.card_map.some(
          (card) => card.name === "" || card.description === ""
        ) ||
        dataModelManagementStore.model_being_edited.primary_key === "" ||
        dataModelManagementStore.model_being_edited.collection === ""
      ) {
        disabled.value = true;
      } else {
        disabled.value = false;
      }
    }
  },
  { deep: true }
);
watch(
  () => [dataModelManagementStore.datamodalopen],
  () => {
    if (!dataModelManagementStore.datamodalopen) {
      const template = JSON.parse(JSON.stringify(dataModelManagementStore.blank_model));
      dataModelManagementStore.model_being_edited = template;
      custom_button_text.value = "Next";
      activeTab.value = "General Settings";
    }
  },
  { deep: true }
);

async function nextpage() {
  if (activeTab.value === "General Settings") {
    activeTab.value = "Model Configuration";
  } else if (activeTab.value === "Model Configuration") {
    // Adding new cards
    dataModelManagementStore.model_being_edited.all_config_options.forEach((option) => {
      const exists = dataModelManagementStore.model_being_edited.card_map.some(
        (card) => card.config_object === option
      );

      if (!exists) {
        dataModelManagementStore.model_being_edited.card_map.push({ config_object: option });
      }
    });

    // Removing invalid cards
    dataModelManagementStore.model_being_edited.card_map =
      dataModelManagementStore.model_being_edited.card_map.filter((card) => {
        return dataModelManagementStore.model_being_edited.all_config_options.includes(
          card.config_object
        );
      });

    activeTab.value = "Card Management";
    custom_button_text.value = "Save";
  } else if (activeTab.value === "Card Management") {
    await dataModelManagementStore.insertModel();
    await dataModelManagementStore.setManagementObjects();
    emit("close-modal");
  }
}
</script>
