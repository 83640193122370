<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" @close-modal="$emit('close-modal')" size="md">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="flex flex-row items-center">
          <div
            class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-info-click bg-opacity-20 p-1"
          >
            <IconTableRows class="h-5 w-5 fill-functional-info-click" />
          </div>

          <div class="font-bold text-custom-navy-2">
            {{ dataModelManagementStore.propertyAction }} Property
          </div>
        </div>
      </template>
      <template v-slot:modal-tabs>
        <div class="no-scrollbar ml-4 flex overflow-x-scroll border-b border-neutral-gray-4">
          <!-- Render tabs -->
          <!-- Render tabs -->
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            @click="tab === 'Arrow' ? '' : (activeTab = tab)"
            :class="[
              activeTab === tab
                ? 'border-b-3 border-secondary-6 bg-secondary-6 bg-opacity-20 font-bold'
                : 'border-b-3 border-transparent',
              tab === 'Arrow' ? 'pl-2 pr-1' : 'p-3',
            ]"
            class="flex flex-grow-0 cursor-pointer flex-row items-center justify-center whitespace-nowrap"
          >
            <div class="flex flex-row items-center text-left text-base text-primary-6">
              <div v-if="tab !== 'Arrow'">{{ tab }}</div>
              <div v-else><IconChevronRight class="h-value-12 w-value-12" /></div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-body>
        <!-- Display content based on the active tab -->

        <div class="no-scrollbar mb-4 h-80 bg-neutral-gray-2 p-4">
          <div
            v-if="activeTab === 'General Settings'"
            class="grid grid-cols-1 gap-x-5 gap-y-3 pb-4 md:grid-cols-2"
          >
            <!-- place as info nugget -->
            <div v-for="sett of general_settings_inputs" class="w-full">
              <BaseInput
                :label="sett.label"
                required
                v-model="propertyObject[sett.value]"
                type="boolean"
                size="large"
              />
            </div>
            <BaseInput
              :disabled="!propertyObject.belongs_to_nested_object"
              dropdownAbove
              label="Nested Object Name"
              :required="propertyObject.belongs_to_nested_object"
              v-model="propertyObject.nested_object_name"
              type="select"
              :options="dataModelManagementStore.nested_object_options"
              size="large"
            />
          </div>
          <div
            v-if="activeTab === 'Data Settings'"
            class="grid grid-cols-1 gap-x-5 gap-y-3 pb-4 md:grid-cols-2"
          >
            <BaseInput
              label="Label"
              required
              v-model="propertyObject.label"
              type="text"
              size="large"
            /><BaseInput
              label="Field Name"
              required
              v-model="propertyObject.field_name"
              type="text"
              size="large"
            /><BaseInput
              label="Field Type"
              required
              v-model="propertyObject.field_type"
              :options="field_type_options"
              type="select"
              size="large"
            /><BaseInput
              label="Select Group"
              required
              :options="computedGroupingOrder"
              v-model="propertyObject.record_detail_group"
              type="select"
              size="large"
            />
            <BaseInput
              label="Default Value"
              placeholder=""
              v-model="propertyObject.default_value"
              type="text"
              size="large"
            />
            <BaseInput
              label="Error Message"
              placeholder=""
              v-model="propertyObject.error_message"
              type="text"
              size="large"
            />
            <BaseInput
              label="V1 Field Name"
              placeholder=""
              v-model="propertyObject.v1_field_name"
              type="text"
              size="large"
            />
            <BaseInput
              label="Migration Notes"
              placeholder=""
              v-model="propertyObject.migration_notes"
              type="text"
              size="large"
            />

            <BaseInput
              v-if="propertyObject.field_type === 'Number'"
              label="Number Type"
              required
              v-model="propertyObject.number_type"
              :options="number_type_options"
              type="select"
              size="large"
            />

            <BaseInput
              v-if="propertyObject.field_type === 'Text' || propertyObject.field_type === 'Array'"
              label="Associated Collection"
              v-model="propertyObject.associated_collection"
              :options="UIStore.all_collection_options"
              type="select"
              size="large"
            />

            <BaseInput
              v-if="propertyObject.field_type === 'Text'"
              label="Associated Field Name"
              v-model="propertyObject.associated_field_name"
              type="text"
              size="large"
            />
            <BaseInput
              v-if="propertyObject.field_type === 'Text' || propertyObject.field_type === 'Array'"
              label="Associated Name For Results"
              v-model="propertyObject.associated_name_for_results"
              type="text"
              size="large"
            />
            <BaseInput
              v-if="propertyObject.field_type === 'Text' || propertyObject.field_type === 'Array'"
              label="Base URL"
              v-model="propertyObject.base_url"
              type="text"
              size="large"
            />
            <BaseInput
              v-if="propertyObject.field_type === 'Text' || propertyObject.field_type === 'Array'"
              label="Associated Collection Query"
              v-model="propertyObject.associated_collection_query"
              type="text"
              size="large"
            />
            <BaseInput
              v-if="propertyObject.field_type === 'Text'"
              label="Field Regex"
              v-model="propertyObject.field_regex"
              type="text"
              size="large"
            />
            <BaseInput
              label="Original Collection Name"
              v-model="propertyObject.original_collection_name"
              :options="UIStore.all_collection_options"
              type="select"
              size="large"
            />
          </div>
          <div
            v-if="
              (propertyObject.field_type === 'Dropdown' ||
                propertyObject.field_type === 'Multi Select') &&
              activeTab === 'Data Settings'
            "
            class="mt-3"
          >
            <p class="mb-3 text-xs font-semibold text-lighter">List of Items</p>
            <div class="flex flex-col space-y-3">
              <div
                v-for="(option, index) of propertyObject.dropdown_options"
                class="flex w-full flex-row justify-between"
              >
                <div class="flex w-full flex-row">
                  <Button
                    v-if="propertyObject.dropdown_options.length > 1"
                    @click="movePropertyUp(index)"
                    icon
                    variant="default-gray-outlined"
                    size="large"
                    class="mr-1"
                    :disabled="index === 0"
                  >
                    <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
                  </Button>
                  <Button
                    v-if="propertyObject.dropdown_options.length > 1"
                    @click="movePropertyDown(index)"
                    icon
                    variant="default-gray-outlined"
                    size="large"
                    class="mr-3"
                    :disabled="index === propertyObject.dropdown_options.length - 1"
                  >
                    <template #icon><IconExpandMore class="h-4 w-4" /></template>
                  </Button>
                  <div class="mr-3 w-full">
                    <BaseInput
                      v-model="propertyObject.dropdown_options[index]"
                      type="text"
                      size="large"
                    />
                  </div>
                </div>
                <div class="flex flex-row">
                  <Button
                    v-if="index === propertyObject.dropdown_options.length - 1"
                    @click="addProperty()"
                    icon
                    variant="default-gray-outlined"
                    size="large"
                    class="mr-1"
                  >
                    <template #icon><IconPlus class="h-4 w-4" /></template>
                  </Button>
                  <Button
                    @click="deleteProperty(index)"
                    icon
                    :disabled="propertyObject.dropdown_options.length < 2"
                    variant="default-gray-outlined"
                    size="large"
                  >
                    <template #icon>
                      <Icon
                        name="Trash"
                        class="h-4.5 w-4.5 stroke-functional-error-default"
                        :stroke-width="2"
                    /></template>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="flex flex-row justify-end gap-1">
          <Button label variant="default-gray-outlined" size="large" @click="$emit('close-modal')">
            <template #label><p>Cancel</p></template></Button
          >
          <Button
            size="large"
            :disabled="customButtonText === 'Save' && save_is_disabled"
            label
            @click="customButtonText === 'Save' ? $emit('close-modal') : '', updateProperty()"
          >
            <template #label>{{
              activeTab === "General Settings"
                ? (customButtonText = "Next")
                : (customButtonText = "Save")
            }}</template></Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { BaseInput, Button, Modal } from "@/components";
import { Switch } from "@headlessui/vue";
import {
  IconTableRows,
  IconExpandLess,
  IconExpandMore,
  IconChevronRight,
  IconDelete,
  IconPlus,
} from "@/components/icons";
import { Icon } from "@/components";
import _ from "lodash";
import { useDataModelManagementStore, useUIStore } from "@/stores";

const dataModelManagementStore = useDataModelManagementStore();
const UIStore = useUIStore();
const computedGroupingOrder = ref();
const propertyObject = ref(_.cloneDeep(dataModelManagementStore.default_property_object));
const field_type_options = ref([
  "Text",
  "Date",
  "Boolean",
  "Number",
  "Long Text",
  "Dropdown",
  "Multi Select",
  "Array",
  "Object",
  "Array Of Ids",
  "Password",
]);
const number_type_options = ref(["Currency", "Decimal", "Integer", "Percentage"]);
const tabs = ref(["General Settings", "Arrow", "Data Settings"]);
const activeTab = ref("General Settings");
const customButtonText = ref("Next");
const save_is_disabled = ref(true);
const general_settings_inputs = ref([
  {
    label: "Is Information Nugget?",
    value: "is_info_nugget",
  },
  {
    label: "Is Required?",
    value: "is_required",
  },
  {
    label: "Show in Search Results?",
    value: "show_in_search_results",
  },
  {
    label: "Show In Related Lists?",
    value: "show_in_related_list",
  },
  {
    label: "Available for Symbology?",
    value: "available_for_symbology",
  },
  {
    label: "Available for Filtering?",
    value: "available_for_filtering",
  },
  {
    label: "Read Only?",
    value: "read_only",
  },
  {
    label: "Is Object Id?",
    value: "is_object_id",
  },
  {
    label: "Has Copy?",
    value: "has_copy",
  },
  {
    label: "Is Association Field?",
    value: "is_association_field",
  },
  {
    label: "Is Accredited Only Field?",
    value: "accredited_only",
  },
  {
    label: "Belongs To Nested Object?",
    value: "belongs_to_nested_object",
  },
]);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  currentGroup: {
    type: String,
    required: true,
  },
});

watch(
  () => propertyObject.value,
  async () => {
    if (!propertyObject.value.belongs_to_nested_object) {
      propertyObject.value.nested_object_name = "";
    }
    if (
      propertyObject.value.label === "" ||
      propertyObject.value.field_name === "" ||
      propertyObject.value.field_type === "" ||
      (!propertyObject.value.nested_object_name && propertyObject.value.belongs_to_nested_object) ||
      propertyObject.value.record_detail_group === "" ||
      ((propertyObject.value.field_type === "Dropdown" ||
        propertyObject.value.field_type === "Multi Select") &&
        (propertyObject.value.dropdown_options.length === 0 ||
          propertyObject.value.dropdown_options.some((option) => option === ""))) ||
      (propertyObject.value.field_type === "Number" && propertyObject.value.number_type === "")
    ) {
      save_is_disabled.value = true;
    } else {
      save_is_disabled.value = false;
    }
  },
  { deep: true }
);
watch(
  () => props.open,
  async () => {
    if (props.open) {
      activeTab.value = "General Settings";
    }
    propertyObject.value = await dataModelManagementStore.formatPropertyToEdit();
  }
);
watch(
  () => dataModelManagementStore.finalGroupingOrder,
  () => {
    computedGroupingOrder.value = JSON.parse(
      JSON.stringify(dataModelManagementStore.finalGroupingOrder)
    );
    const filteredGroupingOrder = computedGroupingOrder.value.filter((item) => item !== "");
    filteredGroupingOrder.push("No Group");
    computedGroupingOrder.value = filteredGroupingOrder;
  },
  { deep: true }
);
watch(
  () => propertyObject.value.field_type,
  () => {
    if (
      (propertyObject.value.field_type === "Dropdown" ||
        propertyObject.value.field_type === "Multi Select") &&
      !propertyObject.value.dropdown_options.length
    ) {
      propertyObject.value.dropdown_options.push("");
    }
  },
  { deep: true }
);

function movePropertyUp(index) {
  if (index > 0) {
    const temp = propertyObject.value.dropdown_options[index];
    propertyObject.value.dropdown_options[index] = propertyObject.value.dropdown_options[index - 1];
    propertyObject.value.dropdown_options[index - 1] = temp;
  }
}

function movePropertyDown(index) {
  if (index < propertyObject.value.dropdown_options.length - 1) {
    const temp = propertyObject.value.dropdown_options[index];
    propertyObject.value.dropdown_options[index] = propertyObject.value.dropdown_options[index + 1];
    propertyObject.value.dropdown_options[index + 1] = temp;
  }
}

function addProperty() {
  propertyObject.value.dropdown_options.push("");
}

function deleteProperty(index) {
  propertyObject.value.dropdown_options.splice(index, 1);
}

async function updateProperty() {
  if (customButtonText.value === "Save") {
    await dataModelManagementStore.updateProperty(propertyObject.value);
    await dataModelManagementStore.fetchModelNuggets();
    await dataModelManagementStore.setGroupData();
  }
  if (customButtonText.value === "Next") {
    activeTab.value = "Data Settings";
  }
}
</script>
