<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ createInvestmentStore.currentStep + 1 }} : Select Joint Contact
    </div>
  </div>
  <div>
    <div class="mt-4 flex flex-col gap-6">
      <label
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="{ 'border-gold': createInvestmentStore.add_joint_contact }"
        @click="setTemplate(null)"
      >
        <RadioOption
          id="newJointContact"
          name="newJointContact"
          :value="null"
          v-model="createInvestmentStore.selected_joint_contact"
          label="New Joint Investor"
          description="Use different person"
        />
      </label>

      <!-- Display only the first 5 items initially -->
      <label
        v-for="(joint_contact, index) in visibleContacts"
        :key="joint_contact.email"
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="
          createInvestmentStore.selected_joint_contact?.email === joint_contact.email
            ? 'border-gold'
            : 'border-platform-outlines'
        "
        @click="setTemplate(joint_contact)"
      >
        <RadioOption
          :id="index"
          name="activeJointContact"
          :value="joint_contact"
          v-model="createInvestmentStore.selected_joint_contact"
          :label="`${joint_contact.first_name} ${joint_contact.last_name}`"
          :description="joint_contact.email"
        />
      </label>

      <!-- Show more / Show less link -->
      <div
        class="w-full mb-4 flex items-center justify-center"
        v-if="createInvestmentStore.existing_joint_contacts.length > 5"
      >
        <p
          class="text-primary-6 font-semibold hover:underline cursor-pointer"
          @click="toggleShowAll"
        >
          {{
            showAll
              ? "Show less"
              : `Show More (${createInvestmentStore.existing_joint_contacts.length - 5})`
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { RadioOption } from "@/components";
import _ from "lodash";

const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

const showAll = ref(false); // Control whether to show all items or not

const visibleContacts = computed(() => {
  return showAll.value
    ? createInvestmentStore.existing_joint_contacts
    : createInvestmentStore.existing_joint_contacts.slice(0, 5);
});

function toggleShowAll() {
  showAll.value = !showAll.value;
}

function setTemplate(joint_contact) {
  if (joint_contact) {
    createInvestmentStore.add_joint_contact = false;
    createInvestmentStore.selected_joint_contact = _.cloneDeep(joint_contact);
    createInvestmentStore.joint_contact_template = _.cloneDeep(joint_contact);
  } else {
    createInvestmentStore.add_joint_contact = true;
    createInvestmentStore.selected_joint_contact = {};
    createInvestmentStore.joint_contact_template = schemaStore.createJointTemplate();
    createInvestmentStore.joint_contact_template.user_id =
      createInvestmentStore.current_owner_contact.user_id;
    createInvestmentStore.joint_contact_template.account_id =
      createInvestmentStore.current_owner_contact.account_id;
  }
}
</script>
