<template>
  <AddBankModal
    @close-modal="addBankModalOpen = false"
    @account-updated="handleAccountUpdated"
    :open="addBankModalOpen"
    :user_id="recordDetailStore.currentPageDocument.user_id"
  />
  <EditBankModal
    @close-modal="editBankModalOpen = false"
    @account-updated="handleAccountUpdated"
    :open="editBankModalOpen"
    :account_type="account_type"
    :account="account_to_edit"
  />
  <DeleteBankModal
    @close-modal="deleteBankModalOpen = false"
    @account-updated="handleAccountUpdated"
    @account-replaced="handleAccountUpdated"
    :open="deleteBankModalOpen"
    :account_type="account_type"
    :account="account_to_delete"
  />
  <div
    v-if="investorStore.active_profile_tab === 'Banking'"
    class="w-full flex flex-col justify-between h-full"
  >
    <!-- Funding Accounts -->
    <div
      v-if="investmentStore.bankAccounts.length > 0"
      class="grid grid-cols-1 lg:grid-cols-2 gap-4"
    >
      <div class="mb-4 col-span-1">
        <h2 class="text-lg font-bold">Bank Accounts</h2>
        <p class="text-lighter text-sm lg:text-base">
          Below are all accounts available to use for funding your investments. These accounts can
          also be used for receiving monthly interest distributions.
        </p>
      </div>
      <div class="flex justify-end col-span-1 gap-2">
        <Button
          class="w-full lg:w-auto"
          variant="default-gray-outlined"
          size="large"
          label
          @click="addBankModalOpen = true"
        >
          <template #label>Add ACH Account</template>
        </Button>
        <Button
          class="w-full lg:w-auto"
          variant="default-gray-outlined"
          size="large"
          label
          @click="editMode = !editMode"
        >
          <template #label>{{ editMode ? "Cancel" : "Edit" }}</template>
        </Button>
      </div>
      <div class="flex flex-col gap-4 lg:col-span-2 col-span-1 w-full">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-neutral-gray-2 rounded-md p-3">
          <div
            v-for="(bankingOption, index) in !showMoreFunding
              ? investmentStore?.bankAccounts?.slice(0, 4)
              : investmentStore.bankAccounts"
            :key="bankingOption.id"
          >
            <!-- Plaid Accounts will go here -->
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 border-platform-outlines"
            >
              <BankingOption
                :id="'' + index"
                :name="'FundingAccount' + index"
                :value="bankingOption._id"
                v-model="selectedBankAccountId"
                :label="bankingOption.financial_institution_name"
                :description="bankingOption.account_type"
                :account_num="bankingOption.account_number"
                :edit_cta="editMode && !bankingOption.plaid_account_id"
                @edit-account="editAccount(bankingOption)"
                :delete_cta="editMode"
                @delete-account="deleteAccount(bankingOption)"
              />
              <img
                v-if="bankingOption.logo"
                class="h-16 w-16"
                :src="'data:image/png;base64, ' + bankingOption.logo + ''"
                alt="Red dot"
              />
              <div v-else class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon name="Landmark" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
              </div>
            </label>
          </div>
          <button
            v-if="investmentStore.bankAccounts?.length > 4"
            @click="showMoreFunding = !showMoreFunding"
            class="text-center lg:col-span-2"
          >
            <span v-if="!showMoreFunding">
              Show More ({{
                investmentStore.bankAccounts?.slice(4, investmentStore.bankAccounts?.length)
                  ?.length
              }})
            </span>
            <span v-else>Show Less</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center">
      <EmptyState icon_name="Landmark" description="No banking information to show." />
      <div class="flex justify-end col-span-1 gap-2">
        <Button class="w-full lg:w-auto" size="large" label @click="addBankModalOpen = true">
          <template #label>Add ACH Account</template>
        </Button>
      </div>
    </div>
    <!-- <div class="border-b border-neutral-gray-4 w-full my-4 lg:my-8"></div> -->
    <!-- Distribution Accounts
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <div class="mb-4 col-span-1">
        <h2 class="text-lg font-semibold">Distribution Accounts</h2>
        <p class="text-lighter text-sm lg:text-base">
          Phoenix distributes interest monthly via ACH on the 10th of each month. Please fill in the
          information below for where you'd like interest deposited. Only Checking and Savings
          accounts are supported.
        </p>
      </div>
      <div class="flex justify-end col-span-1">
        <Button
          variant="default-gray-outlined"
          size="large"
          label
          @click="editDistribution = !editDistribution"
        >
          <template #label>Edit</template>
        </Button>
      </div>
      <div class="flex flex-col gap-4 lg:col-span-2 col-span-1 w-full">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 bg-neutral-gray-2 rounded-md p-3">
          <div
            v-for="(bankingOption, index) in !showMoreDistribution
              ? investmentStore.bankAccounts?.slice(0, 4)
              : investmentStore.bankAccounts"
            :key="bankingOption.id"
          >
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 border-platform-outlines"
            >
              <BankingOption
                :id="'' + index"
                :name="'FundingAccount' + index"
                :value="bankingOption._id"
                v-model="selectedBankAccountId"
                :label="bankingOption.financial_institution_name"
                :description="bankingOption.account_type"
                :account_num="bankingOption.account_number"
                :edit_cta="editDistribution && !bankingOption.plaid_account_id"
                @edit-account="editBankAccount(bankingOption)"
                :delete_cta="editDistribution"
                @delete-account="deleteBankAccount(bankingOption)"
              />
              <img
                v-if="bankingOption.logo"
                class="h-16 w-16"
                :src="'data:image/png;base64, ' + bankingOption.logo + ''"
                alt="Red dot"
              />
              <div v-else class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon name="Landmark" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
              </div>
            </label>
          </div>
          <button
            v-if="investmentStore.bankAccounts?.length > 4"
            @click="showMoreDistribution = !showMoreDistribution"
            class="text-center lg:col-span-2"
          >
            <span v-if="!showMoreDistribution">
              Show More ({{
                investmentStore.bankAccounts?.slice(4, investmentStore.bankAccounts?.length)
                  ?.length
              }})
            </span>
            <span v-else>Show Less</span>
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import {
  useInvestmentStore,
  useCrudStore,
  useAuthStore,
  useRecordDetailStore,
  useInvestorStore,
} from "@/stores";
import {
  Button,
  Icon,
  RadioOption,
  BankingOption,
  AddBankModal,
  EditBankModal,
  DeleteBankModal,
  EmptyState,
} from "@/components";
import PlaidLink from "./PlaidLink.vue";

const investmentStore = useInvestmentStore();
const crudStore = useCrudStore();
const authStore = useAuthStore();
const recordDetailStore = useRecordDetailStore();
const investorStore = useInvestorStore();

const showMoreFunding = ref(false);
const showMoreDistribution = ref(false);
const selectedBankAccountId = ref(null);
const editMode = ref(false);

const addBankModalOpen = ref(false);
const editBankModalOpen = ref(false);
const account_to_edit = ref(null);
const deleteBankModalOpen = ref(false);
const account_to_delete = ref(null);

onMounted(async () => {
  onMountedFunction();
});

async function onMountedFunction() {
  investmentStore.bankAccounts = await crudStore.aggregate("BankAccounts", [
    {
      $match: {
        user_id: recordDetailStore.currentPageDocument.user_id,
      },
    },
    { $sort: { created_date: -1 } },
  ]);
}

function handleAccountUpdated() {
  // Refresh the bank accounts after an account is added, edited, or deleted
  onMountedFunction();
}

function editAccount(account) {
  //console.log(account);
  account_to_edit.value = account;
  editBankModalOpen.value = true;
}

function deleteAccount(account) {
  //console.log(account);
  account_to_delete.value = account;
  deleteBankModalOpen.value = true;
}
</script>
