<template>
  <div class="p-3 pl-4 flex justify-between w-full">
    <div class="flex items-center text-sm">
      <input
        :id="id"
        type="radio"
        :name="name"
        :value="value"
        :checked="isSelected"
        class="hidden"
        @change="updateValue"
      />
      <div
        class="min-w-5 min-h-5 rounded-full border-2 flex items-center justify-center cursor-pointer mr-3 mt-0.5"
        :class="{
          'border-gray-300': !isSelected,
          'border-secondary-6 bg-secondary-6': isSelected,
        }"
        @click="updateValue"
      >
        <div
          class="min-w-1.5 min-h-1.5 rounded-full"
          :class="{
            'bg-primary-6': isSelected,
            'bg-transparent': !isSelected,
          }"
        ></div>
      </div>
      <label :for="id" class="text-sm lg:text-base">
        <p class="text-primary font-bold">{{ label }}</p>
        <p class="text-light font-semibold">{{ description }}</p>
        <p class="text-lighter font-semibold">{{ description2 }}</p>
      </label>
    </div>
    <div class="flex items-center justify-center ml-4">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { useInvestmentStore, useInvestorStore, useCollectionStore } from "@/stores";

const collectionStore = useCollectionStore();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  boldLabel: {
    type: Boolean,
    required: true,
    default: true,
  },
  value: {
    type: [String, Object, Boolean, null],
    required: true,
  },
  modelValue: {
    type: [String, Object, Boolean, null],
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  description2: {
    type: String,
    required: true,
  },
  account_num: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const updateValue = () => {
  /// emit("update:modelValue", props.value);
};

const isSelected = computed(() => {
  if (typeof props.modelValue === "object" && typeof props.value === "object") {
    return JSON.stringify(props.modelValue) === JSON.stringify(props.value);
  }
  return props.modelValue === props.value;
});
</script>
