<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" @close-modal="$emit('close-modal')" size="large" :hideModalTabs="true">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header> {{ fileName }} - {{ fileType }}</template>

      <template v-slot:modal-body>
        <div>
          <div>
            <div
              v-if="
                fileType ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              "
            >
              <div
                class="rounded border border-platform-outlines bg-white p-4"
                v-html="fileContent"
              ></div>
            </div>

            <div v-else class="rounded p-1">
              <object
                :data="fileContent"
                :type="fileType"
                width="100%"
                height="700"
                class="rounded"
              ></object>
            </div>
          </div></div
      ></template>

      <template v-slot:modal-footer>
        <div class="flex flex-row justify-end gap-1">
          <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
            <template #label><p>Close</p></template></Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { Button, Modal } from "@/components";
import { IconDeleteData, IconWarning } from "@/components/icons";
import { useCrudStore, useDataModelManagementStore } from "@/stores";

const dataModelManagementStore = useDataModelManagementStore();
const crudStore = useCrudStore();

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  fileName: {
    default: "Test",
    required: true,
  },
  fileContent: {
    default: "Test",
    required: true,
  },
  fileType: {
    default: "Test",
    required: true,
  },
});
</script>
