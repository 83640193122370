<template>
  <div class="flex flex-col lg:flex-row-reverse items-start w-full h-full pb-10 lg:p-6">
    <div
      class="flex px-6 w-full lg:w-[65%] lg:border border-platform-outlines h-full rounded-md justify-center"
    >
      <div class="w-full md:w-[55%] lg:w-[45%] flex flex-col md:justify-center">
        <h3 class="font-bold text-[1.75rem] mb-8 mt-16 md:my-0 md:mb-8 whitespace-nowrap">
          Welcome Back Admin
        </h3>
        <div class="flex flex-col gap-4 mb-6 w-full">
          <BaseInput
            v-model="authStore.signInFields.email"
            label="Email"
            placeholder="Type Here"
            size="large"
            @keydown.enter="handleSignin"
          />
          <BaseInput
            v-model="authStore.signInFields.password"
            label="Password"
            placeholder="Type Here"
            size="large"
            type="password"
            @keydown.enter="handleSignin"
          />
        </div>
        <!-- Turnstyle widget -->
        <div class="mb-4" data-theme="light" id="turnstile"></div>
        <div class="flex flex-col items-center w-full gap-4">
          <Button
            @click="handleSignin"
            @keydown.enter="handleSignin"
            :disabled="!authStore.signInFields.email || !authStore.signInFields.password"
            label
            icon
            size="large"
            class="w-full"
          >
            <template v-if="isSigninLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template v-else #label>Sign In</template>
          </Button>
          <a
            href="/forgot-password"
            class="hover:bg-neutral-gray-4 h-10.5 py-4 px-3 rounded-md flex items-center justify-center w-full text-base"
          >
            Forgot password?
          </a>
        </div>
        <p v-if="signinError" class="text-functional-error-default text-center mt-2">
          {{ signinError }}
        </p>
      </div>
    </div>
    <!-- Bottom (mobile) / Left (desktop) Section -->
    <div
      class="hidden lg:flex w-full lg:w-[35%] lg:h-full rounded-md px-6 lg:px-0 lg:mr-6 items-center justify-center"
    >
      <div
        class="relative w-full h-full bg-cover bg-center bg-no-repeat rounded-md md:max-w-[55%] lg:max-w-none"
        :style="{ backgroundImage: `url(${bgImg})` }"
      >
        <!-- <div class="absolute inset-0 bg-primary-6 opacity-80 rounded-md z-0"></div> -->
        <div class="relative z-10 w-full h-full flex flex-col items-start px-4 py-6 lg:p-10">
          <img :src="phxLogo" alt="" class="hidden lg:block h-8 object-contain mt-6 mb-10" />
          <h3 class="text-white text-3xl md:text-4xl xl:text-[54px] mb-4 hidden lg:block font-bold">
            Take Flight
          </h3>
          <h3
            class="text-white text-3xl md:text-4xl xl:text-[54px] mb-6 lg:mb-12 hidden lg:block font-bold"
          >
            with Phoenix
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import { BaseInput, Button } from "@/components";
import { IconPulseSpinner } from "@/components/icons";
import phxLogo from "@/assets/images/PHX_Revised_Logo.png";
import bgImg from "@/assets/images/admin_bg_small.png";

const router = useRouter();
const authStore = useAuthStore();

const signinError = ref(false);
const isSigninLoading = ref(false);

onMounted(() => {
  authStore.verification_code = ["", "", "", "", "", ""];
  turnstile.ready(function () {
    turnstile.render("#turnstile", {
      sitekey:
        import.meta.env.VITE_ENV === "Production"
          ? "0x4AAAAAAAbuzA10_ip9GLl-"
          : "1x00000000000000000000AA",
      callback: function (token) {
        authStore.turnstileToken = token;
        authStore.isTurnstileValid = true;
      },
    });
  });
});

onUnmounted(() => {
  authStore.isTurnstileValid = false;
});

async function handleSignin() {
  try {
    if (!authStore.isTurnstileValid) {
      signinError.value =
        "Please complete the security check above to confirm that you are a human.";
      return;
    }

    if (!authStore.signInFields.email || !authStore.signInFields.password) {
      return;
    }
    signinError.value = "";
    isSigninLoading.value = true;
    const isSigninSuccessful = await authStore.validateUser(
      authStore.signInFields.email.toLowerCase().trim(),
      authStore.signInFields.password
    );

    if (isSigninSuccessful.status === "approved") {
      const sendCodeResult = await authStore.sendVerificationCode();
      if (sendCodeResult.status !== "pending") {
        signinError.value = "Failed to send verification code. Please try again.";
        return;
      }
      router.push("/auth-verification");
    } else {
      signinError.value = "Invalid email or password. Please try again.";
    }
  } catch (err) {
    signinError.value = "Error trying to sign in. Please try again.";
  } finally {
    isSigninLoading.value = false;
  }
}

let timeout = null;
watch(
  () => signinError.value,
  (newValue) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (newValue) {
      timeout = setTimeout(() => {
        signinError.value = false;
      }, 3000);
    }
  }
);
</script>
