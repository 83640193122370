<template>
  <div class="px-3" :class="{ 'pb-3.5': isDetailView }">
    <div
      v-if="!isDetailView"
      v-for="(history, index) in filterHistory"
      :key="`${index}-${recordDetailStore.activeFilterCategory}`"
      class="mb-3 animate-slideup rounded-md border border-neutral-gray-5 bg-neutral-gray-4 p-2"
    >
      <div v-if="recordDetailStore.activeFilterCategory === 'Favorites'" class="relative pb-2">
        <Transition
          enter-active-class="transition-opacity ease-in-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity ease-in-out duration-300"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="isDoneSavingName" class="absolute right-2 top-2 animate-slideup">
            <IconCheckmark class="h-4 w-4 fill-green-500" />
          </div>
        </Transition>

        <BaseInput
          v-model="history.favorite_filter_name"
          @input="debouncedHandleFavoriteNameChange(history)"
          placeholder="Favorite Filter Name"
        />
      </div>
      <div class="mb-2 flex items-center justify-between">
        <p class="text-xxs font-medium uppercase text-primary-1">
          USED: {{ calculateDaysAgo(history?.created_date) }}
        </p>
        <div class="flex items-center gap-1">
          <Button
            v-if="recordDetailStore.activeFilterCategory !== 'Favorites'"
            @click="handleFavoriteHistory(history, index)"
            icon
            size="small"
            :variant="history.is_favorited ? 'warning-filled' : 'default-gray-outlined'"
          >
            <template #icon>
              <IconFavoriteBorder />
            </template>
          </Button>
          <div class="relative">
            <div
              v-if="history.showFilterSwitchWarning"
              class="filter-warning absolute -right-8 top-8 z-50 flex h-24 w-60 animate-slideup flex-col gap-3 rounded-md bg-white p-3 text-xs shadow-lg"
            >
              <p>Applying this filter set will overwrite the current filters in the Applied tab.</p>
              <div class="flex items-center gap-2">
                <Button
                  @click="confirmSetHistoryToApplied(history)"
                  label
                  variant="default-color-outlined"
                  size="small"
                  class="!w-full"
                >
                  <template #label>Apply Filter</template>
                </Button>
                <Button
                  @click="history.showFilterSwitchWarning = false"
                  label
                  variant="subtle"
                  size="small"
                >
                  <template #label>Dismiss</template>
                </Button>
              </div>
            </div>
            <Button
              @click="handleSetHistoryToApplied(history)"
              icon
              size="small"
              variant="default-gray-outlined"
            >
              <template #icon>
                <IconFileCopy class="cursor-pointer fill-primary-6" />
              </template>
            </Button>
          </div>
          <div class="relative">
            <div
              v-if="history.showDeleteWarning"
              class="filter-warning absolute right-0 top-8 z-50 flex h-24 w-60 animate-slideup flex-col gap-3 rounded-md bg-white p-3 text-xs shadow-lg"
            >
              <p>Are you sure you want to delete this filter from your favorites?</p>
              <div class="flex items-center gap-2">
                <Button
                  @click="handleDeleteFilterHistory(history, true)"
                  label
                  variant="default-color-outlined"
                  size="small"
                  class="!w-full"
                >
                  <template #label>Confirm Delete</template>
                </Button>
                <Button
                  @click="history.showDeleteWarning = false"
                  label
                  variant="subtle"
                  size="small"
                >
                  <template #label>Dismiss</template>
                </Button>
              </div>
            </div>
            <Button
              @click="
                recordDetailStore.activeFilterCategory === 'Favorites'
                  ? ((history.showDeleteWarning = true), (history.showFilterSwitchWarning = false))
                  : handleDeleteFilterHistory(history, false)
              "
              icon
              size="small"
              variant="default-gray-outlined"
            >
              <template #icon>
                <IconDelete class="cursor-pointer fill-functional-error-default" />
              </template>
            </Button>
          </div>
        </div>
      </div>
      <div class="text-xs text-light">
        <p>
          Field Name: <span class="font-semibold">{{ history?.filters[0]?.label }}</span>
        </p>
        <p>
          Operator: <span class="font-semibold">{{ history?.filters[0]?.operator.name }}</span>
        </p>
        <p>
          Value:
          <span class="break-words font-semibold">
            {{
              history?.filters[0]?.value?.value !== undefined
                ? history?.filters[0]?.value?.value
                : history?.filters[0]?.value
            }}
          </span>
        </p>
        <p v-if="history.filters.some((f) => f.internal_sets.length > 0)">
          Logical Operator:
          <span class="font-semibold uppercase"
            >{{ history?.filters[0]?.logical_operator }}...</span
          >
        </p>
        <button
          @click="handleShowDetailView(history)"
          type="button"
          class="mt-2 w-full text-center font-semibold text-lighter"
        >
          View Details
        </button>
      </div>
    </div>
    <div
      v-if="isDetailView"
      class="animate-slideup rounded-md border border-neutral-gray-5 bg-neutral-gray-4"
    >
      <div class="flex items-center justify-between px-2 pt-2">
        <Button @click="isDetailView = false" icon variant="default-gray-outlined" size="small">
          <template #icon><IconChevronLeft /></template>
        </Button>
        <div class="flex items-center gap-1">
          <Button
            v-if="recordDetailStore.activeFilterCategory !== 'Favorites'"
            @click="handleFavoriteHistory(selectedFilterForDetailView)"
            icon
            size="small"
            :variant="
              selectedFilterForDetailView.is_favorited ? 'warning-filled' : 'default-gray-outlined'
            "
          >
            <template #icon>
              <IconFavoriteBorder />
            </template>
          </Button>
          <div class="relative">
            <div
              v-if="selectedFilterForDetailView.showFilterSwitchWarning"
              class="filter-warning absolute -right-8 top-8 z-50 flex h-24 w-60 animate-slideup flex-col gap-3 rounded-md bg-white p-3 text-xs shadow-lg"
            >
              <p>Applying this filter set will overwrite the current filters in the Applied tab.</p>
              <div class="flex items-center gap-2">
                <Button
                  @click="confirmSetHistoryToApplied(selectedFilterForDetailView)"
                  label
                  variant="default-color-outlined"
                  size="small"
                  class="!w-full"
                >
                  <template #label>Apply Filter</template>
                </Button>
                <Button
                  @click="selectedFilterForDetailView.showFilterSwitchWarning = false"
                  label
                  variant="subtle"
                  size="small"
                >
                  <template #label>Dismiss</template>
                </Button>
              </div>
            </div>
            <Button
              @click="handleSetHistoryToApplied(selectedFilterForDetailView)"
              icon
              size="small"
              variant="default-gray-outlined"
            >
              <template #icon>
                <IconFileCopy class="cursor-pointer fill-primary-6" />
              </template>
            </Button>
          </div>
          <Button
            @click="
              handleDeleteFilterHistory(
                selectedFilterForDetailView,
                recordDetailStore.activeFilterCategory === 'Favorites'
              )
            "
            icon
            size="small"
            variant="default-gray-outlined"
          >
            <template #icon>
              <IconDelete class="cursor-pointer fill-functional-error-default" />
            </template>
          </Button>
        </div>
      </div>
      <!-- <div class="px-2 pb-3 pt-2">
        <LayerDrawerViewOnlyLogicalFilters :dataFilters="selectedFilterForDetailView.filters" />
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";

import { Button, BaseInput, LogicalFilters } from "@/components";
//import LayerDrawerViewOnlyLogicalFilters from "@/pages/LayerManagement/LayerDrawerViewOnlyLogicalFilters.vue";
import {
  //IconFavoriteBorder,
  IconDelete,
  // IconFileCopy,
  IconChevronLeft,
  IconCheckmark,
} from "@/components/icons";
import { useRecordDetailStore } from "@/stores/useRecordDetailStore";
import { useCollectionStore } from "@/stores/useCollectionStore";
import { debounce } from "@/utilities";

const props = defineProps({
  filterHistory: {
    type: Array,
    required: true,
    default: [],
  },
});

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

const emit = defineEmits(["updateDataFilters"]);

const recordDetailStore = useRecordDetailStore();
const collectionStore = useCollectionStore();

const isDetailView = ref(false);
const selectedFilterForDetailView = ref(null);
const isDoneSavingName = ref(false);

watch(
  () => recordDetailStore.activeFilterCategory,
  () => {
    recordDetailStore.filterHistory.forEach((history) => {
      history.showFilterSwitchWarning = false;
    });
    isDetailView.value = false;
  }
);

watch(
  () => isDetailView.value,
  () => {
    const scrollableBody = document.querySelector("#record-detail-filter-modal-body");
    if (scrollableBody) {
      scrollableBody.scrollTop = 0;
    }
    recordDetailStore.filterHistory.forEach((history) => {
      history.showFilterSwitchWarning = false;
    });
  }
);

watch(
  () => recordDetailStore.activeFilterCategory,
  () => {
    recordDetailStore.filterHistory.forEach((history) => {
      history.showFilterSwitchWarning = false;
    });
    if (
      recordDetailStore.activeFilterCategory === "Favorites" ||
      recordDetailStore.activeFilterCategory === "Recent"
    ) {
      isDetailView.value = false;
    }
  }
);

watch(
  () => isDoneSavingName.value,
  () => {
    setTimeout(() => {
      isDoneSavingName.value = false;
    }, 1500);
  }
);

function handleClickOutside(e) {
  if (!e.target.closest(".filter-warning")) {
    recordDetailStore.filterHistory.forEach((history) => {
      history.showFilterSwitchWarning = false;
      history.showDeleteWarning = false;
    });
  }
}

function handleShowDetailView(history) {
  selectedFilterForDetailView.value = history;
  isDetailView.value = true;
}

async function handleFavoriteHistory(history, index) {
  if (!index) {
    // if index is 0, it means the user is trying to favorite the filter from the detail view
    index = recordDetailStore.filterHistory.findIndex(
      (h) => h._id.toString() === history._id.toString()
    );
  }
  history.is_favorited = !history.is_favorited;
  if (history.is_favorited) {
    history.favorite_filter_name = `Filter ${
      recordDetailStore.filterHistory.filter((h) => h.is_favorited).length
    }`;
  }

  await collectionStore.upsertRecord(
    "UserFilters",
    { _id: history._id },
    {
      $set: {
        is_favorited: history.is_favorited,
        favorite_filter_name: history.favorite_filter_name,
      },
    }
  );
}

async function handleDeleteFilterHistory(history, isFromFavorites = false) {
  history.showDeleteWarning = false;
  if (isFromFavorites) {
    history.is_favorited = false;
    history.favorite_filter_name = "";
    await collectionStore.upsertRecord(
      "UserFilters",
      { _id: history._id },
      {
        $set: {
          is_favorited: history.is_favorited,
          favorite_filter_name: history.favorite_filter_name,
        },
      }
    );
  } else {
    recordDetailStore.filterHistory = recordDetailStore.filterHistory.filter(
      (h) => h._id.toString() !== history._id.toString()
    );
    await collectionStore.deleteOneRecord("UserFilters", { _id: history._id });
  }
}

function handleSetHistoryToApplied(history) {
  if (
    recordDetailStore.filterPipeline &&
    Object.keys(recordDetailStore.filterPipeline).length > 0
  ) {
    recordDetailStore.filterHistory.forEach((history) => {
      history.showFilterSwitchWarning = false;
    });
    history.showFilterSwitchWarning = true;
    history.showDeleteWarning = false;
  } else {
    emit("updateDataFilters", history.filters);
    recordDetailStore.activeFilterCategory = "Applied";
  }
}

function confirmSetHistoryToApplied(history) {
  emit("updateDataFilters", history.filters);
  recordDetailStore.filterHistory.forEach((history) => {
    history.showFilterSwitchWarning = false;
  });
  recordDetailStore.activeFilterCategory = "Applied";
}

const debouncedHandleFavoriteNameChange = debounce(handleFavoriteNameChange, 1000);
async function handleFavoriteNameChange(history) {
  if (!history.favorite_filter_name) return;
  await collectionStore.upsertRecord(
    "UserFilters",
    { _id: history._id },
    {
      $set: {
        is_favorited: history.is_favorited,
        favorite_filter_name: history.favorite_filter_name,
      },
    }
  );
  isDoneSavingName.value = true;
}

function calculateDaysAgo(date) {
  const daysAgo = Math.floor((new Date() - new Date(date)) / (1000 * 60 * 60 * 24));
  return daysAgo < 1 ? "Today" : daysAgo > 1 ? `${daysAgo} days ago` : `Yesterday`;
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-enter-from {
  transform: translateX(100%);
  opacity: 0;
}
.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>
