<template>
  <Modal size="32square" :hideModalTabs="true" @close-modal="$emit('close-modal')" :open="open">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping['IconLandmark']"
          />
        </div>

        <div class="font-bold text-custom-navy-2">Add Bank Account</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-neutral-gray-2 flex flex-col gap-4">
        <div v-if="loading" class="h-full w-full flex items-center justify-center">
          <LoadingSpinner :show="true" :hide-overlay="true" position="absolute" />
        </div>
        <div v-else class="p-4 rounded-2xl h-full grid grid-cols-2 gap-2">
          <BaseInput
            :required="true"
            label="Financial Institution Name"
            v-model="bankAccountEdits.financial_institution_name"
            type="string"
            size="large"
            placeholder="Type here"
            showFullText
          />
          <BaseInput
            :required="false"
            label="Account Nickname"
            v-model="bankAccountEdits.account_nickname"
            type="string"
            size="large"
            placeholder="Type here"
            showFullText
          />
          <BaseInput
            class="col-span-2"
            :required="true"
            label="Account Type"
            v-model="bankAccountEdits.account_type"
            :options="['Checking', 'Savings']"
            type="select"
            size="large"
            placeholder="Checking"
            showFullText
          />
          <BaseInput
            :required="true"
            label="Routing Number"
            v-model="bankAccountEdits.routing_number"
            type="string"
            size="large"
            placeholder="•••••••••"
            showFullText
          />
          <BaseInput
            :required="true"
            label="Account Number"
            v-model="bankAccountEdits.account_number"
            type="string"
            size="large"
            placeholder="•••••••••"
            showFullText
          />
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="handleAddBankAccount">
          <template #label> Save </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import { Modal, Button, BaseInput, LoadingSpinner } from "@/components";
import {
  useCrudStore,
  useCollectionStore,
  useUIStore,
  useRecordDetailStore,
  useAuthStore,
} from "@/stores";
import { iconMapping } from "@/constants/iconMapping";

const crudStore = useCrudStore();
const collectionStore = useCollectionStore();
const UIStore = useUIStore();
const authStore = useAuthStore();
const recordDetailStore = useRecordDetailStore();

const loading = ref(false);

const bankAccountEdits = ref({
  financial_institution_name: "",
  account_nickname: "",
  account_type: "",
  routing_number: "",
  account_number: "",
});

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  user_id: {
    type: Object,
    required: false,
  },
});

const associated_account = ref(null);

const emit = defineEmits(["close-modal", "account-updated"]);

onMounted(async () => {});

const isSaveDisabled = computed(() => {
  if (
    bankAccountEdits.value.financial_institution_name === "" ||
    bankAccountEdits.value.account_type === "" ||
    bankAccountEdits.value.routing_number === "" ||
    bankAccountEdits.value.account_number === ""
  ) {
    return true;
  } else return false;
});

async function handleAddBankAccount() {
  loading.value = true;
  let new_account = {
    financial_institution_name: bankAccountEdits.value.financial_institution_name,
    account_nickname: bankAccountEdits.value.account_nickname,
    account_type: bankAccountEdits.value.account_type,
    routing_number: collectionStore.encryptField(bankAccountEdits.value.routing_number),
    account_number: collectionStore.encryptField(bankAccountEdits.value.account_number),
    contact_id: associated_account.value._id,
    account_id: associated_account.value.account_id,
    user_id: associated_account.value.user_id,
    created_date: new Date(),
    created_by_id: authStore.currentUser.id,
    created_by_name:
      authStore.currentUser.customData.first_name +
      " " +
      authStore.currentUser.customData.last_name,
    updated_date: new Date(),
    updated_by_id: authStore.currentUser.id,
    updated_by_name:
      authStore.currentUser.customData.first_name +
      " " +
      authStore.currentUser.customData.last_name,
  };

  console.log(new_account);

  var res = await crudStore.insertOne("BankAccounts", new_account);
  console.log(res);
  if (res?.insertedId) {
    var alert = {
      type: "success",
      icon: "BadgeCheck",
      message: "Bank account added successfully",
    };
  } else {
    var alert = {
      type: "error",
      icon: "IconX",
      message: "Failed to add bank account",
    };
  }

  UIStore.animateNotificationAlert(alert);

  loading.value = false;
  emit("account-updated");
  emit("close-modal");
}

// vue watcher to set custodianEdits when open prop changes
watch(props, async (value) => {
  if (value.open) {
    bankAccountEdits.value = {
      financial_institution_name: "",
      account_nickname: "",
      account_type: "",
      routing_number: "",
      account_number: "",
    };

    if (props.user_id) {
      associated_account.value = await crudStore.findOne("Contacts", {
        user_id: props.user_id,
      });
    }
  }
});
</script>
