<template>
  <Modal size="36square" :hideModalTabs="true" @close-modal="$emit('close-modal')">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2">Update Investment</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white flex flex-col gap-4 p-8">
        <Banner v-if="statusError" type="error" hideClose>
          <template #content>
            <div class="flex items-start justify-start">
              <p class="font-normal">{{ statusError }}</p>
            </div>
          </template>
        </Banner>

        <BaseInput label="Date" v-model="date" type="date" size="large" />
        <BaseInput
          label="Redemption Date"
          v-model="redemption_date"
          type="date"
          size="large"
          formatRule="first_payment_date"
          errorMessage="Redemption date must be on the 10th of the month."
        />
        <div
          v-if="recordDetailStore.currentPageDocument.maturity_date <= new Date(redemption_date)"
          class="text-red-500 text-sm"
        >
          Redemption date must be before maturity date
        </div>
        <div
          v-if="
            recordDetailStore.currentPageDocument.partial_redemptions.some(
              (redemption) => new Date(redemption.redemption_date) >= new Date()
            ) && new Date(redemption_date) >= new Date()
          "
          class="text-red-500 text-sm"
        >
          Investment can only have one future partial at a time
        </div>
        <div
          v-if="
            redemption_date &&
            recordDetailStore.currentPageDocument.distribution_window_start_date >=
              new Date(redemption_date)
          "
          class="text-red-500 text-sm"
        >
          Redemption date must be within the distribution window
        </div>
        <BaseInput
          label="Redemption Amount"
          v-model="redemption_amount"
          :compareValue="recordDetailStore.currentPageDocument.amount"
          type="number"
          size="large"
          formatRule="redemption_amount"
          errorMessage="Redemption amount must be less than or equal to the investment amount."
        />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="processPartialRedemption">
          <template #label>
            Partially Redeem ${{ commaSeparateThousands(redemption_amount) }}
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";

import { Modal, Button, BaseInput, Banner } from "@/components";
import { useRecordDetailStore, useCrudStore, useInvestmentStore } from "@/stores";
import { iconMapping } from "@/constants/iconMapping";
import { commaSeparateThousands } from "@/utilities";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const investmentStore = useInvestmentStore();

const emit = defineEmits(["close-modal", "fetch-investments"]);

onMounted(async () => {});

const date = ref(null);
const redemption_date = ref(null);
const redemption_amount = ref(null);

const isSaveDisabled = computed(() => {
  return (
    !redemption_amount.value ||
    !redemption_date.value ||
    redemption_amount.value <= 0 ||
    redemption_amount.value > recordDetailStore.currentPageDocument.amount ||
    redemption_date.value?.split("-")[2] !== "10" ||
    recordDetailStore.currentPageDocument.maturity_date <= new Date(redemption_date.value) ||
    recordDetailStore.currentPageDocument.distribution_window_start_date >=
      new Date(redemption_date.value) ||
    (recordDetailStore.currentPageDocument.partial_redemptions.some(
      (redemption) => new Date(redemption.redemption_date) >= new Date()
    ) &&
      new Date(redemption_date.value) >= new Date()) // if there is a future partial redemption, only allow one future partial redemption
  );
});

async function processPartialRedemption() {
  emit("close-modal");
  let redemption_date_format = new Date(redemption_date.value);
  let partial_redemption = {
    redemption_date: redemption_date_format,
    redemption_amount: Number(redemption_amount.value),
    previous_amount: recordDetailStore.currentPageDocument.post_redemption_amount
      ? recordDetailStore.currentPageDocument.post_redemption_amount
      : recordDetailStore.currentPageDocument.amount,
    previous_discounted_amount: recordDetailStore.currentPageDocument
      .post_redemption_discounted_bond_amount
      ? recordDetailStore.currentPageDocument.post_redemption_discounted_bond_amount
      : recordDetailStore.currentPageDocument.discounted_bond_amount,
  };
  // query for all distributions up to the redemption date and sum up oid
  // set discounted_bond_amount = total distributions - total oid - discounted_bond_amount, only if investment.is_discounted
  // 2. Push that to an array and upsert that array onto the investment
  let query = { _id: recordDetailStore.currentPageDocument._id };
  let update = {
    $push: { partial_redemptions: partial_redemption },
  };
  await crudStore.updateOne("Investments", query, update);
  setTimeout(async () => {
    // wait for processing to finish
    recordDetailStore.updateTic++;
  }, 2000);
  redemption_amount.value = null;
  redemption_date.value = null;
  date.value = null;
}
</script>
