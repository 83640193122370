<template>
  <FilterModal
    :tabs="modalMainTabs"
    :activeTab="activeFilterTab"
    :show="UIStore.isEditFilterModalOpen"
    showFilterOptions
    :customClasses="`${
      UIStore.isEditFilterModalCollapsed ? 'h-24' : 'h-140'
    } z-20 lg:left-20 bottom-8`"
  >
    <template v-slot:filter-modal-header>
      <button
        v-for="mainTab in modalMainTabs"
        :key="mainTab"
        @click="activeFilterTab = mainTab.name"
        type="button"
        class="flex items-center gap-2.5 border-b-3 border-b-secondary-6 p-3 text-sm font-bold"
      >
        <component :is="mainTab.icon" class="h-5.5 w-5.5 fill-primary-4" />
        <span>{{ mainTab.name }}</span>
      </button>
    </template>
    <template v-slot:filter-modal-body>
      <!-- Filters Modal Body -->

      <div
        class="no-scrollbar relative flex h-116 w-full flex-col overflow-y-auto"
        id="record-detail-filter-modal-body"
      >
        <!-- Search Bar -->
        <div class="sticky top-0 z-20 bg-neutral-gray-2 px-3 py-3">
          <BaseInput
            v-model="searchStore.searchTerm_columns"
            :results="filteredPossibleColumns"
            @focus="handleFocus"
            placeholder="Search and Add Filters"
            :label="null"
            type="search"
            size="small"
            customClass="bg-neutral-gray-1"
            ref="searchInput"
          >
            <template #search-dropdown>
              <div v-for="columnGroup in filteredPossibleColumns" :key="columnGroup" class="w-full">
                <div
                  v-if="columnGroup.fields.length"
                  class="sticky top-0 w-full bg-neutral-gray-3 p-2 text-xs font-bold text-primary-6"
                >
                  {{ columnGroup.collection_name.toUpperCase() }}
                </div>
                <div
                  v-for="column in columnGroup.fields"
                  :key="column"
                  @mousedown="addFilter(column)"
                  class="cursor-pointer px-3 py-2 hover:bg-platform-hover-secondary"
                >
                  <span class="text-primary text-sm">
                    {{ column.label }}
                  </span>
                </div>
              </div>
            </template>
          </BaseInput>
          <!-- Category Pills -->
          <div class="mt-3 flex w-full items-center">
            <div class="flex w-full items-center gap-1">
              <Button
                v-for="category in ['Applied', 'Favorites', 'Recents']"
                @click="recordDetailStore.activeFilterCategory = category"
                label
                icon
                size="small"
                variant="default-gray-outlined"
                class="!p-2"
                :class="
                  recordDetailStore.activeFilterCategory === category
                    ? '!border !border-secondary-6'
                    : ''
                "
              >
                <template #label>
                  <p
                    :class="recordDetailStore.activeFilterCategory === category ? 'font-bold' : ''"
                    class="text-xs"
                  >
                    {{ category }}
                  </p>
                </template>
              </Button>
            </div>
          </div>
        </div>
        <LogicalFilters
          v-if="recordDetailStore.activeFilterCategory === 'Applied' && tempFilters.length > 0"
          :dataFilters="tempFilters"
          :searchOptions="recordDetailStore.possibleColumns"
        />
        <FilterHistory
          v-else-if="
            (recordDetailStore.activeFilterCategory === 'Favorites' ||
              recordDetailStore.activeFilterCategory === 'Recents') &&
            filterHistoryToShow.length > 0
          "
          :filterHistory="filterHistoryToShow"
          @updateDataFilters="handleUpdateDataFiltersForHistory($event)"
        />
        <div v-else class="flex grow flex-col items-center justify-center gap-3 pb-8">
          <IconFilter class="h-6 w-6 fill-neutral-gray-6" />
          <p class="max-w-[50%] text-center text-xs text-dark opacity-[.3]">
            No Filters added. Add filters using the search bar.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:filter-modal-footer>
      <div class="flex h-11 items-center justify-end rounded-b-md bg-white px-3">
        <div class="flex gap-2">
          <Button
            @click="UIStore.isEditFilterModalOpen = false"
            label
            variant="default-gray-outlined"
            size="small"
          >
            <template #label>Close</template>
          </Button>
          <Button @click="applyChanges()" label variant="default-filled" size="small">
            <template #label>Apply</template>
          </Button>
        </div>
      </div>
    </template>
  </FilterModal>
  <div class="flex flex-col gap-4 max-w-[100vw]">
    <Header
      class="mx-3 sm:mx-0"
      :class="{
        'sm:border-b border-neutral-gray-4 sm:pb-4': headerSeperator,
      }"
    >
      <template #header-icon>
        <div
          class="p-1 bg-opacity-20 rounded-lg flex items-center justify-center"
          :class="
            customData ? customData.bg_color : recordDetailStore.currentPageConfig.icon_color_bg
          "
        >
          <div
            class="p-1 rounded-md flex items-center justify-center"
            :class="
              customData ? customData.bg_color : recordDetailStore.currentPageConfig.icon_color_bg
            "
          >
            <component
              v-if="!customData || !customData.icon_name"
              :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
              class="w-5.5 h-5.5 fill-white"
            />
            <Icon
              v-else
              :name="customData.icon_name"
              class="w-5.5 h-5.5 text-white"
              :stroke-width="1.5"
            />
          </div>
        </div>
      </template>
      <template #header-title>
        <div class="flex flex-row items-center uppercase font-bold text-xl sm:text-2xl gap-3">
          <slot name="title">
            {{ customData ? customData?.header_name : recordDetailStore.currentPageConfig.plural }}
          </slot>
          <div
            class="hidden sm:flex rounded-2xl bg-opacity-20 text-lg py-2 px-2.5 text-center align-middle leading-4 items-center justify-center font-normal"
            :class="
              customData ? customData.bg_color : recordDetailStore.currentPageConfig.icon_color_bg
            "
          >
            <span>{{ recordDetailStore.tableRecordCount }}</span>
          </div>
        </div>
      </template>

      <template #header-ctas>
        <Button
          v-if="useFilters"
          :variant="blue_border ? 'default-color-outlined' : 'default-gray-outlined'"
          :is-icon-only-on-mobile="true"
          :icon="true"
          :label="true"
          @click="UIStore.isEditFilterModalOpen = true"
        >
          <template #icon> <IconFilter /> </template>
          <template #label> Filters </template>
        </Button>
      </template>

      <template v-slot:additional-ctas><slot name="additional-ctas"></slot></template>
      <template v-slot:filter-inputs><slot name="filter-inputs"></slot></template>
    </Header>
    <div class="max-w-[100vw]">
      <Table
        v-if="recordDetailStore.tableRecordCount > 0"
        :table-data="recordDetailStore.allRecords"
        :headers="recordDetailStore.allRecordsTableHeaders"
        :fieldsWithCustomStyles="props.fieldsWithCustomStyles"
        @setSortHeader="handleSort"
        @selectAll="$emit('selectAll')"
        :isAllChecked="isAllChecked"
        :isResizable="true"
        :actions_always_sticky="actions_always_sticky"
      >
        <!-- Add slots from parent for fieldsWithCustomStyles-->
        <template v-for="(slot, index) of Object.keys($slots)" :key="index" v-slot:[slot]="entry">
          <slot :name="slot" :row="entry"></slot>
        </template>
      </Table>
    </div>
    <Pagination
      v-if="recordDetailStore.tableRecordCount > 0"
      class="p-3"
      :show-count="true"
      :total-category-results="recordDetailStore.tableRecordCount"
      :page-size="recordDetailStore.pageSize"
      :page-num="1"
      @change-page="recordDetailStore.allRecordsPageNumber = $event"
    ></Pagination>
    <div
      v-if="recordDetailStore.tableRecordCount < 1 && !UIStore.isLoading"
      class="flex h-40 flex-col items-center justify-center rounded-b-lg border-platform-outlines bg-white"
    >
      <div class="mb-4">
        <component
          :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          class="h-6 w-6 fill-neutral-gray-8"
        />
      </div>
      <div class="text-sm text-dark">No matching items.</div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  shallowRef,
  onUnmounted,
  ref,
  watch,
  nextTick,
  onBeforeUnmount,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  Header,
  Button,
  Table,
  Pagination,
  FilterModal,
  FilterHistory,
  BaseInput,
  LogicalFilters,
  Icon,
} from "@/components";
import {
  IconPlus,
  IconVisibility,
  IconX,
  IconDelete,
  IconEdit,
  IconRefresh,
  IconSigner,
  IconFilter,
} from "@/components/icons";
import { useRecordDetailStore } from "@/stores/useRecordDetailStore";
import { useUIStore } from "../../stores/useUIStore";
import { useSearchStore } from "@/stores/useSearchStore";
import { commaSeparateThousands } from "@/utilities";
import { iconMapping } from "../../constants/iconMapping";

const recordDetailStore = useRecordDetailStore();

const modalOpen = ref(false);

const route = useRoute();
const modalData = ref(null);
const activeFilterTab = ref("Filters");
const modalMainTabs = shallowRef([{ name: "Filters", icon: IconFilter }]);
const iconBg = ref("");
const iconFill = ref("");
const iconRef = ref("");
const router = useRouter();
const UIStore = useUIStore();
const searchStore = useSearchStore();
const dataFilters = ref([]);
const tempFilters = ref([]);
const filteredPossibleColumns = ref(JSON.parse(JSON.stringify(recordDetailStore.possibleColumns)));
const filterHistoryToShow = computed(() => {
  if (!recordDetailStore?.filterHistory?.length) return [];
  if (recordDetailStore.activeFilterCategory === "Favorites") {
    return recordDetailStore.filterHistory.filter((filter) => filter.is_favorited);
  }
  return recordDetailStore.filterHistory;
});
const searchInput = ref(null);
const blue_border = ref(false);
const moreDropdownOpenFor = ref({});

const props = defineProps({
  fieldsWithCustomStyles: {
    type: Array,
    default: () => [],
  },
  showKanbanView: {
    type: Boolean,
    default: false,
  },
  isAllChecked: {
    type: Boolean,
    default: false,
  },
  actions_always_sticky: {
    type: Boolean,
    default: false,
  },
  useFilters: {
    type: Boolean,
    default: true,
  },
  customData: {
    type: [Object, null],
    default: null,
  },
  keep_actions_on_sort: {
    type: Boolean,
    default: false,
  },
  hide_checkboxes_on_sort: {
    type: Boolean,
    default: false,
  },
  headerSeperator: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["selectAll", "row-clicked", "sortingTable"]);

onMounted(async () => {
  handleCustomScroll();
  filteredPossibleColumns.value = JSON.parse(JSON.stringify(recordDetailStore.possibleColumns));
});

onBeforeUnmount(() => {});

onUnmounted(() => {});

// Watchers

watch(
  () => searchStore.searchTerm_columns,
  function () {
    recordDetailStore.possibleColumns.forEach((columnGroup, index) => {
      filteredPossibleColumns.value[index].fields = columnGroup.fields.filter((column) =>
        column.label
          .toLowerCase()
          .replace(/_/g, " ")
          .includes(searchStore.searchTerm_columns.toLowerCase())
      );
    });
  }
);

watch(
  () => UIStore.isEditFilterModalOpen,
  () => {
    if (!UIStore.isEditFilterModalOpen) {
      //recordDetailStore.activeFilterCategory = "Applied";
      tempFilters.value = JSON.parse(JSON.stringify(dataFilters.value));
    }
  }
);

function handleSort(sortHeader) {
  recordDetailStore.setSortHeader(
    sortHeader,
    props.keep_actions_on_sort,
    props.hide_checkboxes_on_sort
  );

  emit("sortingTable");
}

function handleCustomScroll() {
  // Wait for the next tick to ensure the DOM is ready
  nextTick(() => {
    const customScrollbarContainer = document.querySelector("#custom-scrollbar-container");
    const customScrollbarThumb = document.querySelector("#custom-scrollbar-thumb");
    const tableContainer = document.querySelector("#table-container");

    const padding = 4;
    if (!tableContainer || !customScrollbarThumb || !customScrollbarContainer) return;
    tableContainer.classList.add("no-scrollbar");
    let isDragging = false;
    let hasDragged = false; // Flag to indicate if there has been dragging
    let initialScrollLeft = 0;

    const updateThumbPosition = () => {
      const containerWidth = customScrollbarContainer.clientWidth - 2 * padding;
      const scrollRatio =
        tableContainer.scrollLeft / (tableContainer.scrollWidth - tableContainer.clientWidth);
      const thumbWidthPercent =
        (tableContainer.clientWidth / tableContainer.scrollWidth) * containerWidth;
      customScrollbarThumb.style.width = `${thumbWidthPercent}px`;
      const thumbPosition = scrollRatio * (containerWidth - thumbWidthPercent) + padding;
      customScrollbarThumb.style.left = `${thumbPosition}px`;
    };

    const onResize = () => {
      updateThumbPosition();
    };

    updateThumbPosition();
    window.addEventListener("resize", onResize);
    tableContainer.addEventListener("scroll", updateThumbPosition);

    const onMouseMoveThumb = (dx) => {
      const scrollableWidth = tableContainer.scrollWidth - tableContainer.clientWidth;
      const scrollRatio = (initialScrollLeft + dx) / scrollableWidth;

      tableContainer.scrollLeft = scrollRatio * scrollableWidth;
      updateThumbPosition();
    };

    customScrollbarThumb.addEventListener("mousedown", (event) => {
      event.preventDefault();
      isDragging = true;
      hasDragged = false; // Reset the hasDragged flag on new drag initiation
      const startX = event.pageX;
      initialScrollLeft = tableContainer.scrollLeft;

      const onMouseMove = (event) => {
        if (!isDragging) return;
        hasDragged = true; // Set hasDragged to true as the thumb is being dragged
        const dx = event.pageX - startX;
        onMouseMoveThumb(dx);
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        isDragging = false;
        setTimeout(() => (hasDragged = false), 50); // Reset the hasDragged flag shortly after the drag ends
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    });

    customScrollbarContainer.addEventListener("click", (event) => {
      if (hasDragged) return; // Ignore clicks that are actually the end of a drag

      const containerRect = customScrollbarContainer.getBoundingClientRect();
      const clickPosition = event.clientX - containerRect.left; // Click position relative to the scrollbar container
      const containerWidth = customScrollbarContainer.clientWidth - 2 * padding;
      // Calculate where to scroll based on the click position
      const thumbWidth = customScrollbarThumb.offsetWidth;
      const clickPositionRatio = (clickPosition - thumbWidth / 2) / (containerWidth - thumbWidth);
      const scrollableWidth = tableContainer.scrollWidth - tableContainer.clientWidth;

      tableContainer.scrollTo({
        left: scrollableWidth * clickPositionRatio,
        behavior: "smooth",
      });
    });
  });
}

// Filtering

function addFilter(filter) {
  if (!dataFilters.value.find((e) => e.field_name === filter.label)) {
    let logicalFilter = {
      operator: UIStore.operators.find((operator) => operator.name === "Equal To"),
      value: filter.field_type === "boolean" ? { name: "Yes", value: true } : null,
      logical_operator: "and",
      label: filter.label,
      data_type: filter.field_type,
      field_name: filter.field_name,
      is_object_id: filter.is_object_id,
      collection_name: filter.collection_name,
      dropdown_options: filter.dropdown_options ? filter.dropdown_options : [],
      internal_sets: [],
    };

    if (recordDetailStore.activeFilterCategory !== "Applied") {
      recordDetailStore.activeFilterCategory = "Applied";
    }

    tempFilters.value.push(logicalFilter);
  }

  searchStore.searchTerm_columns = "";
}

function handleFocus() {
  if (!searchStore?.searchTerm_columns?.length) {
    filteredPossibleColumns.value = JSON.parse(JSON.stringify(recordDetailStore.possibleColumns));
  }
}

async function applyChanges() {
  UIStore.isLoading = true;
  recordDetailStore.allRecordsPageNumber = 1;
  const collectionName = JSON.parse(JSON.stringify(recordDetailStore.currentPageConfig.collection));

  dataFilters.value = JSON.parse(JSON.stringify(tempFilters.value));
  if (dataFilters.value.length > 0) {
    // await recordDetailStore.storeFilterHistoryForUser(dataFilters.value);
    let collection = recordDetailStore.currentPageConfig.collection;
    // recordDetailStore.filterHistory =
    //   await recordDetailStore.fetchUsersFilterHistoryForCollection(collection);
  }

  // reportStore.selectedReport.collection_name = collectionName;
  // reportStore.selectedReport.filters = JSON.parse(JSON.stringify(dataFilters.value));

  //this has to be an await function - needs to complete so that fetchTableRecords has access to the match stage created here
  // await recordDetailStore.queryForData(dataFilters.value, true);
  recordDetailStore.filterPipeline = recordDetailStore.parseFilters(dataFilters.value, false);
  if (dataFilters.value.length > 0) {
    blue_border.value = true;
  } else {
    blue_border.value = false;
  }

  const isMarketingPage = route.path.includes("marketing");

  let actions_boolean = true;

  if (isMarketingPage) {
    actions_boolean = false;
  }

  await recordDetailStore.fetchTableRecords(actions_boolean, isMarketingPage);
  // UIStore.isEditFilterModalOpen = false;
  activeFilterTab.value = null;
  UIStore.isLoading = false;
  handleCustomScroll();
}
</script>
