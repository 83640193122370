<template>
  <Modal size="36square" :open="open" @close-modal="$emit('close-modal')">
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">{{ isEdit ? "Edit" : "Add" }} Custodian</p>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white gap-4 px-8 pb-8 pt-4 grid grid-cols-2 overflow-y-auto">
        <BaseInput
          v-for="field in schemaStore.all_custodian_schema"
          :type="field.field_type"
          :label="field.label"
          :placeholder="field.label"
          v-model="activeCustodian[field.field_name]"
          size="large"
          :options="
            field.field_name === 'account_type'
              ? ['Checking', 'Savings']
              : field.field_name === 'state'
                ? ALL_50_STATES
                : []
          "
          :required="field.field_name !== 'street2'"
        />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="handleAddEditCustodian">
          <template #label>
            {{ !isEdit ? "Add Custodian" : "Save Custodian" }}
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";

import { Modal, Button, BaseInput } from "@/components";
import { useUIStore, useAuthStore, useCrudStore, useSchemaStore } from "@/stores";
import ALL_50_STATES from "@/constants/states";

const UIStore = useUIStore();
const authStore = useAuthStore();
const crudStore = useCrudStore();
const schemaStore = useSchemaStore();

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  custodianForEdit: {
    type: [Object, null],
    default: null,
  },
});

const emit = defineEmits(["close-modal", "refetch-custodians"]);

const custodianSchema = ref([]);
const activeCustodian = ref({});

watch(
  () => schemaStore.all_custodian_schema,
  () => {
    schemaStore.all_custodian_schema.map((field) =>
      field.field_type === "boolean"
        ? (activeCustodian.value[field.field_name] = false)
        : (activeCustodian.value[field.field_name] = "")
    );
  }
);

watch(
  () => props.custodianForEdit,
  () => {
    if (props.custodianForEdit) {
      activeCustodian.value = props.custodianForEdit;
      activeCustodian.value.state = ALL_50_STATES.find(
        (state) => state.abbr === activeCustodian.value.state
      );
    }
  }
);

const isSaveDisabled = computed(() => {
  return custodianSchema.value
    .filter((f) => f.field_name !== "street2")
    .some((field) => !activeCustodian.value[field.field_name]);
});

async function handleAddEditCustodian() {
  emit("close-modal");
  setTimeout(() => {
    UIStore.animateNotificationAlert({
      icon: "BadgeCheck",
      message: `Successfully ${!props.isEdit ? "added" : "updated"} custodian!`,
    });
  }, 300);
  if (!props.isEdit) {
    activeCustodian.value.state = activeCustodian.value.state.abbr;
    activeCustodian.value.total_invested = 0;
    activeCustodian.value.total_bonds = 0;
    activeCustodian.value.total_interest_paid = 0;
    activeCustodian.value.created_date = new Date();
    activeCustodian.value.created_by_id = authStore.currentUser.id;
    activeCustodian.value.created_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
    activeCustodian.value.updated_date = new Date();
    activeCustodian.value.updated_by_id = authStore.currentUser.id;
    activeCustodian.value.updated_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
    const { insertedId } = await crudStore.insertOne("Custodians", activeCustodian.value);
  } else {
    activeCustodian.value.state = activeCustodian.value.state.abbr;
    activeCustodian.value.updated_date = new Date();
    activeCustodian.value.updated_by_id = authStore.currentUser.id;
    activeCustodian.value.updated_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
    await crudStore.updateOne(
      "Custodians",
      { _id: activeCustodian.value._id },
      { $set: activeCustodian.value }
    );
  }
  activeCustodian.value = {};
  emit("refetch-custodians");
}
</script>
