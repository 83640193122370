<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ createInvestmentStore.currentStep + 1 }}: Owner Information
    </div>
  </div>
  <div class="flex flex-col">
    <div
      v-for="(group, index) in personalInfo"
      :key="index"
      :class="{
        'py-8 border-b border-platform-outlines': index !== personalInfo.length - 1,
        'py-8': index === personalInfo.length - 1,
      }"
    >
      <div class="font-semibold text-lg mb-4">
        {{ group.group_name }}
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div v-for="field of group.fields">
          <BaseInput
            :required="field.is_required"
            :disabled="
              field.read_only ||
              (createInvestmentStore.owner_has_investments &&
                schemaStore.admin_disabled_owner_contact_fields.includes(field.field_name))
            "
            :label="field.label"
            v-if="!field.is_association_field && !field.belongs_to_nested_object"
            v-model="createInvestmentStore.current_owner_contact[field.field_name]"
            :formatRule="field.field_name"
            :errorMessage="field.error_message"
            :type="field.field_type"
            size="large"
            :options="field.dropdown_options"
            customClass="bg-neutral-gray-1"
          ></BaseInput>
          <BaseInput
            :required="field.is_required"
            :disabled="
              field.read_only ||
              (createInvestmentStore.owner_has_investments &&
                schemaStore.admin_disabled_owner_contact_fields.includes(field.field_name))
            "
            :formatRule="field.field_name"
            :errorMessage="field.error_message"
            :options="field.dropdown_options"
            :label="field.label"
            v-if="!field.is_association_field && field.belongs_to_nested_object"
            v-model="
              createInvestmentStore.current_owner_contact[field.nested_object_name][
                field.field_name
              ]
            "
            :type="field.field_type"
            size="large"
            customClass="bg-neutral-gray-1"
          ></BaseInput>

          <div
            v-if="field.field_name === 'ssn' && ssn_is_invalid"
            class="text-functional-error-default text-sm my-1"
          >
            Invalid SSN. SSN can not match the SSN of any other Joint contacts or Beneficial Owner
            contacts tied to this owner.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import { useRecordDetailStore, useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { BaseInput } from "@/components";

const recordDetailStore = useRecordDetailStore();
const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();
const personalInfo = ref(
  createInvestmentStore.selected_offering.type === "Reg D"
    ? schemaStore.accredited_owner_contact_personal_info_schema
    : schemaStore.non_accredited_owner_contact_personal_info_schema
);

const ssn_is_invalid = computed(() => {
  const currentOwnerContact = createInvestmentStore.current_owner_contact;

  const invalidSSNs = createInvestmentStore.invalid_ssn_array;

  //the current_owner_contacts ssn does not match recordDetailStore.currentPageDocument.ssn AND it is included int the invalidSSNs array
  let val =
    invalidSSNs.includes(currentOwnerContact.ssn) &&
    currentOwnerContact.ssn !== recordDetailStore.currentPageDocument.ssn;

  createInvestmentStore.owner_ssn_is_invalid = val;
  return val;
});
</script>
