<template>
  <div v-if="titleTransferStore.offerings" class="flex flex-col h-full justify-between">
    <div class="m-6 md:m-5.5">
      <div>
        <component
          :is="getStepComponent(titleTransferStore.investmentSteps[titleTransferStore.currentStep])"
          :key="titleTransferStore.currentStep"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTitleTransferStore } from "@/stores";

import CompleteTitleTransferInvestment from "./CompleteTitleTransferInvestment.vue";
import JointInfo from "./JointInfo.vue";
import EntityInfo from "./EntityInfo.vue";
import SelectJoint from "./SelectJoint.vue";
import SelectEntity from "./SelectEntity.vue";
import TransferTypeSelection from "./TransferTypeSelection.vue";
import TransferorOwner from "./TransferorOwner.vue";
import SplitInvestment1 from "./SplitInvestment1.vue";
import CompleteSplitInvestment from "./CompleteSplitInvestment.vue";
import TransfereeOwner from "./TransfereeOwner.vue";
import CompleteOwnerTransferInvestment from "./CompleteOwnerTransferInvestment.vue";
import OGTitleTransferPage1 from "./OGTitleTransferPage1.vue";

const titleTransferStore = useTitleTransferStore();

const stepComponents = {
  "Type Selection": TransferTypeSelection,
  "Complete Title Transfer": CompleteTitleTransferInvestment,
  "Select Joint Contact": SelectJoint,
  "Select Entity Account": SelectEntity,
  "Joint Info": JointInfo,
  "Entity Info": EntityInfo,
  "Transferor Owner": TransferorOwner,
  "Split Investment": SplitInvestment1,
  "Transferee Owner": TransfereeOwner,
  "Complete Split Investment": CompleteSplitInvestment,
  "Complete Owner Transfer": CompleteOwnerTransferInvestment,
  "First Transfer Step": OGTitleTransferPage1,
};

const getStepComponent = (stepName) => {
  return stepComponents[stepName] || null;
};
</script>
