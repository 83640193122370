import { useAuthStore, useCrudStore, useUIStore, useCreateInvestmentStore } from "@/stores";

const useFileUtility = () => {
  const authStore = useAuthStore();
  const crudStore = useCrudStore();
  const createInvestmentStore = useCreateInvestmentStore();
  const UIStore = useUIStore();
  const baseURL = import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT;

  const fetchFileForPreview_R2 = async (file, returnFile) => {
    UIStore.isProcessingFiles = true;
    try {
      // Make a GET request to our R2 worker to fetch the file
      const response = await fetch(`${baseURL}/get-file/${file.r2_key}`, {
        method: "GET",
        headers: {
          user_id: authStore.currentUser.id,
          session_id: authStore.currentUser.session_id,
        },
      });
      // if (response.status == 401) {
      //   UIStore.badTokenModalOpen = true;
      // }
      if (!response.ok) {
        throw new Error(`Error downloading file: ${response.statusText}`);
      }

      let blob;
      if (file?.is_base64) {
        const base64String = await response.text(); // Assuming the response is text when base64
        const binary = atob(base64String.replace(/\s/g, ""));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);

        for (let i = 0; i < len; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        blob = new Blob([view], { type: file.file_type || "application/octet-stream" });
      } else {
        blob = await response.blob();
      }

      const blobWithType = new Blob([blob], { type: file.file_type });
      const url = URL.createObjectURL(blobWithType);
      return url;
    } catch (err) {
      console.log({
        error: err,
        error_type: "Error",
        error_summary: `Error downloading file  
        - Key: ${file.r2_key} `,
        error_source: "useFileUtility - downloadFile_R2",
      });
    }
    UIStore.isProcessingFiles = false;
  };
  const downloadFile_R2 = async (file, returnFile) => {
    let keep_drawer_open = false;
    if (createInvestmentStore.investment_drawer_open == true) {
      keep_drawer_open = true;
    } else {
      keep_drawer_open = false;
    }

    UIStore.isProcessingFiles = true;
    const isBase64Download = file?.is_base64;
    try {
      // Make a GET request to our R2 worker to fetch the file
      const response = await fetch(`${baseURL}/get-file/${file.r2_key}`, {
        method: "GET",
        headers: {
          user_id: authStore.currentUser.id,
          session_id: authStore.currentUser.session_id,
        },
      });
      // if (response.status == 401) {
      //   UIStore.badTokenModalOpen = true;
      // }
      if (!response.ok) {
        throw new Error(`Error downloading file: ${response.statusText}`);
      }

      let blob;
      if (isBase64Download) {
        const base64String = await response.text(); // Assuming the response is text when base64
        const binary = atob(base64String.replace(/\s/g, ""));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);

        for (let i = 0; i < len; i += 1) {
          view[i] = binary.charCodeAt(i);
        }

        blob = new Blob([view], { type: file.file_type || "application/octet-stream" });
      } else {
        blob = await response.blob();
      }

      const blobWithType = new Blob([blob], { type: file.file_type });
      const url = URL.createObjectURL(blobWithType);

      const downloadedFile = new File([blob], file.file_name, {
        type: file.file_type || "application/octet-stream",
      });

      if (returnFile) {
        UIStore.isProcessingFiles = false;
        return downloadedFile;
      }

      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = url;

      downloadLink.download = file.file_name;

      downloadLink.click();
      if (keep_drawer_open) {
        createInvestmentStore.investment_drawer_open = true;
      }
    } catch (err) {
      console.log({
        error: err,
        error_type: "Error",
        error_summary: `Error downloading file  
        - Key: ${file.r2_key} `,
        error_source: "useFileUtility - downloadFile_R2",
      });
      UIStore.animateNotificationAlert({
        type: "error",
        message: `Failed to download document`,
        // subText: "Failed download",
      });
    }

    UIStore.isProcessingFiles = false;
  };
  const uploadFile_R2 = async ({ file, type, existingKey = null, details = {} }) => {
    UIStore.isProcessingFiles = true;
    const formData = new FormData();
    const createdDetails = {
      created_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
      created_by_id: authStore.currentUser.customData.user_id,
      updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
      updated_by_id: authStore.currentUser.customData.user_id,
    };

    // Append all needed form data to the formData object

    const fileSize = file.size;
    try {
      // For file less than 200mb, use simple upload
      let isSimpleUpload = fileSize <= 200 * 1024 * 1024;
      if (isSimpleUpload) {
        // 4.95 gigabytes
        let response;
        try {
          formData.append("file", file);
          formData.append("createdDetails", JSON.stringify(createdDetails));
          formData.append("documentDetails", JSON.stringify(details));
          formData.append("type", type);
          if (existingKey) formData.append("existingKey", existingKey);

          response = await fetch(`${baseURL}/upload-file`, {
            method: "POST",
            body: formData,
            headers: {
              user_id: authStore.currentUser.id,
              session_id: authStore.currentUser.session_id,
            },
          });

          if (response.status == 401) {
            UIStore.badTokenModalOpen = true;
          }
        } catch (err1) {
          try {
            let file_b64 = await fileToBase64(file);

            let additional_details = {
              file_type: file.type,
              file_name: file.name,
              ...createdDetails,
              ...details,
              type: type,
            };

            let formData = new FormData();
            formData.append("file", file_b64);
            formData.append("additional_details", JSON.stringify(additional_details));

            response = await fetch(`${baseURL}/upload-bad-file-as-b64`, {
              method: "POST",
              body: formData,
              headers: {
                user_id: authStore.currentUser.id,
                session_id: authStore.currentUser.session_id,
              },
            });

            // ORIGIONAL CODE
            // let file_b64 = await fileToBase64(file);

            // let upload_obj = {
            //   file: file_b64,

            //   file_type: file.type,
            //   file_name: file.name,
            //   ...createdDetails,
            //   ...details,
            //   type: type,
            // };
            // response = await fetch(`${baseURL}/upload-bad-file-as-b64`, {
            //   method: "POST",
            //   body: JSON.stringify(upload_obj),
            //   headers: {
            //     user_id: authStore.currentUser.id,
            //     session_id: authStore.currentUser.session_id,
            //     "Content-Type": "application/json",
            //   },
            // });
            // if (response.status == 401) {
            //   UIStore.badTokenModalOpen = true;
            // }
          } catch (err2) {
            throw new Error(`Error uploading file as b64`, err2);
          }
        }
        // console.log("response", response);
        // let val = await response.json();
        // console.log("val", val);
        const { key, upsertResponse } = await response.json();

        console.log("key", key);
        console.log("upsertResponse", upsertResponse);

        if (key && upsertResponse) {
          const query = { r2_key: key };
          const newDocument = await crudStore.findOne("Documents", query);
          UIStore.isProcessingFiles = false;
          return newDocument;
        }
      } else {
        // If the file size is greater than 200mb, use multipart upload
        // Start the multipart upload and get the uploadId and key
        const startResponse = await fetch(`${baseURL}/upload-file?action=mpu-create`, {
          method: "POST",
          headers: {
            user_id: authStore.currentUser.id,
            session_id: authStore.currentUser.session_id,
          },
        });

        // if (startResponse.status == 401) {
        //   UIStore.badTokenModalOpen = true;
        // }
        const { uploadId, key } = await startResponse.json();

        if (!uploadId || !key) throw new Error("Error starting multipart upload");

        // Divide the file into chunks
        const chunkSize = 50 * 1024 * 1024; //  50mb at a time
        const chunks = [];
        for (let i = 0; i < file.size; i += chunkSize) {
          chunks.push(file.slice(i, i + chunkSize));
        }

        const uploadChunkResponses = [];
        for (let index = 0; index < chunks.length; index++) {
          const chunk = chunks[index];
          try {
            const url = `${baseURL}/upload-part?action=mpu-uploadpart&uploadId=${uploadId}&partNumber=${
              index + 1
            }&mpu_key=${encodeURIComponent(key)}`;
            const response = await fetch(url, {
              method: "PUT",
              headers: {
                user_id: authStore.currentUser.id,
                session_id: authStore.currentUser.session_id,
              },
              body: chunk,
            });
            // if (response.status == 401) {
            //   UIStore.badTokenModalOpen = true;
            // }
            // Check if the response is successful
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(`Error uploading part ${index + 1}: ${errorText}`);
            } else {
              const responseData = await response.json();
              uploadChunkResponses.push({
                success: true,
                index: index + 1,
                etag: responseData.etag,
              });
            }
          } catch (err) {}
        }

        const completeResponse = await fetch(
          `${baseURL}/upload-file?action=mpu-complete&uploadId=${uploadId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              user_id: authStore.currentUser.id,
              session_id: authStore.currentUser.session_id,
            },
            body: JSON.stringify({
              parts: uploadChunkResponses.map((response) => ({
                partNumber: response.index,
                etag: response.etag,
              })),
              mpu_key: key,
              file_name: file.name,
              file_type: file.type,
              mpu_type: type,
              created_details: createdDetails,
              document_details: details,
              existing_key: existingKey,
            }),
          }
        );

        // if (completeResponse.status == 401) {
        //   UIStore.badTokenModalOpen = true;
        // }

        if (!completeResponse.ok) {
          UIStore.animateNotificationAlert({
            type: "error",
            message: `Failed to Upload Document.`,
            // subText: "Failed upload",
          });
          let result = await completeResponse.json();
          throw new Error(`Error completing upload: ${completeResponse.statusText}`);
        } else {
          let result = await completeResponse.json();
          if (result.mpu_key) {
            const query = { r2_key: result.mpu_key };
            const newDocument = await crudStore.findOneRecord("Documents", query);
            UIStore.isProcessingFiles = false;
            return newDocument;
          }
        }
      }
    } catch (err) {
      console.error(err);
      let alert = {
        type: "error",
        message: "Failed to upload document",
      };
      UIStore.animateNotificationAlert(alert);
      UIStore.isProcessingFiles = false;
    }
  };
  const deleteFile_R2 = async (file) => {
    UIStore.isProcessingFiles = true;
    try {
      await crudStore.deleteOne("Documents", { r2_key: file.r2_key });

      //TODO: temnporary fix to delete file from R2 - should move to doc trigger

      const response = await fetch(`${baseURL}/delete-file/${file.r2_key}`, {
        method: "DELETE",
        headers: {
          user_id: authStore.currentUser.id,
          session_id: authStore.currentUser.session_id,
        },
      });

      if (!response.ok) {
        console.log(`Error deleting file: ${response.statusText}`);
      } else {
        console.log(`File deleted successfully`);
      }
      UIStore.isProcessingFiles = false;
      return;
    } catch (err) {
      console.log({
        error: err,
        error_type: "Error",
        error_summary: `Error deleting file - ${file.file_name}`,
        error_source: "useFileUtility - deleteFile_R2",
      });
      UIStore.isProcessingFiles = false;
    }
  };
  const getFile_R2 = async (file) => {
    //In Ark this is maily used for getting a csv so that the response can be converted to text
    UIStore.isProcessingFiles = true; // Assume UIStore is available in your context
    try {
      // Make a GET request to the R2 worker to fetch the file
      const response = await fetch(`${baseURL}/get-file/${file.r2_key}`, {
        method: "GET",
        headers: {
          user_id: authStore.currentUser.id,
          session_id: authStore.currentUser.session_id,
        },
      }); // baseURL should be defined in your environment

      // if (response.status == 401) {
      //   UIStore.badTokenModalOpen = true;
      // }

      if (!response.ok) {
        // If the server responds with an error, throw an exception
        throw new Error(`Error fetching file: ${response.statusText}`);
      }

      // Create a Blob from the response
      const blob = await response.blob();

      // Optionally, wrap the Blob in a File object if file metadata is important for your use case
      const fileObject = new File([blob], file.file_name, {
        type: file.file_type || "application/octet-stream",
      });

      // Operation was successful, reset the processing indicator
      UIStore.isProcessingFiles = false;

      // Return the File object
      return fileObject;
    } catch (err) {
      // Handle any errors that occurred during the fetch operation

      console.log({
        error: err,
        error_type: "Error",
        error_summary: `Error fetching file - Key: ${file.r2_key}`,
        error_source: "useFileUtility - getFile_R2",
      });
      UIStore.isProcessingFiles = false;
      throw err;
    }
  };
  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const base64String = e.target.result.split(",")[1]; // Extract the Base64 part from the Data URL
        resolve(base64String);
      };

      reader.onerror = function (e) {
        reject(new Error("Error reading file: " + e.target.error));
      };

      reader.readAsDataURL(file); // Read the file content as a Data URL
    });
  };
  const parseCSV = async (file) => {
    const text = await file.text();
    const lines = text.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());
    const jsonArray = lines.slice(1).map((line) => {
      const values = line.split(",").map((value) => value.trim());
      return headers.reduce((obj, header, index) => {
        obj[header] = values[index];
        return obj;
      }, {});
    });

    return jsonArray;
  };

  return {
    downloadFile_R2,
    getFile_R2,
    fetchFileForPreview_R2,
    uploadFile_R2,
    deleteFile_R2,
    parseCSV,
  };
};

export default useFileUtility;
