<template>
  <div class="p-5" v-if="pageNum == 1">
    <div class="flex flex-col gap-y-5">
      <Button size="large" @click="getRandomDoc" label>
        <template #label>Find Random Document</template>
      </Button>
      <div class="text-xs w-full text-center">--- OR ---</div>
      <div class="flex flex-col gap-4 bg-gray-100 p-4 rounded">
        <div class="flex items-end gap-2">
          <BaseInput
            v-model="r2_key_input"
            label="Find By R2 Key"
            placeholder="Enter R2 Key"
          ></BaseInput>
          <Button @click="findDocByKey" label>
            <template #label>Find Document</template>
          </Button>
        </div>

        <div class="flex text-sm" v-for="sample in samples" :key="key">
          <label class="font-bold text-gray-700 mr-1">{{ sample.file_type }}:</label>
          <span class="text-gray-600 break-words">{{ sample.r2_key }}</span>
        </div>
      </div>

      <div class="text-xs w-full text-center">--- OR ---</div>

      <div>
        <div>
          <input @change="handleUpload" ref="fileInputRef" type="file" hidden />
          <div
            class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0"
            :class="dragging ? 'border-blue-500 bg-blue-100' : 'bg-neutral-gray-4'"
            @drop="drop"
            @dragover.prevent="dragging = true"
            @dragenter="dragging = true"
            @dragleave="dragging = false"
          >
            <IconX class="h-6 w-6 fill-cTeal" />
            <p v-if="uploadState === 'idle'">Drag the file here or click upload</p>
            <p v-else>{{ editName ? editName : uploadedFile?.name }}</p>
            <div v-if="uploadState === 'idle'" class="mt-4">
              <Button @click="chooseFiles" label>
                <template #label>Upload File</template>
              </Button>
            </div>
            <div
              v-if="uploadState === 'uploading'"
              class="mt-4 flex w-full flex-col items-center justify-center"
            >
              <Button @click="cancelUpload" icon label variant="warning-color-outlined">
                <template #icon><IconX class="fill-primary-6" /></template>
                <template #label>Cancel Upload</template>
              </Button>
            </div>

            <div
              v-if="uploadState === 'completed'"
              class="mt-4 flex w-full flex-col items-center justify-center"
            >
              <Button @click="cancelUpload" icon label variant="default-gray-outlined">
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-4.5 w-4.5 stroke-functional-error-default"
                    :stroke-width="2"
                /></template>
                <template #label>Remove File</template>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-5 flex flex-col gap-y-2" v-else>
    <div class="flex w-full justify-between py-2">
      <Button @click="reset" label> <template #label>Reset</template> </Button>

      <div class="gap-2 flex">
        <Button @click="downloadFile" label> <template #label>Download File</template> </Button
        ><Button @click="deleteFile" label> <template #label>Delete File</template> </Button>
      </div>
    </div>

    <div class="bg-gray-200 rounded p-2 overflow-y-scroll">
      <div>
        <div class="flex" v-for="(value, key) in file_doc" :key="key">
          <label class="font-bold text-gray-700 mr-1">{{ formatKey(key) }}:</label>
          <span class="text-gray-600 break-words">{{ value }}</span>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div
          v-if="
            file_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          "
        >
          <div
            class="rounded border border-platform-outlines bg-white p-4"
            v-html="file_data"
          ></div>
        </div>

        <Table
          v-else-if="file_type === 'text/csv'"
          :tableData="file_data"
          :headers="file_data_headers"
          :rounded_bottom="false"
          class="w-full overflow-auto border"
        >
        </Table>
        <div v-else class="rounded bg-gray-800 p-1">
          <object
            :data="file_data"
            :type="file_type"
            width="100%"
            height="700"
            class="rounded"
          ></object>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { Button, Modal, BaseInput, Table } from "@/components";
import { IconX, IconDelete, IconCheckmark, IconPlus } from "@/components/icons";
import { Icon } from "@/components";
import { useCrudStore, useUIStore } from "@/stores";
import useFileUtility from "@/composables/useFileUtility";
import mammoth from "mammoth";
const {
  downloadFile_R2,
  getFile_R2,
  fetchFileForPreview_R2,
  uploadFile_R2,
  deleteFile_R2,
  parseCSV,
} = useFileUtility();

const crudStore = useCrudStore();
const UIStore = useUIStore();

const fileInputRef = ref(null);
const uploadedFile = ref(null);
const file_id = ref(null);
const file_data = ref(null);
const file_data_headers = ref([]);
const file_doc = ref({});
const file_name = ref("");
const file_r2_key = ref("");
const r2_key_input = ref("");
const file_type = ref("");
const samples = ref([]);
const editName = ref("");
const dragging = ref(false);
const uploadState = ref("idle"); // 'idle', 'uploading', 'completed'
const uploadProgress = ref(0);
const pageNum = ref(1);
let uploadTimeout = null;

onMounted(async () => {
  let sample_aggregation = [
    {
      // Group by file_type and pick the first r2_key for each file_type
      $group: {
        _id: "$file_type",
        r2_key: { $first: "$r2_key" },
      },
    },
    {
      // Project the fields into the desired format
      $project: {
        _id: 0,
        file_type: "$_id",
        r2_key: 1,
      },
    },
  ];
  samples.value = await crudStore.aggregate("Documents", sample_aggregation);
});

async function handleUploadFinal() {
  let newFileDocument = null;

  newFileDocument = await uploadFile_R2({
    file: uploadedFile.value,
    type: "Sub Agreements",
    existingKey: null,
    details: {
      test_detail: "test",
    },
  });

  if (newFileDocument) {
    await setUpPage2(newFileDocument);
  }
}

async function setUpPage2(newFileDocument) {
  file_doc.value = newFileDocument;
  file_id.value = newFileDocument._id;
  file_r2_key.value = newFileDocument.file_r2_key;
  file_name.value = newFileDocument.file_name;
  file_type.value = newFileDocument.file_type;

  if (file_type.value == "text/csv") {
    let csv_file = await getFile_R2(newFileDocument);
    let returned_json = await parseCSV(csv_file);
    let transformedRecords = UIStore.transformRecords(returned_json);
    file_data.value = transformedRecords;

    for (let key in returned_json[0]) {
      file_data_headers.value.push({ label: key, field_name: key });
    }
  } else if (
    file_type.value == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    let fetched_file = await fetchFileForPreview_R2(newFileDocument);
    const response = await fetch(fetched_file);
    const arrayBuffer = await response.arrayBuffer();
    const result = await mammoth.convertToHtml({ arrayBuffer });
    file_data.value = result.value;
  } else {
    file_data.value = await fetchFileForPreview_R2(newFileDocument);
  }

  pageNum.value = 2;
}

async function findDocByKey() {
  const query = { r2_key: r2_key_input.value };
  const newDocument = await crudStore.findOne("Documents", query);
  if (newDocument) {
    await setUpPage2(newDocument);
  }
}

async function getRandomDoc() {
  const newDocument = await crudStore.findOne("Documents", {});
  if (newDocument) {
    await setUpPage2(newDocument);
  }
}

async function downloadFile() {
  await downloadFile_R2(file_doc.value);
}

async function deleteFile() {
  await deleteFile_R2(file_doc.value);
  reset();
}

function reset() {
  window.location.reload();
}

const cancelUpload = () => {
  clearInterval(uploadTimeout);
  uploadState.value = "idle";
  uploadProgress.value = 0;
  uploadedFile.value = null;
};

const simulateUpload = () => {
  uploadState.value = "uploading";
  uploadProgress.value = 0;
  uploadTimeout = setInterval(() => {
    uploadProgress.value += 50; // Increase by 50% every second
    if (uploadProgress.value >= 100) {
      clearInterval(uploadTimeout);
      uploadState.value = "completed";
    }
  }, 1000);
  handleUploadFinal();
};

const chooseFiles = () => {
  fileInputRef.value.click();
};

const handleUpload = async (e) => {
  uploadedFile.value = e.target.files[0];
  simulateUpload();
};

const drop = (e) => {
  e.preventDefault();
  dragging.value = false;
  uploadedFile.value = e.dataTransfer.files[0];
  simulateUpload();
};
function formatKey(key) {
  return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
}
</script>
