<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ createInvestmentStore.currentStep + 1 }}: Banking Information
    </div>
  </div>
  <BankInfoModal
    :open="createInvestmentStore.bank_account_modal_open"
    @close-modal="createInvestmentStore.bank_account_modal_open = false"
  />
  <FundingAccountSelection />
  <DistributionAccountSelection />
</template>

<script setup>
import { watch } from "vue";

import { useCreateInvestmentStore } from "@/stores";

import FundingAccountSelection from "./FundingAccountSelection.vue";
import DistributionAccountSelection from "./DistributionAccountSelection.vue";
import BankInfoModal from "./BankInfoModal.vue";

const createInvestmentStore = useCreateInvestmentStore();

watch(
  () => [
    createInvestmentStore.selected_funding_method,
    createInvestmentStore.selected_distribution_method,
    createInvestmentStore.selected_rollover_investment,
  ],
  () => {
    if (
      Object.keys(createInvestmentStore.selected_funding_method).length === 0 ||
      Object.keys(createInvestmentStore.selected_distribution_method).length === 0
    ) {
      createInvestmentStore.banking_info_is_invalid = true;
    } else if (
      createInvestmentStore.selected_funding_method.is_type_rollover &&
      !createInvestmentStore.selected_rollover_investment._id
    ) {
      createInvestmentStore.banking_info_is_invalid = true;
    } else {
      createInvestmentStore.banking_info_is_invalid = false;
    }
  },
  { deep: true }
);
</script>
