<template>
  <div>
    <div class="py-2 text-xl font-bold">Conversion Overview:</div>
    <div class="flex flex-col gap-2">
      <p class="font-semibold">Type: {{ investmentStore.selectedConversionType?.name }}</p>
      <p class="font-semibold">Method: {{ investmentStore.selectedConversionMethod?.name }}</p>
      <div class="flex flex-col gap-2 mt-4">
        <p class="font-semibold">Selected Investment(s) to Convert:</p>
        <p v-for="(investment, index) in investmentStore.selectedInvestmentsForConversion">
          {{ index + 1 }}. {{ investment.type }} - ${{
            commaSeparateThousands_2(investment.amount)
          }}
          - {{ investment.offering.type }} (Series
          {{
            investment.is_compounded
              ? `${investment.offering.name?.replace("Series", "")}-1`
              : investment.offering.name
          }}
          - {{ investment.offering.interest_rate }}% -
          {{ investment.offering.bond_term / 12 }} year)
        </p>
      </div>
      <div class="border-b border-platform-outlines w-full my-4"></div>
      <div class="flex flex-col gap-2">
        <p class="font-semibold">Converted Investment(s):</p>
        <div
          v-for="(investment, index) in investmentStore.conversionInvestments"
          class="flex justify-between gap-4"
        >
          <div class="flex flex-col gap-2">
            <p>
              {{ index + 1 }}. {{ investment.type }} - ${{
                commaSeparateThousands_2(investment.amount)
              }}
              - {{ investment?.offering?.type }} (Series
              {{
                investmentStore.conversionDetails.is_compounded
                  ? `${investment?.offering?.name?.replace("Series", "")}-1`
                  : investment?.offering?.name
              }}
              - {{ investment?.offering?.interest_rate }}% -
              {{ investment?.offering?.bond_term / 12 }}
              year)
            </p>
            <div
              v-if="investmentStore.conversionSubsToUpload[index]"
              class="flex gap-2 items-center"
            >
              <p>
                {{ investmentStore.conversionSubsToUpload[index]?.name }}
              </p>
              <Button
                @click="investmentStore.conversionSubsToUpload.splice(index, 1)"
                icon
                size="small"
                variant="error-color-outlined"
              >
                <template #icon>
                  <Icon name="Trash" class="h-4 w-4 text-functional-error-default" />
                </template>
              </Button>
            </div>
          </div>
          <input
            @change="handleUploadSub(index)"
            :ref="(el) => (fileInputRefs[index] = el)"
            type="file"
            accept=".pdf"
            hidden
          />

          <Button @click="chooseFiles(index)" label icon size="small">
            <template #label>Upload Sub</template>
            <template #icon>
              <Icon name="Upload" class="h-4 w-4" />
            </template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import { useInvestmentStore } from "@/stores";
import { Icon, Button } from "@/components";
import { commaSeparateThousands_2 } from "@/utilities";

const investmentStore = useInvestmentStore();
const fileInputRefs = ref([]);

const conversionInvestments = computed(() => {
  if (investmentStore.selectedConversionType.id === 1) {
    const selectedInvestment = investmentStore.selectedInvestmentsForConversion[0];

    if (investmentStore.selectedConversionMethod.id === 1) {
      return [
        {
          ...selectedInvestment,
          is_compounded: investmentStore.conversionDetails.is_compounded,
          offering: investmentStore.conversionDetails.selectedOffering.offering,
          offering_name: investmentStore.conversionDetails.selectedOffering.offering.name,
          offering_id: investmentStore.conversionDetails.selectedOffering.offering._id,
          offering_type: investmentStore.conversionDetails.selectedOffering.offering.type,
        },
      ];
    }

    if (investmentStore.selectedConversionMethod.id === 2) {
      const newAmount =
        Number(selectedInvestment.amount) +
        Number(investmentStore.conversionDetails.amount_to_add.replace(/,/g, ""));

      return [
        {
          ...selectedInvestment,
          amount: newAmount,
          is_compounded: investmentStore.conversionDetails.is_compounded,
          offering: investmentStore.conversionDetails.selectedOffering.offering,
          offering_name: investmentStore.conversionDetails.selectedOffering.offering.name,
          offering_id: investmentStore.conversionDetails.selectedOffering.offering._id,
          offering_type: investmentStore.conversionDetails.selectedOffering.offering.type,
        },
      ];
    }

    return [];
  } else if (investmentStore.selectedConversionType.id === 2) {
    const selectedInvestment = investmentStore.selectedInvestmentsForConversion[0];
    const filteredOneToManyList = investmentStore.oneToManyInvestmentList.filter(
      (investment) => investment.amount !== "0"
    );
    return filteredOneToManyList.map((investment) => {
      return {
        ...selectedInvestment,
        amount: Number(investment.amount.replace(/,/g, "")),
        offering: investmentStore.conversionDetails.selectedOffering.offering,
        offering_name: investmentStore.conversionDetails.selectedOffering.offering.name,
        offering_id: investmentStore.conversionDetails.selectedOffering.offering._id,
        offering_type: investmentStore.conversionDetails.selectedOffering.offering.type,
      };
    });
  } else if (investmentStore.selectedConversionType.id === 3) {
    // many to one
    const selectedInvestment = investmentStore.selectedInvestmentsForConversion[0];
    const summedAmount = investmentStore.selectedInvestmentsForConversion.reduce(
      (acc, investment) => {
        return acc + investment.amount;
      },
      0
    );
    return [
      {
        ...selectedInvestment,
        amount: summedAmount,
        offering: investmentStore.conversionDetails.selectedOffering.offering,
        offering_name: investmentStore.conversionDetails.selectedOffering.offering.name,
        offering_id: investmentStore.conversionDetails.selectedOffering.offering._id,
        offering_type: investmentStore.conversionDetails.selectedOffering.offering.type,
      },
    ];
  }
});

const chooseFiles = (index) => {
  fileInputRefs.value[index].click();
};

function handleUploadSub(index) {
  const fileInput = fileInputRefs.value[index];
  if (fileInput && fileInput.files.length > 0) {
    const file = fileInput.files[0];
    investmentStore.conversionSubsToUpload[index] = file;
  }
}
</script>
