<template>
  <Modal size="26x38" :hideModalTabs="true" :open="props.open" @close-modal="$emit('close-modal')">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <!-- <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div> -->

        <div class="font-semibold text-lg text-custom-navy-2">Edit Investment Notes</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <slot name="modal-body">
        <div class="h-full w-full bg-white flex flex-col gap-4 p-4 px-10">
          <BaseInput class="h-full" type="longText" v-model="investmentEdits.notes" label="Notes" />
        </div>
      </slot>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template></Button
        >
        <Button size="large" :disabled="isSaveDisabled" label @click="updateInvestmentNotes">
          <template #label>Save</template></Button
        >
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconInvestment, IconX } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRecordDetailStore, useCrudStore, useUIStore, useInvestmentStore } from "@/stores";
import { Modal, Button, BaseInput, RadioOptionModal, Table, Icon } from "@/components";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const UIStore = useUIStore();
const investmentStore = useInvestmentStore();

const investmentEdits = ref({ notes: "" });
const original_notes = ref("");

const isSaveDisabled = computed(() => {
  return investmentEdits.value.notes === original_notes.value;
});

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close-modal"]);

// watcher to set investmentEdits.notes based on investmentStore.investment_to_process.notes when open prop changes
watch(
  () => props.open,
  (newVal) => {
    if (newVal) {
      console.log("run watch");
      investmentEdits.value = {
        notes: investmentStore.investment_to_process?.notes || "",
      };
      original_notes.value = investmentStore.investment_to_process?.notes || "";
    }
  }
);

async function updateInvestmentNotes() {
  const filter = { _id: investmentStore.investment_to_process._id };
  const update = { $set: { notes: investmentEdits.value.notes } };

  let response = await crudStore.updateOne("Investments", filter, update);

  console.log(response);

  if (response.matchedCount == 1) {
    UIStore.animateNotificationAlert({
      type: "success",
      icon: "BadgeCheck",
      message: "Successfully updated Investment Notes!",
      subText: "",
      is_read: false,
      does_persist: false,
      action: null,
    });
  } else {
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to update Investment Notes!",
      subText: "",
      is_read: false,
      does_persist: false,
      action: null,
    });
  }
  // TODO send notification

  emit("close-modal");
}
</script>
