<template>
  <DocumentPreviewModal
    :open="previewModalOpen"
    @close-modal="previewModalOpen = false"
    :file="selectedFile"
  />
  <FilterModal
    v-if="investorStore.active_profile_tab === 'Investments' && activeTab === 'purchases'"
    :tabs="modalMainTabs"
    :activeTab="activeFilterTab"
    :show="UIStore.isEditFilterModalOpen"
    showFilterOptions
    class="position-fixed bottom-20"
    :customClasses="`${
      UIStore.isEditFilterModalCollapsed ? 'h-24' : 'h-140'
    } z-20 lg:left-20 bottom-8`"
  >
    <template v-slot:filter-modal-header>
      <button
        v-for="mainTab in modalMainTabs"
        :key="mainTab"
        @click="activeFilterTab = mainTab.name"
        type="button"
        class="flex items-center gap-2.5 border-b-3 border-b-secondary-6 p-3 text-sm font-bold"
      >
        <component :is="mainTab.icon" class="h-5.5 w-5.5 fill-primary-4" />
        <span>{{ mainTab.name }}</span>
      </button>
    </template>
    <template v-slot:filter-modal-body>
      <!-- Filters Modal Body -->

      <div
        class="no-scrollbar relative flex h-116 w-full flex-col overflow-y-auto"
        id="record-detail-filter-modal-body"
      >
        <!-- Search Bar -->
        <div class="sticky top-0 z-20 bg-neutral-gray-2 px-3 py-3">
          <BaseInput
            v-model="searchStore.searchTerm_columns"
            :results="filteredPossibleColumns"
            @focus="handleFocus"
            placeholder="Search and Add Filters"
            :label="null"
            type="search"
            size="small"
            customClass="bg-neutral-gray-1"
            ref="searchInput"
          >
            <template #search-dropdown>
              <div v-for="columnGroup in filteredPossibleColumns" :key="columnGroup" class="w-full">
                <div
                  v-if="columnGroup.fields.length"
                  class="sticky top-0 w-full bg-neutral-gray-3 p-2 text-xs font-bold text-primary-6"
                >
                  {{ columnGroup.collection_name.toUpperCase() }}
                </div>
                <div
                  v-for="column in columnGroup.fields"
                  :key="column"
                  @mousedown="addFilter(column)"
                  class="cursor-pointer px-3 py-2 hover:bg-platform-hover-secondary"
                >
                  <span class="text-primary text-sm">
                    {{ column.label }}
                  </span>
                </div>
              </div>
            </template>
          </BaseInput>
          <!-- Category Pills -->
          <div class="mt-3 flex w-full items-center">
            <div class="flex w-full items-center gap-1">
              <Button
                v-for="category in ['Applied', 'Favorites', 'Recents']"
                @click="recordDetailStore.activeFilterCategory = category"
                label
                icon
                size="small"
                variant="default-gray-outlined"
                class="!p-2"
                :class="
                  recordDetailStore.activeFilterCategory === category
                    ? '!border !border-secondary-6'
                    : ''
                "
              >
                <template #label>
                  <p
                    :class="recordDetailStore.activeFilterCategory === category ? 'font-bold' : ''"
                    class="text-xs"
                  >
                    {{ category }}
                  </p>
                </template>
              </Button>
            </div>
          </div>
        </div>
        <LogicalFilters
          v-if="recordDetailStore.activeFilterCategory === 'Applied' && tempFilters.length > 0"
          :dataFilters="tempFilters"
          :searchOptions="recordDetailStore.possibleColumns"
        />
        <FilterHistory
          v-else-if="
            (recordDetailStore.activeFilterCategory === 'Favorites' ||
              recordDetailStore.activeFilterCategory === 'Recents') &&
            filterHistoryToShow.length > 0
          "
          :filterHistory="filterHistoryToShow"
          @updateDataFilters="handleUpdateDataFiltersForHistory($event)"
        />
        <div v-else class="flex grow flex-col items-center justify-center gap-3 pb-8">
          <IconFilter class="h-6 w-6 fill-neutral-gray-6" />
          <p class="max-w-[50%] text-center text-xs text-dark opacity-[.3]">
            No Filters added. Add filters using the search bar.
          </p>
        </div>
      </div>
    </template>
    <template v-slot:filter-modal-footer>
      <div class="flex h-11 items-center justify-end rounded-b-md bg-white px-3">
        <div class="flex gap-2">
          <Button
            @click="UIStore.isEditFilterModalOpen = false"
            label
            variant="default-gray-outlined"
            size="small"
          >
            <template #label>Close</template>
          </Button>
          <Button @click="applyChanges()" label variant="default-filled" size="small">
            <template #label>Apply</template>
          </Button>
        </div>
      </div>
    </template>
  </FilterModal>
  <GenerateStatementModal
    :open="isGenerateStatementModalOpen"
    @close-modal="isGenerateStatementModalOpen = false"
    @generate-statement="handleGenerateMonthlyStatement"
  />
  <Teleport to="body">
    <ConvertInvestmentsDrawer
      @close-drawer="investmentStore.isConversionDrawerOpen = false"
      :open="investmentStore.isConversionDrawerOpen"
    />
  </Teleport>
  <div v-if="investorStore.active_profile_tab === 'Investments'">
    <div v-if="investmentStore.transactionsData?.length || dataFilters.length">
      <div
        class="rounded-md md:border border-neutral-gray-5 w-full block lg:flex justify-between mb-10"
      >
        <div
          class="md:p-10 py-3 px-3 border-b lg:border-b-0 lg:border-r border-neutral-gray-5 lg:w-[18.938rem] w-full grid grid-cols-2 lg:grid-cols-1 flex-none"
        >
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Total Account Value
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.total_invested != null &&
                  !isNaN(investorStore.account.total_invested)
                    ? commaSeparateThousands(investorStore.account.total_invested.toFixed(2))
                    : "0"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Total Pending
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.total_pending != null &&
                  !isNaN(investorStore.account.total_pending)
                    ? commaSeparateThousands(investorStore.account.total_pending.toFixed(2))
                    : "0"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Simple Interest Earnings
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.distributions_inception_to_date != null &&
                  !isNaN(investorStore.account.distributions_inception_to_date) &&
                  investmentStore.runningTotalDistributions.slice(-1)[0]
                    ? commaSeparateThousands(
                        (
                          Math.round(
                            investmentStore.runningTotalDistributions.slice(-1)[0]
                              ?.runningNonCompoundedAmount * 100
                          ) / 100
                        )?.toFixed(2)
                      )
                    : "0.00"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p class="text-xs leading-tight font-medium text-primary-2 uppercase">
                Compounding Interest Earnings
              </p>
              <p class="text-xl md:text-2xl leading-tight font-bold text-text-dark uppercase">
                ${{
                  investorStore.account?.distributions_inception_to_date != null &&
                  !isNaN(investorStore.account.distributions_inception_to_date) &&
                  investmentStore.runningTotalDistributions.slice(-1)[0]
                    ? commaSeparateThousands(
                        (
                          Math.round(
                            investmentStore.runningTotalDistributions.slice(-1)[0]
                              ?.compoundedAmount * 100
                          ) / 100
                        )?.toFixed(2)
                      )
                    : "0.00"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5" />
          </div>
          <div>
            <div class="py-2.5">
              <p
                class="text-xs font-leaguespartan leading-tight font-medium text-primary-2 uppercase"
              >
                Next Statement Date
              </p>
              <p
                class="text-xl md:text-2xl font-leaguespartan leading-tight font-bold text-text-dark uppercase"
              >
                {{
                  investorStore.account?.next_distribution_date
                    ? new Date(investorStore.account?.next_distribution_date).toLocaleDateString(
                        "en-US",
                        {
                          timeZone: "UTC",
                        }
                      )
                    : "N/A"
                }}
              </p>
            </div>
            <hr class="py-2.5 border-neutral-gray-5 lg:hidden" />
          </div>
        </div>
        <div class="w-full p-5">
          <div class="flex items-center justify-between">
            <div class="flex items-center overflow-auto">
              <button
                @click="setActiveChartTab('portfolio_dynamics')"
                class="md:px-value-32 md:py-3.5 px-2 py-value-8 uppercase flex items-center md:space-x-2"
                :class="
                  activeChartTab === 'portfolio_dynamics'
                    ? 'border-b-[3px] border-[#d3ac39] text-xs font-leaguespartan leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-gray text-xs font-leaguespartan leading-tight font-bold text-primary-2'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-chart-column-increasing hidden md:block"
                >
                  <path d="M13 17V9" />
                  <path d="M18 17v-3" />
                  <path d="M3 3v16a2 2 0 0 0 2 2h16" />
                  <path d="M8 17V5" />
                </svg>
                <p class="whitespace-nowrap">Portfolio Dynamics</p>
              </button>
              <button
                @click="setActiveChartTab('total_earnings')"
                class="md:px-value-32 md:py-3.5 px-2 py-value-8 uppercase flex items-center md:space-x-2"
                :class="
                  activeChartTab === 'total_earnings'
                    ? 'border-b-[3px] border-[#d3ac39] text-xs font-leaguespartan leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-gray text-xs font-leaguespartan leading-tight font-bold text-primary-2'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-dollar-sign hidden md:block"
                >
                  <line x1="12" x2="12" y1="2" y2="22" />
                  <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                </svg>
                <p class="whitespace-nowrap">Total Earnings</p></button
              ><button
                @click="setActiveChartTab('portfolio_structure')"
                class="md:px-value-32 md:py-3.5 px-2 py-value-8 uppercase flex items-center md:space-x-2"
                :class="
                  activeChartTab === 'portfolio_structure'
                    ? 'border-b-[3px] border-[#d3ac39] text-xs font-leaguespartan leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-gray text-xs font-leaguespartan leading-tight font-bold text-primary-2'
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-chart-pie hidden md:block"
                >
                  <path
                    d="M21 12c.552 0 1.005-.449.95-.998a10 10 0 0 0-8.953-8.951c-.55-.055-.998.398-.998.95v8a1 1 0 0 0 1 1z"
                  />
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                </svg>

                <p class="whitespace-nowrap">Portfolio Structure</p>
              </button>
            </div>
          </div>
          <div class="w-full h-[25.625rem]">
            <PieChart
              v-if="activeChartTab === 'portfolio_structure'"
              :chartData="investmentStore.chartData"
              :chartOptions="investmentStore.chartOptions"
            />
            <BarChart
              v-else
              :chartData="investmentStore.chartData"
              :chartOptions="investmentStore.chartOptions"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="px-3 md:px-0">
          <div class="flex justify-between items-center">
            <p class="text-2xl font-leaguespartan leading-tight font-bold text-text-dark uppercase">
              Transactions
            </p>
            <!-- <Button
          :variant="
            UIStore.isEditFilterModalOpen ? 'default-color-outlined' : 'default-gray-outlined'
          "
          is-icon-only-on-mobile
          icon
          label
          @click="UIStore.isEditFilterModalOpen = true"
        >
          <template #icon> <IconFilter /> </template>
          <template #label> Filters </template>
        </Button> -->
          </div>

          <div class="flex items-center justify-between mt-4.5">
            <div class="flex items-center">
              <button
                @click="setActiveTab('purchases')"
                class="px-value-32 py-3.5 uppercase flex items-center space-x-2"
                :class="
                  activeTab === 'purchases'
                    ? 'border-b-[3px] border-[#d3ac39] text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <Icon
                  name="ShoppingCart"
                  class="h-5 w-5 fill-none hidden md:block"
                  :strokeWidth="2"
                />
                <p>Purchases</p>
              </button>
              <button
                @click="setActiveTab('earnings')"
                class="px-value-32 py-3.5 uppercase flex items-center space-x-2"
                :class="
                  activeTab === 'earnings'
                    ? 'border-b-[3px] border-[#d3ac39] text-xs leading-tight font-bold text-primary-6'
                    : 'border-b-[3px] border-platform-outlines text-xs leading-tight font-bold text-primary-2'
                "
              >
                <Icon
                  name="DollarSign"
                  class="h-5 w-5 fill-none hidden md:block"
                  :strokeWidth="2"
                />
                <p>Earnings</p>
              </button>
            </div>
            <div class="flex items-center gap-2">
              <Button
                v-if="activeTab === 'purchases'"
                :variant="blue_border ? 'default-color-outlined' : 'default-gray-outlined'"
                :is-icon-only-on-mobile="true"
                :icon="true"
                :label="true"
                @click="UIStore.isEditFilterModalOpen = true"
              >
                <template #icon> <IconFilter /> </template>
                <template #label> Filters </template>
              </Button>
              <!-- <Button
                v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
                @click="investmentStore.isConversionDrawerOpen = true"
                label
                icon
                variant="white-bordered"
                isIconOnlyOnMobile
              >
                <template #label>Convert Investments</template>
                <template #icon>
                  <Icon name="MessageSquareReply" :stroke-width="2" class="h-4 w-4" />
                </template>
              </Button> -->
              <Button
                v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
                label
                icon
                variant="white-bordered"
                isIconOnlyOnMobile
              >
                <template #label>Replace Account</template>
                <template #icon>
                  <Icon name="UserPen" :stroke-width="2" class="h-4 w-4" />
                </template>
              </Button>
              <Button
                v-if="!authStore.isDalmoreAdmin"
                @click="isGenerateStatementModalOpen = true"
                label
                icon
                variant="white-bordered"
                isIconOnlyOnMobile
                :disabled="
                  !investmentStore.transactionsData?.some((i) => i.status === 'Settled') ||
                  investmentStore.isGeneratingOneOffStatement
                "
              >
                <template #label>Generate Statement</template>
                <template #icon>
                  <Icon
                    name="RefreshCcw"
                    :stroke-width="2"
                    class="h-4 w-4"
                    :class="investmentStore.isGeneratingOneOffStatement ? 'animate-spin' : ''"
                  />
                </template>
              </Button>
              <Button
                v-if="!authStore.isDalmoreAdmin"
                @click="downloadStatement"
                label
                icon
                variant="white-bordered"
                isIconOnlyOnMobile
                :disabled="!statement"
              >
                <template #label>Download Statement</template>
                <template #icon>
                  <Icon name="Download" :stroke-width="2" class="h-4 w-4" />
                </template>
              </Button>
            </div>
          </div>
        </div>

        <Table
          v-if="paginatedData?.length > 0"
          @setSortHeader="(header, props) => setSortHeader(header, props, activeTab)"
          @selectAll="handleSelectAll"
          :isAllChecked="isAllChecked"
          :headers="tableHeaders"
          :tableData="UIStore.transformRecords(paginatedData)"
          :fieldsWithCustomStyles="['status', 'offering_name', 'checkbox']"
          :isResizable="false"
          :actions_always_sticky="activeTab === 'purchases'"
        >
          <template v-slot:checkbox="entry">
            <div class="flex max-w-min items-center space-x-value-8 px-4">
              <input
                @click="select(entry)"
                :checked="investmentStore.investment_ids_to_process.includes(entry.row._id.value)"
                type="checkbox"
                class="accent-secondary-6"
              />
              <button
                @click="selectOnly(entry)"
                class="text-primary rounded bg-neutral-gray-4 px-4.5 py-0.5 text-xs"
              >
                Only
              </button>
            </div>
          </template>
          <template v-slot:status="entry">
            <div class="ml-4 truncate">
              <div
                class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
                :class="
                  entry.row.status.value == 'Created'
                    ? 'bg-custom-light-blue text-custom-light-blue'
                    : entry.row.status.value == 'Settled'
                      ? 'bg-custom-lime-green text-custom-lime-green'
                      : entry.row.status.value === 'Canceled'
                        ? 'bg-functional-error-default text-functional-error-hover'
                        : 'bg-functional-warning-default text-functional-warning-hover'
                "
              >
                {{ entry.row.status.value }}
              </div>
            </div>
          </template>
          <template v-slot:offering_name="entry">
            <p v-if="entry.row.offering_name?.value" class="ml-4 truncate">
              {{
                entry.row.is_compounded.value
                  ? `${entry.row.offering_name.value}-1`
                  : entry.row.offering_name.value
              }}
            </p>
            <p class="ml-4 truncate" v-else>-</p>
          </template>

          <template v-slot:table-ctas="entry">
            <div class="flex items-center space-x-2">
              <Button
                @click="downloadSubAgreement(entry)"
                size="small"
                variant="subtle"
                icon
                :disabled="entry.entry.signature_date.value === null"
              >
                <template #icon>
                  <Icon name="Download" class="h-4 w-4 fill-none" :strokeWidth="2" />
                </template>
              </Button>
              <Button
                @click="previewSubAgreement(entry)"
                size="small"
                variant="subtle"
                icon
                :disabled="entry.entry.signature_date.value === null"
              >
                <template #icon>
                  <Icon name="Eye" class="h-4 w-4 fill-none" :strokeWidth="2" />
                </template>
              </Button>
            </div>
          </template>
        </Table>
        <div v-else-if="dataFilters.length" class="py-10">
          <EmptyState
            description="No Transactions found under current filters"
            icon_name="ShoppingCart"
          />
        </div>
        <Pagination
          v-if="paginatedData.length > 0"
          show-count
          :total-category-results="
            activeTab === 'purchases'
              ? investmentStore.transactionsData.length
              : investmentStore.earningsData.length
          "
          :page-size="pageSize"
          :page-num="currentPage"
          @change-page="currentPage = $event"
          class="mt-5 md:mt-8 px-4 md:px-0"
        />
      </div>
    </div>
    <div
      v-if="
        activeTab === 'purchases' &&
        !investmentStore.transactionsData?.length &&
        !dataFilters.length
      "
    >
      <div class="flex flex-col justify-center items-center h-40">
        <div
          class="p-2 bg-primary-1-light bg-opacity-50 rounded-full flex justify-center items-center"
        >
          <div class="h-18 w-18 bg-primary-1-light rounded-full flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-chart-line"
            >
              <path d="M3 3v16a2 2 0 0 0 2 2h16" />
              <path d="m19 9-5 5-4-4-3 3" />
            </svg>
          </div>
        </div>

        <p class="text-primary-2 text-lg mt-4">Investments will appear here</p>
      </div>
      <Button
        v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
        @click="createInvestmentStore.investment_drawer_open = true"
        label
        size="large"
        class="mx-auto"
      >
        <template #label>Create an Investment</template>
      </Button>
    </div>
    <div
      v-if="
        activeTab === 'earnings' && !investmentStore.earningsData?.length && !dataFilters.length
      "
    >
      <div class="mx-auto">
        <EmptyState
          description="There are currently no distributions for this investment."
          icon_name="HandCoins"
        />
        <Button
          v-if="!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions"
          @click="createInvestmentStore.investment_drawer_open = true"
          label
          size="large"
          class="mx-auto hidden md:block"
        >
          <template #label>Create an Investment</template>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { ObjectId } from "bson";

import {
  useUIStore,
  useInvestmentStore,
  useInvestorStore,
  useCrudStore,
  useCreateInvestmentStore,
  useSearchStore,
  useRecordDetailStore,
  useAuthStore,
} from "@/stores";
import {
  Button,
  Icon,
  Table,
  DocumentPreviewModal,
  Pagination,
  EmptyState,
  FilterModal,
  LogicalFilters,
  FilterHistory,
  BaseInput,
} from "@/components";
import { IconFilter } from "@/components/icons";
import { commaSeparateThousands } from "@/utilities";
import useFileUtility from "@/composables/useFileUtility";
import BarChart from "./BarChart.vue";
import PieChart from "./PieChart.vue";
import GenerateStatementModal from "./GenerateStatementModal.vue";
import ConvertInvestmentsDrawer from "./InvestmentConversions/ConvertInvestmentsDrawer.vue";

const { downloadFile_R2, fetchFileForPreview_R2 } = useFileUtility();

const route = useRoute();

const UIStore = useUIStore();
const investorStore = useInvestorStore();
const investmentStore = useInvestmentStore();
const crudStore = useCrudStore();
const createInvestmentStore = useCreateInvestmentStore();
const searchStore = useSearchStore();
const recordDetailStore = useRecordDetailStore();
const authStore = useAuthStore();

const activeChartTab = ref("portfolio_dynamics");
const currentPage = ref(1);
const pageSize = ref(10);

const selectedFile = ref(null);
const previewModalOpen = ref(false);

const activeTab = ref("purchases");
const tableData = ref([]);
const tableHeaders = ref([]);

// filters
const activeFilterTab = ref("Filters");
const modalMainTabs = ref([{ name: "Filters", icon: IconFilter }]);
const dataFilters = ref([]);
const tempFilters = ref([]);
const filteredPossibleColumns = ref(JSON.parse(JSON.stringify(recordDetailStore.possibleColumns)));
const filterHistoryToShow = computed(() => {
  if (!recordDetailStore?.filterHistory?.length) return [];
  if (recordDetailStore.activeFilterCategory === "Favorites") {
    return recordDetailStore.filterHistory.filter((filter) => filter.is_favorited);
  }
  return recordDetailStore.filterHistory;
});
const searchInput = ref(null);
const blue_border = ref(false);

// const statementModalOpen = ref(false);
// const statementMonth = ref(
//   new Date().getDate() < 10 ? MONTHS[new Date().getMonth() - 1] : MONTHS[new Date().getMonth()]
// );
// const statementYear = ref(new Date().getFullYear());
const noInvestments = ref(false);

const isConversionDrawerOpen = ref(false);

const statement = ref(null);
const isGenerateStatementModalOpen = ref(false);

onMounted(async () => {
  investmentStore.investment_ids_to_process = []; // clear this incase they are coming from the investments page
  UIStore.sortHeader = null;
  UIStore.isChartLoading = true;
  //await recordDetailStore.setCurrentPageDocument(route.query.recordId); // for some reason the timing is off here, have to set this before query

  await getTableData("purchases"); // populate transactions and set it to active
  if (tableData?.value?.length) {
    await investmentStore.getPortfolioDynamicsData();
    await investmentStore.getInvestorEarnings();
    if (route.query.recordId) {
      statement.value = await crudStore.findOne("Documents", {
        type: "Statements",
        contact_id: ObjectId(route.query.recordId),
      });
    }
  }
});

onUnmounted(() => {
  // reset chart data
  investmentStore.runningTotalInvestments = [];
});

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  return activeTab.value === "purchases"
    ? investmentStore.transactionsData.slice(start, end)
    : investmentStore.earningsData.slice(start, end);
});

watch(
  () => activeTab.value,
  () => {
    currentPage.value = 1;
  }
);

watch(
  () => searchStore.searchTerm_columns,
  function () {
    recordDetailStore.possibleColumns.forEach((columnGroup, index) => {
      filteredPossibleColumns.value[index].fields = columnGroup.fields.filter((column) =>
        column.label
          .toLowerCase()
          .replace(/_/g, " ")
          .includes(searchStore.searchTerm_columns.toLowerCase())
      );
    });
  }
);

watch(
  () => investmentStore.isGeneratingOneOffStatement,
  async function () {
    let intervalId;
    if (investmentStore.isGeneratingOneOffStatement) {
      intervalId = setInterval(() => {
        checkStatementMessage().catch(console.error);
      }, 3000);
    } else {
      clearInterval(intervalId);
    }
  }
);
let count = 0;
async function checkStatementMessage() {
  let message = await crudStore.findOne("Messages", {
    mongodb_record_id: ObjectId(investorStore.account._id.toString()),
    mongodb_record_type: "Account-Statement",
    is_statement_complete: true,
  });
  if (count === 25) {
    count = 0;
    investmentStore.isGeneratingOneOffStatement = false;
  }
  if (message) {
    investmentStore.isGeneratingOneOffStatement = false;
    statement.value = await crudStore.findOne("Documents", {
      type: "Statements",
      contact_id: ObjectId(route.query.recordId),
    });
  }
  count++;
}

watch(
  () => UIStore.isEditFilterModalOpen,
  () => {
    if (!UIStore.isEditFilterModalOpen) {
      //recordDetailStore.activeFilterCategory = "Applied";
      tempFilters.value = JSON.parse(JSON.stringify(dataFilters.value));
    }
  }
);

const isAllChecked = computed(() => {
  if (investmentStore.transactionsData.length === 0) {
    return false;
  }
  return investmentStore.transactionsData.every((record) =>
    investmentStore.investment_ids_to_process.includes(record?._id?.toString())
  );
});

async function getTableData(activeTab) {
  if (activeTab === "purchases") {
    tableHeaders.value = [
      {
        field_name: "_id",
        label: "ID",
        field_type: "",
        associated_collection: "Investments",
        has_copy: true,
      },
      { field_name: "signature_date", label: "Transaction Date", field_type: "date" },
      { field_name: "type", label: "Type" },
      { field_name: "status", label: "Status" },
      { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
      { field_name: "bonds", label: "Bonds", field_type: "number" },
      { field_name: "offering_type", label: "Offering" },
      { field_name: "offering_name", label: "Series" },
      {
        field_name: "interest_rate",
        label: "Rate",
        field_type: "number",
        number_type: "percentage",
      },
      { field_name: "bond_term", label: "Term", field_type: "number" },
      { field_name: "maturity_date", label: "Maturity Date", field_type: "date" },
      {
        field_name: "year_to_date_interest_paid",
        label: "YTD Earned",
        field_type: "number",
        number_type: "currency",
      },
      {
        field_name: "inception_to_date_interest_paid",
        label: "Total Earned",
        field_type: "number",
        number_type: "currency",
      },
      {
        field_name: "created_date",
        label: "Created Date",
        field_type: "date",
      },
      { field_name: "subscription_agreement", label: "Agreement" },
    ];
    if (!authStore.isDalmoreAdmin && authStore.hasInvestmentProcessingPermissions) {
      tableHeaders.value.unshift({ field_name: "checkbox", label: "Select All" });
    }
    if (!investmentStore.transactionsData?.length) {
      tableData.value = await investmentStore.getInvestorTransactions();
    } else {
      tableData.value = investmentStore.transactionsData;
    }
  } else {
    tableHeaders.value = [
      { field_name: "distribution_date", label: "Date", field_type: "date" },
      { field_name: "amount", label: "Amount", field_type: "number", number_type: "currency" },
    ];
    if (!investmentStore.earningsData.length) {
      tableData.value = await investmentStore.getInvestorEarnings();
    } else {
      tableData.value = investmentStore.earningsData;
    }
  }
}

async function handleGenerateMonthlyStatement(statementDate, isSendEmail) {
  await investmentStore.generateMonthlyStatement(statementDate, isSendEmail);
}

async function setSortHeader(header, props, activeTab) {
  UIStore.setSortHeader(header, props);
  activeTab === "purchases"
    ? (tableData.value = await investmentStore.getInvestorTransactions())
    : (tableData.value = await investmentStore.getInvestorEarnings());
}

async function downloadStatement() {
  if (statement.value) {
    await downloadFile_R2(statement.value);
  }
}

async function downloadSubAgreement(entry) {
  let subAgreement = await crudStore.findOne("Documents", {
    type: "Sub Agreements",
    investment_id: entry.entry._id.value,
  });
  await downloadFile_R2(subAgreement);
}

async function previewSubAgreement(entry) {
  let subAgreement = await crudStore.findOne("Documents", {
    type: "Sub Agreements",
    investment_id: entry.entry._id.value,
  });
  const fileUrl = await fetchFileForPreview_R2(subAgreement);
  selectedFile.value = { ...subAgreement, file_url: fileUrl };
  previewModalOpen.value = true;
}

async function setActiveTab(tabName) {
  activeTab.value = tabName;
  await getTableData(tabName);
}

async function setActiveChartTab(tabName) {
  activeChartTab.value = tabName;
  if (tabName === "portfolio_dynamics") {
    await investmentStore.getPortfolioDynamicsData();
  } else if (tabName === "total_earnings") {
    investmentStore.getTotalEarningsData();
  } else if (tabName === "portfolio_structure") {
    investmentStore.getPortfolioStructureData();
  }
}

function handleSelectAll() {
  let selectAllInput = document.getElementById("select-all");

  if (!selectAllInput.checked) {
    // Remove the IDs of the records on the current page from the investment_ids_to_process array
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) =>
        !investmentStore.transactionsData.some((record) => record._id.toString() === id.toString())
    );
  } else {
    // Add the IDs of the records on the current page to the investment_ids_to_process array
    const currentIds = investmentStore.transactionsData.map((record) => record?._id?.toString());
    const uniqueIds = new Set([...investmentStore.investment_ids_to_process, ...currentIds]);
    investmentStore.investment_ids_to_process = Array.from(uniqueIds);
  }
}

function selectOnly(entry) {
  const recordId = entry.row._id.value?.toString();
  investmentStore.investment_ids_to_process = [recordId];
}

function select(entry) {
  const recordId = entry.row._id.value?.toString();

  if (investmentStore.investment_ids_to_process.includes(recordId)) {
    // If the ID is already in the array, remove it
    investmentStore.investment_ids_to_process = investmentStore.investment_ids_to_process.filter(
      (id) => id !== recordId
    );
  } else {
    // If the ID is not in the array, add it
    investmentStore.investment_ids_to_process.push(recordId);
  }
}

// Filtering

function addFilter(filter) {
  if (!dataFilters.value.find((e) => e.field_name === filter.label)) {
    let logicalFilter = {
      operator: UIStore.operators.find((operator) => operator.name === "Equal To"),
      value: filter.field_type === "boolean" ? { name: "Yes", value: true } : null,
      logical_operator: "and",
      label: filter.label,
      data_type: filter.field_type,
      field_name: filter.field_name,
      is_object_id: filter.is_object_id,
      collection_name: filter.collection_name,
      dropdown_options: filter.dropdown_options ? filter.dropdown_options : [],
      internal_sets: [],
    };

    if (recordDetailStore.activeFilterCategory !== "Applied") {
      recordDetailStore.activeFilterCategory = "Applied";
    }

    tempFilters.value.push(logicalFilter);
  }

  searchStore.searchTerm_columns = "";
}

function handleFocus() {
  if (!searchStore?.searchTerm_columns?.length) {
    filteredPossibleColumns.value = JSON.parse(JSON.stringify(recordDetailStore.possibleColumns));
  }
}

async function applyChanges() {
  UIStore.isLoading = true;
  currentPage.value = 1;

  dataFilters.value = JSON.parse(JSON.stringify(tempFilters.value));
  if (dataFilters.value.length > 0) {
    // await recordDetailStore.storeFilterHistoryForUser(dataFilters.value);
    let collection = "Investments";
    // recordDetailStore.filterHistory =
    //   await recordDetailStore.fetchUsersFilterHistoryForCollection(collection);
  }

  // reportStore.selectedReport.collection_name = collectionName;
  // reportStore.selectedReport.filters = JSON.parse(JSON.stringify(dataFilters.value));

  //this has to be an await function - needs to complete so that fetchTableRecords has access to the match stage created here
  // await recordDetailStore.queryForData(dataFilters.value, true);
  investmentStore.dashboard_filters = recordDetailStore.parseFilters(dataFilters.value, false);
  if (dataFilters.value.length > 0) {
    blue_border.value = true;
  } else {
    blue_border.value = false;
  }

  tableData.value = await investmentStore.getInvestorTransactions();
  //await recordDetailStore.fetchTableRecords();
  // UIStore.isEditFilterModalOpen = false;
  activeFilterTab.value = null;
  UIStore.isLoading = false;
}
function getTypeInfo(entry) {
  if (entry.type.value === "Joint") {
    const jointContact = investorStore.joint_contacts.find(
      (contact) => contact._id?.toString() === entry.joint_contact_id?.value?.toString()
    );

    const jointName = `${jointContact?.first_name[0]}. ${jointContact?.last_name}`;
    return `${entry.type.value} - ${jointName.length > 14 ? jointName?.slice(0, 14) + "..." : jointName}`;
  } else if (entry.type.value === "Entity") {
    const entityAccount = investorStore.entity_accounts.find(
      (entity) => entity._id?.toString() === entry.entity_account_id?.value?.toString()
    );

    const entityName = entityAccount?.name;
    return `${entry.type.value} - ${
      entityName.length > 14 ? entityName?.slice(0, 14) + "..." : entityName
    }`;
  }
}
</script>
