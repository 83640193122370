<template>
  <Modal :open="open" @close-modal="$emit('close-modal')" size="small-narrow" hideModalTabs>
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">Change Owner Email</p>
    </template>

    <template v-slot:modal-body>
      <div class="py-4 px-10 flex flex-col gap-4">
        <p class="text-lighter">
          Current Email: <span class="text-primary-6">{{ investorStore.contact.email }}</span>
        </p>
        <BaseInput
          label="New Email"
          required
          placeholder="Enter new email"
          v-model="newEmail"
          size="large"
          formatRule="email"
          errorMessage="Please enter a valid email address"
        />
        <BaseInput
          label="Confirm New Email"
          required
          placeholder="Confirm New Email"
          v-model="confirmedNewEmail"
          size="large"
          formatRule="email"
          errorMessage="Please enter a valid email address"
        />
        <p v-if="showExistingEmailError" class="text-sm text-functional-error-default">
          This email is already in use. Please enter a different email.
        </p>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button size="large" label @click="handleSubmitChangeUserEmail" :disabled="!isFormValid">
          <template #label> Submit </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed } from "vue";

import { useCrudStore, useInvestorStore } from "@/stores";
import { Modal, Button, BaseInput } from "@/components";
import { validateField } from "@/utilities";

const investorStore = useInvestorStore();
const crudStore = useCrudStore();

const emit = defineEmits(["close-modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const newEmail = ref("");
const confirmedNewEmail = ref("");
const showExistingEmailError = ref(false);

const isFormValid = computed(() => {
  if (!newEmail.value) {
    return false;
  }

  return validateField("email", newEmail.value) && newEmail.value === confirmedNewEmail.value;
});

async function handleSubmitChangeUserEmail() {
  const ownerEmailExists = await crudStore.findOne("Contacts", {
    email: newEmail.value,
    type: "Regular",
  });
  if (ownerEmailExists) {
    showExistingEmailError.value = true;
    return;
  }
  emit("close-modal");
  await investorStore.handleChangeOwnerContactEmail(newEmail.value);
}
</script>
