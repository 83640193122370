<template>
  <div class="flex">
    <div
      class="flex items-center text-xs"
      :class="disabled ? '!cursor-not-allowed' : '!cursor-pointer'"
      @click="updateValue"
    >
      <input
        :id="id"
        type="radio"
        :name="name"
        :value="value"
        :checked="isSelected"
        :disabled="disabled"
        class="hidden"
        @change="updateValue"
      />
      <div
        class="min-w-5 min-h-5 rounded-full border-2 flex items-center justify-center mr-3"
        :class="{
          'border-gray-300': !isSelected && !disabled,
          'border-secondary-6 bg-secondary-6': isSelected && !disabled,
          'border-gray-200 bg-gray-100 cursor-not-allowed': disabled,
        }"
      >
        <div
          class="min-w-1.5 min-h-1.5 rounded-full"
          :class="{
            'bg-primary-6': isSelected && !disabled,
            'bg-transparent': !isSelected || disabled,
          }"
        ></div>
      </div>
      <label
        :for="id"
        class="text-sm lg:text-base"
        :class="disabled ? '!cursor-not-allowed' : '!cursor-pointer'"
      >
        <p
          class="text-primary text-base"
          :class="[boldLabel ? 'font-semibold text-lg' : '', disabled ? 'text-neutral-gray-5' : '']"
        >
          {{ label }}
        </p>
        <p class="text-lighter">{{ description }}</p>
      </label>
    </div>
    <div class="bg-red-100 flex items-center justify-center ml-4">
      <slot name="icon"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import { useAuthStore } from "@/stores";

const authStore = useAuthStore();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  boldLabel: {
    type: Boolean,
    required: true,
    default: true,
  },
  value: {
    type: [String, Object, Boolean, null],
    required: true,
  },
  modelValue: {
    type: [String, Object, Boolean, null],
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const updateValue = () => {
  if (props.disabled) return;
  emit("update:modelValue", props.value);
};

const isSelected = computed(() => {
  if (typeof props.modelValue === "object" && typeof props.value === "object") {
    return JSON.stringify(props.modelValue) === JSON.stringify(props.value);
  }
  return props.modelValue === props.value;
});
</script>
