<template>
  <EditGroupsModal :open="groupModalOpen" @close-modal="groupModalOpen = false" />
  <EditPropertyModal
    :open="editPropertyModalOpen"
    @close-modal="editPropertyModalOpen = false"
    :currentGroup="current_group"
  />
  <DeletePropertyModal
    :open="deletePropertyModalOpen"
    @close-modal="deletePropertyModalOpen = false"
  />
  <div v-if="dataModelManagementStore.model_is_loading">
    <LoadingSpinner :show="dataModelManagementStore.model_is_loading" />
  </div>

  <div v-else class="pb-5">
    <!-- Page Header -->

    <div
      class="mx-4 my-3 flex flex-col justify-between md:mx-6 md:my-4 md:flex-row md:items-center lg:m-5"
    >
      <div class="flex flex-row items-center">
        <Button @click="backPage()" icon variant="default-gray-outlined" size="small" class="mr-6">
          <template #icon><IconChevronLeft /></template>
        </Button>

        <div class="flex flex-row items-center">
          <div v-if="dataModelManagementStore.currentModelCard.is_merge_collection" class="flex">
            <div
              class="flex h-8 w-8 items-center justify-center rounded-md bg-opacity-20 p-1"
              :class="[dataModelManagementStore.currentModelCard.icon_color_bg]"
            >
              <component
                :is="iconMapping[dataModelManagementStore.currentModelCard.icon]"
                class="h-6 w-6"
                :class="[dataModelManagementStore.currentModelCard.icon_color]"
              />
            </div>
            <div
              class="relative -ml-3 mt-3 flex h-8 w-8 items-center justify-center rounded-md bg-opacity-20 p-1"
              :class="[dataModelManagementStore.currentModelCard.icon_color2]"
            >
              <component
                :is="iconMapping[dataModelManagementStore.currentModelCard.icon2]"
                class="h-6 w-6"
                :class="[dataModelManagementStore.currentModelCard.icon_color2]"
              />
            </div>
          </div>

          <div
            v-else
            class="flex h-8 w-8 items-center justify-center rounded-lg bg-opacity-20"
            :class="[dataModelManagementStore.currentModelCard.icon_color_bg]"
          >
            <component
              :is="iconMapping[dataModelManagementStore.currentModelCard.icon]"
              class="h-6 w-6"
              :class="[dataModelManagementStore.currentModelCard.icon_color_fill]"
            />
          </div>
          <div class="ml-3 flex h-10 flex-col">
            <p class="truncate whitespace-nowrap text-base font-bold text-dark">
              {{ dataModelManagementStore.currentModelCard.name }}
            </p>
            <p class="text-xs text-light">
              {{ dataModelManagementStore.schemaRecordCount }} Properties
            </p>
          </div>
        </div>
      </div>

      <div class="ml-3 flex h-6 items-center gap-2">
        <p class="rounded bg-blue-100 px-2 py-1">V1 Field is the same as the V2 field</p>
        <p class="rounded bg-pink-100 px-2 py-1">Field is new new field</p>
        <p class="rounded bg-yellow-100 px-2 py-1">Other change</p>
      </div>

      <div class="mt-4 flex flex-row items-center justify-end space-x-4 md:mt-0">
        <div class="grid w-full grid-cols-2 space-x-1 md:flex md:flex-row md:items-center">
          <Button
            @click="openGroupModal()"
            variant="default-gray-outlined"
            size="medium"
            class=""
            icon
            label
          >
            <template #icon><IconSetting /></template>
            <template #label><p class="ml-1">Manage Properties</p></template></Button
          >
        </div>
      </div>
    </div>

    <!-- Search Bar -->
    <div class="relative mx-4 mb-3 md:mx-6 md:mb-4 lg:mx-5 lg:mb-5">
      <div class="flex w-full items-center rounded-md border border-neutral-gray-5">
        <input
          type="text"
          placeholder="Search for Labels"
          class="text-primary h-11 w-full rounded-md bg-neutral-gray-1 px-4 py-3 text-sm placeholder:text-sm focus:border-none focus:outline-none focus:ring-0"
          v-model="searchQuery"
        />
        <IconX class="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 fill-primary-1 md:mr-4" />
      </div>
    </div>

    <!-- Info Nugget Table -->
    <div
      v-if="filteredNuggets.length > 0"
      class="my-3 border-b border-t border-platform-outlines bg-neutral-gray-2 md:mx-6 md:my-4 md:rounded-lg md:border lg:my-5"
    >
      <div class="mx-4 flex flex-row items-center justify-between">
        <div class="my-2.5">
          <p class="h-4 text-xxs font-bold text-primary-1">Group</p>
          <p class="h-4 text-xs font-bold text-gold">Information Nuggets</p>
        </div>
        <Button
          @click="
            show_info_nugget_table === true
              ? (show_info_nugget_table = false)
              : (show_info_nugget_table = true)
          "
          variant="default-gray-outlined"
          size="small"
          class="ml-1"
        >
          <template #icon
            ><div class="flex flex-col">
              <IconExpand v-if="!show_info_nugget_table" />

              <div v-else class="flex flex-col"><IconContract /></div></div
          ></template>
        </Button>
      </div>
      <Transition name="slide-fade">
        <Table
          v-if="filteredNuggets.length > 0 && show_info_nugget_table"
          :tableData="filteredNuggets"
          :headers="dataModelManagementStore.tableHeaders"
          :fixTable="true"
          :fieldsWithCustomStyles="['field_type', 'updated_date', 'v1_field_name']"
        >
          <template v-slot:v1_field_name="entry">
            <div class="ml-3 flex h-6 items-center">
              <p
                v-if="entry.row.v1_field_name.value == entry.row.field_name.value"
                class="rounded bg-blue-100 px-2 py-1"
              >
                {{ entry.row.v1_field_name.value }}
              </p>
              <p
                v-else-if="entry.row.v1_field_name.value == 'net_new_field'"
                class="rounded bg-pink-100 px-2 py-1"
              >
                {{ entry.row.v1_field_name.value }}
              </p>
              <p v-else class="rounded bg-yellow-100 px-2 py-1">
                {{ entry.row.v1_field_name.value || "-" }}
              </p>
            </div>
          </template>
          <template v-slot:field_type="entry">
            <div class="ml-3 flex h-6 items-center">
              <p class="rounded bg-button-primary-hover px-2 py-1">
                {{ entry.row.field_type.value }}
              </p>
            </div>
          </template>
          <template v-slot:updated_date="entry">
            <div class="ml-3 flex h-6 items-center">
              {{
                new Date(entry.row.updated_date.value).toLocaleDateString("en-US") +
                " - " +
                new Date(entry.row.updated_date.value).toLocaleTimeString("en-US")
              }}
            </div>
          </template>
          <template v-slot:table-ctas="{ index, entry }">
            <div class="flex items-center space-x-2">
              <Button
                @click="openEditPropertyModal(entry)"
                isIconOnlyOnMobile
                icon
                label
                variant="subtle"
                size="small"
                class="md:px-5"
              >
                <template #label>Edit</template>
                <template #icon><IconEdit class="h-4 w-4" /></template>
              </Button>
              <Button
                :disabled="index === 0 || searchQuery !== ''"
                @click="dataModelManagementStore.handleOrderUpdate('nugget', entry, 'up')"
                icon
                variant="subtle"
                size="small"
              >
                <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
              </Button>
              <Button
                :disabled="
                  index === dataModelManagementStore.infoNuggets.length - 1 || searchQuery !== ''
                "
                @click="dataModelManagementStore.handleOrderUpdate('nugget', entry, 'down')"
                icon
                variant="subtle"
                size="small"
              >
                <template #icon><IconExpandMore class="h-4 w-4" /></template>
              </Button>
            </div>
          </template>
        </Table>
      </Transition>
      <div
        v-if="dataModelManagementStore.infoNuggets?.length === 0 && show_info_nugget_table"
        class="flex h-22 flex-col items-center justify-center rounded-b-lg bg-white"
      >
        <div class="mb-2"><IconX class="h-6 w-6 fill-neutral-gray-5" /></div>
        <div class="text-sm text-light">Edit a property to place it as an information nugget</div>
      </div>
    </div>

    <!-- Accordion Group Management Tables -->
    <div
      class="mb-3 border-b border-t border-platform-outlines bg-neutral-gray-2 md:mx-6 md:mb-4 md:rounded-lg md:border lg:mb-5"
      v-for="(group, index) of filteredGroups"
    >
      <div class="mx-4 flex flex-row items-center justify-between">
        <div class="my-2.5">
          <p class="h-4 text-xxs font-bold text-primary-1">Group</p>
          <p class="h-4 text-xs font-bold text-gold">
            {{ group.name === "" ? "No Group" : group.name }}
          </p>
        </div>
        <div class="flex flex-row gap-1">
          <Button
            @click="openEditPropertyModal(null, group)"
            variant="default-gray-outlined"
            size="small"
            class="ml-1"
            isIconOnlyOnMobile
          >
            <template #icon><IconPlus /></template>
            <template #label><p class="ml-1">Add Property</p></template></Button
          >
          <Button
            @click="toggleCollapse(index)"
            variant="default-gray-outlined"
            size="small"
            class="ml-1"
          >
            <template #icon
              ><div class="flex flex-col">
                <IconExpand v-if="collapsedGroups.includes(index)" />

                <div v-else class="flex flex-col"><IconContract /></div></div
            ></template>
          </Button>
        </div>
      </div>

      <Transition name="slide-fade-2">
        <Table
          v-if="!collapsedGroups.includes(index) && group.fields.length > 0"
          :useCustomSort="true"
          :tableData="group.fields"
          :headers="dataModelManagementStore.tableHeaders"
          :fixTable="true"
          :fieldsWithCustomStyles="['field_type', 'updated_date', 'v1_field_name']"
        >
          <template v-slot:field_type="entry">
            <div class="ml-3 flex h-6 items-center">
              <p class="rounded bg-button-primary-hover px-2 py-1">
                {{ entry.row.field_type.value }}
              </p>
            </div>
          </template>
          <template v-slot:v1_field_name="entry">
            <div class="ml-3 flex h-6 items-center">
              <p
                v-if="entry.row.v1_field_name.value == entry.row.field_name.value"
                class="rounded bg-blue-100 px-2 py-1"
              >
                {{ entry.row.v1_field_name.value }}
              </p>
              <p
                v-else-if="entry.row.v1_field_name.value == 'net_new_field'"
                class="rounded bg-pink-100 px-2 py-1"
              >
                {{ entry.row.v1_field_name.value }}
              </p>
              <p v-else class="rounded bg-yellow-100 px-2 py-1">
                {{ entry.row.v1_field_name.value || "-" }}
              </p>
            </div>
          </template>
          <template v-slot:updated_date="entry">
            <div class="ml-3 flex h-6 items-center">
              {{
                new Date(entry.row.updated_date.value).toLocaleDateString("en-US") +
                " - " +
                new Date(entry.row.updated_date.value).toLocaleTimeString("en-US")
              }}
            </div>
          </template>
          <template v-slot:table-ctas="{ entry }">
            <div class="flex items-center space-x-2">
              <Button
                @click="openEditPropertyModal(entry, group)"
                isIconOnlyOnMobile
                icon
                label
                variant="subtle"
                size="small"
                class="md:px-5"
              >
                <template #label>Edit</template>
                <template #icon><IconEdit class="h-4 w-4" /></template>
              </Button>
              <Button
                @click="openDeletePropertyModal(entry, group)"
                isIconOnlyOnMobile
                icon
                variant="default-gray-outlined"
                size="small"
              >
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-4.5 w-4.5 stroke-functional-error-default"
                    :stroke-width="2"
                /></template>
              </Button>
              <Button
                :disabled="group.fields.indexOf(entry) === 0 || searchQuery !== ''"
                @click="dataModelManagementStore.handleOrderUpdate(group, entry, 'up')"
                icon
                variant="subtle"
                size="small"
              >
                <template #icon><IconExpandLess class="fill-primary- h-4 w-4" /> </template>
              </Button>
              <Button
                :disabled="
                  group.fields.indexOf(entry) === group.fields.length - 1 || searchQuery !== ''
                "
                @click="dataModelManagementStore.handleOrderUpdate(group, entry, 'down')"
                icon
                variant="subtle"
                size="small"
              >
                <template #icon><IconExpandMore class="h-4 w-4" /></template>
              </Button>
            </div>
          </template>
        </Table>
      </Transition>

      <div
        v-if="group.fields.length === 0 && !collapsedGroups.includes(index)"
        class="flex h-22 flex-col items-center justify-center rounded-b-lg bg-white"
      >
        <div class="mb-2"><IconX class="h-6 w-6 fill-neutral-gray-5" /></div>
        <div class="text-sm text-light">Properties added to this section will show up here</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Button, Table, LoadingSpinner } from "@/components";
import { iconMapping } from "@/constants/iconMapping";
import {
  IconX,
  IconEdit,
  IconDelete,
  IconExpandMore,
  IconExpandLess,
  IconExpand,
  IconSetting,
  IconContract,
  IconPlus,
  IconChevronLeft,
} from "@/components/icons";
import { Icon } from "@/components";
import { useDataModelManagementStore } from "@/stores/useDataModelManagementStore";

import EditGroupsModal from "./EditGroupsModal.vue";

import EditPropertyModal from "./EditPropertyModal.vue";
import DeletePropertyModal from "./DeletePropertyModal.vue";
import _ from "lodash";

const route = useRoute();
const router = useRouter();
const dataModelManagementStore = useDataModelManagementStore();

const groupModalOpen = ref(false);
const editPropertyModalOpen = ref(false);
const deletePropertyModalOpen = ref(false);
const collapsedGroups = ref([]);
const show_info_nugget_table = ref(true);
const current_group = ref("");
const searchQuery = ref("");
const route_model = route.query.model;

onMounted(async () => {
  window.scrollTo(0, 0);
  dataModelManagementStore.model_is_loading = true;
  await dataModelManagementStore.setManagementObjects();

  if (dataModelManagementStore.currentModel === null) {
    const route_array = route_model.split("/");

    dataModelManagementStore.currentModelCard = dataModelManagementStore.managementObjects.find(
      (item) =>
        item.collection.toLowerCase() === route_array[0] &&
        item.name.split(" ").join("").toLowerCase() === route_array[1]
    );

    dataModelManagementStore.currentModel = dashToSpaceAndCapitalize(route_model);
  }

  await dataModelManagementStore.fetchModelNuggets();
  await dataModelManagementStore.setGroupData();
  await dataModelManagementStore.set_QRs_RLs_DSs();

  dataModelManagementStore.model_is_loading = false;
});

const filteredGroups = computed(() => {
  if (!searchQuery.value) {
    return dataModelManagementStore.fetchedTableData;
  }
  const searchLower = searchQuery.value.toLowerCase();

  return dataModelManagementStore.fetchedTableData
    .map((group) => ({
      ...group,
      fields: group.fields.filter((field) => field.label.value.toLowerCase().includes(searchLower)),
    }))
    .filter((group) => group.fields.length > 0);
});

// Computed property for filtering dataModelManagementStore.infoNuggets
const filteredNuggets = computed(() => {
  if (!searchQuery.value) {
    return dataModelManagementStore.infoNuggets;
  }
  const searchLower = searchQuery.value.toLowerCase();
  return dataModelManagementStore.infoNuggets.filter((nugget) =>
    nugget.label.value.toLowerCase().includes(searchLower)
  );
});

function dashToSpaceAndCapitalize(str) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
function backPage() {
  router.push(`/data-model-management`);
}

function openEditPropertyModal(entry, group) {
  editPropertyModalOpen.value = true;

  dataModelManagementStore.propertyToEdit = null;

  if (entry) {
    dataModelManagementStore.propertyToEdit = entry;
    dataModelManagementStore.propertyAction = "Edit";
  } else {
    dataModelManagementStore.propertyAction = "Create";
    dataModelManagementStore.current_property_group = group.name === "" ? "No Group" : group.name;
    const template = _.cloneDeep(this.default_property_object);
    dataModelManagementStore.propertyToEdit = template;
  }
}
function openDeletePropertyModal(entry) {
  dataModelManagementStore.propertyToEdit = null;

  if (entry) {
    dataModelManagementStore.propertyToEdit = entry;
    dataModelManagementStore.propertyAction = "Edit";
  }
  deletePropertyModalOpen.value = true;
}

function openGroupModal() {
  groupModalOpen.value = true;
}

function toggleCollapse(index) {
  const indexPosition = collapsedGroups.value.indexOf(index);

  if (indexPosition > -1) {
    collapsedGroups.value.splice(indexPosition, 1);
  } else {
    collapsedGroups.value.push(index);
  }
}
</script>
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: max-height 0.4s ease-in-out;
  overflow: hidden; /* Prevent content from spilling out during transition */
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0; /* Start with a max-height of 0 */
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 500px; /* Set to a value higher than the expected maximum content height */
}
.slide-fade-2-enter-active,
.slide-fade-2-leave-active {
  transition: max-height 0.25s ease-in-out;
  overflow: hidden; /* Prevent content from spilling out during transition */
}

.slide-fade-2-enter-from,
.slide-fade-2-leave-to {
  max-height: 0; /* Start with a max-height of 0 */
}

.slide-fade-2-enter-to,
.slide-fade-2-leave-from {
  max-height: 1200px; /* Set to a value higher than the expected maximum content height */
}
</style>
