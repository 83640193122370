import * as Realm from "realm-web";
import { defineStore } from "pinia";
import { useCollectionStore } from "./useCollectionStore";
import { useCrudStore } from "./useCrudStore";
import { useAuthStore } from "./useAuthStore";
import { useUIStore } from "./useUIStore";
import { useSchemaStore } from "./useSchemaStore";
import {
  commaSeparateThousands,
  convertJsonToCsvFileAndDownload,
  objectCompare,
  transformRecordsForTable,
} from "@/utilities";
import _, { has } from "lodash";
import { list } from "postcss";

const {
  BSON: { ObjectId },
} = Realm;

export const useDataModelManagementStore = defineStore("dataModelManagementStore", {
  state: () => ({
    currentModel: null,
    nested_object_options: [],
    editListModalOpen: false,
    icon_options: [],
    icon_color_options: [],
    currentModelCard: {
      type: "",
      config_object: "",
      name: "",
      collection: "",
      description: "",
    },
    all_available_lists: [],
    resetTic: 0,
    historyNuggets: [],
    historyRecords: null,
    historyTableRecordCount: 0,
    searchResultsArray: [],
    relatedListArray: [],

    editsPageNumber: 1,
    schemaRecordCount: 0,
    model_being_edited: null,
    list_being_edited: null,
    model_is_loading: false,
    datamodalopen: false,
    historyPageNumber: 1,
    current_property_group: null,
    currentCollection: null,
    finalGroupingOrder: null,
    fetched_settings: null,
    fetchedTableData: null,
    allQuickReferences: null,
    allRLQRs: null,
    allRelatedLists: null,
    propertyToEdit: null,
    propertyAction: null,
    default_property_object: {
      created_by: "",
      is_info_nugget: false,
      label: "",
      field_name: "",
      field_type: "",
      record_detail_group: "",
      default_value: "",
      dropdown_options: [],
      is_required: false,
      show_in_search_results: false,
      show_in_related_list: false,
      belongs_to_nested_object: false,
      accredited_only: false,
      available_for_symbology: false,
      available_for_filtering: false,
      read_only: false,
      number_type: "",
      associated_collection: "",
      associated_field_name: "",
      is_association_field: false,
      base_url: "",
      associated_name_for_results: "",
      migration_notes: "",
      v1_field_name: "",
      associated_collection_query: "",
      original_collection_name: "",
      field_regex: "",
      error_message: "",
      nested_object_name: "",
      is_object_id: false,
      has_copy: false,
    },
    managementObjects: null,
    propertyUpdateTic: 0,
    historyPageSize: 25,
    QRList: [],
    RLList: [],
    DSList: [],
    blank_list_setting: {
      card_name: "",
      description: "",
      is_list: true,
      options: [],
      reference: "",
    },
    blank_model: {
      type: "data_model_object",
      has_multiple_configs: false,
      collection: "",
      config_determining_field: "",
      field_map: [{ value: "", config: "" }],
      card_map: [
        {
          name: "",
          config_object: "default",
          description: "",
        },
      ],
      all_config_options: ["default"],
    },
    tableHeaders: [
      {
        field_name: "label",
        label: "Label",
        actions: {
          hide_sort: true,
        },
      },

      {
        field_name: "field_type",
        label: "Field Type",
        actions: {
          hide_sort: true,
        },
      },
      {
        field_name: "field_name",
        label: "Field Name",
        actions: {
          hide_sort: true,
        },
      },
      {
        field_name: "v1_field_name",
        label: "V1 Field Name",
        actions: {
          hide_sort: true,
        },
      },
      {
        field_name: "migration_notes",
        label: "Migration Notes",
        actions: {
          hide_sort: true,
        },
      },

      {
        field_name: "updated_date",
        label: "Updated Date",
        actions: {
          hide_sort: true,
        },
      },
      {
        field_name: "updated_by_name",
        label: "Updated By",
        actions: {
          hide_sort: true,
        },
      },
      {
        label: "Actions",
        field_name: "actions",

        actions: { cta: true, hide_sort: true },
      },
    ],
    infoNuggets: [],
  }),

  actions: {
    async setManagementObjects() {
      let fetched_items = await this.setCollectionConstants();
      console.log("fetched_items", fetched_items);

      let collection_order = [
        "Contacts",
        "Accounts",
        "Investments",
        "BankAccounts",
        "Documents",
        "Offerings",
      ];

      collection_order = [
        ...collection_order,
        "GeneralDocuments",
        "Accreditation",
        "MonthlyStatements",
      ];

      //right here i want to sort the fetched_items so that objects in the array with collection in the collection_order array are first, and the rest are last, I want the rest after this list to be sorted alphabetically

      // Sort fetched_items with custom sorting logic
      fetched_items.sort((a, b) => {
        // Check if both items are in collection_order
        const indexA = collection_order.indexOf(a.collection);
        const indexB = collection_order.indexOf(b.collection);

        if (indexA !== -1 && indexB !== -1) {
          // If both items are in collection_order, preserve their order
          return indexA - indexB;
        } else if (indexA !== -1) {
          // If only a is in collection_order, it should come first
          return -1;
        } else if (indexB !== -1) {
          // If only b is in collection_order, it should come first
          return 1;
        } else {
          // If neither is in collection_order, sort alphabetically by collection name
          return a.collection.localeCompare(b.collection);
        }
      });

      await this.setLists();
      this.fetched_settings = fetched_items;
      let labels_descriptions = this.transformDataModelObjects(fetched_items);
      this.managementObjects = labels_descriptions;
      return labels_descriptions;
    },
    async setLists() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      let available_lists = await crudStore.find(
        "Settings",
        { is_list: true },
        { card_name: 1, options: 1, reference: 1, description: 1 }
      );
      this.all_available_lists = available_lists;

      //Setting List of Possible Collections
      let collection_option_setting = available_lists.find(
        (list) => list.reference === "collection_names"
      );
      if (collection_option_setting) {
        UIStore.all_collection_options = collection_option_setting.options;
      } else {
        UIStore.all_collection_options = ["No Collection / Collections Unavailable"];
      }
      let color_setting = available_lists.find((list) => list.reference === "colors");
      if (color_setting) {
        UIStore.all_color_options = color_setting.options;
      } else {
        UIStore.all_color_options = ["No Color / Colors Unavailable"];
      }

      let icon_setting = available_lists.find((list) => list.reference === "icons");
      if (icon_setting) {
        UIStore.all_icon_options = icon_setting.options;
      } else {
        UIStore.all_icon_options = ["No Icon / Icons Unavailable"];
      }
      return available_lists;
    },
    async setCollectionConstants() {
      const crudStore = useCrudStore();

      let fetched_items = await crudStore.find("Settings", {
        type: "data_model_object",
      });
      ///TODO: set the constants
      return fetched_items;
    },

    transformDataModelObjects(dataModelObjects) {
      let transformedObjects = [];

      dataModelObjects.forEach((obj) => {
        obj.card_map.forEach((card) => {
          let newObject = {
            type: obj.type,
            has_multiple_configs: obj.has_multiple_configs,
            collection: obj.collection,
            config_determining_field: obj.config_determining_field,
            name: card.name,
            config_object: card.config_object,
            description: card.description,
            field_map: obj.field_map,
            all_config_options: obj.all_config_options,
            landing_path: obj.landing_path,
            record_path: obj.record_path,
            singular: obj.singular,
            plural: obj.plural,
            is_merge_collection: obj.is_merge_collection,
            icon: obj.icon,
            icon_color: obj.icon_color,
            icon_color_fill: obj.icon_color_fill,
            icon_color_bg: obj.icon_color_bg,
            primary_key: obj.primary_key,
          };

          if (obj.is_merge_collection) {
            newObject.icon2 = obj.icon2;
            newObject.icon_color2 = obj.icon_color2;
            newObject.icon_color_fill2 = obj.icon_color_fill2;
            newObject.icon_color_bg2 = obj.icon_color_bg2;
          }
          transformedObjects.push(newObject);
        });
      });

      return transformedObjects;
    },
    async fetchModelNuggets() {
      const crudStore = useCrudStore();

      const matchString =
        "record_detail_config." + this.currentModelCard.config_object + ".is_info_nugget";
      const sortString =
        "record_detail_config." + this.currentModelCard.config_object + ".info_nugget_order";
      const infoNuggetString =
        "$record_detail_config." + this.currentModelCard.config_object + ".is_info_nugget";
      let pipeline = [
        {
          $match: {
            collection_name: this.currentModelCard.collection,
            [matchString]: true,
          },
        },
        {
          $sort: {
            [sortString]: 1,
          },
        },
        {
          $project: {
            _id: "$_id",
            label: "$label",
            field_name: "$field_name",
            field_type: "$field_type",
            number_type: "$number_type",
            id_object_id: "$is_object_id",
            has_copy: "$has_copy",
            read_only: "$read_only",
            info_nugget_boolean: infoNuggetString,
            updated_by_name: "$updated_by_name",
            updated_by_id: "$updated_by_id",
            updated_date: "$updated_date",
            v1_field_name: "$v1_field_name",
            migration_notes: "$migration_notes",
          },
        },
      ];

      let infoNuggets = await crudStore.aggregate("Schema", pipeline);

      for (const nugget of infoNuggets) {
        if (nugget.field_type === "string") {
          nugget.field_type = "Text";
        } else if (nugget.field_type === "long_text") {
          nugget.field_type = "Long Text";
        } else {
          nugget.field_type = nugget.field_type
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }
      }

      const transformedRecords = transformRecordsForTable(infoNuggets);
      this.infoNuggets = transformedRecords;
    },
    async setGroupData() {
      const crudStore = useCrudStore();

      let pageLayout = await crudStore.findOne("PageLayouts", {
        collection_name: this.currentModelCard.collection,
      });

      let groupingOrder;

      groupingOrder =
        pageLayout.record_detail_config[this.currentModelCard.config_object].detail_sections;
      groupingOrder.push("");

      this.finalGroupingOrder = groupingOrder;

      const matchString =
        "record_detail_config." + this.currentModelCard.config_object + ".record_detail_group";
      const sortString =
        "record_detail_config." +
        this.currentModelCard.config_object +
        ".record_detail_group_order";
      const infoNuggetString =
        "$record_detail_config." + this.currentModelCard.config_object + ".is_info_nugget";
      const groupString =
        "$record_detail_config." + this.currentModelCard.config_object + ".record_detail_group";

      let pipeline = [
        {
          $match: {
            collection_name: this.currentModelCard.collection,
            [matchString]: { $exists: true },
          },
        },
        {
          $sort: {
            [sortString]: 1,
          },
        },
        {
          $group: {
            _id: groupString,
            fields: {
              $push: {
                _id: "$_id",
                label: "$label",
                field_name: "$field_name",
                field_type: "$field_type",
                number_type: "$number_type",
                id_object_id: "$is_object_id",
                has_copy: "$has_copy",
                read_only: "$read_only",
                info_nugget_boolean: infoNuggetString,
                updated_by_name: "$updated_by_name",
                updated_by_id: "$updated_by_id",
                updated_date: "$updated_date",
                show_in_search_results: "$show_in_search_results",
                show_in_related_list: "$show_in_related_list",
                belongs_to_nested_object: "$belongs_to_nested_object",
                accredited_only: "$accredited_only",
                v1_field_name: "$v1_field_name",
                migration_notes: "$migration_notes",
              },
            },
          },
        },
        {
          $addFields: {
            groupOrderIndex: { $indexOfArray: [groupingOrder, "$_id"] },
          },
        },
        {
          $sort: { groupOrderIndex: 1 },
        },
        {
          $project: {
            name: "$_id",
            fields: 1,
            _id: 0,
          },
        },
      ];

      let fetchedTableData = await crudStore.aggregate("Schema", pipeline);

      this.nested_object_options = [];

      this.nested_object_options = fetchedTableData
        .flatMap((point) => point.fields)
        .filter((field) => field.field_type === "object")
        .map((field) => field.field_name);

      // Create a new array to store the ordered data
      let orderedFetchedData = [];

      // Loop through finalGroupingOrder
      for (const groupName of this.finalGroupingOrder) {
        // Find the object in fetchedTableData or create a new one
        let group = fetchedTableData.find((group) => group.name === groupName) || {
          fields: [],
          name: groupName,
        };

        // Push the found or created object into the ordered array
        orderedFetchedData.push(group);
      }

      let missingFromOrderedData = fetchedTableData.filter(
        (fetchedItem) =>
          !orderedFetchedData.some((orderedItem) => orderedItem.name === fetchedItem.name)
      );

      // Now, orderedFetchedData has the groups in the same order as finalGroupingOrder
      let total_count = await crudStore.countRecords("Schema", [
        {
          $match: {
            collection_name: this.currentModelCard.collection,
          },
        },
      ]);
      this.schemaRecordCount = total_count;
      console.log("total_count", total_count);
      // this.schemaRecordCount = 5;
      // Existing code for transforming fields, applied to orderedFetchedData
      for (const group of orderedFetchedData) {
        const transformedFields = [];
        for (const field of group.fields) {
          if (field.show_in_related_list) {
          }
          const transformed = await this.transformSchemaFields([field]);
          transformedFields.push(transformed[0]); // Assuming transformSchemaFields returns an array
        }
        group.fields = transformedFields;
      }
      let searchPipeline = [
        {
          $match: {
            collection_name: this.currentModelCard.collection,
            show_in_search_results: true,
          },
        },
        {
          $sort: {
            search_result_order: 1,
          },
        },
        {
          $project: {
            search_result_order: 1,
            show_in_search_results: 1,
            label: 1,
          },
        },
      ];
      this.searchResultsArray = await crudStore.aggregate("Schema", searchPipeline);
      let rlPipeline = [
        {
          $match: {
            collection_name: this.currentModelCard.collection,
            show_in_related_list: true,
          },
        },
        {
          $sort: {
            related_list_order: 1,
          },
        },
        {
          $project: {
            related_list_order: 1,
            show_in_related_list: 1,
            label: 1,
          },
        },
      ];
      this.relatedListArray = await crudStore.aggregate("Schema", rlPipeline);

      // Assign the ordered data back to fetchedTableData
      this.fetchedTableData = orderedFetchedData;

      return orderedFetchedData;
    },
    transformSchemaFields(fields) {
      return fields.map((field) => {
        const transformedfield = {};

        // Function to capitalize the first letter of every word

        // Dynamically create new properties based on the original field's properties
        Object.keys(field).forEach((key) => {
          let value = field[key];

          if (key === "field_type") {
            if (value === "string") {
              value = "Text";
            } else if (value === "long_text") {
              value = "Long Text";
            } else if (value === "array") {
              value = "Array";
            } else if (value === "number") {
              value = "Number";
            } else if (value === "date") {
              value = "Date";
            } else if (value === "boolean") {
              value = "Boolean";
            } else if (value === "dropdown") {
              value = "Dropdown";
            } else if (value === "multiselect") {
              value = "Multi Select";
            } else if (value === "object") {
              value = "Object";
            } else if (value === "array_of_ids") {
              value = "Array Of Ids";
            }
          }

          transformedfield[key] = {
            value: value,
            clickable: key === "name", // or any other condition you want to apply
          };
        });

        // Add a standard action object
        transformedfield.actions = { cta: true, hide_sort: true };

        return transformedfield;
      });
    },
    async set_QRs_RLs_DSs() {
      const crudStore = useCrudStore();
      const UIStore = useUIStore();
      const fetchedPageLayoutDoc = {};
      // const fetchedPageLayoutDoc = await crudStore.findOne("PageLayouts", {
      //   collection_name: this.currentModelCard.collection,
      // });

      let QRList = [];
      let RLList = [];
      this.DSList = [];
      // let QRList =
      //   fetchedPageLayoutDoc.record_detail_config[this.currentModelCard.config_object]
      //     .quick_references;
      // let RLList =
      //   fetchedPageLayoutDoc.record_detail_config[this.currentModelCard.config_object]
      //     .related_lists;
      // this.DSList =
      //   fetchedPageLayoutDoc.record_detail_config[
      //     this.currentModelCard.config_object
      //   ].detail_sections;

      const QRarray = [];
      const RLarray = [];

      for (const reference of QRList) {
        const iconConfig = UIStore.icon_config.find((item) => item.name === reference);

        const QRObject = {
          // If we find a matching icon configuration, update the refs
          name: reference,
          iconBg: iconConfig?.icon_background,
          iconFill: iconConfig?.icon_color,
          icon: iconConfig?.icon,
        };
        QRarray.push(QRObject);
      }

      for (const list of RLList) {
        const iconConfig = UIStore.icon_config.find((item) => item.name === list);

        const QRObject = {
          name: list,
          iconBg: iconConfig?.icon_background,
          iconFill: iconConfig?.icon_color,
          icon: iconConfig?.icon,
        };
        RLarray.push(QRObject);
      }

      this.QRList = QRarray;
      this.RLList = RLarray;
    },
    async setGroups(groups, searchresults, relatedlists) {
      this.model_is_loading = true;
      const crudStore = useCrudStore();
      const authStore = useAuthStore();

      for (const [index, rel_list] of relatedlists.entries()) {
        const rl_schema_id = ObjectId(rel_list._id);

        let update = {
          $set: {
            related_list_order: index + 1,
            updated_by_id: authStore.currentUser.customData.user_id,
            updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
            updated_date: new Date(),
          },
        };

        await crudStore.updateOne("Schema", { _id: rl_schema_id }, update, false);
      }

      for (const [index, search_result] of searchresults.entries()) {
        const search_schema_id = ObjectId(search_result._id);

        let update2 = {
          $set: {
            search_result_order: index + 1,
            updated_by_id: authStore.currentUser.customData.user_id,
            updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
            updated_date: new Date(),
          },
        };

        await crudStore.updateOne("Schema", { _id: search_schema_id }, update2, false);
      }

      const setString =
        "record_detail_config." + this.currentModelCard.config_object + ".detail_sections";
      let update = {
        $set: {
          [setString]: groups,
        },
      };

      await crudStore.updateOne(
        "PageLayouts",
        {
          collection_name: this.currentModelCard.collection,
        },
        update,
        false
      );

      const setString2 =
        "record_detail_config." + this.currentModelCard.config_object + ".record_detail_group";

      let res = await crudStore.updateMany(
        "Schema",
        {
          collection_name: this.currentModelCard.collection,
          [setString2]: { $nin: [...groups] },
        },
        {
          $set: {
            [setString2]: "",
          },
        }
      );
      //TODO: send user a notification about any properties that were moved to no group
      await this.fetchModelNuggets();
      await this.setGroupData();
      await this.set_QRs_RLs_DSs();
      this.model_is_loading = false;
    },

    async setPageLayout() {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();

      let newQRList = [];
      let newRLList = [];

      for (const list of this.RLList) {
        newRLList.push(list.name);
      }
      for (const reference of this.QRList) {
        newQRList.push(reference.name);
      }
      let group = this.DSList;

      const setString = "record_detail_config." + this.currentModelCard.config_object;
      let update = {
        $set: {
          [setString]: {
            detail_sections: group,
            related_lists: newRLList,
            quick_references: newQRList,
          },
          updated_by_id: authStore.currentUser.customData.user_id,
          updated_by_name: `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`,
          updated_date: new Date(),
        },
      };

      await crudStore.updateOne(
        "PageLayouts",
        {
          collection_name: this.currentModelCard.collection,
        },
        update,
        false
      );
    },

    async handleOrderUpdate(location, entry, direction) {
      const crudStore = useCrudStore();
      let swappedEntries = [];

      if (location === "nugget") {
        const index = this.infoNuggets.findIndex((nugget) => nugget === entry);
        if (direction === "up" && index > 0) {
          // Move nugget up
          [this.infoNuggets[index], this.infoNuggets[index - 1]] = [
            this.infoNuggets[index - 1],
            this.infoNuggets[index],
          ];
          swappedEntries.push(
            { _id: this.infoNuggets[index]._id.value, index: index },
            { _id: this.infoNuggets[index - 1]._id.value, index: index - 1 }
          );
        } else if (direction === "down" && index < this.infoNuggets.length - 1) {
          // Move nugget down
          [this.infoNuggets[index], this.infoNuggets[index + 1]] = [
            this.infoNuggets[index + 1],
            this.infoNuggets[index],
          ];
          swappedEntries.push(
            { _id: this.infoNuggets[index]._id.value, index: index },
            { _id: this.infoNuggets[index + 1]._id.value, index: index + 1 }
          );
        }
      } else {
        const group = location; // Assuming location is the group object in this case
        const index = group.fields.findIndex((field) => field === entry);
        if (direction === "up" && index > 0) {
          // Move item up
          [group.fields[index - 1], group.fields[index]] = [
            group.fields[index],
            group.fields[index - 1],
          ];
          swappedEntries.push(
            { _id: group.fields[index]._id.value, index: index },
            { _id: group.fields[index - 1]._id.value, index: index - 1 }
          );
        } else if (direction === "down" && index < group.fields.length - 1) {
          // Move item down
          [group.fields[index + 1], group.fields[index]] = [
            group.fields[index],
            group.fields[index + 1],
          ];
          swappedEntries.push(
            { _id: group.fields[index]._id.value, index: index },
            { _id: group.fields[index + 1]._id.value, index: index + 1 }
          );
        }
      }

      await this.handleSchemaOrderUpdate(location, swappedEntries);
    },

    async handleSchemaOrderUpdate(location, swappedEntries) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let item_id;
      let setString;
      let update;
      if (location === "nugget") {
        for (const nugget of swappedEntries) {
          item_id = nugget._id;
          setString =
            "record_detail_config." + this.currentModelCard.config_object + ".info_nugget_order";
          update = {
            [setString]: nugget.index + 1,
          };
          update = schemaStore.addCreatedUpdatedFields(update, false, true);
          await crudStore.updateOne("Schema", { _id: item_id }, { $set: update }, false);
        }
      } else {
        for (const item of swappedEntries) {
          item_id = item._id;

          setString =
            "record_detail_config." +
            this.currentModelCard.config_object +
            ".record_detail_group_order";

          update = {
            [setString]: item.index + 1,
          };
          update = schemaStore.addCreatedUpdatedFields(update, false, true);

          await crudStore.updateOne("Schema", { _id: item_id }, { $set: update }, false);
        }
      }
    },

    async updateProperty(property) {
      const crudStore = useCrudStore();
      const authStore = useAuthStore();
      const UIStore = useUIStore();
      const schemaStore = useSchemaStore();

      let restructured_field_type;
      if (property.field_type === "Text") {
        restructured_field_type = "string";
      } else if (property.field_type === "Long Text") {
        restructured_field_type = "long_text";
      } else if (property.field_type === "Array Of Ids") {
        restructured_field_type = "array_of_ids";
      } else {
        restructured_field_type = property.field_type.toLowerCase().split(" ").join("");
      }

      let restructured_detail_group;
      if (property.record_detail_group === "No Group") {
        restructured_detail_group = "";
      } else {
        restructured_detail_group = property.record_detail_group;
      }
      let item_number =
        this.fetchedTableData.find((item) => item.name === restructured_detail_group).fields
          .length + 1;

      let blank_config = {
        default_value: property.field_type === "Number" ? 0 : "",

        dropdown_options: [],
        is_info_nugget: false,
        record_detail_group: "",
        is_required: false,
        record_detail_group_order: item_number,
        info_nugget_order: 0,
      };

      let new_config = {
        default_value:
          property.field_type === "Number"
            ? Number(property.default_value)
            : property.default_value,
        dropdown_options:
          restructured_field_type === "dropdown" || restructured_field_type === "multiselect"
            ? property.dropdown_options
            : [],
        is_info_nugget: property.is_info_nugget,
        record_detail_group: restructured_detail_group,
        is_required: property.is_required,
        record_detail_group_order: item_number,
        info_nugget_order: 0,
      };

      let new_non_config_fields = {
        collection_name: this.currentModelCard.collection,
        label: property.label,
        field_name: property.field_name,
        field_type: restructured_field_type,
        number_type: restructured_field_type === "number" ? property.number_type.toLowerCase() : "",
        associated_collection:
          property.associated_collection === "No Collection" ? "" : property.associated_collection,
        associated_field_name: property.associated_field_name,
        is_association_field: property.is_association_field,
        base_url: property.base_url,
        associated_collection_query: property.associated_collection_query,
        original_collection_name: property.original_collection_name,
        field_regex: property.field_regex,
        error_message: property.error_message,
        nested_object_name: property.nested_object_name,
        associated_name_for_results: property.associated_name_for_results,
        migration_notes: property.migration_notes,
        v1_field_name: property.v1_field_name,
        is_object_id: property.is_object_id,
        has_copy: property.has_copy,
        available_for_symbology: property.available_for_symbology,
        available_for_filtering: property.available_for_filtering,
        belongs_to_nested_object: property.belongs_to_nested_object,
        accredited_only: property.accredited_only,
        show_in_search_results: property.show_in_search_results,
        show_in_related_list: property.show_in_related_list,
        read_only: property.read_only,
      };
      new_non_config_fields = schemaStore.addCreatedUpdatedFields(
        new_non_config_fields,
        true,
        true
      );

      let updated_non_config_fields = {
        collection_name: this.currentModelCard.collection,
        label: property.label,
        field_name: property.field_name,
        field_type: restructured_field_type,
        number_type: restructured_field_type === "number" ? property.number_type.toLowerCase() : "",
        associated_collection:
          property.associated_collection === "No Collection" ? "" : property.associated_collection,
        associated_field_name: property.associated_field_name,
        is_association_field: property.is_association_field,
        base_url: property.base_url,
        associated_collection_query: property.associated_collection_query,
        original_collection_name: property.original_collection_name,
        field_regex: property.field_regex,
        error_message: property.error_message,
        nested_object_name: property.nested_object_name,
        associated_name_for_results: property.associated_name_for_results,
        migration_notes: property.migration_notes,
        v1_field_name: property.v1_field_name,
        is_object_id: property.is_object_id,
        has_copy: property.has_copy,
        available_for_symbology: property.available_for_symbology,
        available_for_filtering: property.available_for_filtering,
        belongs_to_nested_object: property.belongs_to_nested_object,
        accredited_only: property.accredited_only,
        show_in_search_results: property.show_in_search_results,
        show_in_related_list: property.show_in_related_list,
        read_only: property.read_only,
      };
      updated_non_config_fields = schemaStore.addCreatedUpdatedFields(
        updated_non_config_fields,
        false,
        true
      );
      let newObject;
      //if you are creating a property
      if (this.propertyAction === "Create") {
        if (this.currentModelCard.has_multiple_configs) {
          // Initialize record_detail_config with blank configs for all options
          let record_detail_config = this.currentModelCard.all_config_options
            .filter((configKey) => configKey !== this.currentModelCard.config_object)
            .reduce((configObject, configKey) => {
              return { ...configObject, [configKey]: blank_config };
            }, {});

          if (this.currentModelCard.config_object !== "default") {
            // Set specified config_object to new_config and default to blank_config
            record_detail_config[this.currentModelCard.config_object] = new_config;
            record_detail_config["default"] = blank_config;
          } else {
            // Set default to new_config, other configs are already set to blank_config
            record_detail_config["default"] = new_config;
          }

          newObject = {
            ...new_non_config_fields,
            record_detail_config,
          };
        } else {
          // If not a multiple config scenario, set default config only
          newObject = {
            ...new_non_config_fields,
            record_detail_config: {
              default: new_config,
            },
          };
        }
      }

      let updateObject;
      //if you are editing a property
      if (this.propertyAction === "Edit") {
        const fetchedOriginalProperty = await crudStore.findOne("Schema", {
          _id: this.propertyToEdit._id.value,
        });

        let updated_config = {
          default_value:
            property.field_type === "Number"
              ? Number(property.default_value)
              : property.default_value,
          dropdown_options:
            restructured_field_type === "dropdown" || restructured_field_type === "multiselect"
              ? [...property.dropdown_options]
              : [],
          is_info_nugget: property.is_info_nugget,
          record_detail_group: restructured_detail_group,
          is_required: property.is_required,
          record_detail_group_order:
            fetchedOriginalProperty.record_detail_config[this.currentModelCard.config_object]
              .record_detail_group_order,
          info_nugget_order:
            fetchedOriginalProperty.record_detail_config[this.currentModelCard.config_object]
              .info_nugget_order,
        };

        if (this.currentModelCard.has_multiple_configs) {
          if (this.currentModelCard.config_object !== "default") {
            // Update only the specific config_object field
            updateObject = {
              ...updated_non_config_fields,
              [`record_detail_config.${this.currentModelCard.config_object}`]: updated_config,
            };
          } else {
            // Update only the default field
            updateObject = {
              ...updated_non_config_fields,
              "record_detail_config.default": updated_config,
            };
          }
        } else {
          // If not a multiple config scenario, update default config only
          updateObject = {
            ...updated_non_config_fields,
            "record_detail_config.default": updated_config,
          };
        }
      }

      if (this.propertyAction === "Create") {
        //Insert the new document

        // let result = await crudStore.insertOne("Schema", newObject);

        // if (!result) {
        // }

        let result = await crudStore.insertOne("Schema", newObject);

        if (!result) {
          await UIStore.createNotificationAlert({
            type: "Alert",
            message: `Failed to insert property`,
            subText: `Check the custom logs for details`,
            action: { type: "route", payload: `/data/logs` },
          });
        }
      }
      if (this.propertyAction === "Edit") {
        // Query for a doc with an id that matches the property to edit's id
        let query = { _id: this.propertyToEdit._id.value };

        // Fetch original record
        const originalRecord = await crudStore.findOne("Schema", query);

        // Prepare updateObject (assuming it's already defined in your scope)

        const fieldsToIgnore = ["updated_by_name", "updated_date", "updated_by_id"];

        // Deep comparison function
        function deepEqual(obj1, obj2) {
          if (obj1 === obj2) {
            return true;
          }
          if (
            typeof obj1 !== "object" ||
            typeof obj2 !== "object" ||
            obj1 == null ||
            obj2 == null
          ) {
            return false;
          }

          let keys1 = Object.keys(obj1);
          let keys2 = Object.keys(obj2);

          if (keys1.length !== keys2.length) {
            return false;
          }

          for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
              return false;
            }
          }

          return true;
        }

        // Compare it to the updateObject
        let isDifferent = false;
        for (const key in updateObject) {
          if (fieldsToIgnore.includes(key)) {
            continue;
          }

          let originalValue = originalRecord[key];
          let updatedValue = updateObject[key];

          // Use deepEqual for comparison
          if (!deepEqual(originalValue, updatedValue)) {
            isDifferent = true;
          }
        }

        // Only perform operation if there is a difference
        if (isDifferent) {
          await crudStore.updateOne("Schema", query, { $set: updateObject }, false);
        } else {
        }
      }
      this.propertyUpdateTic++;
    },
    async insertModel() {
      const crudStore = useCrudStore();

      try {
        let result = await crudStore.findOne("Settings", {
          collection: this.model_being_edited.collection,
          type: "data_model_object",
        });

        if (result) {
          await this.updateExistingModel(result);
        } else {
          await this.insertNewModel();
        }

        this.resetTic++;
      } catch (error) {
        console.error("Error inserting model: ", error);
      }
    },

    async updateExistingModel(result) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let net_new_configs = this.model_being_edited.all_config_options.filter(
        (option) => !result.all_config_options.includes(option)
      );

      if (net_new_configs.length) {
        let current_pageLayout_document = await crudStore.findOne("PageLayouts", {
          collection_name: this.model_being_edited.collection,
        });

        let default_page_layout_config = this.getDefaultPageLayoutConfig(
          current_pageLayout_document
        );
        this.addNewConfigsToPageLayout(
          net_new_configs,
          current_pageLayout_document,
          default_page_layout_config
        );

        await this.updatePageLayoutDocument(current_pageLayout_document);
        await this.updateRelatedSchemaDocuments(net_new_configs);
      }

      this.model_being_edited = schemaStore.addCreatedUpdatedFields(
        this.model_being_edited,
        false,
        true
      );
      this.model_being_edited.icon_color_bg = `bg-${this.model_being_edited.icon_color}`;
      this.model_being_edited.icon_color_fill = `fill-${this.model_being_edited.icon_color}`;
      this.model_being_edited.icon_color_bg2 = `bg-${this.model_being_edited.icon_color2}`;
      this.model_being_edited.icon_color_fill2 = `fill-${this.model_being_edited.icon_color2}`;

      await crudStore.updateOne(
        "Settings",
        {
          collection: this.model_being_edited.collection,
          type: "data_model_object",
        },
        { $set: this.model_being_edited },
        false
      );
    },

    async updatePageLayoutDocument(current_pageLayout_document) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      current_pageLayout_document = schemaStore.addCreatedUpdatedFields(
        current_pageLayout_document,
        false,
        true
      );

      await crudStore.updateOne(
        "PageLayouts",
        {
          collection_name: this.model_being_edited.collection,
        },
        { $set: current_pageLayout_document },
        true
      );
    },

    async updateRelatedSchemaDocuments(net_new_configs) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let related_schema_docs = await crudStore.find("Schema", {
        collection_name: this.model_being_edited.collection,
      });

      related_schema_docs.forEach(async (document) => {
        let example_config_object = document.record_detail_config.default
          ? document.record_detail_config.default
          : {
              default_value: document.field_type === "number" ? 0 : "",
              dropdown_options: [],
              is_info_nugget: false,
              record_detail_group: "",
              is_required: false,
              record_detail_group_order: 0,
              info_nugget_order: 0,
            };

        net_new_configs.forEach((newConfig) => {
          if (!document.record_detail_config.hasOwnProperty(newConfig)) {
            document.record_detail_config[newConfig] = { ...example_config_object };
          }
        });

        document = schemaStore.addCreatedUpdatedFields(document, false, true);

        await crudStore.updateOne("Schema", { _id: document._id }, { $set: document }, false);
      });
    },

    async insertNewModel() {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let examplePageLayout = {
        collection_name: this.model_being_edited.collection,
        record_detail_config: {
          default: {
            detail_sections: ["System Information"],
            related_lists: [],
            quick_references: [],
          },
        },
      };

      examplePageLayout = schemaStore.addCreatedUpdatedFields(examplePageLayout, true, true);

      this.model_being_edited.all_config_options.forEach((option) => {
        if (option !== "default") {
          examplePageLayout.record_detail_config[option] = {
            detail_sections: ["System Information"],
            related_lists: [],
            quick_references: [],
          };
        }
      });

      await crudStore.insertOne("PageLayouts", examplePageLayout);

      let standardSchemaDocs = [
        this.createFieldObject("Updated By Id", "updated_by_id", "string"),
        this.createFieldObject(
          "Updated By Name",
          "updated_by_name",
          "string",
          "System Information"
        ),
        this.createFieldObject("Updated Date", "updated_date", "date", "System Information"),
        this.createFieldObject("Created By Id", "created_by_id", "string"),
        this.createFieldObject(
          "Created By Name",
          "created_by_name",
          "string",
          "System Information"
        ),
        this.createFieldObject("Created Date", "created_date", "date", "System Information"),
      ];

      let docs_to_insert = standardSchemaDocs.map((doc) => {
        const newDoc = { ...doc };
        this.model_being_edited.all_config_options.forEach((option) => {
          if (option !== "default") {
            newDoc.record_detail_config[option] = newDoc.record_detail_config.default;
          }
        });
        return newDoc;
      });
      await crudStore.insertMany("Schema", docs_to_insert);
      this.model_being_edited = schemaStore.addCreatedUpdatedFields(
        this.model_being_edited,
        true,
        false
      );
      this.model_being_edited.icon_color_bg = `bg-${this.model_being_edited.icon_color}`;
      this.model_being_edited.icon_color_fill = `fill-${this.model_being_edited.icon_color}`;

      await crudStore.insertOne("Settings", this.model_being_edited);
    },

    async formatPropertyToEdit() {
      const crudStore = useCrudStore();
      let propertyObject;
      if (this.propertyToEdit) {
        const propertyID = this.propertyToEdit._id.value;
        const fetchedProperty = await crudStore.findOne("Schema", { _id: propertyID });

        let formatted_field_type;
        if (fetchedProperty.field_type === "string") {
          formatted_field_type = "Text";
        } else if (fetchedProperty.field_type === "long_text") {
          formatted_field_type = "Long Text";
        } else if (fetchedProperty.field_type === "multiselect") {
          formatted_field_type = "Multi Select";
        } else if (fetchedProperty.field_type === "array_of_ids") {
          formatted_field_type = "Array Of Ids";
        } else {
          formatted_field_type = fetchedProperty.field_type
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }

        let dropdown_options_calc;
        if (
          fetchedProperty.field_type === "dropdown" ||
          fetchedProperty.field_type === "multiselect"
        ) {
          dropdown_options_calc =
            fetchedProperty.record_detail_config[this.currentModelCard.config_object]
              .dropdown_options;

          //  dropdown_options_calc = fetchedProperty.record_detail_config.default.dropdown_options;
        } else {
          dropdown_options_calc = [];
        }
        let formatted_number_type;
        if (fetchedProperty.number_type && fetchedProperty.number_type !== "") {
          formatted_number_type =
            fetchedProperty.number_type.charAt(0).toUpperCase() +
            fetchedProperty.number_type.slice(1);
        } else {
          formatted_number_type = "";
        }

        propertyObject = {
          created_by: "",
          is_info_nugget:
            fetchedProperty.record_detail_config[this.currentModelCard.config_object]
              .is_info_nugget,
          label: fetchedProperty.label,
          field_name: fetchedProperty.field_name,
          field_type: formatted_field_type,
          record_detail_group:
            fetchedProperty.record_detail_config[this.currentModelCard.config_object]
              .record_detail_group === ""
              ? "No Group"
              : fetchedProperty.record_detail_config[this.currentModelCard.config_object]
                  .record_detail_group,

          default_value:
            fetchedProperty.record_detail_config[this.currentModelCard.config_object].default_value,
          dropdown_options: dropdown_options_calc,

          is_required:
            fetchedProperty.record_detail_config[this.currentModelCard.config_object].is_required,
          show_in_search_results: fetchedProperty.show_in_search_results,
          show_in_related_list: fetchedProperty.show_in_related_list
            ? fetchedProperty.show_in_related_list
            : false,
          associated_collection: fetchedProperty.associated_collection
            ? fetchedProperty.associated_collection
            : "",
          associated_field_name: fetchedProperty.associated_field_name
            ? fetchedProperty.associated_field_name
            : "",
          is_association_field: fetchedProperty.is_association_field
            ? fetchedProperty.is_association_field
            : false,
          belongs_to_nested_object: fetchedProperty.belongs_to_nested_object
            ? fetchedProperty.belongs_to_nested_object
            : false,
          accredited_only: fetchedProperty.accredited_only
            ? fetchedProperty.accredited_only
            : false,
          base_url: fetchedProperty.base_url ? fetchedProperty.base_url : "",
          associated_name_for_results: fetchedProperty.associated_name_for_results
            ? fetchedProperty.associated_name_for_results
            : "",
          migration_notes: fetchedProperty.migration_notes ? fetchedProperty.migration_notes : "",
          v1_field_name: fetchedProperty.v1_field_name ? fetchedProperty.v1_field_name : "",
          associated_collection_query: fetchedProperty.associated_collection_query
            ? fetchedProperty.associated_collection_query
            : "",
          original_collection_name: fetchedProperty.original_collection_name
            ? fetchedProperty.original_collection_name
            : "",
          field_regex: fetchedProperty.field_regex ? fetchedProperty.field_regex : "",
          error_message: fetchedProperty.error_message ? fetchedProperty.error_message : "",
          nested_object_name: fetchedProperty.nested_object_name
            ? fetchedProperty.nested_object_name
            : "",
          available_for_filtering: fetchedProperty.available_for_filtering,
          read_only: fetchedProperty.read_only,
          available_for_symbology: fetchedProperty.available_for_symbology,
          is_object_id: fetchedProperty.is_object_id ? fetchedProperty.is_object_id : false,
          has_copy: fetchedProperty.has_copy ? fetchedProperty.has_copy : false,
          number_type: formatted_number_type
            ? fetchedProperty.number_type.charAt(0).toUpperCase() +
              fetchedProperty.number_type.slice(1)
            : "",
        };
      } else {
        this.default_property_object.record_detail_group = _.cloneDeep(this.current_property_group);

        const template = _.cloneDeep(this.default_property_object);
        propertyObject = template;
      }

      return propertyObject;
    },
    // async fetchHistorySearchOptions() {
    //   const reportStore = useReportStore();
    //   const crudStore = useCrudStore();

    //   let collection = "History";

    //   let results = await crudStore.find(
    //     "Schema",
    //     {
    //       collection_name: collection,
    //       available_for_filtering: true,
    //     },
    //     { sort: { label: 1 } }
    //   );

    //   const searchTerms = results.map((result) => {
    //     return {
    //       collection_name: collection,
    //       dropdown_options:
    //         result.record_detail_config && result.record_detail_config.default
    //           ? result.record_detail_config.default.dropdown_options
    //           : undefined,
    //       field_name: result.field_name,
    //       field_type: result.field_type,
    //       label: result.label,
    //       is_object_id: result.is_object_id,
    //       has_copy: result.has_copy,
    //     };
    //   });

    //   const searchObject = {
    //     collection_name: collection,
    //     fields: searchTerms, // array of formatted search terms
    //   };

    //   reportStore.possibleColumns.shift();
    //   reportStore.possibleColumns.push(searchObject);
    // },
    async fetchHistoryTableRecords() {
      const crudStore = useCrudStore();
      const recordDetailStore = useRecordDetailStore();
      const UIStore = useUIStore();
      UIStore.isLoading = true;
      let collection = "History";

      //fetch info nuggets for drawer

      let header_pipeline = [
        {
          $match: {
            collection_name: "History",
            "record_detail_config.default.is_info_nugget": true,
          },
        },
        {
          $sort: {
            "record_detail_config.default.info_nugget_order": 1,
          },
        },
        {
          $project: {
            _id: "$_id",
            label: "$label",
            field_name: "$field_name",
            field_type: "$field_type",
            number_type: "$number_type",
            id_object_id: "$is_object_id",
            has_copy: "$has_copy",
            read_only: "$read_only",
            updated_by_name: "$updated_by_name",
            updated_by_id: "$updated_by_id",
            updated_date: "$updated_date",
            v1_field_name: "$v1_field_name",
            migration_notes: "$migration_notes",
          },
        },
      ];

      this.historyNuggets = await crudStore.aggregate("Schema", header_pipeline);

      try {
        const skipValue = (this.historyPageNumber - 1) * this.historyPageSize;
        const skipStage = { $skip: skipValue };
        const sortStage = { $sort: { updated_date: -1 } };

        const limitStage = { $limit: this.historyPageSize };

        const matchStage = { $match: recordDetailStore.filterPipeline };

        let pipeline = [sortStage, skipStage, limitStage];

        if (recordDetailStore.filterPipeline !== null) {
          pipeline.unshift(matchStage);
        }

        const fetchedRecords = await crudStore.aggregate(collection, pipeline);

        this.historyTableRecordCount = await crudStore.countRecords(collection, {
          ...recordDetailStore.filterPipeline,
        });

        const transformedRecords = recordDetailStore.transformRecords(fetchedRecords);

        this.historyRecords = transformedRecords;
        UIStore.isLoading = false;
        return transformedRecords;
      } catch (error) {}
    },
    async updateListItems(list) {
      const crudStore = useCrudStore();
      const schemaStore = useSchemaStore();
      let og_list = this.all_available_lists.find(
        (item) => item._id === this.list_being_edited._id
      );
      if (og_list) {
        if (
          !this.arraysEqual(og_list.options, list) ||
          og_list.reference !== this.list_being_edited.reference ||
          og_list.card_name !== this.list_being_edited.card_name ||
          og_list.description !== this.list_being_edited.description
        ) {
          let query = { _id: this.list_being_edited._id };
          let update = {
            reference: this.list_being_edited.reference,
            options: list,
            card_name: this.list_being_edited.card_name,
            description: this.list_being_edited.description,
            is_list: true,
          };
          update = schemaStore.addCreatedUpdatedFields(update, false, true);

          await crudStore.updateOne("Settings", query, { $set: update });

          this.resetTic++;
        }
      } else {
        let new_list = {
          reference: this.list_being_edited.reference,
          options: list,
          card_name: this.list_being_edited.card_name,
          description: this.list_being_edited.description,
          is_list: true,
        };
        new_list = schemaStore.addCreatedUpdatedFields(new_list, true, true);
        await crudStore.insertOne("Settings", new_list);
        this.resetTic++;
      }
    },
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    getDefaultPageLayoutConfig(current_pageLayout_document) {
      return current_pageLayout_document.record_detail_config.default
        ? current_pageLayout_document.record_detail_config.default
        : {
            quick_references: [],
            related_lists: [],
            detail_sections: ["System Information"],
          };
    },
    addNewConfigsToPageLayout(
      net_new_configs,
      current_pageLayout_document,
      default_page_layout_config
    ) {
      net_new_configs.forEach((newConfig) => {
        if (!current_pageLayout_document.record_detail_config.hasOwnProperty(newConfig)) {
          current_pageLayout_document.record_detail_config[newConfig] = {
            ...default_page_layout_config,
          };
        }
      });
    },
    createFieldObject(
      label,
      fieldName,
      fieldType,
      recordDetailGroup = "",
      recordDetailGroupOrder = 0,
      isInfoNugget = false,
      infoNuggetOrder = 0
    ) {
      const schemaStore = useSchemaStore();
      return schemaStore.addCreatedUpdatedFields(
        {
          collection_name: this.model_being_edited.collection,
          label: label,
          field_name: fieldName,
          field_type: fieldType,
          record_detail_config: {
            default: {
              default_value: "",
              dropdown_options: [],
              is_info_nugget: isInfoNugget,
              record_detail_group: recordDetailGroup,
              is_required: false,
              record_detail_group_order: recordDetailGroupOrder,
              info_nugget_order: infoNuggetOrder,
            },
          },
          available_for_symbology: false,
          show_in_search_results: false,
          show_in_related_list: false,
          available_for_filtering: true,
          belongs_to_nested_object: false,
          accredited_only: false,
          read_only: true,
          number_type: "",
          associated_collection: "",
          associated_field_name: "",
          is_association_field: false,
          base_url: "",
          associated_name_for_results: "",
          v1_field_name: "",
          migration_notes: "",
          associated_collection_query: "",
          original_collection_name: "",
          error_message: "",
          field_regex: "",
          nested_object_name: "",
          is_object_id: false,
          has_copy: false,
        },
        true,
        true,
        true
      );
    },
    // async updatev1fieldNames() {
    //   const crudStore = useCrudStore();

    //   // Aggregate to get the documents where 'field_name' matches one of the values in the list
    //   let docs = await crudStore.aggregate("Schema", [
    //     {
    //       $match: {
    //         field_name: {
    //           $in: [
    //             "last_payment_date",
    //             "effective_date",
    //             "custodian_id",
    //             "child_investments",
    //             "custodian_account_number",
    //             "is_compounded",
    //             "is_discounted_bond",
    //             "amount",
    //             "created_date",
    //             "signature_date",
    //             "offering_id",
    //             "is_paper_sub_complete",
    //             "transfer_completed_date",
    //             "sync_to_ark",
    //             "notes",
    //             "transferee_joint_signature_date",
    //             "inception_to_date_interest_paid",
    //             "transferor_entity_signature_date",
    //             "approval_status",
    //             "modified_by",
    //             "amount_reinvested",
    //             "transferor_joint_signature_date",
    //             "parent_investment",
    //             "user_id",
    //             "close_date",
    //             "payment_method",
    //             "current_nav",
    //             "type",
    //             "dalmore_notes",
    //             "rollover_available",
    //             "all_contacts_identity_verified",
    //             "first_payment_date",
    //             "able_to_rollover",
    //             "_id",
    //             "transferred_from_first_payment_date",
    //             "transferee_custodian_signature_date",
    //             "account_id",
    //             "discounted_bond_amount",
    //             "contact_id",
    //             "is_title_transfer",
    //             "cancelled_date",
    //             "updated_date",
    //             "approval_date",
    //             "accretion_YTM",
    //             "transferee_signature_date",
    //             "funded_date",
    //             "_id__baas_transaction",
    //             "ready_for_signature",
    //             "is_paper_sub",
    //             "transferor_signature_date",
    //             "status",
    //             "dalmore_notes_date",
    //             "bonds",
    //             "ach_batch_id",
    //             "amount_at_maturity",
    //             "transferor_custodian_signature_date",
    //             "add_to_ach_batch",
    //             "transferee_entity_signature_date",
    //             "is_rollover",
    //             "maturity_date",
    //             "year_to_date_interest_paid",
    //           ],
    //         },

    //         collection_name: "Investments",
    //       },
    //     },
    //   ]);
    // },
  },
});
