<template>
  <!-- Loop through the filtered schema -->
  <div
    class="w-full border-platform-outlines border-b grid grid.cols-1 md:grid-cols-4 py-4 gap-4 font-medium mb-4"
    :class="filteredSchema.length > 6 ? 'lg:grid-cols-6' : 'lg:grid-cols-8'"
  >
    <div v-for="field in filteredSchema" :key="field.field_name">
      <div class="text-xs text-lighter uppercase font-medium tracking-wideish white">
        {{ field.label }}
      </div>
      <div v-if="field.field_type == 'date'" class="text-xl text-dark font-bold">
        {{
          recordDetailStore.currentPageDocument[field.field_name]
            ? new Date(recordDetailStore.currentPageDocument[field.field_name]).toLocaleDateString(
                "en-US",
                {
                  timeZone: "UTC", // Set time zone to UTC to prevent shifting
                }
              )
            : "-"
        }}
      </div>
      <div v-else class="text-xl text-dark font-bold">
        {{
          recordDetailStore.currentPageDocument[field.field_name]
            ? recordDetailStore.currentPageDocument[field.field_name]
            : "-"
        }}
      </div>
    </div>
  </div>
  <div class="w-full">
    <TransferParentsBanner v-if="recordDetailStore.fetched_transfer_parents.length" />
    <div
      class="border-b border-platform-outlines mb-4"
      v-if="
        recordDetailStore.fetched_transfer_children.length &&
        recordDetailStore.fetched_transfer_parents.length
      "
    ></div>
    <TransferChildrenBanner v-if="recordDetailStore.fetched_transfer_children.length" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import TransferParentsBanner from "./TransferParentsBanner.vue";
import TransferChildrenBanner from "./TransferChildrenBanner.vue";
import { useRecordDetailStore, useSchemaStore } from "@/stores";

const recordDetailStore = useRecordDetailStore();
const schemaStore = useSchemaStore();

// Computed property to filter out items where the value is not null or an empty string
const filteredSchema = computed(() => {
  return schemaStore.title_transfer_schema.filter((field) => {
    const value = recordDetailStore.currentPageDocument[field.field_name];
    return value !== null && value !== "" && value !== undefined;
  });
});
</script>
