<template>
  <Modal :open="props.open" @close-modal="$emit('close-modal')" size="26x38" :hideModalTabs="true">
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div>

        <div class="font-bold text-custom-navy-2">Edit Investment</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white flex flex-col gap-4 p-4">
        <BaseInput
          v-model="investment_amount"
          type="string"
          label="Investment Amount"
          size="large"
          required
          formatRule="amount"
          roundThousands
        /><BaseInput
          v-model="discount_amount"
          type="string"
          label="Discount Amount"
          size="large"
          required
          formatRule="amount"
        />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template></Button
        >
        <Button size="large" :disabled="isSaveDisabled" label @click="updateInvestment">
          <template #label>Update</template></Button
        >
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconInvestment } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRecordDetailStore, useCrudStore, useInvestmentStore } from "@/stores";
import { Modal, Button, BaseInput } from "@/components";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const investmentStore = useInvestmentStore();

const investment_amount = ref(recordDetailStore.currentPageDocument.amount);
const discount_amount = ref(recordDetailStore.currentPageDocument.discounted_bond_amount);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close-modal"]);

const isSaveDisabled = computed(() => {
  return false;
});

async function updateInvestment() {
  console.log("Updating investment");
  emit("close-modal");
  await investmentStore.updateInvestmentAmounts({
    _id: recordDetailStore.currentPageDocument._id,
    investment_amount: investment_amount.value,
    discount_amount: discount_amount.value,
  });
  recordDetailStore.updateTic++;
}

watch(
  () => props.open,
  () => {
    investment_amount.value = recordDetailStore.currentPageDocument.amount;
    discount_amount.value = recordDetailStore.currentPageDocument.discounted_bond_amount;
  }
);
</script>
