import { defineStore } from "pinia";
import * as Realm from "realm-web";

import { useCrudStore, useAuthStore } from "@/stores";
import { commaSeparateThousands_2 } from "@/utilities";
const {
  BSON: { ObjectId },
} = Realm;

export const useACHBatchStore = defineStore("achBatchStore", {
  state: () => ({
    // investment processing
    investment_ids_to_process: [],
    achBatchData: [],
    currentAchBatch: null,
  }),
  getters: {},
  actions: {
    async fetchACHBatches(date) {
      const crudStore = useCrudStore();
      console.log("fetchACHBatches", date);
      try {
        const achBatchData = await crudStore.findOne("ACHBatches", {
          batch_date: new Date(date),
        });
        this.currentAchBatch = achBatchData;
        console.log("ACH Batch", this.currentAchBatch);
        this.achBatchData = achBatchData.batch_data;
        console.log("Batch Data Array", this.achBatchData);
      } catch (err) {
        console.error(err);
      }
    },
    async removeInvestmentFromBatch(investment_id) {
      const crudStore = useCrudStore();
      // Create a Set to ensure uniqueness
      const idsToSet = new Set(
        this.currentAchBatch.investment_ids
          .map((id) => id.toString())
          ?.filter((id) => id !== investment_id)
          ?.map((id) => ObjectId(id))
      );

      const uniqueIdsArray = Array.from(idsToSet);

      try {
        await Promise.all([
          crudStore.updateOne(
            "Investments",
            { _id: ObjectId(investment_id) },
            { $set: { ach_batch_id: null } }
          ),
          crudStore.updateOne(
            "ACHBatches",
            { _id: ObjectId(this.currentAchBatch._id) },
            {
              $pull: { batch_data: { investment_id } },
              $set: { investment_ids: uniqueIdsArray },
            }
          ),
        ]);
      } catch (err) {
        console.error(err);
      }
    },
    async addInvestmentsToBatch(investment_ids) {
      const crudStore = useCrudStore();
      try {
        const investmentOids = investment_ids.map((id) => ObjectId(id));
        const pipeline = [
          { $match: { _id: { $in: investmentOids } } },
          {
            $lookup: {
              from: "Contacts",
              localField: "contact_id",
              foreignField: "_id",
              as: "contact",
            },
          },
          { $unwind: "$contact" },
          {
            $lookup: {
              from: "Offerings",
              localField: "offering_id",
              foreignField: "_id",
              as: "offering",
            },
          },
          { $unwind: "$offering" },
          {
            $lookup: {
              from: "BankAccounts",
              localField: "funding_account",
              foreignField: "_id",
              as: "funding_account",
            },
          },
          {
            $unwind: {
              path: "$funding_account",
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $project: {
              account_number: { $ifNull: ["$funding_account.account_number", null] },
              routing_number: { $ifNull: ["$funding_account.routing_number", null] },
              account_type: { $ifNull: ["$funding_account.account_type", null] },
              amount: {
                $cond: {
                  if: "$is_discounted_bond",
                  then: "$discounted_bond_amount",
                  else: "$amount",
                },
              },
              investment_id: { $toString: "$_id" },
              investment_type: "$type",
              is_discounted_bond: "$is_discounted_bond",
              name: { $concat: ["$contact.first_name", " ", "$contact.last_name"] },
              offering_type: "$offering.name",
              routing_number: "$funding_account.routing_number",
              signature_date: "$signature_date",
              state: "$contact.state",
              user_id: "$user_id",
              contact_id: "$contact_id",
            },
          },
        ];

        const investment_with_ach_batch_info = await crudStore.aggregate("Investments", pipeline);

        const batchDataToPush = [];
        const investmentIdsToPush = [];

        for (let investment of investment_with_ach_batch_info) {
          delete investment._id;
          investment.amount = commaSeparateThousands_2(investment.amount);
          investment.signature_date = new Date(investment.signature_date);
          this.achBatchData.push(investment);
          investmentIdsToPush.push(ObjectId(investment.investment_id));
          batchDataToPush.push(investment);
        }

        await crudStore.updateOne(
          "ACHBatches",
          { _id: ObjectId(this.currentAchBatch._id) },
          {
            $push: {
              batch_data: { $each: batchDataToPush },
              investment_ids: { $each: investmentIdsToPush },
            },
          }
        );
        await crudStore.updateOne(
          "Investments",
          { _id: { $in: investmentIdsToPush } },
          { $set: { ach_batch_id: ObjectId(this.currentAchBatch._id) } }
        );
      } catch (err) {
        console.error(err);
      }
    },
    async generateDistributionData() {
      const authStore = useAuthStore();
      const currentUser = authStore.currentUser;
      try {
        const response = await fetch(
          `${import.meta.env.VITE_INVESTMENT_SERVICES_ENDPOINT}/generate-distribution-data`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              user_id: currentUser.id,
              session_id: currentUser.session_id,
            },
          }
        );
        const result = await response.json();
        console.log(result, "result from starting the generate distribution data");
      } catch (err) {
        console.error(err);
      }
    },
  },
});
