<template>
  <SideDrawer
    @close-drawer="$emit('close-drawer')"
    :open="open"
    customWidth="lg:w-3/4"
    id="quick-ref-rel-list"
    :hideHeader="true"
    :showFooter="true"
  >
    <template v-slot:tabs> <div class="font-semibold">Create Investor</div> </template>

    <template v-slot:main-content>
      <div class="p-4">
        <div class="flex flex-col">
          <div
            v-for="(group, index) in schemaStore.non_accredited_owner_contact_personal_info_schema"
            :key="index"
            :class="{
              'py-4 border-b border-platform-outlines':
                index !== schemaStore.non_accredited_owner_contact_personal_info_schema.length - 1,
              'py-4':
                index === schemaStore.non_accredited_owner_contact_personal_info_schema.length - 1,
            }"
          >
            <div class="font-semibold text-lg mb-4">
              {{ group.group_name }}
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div v-for="field of group.fields">
                <BaseInput
                  :required="field.is_required"
                  :label="field.label"
                  v-if="!field.is_association_field && !field.belongs_to_nested_object"
                  v-model="investorStore.new_investor[field.field_name]"
                  :formatRule="field.field_name"
                  :errorMessage="field.error_message"
                  :type="field.field_type"
                  size="large"
                  :options="field.dropdown_options"
                  customClass="bg-neutral-gray-1"
                ></BaseInput>
                <BaseInput
                  :required="field.is_required"
                  :formatRule="field.field_name"
                  :errorMessage="field.error_message"
                  :options="field.dropdown_options"
                  :label="field.label"
                  v-if="!field.is_association_field && field.belongs_to_nested_object"
                  v-model="investorStore.new_investor[field.nested_object_name][field.field_name]"
                  :type="field.field_type"
                  size="large"
                  customClass="bg-neutral-gray-1"
                ></BaseInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer
      ><div class="w-full flex justify-end">
        <Button :disabled="nextIsDisabled" @click="createInvestor" label>
          <template #label>Save</template>
        </Button>
      </div>
    </template>
  </SideDrawer>
</template>
<script setup>
import { watch, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { SideDrawer, Button } from "@/components";
import { useSchemaStore, useInvestorStore, useCreateInvestmentStore, useUIStore } from "@/stores";
import { BaseInput } from "@/components";
import _ from "lodash";
import { STATES } from "@/constants";

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const schemaStore = useSchemaStore();
const investorStore = useInvestorStore();
const createInvestmentStore = useCreateInvestmentStore();
const UIStore = useUIStore();
const router = useRouter();

watch(
  () => props.open,
  async () => {
    if (props.open) {
      let template = schemaStore.createInvestorTemplate();
      investorStore.new_investor = _.cloneDeep(template);
    }
  }
);

const validateField = (fieldValue, regex) => {
  if (!fieldValue) {
    return false;
  }
  if (regex && !regex.test(fieldValue)) {
    return false;
  }
  return true;
};

const isFieldValid = (currentContact, field, regex) => {
  if (field.belongs_to_nested_object) {
    const nestedValue = currentContact[field.nested_object_name]?.[field.field_name];
    if (!validateField(nestedValue, regex)) {
      return false;
    }
  } else {
    const fieldValue = currentContact[field.field_name];
    if (!validateField(fieldValue, regex)) {
      return false;
    }
  }
  return true;
};

const validateContactInfo = (currentContact, schema) => {
  for (const group of schema) {
    for (const field of group.fields) {
      if (field.is_required) {
        let regex;
        switch (field.field_name) {
          case "phone":
            regex = /^\(\d{3}\) \d{3}-\d{4}$/;
            break;
          case "email":
            regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            break;
          case "ssn":
            regex = /^\d{3}-\d{2}-\d{4}$/;
            break;
          case "ein":
            regex = /^\d{2}-\d{7}$/;
            break;
          // case "state":
          //   if (!STATES.includes(currentContact[field.field_name])) {
          //     return true;
          //   }
          // continue;
          default:
            regex = null;
        }
        if (!isFieldValid(currentContact, field, regex)) {
          return true;
        }
      }
    }
  }
  return false;
};

const nextIsDisabled = computed(() => {
  let val = validateContactInfo(
    investorStore.new_investor,
    schemaStore.non_accredited_owner_contact_personal_info_schema
  );
  return val;
});

async function createInvestor() {
  investorStore.new_investor_drawer_open = false;
  createInvestmentStore.investment_being_created = true;
  let new_contact_id = await investorStore.createNewInvestor();
  if (new_contact_id) {
    router.push(`/contacts?recordId=${new_contact_id}`);
    createInvestmentStore.investment_being_created = false;
  } else {
    createInvestmentStore.investment_being_created = false;
    UIStore.animateNotificationAlert({
      type: "error",
      icon: "X",
      message: "Failed to create new investor",
      subText: "Investor with that email already exists",
      is_read: false,
      does_persist: false,
      action: null,
    });
  }
}
</script>
