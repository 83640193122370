<template>
  <div>
    <AddBeneficiaryModal
      :open="isBeneficiaryModalOpen"
      @close-modal="isBeneficiaryModalOpen = false"
      @handle-add-beneficiary="investorStore.tempBeneficialOwners.push($event)"
      fromProfile
    />
    <SideDrawer
      @close-drawer="$emit('close-drawer')"
      :open="open"
      customWidth="lg:w-1/2"
      hideHeader
      showFooter
    >
      <template v-slot:tabs>
        <div class="font-semibold mt-1">
          Add {{ type }}
          {{ type === "Joint" || type === "Beneficial Owner" ? "Contact" : "Account" }}
        </div>
      </template>
      <template v-slot:main-content>
        <div class="flex flex-col w-full p-6">
          <template v-for="(schema, index) of schemaToUse" :key="index">
            <Form
              :fields="schema.fields"
              :record="props.record"
              :header="schema.group_name"
              :subHeader="
                index === 1
                  ? 'Your physical address. PO Boxes and mail drops are not valid.'
                  : index === 2
                    ? 'Your passport or government issued ID (driver\'s license).'
                    : ''
              "
              :isFormValid="isFormValid"
              isSmall
              :type="props.type.toLowerCase()"
              isNew
            />
            <div
              v-if="index !== schemaToUse.length - 1"
              class="border-b border-neutral-gray-4 w-full my-4 md:my-8"
            ></div>
          </template>
          <div v-if="type === 'Entity'">
            <div class="border-b border-neutral-gray-4 w-full my-4 md:my-8"></div>
            <BeneficialOwners
              @open-modal="isBeneficiaryModalOpen = true"
              :beneficial_owners="investorStore.tempBeneficialOwners"
              :active_entity="props.record"
              isSmall
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <Button
            @click="handleSave"
            :disabled="!isFormValid"
            label
            icon
            size="large"
            class="w-full md:w-32"
          >
            <template v-if="isAddLoading" #label>
              <IconPulseSpinner class="h-6 w-6 stroke-white" />
            </template>
            <template v-else #label>Save</template>
          </Button>
        </div>
      </template>
    </SideDrawer>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

import { useInvestmentStore, useInvestorStore, useSchemaStore } from "@/stores";
import { SideDrawer, Form, Button } from "@/components";
import { IconPulseSpinner } from "@/components/icons";
import { validateField } from "@/utilities";
import BeneficialOwners from "./BeneficialOwners.vue";
import AddBeneficiaryModal from "./AddBeneficiaryModal.vue";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  record: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["close-drawer", "handle-save"]);

const investmentStore = useInvestmentStore();
const investorStore = useInvestorStore();
const schemaStore = useSchemaStore();

const isAddLoading = ref(false);
const isBeneficiaryModalOpen = ref(false);
const beneficialOwners = ref([]);

onMounted(async () => {
  window.scrollTo(0, 0);
});

const schemaToUse = computed(() => {
  if (props.type === "Joint") {
    return investmentStore.hasAccreditedInvestments
      ? schemaStore.accredited_joint_contact_personal_info_schema
      : schemaStore.non_accredited_joint_contact_personal_info_schema;
  } else if (props.type === "Entity") {
    return schemaStore.entity_information;
  }

  return [];
});

const isFormValid = computed(() => {
  if (!schemaToUse.value || schemaToUse.value.length === 0) {
    return false;
  }

  // beneficial owner validation
  if (props.type === "Entity") {
    if (!investorStore.isOwnerBeneficialOwner && investorStore.tempBeneficialOwners.length === 0) {
      return false;
    }

    if (investorStore.hasSpecialError === "ein") {
      return false;
    }
  } else if (props.type === "Joint") {
    if (
      investorStore.hasSpecialError === "ssn" ||
      investorStore.hasSpecialError === "email" ||
      investorStore.hasSpecialError === "id_number"
    ) {
      return false;
    }
  }

  const fieldsToValidate = schemaToUse.value.flatMap((schema) =>
    schema.fields.filter((f) => f.is_required)
  );

  return fieldsToValidate.every((field) => {
    const value =
      field.belongs_to_nested_object && field.nested_object_name
        ? props.record[field.nested_object_name]?.[field.field_name]
        : props.record[field.field_name];

    return validateField(field.field_name, value);
  });
});

async function handleSave() {
  try {
    isAddLoading.value = true;
    if (props.type === "Joint") {
      const newContact = await investorStore.upsertJointContact(props.record);
      emit("close-drawer");
      emit("handle-save", newContact);
    } else if (props.type === "Entity") {
      emit("close-drawer");
      const newEntity = await investorStore.upsertEntityAccount(props.record);
      emit("handle-save", newEntity);
    }
  } catch (err) {
    console.error(err);
  } finally {
    isAddLoading.value = false;
  }
}
</script>
