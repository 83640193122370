<template id="base-input">
  <div class="w-full relative">
    <label v-if="label" class="mb-2 block text-sm whitespace-nowrap font-semibold text-lighter">
      {{ label }} <span v-if="required" class="text-red-500">*</span>
    </label>

    <template v-if="type === 'text' || type === 'string'">
      <Icon
        v-if="listOfAmountFields.includes(formatRule) && !hideCurrencyUI"
        name="DollarSign"
        class="h-4 w-4 text-lighter absolute left-2 top-[41px]"
        :strokeWidth="2"
      />
      <input
        ref="input"
        type="text"
        :value="modelValue"
        @input="updateValue($event.target.value)"
        @focus.prevent.stop="handleFocus"
        @blur.prevent.stop="handleBlur"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="[
          inputStyles,
          { 'pl-7': listOfAmountFields.includes(formatRule) && !hideCurrencyUI },
        ]"
        :maxLength="maxLength ? maxLength : null"
        :autocomplete="autocompleteprop ? 'on' : 'off'"
      />
      <p
        v-if="listOfAmountFields.includes(formatRule) && !hideCurrencyUI"
        class="absolute right-2 top-10 text-lighter"
      >
        USD
      </p>
    </template>

    <template v-if="type === 'password'">
      <div class="relative">
        <input
          ref="input"
          :type="isHiddenTextVisible ? 'text' : 'password'"
          :value="modelValue"
          @input="updateValue($event.target.value)"
          @focus.prevent.stop="handleFocus"
          @blur.prevent.stop="handleBlur"
          :disabled="disabled"
          :placeholder="placeholder"
          :class="inputStyles"
          :autocomplete="autocompleteprop ? 'on' : 'off'"
        />
        <IconVisibilityOff
          id="base-input"
          @click="isHiddenTextVisible = !isHiddenTextVisible"
          v-if="isHiddenTextVisible"
          class="fill-neutral-gray-6 absolute cursor-pointer"
          :class="
            props.size === 'large' ? 'right-4 top-[13px] h-5 w-5' : 'right-4 top-[7px] h-4.5 w-4.5'
          "
        />
        <IconVisibility
          v-else
          id="base-input"
          @click="isHiddenTextVisible = !isHiddenTextVisible"
          class="fill-neutral-gray-6 absolute cursor-pointer"
          :class="
            props.size === 'large' ? 'right-4 top-[13px] h-5 w-5' : 'right-4 top-[7px] h-4.5 w-4.5'
          "
        />
      </div>
    </template>

    <template v-if="type === 'boolean'">
      <Switch
        ref="switchButton"
        :modelValue="modelValue"
        @update:modelValue="(val) => $emit('update:modelValue', val)"
        :disabled="disabled"
        :class="[
          modelValue && !disabled
            ? 'bg-secondary-6 focus:ring-primary-6'
            : 'bg-neutral-gray-5 focus:ring-secondary-6',
          'relative inline-flex h-3.5 w-8 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-opacity-70  ',
          hasYMargin ? 'my-3.5' : '',
        ]"
      >
        <span
          aria-hidden="true"
          :class="[
            modelValue && disabled
              ? 'translate-x-4 bg-primary-2'
              : modelValue && !disabled
                ? 'translate-x-4 bg-primary-6'
                : '-translate-x-1 bg-primary-2',
            'pointer-events-none inline-block h-4 w-4 transform rounded-full shadow transition duration-200 ease-in-out',
          ]"
        />
      </Switch>
    </template>

    <template v-if="type === 'number'">
      <input
        ref="input"
        type="number"
        :value="modelValue"
        @input="updateValue($event.target.value)"
        @focus.prevent.stop="handleFocus"
        @blur.prevent.stop="handleBlur"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="inputStyles"
        :step="step"
        :max="max"
        :min="min"
      />
    </template>

    <template v-if="type === 'search'">
      <div class="relative w-full">
        <div class="flex items-center rounded-md">
          <input
            ref="input"
            type="text"
            :value="modelValue"
            @input="updateValue($event.target.value)"
            :disabled="disabled"
            :placeholder="placeholder"
            @focus.prevent.stop="handleFocus"
            @blur.prevent.stop="handleBlur"
            class="text-primary w-full rounded-md border border-neutral-gray-5 px-3 py-3 focus:border-primary-6 focus:outline-none focus:ring-0 disabled:border-none disabled:bg-neutral-gray-4"
            :class="[
              size === 'large'
                ? 'h-11 text-sm placeholder:text-sm'
                : 'h-8 text-xs placeholder:text-xs',
              customClass,
            ]"
          />
          <IconSearch
            class="absolute right-4 top-1/2 h-5 w-5 -translate-y-1/2 transform fill-primary-1"
          />
        </div>
        <!-- Slot for dropdown results -->
        <div
          v-if="isInputFocused == label && results.length > 0"
          class="absolute z-30 mt-2 max-h-48 w-full overflow-auto rounded-md border border-platform-outlines bg-neutral-gray-1 shadow-lg"
        >
          <slot name="search-dropdown"></slot>
        </div>
        <div
          v-else-if="
            isInputFocused == label &&
            results.length === 0 &&
            modelValue.length > 0 &&
            !hideDropdown
          "
          class="absolute z-10 mt-2 flex h-48 w-full flex-col items-center justify-center overflow-auto rounded-md border border-platform-outlines bg-neutral-gray-1 text-sm text-lighter shadow-lg"
        >
          <IconSearchOff class="h-6 w-6 fill-neutral-gray-6" />
          No Results
        </div>
      </div>
    </template>

    <template v-if="type === 'autofill'">
      <div class="relative w-full">
        <div
          class="relative flex flex-wrap items-center overflow-auto rounded-md border border-neutral-gray-5 bg-white"
          :class="{ 'border border-primary-6': isInputFocused == label }"
        >
          <div
            v-for="(item, index) in selectedItems"
            :key="index"
            class="flex items-center gap-2.5 rounded bg-primary-1-light px-2 py-1"
            :class="size === 'large' ? 'ml-2 mt-2 h-7 text-sm' : 'ml-1.5 mt-1 h-6 text-xs'"
          >
            <p>{{ item?.name || item }}</p>
            <IconX @click="deleteSelectedItem(index)" class="cursor-pointer fill-primary-1" />
          </div>
          <input
            ref="input"
            type="text"
            :value="currentTermValue"
            @input="updateCurrentTermValue($event.target.value)"
            :disabled="disabled"
            :placeholder="placeholder"
            @focus.prevent.stop="handleFocus"
            @blur.prevent.stop="handleBlur"
            class="text-primary w-full rounded-md bg-neutral-gray-1 px-4 py-3 focus:border-none focus:outline-none focus:ring-0 disabled:bg-neutral-gray-4"
            :class="
              size === 'large'
                ? 'h-11 text-sm placeholder:text-sm'
                : 'h-8 text-xs placeholder:text-xs'
            "
          />
          <IconSearch
            class="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 transform fill-primary-1"
          />
        </div>
        <!-- Slot for dropdown results -->
        <div
          v-if="isInputFocused == label && results.length > 0"
          class="absolute z-30 mt-2 max-h-48 w-full overflow-auto rounded-md border border-platform-outlines bg-neutral-gray-1 text-sm shadow-lg"
          :class="dropdownAbove ? 'bottom-full -translate-y-1' : ''"
        >
          <slot name="search-dropdown"></slot>
        </div>
        <div
          v-else-if="isInputFocused == label && results.length === 0 && currentTermValue.length > 0"
          class="absolute z-30 mt-2 flex h-32 w-full flex-col items-center justify-center overflow-auto rounded-md border border-platform-outlines bg-neutral-gray-1 text-sm text-lighter shadow-lg"
          :class="dropdownAbove ? 'bottom-full -translate-y-1' : ''"
        >
          <IconSearchOff class="h-6 w-6 fill-neutral-gray-6" />
          No Results
        </div>
      </div>
    </template>

    <template v-if="type === 'assignment'">
      <div class="relative w-full">
        <div
          class="relative flex items-center rounded-md border border-neutral-gray-5"
          :class="{
            'border border-primary-6': isInputFocused == label,
            'h-11': size === 'large',
            'h-8': size === 'medium',
            'bg-neutral-gray-4': disabled,
            'bg-white': !disabled,
          }"
        >
          <div
            v-if="assignedObject.name"
            :disabled="disabled"
            class="flex max-w-[85%] items-center gap-2.5 truncate rounded px-2 py-1"
            :class="[
              size === 'large' ? 'my-2 ml-2 h-7 text-sm' : 'my-1 ml-1.5 h-6 text-xs',
              disabled ? 'bg-neutral-gray-4' : 'bg-primary-1-light',
            ]"
            id="base-input"
          >
            <p class="truncate">{{ assignedObject?.name }}</p>
            <IconX
              v-if="!disabled"
              @click="deleteAssignment"
              class="h-3.5 min-h-3.5 w-3.5 min-w-3.5 cursor-pointer fill-primary-1"
            />
          </div>
          <input
            v-if="!assignedObject.name"
            type="text"
            ref="assignmentInput"
            :value="currentTermValue"
            @input="updateCurrentTermValue($event.target.value)"
            :disabled="disabled"
            :placeholder="placeholder"
            @focus.prevent.stop="handleFocus"
            @blur.prevent.stop="handleBlur"
            class="text-primary h-full w-full rounded-md bg-neutral-gray-1 px-4 focus:border-none focus:outline-none focus:ring-0 disabled:bg-neutral-gray-4"
            :class="
              size === 'large' ? 'text-sm placeholder:text-sm' : 'text-xs placeholder:text-xs'
            "
          />
          <IconSearch
            class="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 transform fill-primary-1"
          />
        </div>
        <!-- Slot for dropdown results -->
        <div
          v-if="isInputFocused != '' && isInputFocused === label && results.length > 0"
          class="absolute z-30 mt-2 max-h-48 w-full overflow-auto rounded-md border border-platform-outlines bg-neutral-gray-1 text-sm shadow-lg"
        >
          <slot name="search-dropdown"></slot>
        </div>
        <div
          v-else-if="isInputFocused == label && results.length === 0 && currentTermValue.length > 0"
          class="absolute z-30 mt-2 flex h-32 w-full flex-col items-center justify-center overflow-auto rounded-md border border-platform-outlines bg-neutral-gray-1 text-sm text-lighter shadow-lg"
        >
          <IconSearchOff class="h-6 w-6 fill-neutral-gray-6" />
          No Results
        </div>
      </div>
    </template>

    <template v-else-if="type === 'longText'">
      <textarea
        ref="input"
        :rows="rows"
        :value="modelValue"
        @input="updateValue($event.target.value)"
        @focus.prevent.stop="handleFocus"
        @blur.prevent.stop="handleBlur"
        :disabled="disabled"
        :placeholder="placeholder"
        :class="inputStyles"
      />
    </template>

    <template v-else-if="type === 'date'">
      <input
        ref="input"
        type="date"
        :value="modelValue"
        @input="updateValue($event.target.value)"
        :disabled="disabled"
        :class="inputStyles"
        class="w-full"
        @focus.prevent.stop="handleFocus"
        @blur.prevent.stop="handleBlur"
      />
    </template>

    <template v-else-if="type === 'select' || type === 'dropdown'">
      <div class="relative">
        <Listbox
          :modelValue="modelValue"
          @update:modelValue="(value) => emit('update:modelValue', value)"
          :disabled="disabled"
          v-slot="{ open }"
          as="div"
        >
          <ListboxButton
            ref="listboxButtonRef"
            :class="[
              'text-primary relative flex w-full cursor-pointer items-center rounded-md border px-3 text-left ui-focus-visible:border-primary-6',
              size === 'large' ? 'h-11 text-sm' : 'h-8 text-xs',
              disabled ? 'bg-neutral-gray-4' : 'bg-neutral-gray-1',
              open ? 'border-primary-6' : 'border-neutral-gray-5',
              'focus:ring-none focus:outline-none focus:ring-0 ',
            ]"
            :disabled="disabled"
          >
            <span
              v-if="modelValue?.name || modelValue"
              class="block truncate"
              :class="showFullText ? 'max-w-[90%]' : 'max-w-[140px]'"
            >
              {{ displayedValueForOptions }}
            </span>
            <span v-else class="block truncate text-primary-1">{{ placeholder }}</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <IconExpandMore
                class="h-5 w-5"
                aria-hidden="true"
                :class="disabled ? 'fill-neutral-gray-6' : 'fill-primary-6'"
              />
            </span>
          </ListboxButton>
          <ListboxOptions
            id="select-options"
            :class="[
              'absolute z-20 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:border-none focus:outline-none focus:ring-0',
              size === 'large' ? 'text-sm' : 'text-xs',
              dropdownAbove ? 'bottom-full -translate-y-1' : '',
            ]"
          >
            <ListboxOption
              v-for="option in options"
              :key="option"
              :value="option"
              v-slot="{ active }"
              as="li"
            >
              <li
                :class="[
                  'relative block max-w-full cursor-pointer truncate py-2 pl-3 text-dark',
                  active ? 'bg-platform-hover-secondary' : '',
                  isOptionSelected(option) ? 'bg-platform-hover-secondary font-bold' : '',
                ]"
                id="select-option"
              >
                {{ option.name ? option.name : option }}
                <span
                  v-if="isOptionSelected(option)"
                  class="absolute inset-y-0 right-0 flex items-center pr-4"
                >
                  <IconCheckmark class="h-5 w-5 fill-secondary-7" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
      </div>
    </template>

    <template v-else-if="type === 'multi-select'">
      <div class="relative">
        <Listbox
          v-model="internalModel"
          @update:modelValue="handleMultiSelectUpdate"
          :disabled="disabled"
          as="div"
          multiple
        >
          <ListboxButton
            ref="multiSelectRef"
            :class="[
              'text-primary relative flex w-full cursor-pointer items-center rounded-md border px-3 text-left ui-focus-visible:border-primary-6',
              size === 'large' ? 'h-11 text-sm' : 'h-8 text-xs',
              disabled ? 'bg-neutral-gray-4' : 'bg-neutral-gray-1',
              open ? 'border-primary-6' : 'border-neutral-gray-5',
              'focus:ring-none focus:outline-none focus:ring-0 ',
            ]"
          >
            <span
              class="block truncate"
              :class="[
                showFullText ? 'max-w-[80%]' : 'max-w-[140px]',
                modelValue?.name || modelValue ? '' : 'text-primary-1',
              ]"
            >
              {{ internalModel.join(", ") || placeholder }}
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <IconExpandMore
                class="h-5 w-5"
                aria-hidden="true"
                :class="disabled ? 'fill-neutral-gray-6' : 'fill-primary-6'"
              />
            </span>
          </ListboxButton>
          <ListboxOptions
            :class="[
              'absolute z-20 mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:border-none focus:outline-none focus:ring-0',
              size === 'large' ? 'text-sm' : 'text-xs',
              dropdownAbove ? 'bottom-full -translate-y-1' : '',
            ]"
          >
            <ListboxOption
              v-for="option in options"
              :key="option"
              :value="option"
              v-slot="{ active }"
              as="li"
            >
              <li
                :class="[
                  'relative block max-w-full cursor-pointer truncate py-2 pl-3 text-dark',
                  active ? 'bg-platform-hover-secondary' : '',
                  internalModel.includes(option) ? 'bg-platform-hover-secondary font-bold' : '',
                ]"
              >
                {{ option.name ? option.name : option }}
                <span
                  v-if="internalModel.includes(option)"
                  class="absolute inset-y-0 right-0 flex items-center pr-4"
                >
                  <IconCheckmark class="h-5 w-5 fill-secondary-7" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </Listbox>
      </div>
    </template>
    <div v-if="inputError" class="text-red-500 text-sm mt-1">
      {{ inputError }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, nextTick, onMounted } from "vue";
import { Switch, Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";

import { STATES } from "@/constants";

import { Icon } from "@/components";
import {
  IconCheckmark,
  IconX,
  IconSearch,
  IconExpandMore,
  IconSearchOff,
  IconVisibility,
  IconVisibilityOff,
} from "@/components/icons";

const props = defineProps({
  modelValue: {
    type: [String, Number, Date, Array, Object, Boolean],
    default: "",
  },
  compareValue: {
    type: [String, Number, Date, Array, Object, Boolean],
    default: "",
  },
  // for autofill inputs
  currentTermValue: {
    type: String,
    default: "",
  },
  selectedItems: {
    type: Array,
    default: () => [],
  },
  // for assignment inputs
  assignedObject: {
    type: Object,
    default: () => ({}),
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: "",
  },
  results: {
    type: Array,
    default: () => [],
  },
  size: {
    type: String,
    default: "medium",
  },
  rows: {
    type: Number,
    default: 8,
  },
  options: {
    type: Array,
    default: () => [],
  },
  id: {
    type: String,
    default: "",
  },
  step: {
    type: String,
    default: "",
  },
  max: {
    type: String,
    default: "",
  },
  min: {
    type: String,
    default: "",
  },
  hideDropdown: {
    type: Boolean,
    default: false,
  },
  dropdownAbove: {
    type: Boolean,
    default: false,
  },
  hideCurrencyUI: {
    type: Boolean,
    default: false,
  },
  formatRule: {
    type: String,
    default: "",
  },
  errorMessage: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  roundThousands: {
    type: Boolean,
    default: false,
  },
  showFullText: {
    type: Boolean,
    default: false,
  },
  isEditFromAccordion: {
    type: Boolean,
    default: false,
  },
  showErrorOnInput: {
    type: Boolean,
    default: false,
  },
  hasYMargin: {
    type: Boolean,
    default: true,
  },
  autocompleteprop: {
    type: Boolean,
    default: false,
  },
});

const internalModel = ref([]); // for multi-select
const switchButton = ref(null);
const isInputFocused = ref("");
const assignmentInput = ref(null);

const input = ref(null);
const listboxButtonRef = ref(null);
const multiSelectRef = ref(null);
const isHiddenTextVisible = ref(false);

// emits
const emit = defineEmits([
  "update:modelValue",
  "update:currentTermValue",
  "update:searchedTerms",
  "update:selectedItems",
  "update:assignedObject",
  "blur",
  "focus",
]);

onMounted(() => {
  if (props.type === "multi-select" && props.modelValue) {
    initializeInternalModel();
  }
  if (props.formatRule) {
    updateValue(props.modelValue);
  }
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (props.type === "multi-select" && newValue !== internalModel.value.join(",")) {
      initializeInternalModel();
    }
  }
);

const handleMultiSelectUpdate = (newValues) => {
  internalModel.value = newValues.filter((item) => item && item.trim() !== "");
  emit("update:modelValue", internalModel.value.join(","));
};

const initializeInternalModel = () => {
  internalModel.value = props.modelValue
    ? props.modelValue.split(",").filter((item) => item.trim() !== "")
    : [];
};

const inputStyles = computed(() => {
  let baseClass = `text-primary rounded-md border border-neutral-gray-5 bg-neutral-gray-1 px-3 py-3 w-full
  focus:border-primary-6 focus:outline-none focus:ring-0 disabled:bg-neutral-gray-4 disabled:border-none`;

  switch (props.size) {
    case "medium":
      return props.type === "longText"
        ? `${baseClass} text-sm placeholder:text-sm`
        : `${baseClass} h-8 text-sm placeholder:text-sm`;
    case "large":
      return props.type === "longText"
        ? `${baseClass} text-base placeholder:text-base`
        : `${baseClass} h-11 text-base placeholder:text-base`;
    default:
      return props.type === "longText"
        ? `${baseClass} text-xs placeholder:text-xs`
        : `${baseClass} h-8 text-xs placeholder:text-xs`;
  }
});

// this is for handling when options are objects and we want the active styling to work
const displayedValueForOptions = computed(() => {
  if (typeof props.modelValue === "object" && props.modelValue.name) {
    return props.modelValue.name;
  }
  return props.modelValue;
});

const isOptionSelected = (option) => {
  if (props.modelValue === null) return false;

  if (typeof props.modelValue === "object") {
    return props.modelValue.name === option.name;
  }
  return props.modelValue === option;
};

// const updateValue = (value) => {
//   let v = value;
//   if (props.type === "number") {
//     v = Number(value);
//   }
//   emit("update:modelValue", v);
// };

const updateCurrentTermValue = (value) => {
  emit("update:currentTermValue", value);
};

const deleteSelectedItem = (index) => {
  const updatedList = [...props.selectedItems];
  updatedList.splice(index, 1);
  emit("update:selectedItems", updatedList);
};

const deleteAssignment = async () => {
  emit("update:assignedObject", {});
  emit("focus");

  await nextTick();

  if (assignmentInput.value) {
    isInputFocused.value = props.label;
    assignmentInput.value.focus();
  }
};
const inputError = ref("");

const updateValue = (value) => {
  // Remove all non-digit characters to get the raw number
  let v = value;
  // If the format rule is phone and the raw value length exceeds 10, ignore additional input
  if (props.formatRule === "phone") {
    v = formatPhoneNumber(value); // Manually set the input field value
  }

  if (props.formatRule === "ssn") {
    v = formatSSN(value); // Manually set the input field value
    console.log("v=", v);
  }
  if (props.formatRule === "amount") {
    v = formatAmount(value); // Manually set the input field value
  }
  if (props.formatRule === "ein") {
    v = formatEIN(value); // Manually set the input field value
  }
  if (
    props.formatRule === "first_name" ||
    props.formatRule === "last_name" ||
    props.formatRule === "name"
  ) {
    //I want to automatically capitalize the first letter of the string
    v = value.charAt(0).toUpperCase() + value.slice(1);
  }
  if (props.formatRule === "account_number" || props.formatRule === "routing_number") {
    //I want to  only allow numbers to be typed
    v = value.replace(/\D/g, "");
  }

  if (props.type === "date" && value) {
    if (typeof value === "string") {
      v = value;
    } else {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        // Set hours to zero to avoid timezone issues
        date.setHours(0, 0, 0, 0);
        v = date.toISOString().slice(0, 10); // Format as YYYY-MM-DD
      } else {
        v = ""; // Invalid date handling
      }
    }
  }

  if (input.value) {
    input.value.value = v;
    emit("update:modelValue", v);
  }
  if (props.showErrorOnInput && input.value.value) {
    validateInputValue(v);
  }
};

const formatAmount = (amount) => {
  //if amount is typeof number i need to convert it to string first before formatting
  if (typeof amount === "number") {
    amount = amount.toString();
  }
  let value = amount.replace(/[^0-9]/g, "");
  return new Intl.NumberFormat().format(value);
};

const validateInputValue = (value, compareValue) => {
  let isValid = true;
  if (props.formatRule === "phone") {
    isValid = /^\(\d{3}\) \d{3}-\d{4}$/.test(value);
  } else if (props.formatRule === "ssn") {
    isValid = /^\d{3}-\d{2}-\d{4}$/.test(value);
  } else if (props.formatRule === "amount") {
    isValid = /^\d{1,3}(,\d{3})*$/.test(value);
  } else if (props.formatRule === "email") {
    isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
  } else if (props.formatRule === "ein") {
    isValid = /^\d{2}-\d{7}$/.test(value);
  }
  //  else if (props.formatRule === "state") {
  //   isValid = STATES.includes(value);
  // }
  else if (props.formatRule === "first_payment_date") {
    isValid = value.split("-")[2] === "10";
  } else if (props.formatRule === "redemption_amount") {
    isValid = value > 0 && value <= compareValue;
  }

  if (isValid) {
    inputError.value = "";
  } else {
    inputError.value = props.errorMessage;
  }

  inputError.value = isValid ? "" : props.errorMessage;
};

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  // Strip all characters from the input except digits
  let cleaned = phoneNumber.replace(/\D/g, "");

  // Limit the number of digits to 10
  if (cleaned.length > 10) {
    cleaned = cleaned.substring(0, 10);
  }

  // Based on the length of the string, add formatting as necessary
  const size = cleaned.length;
  if (size === 0) {
    return cleaned;
  } else if (size < 4) {
    return "(" + cleaned;
  } else if (size < 7) {
    return "(" + cleaned.substring(0, 3) + ") " + cleaned.substring(3, 6);
  } else {
    return (
      "(" +
      cleaned.substring(0, 3) +
      ") " +
      cleaned.substring(3, 6) +
      "-" +
      cleaned.substring(6, 10)
    );
  }
};
const formatSSN = (ssn) => {
  if (!ssn) return "";

  // Strip all characters from the input except digits
  let cleaned = ssn.replace(/\D/g, "");

  // Based on the length of the cleaned string, add formatting as necessary
  const size = cleaned.length;
  let val = "";
  if (size === 0) {
    val = cleaned;
  } else if (size <= 3) {
    val = cleaned;
  } else if (size <= 5) {
    val = cleaned.substring(0, 3) + "-" + cleaned.substring(3, 5);
  } else {
    val = cleaned.substring(0, 3) + "-" + cleaned.substring(3, 5) + "-" + cleaned.substring(5, 9);
  }

  return val;
};
const formatEIN = (ein) => {
  if (!ein) return "";

  // Strip all characters from the input except digits
  let cleaned = ein.replace(/\D/g, "");

  // Based on the length of the cleaned string, add formatting as necessary
  const size = cleaned.length;

  let val = "";
  if (size === 0) {
    val = cleaned;
  } else if (size <= 2) {
    val = cleaned;
  } else {
    val = cleaned.substring(0, 2) + "-" + cleaned.substring(2, 9);
  }

  return val;
};
const handleFocus = () => {
  inputError.value = "";
  isInputFocused.value = props.label;
  emit("focus");
};

const handleBlur = () => {
  isInputFocused.value = "n/a";
  validateInputValue(props.modelValue, props.compareValue); // Validate on blur
  console.log("blur", props);
  if (props.formatRule === "amount" && props.roundThousands) {
    emit("update:modelValue", formatThousandsAmount(props.modelValue));
  }
  emit("blur");
};

const formatThousandsAmount = (amount) => {
  // If the amount is a number, convert it to a string first
  if (typeof amount === "number") {
    amount = amount.toString();
  }

  // Remove any non-numeric characters (except the decimal point if needed)
  let value = amount.replace(/[^0-9.]/g, "");

  // Convert the cleaned string to a number and round it to the nearest thousand
  let roundedValue = Math.round(Number(value) / 1000) * 1000;

  // Format the rounded number with commas
  return new Intl.NumberFormat().format(roundedValue);
};

const listOfAmountFields = [
  "amount",
  "current_net_worth",
  "current_liquid_net_worth",
  "current_income",
];
</script>
