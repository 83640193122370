<template>
  <SideDrawer
    @close-drawer="$emit('close-drawer')"
    :open="open"
    customWidth="lg:w-3/4"
    id="quick-ref-rel-list"
    :hideHeader="true"
    :showFooter="true"
  >
    <template v-slot:tabs> <div class="font-semibold">Create Investment</div> </template>

    <template v-slot:main-content><InvestmentFlow /> </template>
    <template v-slot:footer><InvestmentFlowBackAndNext /></template>
  </SideDrawer>
</template>
<script setup>
import { watch } from "vue";
import { SideDrawer } from "@/components";
import { useCreateInvestmentStore } from "@/stores";
import { InvestmentFlow } from "@/pages";
import InvestmentFlowBackAndNext from "./InvestmentFlowBackAndNext.vue";

const createInvestmentStore = useCreateInvestmentStore();
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
</script>
