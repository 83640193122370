<template>
  <Modal size="36square" :open="open" @close-modal="$emit('close-modal')">
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">
        {{ isEdit ? "Edit" : isClone ? "Clone" : "Add" }} Offering
      </p>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white gap-4 px-8 pb-8 pt-4 grid grid-cols-2 overflow-y-auto">
        <BaseInput
          v-for="field in schemaStore.all_offering_schema"
          :type="field.field_type"
          :label="field.label"
          :placeholder="field.label"
          v-model="activeOffering[field.field_name]"
          size="large"
          :options="field?.record_detail_config?.default?.dropdown_options"
          :disabled="
            isEdit &&
            !(
              field.field_name === 'is_visible_to_admins' ||
              field.field_name === 'is_visible_to_investors'
            )
          "
        />
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Cancel</p></template>
        </Button>
        <Button size="large" :disabled="isSaveDisabled" label @click="handleAddEditOffering">
          <template #label>
            {{ !isEdit ? "Add Offering" : "Save Offering" }}
          </template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";

import { Modal, Button, BaseInput } from "@/components";
import { useUIStore, useAuthStore, useCrudStore, useSchemaStore } from "@/stores";

const UIStore = useUIStore();
const authStore = useAuthStore();
const crudStore = useCrudStore();
const schemaStore = useSchemaStore();

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  offeringForEdit: {
    type: [Object, null],
    default: null,
  },
  isClone: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close-modal", "refetch-custodians"]);

const offeringSchema = ref([]);
const activeOffering = ref({});

watch(
  () => schemaStore.all_offering_schema,
  () => {
    schemaStore.all_offering_schema.map((field) =>
      field.field_type === "boolean"
        ? (activeOffering.value[field.field_name] = false)
        : (activeOffering.value[field.field_name] = "")
    );
  }
);

watch(
  () => props.offeringForEdit,
  () => {
    if (props.offeringForEdit) {
      activeOffering.value = props.offeringForEdit;
    } else {
      activeOffering.value = {};
      schemaStore.all_offering_schema.map((field) =>
        field.field_type === "boolean"
          ? (activeOffering.value[field.field_name] = false)
          : (activeOffering.value[field.field_name] = "")
      );
    }
  }
);

const isSaveDisabled = computed(() => {
  return offeringSchema.value
    .filter(
      (f) =>
        f.field_name !== "can_compound" &&
        f.field_name !== "is_visible_to_investors" &&
        f.field_name !== "is_visible_to_admins"
    )
    .some((field) => !activeOffering.value[field.field_name]);
});

async function handleAddEditOffering() {
  emit("close-modal");
  setTimeout(() => {
    UIStore.animateNotificationAlert({
      icon: "BadgeCheck",
      message: `Successfully ${!props.isEdit ? "added" : "updated"} offering!`,
    });
  }, 300);

  if (!props.isEdit) {
    activeOffering.value.total_raised = 0;
    activeOffering.value.visible = false;
    activeOffering.value.total_pending = 0;
    activeOffering.value.offer_number = 0;
    activeOffering.value.total_bonds = 0;
    activeOffering.value.total_interest_paid = 0;
    activeOffering.tier = null;
    activeOffering.note = "";
    activeOffering.value.created_date = new Date();
    activeOffering.value.created_by_id = authStore.currentUser.id;
    activeOffering.value.created_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
    activeOffering.value.updated_date = new Date();
    activeOffering.value.updated_by_id = authStore.currentUser.id;
    activeOffering.value.updated_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
    const { insertedId } = await crudStore.insertOne("Offerings", activeOffering.value);
  } else {
    activeOffering.value.updated_date = new Date();
    activeOffering.value.updated_by_id = authStore.currentUser.id;
    activeOffering.value.updated_by_name = `${authStore.currentUser.customData.first_name} ${authStore.currentUser.customData.last_name}`;
    await crudStore.updateOne(
      "Offerings",
      { _id: activeOffering.value._id },
      { $set: activeOffering.value }
    );
  }
  activeOffering.value = {};
  emit("refetch-offerings");
}
</script>
