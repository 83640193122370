<template>
  <Modal :open="open" @close-modal="$emit('close-modal')" size="small" hideModalTabs hideFooter>
    <template v-slot:modal-header>
      <p class="text-lg font-semibold text-primary">Open Subscription Agreement Signing Links</p>
    </template>

    <template v-slot:modal-body>
      <div class="py-4 px-10 flex flex-col gap-4">
        <div class="flex flex-wrap flex-col">
          <div class="flex gap-4 truncate">
            <p class="font-semibold">Signing Link:</p>
            <Icon
              @click="handleCopy(subDoc?.signing_links[1]?.embed_url, 'signingLink1Copied')"
              :name="signingLink1Copied ? 'CheckCircle' : 'Copy'"
              :stroke-width="2"
              class="cursor-pointer"
              :class="signingLink1Copied ? 'text-green-500 h-4 w-4' : 'text-primary-2 h-4 w-4'"
            />
          </div>
          <BaseInput v-model="signingLink1" size="large" disabled />
        </div>
        <div v-if="subDoc?.signing_links[2]?.embed_url">
          <div class="flex gap-4">
            <p class="font-semibold">Joint Signing Link:</p>
            <Icon
              @click="handleCopy(subDoc?.signing_links[1]?.embed_url, 'signingLink2Copied')"
              :name="signingLink2Copied ? 'CheckCircle' : 'Copy'"
              :stroke-width="2"
              class="cursor-pointer"
              :class="signingLink2Copied ? 'text-green-500 h-4 w-4' : 'text-primary-2 h-4 w-4'"
            />
          </div>
          <BaseInput v-model="signingLink2" size="large" disabled />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";

import { useInvestorStore, useCrudStore, useInvestmentStore } from "@/stores";
import { Modal, Button, Icon, BaseInput } from "@/components";

const investorStore = useInvestorStore();
const investmentStore = useInvestmentStore();
const crudStore = useCrudStore();

const emit = defineEmits(["close-modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const subDoc = ref(null);
const signingLink1Copied = ref(false);
const signingLink2Copied = ref(false);

const signingLink1 = ref("");
const signingLink2 = ref("");

watch(
  () => props.open,
  async () => {
    if (props.open) {
      const pendingInvestment = investmentStore.transactionsData.find(
        (i) => i.status === "Pending"
      );
      subDoc.value = await crudStore.findOne("Documents", { investment_id: pendingInvestment._id });
      signingLink1.value = subDoc.value.signing_links[1].embed_url;
      if (subDoc.value.signing_links[2]) {
        signingLink2.value = subDoc.value.signing_links[2].embed_url;
      }
    }
  }
);

async function handleCopy(text, type) {
  try {
    await navigator.clipboard.writeText(text);
    signingLink1Copied.value = false;
    signingLink2Copied.value = false;

    if (type === "signingLink1Copied") {
      signingLink1Copied.value = true;
    } else if (type === "signingLink2Copied") {
      signingLink2Copied.value = true;
    }

    setTimeout(() => {
      if (type === "signingLink1Copied") {
        signingLink1Copied.value = false;
      } else if (type === "signingLink2Copied") {
        signingLink2Copied.value = false;
      }
    }, 3000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}
</script>
