import { drop } from "lodash";

export const ALL_50_STATES = [
  { name: "Alabama", value: "AL" },
  { name: "Alaska", value: "AK" },
  { name: "Arizona", value: "AZ" },
  { name: "Arkansas", value: "AR" },
  { name: "California", value: "CA" },
  { name: "Colorado", value: "CO" },
  { name: "Connecticut", value: "CT" },
  { name: "Delaware", value: "DE" },
  { name: "District Of Columbia", value: "DC" },
  { name: "Florida", value: "FL" },
  { name: "Georgia", value: "GA" },
  { name: "Hawaii", value: "HI" },
  { name: "Idaho", value: "ID" },
  { name: "Illinois", value: "IL" },
  { name: "Indiana", value: "IN" },
  { name: "Iowa", value: "IA" },
  { name: "Kansas", value: "KS" },
  { name: "Kentucky", value: "KY" },
  { name: "Louisiana", value: "LA" },
  { name: "Maine", value: "ME" },
  { name: "Maryland", value: "MD" },
  { name: "Massachusetts", value: "MA" },
  { name: "Michigan", value: "MI" },
  { name: "Minnesota", value: "MN" },
  { name: "Mississippi", value: "MS" },
  { name: "Missouri", value: "MO" },
  { name: "Montana", value: "MT" },
  { name: "Nebraska", value: "NE" },
  { name: "Nevada", value: "NV" },
  { name: "New Hampshire", value: "NH" },
  { name: "New Jersey", value: "NJ" },
  { name: "New Mexico", value: "NM" },
  { name: "New York", value: "NY" },
  { name: "North Carolina", value: "NC" },
  { name: "North Dakota", value: "ND" },
  { name: "Ohio", value: "OH" },
  { name: "Oklahoma", value: "OK" },
  { name: "Oregon", value: "OR" },
  { name: "Pennsylvania", value: "PA" },
  { name: "Rhode Island", value: "RI" },
  { name: "South Carolina", value: "SC" },
  { name: "South Dakota", value: "SD" },
  { name: "Tennessee", value: "TN" },
  { name: "Texas", value: "TX" },
  { name: "Utah", value: "UT" },
  { name: "Vermont", value: "VT" },
  { name: "Virginia", value: "VA" },
  { name: "Washington", value: "WA" },
  { name: "West Virginia", value: "WV" },
  { name: "Wisconsin", value: "WI" },
  { name: "Wyoming", value: "WY" },
];

export const CHART_OPTIONS = {
  plugins: {
    title: {
      display: false,
      text: "Distribution Amounts",
      align: "start",
      font: { size: 14 },
    },
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          const value = tooltipItem.raw;
          return (
            "$" +
            Number(value)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          );
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        display: true,
      },
      grid: { display: false },
    },
    y: {
      stacked: true,
      ticks: {
        display: false,
      },
      title: {
        display: true,
        text: "Valuation",
        font: { size: 14 },
        color: "#6a7c8c",
      },
      grid: { display: true },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const JOINT_TEMPLATE = {
  is_new: true,
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  date_of_birth: "",
  ssn: "",
  occupation: "",
  employer: "",
  country: "United States",
  city: "",
  street1: "",
  street2: "",
  state: "",
  zip_code: "",
  is_owner: false,
  is_beneficial_owner: false,
  type: "Joint",
  id_info: {},
};

export const ENTITY_TEMPLATE = {
  is_new: true,
  name: "",
  phone: "",
  email: "",
  date_of_birth: "",
  ein: "",
  entity_type: "",
  country: "United States",
  city: "",
  street1: "",
  street2: "",
  state: "",
  zip_code: "",
  type: "Entity",
};

export const NEW_BENEFICIAL_OWNER_TEMPLATE = {
  is_new: true,
  first_name: "",
  last_name: "",
  date_of_birth: "",
  ssn: "",
  country: "United States",
  state: "",
  street1: "",
  street2: "",
  city: "",
  zip_code: "",
  is_owner: false,
  is_beneficial_owner: false,
  type: "Beneficial Owner",
  id_info: {},
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// for suitability questionnaire
export const INCOME_FIELDS = [
  {
    field_name: "current_net_worth",
    label: "Net Worth",
    number_type: "currency",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
  {
    field_name: "current_liquid_net_worth",
    label: "Liquid Net",
    number_type: "currency",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
  {
    field_name: "current_income",
    label: "Current Income",
    number_type: "currency",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
  {
    field_name: "source_of_income",
    label: "Income Source",
    type: "text",
    belongs_to_nested_object: true,
    nested_object_name: "suitability_info",
    is_required: true,
  },
];

export const QUESTIONNAIRE_QUESTIONS_ANSWERS = [
  {
    field_name: "investment_objective",
    question: "My overall investment objective",
    answers: [
      "Capital Preservation- you seek to preserve capital and are willing to accept a lower rate of return in exchange",
      "Income- you seek investments with periodic distributions in exchange for capital appreciation",
      "Growth & Income- you seek a moderate increase in investment value in combination with some income",
      "Growth- you seek growth in the value of your investment",
      "Aggressive Growth – seek investment opportunities with very high growth potential",
    ],
  },
  {
    field_name: "investment_experience",
    question: "My relevant investment experience",
    answers: [
      "Less than 2 years",
      "Between 2 and 5 years",
      "Between 5 and 10 years",
      "Greater than 10 years",
    ],
  },
  {
    field_name: "risk_willingness",
    question: "My willingness to accept risk for this investment",
    answers: ["Conservative", "Moderate", "Significant", "Speculative"],
  },
  {
    field_name: "equities_allocation",
    question: "My current portfolio investment allocation for equities",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "bonds_allocation",
    question: "My current portfolio investment allocation for bonds",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "real_estate_allocation",
    question: "My current portfolio investment allocation for real estate",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "other_investments_allocation",
    question:
      "My current portfolio investment allocation for other investments (private securities, alternative investments, etc.)",
    answers: [
      "None",
      "Less than $250,000 USD",
      "Greater than $250,000 USD",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "overall_portfolio_percentage",
    question: "This corporate bond investment is what percentage of your overall portfolio?",
    answers: ["0% – 10%", "10% - 25%", "26% and over", "Prefer not to answer"],
  },
  {
    field_name: "annual_living_expenses",
    question: "My approximate annual living expenses",
    answers: [
      "Less than $50,000",
      "Between $50,000 and $100,000",
      "Greater than $100,000",
      "Not applicable-if investing as an entity",
      "Prefer not to answer",
    ],
  },
  {
    field_name: "marginal_tax_rate",
    question: "My marginal personal tax rate",
    answers: ["Less than 20%", "Between 20%-30%", "Greater than 30%", "Not applicable"],
  },
  {
    field_name: "significant_liquid_net_worth",
    question:
      "I have significant liquid net worth, excluding the funds for my investment, to maintain my quality of life and understand that this investment is illiquid, and I may not be able to access invested funds for an extended amount of time.",
    answers: ["Yes", "No"],
  },
  {
    field_name: "risky_investment_understanding",
    question:
      "I understand that an investment in private securities has risks, is not guaranteed, and that this it is an illiquid investment with set terms that may not align with my overall investment strategy as answered in Question 1.",
    answers: ["Yes", "No"],
  },
  {
    field_name: "investment_time_horizon",
    question: "What is your time horizon for this investment?",
    answers: ["1-5 years", "6-10 years", "Over 10 years"],
  },
  {
    field_name: "self_responsibility",
    question:
      "I have conducted my own due diligence, I am making this investment on my own accord, and I will not hold anyone other than myself responsible for any losses that may result from this investment choice.",
    answers: ["Yes", "No"],
  },
];

export const ACCREDITATION_QUALIFIERS = [
  "A letter from CPA, Attorney, or Financial professional with an active Series 7, Series 65, or Series 82 license",
  "Proof or assets worth more than $1M by reviewing specific types of documentation dated within the prior three months, such as bank statements, brokerage statements, certificates of deposit, tax assessments, and a credit report from at least one of the nationwide consumers reporting agencies and obtaining a written reprsentation from the investor.",
  "Individual - Proof of income for 2 years of at least 200k by reviewing copies of any Internal Revenue Service form that reports income, such as Form W-2, Form 1099, Schedule K-1 of Form 1065, and a filed Form 1040.",
  "Joint - Proof of income for 2 years of at least 300k by reviewing copies of any Internal Revenue Service form that reports income, such as Form W-2, Form 1099, Schedule K-1 of Form 1065, and a filed Form 1040.",
];

export const REVIEW_CATEGORIES = [
  { name: "Offering", fields: [{ label: "Type", field_name: "type", record: "" }] },
];
export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const INVESTMENT_DOCUMENT_FIELD_ORDER = [
  "signature_date",
  "user_id",
  "account_id",
  "contact_id",
  "offering_id",
  "status",
  "type",
  "amount",
  "is_compounded",
  "current_nav",
  "bonds",
  "payment_method",
  "cancelled_date",
  "maturity_date",
  "close_date",
  "funded_date",
  "inception_to_date_interest_paid",
  "year_to_date_interest_paid",
  "all_contacts_identity_verified",
];
