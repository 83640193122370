<template>
  <div
    v-if="
      !titleTransferStore.investment_being_created &&
      !titleTransferStore.show_investment_completion_screen
    "
    class="flex flex-col gap-8 mb-4"
  >
    <div>
      <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
        Review and Complete Owner Transfer
      </div>
    </div>
    <div class="text-sm text-light">
      <!-- Investment Details -->
      <div>
        <div>
          Amount: $
          {{
            new Intl.NumberFormat().format(titleTransferStore.current_investment.amount)
              ? new Intl.NumberFormat().format(titleTransferStore.current_investment.amount)
              : titleTransferStore.current_investment.amount
          }}
        </div>
      </div>
      <!-- From Details -->
      <div>
        <div>
          From:
          {{ titleTransferStore.current_owner_contact.first_name }}
          {{ titleTransferStore.current_owner_contact.last_name }} ({{
            titleTransferStore.current_owner_contact.email
          }})
        </div>
      </div>
      <!-- To Details -->
      <div>
        <div>
          To:
          {{ titleTransferStore.selected_transferee_full_document.first_name }}
          {{ titleTransferStore.selected_transferee_full_document.last_name }} ({{
            titleTransferStore.selected_transferee_full_document.email
          }})
        </div>
      </div>
    </div>
    <div class="pb-8 border-b border-platform-outlines">
      <div class="font-bold">Reason For Transfer</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          :required="true"
          v-model="titleTransferStore.reason_for_transfer"
          type="dropdown"
          label="Reason For Transfer"
          :options="schemaStore.title_transfer_reasons"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          :required="titleTransferStore.reason_for_transfer === 'Other'"
          v-model="titleTransferStore.other_reason_for_transfer"
          type="text"
          label="Other Reason"
          size="large"
          customClass="bg-neutral-gray-1"
          :disabled="titleTransferStore.reason_for_transfer !== 'Other'"
        ></BaseInput>
      </div>
    </div>
    <div
      v-if="
        titleTransferStore.investment_type.includes('IRA') ||
        titleTransferStore.investment_type.includes('HSA') ||
        titleTransferStore.investment_type.includes('ira') ||
        titleTransferStore.investment_type.includes('hsa')
      "
      class="pb-8 border-b border-platform-outlines"
    >
      <div class="font-bold">Custodian Details</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          :required="true"
          label="Custodian Name"
          v-model="titleTransferStore.custodian_name"
          type="select"
          :options="schemaStore.custodian_name_options"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
        <BaseInput
          :required="true"
          label="Custodian Account Number"
          v-model="titleTransferStore.custodian_account_number"
          type="number"
          size="large"
          customClass="bg-neutral-gray-1"
        ></BaseInput>
      </div>
    </div>
    <EmailPreferences />
    <div>
      <div class="font-semibold text-lg">Upload Signed Sub Agreement</div>
      <div class="my-2">Please verify before submitting that the:</div>
      <ul class="list-disc pl-5 text-gray-500">
        <li>
          Name on the sub agreement matches the name in the personal information form you filled out
        </li>
        <li>Sensitive information filled out (banking, SSN, EIN) matches what is on the sub</li>
        <li>Joint signature exists on the sub agreeement in the case of a joint investment</li>
      </ul>
    </div>
    <div>
      <div v-if="titleTransferStore.uploaded_owner_transfer_form">
        <div class="mb-4 flex items-center border p-4 rounded-md shadow-sm">
          <div class="flex-grow">
            <div class="text-sm">{{ titleTransferStore.uploaded_owner_transfer_form.name }}</div>
          </div>

          <div class="flex gap-1">
            <Button @click="removeFile()" icon variant="default-gray-outlined">
              <template #icon>
                <Icon
                  name="Trash"
                  class="h-4.5 w-4.5 stroke-functional-error-default"
                  :stroke-width="2"
              /></template>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <input @change="handleUpload" ref="fileInputRef" type="file" hidden />
        <div
          class="flex w-full grow flex-col items-center justify-center rounded-md border border-dashed border-primary-4 py-4 text-sm lg:h-45 lg:min-h-0 bg-light bg-opacity-20"
          :class="dragging ? 'border-blue-500 bg-blue-100' : 'bg-light bg-opacity-20'"
          @drop="drop"
          @dragover.prevent="dragging = true"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <IconX class="h-6 w-6 fill-cTeal" />
          <p>Drag the file here or click upload</p>

          <div class="mt-4">
            <Button @click="chooseFiles" label>
              <template #label>{{
                titleTransferStore.uploaded_owner_transfer_form ? "Replace File" : "Upload File"
              }}</template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="titleTransferStore.investment_being_created">Investment being created ...</div>
  <div v-if="titleTransferStore.show_investment_completion_screen">Investment complete</div>
</template>

<script setup>
import { ref, watch } from "vue";

import { useTitleTransferStore, useSchemaStore } from "@/stores";
import { Button, BaseInput } from "@/components";
import EmailPreferences from "./EmailPreferences.vue";
import { Icon } from "@/components";

const titleTransferStore = useTitleTransferStore();
const schemaStore = useSchemaStore();

const fileInputRef = ref(null);

const dragging = ref(false);
const uploadState = ref("idle"); // 'idle', 'uploading', 'completed'
const uploadProgress = ref(0);
let uploadTimeout = null;

watch(
  () => titleTransferStore.reason_for_transfer,
  (newValue) => {
    if (newValue !== "Other") {
      titleTransferStore.other_reason_for_transfer = "";
    }
  }
);

const removeFile = () => {
  titleTransferStore.uploaded_owner_transfer_form = null;
};

const simulateUpload = () => {
  uploadState.value = "uploading";
  uploadProgress.value = 0;
  uploadTimeout = setInterval(() => {
    uploadProgress.value += 50; // Increase by 50% every second
    if (uploadProgress.value >= 100) {
      clearInterval(uploadTimeout);
      uploadState.value = "completed";
    }
  }, 1000);
};

const chooseFiles = () => {
  fileInputRef.value.click();
};

const handleUpload = async (e) => {
  titleTransferStore.uploaded_owner_transfer_form = e.target.files[0];
  simulateUpload();
};

const drop = (e) => {
  e.preventDefault();
  dragging.value = false;
  titleTransferStore.uploaded_owner_transfer_form = e.dataTransfer.files[0];
  simulateUpload();
};
</script>
