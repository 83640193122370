<template>
  <Modal
    :open="props.open"
    @close-modal="$emit('close-modal')"
    :size="props.size"
    :custom-height="props.customHeight"
    :custom-width="props.customWidth"
    :hideModalTabs="true"
  >
    <template v-slot:modal-header>
      <div class="flex flex-row items-center">
        <!-- <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-functional-warning-click bg-opacity-20 p-1"
        >
          <component
            class="h-6 w-6 fill-functional-warning-click"
            :is="iconMapping[recordDetailStore.currentPageConfig.icon]"
          />
        </div> -->

        <div class="font-semibold text-xl text-custom-navy-2">IDology Qualifiers</div>
      </div>
    </template>
    <template v-slot:modal-body>
      <div class="h-full w-full bg-white gap-2 py-4 px-8 flex flex-col items-center">
        <div class="py-4 w-full flex flex-col items-center border-b border-neutral-gray-5">
          <span class="font-semibold text-xl"
            >{{ investorStore.investor_to_process.first_name }}
            {{ investorStore.investor_to_process.last_name }}</span
          >
          <div>
            <a
              :href="`/contacts?recordId=${investorStore.investor_to_process._id}`"
              class="cursor-pointer underline"
              >{{ investorStore.investor_to_process.email }}</a
            >
            <!-- -
            <span
              v-if="investorStore.investor_to_process.is_identity_verified"
              class="text-functional-success-default"
              >PASS</span
            ><span v-else class="text-functional-error-default">FAIL</span> -->
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-2 py-4 border-b border-neutral-gray-5"
          v-if="router.currentRoute.value.fullPath.includes('identity-checks')"
        >
          <div class="px-4 flex flex-col items-start rounded-lg gap-4">
            <div class="flex items-center gap-2">
              <div
                class="h-8 w-8 items-center justify-center flex bg-custom-teal bg-opacity-20 rounded-lg"
              >
                <Icon name="Key" class="w-5.5 h-5.5 text-custom-teal" />
              </div>
              <span>KYC/AML</span>
            </div>
            <div class="text-sm text-lighter grow">
              Please review the provided qualifiers and update the investor's personal information
              as needed. Once completed, initiate an additional KYC/AML verification check.
            </div>
            <div v-if="!isRunningIdentityCheck" class="flex items-center justify-self-end w-full">
              <Button
                variant="default-filled"
                class="w-full md:w-auto"
                @click="handleRunIdentityCheck()"
              >
                <template #label>Run KYC/AML</template>
              </Button>
            </div>
            <div v-else>
              <div v-if="didIdentityCheckPass === null" class="flex items-center justify-center">
                <span>Verifying...</span>
              </div>
              <div v-else class="flex items-center justify-center">
                <span
                  class="text-sm"
                  :class="
                    didIdentityCheckPass
                      ? 'text-custom-lime-green'
                      : 'text-functional-error-default'
                  "
                  >{{
                    didIdentityCheckPass ? "Identity Check Passed" : "Identity Check Failed"
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="px-4 flex flex-col items-start rounded-lg gap-4">
            <div class="flex items-center gap-2">
              <div
                class="w-8 h-8 bg-custom-teal bg-opacity-20 rounded-lg flex items-center justify-center"
              >
                <IconUserCheck class="w-5.5 h-5.5 fill-custom-teal" />
              </div>
              <span>ID Verification</span>
            </div>
            <div class="text-sm text-lighter">
              In the event of an unsuccessful standard KYC/AML check, you may request an ID
              verification link to proceed with an identity verification process. This will involve
              submitting images of the front and back of the investor's ID card or Drivers License.
            </div>
            <Button variant="default-filled" class="w-full md:w-auto" @click="requestScanLink()">
              <template #label>Verify Documents</template>
            </Button>
          </div>
        </div>
        <div v-if="tableData.length > 0" class="w-full">
          <Table
            class="w-full mt-4"
            fix-table="true"
            :table-data="tableData"
            :headers="tableHeaders"
            fields-with-custom-styles="['number']"
          >
            <template #number="{ row }">
              <span class="text-primary p-4">{{ row.number.value }}</span>
            </template>
          </Table>
        </div>
        <div v-else class="w-full flex items-center justify-center h-32">
          <span>No qualifiers found for this investor.</span>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label><p>Close</p></template></Button
        >
        <!-- <Button size="large" :disabled="isSaveDisabled" label @click="updateCustodian">
          <template #label>Run KYC/AML</template></Button
        > -->
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { IconIdentity, IconUserCheck } from "@/components/icons";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  useRecordDetailStore,
  useCrudStore,
  useInvestorStore,
  useUIStore,
  useAuthStore,
} from "@/stores";
import { Modal, Button, BaseInput, Table, Icon } from "@/components";
import { iconMapping } from "@/constants/iconMapping";

const recordDetailStore = useRecordDetailStore();
const crudStore = useCrudStore();
const investorStore = useInvestorStore();
const UIStore = useUIStore();
const authStore = useAuthStore();

const router = useRouter();

console.log(router.currentRoute.value);

const isRequestingScanLink = ref(false);
const isRunningIdentityCheck = ref(false);
const didIdentityCheckPass = ref(null);

var tableData = computed(() => {
  let records = investorStore.investor_to_process.idology_qualifiers;
  for (let r of records) {
    r.number = records.indexOf(r) + 1;
  }
  return UIStore.transformRecords(records);
});

const tableHeaders = ref([
  { field_name: "number", label: "#" },
  { field_name: "message", label: "Qualifier" },
]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "small",
  },
  customHeight: {
    type: String,
    default: null,
  },
  customWidth: {
    type: String,
    default: null,
  },
});

// define emits
const emit = defineEmits(["close-modal"]);

// declare props open
// const props = defineProps(["open"]);

onMounted(async () => {});

async function runKYC() {
  emit("close-modal");
}

const custodians = ref([]);
const investmentTypes = ref([]);

const isSaveDisabled = computed(() => {
  return false;
});

async function requestScanLink() {
  isRequestingScanLink.value = true;
  try {
    //run contact through the KYC/AML check again that will update the contact record
    // throw new Error("Testing Error path");
    // await authStore.currentUser.functions.Check_Investor_Identity(investorStore.investor_to_process._id, true);
    await fetch(
      `https://us-west-2.aws.data.mongodb-api.com/app/${import.meta.env.VITE_REALM_APP_ID_APLUS}/endpoint/CheckInvestorIdentity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contact: { ...investorStore.investor_to_process },
          isScanVerifiy: true,
          adminUserId: authStore.currentUser.id,
        }),
      }
    );
  } catch (e) {
    console.error(e);
  } finally {
    //check status of contact
    let contactRecord = await crudStore.findOne("Contacts", {
      _id: investorStore.investor_to_process._id,
    });
    //investorStore.investor_to_process = contactRecord;
    isRequestingScanLink.value = false;
    const scanUrl = contactRecord?.scan_url;
    if (scanUrl) {
      const anchor = document.createElement("a");
      anchor.setAttribute("href", scanUrl);
      anchor.setAttribute("target", "_blank");
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
    //await approvalsStore.getContactsWithFailedIdentityVerification();
  }
}

async function handleRunIdentityCheck() {
  isRunningIdentityCheck.value = true;
  // isShowQualifiersModal.value = false;
  // emit("close-modal");
  // this is where we will perform an identity check on the selected contact
  try {
    //run contact through the KYC/AML check again that will update the contact record
    // throw new Error("Testing Error path");
    // await authStore.currentUser.functions.Check_Investor_Identity(investorStore.investor_to_process._id, false);
    await fetch(
      `https://us-west-2.aws.data.mongodb-api.com/app/${import.meta.env.VITE_REALM_APP_ID_APLUS}/endpoint/CheckInvestorIdentity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contact: { ...investorStore.investor_to_process },
          isScanVerifiy: false,
          adminUserId: authStore.currentUser.id,
        }),
      }
    );
  } catch (e) {
    console.error(e);
  } finally {
    //check status of contact
    let contactRecord = await crudStore.findOne("Contacts", {
      _id: investorStore.investor_to_process._id,
    });
    await recordDetailStore.fetchTableRecords(true);
    //await approvalsStore.getContactsWithFailedIdentityVerification();
    setTimeout(() => {
      if (contactRecord?.is_identity_verified) {
        didIdentityCheckPass.value = true;
        // UIStore.setAlert({
        //   title: "Success!!",
        //   message:
        //     "Succesfully verified investor's identity! They have been removed from the failed list.",
        //   type: "success",
        // });
      } else {
        didIdentityCheckPass.value = false;
        // UIStore.setAlert({
        //   title: "There was an error!",
        //   message:
        //     "The investor failed the identity check! Please double check investor personal information and try again.",
        //   type: "error",
        // });
      }
    }, 500);
    //investorStore.investor_to_process = null;
    //isRunningIdentityCheck.value = false;
  }
}
</script>
