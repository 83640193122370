<template>
  <div v-if="createInvestmentStore.offerings" class="flex flex-col h-full justify-between">
    <div class="m-6 md:m-5.5">
      <FirstInvestmentStep v-if="createInvestmentStore.currentStep === 0" />

      <div v-if="createInvestmentStore.currentStep > 0">
        <component
          :is="
            getStepComponent(
              createInvestmentStore.investmentSteps[createInvestmentStore.currentStep - 1]
            )
          "
          :key="createInvestmentStore.currentStep"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCreateInvestmentStore } from "@/stores";
import FirstInvestmentStep from "./FirstInvestmentStep.vue";
import PersonalInfo from "./PersonalInfo.vue";
import SuitabilityQuestionnaire from "./SuitabilityQuestionnaire.vue";
import AccreditationDetails from "./AccreditationDetails.vue";
import CompleteYourInvestment from "./CompleteYourInvestment.vue";
import JointInfo from "./JointInfo.vue";
import EntityInfo from "./EntityInfo.vue";
import BankingInfo from "./BankingInfo.vue";
import SelectJoint from "./SelectJoint.vue";
import SelectEntity from "./SelectEntity.vue";

const createInvestmentStore = useCreateInvestmentStore();

const stepComponents = {
  "Personal Information": PersonalInfo,
  "Suitability Questionnaire": SuitabilityQuestionnaire,
  "Accreditation Details": AccreditationDetails,
  "Complete Your Investment": CompleteYourInvestment,
  "Banking Info": BankingInfo,
  "Select Joint Contact": SelectJoint,
  "Select Entity Account": SelectEntity,
  "Joint Info": JointInfo,
  "Entity Info": EntityInfo,
};

const getStepComponent = (stepName) => {
  return stepComponents[stepName] || null;
};
</script>
