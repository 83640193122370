<template>
  <div
    v-if="
      recordDetailStore.currentPageDocument.payment_method ||
      recordDetailStore.currentPageDocument.distribution_method
    "
    class="grid grid-cols-1 sm:grid-cols-2 w-full sm:py-4 divide-y-2 sm:divide-x-2 sm:divide-y-0 border-platform-outlines"
  >
    <div class="flex flex-col pb-10 sm:pr-10 sm:pb-0 gap-8 justify-between">
      <div class="flex flex-row items-center min-h-12">
        <div class="flex flex-col gap-1">
          <span class="font-bold grow">Funding Account</span>
          <p class="text-lighter">
            Select the account to use to fund your investment. Only Checking and Savings accounts
            are supported.
          </p>
        </div>
      </div>
      <div class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
        <div
          class="border border-platform-outlines rounded-xl bg-white p-4 flex flex-row items-center h-full"
        >
          <div
            v-if="recordDetailStore.currentPageDocument.payment_method == 'ACH'"
            class="flex flex-col grow"
          >
            <span class="text-primary font-bold">{{
              banks.funding_account.financial_institution_name
            }}</span>
            <span class="text-light">{{ banks.funding_account.account_type }} account</span>
            <span class="text-light">**** {{ banks.funding_account.mask }}</span>
          </div>
          <div v-else class="grow">
            <span>{{ recordDetailStore.currentPageDocument.payment_method }}</span>
          </div>
          <div v-if="recordDetailStore.currentPageDocument.payment_method == 'ACH'">
            <img
              v-if="banks.funding_account.logo"
              class="h-12 w-12"
              :src="'data:image/png;base64, ' + banks.funding_account.logo + ''"
              alt="Red dot"
            />
          </div>
          <div v-else>
            <Icon
              v-if="recordDetailStore.currentPageDocument.payment_method == 'Wire'"
              name="DollarSign"
              class="h-8 w-8 m-2"
            />
            <Icon
              v-else-if="
                recordDetailStore.currentPageDocument.payment_method == 'Existing Investment'
              "
              name="DollarSign"
              class="h-8 w-8 m-2"
            />
            <Icon
              v-else-if="recordDetailStore.currentPageDocument.payment_method == 'Check'"
              name="Banknote"
              class="h-8 w-8 m-2"
            />
            <IconLandmark v-else class="h-8 w-8" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col pb-10 sm:pl-10 sm:pb-0 gap-8 justify-between">
      <div class="flex flex-row items-center min-h-12 gap-4">
        <div class="flex flex-col gap-1 grow">
          <span class="font-bold grow">Distribution Account</span>
          <p class="text-lighter">
            Phoenix distributes interest monthly via ACH on the 10th of each month. Select the
            account where you'd like interest deposited. Only Checking and Savings accounts are
            supported.
          </p>
        </div>
        <Button
          class="self-end justify-self-end"
          variant="default-gray-outlined"
          size="large"
          :label="true"
          @click="handleOpenBankDrawer('Distribution')"
          ><template #label> <div class="text-base">Edit</div> </template>
        </Button>
      </div>
      <div class="bg-neutral-gray-2 p-4 rounded-2xl h-36">
        <div
          class="border border-platform-outlines rounded-xl bg-white p-4 flex flex-row items-center h-full"
        >
          <div
            v-if="recordDetailStore.currentPageDocument.distribution_method == 'ACH'"
            class="flex flex-col grow"
          >
            <span class="text-primary font-bold">{{
              banks.distrubution_account.financial_institution_name
            }}</span>
            <span class="text-light">{{ banks.distrubution_account.account_type }} account</span>
            <span class="text-light">**** {{ banks.distrubution_account.mask }}</span>
          </div>
          <div v-else class="grow">
            <span>{{ recordDetailStore.currentPageDocument.distribution_method }}</span>
          </div>
          <div v-if="banks.distrubution_account.logo">
            <img
              class="h-12 w-12"
              :src="'data:image/png;base64, ' + banks.distrubution_account.logo + ''"
              alt="Red dot"
            />
          </div>
          <div v-else>
            <Icon
              v-if="recordDetailStore.currentPageDocument.distribution_method == 'Wire'"
              name="DollarSign"
              class="h-8 w-8 m-2"
            />
            <Icon
              v-else-if="recordDetailStore.currentPageDocument.distribution_method == 'Check'"
              name="Banknote"
              class="h-8 w-8 m-2"
            />
            <IconLandmark v-else class="h-8 w-8" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <EmptyState
      icon_name="Landmark"
      description="This investment does not have any banking information."
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { Icon, Button, EmptyState } from "@/components";
import { IconLandmark } from "@/components/icons";
import { useRecordDetailStore } from "@/stores";

const recordDetailStore = useRecordDetailStore();

// Define your data properties
const dataProperty = ref(null);

// Define your methods
const someMethod = () => {
  // Add your method logic here
};

defineProps({
  banks: {
    type: Object,
    required: true,
  },
});

// Define your computed properties
const computedProperty = computed(() => {
  // Add your computed logic here
});

// Lifecycle hook
onMounted(() => {
  // Add your mounted logic here
});
</script>
