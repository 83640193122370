<template>
  <Modal :open="open" @close-modal="$emit('close-modal')" size="36square" hideModalTabs>
    <template v-slot:modal-header>
      <div class="flex items-center">
        <div
          class="mr-4 flex h-8 w-8 items-center justify-center rounded-md bg-cPurple bg-opacity-20 p-1"
        >
          <Icon name="Key" class="h-6 w-6 text-cPurple" :stroke-width="2" />
        </div>

        <p class="text-lg font-semibold text-primary">Add Admin User</p>
      </div>
    </template>

    <template v-slot:modal-body>
      <div class="py-8 px-10 flex flex-col gap-4">
        <BaseInput
          v-if="adminUserForEdit"
          label="Disable User"
          v-model="newAdminUser.is_user_disabled"
          required
          type="boolean"
          :hasYMargin="false"
        />
        <div class="flex items-center gap-4">
          <BaseInput
            label="First Name"
            placeholder="Enter first name"
            v-model="newAdminUser.first_name"
            required
            size="large"
            formatRule="first_name"
            errorMessage="Please enter a valid first name"
          />
          <BaseInput
            label="Last Name"
            placeholder="Enter last name"
            v-model="newAdminUser.last_name"
            required
            size="large"
            formatRule="last_name"
            errorMessage="Please enter a valid last name"
          />
        </div>
        <BaseInput
          label="Email"
          :disabled="adminUserForEdit"
          placeholder="Enter email"
          v-model="newAdminUser.email"
          required
          size="large"
          formatRule="email"
          errorMessage="Please enter a valid email"
        />
        <BaseInput
          label="Admin Permissions"
          required
          v-model:currentTermValue="searchTerm"
          v-model:selectedItems="newAdminUser.permissions"
          type="autofill"
          size="large"
          placeholder="Add Permissions"
          :results="adminPermissions"
          dropdownAbove
        >
          <template #search-dropdown>
            <div class="flex flex-col pt-1">
              <div
                v-for="(result, index) in adminPermissions"
                @mousedown="addPermission(result)"
                :key="index"
                class="relative block cursor-pointer truncate px-1 py-2 pl-3 text-dark hover:bg-platform-hover-secondary"
              >
                <span>{{ result.name }}</span>
              </div>
            </div>
          </template>
        </BaseInput>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="flex flex-row justify-end gap-1">
        <Button size="large" label variant="default-gray-outlined" @click="$emit('close-modal')">
          <template #label>Cancel</template>
        </Button>
        <Button size="large" label @click="handleSubmitAddOrEdit" :disabled="!isFormValid">
          <template #label>{{ adminUserForEdit ? "Update" : "Add" }} User</template>
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { ref, computed, watch, reactive } from "vue";

import { useCrudStore, useAuthStore } from "@/stores";
import { Modal, Button, BaseInput, Icon } from "@/components";

const emit = defineEmits(["close-modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  adminUserForEdit: {
    type: Object,
    default: null,
  },
});

const crudStore = useCrudStore();
const authStore = useAuthStore();

const newAdminUser = reactive({
  first_name: "",
  last_name: "",
  email: "",
  permissions: [],
  is_user_disabled: false,
});

const searchTerm = ref("");
const permissionOptions = ref([
  { name: "Master Admin", value: "master-admin" },
  { name: "Finance Admin", value: "finance-admin" },
  { name: "IR Admin", value: "ir-admin" },
  { name: "Capital Markets Admin", value: "capital-markets-admin" },
  { name: "Marketing Admin", value: "marketing-admin" },
  { name: "Dalmore Admin", value: "dalmore-admin" },
]);

watch(
  () => props.open,
  () => {
    if (props.adminUserForEdit && props.open) {
      newAdminUser.first_name = props.adminUserForEdit.first_name;
      newAdminUser.last_name = props.adminUserForEdit.last_name;
      newAdminUser.email = props.adminUserForEdit.email;
      newAdminUser.permissions = props.adminUserForEdit.permissions.map((permission) => {
        const match = permissionOptions.value.find((option) => option.value === permission);
        return match;
      });
      newAdminUser.is_user_disabled = props.adminUserForEdit.is_user_disabled;
    }
    if (!props.open) {
      newAdminUser.first_name = "";
      newAdminUser.last_name = "";
      newAdminUser.email = "";
      newAdminUser.permissions = [];
      newAdminUser.is_user_disabled = false;
    }
  }
);

const isFormValid = computed(() => {
  return (
    newAdminUser.first_name &&
    newAdminUser.last_name &&
    newAdminUser.email &&
    newAdminUser.permissions.length
  );
});

const adminPermissions = computed(() => {
  return permissionOptions.value.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
});

function addPermission(permission) {
  const permissionNames = newAdminUser.permissions.map((permission) => permission.name);
  if (!permissionNames.includes(permission.name)) {
    newAdminUser.permissions.push(permission);
    searchTerm.value = "";
  }
}

async function handleSubmitAddOrEdit() {
  try {
    if (props.adminUserForEdit) {
      let updatedAdminUser = {
        first_name: newAdminUser.first_name,
        last_name: newAdminUser.last_name,
        permissions: newAdminUser.permissions.map((permission) => permission.value),
        is_user_disabled: newAdminUser.is_user_disabled,
      };
      await crudStore.updateOne(
        "AdminUserData",
        { user_id: props.adminUserForEdit.user_id },
        {
          $set: updatedAdminUser,
        }
      );
      // update the admin user in the store
      const updatedAdminUserIndex = authStore.allAdminUsers.findIndex(
        (adminUser) => adminUser.user_id === props.adminUserForEdit.user_id
      );
      authStore.allAdminUsers[updatedAdminUserIndex] = {
        ...authStore.allAdminUsers[updatedAdminUserIndex],
        ...updatedAdminUser,
      };
      if (props.adminUserForEdit.user_id === authStore.currentUser.id) {
        authStore.adminUserData.permissions = updatedAdminUser.permissions;
      }
    } else {
      const newUserId = await authStore.registerNewAdminUser(newAdminUser.email);
      let newAdminUserObject = {
        user_id: newUserId,
        first_name: newAdminUser.first_name,
        last_name: newAdminUser.last_name,
        email: newAdminUser.email,
        permissions: newAdminUser.permissions.map((permission) => permission.value),
      };
      authStore.allAdminUsers.push(newAdminUserObject);
      await crudStore.insertOne("AdminUserData", newAdminUserObject);
    }
    emit("close-modal");
  } catch (err) {
    console.error(err);
  }
}
</script>
