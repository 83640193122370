<template>
  <div>
    <div class="py-2 text-xl font-bold">Select Method for Conversion</div>
    <div class="mt-4 flex flex-col gap-6">
      <label
        v-for="(option, index) in methodOptions"
        :key="option.id"
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="
          investmentStore.selectedConversionMethod?.id === option?.id
            ? 'border-gold'
            : 'border-platform-outlines'
        "
        @click="investmentStore.selectedConversionMethod = option"
      >
        <RadioOption
          :id="index"
          name="conversionOption"
          :value="option"
          v-model="investmentStore.selectedConversionMethod"
          :label="`${option?.name}`"
          :description="option?.description"
          :disabled="false"
        />
      </label>
    </div>
    <div class="mt-6 flex flex-col md:flex-row gap-4 md:justify-between md:items-center">
      <BaseInput
        v-model="investmentStore.conversionDetails.selectedOffering"
        type="dropdown"
        label="Select New Offering"
        placeholder="Select an offering"
        :options="offeringOptions"
        size="large"
        required
        showFullText
        class="w-full md:w-80 min-w-80"
      />
      <div class="flex w-full md:w-2/3 mt-4 md:mt-0">
        <RadioOption
          id="compounded"
          name="paymentFrequency"
          :value="true"
          v-model="investmentStore.conversionDetails.is_compounded"
          label="Compounded Monthly"
          description="Interest and principal are paid at maturity"
          :disabled="isPaymentFrequencyDisabled"
        />
        <RadioOption
          id="monthly"
          name="paymentFrequency"
          :value="false"
          v-model="investmentStore.conversionDetails.is_compounded"
          label="Interest Paid Monthly"
          description="Interest is paid every month"
        />
      </div>
    </div>
    <!-- Adding Funds - Select Payment Method Section -->
    <div v-if="investmentStore.selectedConversionMethod?.id === 2" class="mt-6 pb-6">
      <BaseInput
        v-model="investmentStore.conversionDetails.amount_to_add"
        placeholder="0.00"
        @blur="roundInvestmentAmount"
        label="How much new capital?"
        size="large"
        maxLength="8"
        formatRule="amount"
        required
        class="w-full md:w-80 mb-4"
      />
      <div class="py-2 text-xl font-bold">Select Funding Method:</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="flex flex-col gap-4 md:col-span-2 col-span-1 w-full">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 bg-neutral-gray-2 rounded-md p-3">
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.conversionPaymentMethod === 'Check'
                  ? 'border-secondary-6'
                  : 'border-platform-outlines'
              "
              @click="
                (investmentStore.conversionPaymentMethod = 'Check'), setBankAccountsInactive()
              "
            >
              <RadioOption
                id="check"
                name="paymentMethod"
                value="Check"
                v-model="investmentStore.conversionPaymentMethod"
                label="Check"
                description="Make payment by check"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon
                  name="ArrowRightLeft"
                  class="h-12 w-12 text-neutral-gray-8-"
                  :stroke-width="1"
                />
              </div>
            </label>
            <label
              class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
              :class="
                investmentStore.conversionPaymentMethod === 'Wire'
                  ? 'border-secondary-6'
                  : 'border-platform-outlines'
              "
              @click="(investmentStore.conversionPaymentMethod = 'Wire'), setBankAccountsInactive()"
            >
              <RadioOption
                id="wire"
                name="paymentMethod"
                value="Wire"
                v-model="investmentStore.conversionPaymentMethod"
                label="Wire"
                description="Make payment by wire transfer"
              />
              <div class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                <Icon
                  name="ArrowRightLeft"
                  class="h-12 w-12 text-neutral-gray-8-"
                  :stroke-width="1"
                />
              </div>
            </label>
            <div
              v-for="(bankingOption, index) in !showMoreFunding
                ? investmentStore.bankAccounts.slice(0, 4)
                : investmentStore.bankAccounts"
              :key="bankingOption.id"
            >
              <!-- Plaid Accounts will go here -->
              <label
                class="bg-white border flex items-center justify-between h-32 rounded-md p-3 px-6 cursor-pointer"
                :class="
                  bankingOption._id === investmentStore.conversionFundingAccount &&
                  investmentStore.conversionPaymentMethod === 'ACH'
                    ? 'border-secondary-6'
                    : 'border-platform-outlines'
                "
                @click="setActiveFundingMethod(bankingOption)"
              >
                <RadioOption
                  :id="'' + index"
                  :name="'FundingAccount' + index"
                  :value="bankingOption._id"
                  v-model="investmentStore.conversionFundingAccount"
                  :label="bankingOption.financial_institution_name"
                  :description="bankingOption.account_type"
                  :account_num="bankingOption.account_number"
                />
                <img
                  v-if="bankingOption.logo"
                  class="h-16 w-16"
                  :src="'data:image/png;base64, ' + bankingOption.logo + ''"
                  alt="Red dot"
                />
                <div v-else class="bg-neutral-gray-4 rounded-md p-4 h-16 w-16 flex items-center">
                  <Icon name="Landmark" class="h-12 w-12 text-neutral-gray-8-" :stroke-width="1" />
                </div>
              </label>
            </div>
            <button
              v-if="investmentStore.bankAccounts.length > 4"
              @click="showMoreFunding = !showMoreFunding"
              class="text-center lg:col-span-2"
            >
              <span v-if="!showMoreFunding"
                >Show More ({{
                  investmentStore.bankAccounts.slice(4, investmentStore.bankAccounts.length).length
                }})</span
              >
              <span v-else>Show Less</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";

import { useInvestmentStore, useCrudStore } from "@/stores";
import { RadioOption, BaseInput, Icon, Button } from "@/components";
import { commaSeparateThousands_2 } from "@/utilities";

const investmentStore = useInvestmentStore();
const crudStore = useCrudStore();

const methodOptions = ref([
  {
    id: 1,
    name: "Switch Offerings",
    description: "Convert the offering of the selected investment(s)",
  },
  {
    id: 2,
    name: "Add Funds and Switch Offerings",
    description: "Add funds to the selected investment(s) and convert the offering",
  },
]);

const offeringOptions = ref([]);
const isPaymentFrequencyDisabled = ref(false);

onMounted(async () => {
  const offerings = await crudStore.find("Offerings", { is_visible_to_admins: true });

  const regAOfferings = [];
  const s1Offerings = [];
  const regDOfferings = [];

  for (const offering of offerings) {
    if (offering.type === "Reg A") {
      regAOfferings.push(offering);
    } else if (offering.type === "S1") {
      s1Offerings.push(offering);
    } else if (offering.type === "Reg D") {
      regDOfferings.push(offering);
    }
  }

  // Sort each group by interest rate
  regAOfferings.sort((a, b) => a.interest_rate - b.interest_rate);
  s1Offerings.sort((a, b) => a.interest_rate - b.interest_rate);

  // Sort Reg D offerings by interest rate, with Adamantium at the end
  regDOfferings.sort((a, b) => {
    if (a.issuer === "Adamantium Capital" && b.issuer !== "Adamantium Capital") {
      return 1;
    }
    if (a.issuer !== "Adamantium Capital" && b.issuer === "Adamantium Capital") {
      return -1;
    }
    return a.interest_rate - b.interest_rate;
  });

  const sortedOfferings = [...regAOfferings, ...s1Offerings, ...regDOfferings];

  // Create the offering options
  for (const offering of sortedOfferings) {
    offeringOptions.value.push({
      offering: offering,
      name: `${offering.type} - ${offering.type !== "S1" && offering.type !== "Reg A" ? "Series" : ""} ${offering.name} (${offering.issuer === "Adamantium Capital" ? "ADAMANTIUM - " : ""}${offering.interest_rate}% - ${offering.bond_term / 12} year)`,
    });
  }
});

watch(
  () => investmentStore.conversionDetails.selectedOffering,
  () => {
    const selectedOffering = investmentStore.conversionDetails.selectedOffering?.offering;

    if (selectedOffering && (selectedOffering.type === "Reg A" || selectedOffering.type === "S1")) {
      investmentStore.conversionDetails.is_compounded = false;
      isPaymentFrequencyDisabled.value = true;
    } else {
      isPaymentFrequencyDisabled.value = false;
    }
  }
);

function roundInvestmentAmount() {
  let amount = investmentStore.conversionDetails.amount_to_add;
  const numberAmount = Number(amount.replace(/,/g, ""));
  const roundedAmount = Math.round(numberAmount / 1000) * 1000;
  const formattedAmount = commaSeparateThousands_2(roundedAmount.toString());
  investmentStore.conversionDetails.amount_to_add = formattedAmount;
}

function setActiveFundingMethod(bankingOption) {
  investmentStore.conversionPaymentMethod = "ACH";
  investmentStore.conversionFundingAccount = bankingOption._id;
}

function setBankAccountsInactive() {
  investmentStore.conversionFundingAccount = null;
  investmentStore.bankAccounts.forEach((account) => {
    account.is_active = false;
  });
}
</script>
