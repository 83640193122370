<template>
  <TransitionRoot as="template" :show="open" class="z-50">
    <Dialog
      as="div"
      class="relative z-10"
      @close="keepModalOpen ? '' : $emit('close-modal')"
      id="main-modal"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-neutral-gray-13 bg-opacity-80 transition-opacity lg:ml-value-1 lg:mt-[3.5em]"
        />
      </TransitionChild>
      <div class="fixed inset-0 z-10">
        <div
          class="flex h-[100%] items-end text-center lg:ml-value-1 lg:items-center lg:justify-center"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel id="modal" :class="inputStyles" class="flex flex-col">
              <div
                id="header"
                class="flex h-16 w-full items-center justify-between border-b border-neutral-gray-4 p-4"
              >
                <div>
                  <slot name="modal-header" />
                </div>

                <button
                  v-if="!UIStore.badTokenModalOpen"
                  @click="$emit('close-modal')"
                  class="px-3"
                >
                  <IconX class="h-6 w-6 fill-neutral-gray-6" />
                </button>
              </div>
              <div v-if="!hideModalTabs" class="h-13">
                <slot name="modal-tabs" />
              </div>

              <div :class="modalStyles">
                <slot name="modal-body" />
              </div>
              <div
                v-if="!hideFooter"
                id="footer"
                class="relative h-17 w-full border-t border-neutral-gray-5 bg-neutral-gray-1 px-6 py-value-2"
              >
                <slot name="modal-footer" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { useUIStore } from "../../stores/useUIStore";
const UIStore = useUIStore();
import { Button } from "@/components";
import { IconX } from "@/components/icons";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  size: {
    type: String,
    default: "",
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  hideModalTabs: {
    type: Boolean,
    default: false,
  },
  keepModalOpen: {
    type: Boolean,
    default: false,
  },
  customHeight: {
    type: String,
    default: "",
  },
  customWidth: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close-modal"]);

const inputStyles = computed(() => {
  let baseClass = `relative w-full transform overflow-hidden rounded-lg bg-neutral-gray-1 text-left shadow-xl transition-all`;
  let customHeight;
  let customWidth;

  switch (props.size) {
    case "small-narrow":
      customHeight = "lg:h-[30.875rem]";
      customWidth = "lg:w-value-27";
      break;
    case "small":
      customHeight = "max-h-value-24 md:h-value-24 lg:h-value-24";
      customWidth = "lg:w-152";
      break;
    case "md":
      customHeight = "lg:h-144";
      customWidth = "lg:w-188";
      break;
    case "36square":
      customHeight = "lg:h-144";
      customWidth = "lg:w-144";
      break;
    case "32square":
      customHeight = "lg:h-128";
      customWidth = "lg:w-128";
      break;
    case "26x38":
      customHeight = "lg:h-104";
      customWidth = "lg:w-152";
      break;
    case "36x46":
      customHeight = "lg:h-144";
      customWidth = "lg:w-184";
      break;
    case "26x32":
      customHeight = "lg:h-80";
      customWidth = "lg:w-128";
      break;
    case "large":
      customHeight = "h-value-15 md:h-value-23 lg:h-160 max-h-160";
      customWidth = "lg:w-188";
      break;
    default:
      customHeight = "";
      customWidth = "";
      break;
  }

  if (props.customHeight && props.customWidth) {
    customHeight = props.customHeight;
    customWidth = props.customWidth;
  }

  let final_class = `${baseClass} ${customHeight} ${customWidth}`;

  return final_class;
});
const modalStyles = computed(() => {
  let baseClass = "grow overflow-auto bg-neutral-gray-2";
  let height;

  switch (props.size) {
    case "small-narrow":
      height = "h-80";
      break;
    case "small":
      height = "h-50";
      break;
    case "md":
      height = "lg:h-96";
      break;
    case "large":
      height = "lg:h-96";
      break;
    default:
      height = "h-80";
      break;
  }

  let final_class = `${baseClass} ${height}`;

  return final_class;
});
</script>
