<template>
  <div>
    <SideDrawer
      @close-drawer="$emit('close-drawer')"
      :open="open"
      customWidth="lg:w-2/3 lg:max-w-[1300px]"
      hideHeader
      showFooter
    >
      <template v-slot:tabs>
        <div class="font-semibold mt-1">View History Edits</div>
      </template>
      <template v-slot:main-content>
        <div class="flex flex-col w-full p-6">
          <Table
            :headers="historyEditsTableHeaders"
            :tableData="UIStore.transformRecords(edits)"
            class="max-w-full"
            use_table_loader
            :fields-with-custom-styles="['old_value', 'new_value']"
            hideSort
          >
            <template v-slot:old_value="data">
              <div class="ml-4 text-wrap p-2">
                <p>
                  {{ data.row.old_value.value === null ? "null" : data.row.old_value.value }}
                </p>
              </div>
            </template>
            <template v-slot:new_value="data">
              <div class="ml-4 text-wrap p-2">
                <p>
                  {{ data.row.new_value.value === null ? "null" : data.row.new_value.value }}
                </p>
              </div>
            </template>
          </Table>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex justify-end">
          <Button @click="$emit('close-drawer')" label icon size="large" class="w-full md:w-32">
            <template #label>Close</template>
          </Button>
        </div>
      </template>
    </SideDrawer>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { SideDrawer, Button, Table } from "@/components";
import { useUIStore } from "@/stores";

const UIStore = useUIStore();

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  edits: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["close-drawer"]);

const historyEditsTableHeaders = ref([
  { field_name: "field", label: "Field" },
  { field_name: "old_value", label: "Old Value" },
  { field_name: "new_value", label: "New Value" },
]);
</script>
