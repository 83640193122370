<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ createInvestmentStore.currentStep + 1 }}: Select Entity Account
    </div>
  </div>
  <div class="mb-4">
    <div class="mt-4 flex flex-col gap-6">
      <!-- Display only the first 5 items initially -->
      <!-- New Entity Account option -->
      <label
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="
          createInvestmentStore.add_entity_account ? 'border-gold' : 'border-platform-outlines'
        "
        @click="setTemplate(null)"
      >
        <RadioOption
          id="newEntityAccount"
          name="newEntityAccount"
          :value="null"
          v-model="createInvestmentStore.selected_entity_account"
          label="New Entity Account"
          description="Create new entity"
        />
      </label>
      <label
        v-for="(entity_account, index) in visibleEntityAccounts"
        :key="entity_account._id"
        class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
        :class="
          createInvestmentStore.selected_entity_account?._id === entity_account._id
            ? 'border-gold'
            : 'border-platform-outlines'
        "
        @click="setTemplate(entity_account)"
      >
        <RadioOption
          :id="index"
          name="activeEntityAccount"
          :value="entity_account"
          v-model="createInvestmentStore.selected_entity_account"
          :label="`${entity_account.name}`"
          :description="entity_account.phone"
        />
      </label>

      <!-- Show more / Show less link -->
      <div
        class="w-full mb-4 flex items-center justify-center"
        v-if="createInvestmentStore.existing_entity_accounts.length > 5"
      >
        <p
          class="text-primary-6 font-semibold hover:underline cursor-pointer"
          @click="toggleShowAll"
        >
          {{
            showAll
              ? "Show less"
              : `Show More (${createInvestmentStore.existing_entity_accounts.length - 5})`
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { RadioOption } from "@/components";
import _ from "lodash";

const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

const showAll = ref(false); // Control whether to show all items or not

const visibleEntityAccounts = computed(() => {
  return showAll.value
    ? createInvestmentStore.existing_entity_accounts
    : createInvestmentStore.existing_entity_accounts.slice(0, 5);
});

function toggleShowAll() {
  showAll.value = !showAll.value;
}

function setTemplate(entity_account) {
  if (entity_account) {
    createInvestmentStore.add_entity_account = false;
    createInvestmentStore.selected_entity_account = _.cloneDeep(entity_account);
    createInvestmentStore.entity_template = _.cloneDeep(entity_account);
  } else {
    createInvestmentStore.add_entity_account = true;
    createInvestmentStore.selected_entity_account = {};
    createInvestmentStore.entity_template = schemaStore.createEntityTemplate();
    createInvestmentStore.entity_template.user_id =
      createInvestmentStore.current_owner_contact.user_id;
    createInvestmentStore.entity_template.contact_id =
      createInvestmentStore.current_owner_contact.contact_id;
  }
}
</script>
