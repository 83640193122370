<template>
  <div class="flex flex-col">
    <div class="py-8 border-b border-platform-outlines">
      <div class="flex justify-between">
        <div>
          <div class="font-semibold text-lg mb-2">Funding Account Information</div>
          <div class="text-sm text-light mb-4 max-w-90">
            Select the account to use to fund your investment. Only Checking and Savings accounts
            are supported.
          </div>
        </div>
        <Button @click="openAddBankModal" variant="default-gray-outlined" label>
          <template #label>{{ AddOrEdit }}</template>
        </Button>
      </div>

      <div class="bg-neutral-gray-2 rounded-2xl p-4">
        <div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <label
              v-for="(bank_account, index) in visibleBankAccounts"
              :key="bank_account.unique_id"
              class="bg-white border flex items-center min-h-24 rounded-md cursor-pointer"
              :class="
                createInvestmentStore.selected_funding_method.unique_id == bank_account.unique_id
                  ? 'border-gold'
                  : 'border-platform-outlines'
              "
              @click="createInvestmentStore.selected_funding_method = bank_account"
            >
              <RadioOptionBanking
                name="activeFunBankAccount"
                :id="bank_account.unique_id"
                :value="bank_account"
                v-model="createInvestmentStore.selected_funding_method"
                :label="`${bank_account.financial_institution_name}`"
                :description="`${bank_account.account_type}`"
                :description2="`${bank_account.account_number_for_display}`"
              >
                <template #icon>
                  <div
                    v-if="
                      bank_account.is_type_rollover ||
                      bank_account.is_check_method ||
                      bank_account.is_wire_transfer ||
                      bank_account.is_new_bank_account
                    "
                    class="bg-neutral-gray-3 p-value-31 rounded"
                  >
                    <Icon
                      name="CircleDollarSign"
                      v-if="bank_account.is_type_rollover"
                      class="h-4.5 w-4.5 stroke-lighter"
                      :stroke-width="2"
                    />
                    <Icon
                      name="ArrowRightLeft"
                      v-if="bank_account.is_check_method"
                      class="h-4.5 w-4.5 stroke-lighter"
                      :stroke-width="2"
                    />
                    <Icon
                      name="Landmark"
                      v-if="bank_account.is_wire_transfer"
                      class="h-4.5 w-4.5 stroke-lighter"
                      :stroke-width="2"
                    />
                    <Icon
                      name="Landmark"
                      v-if="bank_account.is_new_bank_account"
                      class="h-4.5 w-4.5 stroke-lighter"
                      :stroke-width="2"
                    />
                  </div>
                  <div v-else>
                    <img
                      v-if="bank_account.logo"
                      class="h-12.5 w-12.5"
                      :src="'data:image/png;base64, ' + bank_account.logo + ''"
                      alt="Red dot"
                    />
                    <div v-else class="bg-neutral-gray-3 p-value-31 rounded">
                      <Icon name="Landmark" class="h-4.5 w-4.5 stroke-lighter" :stroke-width="2" />
                    </div>
                  </div>
                </template>
              </RadioOptionBanking>
            </label>
          </div>
          <div class="mt-4 w-full flex justify-center">
            <p
              id="base-input"
              v-if="!showAll && createInvestmentStore.owners_funding_bank_accounts.length > 2"
              @click="toggleShowAll"
              class="text-primary-6 font-semibold hover:underline"
            >
              Show More ({{ createInvestmentStore.owners_funding_bank_accounts.length - 2 }})
            </p>
            <p
              id="base-input"
              v-if="showAll"
              @click="toggleShowAll"
              class="text-primary-6 font-semibold hover:underline"
            >
              Show Less
            </p>
          </div>
        </div>
      </div>

      <RolloverTable
        v-if="
          createInvestmentStore.selected_funding_method.financial_institution_name == 'Rollover'
        "
      />
      <div
        v-if="
          !createInvestmentStore.selected_funding_method.is_type_rollover &&
          !createInvestmentStore.selected_funding_method.is_wire_transfer &&
          !createInvestmentStore.selected_funding_method.is_check_method &&
          Object.keys(createInvestmentStore.selected_funding_method).length > 0
        "
        class="mt-8 flex ml-2"
      >
        <input
          type="checkbox"
          v-model="createInvestmentStore.add_to_ach_batch"
          id="select-all"
          class="accent-secondary-6 h-5 w-5"
        />

        <div class="flex items-center font-semibold text-light ml-4">Add to ach batch?</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";

import { useCreateInvestmentStore } from "@/stores";
import { Button, RadioOptionBanking } from "@/components";

import RolloverTable from "./RolloverTable.vue";

import { Icon } from "@/components";

const showAll = ref(false);

const visibleBankAccounts = computed(() => {
  return showAll.value
    ? createInvestmentStore.owners_funding_bank_accounts
    : createInvestmentStore.owners_funding_bank_accounts.slice(0, 2);
});
const AddOrEdit = computed(() => {
  let new_bank_exists = createInvestmentStore.owners_funding_bank_accounts.find(
    (item) => item.is_new_bank_account
  );
  if (new_bank_exists) {
    return "Edit";
  } else {
    return "Add";
  }
});

const toggleShowAll = () => {
  showAll.value = !showAll.value;
};

const createInvestmentStore = useCreateInvestmentStore();

function openAddBankModal() {
  createInvestmentStore.bank_account_type = "Funding";
  createInvestmentStore.bank_account_modal_open = true;
}
</script>
