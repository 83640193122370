<template>
  <div>
    <!-- Use the CustomModal component -->
    <Modal :open="open" :hideModalTabs="true" @close-modal="$emit('close-modal')" size="large">
      <!-- Customize the modal content using slots -->
      <template v-slot:modal-header>
        <div class="text-xl font-semibold">Add Beneficial Owner</div>
      </template>

      <template v-slot:modal-body>
        <div v-if="createInvestmentStore.ben_owner_modal_page == 1">
          <div class="m-4 flex flex-col gap-2">
            <label
              v-for="(ben_owner, index) in createInvestmentStore.all_beneficial_owners_for_user"
              :key="ben_owner._id"
              class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
              :class="{
                'border-gold':
                  createInvestmentStore.current_beneficial_owner_for_modal?._id === ben_owner._id,
              }"
              @click="setTemplate(ben_owner)"
            >
              <RadioOption
                :id="index"
                name="activeBenOwnerContact"
                :value="ben_owner"
                v-model="createInvestmentStore.current_beneficial_owner_for_modal"
                :label="`${ben_owner.first_name} ${ben_owner.last_name} - ${ben_owner.type}`"
                :description="ben_owner.email"
              />
            </label>
            <label
              class="bg-white border flex items-center h-18 rounded-md p-3 pl-6 cursor-pointer"
              :class="{
                'border-gold':
                  createInvestmentStore.current_beneficial_owner_for_modal._id ==
                  createInvestmentStore.ben_owner_template._id,
              }"
              @click="setTemplate(createInvestmentStore.ben_owner_template)"
            >
              <!-- {{ createInvestmentStore.current_beneficial_owner_for_modal }}
              1
              {{ createInvestmentStore.ben_owner_template._id }} -->
              <RadioOption
                id="newBenOwnerContact"
                name="newBenOwnerContact"
                :value="createInvestmentStore.ben_owner_template"
                v-model="createInvestmentStore.current_beneficial_owner_for_modal"
                label="New Beneficial Owner"
                description="Create Beneficial Owner"
              />
            </label>
          </div>
        </div>
        <div v-if="createInvestmentStore.ben_owner_modal_page == 2">
          <div class="flex flex-col mx-4">
            <div
              v-for="(group, index) in benOwnerInfo"
              :key="index"
              :class="{
                'py-8 border-b border-platform-outlines': index !== benOwnerInfo.length - 1,
                'py-8': index === benOwnerInfo.length - 1,
              }"
            >
              <div class="font-semibold text-lg mb-4">
                {{ group.group_name }}
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div v-for="field of group.fields">
                  <BaseInput
                    :required="field.is_required"
                    :disabled="
                      field.read_only ||
                      (createInvestmentStore.current_beneficial_owner_for_modal
                        .has_existing_investments &&
                        disabledFieldsToCheck.includes(field.field_name))
                    "
                    :label="field.label"
                    v-if="!field.is_association_field && !field.belongs_to_nested_object"
                    v-model="
                      createInvestmentStore.current_beneficial_owner_for_modal[field.field_name]
                    "
                    :formatRule="field.field_name"
                    :errorMessage="field.error_message"
                    :type="field.field_type"
                    :options="field.dropdown_options"
                    size="large"
                    customClass="bg-neutral-gray-1"
                  ></BaseInput>
                  <BaseInput
                    :required="field.is_required"
                    :disabled="
                      field.read_only ||
                      (createInvestmentStore.current_beneficial_owner_for_modal
                        .has_existing_investments &&
                        disabledFieldsToCheck.includes(field.field_name))
                    "
                    :formatRule="field.field_name"
                    :errorMessage="field.error_message"
                    :label="field.label"
                    v-if="!field.is_association_field && field.belongs_to_nested_object"
                    v-model="
                      createInvestmentStore.current_beneficial_owner_for_modal[
                        field.nested_object_name
                      ][field.field_name]
                    "
                    :options="field.dropdown_options"
                    :type="field.field_type"
                    size="large"
                    customClass="bg-neutral-gray-1"
                  ></BaseInput>

                  <div
                    v-if="field.field_name === 'ssn' && ssn_is_invalid"
                    class="text-functional-error-default text-sm my-1"
                  >
                    Invalid SSN. SSN can not match the SSN of the owner or the SSN of any other
                    Joint contacts or Beneficial Owner Contacts tied to this owner
                  </div>
                  <div
                    v-if="field.field_name === 'email' && email_is_invalid"
                    class="text-functional-error-default text-sm my-1"
                  >
                    Invalid Email. Email can not match the email of the owner or the email of any
                    other Joint contacts or Beneficial Owner Contacts tied to this owner
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:modal-footer>
        <div class="flex justify-between">
          <div>
            <Button
              v-if="createInvestmentStore.ben_owner_modal_page == 2"
              size="large"
              label
              variant="default-gray-outlined"
              @click="createInvestmentStore.ben_owner_modal_page = 1"
            >
              <template #label><p>Back</p></template></Button
            >
          </div>
          <div class="flex flex-row justify-end gap-1">
            <Button
              size="large"
              label
              variant="default-gray-outlined"
              @click="$emit('close-modal')"
            >
              <template #label><p>Cancel</p></template></Button
            >
            <Button size="large" :disabled="nextIsDisabled" label @click="handleNextAndSave()">
              <template #label>{{
                createInvestmentStore.ben_owner_modal_page == 1 ? "Next" : "Save"
              }}</template></Button
            >
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { Button, Modal, RadioOption, BaseInput } from "@/components";

import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { watch, computed, ref } from "vue";
import _ from "lodash";

const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

const empty_model = ref({});

const emit = defineEmits(["close_modal"]);
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});
watch(
  () => props.open,
  () => {
    if (!props.open) {
      empty_model.value = {};
    } else {
      empty_model.value = createInvestmentStore.current_beneficial_owner_for_modal;
    }
  }
);

function setTemplate(ben_owner) {
  createInvestmentStore.current_beneficial_owner_for_modal = _.cloneDeep(ben_owner);
}

function handleNextAndSave() {
  if (createInvestmentStore.ben_owner_modal_page == 1) {
    createInvestmentStore.ben_owner_modal_page = 2;
    return;
  } else {
    let index = createInvestmentStore.entity_template.beneficial_owners.findIndex(
      (owner) => owner._id === createInvestmentStore.current_beneficial_owner_for_modal._id
    );
    if (index > -1) {
      createInvestmentStore.entity_template.beneficial_owners[index] = _.cloneDeep(
        createInvestmentStore.current_beneficial_owner_for_modal
      );
    } else {
      createInvestmentStore.entity_template.beneficial_owners.unshift(
        _.cloneDeep(createInvestmentStore.current_beneficial_owner_for_modal)
      );
    }
    empty_model.value = {};
    emit("close-modal");
  }
}

const benOwnerInfo = computed(() => {
  const isBeneficialOwner =
    createInvestmentStore.current_beneficial_owner_for_modal.type === "Beneficial Owner";
  const isRegD = createInvestmentStore.selected_offering.type === "Reg D";

  if (isBeneficialOwner) {
    if (isRegD) {
      return schemaStore.accredited_ben_owner_contact_personal_info_schema;
    } else {
      return schemaStore.non_accredited_ben_owner_contact_personal_info_schema;
    }
  } else {
    // This covers the "Joint" type or any other type that isn't "Beneficial Owner"
    if (isRegD) {
      return schemaStore.accredited_joint_contact_personal_info_schema; // Assuming you have a Reg D schema for Joint type here
    } else {
      return schemaStore.non_accredited_joint_contact_personal_info_schema; // Assuming you have a Reg A schema for Joint type here
    }
  }
});

const disabledFieldsToCheck = computed(() => {
  if (createInvestmentStore.current_beneficial_owner_for_modal.type == "Joint") {
    return schemaStore.admin_disabled_joint_contact_fields;
  } else {
    return schemaStore.admin_disabled_beneficial_owner_contact_fields;
  }
});
const nextIsDisabled = computed(() => {
  if (
    createInvestmentStore.ben_owner_modal_page == 1 &&
    Object.keys(createInvestmentStore.current_beneficial_owner_for_modal).length == 0
  ) {
    return true;
  }
  if (createInvestmentStore.ben_owner_modal_page == 2) {
    if (ssn_is_invalid.value) {
      return true;
    }
    if (email_is_invalid.value) {
      return true;
    }
    for (let group of benOwnerInfo.value) {
      for (let field of group.fields) {
        if (field.belongs_to_nested_object) {
          if (
            field.is_required &&
            !createInvestmentStore.current_beneficial_owner_for_modal[field.nested_object_name][
              field.field_name
            ]
          ) {
            return true;
          }
        } else {
          if (
            field.is_required &&
            !createInvestmentStore.current_beneficial_owner_for_modal[field.field_name]
          ) {
            return true;
          }
        }
      }
    }
  }
});
const ssn_is_invalid = computed(() => {
  const NewContactTemplate = createInvestmentStore.current_beneficial_owner_for_modal;
  const existingContacts = createInvestmentStore.all_beneficial_owners_for_user;
  const invalidSSNs = createInvestmentStore.invalid_ssn_array;

  const matchingContact = existingContacts.find(
    (contact) => contact._id === NewContactTemplate._id
  );

  if (matchingContact) {
    const isInvalid =
      NewContactTemplate.ssn !== matchingContact.ssn &&
      invalidSSNs.includes(NewContactTemplate.ssn);

    return isInvalid;
  } else {
    const isInvalid = invalidSSNs.includes(NewContactTemplate.ssn);

    return isInvalid;
  }
});
const email_is_invalid = computed(() => {
  const NewContactTemplate = createInvestmentStore.current_beneficial_owner_for_modal;
  const existingContacts = createInvestmentStore.all_beneficial_owners_for_user;
  const invalidEmails = createInvestmentStore.invalid_email_array;

  const matchingContact = existingContacts.find(
    (contact) => contact._id === NewContactTemplate._id
  );

  if (matchingContact) {
    const isInvalid =
      NewContactTemplate.email !== matchingContact.email &&
      invalidEmails.includes(NewContactTemplate.email);
    return isInvalid;
  } else {
    const isInvalid = invalidEmails.includes(NewContactTemplate.email);
    return isInvalid;
  }
});
</script>
