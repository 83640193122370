<template>
  <div>
    <div class="pb-2 text-primary-6 font-semibold">Custodian Details</div>
    <div class="flex flex-col gap-2 text-light text-sm">
      <div>Custodian Name: {{ titleTransferStore.current_investment.custodian_name }}</div>
      <div>
        Custodian Number: {{ titleTransferStore.current_investment.custodian_account_number }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTitleTransferStore } from "@/stores";
const titleTransferStore = useTitleTransferStore();
</script>
