<template>
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#4w0s842zwa)">
      <mask
        id="i0u8o2zacb"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path fill="#D9D9D9" d="M0 0h16v16H0z" />
      </mask>
      <g mask="url(#i0u8o2zacb)">
        <path
          d="M2.031 10.785V6.607H.638V5.215h2.786v5.57H2.03zm3.25 0v-2.32c0-.264.088-.484.266-.662a.898.898 0 0 1 .662-.267h1.857v-.929H5.28V5.215h3.25c.263 0 .483.089.661.267a.898.898 0 0 1 .267.661v1.393a.898.898 0 0 1-.267.661.898.898 0 0 1-.661.267H6.673v.929h2.785v1.392H5.28zm5.57 0V9.393h2.785v-.929h-1.857v-.928h1.857v-.929H10.85V5.215H14.1c.263 0 .484.089.662.267a.898.898 0 0 1 .266.661v3.714a.899.899 0 0 1-.267.661.898.898 0 0 1-.661.267h-3.25z"
          fill="#BFBFBF"
        />
      </g>
    </g>
    <defs>
      <clipPath id="4w0s842zwa">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
