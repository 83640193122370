<template>
  <div
    class="w-full p-4 border-2 border-dashed rounded-md transition-colors duration-200 border-neutral-gray-5 grow flex items-center justify-center"
    :class="dragOver ? 'border-primary-6 bg-primary-50' : 'border-neutral-gray-4 bg-neutral-100'"
    @dragover.prevent="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <div class="flex flex-col items-center justify-center" :class="computedHeight">
      <Icon name="Upload" class="h-6 w-6 text-primary-6 mb-4" :stroke-width="2" />
      <p class="text-sm text-lighter mb-4">
        Drag the file here or click select and upload your files
      </p>
      <Button @click="openFileDialog" label>
        <template #label>Upload FIles</template>
      </Button>
      <input
        ref="fileInput"
        type="file"
        class="hidden"
        accept=".pdf, .jpeg, .jpg, .png, .docx"
        @change="handleFilesSelected"
        :multiple="!selectOne"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import { Icon, Button } from "@/components";

const props = defineProps({
  selectOne: {
    type: Boolean,
    default: false,
  },
  customHeight: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["files-selected"]);

const fileInput = ref(null);
const dragOver = ref(false);

const computedHeight = computed(() => props.customHeight || "h-48");

const openFileDialog = () => {
  fileInput.value.click();
};

const handleFilesSelected = (event) => {
  const files = Array.from(event.target.files);
  emit("files-selected", files);
};

const handleDragOver = () => {
  dragOver.value = true;
};

const handleDragLeave = () => {
  dragOver.value = false;
};

const handleDrop = (event) => {
  dragOver.value = false;
  let files = [];
  if (props.selectOne) {
    files = [Array.from(event.dataTransfer.files)[0]];
  } else {
    files = Array.from(event.dataTransfer.files);
  }
  emit("files-selected", files);
};
</script>
