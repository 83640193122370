<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m14 11.333-1.437-1.245a.333.333 0 0 0-.563.245v.334a.667.667 0 0 1-.667.666H10a.667.667 0 0 1-.667-.666C9.333 8.97 6.673 8.02 3.667 8a1.667 1.667 0 0 0 0 3.333c2.768 0 3.163-7.53 3.805-9a1.667 1.667 0 1 1 2.207 2.19M2 14h12"
      stroke="#06253F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
