<template>
  <nav
    v-if="authStore.isAuthenticatedNavigation"
    class="flex h-14 flex-row items-center justify-between gap-4 bg-primary-6 pr-5 sm:gap-8 max-w-[100vw]"
    :class="searchStore.isSearchFocused ? 'lg:pl-0' : ''"
  >
    <div class="gap-4 transition-opacity duration-300 flex">
      <!-- MenuBtn + PHX LOGO -->
      <div
        class="flex min-w-14 items-center justify-center transition-opacity duration-300"
        :class="
          searchStore.isSearchFocused
            ? 'invisible hidden opacity-0 lg:visible lg:flex lg:opacity-100'
            : 'visible flex opacity-100'
        "
      >
        <button
          id="menu-btn"
          @click.stop="UIStore.isSideNavigationOpen = !UIStore.isSideNavigationOpen"
        >
          <IconHamburgerMenu class="fill-white" />
        </button>
      </div>
      <RouterLink
        to="/"
        class="transition-opacity duration-300"
        :class="
          searchStore.isSearchFocused
            ? 'invisible hidden opacity-0 lg:visible lg:flex lg:opacity-100'
            : 'visible flex opacity-100'
        "
      >
        <div class="w-48 min-w-48">
          <img :src="phxLogo" alt="" class="object-contain" />
        </div>
      </RouterLink>
    </div>
    <!-- Search Overlay -->
    <div
      as="template"
      class="fixed left-0 top-0 z-50 hidden h-full w-full bg-neutral-gray-13 bg-opacity-[.65] transition-all duration-300 sm:block"
      :class="searchStore.isSearchFocused ? 'visible opacity-100' : 'invisible opacity-0'"
      @click="searchStore.isSearchFocused = false"
    />
    <!-- Search Bar -->
    <MainSearchBar />
    <!-- CTA Section -->
    <div
      class="items-center justify-end gap-2 transition-opacity duration-300"
      :class="
        searchStore.isSearchFocused
          ? 'invisible hidden opacity-0 lg:visible lg:flex lg:opacity-100'
          : 'visible flex opacity-100'
      "
    >
      <button
        @click="searchStore.isSearchFocused = true"
        type="button"
        class="flex h-8 w-8 items-center justify-center rounded-full bg-primary-5 sm:hidden"
      >
        <IconSearch class="fill-white" />
      </button>
      <Menu :actions="actionsList">
        <template #button-content>
          <div
            class="flex items-center justify-center rounded-full h-7 w-7 bg-primary-5 cursor-pointer"
          >
            <Icon name="User" class="h-5 w-5 text-white" strokeWidth="2" />
          </div>
        </template>
      </Menu>
    </div>
  </nav>
  <nav
    v-else
    class="flex h-14 flex-row w-full lg:justify-start justify-center items-center bg-primary-6 px-4"
  >
    <RouterLink to="/">
      <img :src="phxLogo" alt="" class="h-[34.6px] object-contain min-w-full" />
    </RouterLink>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import phxLogo from "@/assets/images/PHX_Revised_Logo.png";
import { MainSearchBar, Menu, Icon } from "@/components";
import { IconHamburgerMenu, IconSearch } from "@/components/icons";
import { useUIStore, useSearchStore, useAuthStore } from "@/stores";

const router = useRouter();
const searchStore = useSearchStore();
const UIStore = useUIStore();
const authStore = useAuthStore();

const actionsList = ref([
  [
    {
      label: "Logout",
      onClick: () => {
        handleSignOut();
      },
    },
  ],
]);

async function handleSignOut() {
  await authStore.signOut();
  router.push("/sign-in");
}
</script>
