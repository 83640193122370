<template>
  <div v-if="is_csv_generating">
    <LoadingSpinner :show="is_csv_generating" />
  </div>
  <div v-if="!page_is_loading" class="px-0 sm:px-10 max-w-[100vw] py-5">
    <AllRecordsTable
      :fields-with-custom-styles="['status']"
      :customData="{ header_name: 'Marketing', icon_name: 'Megaphone', bg_color: 'bg-cPurple' }"
      hide_checkboxes_on_sort
      :header-seperator="false"
    >
      <template v-slot:additional-ctas>
        <div class="flex flex-row items-center ml-3">
          <Button
            variant="default-gray-outlined"
            label
            icon
            is-icon-only-on-mobile
            @click="handleGenerateMarketingCSV"
          >
            <template #icon> <Icon class="w-4 h-4 mb-0.5" name="Download" /> </template>
            <template #label> Download </template>
          </Button>
        </div>
      </template>
      <template v-slot:status="data">
        <div class="ml-4 truncate">
          <div
            class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
            :class="
              data.row.row.status?.value == 'Created'
                ? 'bg-custom-light-blue text-custom-light-blue'
                : data.row.row.status?.value == 'Settled'
                  ? 'bg-custom-lime-green text-custom-lime-green'
                  : data.row.row.status?.value === 'Canceled'
                    ? 'bg-functional-error-default text-functional-error-hover'
                    : data.row.row.status?.value == 'Title Transferred'
                      ? 'bg-custom-teal text-custom-teal'
                      : data.row.row.status?.value == 'Funded'
                        ? 'bg-functional-warning-default text-functional-warning-hover'
                        : 'bg-neutral-gray-6 text-neutral-gray-6'
            "
          >
            {{ data.row.row.status?.value }}
          </div>
        </div>
      </template>
    </AllRecordsTable>
  </div>
  <div v-else-if="page_is_loading">
    <LoadingSpinner :show="page_is_loading" :hide-overlay="true" />
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";

import { useRecordDetailStore, useUIStore, useCrudStore } from "@/stores";
import { Button, LoadingSpinner, AllRecordsTable, Icon } from "@/components";
import { IconCopyAll, IconCircleCheck } from "@/components/icons";
import { sanitizeJSONAndDownloadCSV } from "@/utilities";

const recordDetailStore = useRecordDetailStore();
const UIStore = useUIStore();
const crudStore = useCrudStore();

const page_is_loading = ref(false);
const is_csv_generating = ref(false);

onMounted(() => onMountedFunction());

watch(
  () => recordDetailStore.allRecordsPageNumber,
  async () => {
    await recordDetailStore.fetchTableRecords(false, true);
  }
);

async function onMountedFunction() {
  UIStore.sortHeader = { field_name: "signature_date" };
  UIStore.sortAscending = false;
  page_is_loading.value = true;
  recordDetailStore.currentPageCollection = "Investments";
  recordDetailStore.filterPipeline = null;
  recordDetailStore.defaultFilterPipeline = null;

  await recordDetailStore.setCurrentPageConfig();
  await recordDetailStore.getPossibleColumns("Investments");
  await recordDetailStore.fetchTableRecords(false, true);
  page_is_loading.value = false;
}

async function handleGenerateMarketingCSV() {
  is_csv_generating.value = true;
  const headers = {
    signature_date: "Signature Date",
    _id: "Investment ID",
    contact_id: "Contact ID",
    type: "Type",
    offering_type: "Offering Type",
    offering_name: "Offering Series",
    offering_rate: "Offering Rate",
    offering_term: "Offering Term",
    amount: "Amount",
    status: "Status",
    payment_method: "Payment Method",
    first_payment_date: "First Payment Date",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone: "Phone",
    city: "City",
    state: "State",
    zip_code: "Zip Code",
    date_of_birth: "Date of Birth",
    created_date: "Created Date",
  };
  const now = new Date();
  const matchStage = {
    $match: { ...recordDetailStore.filterPipeline, ...recordDetailStore.defaultFilterPipeline },
  };
  let pipeline = [];

  if (UIStore.sortHeader) {
    pipeline.unshift({
      $sort: { [UIStore.sortHeader.field_name]: UIStore.sortAscending ? 1 : -1 },
    });
  }

  if (
    recordDetailStore.filterPipeline !== null ||
    recordDetailStore.defaultFilterPipeline !== null
  ) {
    pipeline.unshift(matchStage);
  }

  let lookupStage = [
    {
      $lookup: {
        from: "Offerings",
        localField: "offering_id",
        foreignField: "_id",
        as: "offering",
      },
    },
    { $unwind: "$offering" },
    {
      $lookup: {
        from: "Contacts",
        localField: "contact_id",
        foreignField: "_id",
        as: "contact",
      },
    },
    { $unwind: "$contact" },
    {
      $addFields: {
        first_name: "$contact.first_name",
        last_name: "$contact.last_name",
        email: "$contact.email",
        phone: "$contact.phone",
        city: "$contact.city",
        state: "$contact.state",
        zip_code: "$contact.zip_code",
        date_of_birth: "$contact.date_of_birth",
        offering_term: "$offering.bond_term",
        offering_rate: "$offering.interest_rate",
      },
    },
  ];
  pipeline.push(...lookupStage);

  const fetchedRecords = await crudStore.aggregate("Investments", pipeline);
  const formattedData = formatDataForCSV(fetchedRecords);
  const fileName = `Marketing_Report_${now.toLocaleDateString("en-US", {
    timeZone: "UTC",
  })}.csv`;
  sanitizeJSONAndDownloadCSV(headers, formattedData, fileName);
  is_csv_generating.value = false;
}

function formatDataForCSV(data) {
  return data.map((row) => {
    const formattedRow = { ...row };

    formattedRow.signature_date = row.signature_date
      ? new Date(row?.signature_date)?.toLocaleDateString("en-US", {
          timeZone: "UTC",
        })
      : "N/A";
    formattedRow.investment_id = row.investment_id || "N/A";
    formattedRow.contact_id = row.contact_id || "N/A";
    formattedRow.type = row.type || "N/A";
    formattedRow.offering_type = row.offering_type || "N/A";
    formattedRow.offering_series = row.offering_series || "N/A";
    formattedRow.offering_rate = `${row.offering_rate}%` || "N/A";
    formattedRow.offering_term =
      row.offering_term < 1
        ? `${row.offering_term * 12} Month${row.offering_term * 12 > 1 ? "s" : ""}`
        : `${row.offering_term} Year${row.offering_term > 1 ? "s" : ""}` || "N/A";
    formattedRow.amount = row.amount || "N/A";
    formattedRow.status = row.status || "N/A";
    formattedRow.payment_method = row.payment_method || "N/A";
    formattedRow.first_payment_date = row.first_payment_date
      ? new Date(row.first_payment_date)?.toLocaleDateString("en-US", { timeZone: "UTC" })
      : "N/A";
    formattedRow.first_name = row.first_name || "N/A";
    formattedRow.last_name = row.last_name || "N/A";
    formattedRow.email = row.email || "N/A";
    formattedRow.phone = row.phone || "N/A";
    formattedRow.city = row.city || "N/A";
    formattedRow.state = row.state || "N/A";
    formattedRow.zip_code = row.zip_code || "N/A";
    formattedRow.date_of_birth = row.date_of_birth
      ? new Date(row.date_of_birth)?.toLocaleDateString("en-US", { timeZone: "UTC" })
      : "N/A";
    formattedRow.created_date = row.created_date
      ? new Date(row.created_date)?.toLocaleDateString("en-US", { timeZone: "UTC" })
      : "N/A";

    return formattedRow;
  });
}
</script>
