<template>
  <div v-if="isLoading">
    <LoadingSpinner :show="isLoading" message="Loading..." />
  </div>
  <AddToBatchModal :open="isAddToBatchModalOpen" @close-modal="isAddToBatchModalOpen = false" />
  <ConfirmDeleteModal
    :open="isShowConfirmDeleteModal"
    @close-modal="isShowConfirmDeleteModal = false"
    @confirm-delete="handleRemoveFromBatch"
    :message="`Are you sure you want to delete this investment from the ACH batch?`"
    :subText="`Investment ID: ${selectedInvestmentForRemoval}`"
  />
  <div class="px-4 md:px-6 lg:px-10 py-6 flex flex-col h-full">
    <div class="flex flex-col md:flex-row gap-2 justify-between">
      <div class="flex items-center gap-2">
        <div
          class="flex h-10 w-10 min-h-10 min-w-10 items-center justify-center rounded-lg bg-opacity-20 bg-[#469345]"
        >
          <div class="flex h-8 w-8 items-center justify-center rounded-md bg-[#469345]">
            <Icon name="Banknote" :stroke-width="2" class="h-6 w-6 text-neutral-gray-1" />
          </div>
        </div>
        <p class="text-2xl font-bold uppercase whitespace-nowrap">Funding & Payouts</p>
      </div>
      <div class="flex gap-2 mt-6 md:mt-0">
        <Menu :actions="actionsList">
          <template #button-content>
            <div
              class="h-9 rounded gap-1 text-xs flex items-center justify-center transition whitespace-nowrap border border-neutral-gray-4 bg-white hover:bg-neutral-gray-4 active:bg-neutral-gray-5 text-primary box-border w-48 md:w-40 cursor-pointer"
            >
              <Icon name="ChevronDown" class="h-4 w-4 mr-1" :stroke-width="2" />
              <p class="text-sm">Download .CSV</p>
            </div>
          </template>
        </Menu>
        <Button @click="isAddToBatchModalOpen = true" label icon class="!text-sm">
          <template #icon>
            <Icon name="Plus" class="h-4 w-4" :stroke-width="2" />
          </template>
          <template #label>Add to Batch</template>
        </Button>
      </div>
    </div>
    <div
      class="hidden sm:flex flex-col lg:flex-row justify-between items-end mt-6 w-full gap-4 lg:gap-10"
    >
      <div class="flex flex-col md:flex-row items-end gap-4 w-full xl:w-1/2">
        <div class="relative w-full">
          <BaseInput
            v-model="filters.ach_batch_date"
            placeholder="Select ACH Batch Date"
            label="ACH Batch Date"
            size="large"
            type="date"
          />
          <p
            v-if="achBatchStore?.currentAchBatch?.status"
            class="text-sm text-lighter absolute top-0 right-0"
          >
            ({{ achBatchStore.currentAchBatch?.status }})
          </p>
        </div>
        <BaseInput
          v-model="filters.investment_id"
          placeholder="Investment ID"
          label="Investment ID"
          size="large"
          type="text"
        />
        <BaseInput
          v-model="filters.contact_id"
          placeholder="Contact ID"
          label="Contact ID"
          size="large"
          type="text"
        />
      </div>

      <div class="flex gap-2">
        <Button
          label
          @click="clearFilters"
          variant="default-gray-outlined"
          class="w-full md:w-auto"
          size="large"
        >
          <template #label>Clear</template>
        </Button>
        <Button @click="fetchACHBatches" label class="w-full md:w-auto" size="large">
          <template #label>Apply</template>
        </Button>
      </div>
    </div>
    <!-- Mobile -->
    <div class="flex flex-col sm:hidden border-platform-outlines border rounded-xl mt-4">
      <div class="w-full flex justify-between p-4 items-center">
        <span class="font-bold">Filter</span>
        <div
          class="border-platform-outlines border rounded-lg p-1.5 cursor-pointer"
          @click="filterDropOpen = !filterDropOpen"
        >
          <Icon :name="filterDropOpen ? 'ChevronUp' : 'ChevronDown'" class="w-4 h-4" />
        </div>
      </div>
      <div
        class="transition-all duration-300 overflow-hidden"
        :class="filterDropOpen ? 'max-h-screen' : 'max-h-0'"
      >
        <div
          class="flex flex-col md:flex-row flex-wrap items-end gap-4 w-full py-6 px-4 grow bg-neutral-gray-3"
        >
          <div class="relative w-full">
            <BaseInput
              v-model="filters.ach_batch_date"
              placeholder="Select ACH Batch Date"
              label="ACH Batch Date"
              size="large"
              type="date"
            />
            <p
              v-if="achBatchStore?.currentAchBatch?.status"
              class="text-sm text-lighter absolute top-0 right-0"
            >
              ({{ achBatchStore.currentAchBatch?.status }})
            </p>
          </div>
          <BaseInput
            v-model="filters.investment_id"
            placeholder="Investment ID"
            label="Investment ID"
            size="large"
            type="text"
          />
          <BaseInput
            v-model="filters.contact_id"
            placeholder="Contact ID"
            label="Contact ID"
            size="large"
            type="text"
          />
        </div>
        <div class="flex flex-row items-center gap-4 p-4 justify-center">
          <Button
            class="w-full"
            variant="default-gray-outlined"
            :label="true"
            size="large"
            @click="clearFilters()"
          >
            <template #label> Clear </template>
          </Button>

          <Button class="w-full" :label="true" size="large" @click="applyFilters()">
            <template #label> Apply </template>
          </Button>
        </div>
      </div>
    </div>
    <div class="border-b border-platform-outlines w-full my-6"></div>
    <div v-if="paginatedBatchData.length" class="flex flex-col justify-between grow">
      <div>
        <Table
          @setSortHeader="(header, props) => setSortHeader(header, props)"
          :headers="achBatchTableHeaders"
          :tableData="UIStore.transformRecords(paginatedBatchData)"
          actions_always_sticky
          class="max-w-full"
          use_table_loader
          isResizable
        >
          <template v-slot:table-ctas="entry">
            <div class="flex items-center space-x-2">
              <Button
                @click="
                  (isShowConfirmDeleteModal = true),
                    (selectedInvestmentForRemoval = entry.entry.investment_id.value)
                "
                icon
                size="small"
                variant="subtle"
              >
                <template #icon>
                  <Icon
                    name="Trash"
                    class="h-4 w-4 text-functional-error-default"
                    :strokeWidth="2.5"
                  />
                </template>
              </Button>
            </div>
          </template>
        </Table>
      </div>
      <Pagination
        v-if="paginatedBatchData.length > 0"
        show-count
        :total-category-results="totalCategoryResults"
        :page-size="pageSize"
        :page-num="currentPage"
        @change-page="currentPage = $event"
        class="mt-8"
      />
    </div>
    <div
      v-else-if="!achBatchStore?.achBatchData?.length && !UIStore.isLoading"
      class="flex flex-col gap-2 items-center justify-center mt-8"
    >
      <EmptyState description="No ACH Batch Data To Display At This Time." icon_name="Banknote" />
      <Button @click="isAddToBatchModalOpen = true" label icon>
        <template #icon>
          <Icon name="Plus" class="h-4 w-4" :stroke-width="2" />
        </template>
        <template #label>Add to Batch</template>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from "vue";
import { ObjectId } from "bson";

import {
  Icon,
  Button,
  Menu,
  BaseInput,
  Pagination,
  Table,
  ConfirmDeleteModal,
  LoadingSpinner,
  EmptyState,
} from "@/components";
import { useACHBatchStore, useUIStore, useCrudStore, useSchemaStore } from "@/stores";
import { sanitizeJSONAndDownloadCSV } from "@/utilities";
import AddToBatchModal from "./AddToBatchModal.vue";

const achBatchStore = useACHBatchStore();
const UIStore = useUIStore();
const crudStore = useCrudStore();
const schemaStore = useSchemaStore();

const filters = reactive({
  ach_batch_date: new Date().toISOString().split("T")[0],
  investment_id: "",
  contact_id: "",
});

const isAddToBatchModalOpen = ref(false);
const isShowConfirmDeleteModal = ref(false);
const selectedInvestmentForRemoval = ref(null);
const currentPage = ref(1);
const pageSize = ref(10);
const actionsList = computed(() => [
  [
    {
      label: "ACH Batches",
      icon: "Download",
      onClick: () => {
        handleDownloadACHBatchesCSV();
      },
      disabled: !achBatchStore.achBatchData || !achBatchStore.achBatchData.length,
    },
    {
      label: "Distribution Info",
      icon: "Download",
      onClick: () => {
        handleGenerateDistributionData();
      },
    },
  ],
]);

const achBatchTableHeaders = ref([
  { field_name: "name", label: "Investor" },
  {
    field_name: "contact_id",
    label: "Contact ID",
    has_copy: true,
    associated_collection: "Contacts",
  },
  {
    field_name: "investment_id",
    label: "Investment ID",
    has_copy: true,
    associated_collection: "Investments",
  },

  { field_name: "amount", label: "Amount to Pull" },
  { field_name: "account_type", label: "Checking" },
  { field_name: "investment_type", label: "Type" },
  { field_name: "offering_type", label: "Offering" },
  { field_name: "signature_date", label: "Signature Date", field_type: "date" },
  { field_name: "state", label: "State" },
  { field_name: "actions", label: "Actions" },
]);

const copiedStatus = reactive({});
const filteredBatchData = ref([]);
const isLoading = ref(false);
const filterDropOpen = ref(false);

onMounted(async () => {
  UIStore.isLoading = true;
  await fetchACHBatches();
  if (schemaStore.all_contact_schema.length === 0) {
    await schemaStore.setSchema();
  }
  UIStore.isLoading = false;
});

const paginatedBatchData = computed(() => {
  if (!achBatchStore.achBatchData || !achBatchStore.achBatchData.length) return [];

  let data = achBatchStore.achBatchData;

  if (filters.investment_id && isValidObjectId(filters.investment_id)) {
    data = data.filter((batch) => batch.investment_id.toString() === filters.investment_id);
  }

  if (filters.contact_id && isValidObjectId(filters.contact_id)) {
    data = data.filter((batch) => batch.contact_id.toString() === filters.contact_id);
  }

  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;

  return data.slice(start, end);
});

const totalCategoryResults = computed(() => {
  if (!achBatchStore.achBatchData || !achBatchStore.achBatchData.length) return 0;

  let data = achBatchStore.achBatchData;

  if (filters.investment_id && isValidObjectId(filters.investment_id)) {
    data = data.filter((batch) => batch.investment_id.toString() === filters.investment_id);
  }

  if (filters.contact_id && isValidObjectId(filters.contact_id)) {
    data = data.filter((batch) => batch.contact_id.toString() === filters.contact_id);
  }

  return data.length;
});

// watch(
//   () => filters.ach_batch_date,
//   async (newDate) => {
//     if (newDate) {
//       currentPage.value = 1;
//       await fetchACHBatches(newDate);
//     }
//   }
// );

watch(
  () => [filters.investment_id, filters.contact_id],
  () => {
    applyFilters();
  }
);

async function applyFilters() {
  if (!achBatchStore.achBatchData) return;
  const { investment_id, contact_id } = filters;
  let data = [...achBatchStore.achBatchData];

  if (isValidObjectId(investment_id)) {
    data = data.filter((batch) => batch.investment_id.toString() === investment_id);
  }

  if (isValidObjectId(contact_id)) {
    data = data.filter((batch) => batch.contact_id.toString() === contact_id);
  }

  filteredBatchData.value = data;
}

function clearFilters() {
  filters.investment_id = "";
  filters.contact_id = "";
}

async function handleRemoveFromBatch() {
  isShowConfirmDeleteModal.value = false;
  achBatchStore.achBatchData = achBatchStore.achBatchData.filter(
    (batch) => batch.investment_id !== selectedInvestmentForRemoval.value
  );
  await achBatchStore.removeInvestmentFromBatch(selectedInvestmentForRemoval.value);
}

async function fetchACHBatches() {
  currentPage.value = 1;
  await achBatchStore.fetchACHBatches(filters.ach_batch_date);
  applyFilters();
}

async function handleDownloadACHBatchesCSV() {
  const headers = {
    name: "Name",
    routing_number: "Routing Number",
    account_number: "Account Number",
    account_type: "Account Type",
    investment_type: "Investment Type",
    offering_type: "Offering",
    amount: "Amount",
    is_discounted_bond: "Discounted?",
    state: "State",
    investment_id: "Investment ID",
    contact_id: "Investment ID",
    signature_date: "Signature Date",
  };
  const formatedBatchData = formatDataForCSV(achBatchStore.achBatchData, "ach-batch");
  const fileName = `ACH_Batches_${filters.ach_batch_date.replace(/-/g, "_")}`;
  sanitizeJSONAndDownloadCSV(headers, formatedBatchData, fileName);
}

async function handleGenerateDistributionData() {
  isLoading.value = true;
  // const doesDistributionDataExist = await crudStore.findOne("DistributionData", {
  //   _id: { $exists: true },
  // });
  // if (doesDistributionDataExist) {
  //   const pipeline = [{ $match: { _id: { $exists: true } } }, { $sort: { signature_date: -1 } }];
  //   const distributionData = await crudStore.aggregate("DistributionData", pipeline);
  //   console.log(distributionData, "distributionData");
  //   if (distributionData && distributionData.length) {
  //     handleDownloadDistributionCSV(distributionData);
  //   }
  //   isLoading.value = false;
  //   return;
  // }

  await achBatchStore.generateDistributionData();
  const interval = setInterval(async () => {
    const result = await crudStore.find("Messages", {
      mongodb_record_type: "Investment",
      message: { $ne: "Stop Processing" },
    });
    if (result.length === 0) {
      isLoading.value = false;
      clearInterval(interval);
      const pipeline = [{ $match: { _id: { $exists: true } } }, { $sort: { signature_date: -1 } }];
      const distributionData = await crudStore.aggregate("DistributionData", pipeline);
      console.log(distributionData, "distributionData");
      if (distributionData && distributionData.length) {
        handleDownloadDistributionCSV(distributionData);
      }
    }
  }, 1000);
}

async function handleDownloadDistributionCSV(distributionData) {
  const headers = {
    full_name: "Name",
    contact_id: "Contact ID",
    investment_id: "Investment ID",
    investment_type: "Investment Type",
    joint_full_name: "Joint Name",
    entity_name: "Entity Name",
    routing_number: "Routing #",
    account_number: "Account #",
    distribution_account_type: "Distribution Account Type",
    distribution_account_created_date: "Distribution Account Created Date",
    is_pay_to_check: "Is Pay To Check",
    offering_name: "Offering Name",
    offering_term: "Offering Term",
    offering_rate: "Offering Rate",
    inception_to_date_interest_paid: "Inception to Date Paid",
    first_payment_date: "First Payment Date",
    maturity_date: "Maturity Date",
    most_recent_distribution_amount: "Most Recent Distribution Amount",
    most_recent_distribution_date: "Most Recent Distribution Date",
    upcoming_distribution_interest_amount: "Upcoming Distribution Interest Amount",
    upcoming_distribution_principal_amount: "Upcoming Distribution Principal Amount",
    investment_amount: "Investment Amount",
    is_maturity_rollover: "Is Maturity Rollover",
    upcoming_distribution_date: "Upcoming Distribution Date",
    financial_insitution_name: "Financial Institution Name",
    custodian: "Custodian",
    custodian_account_number: "Custodian Account Number",
    street1: "Street 1",
    street2: "Street 2",
    city: "City",
    state: "State",
    zip_code: "Zip",
  };

  const formatedDistributionData = formatDataForCSV(distributionData, "distribution-data");
  const fileName = `Distribution_Data_${new Date()
    .toLocaleDateString("en-US", {
      timeZone: "UTC",
    })
    .replace(/\//g, "_")}`;

  sanitizeJSONAndDownloadCSV(headers, formatedDistributionData, fileName);
}

async function handleCopy(text, rowId, type) {
  try {
    await navigator.clipboard.writeText(text);
    if (!copiedStatus[rowId]) {
      copiedStatus[rowId] = {
        contactIdCopied: false,
        investmentIdCopied: false,
        timeout: null,
      };
    }

    // Reset copied status for all rows before setting for the current row
    Object.keys(copiedStatus).forEach((key) => {
      if (key !== rowId) {
        clearTimeout(copiedStatus[key].timeout);
        copiedStatus[key].contactIdCopied = false;
        copiedStatus[key].investmentIdCopied = false;
      }
    });

    // Set copied state for the current row and type
    if (type === "contactIdCopied") {
      copiedStatus[rowId].contactIdCopied = true;
    } else if (type === "investmentIdCopied") {
      copiedStatus[rowId].investmentIdCopied = true;
    }

    // Clear any existing timeout and set a new one
    clearTimeout(copiedStatus[rowId].timeout);
    copiedStatus[rowId].timeout = setTimeout(() => {
      copiedStatus[rowId].contactIdCopied = false;
      copiedStatus[rowId].investmentIdCopied = false;
    }, 3000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}

function isValidObjectId(value) {
  if (value instanceof ObjectId) {
    return true;
  }
  return typeof value === "string" && value.length === 24 && /^[0-9a-fA-F]{24}$/.test(value);
}

function formatDataForCSV(data, type) {
  if (type === "ach-batch") {
    return data.map((row) => {
      const formattedRow = { ...row };

      formattedRow.routing_number = row.routing_number ? `_${atob(row.routing_number)}` : "N/A";
      formattedRow.account_number = row.account_number ? `_${atob(row.account_number)}` : "N/A";
      formattedRow.account_type = row.account_type ? row.account_type : "N/A";
      formattedRow.signature_date = new Date(row.signature_date).toLocaleDateString("en-US", {
        timeZone: "UTC",
      });
      formattedRow.is_discounted_bond = row.is_discounted_bond ? "Yes" : "No";

      return formattedRow;
    });
  } else if (type === "distribution-data") {
    return data.map((row) => {
      const formattedRow = { ...row };

      formattedRow.joint_full_name = row.joint_full_name ? row.joint_full_name : "N/A";
      formattedRow.entity_name = row.entity_name ? row.entity_name : "N/A";
      formattedRow.distribution_account_type =
        row.distribution_account_type || "No Active Distribution Account";
      formattedRow.distribution_account_created_date = row.distribution_account_created_date
        ? new Date(row?.distribution_account_created_date)?.toLocaleDateString("en-US", {
            timeZone: "UTC",
          })
        : "N/A";
      formattedRow.is_pay_to_check = row.is_pay_to_check ? "TRUE" : "FALSE";
      formattedRow.offering_name = row.offering_name || "";
      formattedRow.offering_term = row.offering_term || "";
      formattedRow.offering_rate = row.offering_rate || "";
      formattedRow.first_payment_date = row.first_payment_date
        ? new Date(row.first_payment_date)?.toLocaleDateString("en-US", { timeZone: "UTC" })
        : "N/A";
      formattedRow.maturity_date = row.maturity_date
        ? new Date(row.maturity_date)?.toLocaleDateString("en-US", { timeZone: "UTC" })
        : "N/A";
      formattedRow.most_recent_distribution_date = row.most_recent_distribution_date
        ? new Date(row.most_recent_distribution_date)?.toLocaleDateString("en-US", {
            timeZone: "UTC",
          })
        : "N/A";
      formattedRow.most_recent_distribution_amount = typeof row.most_recent_distribution_amount
        ? row.most_recent_distribution_amount
        : "N/A";
      formattedRow.upcoming_distribution_date = row.upcoming_distribution_date
        ? new Date(row.upcoming_distribution_date)?.toLocaleDateString("en-US", { timeZone: "UTC" })
        : "N/A";
      formattedRow.upcoming_distribution_interest_amount = row.upcoming_distribution_interest_amount
        ? row.upcoming_distribution_interest_amount
        : "N/A";
      formattedRow.upcoming_distribution_principal_amount =
        row.upcoming_distribution_principal_amount
          ? row.upcoming_distribution_principal_amount
          : "N/A";
      formattedRow.investment_amount = row.investment_amount ? row.investment_amount : "N/A";
      formattedRow.is_maturity_rollover = row.is_maturity_rollover ? "TRUE" : "FALSE";
      formattedRow.financial_insitution_name = row.financial_insitution_name || "N/A";
      formattedRow.custodian = row.custodian || "N/A";
      formattedRow.custodian_account_number = row.custodian_account_number || "N/A";
      formattedRow.street1 = row.street1 || "N/A";
      formattedRow.street2 = row.street2 || "N/A";
      formattedRow.city = row.city || "N/A";
      formattedRow.state = row.state || "N/A";
      formattedRow.zip_code = row.zip_code || "N/A";
      formattedRow.routing_number = row.routing_number ? `_${atob(row.routing_number)}` : "";
      formattedRow.account_number = row.account_number ? `_${atob(row.account_number)}` : "";
      formattedRow.signature_date = new Date(row.signature_date).toLocaleDateString("en-US", {
        timeZone: "UTC",
      });
      formattedRow.is_discounted_bond = row.is_discounted_bond ? "TRUE" : "FALSE";

      return formattedRow;
    });
  }
}
</script>
