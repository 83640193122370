<template>
  <div
    :class="[
      UIStore.isLoading ? 'block' : '',
      rounded_bottom ? 'rounded-b-lg' : '',
      extendAbove ? 'mt-[-16rem] pt-64' : '',
    ]"
    class="overflow-x-auto"
    id="table-container"
    ref="scrollContainer"
    @scroll="handleScroll"
  >
    <table
      v-if="!UIStore.isLoading"
      class="relative left-0 min-w-full overflow-auto"
      :class="[rounded_bottom ? 'rounded-b-lg' : '']"
      :id="'table' + tableContext"
    >
      <thead
        id="table-headers"
        class="sticky top-0 z-10 overflow-auto whitespace-nowrap bg-neutral-gray-2"
      >
        <tr class="divide-x">
          <th
            v-for="(header, index) of headers"
            :key="header"
            scope="col"
            :id="'header' + index + tableContext"
            ref="tableHeaders"
            class="sticky top-0 min-h-9 border-b border-neutral-gray-4 bg-neutral-gray-2 px-4 py-2.5"
            :class="[
              actions_always_sticky ? 'last:sticky last:right-0' : '',
              hide_id && header.label === 'ID' ? 'hidden' : '',
              !hideTopBorder ? 'border-t' : '',
              isResizable && header.label !== 'Actions' && header.label !== 'Agreement'
                ? 'resize-handle border-l'
                : '',
              resizingColumnIndex === index - 1 ? '' : 'grow-0',
            ]"
            style="width: 200"
            @mousedown="initResize($event, index - 1)"
          >
            <label
              v-if="header?.field_name === 'checkbox'"
              for="select-all"
              class="flex items-center gap-2"
            >
              <input
                v-if="!selectAllIsDisabled"
                type="checkbox"
                @change="$emit('selectAll')"
                :checked="isAllChecked"
                id="select-all"
                class="accent-secondary-6"
              />
              <span class="text-left text-xxs font-semibold text-primary-1 pt-0.5">
                {{ header.label.toUpperCase() }}
              </span>
            </label>

            <button
              v-else-if="header.label !== 'Actions' && header.label !== 'Agreement' && !hideSort"
              @click="$emit('setSortHeader', header, props)"
              class="group flex items-center space-x-2"
            >
              <div class="text-left text-xxs font-semibold text-primary-1">
                {{ header.label.toUpperCase() }}
              </div>
              <span
                :class="
                  JSON.stringify(UIStore.sortHeader?.label) == JSON.stringify(header.label)
                    ? 'visible'
                    : 'invisible'
                "
                class="group-hover:visible group-focus:visible"
              >
                <IconArrowUpward
                  v-if="
                    UIStore.sortAscending &&
                    JSON.stringify(UIStore.sortHeader?.label) == JSON.stringify(header.label)
                  "
                  class="pointer-events-none h-4 w-4 fill-lighter group-hover:visible group-focus:visible"
                />
                <IconArrowUpward
                  v-else
                  class="pointer-events-none h-4 w-4 rotate-180 fill-lighter group-hover:visible group-focus:visible"
                />
              </span>
            </button>
            <div v-else class="text-left text-xxs font-semibold text-primary-1">
              {{ header.label.toUpperCase() }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody
        v-if="!UIStore.isLoading"
        class="relative left-0 divide-y divide-gray-200 overflow-visible bg-white"
      >
        <tr
          v-for="(entry, index) in tableData"
          :key="entry"
          class="relative overflow-visible even:border-b even:border-t even:border-neutral-gray-2 even:bg-neutral-gray-2"
        >
          <td
            v-for="header in headers"
            :key="header"
            class="relative overflow-visible whitespace-nowrap text-sm font-medium"
            :class="[
              hide_id && header.field_name === '_id' ? 'hidden' : '',
              actions_always_sticky ? 'last:sticky last:right-0 last:p-0' : 'truncate',
              header.label === 'Actions' || header.label === 'Agreement' ? 'w-10' : 'max-w-80',
            ]"
          >
            <div>
              <template v-if="isCustomStyled(header)">
                <div>
                  <slot :name="header.field_name" :row="entry"></slot>
                </div>
              </template>
              <div
                v-else-if="
                  (entry[header.field_name]?.value || entry[header.field_name]?.value == 0) &&
                  header.label !== 'Actions' &&
                  header.label !== 'Agreement'
                "
                @mouseover="
                  mouseEnter(
                    index + ' ' + entry[header.field_name]?.value + header.field_name,
                    $event
                  )
                "
                @mouseleave="mouseLeave()"
                class="relative left-0 overflow-visible p-4"
                :class="[
                  entry[header.field_name].nullValue
                    ? 'text-primary-1'
                    : entry[header.field_name].clickable
                      ? 'text-gold'
                      : 'text-dark',
                  whitespace_preline ? 'whitespace-pre-line' : 'truncate',
                ]"
              >
                <div class="flex" v-if="!header.has_copy">
                  {{
                    entry[header.field_name].nullValue
                      ? entry[header.field_name].nullValue
                      : header.field_type === "date"
                        ? formatDateValue(entry, header)
                        : header.number_type === "currency" &&
                            typeof entry[header.field_name].value === "number"
                          ? "$" + commaSeparateThousands(entry[header.field_name].value.toFixed(2))
                          : header.number_type === "percentage" &&
                              typeof entry[header.field_name].value === "number"
                            ? commaSeparateThousands(
                                (entry[header.field_name].value * 100).toFixed(2)
                              ) + "%"
                            : typeof entry[header.field_name].value === "number" &&
                                (header.field_name == "cum_oil_phx" || header.field_name == "nma")
                              ? commaSeparateThousands(entry[header.field_name].value.toFixed(2))
                              : typeof entry[header.field_name].value === "number"
                                ? commaSeparateThousands(entry[header.field_name].value)
                                : entry[header.field_name].value === ""
                                  ? "-"
                                  : header.field_name === "notes" ||
                                      header.field_name === "title_notes"
                                    ? stripHTML(entry[header.field_name].value)
                                    : entry[header.field_name].value
                  }}
                </div>

                <div
                  v-if="header.has_copy"
                  class="truncate text-gold flex justify-between max-w-50 min-w-50"
                >
                  <router-link
                    :to="`${schemaStore.route_map[header.associated_collection]}${entry[header.field_name].value}`"
                    class="text-gold"
                  >
                    {{ entry[header.field_name].value }}
                  </router-link>
                  <div
                    class="flex gap-2 items-center"
                    @click="
                      handleCopy(
                        entry[header.field_name].value,
                        `index${index}value${entry[header.field_name].value}`
                      )
                    "
                  >
                    <Icon
                      :name="
                        copy_value === `index${index}value${entry[header.field_name].value}`
                          ? 'CheckCircle'
                          : 'Copy'
                      "
                      :stroke-width="2"
                      class="cursor-pointer"
                      :class="
                        copy_value === `index${index}value${entry[header.field_name].value}`
                          ? 'text-green-500 h-4 w-4'
                          : 'text-primary-2 h-4 w-4'
                      "
                    />
                  </div>
                </div>
              </div>

              <div
                v-else-if="header.label === 'Actions' || header.label === 'Agreement'"
                class="w-full bg-white px-4 py-3"
                :class="[
                  actions_always_sticky ? 'border-l' : '',
                  UIStore.isSideDrawerOpen ? '' : '',
                ]"
              >
                <slot name="table-ctas" :entry="entry"></slot>
              </div>
              <div v-else class="p-4">
                <!-- Empty cell for missing data -->
                -
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="UIStore.isLoading && use_table_loader"
      class="flex min-h-50 items-center justify-center p-10"
    >
      <LoadingSpinner :show="UIStore.isLoading" hideOverlay position="relative" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";

import { useUIStore, useSchemaStore } from "@/stores";
import { IconArrowUpward } from "@/components/icons";
import { Button, LoadingSpinner, Icon } from "@/components";
import { commaSeparateThousands, stripHTML } from "@/utilities";
import { useRoute } from "vue-router";

const emit = defineEmits(["scroll-bottom", "selectAll", "row-clicked"]);
const UIStore = useUIStore();
const schemaStore = useSchemaStore();

const route = useRoute();

const props = defineProps({
  tableData: Array,
  headers: Array,
  parent: String,
  useCustomSort: Boolean,
  hideSort: Boolean,
  fixTable: Boolean,
  fieldsWithCustomStyles: {
    type: Array,
    default: () => [],
  },
  hide_id: {
    type: Boolean,
    default: false,
  },
  use_table_loader: {
    type: Boolean,
    default: true,
  },
  fixedColumnAmount: {
    type: Number,
    default: 6, // Provide a default value that makes sense for your application
  },
  actions_always_sticky: {
    type: Boolean,
    default: false,
  },
  rounded_bottom: {
    type: Boolean,
    default: true,
  },
  extendAbove: {
    type: Boolean,
    default: false,
  },
  hideTopBorder: {
    type: Boolean,
    default: false,
  },
  isResizable: {
    type: Boolean,
    default: false,
  },
  resetSort: {
    type: Boolean,
    default: true,
  },
  set_sort_to_null: {
    type: Boolean,
    default: true,
  },
  whitespace_preline: {
    type: Boolean,
    default: false,
  },
  headersSticky: {
    type: Boolean,
    default: false,
  },
  selectAllIsDisabled: {
    type: Boolean,
    default: false,
  },
  tableContext: {
    type: String,
    default: null,
  },
  isAllChecked: {
    type: Boolean,
    default: false,
  },
});

const scrollContainer = ref(null);
const tooltip_to_show = ref(null);
const newOffsetX = ref(0);
const tableHeaders = ref([]);

let tableWidth;
const copy_value = ref("");
async function handleCopy(text, type) {
  try {
    copy_value.value = "";
    await navigator.clipboard.writeText(text);

    copy_value.value = type;

    setTimeout(() => {
      copy_value.value = "";
    }, 3000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
}

onMounted(() => {
  if (props.resetSort) {
    if (props.set_sort_to_null) {
      UIStore.sortAscending = null;
    }
  }
  if (props.isResizable) {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  }
});

// Get table column widths from session storage
watch(
  () => tableHeaders.value.length,
  function () {
    tableWidth = Number(document.getElementById("table" + props.tableContext)?.offsetWidth);
    // persistant column resizing
    let recordId = route.query.recordId;
    if (recordId) {
      if (window.sessionStorage.getItem(recordId + "_cols")) {
        let tableColsInSessionStore = JSON.parse(window.sessionStorage.getItem(recordId + "_cols"));
        tableColsInSessionStore.forEach((col) => {
          document.getElementById("table" + props.tableContext).width =
            tableWidth + col.width + "px";
          document.getElementById(col.col_id).width = col.width + "px";
        });
      }
    }
  }
);

onUnmounted(() => {
  if (props.isResizable) {
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  }
});

let timer = 0;

function mouseEnter(value, event) {
  timer = setTimeout(() => {
    tooltip_to_show.value = value;
  }, 1000);
}

function mouseLeave() {
  tooltip_to_show.value = null;
  clearTimeout(timer);
}

function isCustomStyled(header) {
  return props.fieldsWithCustomStyles.includes(header.field_name);
}

let isResizing = ref(false);
let startX = ref(0);
let startWidth = ref(0);
let resizingColumnIndex = ref(-1);
let newWidth = ref(0);

const initResize = (event, index) => {
  isResizing.value = true;
  startX.value = event.pageX;
  startWidth.value = document.getElementById("header" + index + props.tableContext)?.offsetWidth;
  resizingColumnIndex.value = index;
  tableWidth = Number(document.getElementById("table" + props.tableContext)?.offsetWidth);
};

const onMouseMove = (event) => {
  if (isResizing.value) {
    let offsetX = event.pageX - startX.value;
    newWidth.value = startWidth.value + offsetX;
    document.getElementById("table" + props.tableContext).width = tableWidth + offsetX + "px";
    if (newWidth.value > 0) {
      document.getElementById("header" + resizingColumnIndex.value + props.tableContext).width =
        newWidth.value + "px";
    }
  }
};

const onMouseUp = () => {
  if (isResizing.value) {
    isResizing.value = false;
    let recordId = route.query.recordId;
    if (recordId) {
      let tableColsInSessionStore = [];
      // if we already have an array of col widths
      if (window.sessionStorage.getItem(recordId + "_cols")) {
        tableColsInSessionStore = JSON.parse(window.sessionStorage.getItem(recordId + "_cols"));
        // Find the index of the element in the array
        const index = tableColsInSessionStore.findIndex(
          (obj) => obj.col_id === "header" + resizingColumnIndex.value + props.tableContext
        );

        if (index !== -1) {
          // If element is found, update the specified property in the array
          tableColsInSessionStore[index].width = newWidth.value;
        } else {
          // If element is not found, push a new object to the array
          tableColsInSessionStore.push({
            col_id: "header" + resizingColumnIndex.value + props.tableContext,
            width: newWidth.value,
          });
        }
      } else {
        // we are starting new array
        tableColsInSessionStore.push({
          col_id: "header" + resizingColumnIndex.value + props.tableContext,
          width: newWidth.value,
        });
      }

      window.sessionStorage.setItem(recordId + "_cols", JSON.stringify(tableColsInSessionStore));
    }
  }
};

const handleScroll = () => {
  if (!scrollContainer.value) return;
  if (
    scrollContainer.value.scrollTop + scrollContainer.value.clientHeight >=
    scrollContainer.value.scrollHeight
  ) {
    emit("scroll-bottom");
  }
};

function formatDateValue(entry, header) {
  const fieldName = header.field_name;
  var value = entry[fieldName].value;
  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  // Check for created_date and updated_date
  if (
    fieldName === "created_date" ||
    fieldName === "updated_date" ||
    ((fieldName === "old_value" || fieldName === "new_value") &&
      (entry?.field_name?.value === "created_date" || entry?.field_name?.value === "updated_date"))
  ) {
    return value.toLocaleDateString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  } else {
    return value.toLocaleDateString("en-us", {
      timeZone: "UTC",
    });
  }
}
</script>
<style scoped>
th:after,
th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

th:before {
  top: -1px;
  border-top: 1px solid #f0f0f0;
}

.table-container {
  overflow-x: auto;
}

.gradient {
  background-color: gray;
  -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
}

.resizable-table table {
  /* Ensure the table layout is fixed for resizing to work */
  border-collapse: collapse;
}

.resize-handle {
  position: relative;
  padding-right: 16px; /* Adjust padding to accommodate resize handle */
}

.resize-handle::after {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  width: 8px; /* Width of the resize handle */
  cursor: ew-resize; /* Horizontal resize cursor */
}
</style>
