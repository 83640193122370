export function buildDataObject_InvestmentsByWeek(data) {
  let labels = data.map((entry) => {
    return getDateOfISOWeek(entry._id.week, entry._id.year);
  });
  // For tooltips
  const formatLabels = (context) => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
      context.parsed.y
    );
  };

  const formatTitles = (tooltipItem) => {
    return labels[tooltipItem[0].dataIndex];
    // return getDateOfISOWeek(
    //   labels[tooltipItem[0].dataIndex].week,
    //   labels[tooltipItem[0].dataIndex].year
    // );
  };

  function getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) {
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart.toLocaleDateString("en-US", { timeZone: "UTC" });
  }

  const investmentData = {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          data: data.map((entry) => entry.totalInvestmentAmount),
          backgroundColor: "#46934680",
          borderColor: "#469346",
          borderWidth: 1,
          pointRadius: 5,
        },
      ],
    },
    options: {
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      plugins: {
        tooltip: {
          displayColors: false,
          bodyFontSize: 16,
          bodyFontColor: "Gold",
          callbacks: {
            title: formatTitles,
            label: formatLabels,
          },
        },
        responsive: true,
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: true,
            lineWidth: 2,
          },
        },
        y: {
          grid: {
            display: true,
            callback: function (value) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format commas in number
            },
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Format commas in number
            },
          },
        },
      },
      datalabels: {
        color: "black",
        font: {
          weight: "bold",
        },
      },
    },
  };
  return investmentData;
}
