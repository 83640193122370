<template>
  <div class="text-primary-6 font-semibold pb-2">TRANSFER CHILDREN</div>

  <Table
    :tableData="recordDetailStore.fetched_transfer_children"
    :headers="schemaStore.transfer_banner_headers"
    :rounded_bottom="false"
    class="w-full overflow-auto"
    :fieldsWithCustomStyles="['status']"
  >
    <template v-slot:status="entry">
      <div class="ml-4 truncate">
        <div
          class="bg-opacity-10 rounded-xl py-1 px-2 w-fit flex items-center justify-center"
          :class="
            entry.row.status.value == 'Created'
              ? 'bg-custom-light-blue text-custom-light-blue'
              : entry.row.status.value == 'Settled'
                ? 'bg-custom-lime-green text-custom-lime-green'
                : entry.row.status.value === 'Canceled'
                  ? 'bg-functional-error-default text-functional-error-hover'
                  : 'bg-functional-warning-default text-functional-warning-hover'
          "
        >
          {{ entry.row.status.value }}
        </div>
      </div>
    </template>
  </Table>
</template>
<script setup>
import { ref } from "vue";
import { Table, Icon } from "@/components";
import { commaSeparateThousands } from "@/utilities";
import { useRecordDetailStore, useSchemaStore } from "@/stores";
const recordDetailStore = useRecordDetailStore();
const schemaStore = useSchemaStore();
</script>
