<template>
  <SideDrawer
    @close-drawer="$emit('close-drawer')"
    :open="open"
    customWidth="lg:w-3/4"
    id="quick-ref-rel-list"
    :hideHeader="true"
  >
    <template v-slot:tabs> <div class="font-semibold">Document Details</div> </template>

    <template v-slot:main-content>
      <div class="m-4 flex grow flex-col rounded-md">
        <div class="grid grid-cols-2 gap-3 rounded-lg">
          <div
            v-for="schema of recordDetailStore.file_details_schema"
            class="col-span-1 flex h-16 w-full flex-col justify-center gap-0.5 rounded-lg border border-platform-outlines px-5 py-2 bg-platform-canvas"
          >
            <p class="truncate text-xs font-medium leading-4 text-light">{{ schema.label }}</p>
            <span
              v-if="
                schema.field_type == 'date' &&
                isDateString(recordDetailStore.current_preview_file[schema.field_name])
              "
              class="text-sm leading-5"
            >
              {{ formatDate(recordDetailStore.current_preview_file[schema.field_name]) }}
            </span>
            <span v-else class="text-sm leading-5">
              {{ recordDetailStore.current_preview_file[schema.field_name] }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="
          !recordDetailStore.fileIsLoading &&
          recordDetailStore.current_preview_file.file_type !== 'text/csv' &&
          recordDetailStore.current_preview_file.file_type !==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        "
        class="mx-4 mb-4 max-w-full"
      >
        <object
          :data="recordDetailStore.file_preview_url"
          :type="recordDetailStore.current_preview_file.file_type"
          width="100%"
          height="700"
          class="rounded"
        ></object>
      </div>
      <div
        v-if="
          !recordDetailStore.fileIsLoading &&
          recordDetailStore.current_preview_file.file_type ==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        "
        class="mx-4 mb-4 max-w-full"
      >
        <div class="rounded bg-white p-4" v-html="docContent"></div>
      </div>
      <div
        v-else-if="
          recordDetailStore.current_preview_file.file_type === 'text/csv' &&
          !recordDetailStore.fileIsLoading &&
          recordDetailStore.preview_csv_data.length > 0
        "
        class="mx-4 mb-4 max-w-full"
      >
        <Table
          :tableData="recordDetailStore.preview_csv_data"
          :headers="recordDetailStore.preview_csv_headers"
          :rounded_bottom="false"
          class="w-full overflow-auto border"
        >
        </Table>
      </div>
      <div
        v-else-if="
          recordDetailStore.current_preview_file.file_type === 'text/csv' &&
          !recordDetailStore.fileIsLoading &&
          recordDetailStore.preview_csv_data.length === 0
        "
        class="mx-4 mb-4 max-w-full"
      >
        This CSV file is empty.
      </div>
      <div v-else class="min-h-75 flex items-center justify-center py-4">
        <LoadingSpinner
          :show="recordDetailStore.fileIsLoading"
          hideOverlay
          position="relative z-0"
        />
      </div>
    </template>
  </SideDrawer>
</template>
<script setup>
import { ref } from "vue";
import { SideDrawer } from "@/components";
import { useRecordDetailStore } from "@/stores";
import { LoadingSpinner, Table } from "@/components";

const recordDetailStore = useRecordDetailStore();

// Function to check if the value is a valid date string
function isDateString(value) {
  const date = new Date(value);
  return !isNaN(date.getTime());
}

// Function to format the date string as MM/DD/YYYY - h:mm a
function formatDate(value) {
  const date = new Date(value);

  let date_string = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  let time_string = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${date_string} - ${time_string}`;
}

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});
</script>
