<template>
  <div class="relative">
    <!-- To simulate the right border without affecting icon placement -->
    <div
      v-if="nav_route ? isMainItemActive : isMainItemActive && !UIStore.isSideNavigationOpen"
      class="absolute right-0 h-12 w-1 bg-secondary-6"
    ></div>
    <RouterLink v-if="nav_route" :to="nav_route" v-slot="{ isActive }">
      <div
        class="flex h-12 w-full items-center gap-2.5 overflow-hidden px-4"
        :class="{
          'bg-primary-1-light text-primary-6': isMainItemActive,
          'text-white': !isMainItemActive,
        }"
      >
        <!-- TODO: Figure out why the icons slightly resize if I don't do this -->
        <component
          v-if="icon"
          :is="icon"
          class="pointer-events-none max-h-6 min-h-6 min-w-6 max-w-6"
          :class="isMainItemActive ? 'fill-primary-6' : 'fill-primary-1-light'"
        />
        <Icon
          v-else
          :name="iconName"
          :stroke-width="2"
          class="max-h-6 min-h-6 min-w-6 max-w-6"
          :class="isMainItemActive ? 'text-primary-6' : 'text-primary-1-light'"
        />
        <span
          class="pointer-events-none whitespace-nowrap text-sm transition-opacity duration-200 ease-linear"
          :class="[
            isMainItemActive ? 'font-bold' : 'font-normal',
            UIStore.isSideNavigationOpen ? 'opacity-100' : 'opacity-0',
          ]"
        >
          {{ title }}
        </span>
      </div>
    </RouterLink>
    <div v-else>
      <div
        class="flex h-12 w-full cursor-pointer items-center gap-2.5 overflow-hidden px-4"
        @click="handleShowAdminTools"
        :class="{
          'bg-primary-1-light': isMainItemActive && !UIStore.isSideNavigationOpen,
          'bg-primary-1 bg-opacity-20': isNestedItemsOpen && UIStore.isSideNavigationOpen,
        }"
      >
        <component
          :is="icon"
          class="pointer-events-none max-h-6 min-h-6 min-w-6 max-w-6 fill-primary-1-light"
          :class="
            isMainItemActive && !UIStore.isSideNavigationOpen
              ? 'fill-primary-6'
              : 'fill-primary-1-light'
          "
        />
        <span
          class="transition-opacity text-sm duration-200 ease-linear"
          :class="UIStore.isSideNavigationOpen ? 'opacity-100' : 'opacity-0'"
        >
          {{ title }}
        </span>
        <IconExpandLess
          v-if="isNestedItemsOpen"
          class="pointer-events-none h-4 w-4 fill-primary-1-light transition-opacity duration-200 ease-linear"
          :class="UIStore.isSideNavigationOpen ? 'opacity-100' : 'opacity-0'"
        />
        <IconExpandMore
          v-else
          class="pointer-events-none h-4 w-4 fill-primary-1-light transition-opacity duration-200 ease-linear"
          :class="UIStore.isSideNavigationOpen ? 'opacity-100' : 'opacity-0'"
        />
      </div>
      <div
        v-if="isNestedItemsOpen"
        class="bg-primary-1 bg-opacity-20 transition-opacity duration-200 ease-linear"
        :class="UIStore.isSideNavigationOpen ? 'opacity-100' : 'opacity-0'"
      >
        <RouterLink
          @click="UIStore.isSideNavigationOpen = false"
          v-for="item in nestedItems"
          :key="item.route"
          :to="item.route"
        >
          <div
            class="flex h-8.5 w-full items-center px-4 text-white"
            :class="{
              'font-semibold': route.path === item.route,
            }"
          >
            <span
              class="whitespace-nowrap text-sm transition-opacity duration-200 ease-linear"
              :class="UIStore.isSideNavigationOpen ? 'opacity-100' : 'opacity-0'"
            >
              {{ item.title }}
            </span>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { RouterLink, useRoute } from "vue-router";

import { useUIStore } from "@/stores";
import { Icon } from "@/components";
import { IconExpandLess, IconExpandMore } from "@/components/icons";

const UIStore = useUIStore();

const route = useRoute();
const isNestedItemsOpen = ref(false);

const props = defineProps({
  title: String,
  icon: Object,
  iconName: String,
  nav_route: String,
  nestedItems: Array,
});

const isMainItemActive = computed(() => {
  if (props.nestedItems && props.nestedItems.length > 0) {
    return props.nestedItems.some((item) => route.fullPath === item.route);
  }

  return route.path.startsWith(props.nav_route);
});

function handleShowAdminTools() {
  if (!UIStore.isSideNavigationOpen) {
    UIStore.isSideNavigationOpen = true;
    isNestedItemsOpen.value = true;
  } else {
    isNestedItemsOpen.value = !isNestedItemsOpen.value;
  }
}
</script>
