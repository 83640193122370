<template>
  <div
    :id="component === 'header' ? 'tabs-container' : 'filter-tabs'"
    class="no-scrollbar w-68 flex items-center overflow-auto border-b border-platform-outlines"
    @scroll="handleScroll()"
  >
    <slot name="filter-modal-header" />

    <!-- <slot name="filter-modal-filter-tabs" /> -->
    <div
      v-if="showScrollLeft && tabs.length > 2"
      class="h-value-7 absolute left-0 z-20 flex"
      :class="component === 'header' ? 'top-0' : activeTab === 'Filters' ? 'top-12' : 'hidden'"
    >
      <button
        @click="handleScrollLeft()"
        class="flex w-12 items-center justify-center rounded-t-md bg-white"
      >
        <IconChevronLeft class="h-5 w-5" />
      </button>
      <div class="gradient-right w-8"></div>
    </div>
    <div
      v-if="showScrollRight && tabs.length > 2"
      class="h-value-7 absolute right-0 z-20 flex"
      :class="component === 'header' ? 'top-0' : activeTab === 'Filters' ? 'top-12' : 'hidden'"
    >
      <div class="gradient-left w-8"></div>
      <button
        @click="handleScrollRight()"
        class="flex w-12 items-center justify-center rounded-t-md bg-white"
      >
        <IconChevronRight class="h-5 w-5" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { IconChevronRight, IconChevronLeft } from "@/components/icons";
const props = defineProps({
  tabs: Array,
  component: String,
  activeTab: String,
});

const showScrollRight = ref(true);
const showScrollLeft = ref(false);
let scrollContainer;

onMounted(() => {
  scrollContainer =
    props.component === "header"
      ? document.querySelector("#tabs-container")
      : document.querySelector("#filter-tabs");
  if (props.activeTab === "Groups" || props.activeTab === "Visibility") {
    scrollContainer.scrollTo(200, 0);
  } else if (props.activeTab === "Filters" || props.activeTab === "Columns") {
    scrollContainer.scrollTo(0, 0);
  }
});

watch(
  () => props.activeTab,
  function () {
    if (props.activeTab === "Groups" || props.activeTab === "Visibility") {
      scrollContainer.scrollTo(200, 0);
    } else if (props.activeTab === "Filters" || props.activeTab === "Columns") {
      scrollContainer.scrollTo(0, 0);
    }
  }
);

function handleScroll() {
  const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

  // Detect if you can scroll to the left.
  showScrollLeft.value = scrollLeft > 0;

  if (Math.ceil(scrollLeft) + clientWidth >= scrollWidth) {
    showScrollRight.value = false;
  } else {
    showScrollRight.value = true;
  }
}

function handleScrollRight() {
  scrollContainer.scrollBy({ top: 0, left: 100, behavior: "smooth" });
}

function handleScrollLeft() {
  scrollContainer.scrollBy({ top: 0, left: -100, behavior: "smooth" });
}
</script>
