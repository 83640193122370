<template>
  <div>
    <div class="pb-2 text-primary-6 font-semibold">Joint Investor Details</div>
    <div class="flex flex-col gap-2 text-light text-sm">
      <div>
        Name: {{ titleTransferStore.joint_contact_for_investment.first_name }}
        {{ titleTransferStore.joint_contact_for_investment.last_name }}
      </div>
      <div>
        Address: {{ titleTransferStore.joint_contact_for_investment.street1 }},
        {{ titleTransferStore.joint_contact_for_investment.city }},
        {{ titleTransferStore.joint_contact_for_investment.state }}
      </div>
      <div>Phone: {{ titleTransferStore.joint_contact_for_investment.phone }}</div>
      <div>Email: {{ titleTransferStore.joint_contact_for_investment.email }}</div>
      <div class="flex w-full items-center">
        <div class="flex h-full items-center">
          SSN:
          {{ ssn_is_visible ? titleTransferStore.joint_contact_for_investment.ssn : "***********" }}
          <div class="ml-2 mb-1 flex items-center">
            <IconVisibility
              class="fill-light"
              id="base-input"
              v-if="!ssn_is_visible"
              @click="ssn_is_visible = true"
            />
            <IconVisibilityOff
              class="fill-light"
              id="base-input"
              v-else
              @click="ssn_is_visible = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { IconVisibility, IconVisibilityOff } from "@/components/icons";
import { useTitleTransferStore } from "@/stores";
const titleTransferStore = useTitleTransferStore();
const ssn_is_visible = ref(false);
</script>
