<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Step {{ createInvestmentStore.currentStep + 1 }}: Investment Details
    </div>
  </div>
  <div class="py-8 border-b border-platform-outlines">
    <div class="font-bold">User Information</div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <BaseInput
        :disabled="route.path == '/contacts'"
        v-model="createInvestmentStore.current_owner_contact.email"
        type="string"
        label="User Email"
        size="large"
        required
      />
      <BaseInput
        :disabled="route.path == '/contacts'"
        v-model="createInvestmentStore.current_owner_contact_full_name"
        type="string"
        label="Full Name"
        size="large"
        required
      />
    </div>
  </div>
  <div class="py-8 border-b border-platform-outlines">
    <div class="font-bold">Investment Information</div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <BaseInput
        v-model="createInvestmentStore.selected_offering_name"
        type="dropdown"
        label="Offering Type"
        :options="createInvestmentStore.offering_name_array"
        size="large"
        required
      />
      <BaseInput
        v-model="createInvestmentStore.investment_type"
        type="dropdown"
        :options="schemaStore.admin_investment_types"
        label="Investment Type"
        size="large"
        required
      />
    </div>
  </div>
  <div class="py-8">
    <div class="font-bold">Amount</div>
    <div class="text-lighter">One bond equals $1,000 USD</div>
    <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
      <BaseInput
        v-model="createInvestmentStore.investment_amount"
        type="string"
        label="Investment Amount"
        size="large"
        required
        formatRule="amount"
      />
      <BaseInput
        :disabled="true"
        v-model="totalBonds"
        type="string"
        label="Number of Bonds"
        size="large"
      />
    </div>
    <div class="font-bold mt-4 mb-3">Payment Frequency</div>
    <div class="grid grid-cols-1 gap-x-3 md:grid-cols-2">
      <div v-for="option in createInvestmentStore.payment_frequency_options" :key="option.name">
        <label
          :for="option.name"
          :class="{
            'cursor-not-allowed opacity-50': option.disabled,
            'cursor-pointer': !option.disabled,
          }"
          class="mb-3.5 flex w-full flex-row items-center rounded-md"
        >
          <input
            type="radio"
            :id="option.name"
            :value="option.name"
            v-model="createInvestmentStore.payment_frequency"
            :disabled="option.disabled"
            class="hidden"
          />
          <!-- Custom radio button design -->
          <div
            :class="{
              'ml-4 mr-4.5 flex h-4.5 w-4.5 items-center justify-center border-2 rounded-full': true,
              'bg-secondary-6 border-secondary-6':
                createInvestmentStore.payment_frequency === option.name && !option.disabled,
              'border-neutral-gray-5 bg-[#fff]':
                createInvestmentStore.payment_frequency !== option.name && !option.disabled,
              'border-neutral-gray-5 bg-[#f0f0f0]': option.disabled,
            }"
          >
            <div
              v-if="createInvestmentStore.payment_frequency === option.name && !option.disabled"
              class="h-value-26 w-1.5 rounded-full bg-primary-5"
            ></div>
          </div>
          <div class="flex flex-col">
            <p :class="{ 'text-neutral-gray-13': option.disabled }">
              {{ option.name }}
            </p>
            <p class="text-lighter">
              {{ option.description }}
            </p>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script setup>
import { BaseInput } from "@/components";
import { useCreateInvestmentStore, useSchemaStore } from "@/stores";
import { useRoute } from "vue-router";
import { watch, computed } from "vue";

const route = useRoute();
const createInvestmentStore = useCreateInvestmentStore();
const schemaStore = useSchemaStore();

watch(
  () => createInvestmentStore.selected_offering_name,
  (newVal) => {
    createInvestmentStore.payment_frequency = "";
    createInvestmentStore.selected_offering = createInvestmentStore.offerings.find(
      (offering) =>
        `${offering.name} - ${offering.issuer} - ${offering.type}` ===
        createInvestmentStore.selected_offering_name
    );

    if (createInvestmentStore.selected_offering.can_compound) {
      createInvestmentStore.payment_frequency_options[0].disabled = false;
    } else {
      createInvestmentStore.payment_frequency_options[0].disabled = true;
      createInvestmentStore.payment_frequency = "Interest Paid Monthly";
    }
  }
);
watch(
  () => createInvestmentStore.investment_is_discounted,
  (newVal) => {
    if (!createInvestmentStore.investment_is_discounted) {
      createInvestmentStore.investment_discount_amount = "0";
    }
  }
);
watch(
  () => createInvestmentStore.investment_amount,
  (newVal) => {
    createInvestmentStore.selected_rollover_investment = {};
  }
);

const correctedInvestmentAmount = computed(() => {
  const amount = parseFloat(createInvestmentStore.investment_amount.replace(/,/g, ""));
  if (isNaN(amount) || amount < 100) {
    return 0;
  }
  return Math.floor(amount / 1000) * 1000;
});

const totalBonds = computed(() => {
  return correctedInvestmentAmount.value / 1000;
});
</script>
