<template>
  <div>
    <div class="pb-2 text-primary-6 font-semibold">Investor Details</div>
    <div class="flex flex-col gap-2 text-light text-sm">
      <div>
        Name: {{ titleTransferStore.current_owner_contact.first_name }}
        {{ titleTransferStore.current_owner_contact.last_name }}
      </div>
      <div>
        Address: {{ titleTransferStore.current_owner_contact.street1 }},
        {{ titleTransferStore.current_owner_contact.city }},
        {{ titleTransferStore.current_owner_contact.state }}
      </div>
      <div>Phone: {{ titleTransferStore.current_owner_contact.phone }}</div>
      <div>Email: {{ titleTransferStore.current_owner_contact.email }}</div>
      <div class="flex w-full items-center">
        <div class="flex h-full items-center">
          SSN: {{ ssn_is_visible ? titleTransferStore.current_owner_contact.ssn : "***********" }}
          <div class="ml-2 mb-1 flex items-center">
            <IconVisibility
              id="base-input"
              class="fill-light"
              v-if="!ssn_is_visible"
              @click="ssn_is_visible = true"
            />
            <IconVisibilityOff
              id="base-input"
              v-else
              class="fill-light"
              @click="ssn_is_visible = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { IconVisibility, IconVisibilityOff } from "@/components/icons";
import { useTitleTransferStore } from "@/stores";
const titleTransferStore = useTitleTransferStore();
const ssn_is_visible = ref(false);
</script>
