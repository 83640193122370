<template>
  <div>
    <div class="pb-8 pt-2 text-xl font-bold border-b border-platform-outlines">
      Transferee Details
    </div>

    <div class="py-8 border-b border-platform-outlines">
      <div class="font-bold">Choose Transferee</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          required
          label="Search for Contact"
          v-model:currentTermValue="titleTransferStore.selected_transferee_investor.searchTerm"
          @update:currentTermValue="titleTransferStore.getResultsForTransfereeUserAssignmentField"
          v-model:assignedObject="titleTransferStore.selected_transferee_investor.assignedObject"
          type="assignment"
          size="large"
          customClass="bg-neutral-gray-1"
          placeholder="Search Contacts..."
          :results="titleTransferStore.selected_transferee_investor.results"
          @focus="
            titleTransferStore.getResultsForTransfereeUserAssignmentField(
              titleTransferStore.selected_transferee_investor.searchTerm
            )
          "
        >
          <template #search-dropdown>
            <div class="flex flex-col pt-1">
              <div
                v-for="(result, index) in titleTransferStore.selected_transferee_investor.results"
                @mousedown="titleTransferStore.setResultForTransfereeUserAssignmentField(result)"
                :key="index"
                class="relative block cursor-pointer truncate px-1 py-2 pl-3 text-dark hover:bg-platform-hover-secondary"
              >
                {{ result.first_name }} {{ result.last_name }} - {{ result.email }}
              </div>
            </div>
          </template>
        </BaseInput>
      </div>
    </div>

    <div class="py-8 border-b border-platform-outlines">
      <div class="pb-2 text-primary-6 font-semibold">Transferee Information</div>
      <div class="mt-4">
        <div
          class="flex flex-col gap-2 text-light text-sm"
          v-if="titleTransferStore.selected_transferee_full_document._id"
        >
          <div>
            Name: {{ titleTransferStore.selected_transferee_full_document.first_name }}
            {{ titleTransferStore.selected_transferee_full_document.last_name }}
          </div>
          <div>
            Address: {{ titleTransferStore.selected_transferee_full_document.street1 }},
            {{ titleTransferStore.selected_transferee_full_document.city }},
            {{ titleTransferStore.selected_transferee_full_document.state }}
          </div>
          <div>Phone: {{ titleTransferStore.selected_transferee_full_document.phone }}</div>
          <div>Email: {{ titleTransferStore.selected_transferee_full_document.email }}</div>
          <div class="flex w-full items-center">
            <div class="flex h-full items-center">
              SSN:
              {{
                ssn_is_visible
                  ? titleTransferStore.selected_transferee_full_document.ssn
                  : "***********"
              }}
              <div id="base-input" class="ml-2 mb-1 flex items-center">
                <IconVisibility
                  id="base-input"
                  v-if="!ssn_is_visible"
                  @click="ssn_is_visible = true"
                  class="fill-light"
                />
                <IconVisibilityOff
                  class="fill-light"
                  id="base-input"
                  v-else
                  @click="ssn_is_visible = false"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>Please select a transferee contact to view their details.</div>
      </div>
    </div>

    <div class="py-8">
      <div class="font-bold">Validate Transferee Information</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <BaseInput
            v-model="titleTransferStore.transferee_entered_email"
            type="string"
            label="Transferee Email"
            size="large"
            required
          />
          <div v-if="invalid_transferee_email" class="mt-2 text-functional-error-default">
            Emails do not match
          </div>
          <div
            class="mt-2 text-green-400"
            v-if="
              titleTransferStore.transferee_entered_email ===
              titleTransferStore.selected_transferee_full_document.email
            "
          >
            Emails Match!
          </div>
        </div>
        <div>
          <BaseInput
            v-model="titleTransferStore.transferee_entered_ssn"
            type="number"
            label="Last 4 Digits of Transferee SSN"
            size="large"
            required
          />
          <div v-if="invalid_transferee_ssn" class="mt-2 text-functional-error-default">
            The last 4 digits of the SSN do not match
          </div>
          <div
            v-if="!invalid_transferee_ssn && titleTransferStore.transferee_entered_ssn.length >= 4"
            class="mt-2 text-green-400"
          >
            SSNs Match!
          </div>
        </div>
      </div>
    </div>
    <div class="py-8">
      <div class="font-bold">Transferee Signature Date</div>
      <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
        <BaseInput
          v-model="titleTransferStore.transferee_signature_date"
          type="date"
          size="large"
          label="Transferee Signature Date"
          required
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { BaseInput } from "@/components";
import { IconVisibility, IconVisibilityOff } from "@/components/icons";
import { useTitleTransferStore } from "@/stores";

const titleTransferStore = useTitleTransferStore();
const ssn_is_visible = ref(false);

watch(
  () => titleTransferStore.selected_transferee_investor,
  () => {
    if (!titleTransferStore.selected_transferee_investor.assignedObject._id) {
      titleTransferStore.selected_transferee_full_document = {};
      titleTransferStore.transferee_entered_email = "";
      titleTransferStore.transferee_entered_ssn = "";
    }
  },
  { deep: true }
);

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const invalid_transferee_email = computed(() => {
  const enteredEmail = titleTransferStore.transferee_entered_email;
  const storedEmail = titleTransferStore.selected_transferee_full_document.email;
  return emailRegex.test(enteredEmail) && enteredEmail !== storedEmail;
});

const invalid_transferee_ssn = computed(() => {
  const enteredSSN = titleTransferStore.transferee_entered_ssn;
  const storedSSNLast4 = titleTransferStore.selected_transferee_full_document?.ssn?.slice(-4);
  return enteredSSN.length >= 4 && enteredSSN !== storedSSNLast4;
});

watch(
  () => [
    titleTransferStore.transferee_entered_email,
    titleTransferStore.transferee_entered_ssn,
    titleTransferStore.transferee_signature_date,
  ],
  () => {
    titleTransferStore.transferee_is_invalid =
      titleTransferStore.transferee_entered_ssn !==
        titleTransferStore.selected_transferee_full_document?.ssn?.slice(-4) ||
      titleTransferStore.transferee_entered_email !==
        titleTransferStore.selected_transferee_full_document.email ||
      !titleTransferStore.transferee_signature_date;
  }
);
</script>
